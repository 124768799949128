import React, { Component, Fragment } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { OrderHistoryTable, OrderDetails } from "../../components/pos";

@inject("store")
@inject("posStore")
@observer
class OrderModal extends Component {
  @action closeModal = () => {
    this.props.close();
  };

  createReturn = (cartId) => {
    this.props.posStore.returnCartById(cartId);
    this.closeModal();
  };

  @observable orderNumber = null;
  @action setOrderNumber = (orderNumber) => {
    if (!isNaN(orderNumber)) {
      this.orderNumber = orderNumber;
      this.props.store.CartStore.fetchCartById(orderNumber)
        .then((cart) => {
          this.props.store.CartStore.setSelectedCart(cart);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load cart details",
            "There was an error retrieving your cart details. Please try again"
          );
        });
    }
  };

  @action clearOrder = () => {
    this.orderNumber = null;
    this.props.store.CartStore.setSelectedCart(null);
  };

  componentDidMount() {
    // this.props.store.CartStore.rehydrateAllCarts(this.offset, this.limit);
    this.props.store.MainStore.buildReceiptData();
  }

  render() {
    return (
      <div
        className={this.props.closeRef ? "modal is-active loadCart" : "modal"}
      >
        <div className="modal-background" onClick={this.closeModal} />
        <div className="modal-card modal-card-large">
          <header className="modal-card-head">
            <button
              className="delete"
              aria-label="close"
              onClick={this.orderNumber ? this.clearOrder : this.closeModal}
            >
              <i className="fal fa-times"></i> Close
            </button>
          </header>
          <section className="modal-card-body">
            <div className="wrapper">
              <Fragment>
                {!this.orderNumber ? (
                  <OrderHistoryTable
                    setOrderNumber={this.setOrderNumber}
                    createReturn={this.createReturn}
                  />
                ) : (
                  <OrderDetails
                    closeModal={this.closeModal}
                    setOrderNumber={this.setOrderNumber}
                  />
                )}
              </Fragment>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default OrderModal;

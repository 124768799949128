import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import { POSMenuStructure } from "../../menuStructures";
import { API_STATE } from "../../constants/api";
import { getEndOfDayReports } from "../../api/graphql/reports";
import { reformatEodReport } from "../../utils/reports";
import { Pagination, TableSpinner } from "../../components/reports";
import { CurrencyRenderer } from "../../components/reports/CellTypes";
import "react-base-table/styles.css";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class EODReports extends React.Component {
  componentDidMount() {
    document.title = "End of Day Reports | BinderPOS";
    const { reset, setApiState, setSourceData, setSortColumn } =
      this.props.store.ReportsStore;
    reset();
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    setApiState(API_STATE.LOADING);
    getEndOfDayReports("2020-01-01", "2099-12-12")
      .then((data) => {
        const reportData = reformatEodReport(data);
        setSourceData(reportData);
        setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load reports",
          "There was an error retrieving your end of day reports. Please refresh and try again"
        );
        setApiState(API_STATE.ERROR);
      });
    // By default, order from newest to oldest
    setSortColumn({
      key: "col1",
      order: "desc",
      column: {
        dataKey: "openedDate",
      },
    });
  }

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { ReportsStore } = this.props.store;
    const {
      currentPage,
      rowsPerPage,
      sortColumn,
      totalRowCount,
      apiState,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">End of Day Reports</span>
          </h2>
        </div>
        <div className="data-table">
          <div className="table-container">
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  data={data}
                  fixed
                  width={width}
                  height={height}
                  sortBy={sortColumn}
                  onColumnSort={setSortColumn}
                  overlayRenderer={() => (
                    <TableSpinner isLoading={apiState === API_STATE.LOADING} />
                  )}
                >
                  <Column
                    key="col0"
                    dataKey="name"
                    width={200}
                    title="Name"
                    sortable
                    resizable
                  />
                  <Column
                    key="col1"
                    dataKey="openedDate"
                    width={200}
                    cellRenderer={(props) =>
                      TimeUtils.convertDateToHumanReadable(props.cellData)
                    }
                    title="Opening Time"
                    sortable
                    resizable
                  />
                  <Column
                    key="col2"
                    dataKey="closedDate"
                    width={200}
                    cellRenderer={(props) =>
                      TimeUtils.convertDateToHumanReadable(props.cellData)
                    }
                    title="Closing Time"
                    sortable
                    resizable
                  />
                  <Column
                    key="col3"
                    dataKey="Cash.openingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Cash Opening"
                    sortable
                    resizable
                  />
                  <Column
                    key="col4"
                    dataKey="Cash.currentAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Cash Current"
                    sortable
                    resizable
                  />
                  <Column
                    key="col25"
                    dataKey="Cash.difference"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Cash Diff"
                    sortable
                    resizable
                  />
                  <Column
                    key="col26"
                    dataKey="Cash.closingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Cash Closing"
                    sortable
                    resizable
                  />
                  <Column
                    key="col5"
                    dataKey="Credit.openingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Credit Opening"
                    sortable
                    resizable
                  />
                  <Column
                    key="col6"
                    dataKey="Credit.currentAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Credit Current"
                    sortable
                    resizable
                  />
                  <Column
                    key="col7"
                    dataKey="Credit.difference"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Credit Diff"
                    sortable
                    resizable
                  />
                  <Column
                    key="col8"
                    dataKey="Credit.closingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Credit Closing"
                    sortable
                    resizable
                  />
                  <Column
                    key="col9"
                    dataKey="EFTPOS.openingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="EFTPOS Opening"
                    sortable
                    resizable
                  />
                  <Column
                    key="col10"
                    dataKey="EFTPOS.currentAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="EFTPOS Current"
                    sortable
                    resizable
                  />
                  <Column
                    key="col11"
                    dataKey="EFTPOS.difference"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="EFTPOS Diff"
                    sortable
                    resizable
                  />
                  <Column
                    key="col12"
                    dataKey="EFTPOS.closingAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="EFTPOS Closing"
                    sortable
                    resizable
                  />
                  <Column
                    key="col13"
                    dataKey="Store_Credit.currentAmount"
                    width={150}
                    align="right"
                    cellRenderer={CurrencyRenderer}
                    title="Store Credit"
                    sortable
                    resizable
                  />
                </BaseTable>
              )}
            </AutoResizer>
          </div>
          <Pagination
            apiState={apiState}
            currentPage={currentPage}
            totalRowCount={totalRowCount}
            rowsPerPage={rowsPerPage}
            rowPerPageOptions={rowPerPageOptions}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </>
    );
  }
}

EODReports.propTypes = { store: PropTypes.object };

export default EODReports;

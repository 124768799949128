import React, { Component } from "react";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Loader, Paging, DateSelect } from "../../components";
import { BuylistMenuStructure } from "../../menuStructures";
import { DEFAULT_BUYLISTS_PER_PAGE } from "../../constants/buylist";
import { fetchBuylists } from "../../api/rest/buylist";
import { BuylistTable } from "../../components/buylist";

@inject("store")
@observer
class PendingBuylist extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  @observable pageOffset = 0;

  @action setPageOffset = (offset) => {
    this.pageOffset = offset;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable buylistFilters = {};

  @action updateBuylistFilter = (parameter, value) => {
    if (parameter === "offset") {
      this.setPageOffset(value);
    } else {
      this.buylistFilters[parameter] = value;
    }
  };

  @observable sortColumn = { key: "dateSubmitted", order: "desc" };

  @action setSortColumn = (sort) => {
    console.log({ setSortColumn: sort });
    const { key, order } = sort;
    this.sortColumn = { key, order };
    this.setPageOffset(0);
    this.loadBuylists();
  };

  @computed get buylistParams() {
    const { key, order } = this.sortColumn;
    // The line at the end removes parameters where a value is not set
    return {
      offset: this.pageOffset,
      limit: DEFAULT_BUYLISTS_PER_PAGE,
      orderBy: key,
      desc: order === "desc",
      ...Object.fromEntries(
        Object.entries(this.buylistFilters).filter(([, value]) => !!value)
      ),
    };
  }

  componentDidMount() {
    document.title = "Pending buylists | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.loadBuylists();
  }

  loadBuylists = () => {
    this.setIsLoading(true);
    fetchBuylists("pending", this.buylistParams)
      .then((result) => {
        this.props.store.BuylistRulesStore.setPendingBuylists(result);
      })
      .catch((error) => {
        console.error(error);
        this.props.store.MainStore.setError(
          error,
          "Failed to load buylists",
          "There was an error retrieving your pending buylists. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  prevPage = () => {
    let newOffset = this.pageOffset - DEFAULT_BUYLISTS_PER_PAGE;
    if (newOffset < 0) {
      newOffset = 0;
    }
    this.setPageOffset(newOffset);
    this.loadBuylists();
  };

  nextPage = () => {
    const newOffset = this.pageOffset + DEFAULT_BUYLISTS_PER_PAGE;
    this.setPageOffset(newOffset);
    this.loadBuylists();
  };

  onBuylistFilterChange = (parameter, value) => {
    this.updateBuylistFilter(parameter, value);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(this.loadBuylists, 500);
  };

  render() {
    const { AuthStore, BuylistRulesStore } = this.props.store;

    if (!AuthStore.screenSettings.buylist) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    if (!BuylistRulesStore.pendingBuylists) {
      return <Loader />;
    }

    // Have to unpack here or Buylist table never gets updated
    const buylistFilters = { ...this.buylistFilters };

    return (
      <>
        <div className="header">
          <h2>Pending buylists</h2>
          <div className="table-controls">
            <DateSelect
              filterValues={buylistFilters}
              updateFilterValue={this.onBuylistFilterChange}
            />
            <Paging
              pageOffset={this.pageOffset}
              currentPageItemCount={
                BuylistRulesStore.pendingBuylists &&
                BuylistRulesStore.pendingBuylists.length
              }
              maxItemsPerPage={DEFAULT_BUYLISTS_PER_PAGE}
              getPrevPage={this.prevPage}
              getNextPage={this.nextPage}
            />
          </div>
        </div>
        <div className="pendingBuylist">
          <BuylistTable
            buylists={BuylistRulesStore.pendingBuylists}
            viewUrl="/buylists/pending/moreDetail"
            isLoading={this.isLoading}
            filterValues={this.buylistFilters}
            updateFilterValue={this.onBuylistFilterChange}
            sortBy={this.sortColumn}
            onColumnSort={this.setSortColumn}
          />
        </div>
        {BuylistRulesStore.pendingBuylists &&
          BuylistRulesStore.pendingBuylists.length > 10 && (
            <Paging
              pageOffset={this.pageOffset}
              currentPageItemCount={
                BuylistRulesStore.pendingBuylists &&
                BuylistRulesStore.pendingBuylists.length
              }
              maxItemsPerPage={DEFAULT_BUYLISTS_PER_PAGE}
              getPrevPage={this.prevPage}
              getNextPage={this.nextPage}
            />
          )}
      </>
    );
  }
}

export default PendingBuylist;

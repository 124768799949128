const IntegrationsMenuStructure = (availableIntegrations) => {
  const integrationLinks = availableIntegrations.map((integration) => ({
    title: integration.syncFriendlyName,
    href: `integrations/${integration.syncName}`,
  }));

  return {
    title: "Integrations",
    activeClass: "orange",
    links: [
      {
        title: "Select Integration",
        href: "integrations",
        exact: true,
      },
      ...integrationLinks,
    ],
  };
};

export default IntegrationsMenuStructure;

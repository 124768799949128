import React, { Component } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import { OperationalMode, operationalMode } from "../../constants/mode";

@inject("posStore")
@observer
class SearchBar extends Component {
  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 220:
        if (
          document.activeElement.type != "text" &&
          document.activeElement.type != "textarea" &&
          document.activeElement.type != "search" &&
          document.activeElement.type != "number" &&
          document.activeElement.type != "tel"
        ) {
          event.preventDefault();
          this.nameInput.focus();
          this.nameInput.select();
        }
        break;
      case 13:
        break;
      case 16:
        break;
      case 17:
        break;
      case 18:
        break;
      case 37:
        break;
      case 38:
        break;
      case 39:
        break;
      case 40:
        this.nameInput.blur();
        document.getElementsByClassName("results-grid")[0].focus();
        break;
      default:
        if (
          document.activeElement.type != "text" &&
          document.activeElement.type != "textarea" &&
          document.activeElement.type != "number" &&
          document.activeElement.type != "search" &&
          document.activeElement.type != "tel"
        ) {
          this.nameInput.focus();
          this.nameInput.select();
        }
        break;
    }
  };

  componentDidMount() {
    this.nameInput.focus();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  @action
  cashMode(e) {
    this.props.store.cashMode = e.target.checked;
  }

  render() {
    return (
      <form className="search-form">
        <div className="search-bar">
          {operationalMode !== OperationalMode.Kiosk ? (
            <div className="suggestToggle">
              <span className="predictive">Predictive</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={this.props.posStore.suggestEnabled}
                  onChange={this.props.posStore.toggleSuggest}
                />
                <span className="slider round" />
              </label>
            </div>
          ) : null}
          <input
            type="text"
            value={this.props.posStore.searchTerm}
            onChange={this.props.posStore.search}
            className="textbox"
            placeholder="Search Product"
            onKeyPress={this.props.posStore.fetchBarcode}
            ref={(input) => {
              this.nameInput = input;
            }}
            onFocus={this.props.posStore.setSearchFocused}
            onBlur={this.props.posStore.setSearchBlurredDelayed}
          />
          {this.props.posStore.searchTerm &&
          this.props.posStore.suggestEnabled &&
          this.props.posStore.searchSuggestions.length > 0 &&
          this.props.posStore.searchSuggestions[0] !=
            this.props.posStore.searchTerm &&
          this.props.posStore.searchFocused ? (
            <div className="suggestBox">
              {this.props.posStore.searchSuggestions.map((title) =>
                title ? (
                  <div
                    key={title}
                    onClick={() =>
                      this.props.posStore.search({ target: { value: title } })
                    }
                  >
                    {title}
                  </div>
                ) : null
              )}
            </div>
          ) : null}
        </div>

        <div className="switcher">
          <label className="switch">
            <input
              type="checkbox"
              checked={this.props.posStore.buyMode}
              onChange={this.props.posStore.toggleBuyMode}
            />
            <span className="slider round" />
          </label>
        </div>
      </form>
    );
  }
}

export default SearchBar;

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { ReportsMenuStructure } from "../../menuStructures";

@inject("store")
@observer
class StockHome extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MenuStore } = this.props.store;
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
  }

  render() {
    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Stock Reports</span>
          </h2>
        </div>
        <div className="reports-menu row">
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Stock Inventory Value</h5>
                <p className="card-text">
                  View count, value and cost of stock inventory by product type.
                </p>
                <Link to="/reports/stock/value" className="btn btn-primary">
                  View Report
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

StockHome.propTypes = { store: PropTypes.object };
export default StockHome;

import React from "react";
import { AdditionalInfoModel } from "./additional-info.model";
import { Item } from "./item.model";

const AdditionalInfo = ({
  addItem,
  form,
  addItemWithAdditionalInfo,
  item,
  close,
}: {
  addItem: () => unknown;
  addItemWithAdditionalInfo: (
    event: React.FormEvent<HTMLFormElement>
  ) => unknown;
  form: React.LegacyRef<HTMLFormElement>;
  item: Item;
  close: () => void;
}) => {
  return (
    <div className="modal is-active additionalInfoModal">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Event additional information</p>
          <button
            className="delete"
            type="button"
            aria-label="close"
            onClick={close}
          >
            <i className="fal fa-times"></i> Close{" "}
          </button>
        </header>
        <form
          className="additionalInfoForm"
          ref={form}
          onSubmit={(e) => addItemWithAdditionalInfo(e)}
          noValidate
        >
          <section className="modal-card-body">
            <div className="field">
              <p>
                Attach additional information to events, these will only be
                applied if a customer is selected.
              </p>
            </div>
            {item.event.additionalInfo.map(
              (additionalInfo: AdditionalInfoModel, index: number) => {
                return (
                  <div className="field" key={index}>
                    <label>{additionalInfo.header}</label>
                    {additionalInfo.description &&
                    additionalInfo.description.length > 0 ? (
                      <p>{additionalInfo.description}</p>
                    ) : null}
                    <input
                      className="input additionalInput"
                      type="text"
                      id={"additionalInfo" + additionalInfo.id}
                    />
                  </div>
                );
              }
            )}
          </section>
          <footer className="modal-card-foot">
            <button className={"modalBtn cancel half"} onClick={addItem}>
              Skip
            </button>
            <button className={"modalBtn action half"} type="submit">
              Add info
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

export default AdditionalInfo;

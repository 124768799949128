import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Loader from "../generic/Loader";

@inject("store")
@observer
class GridView extends Component {
  render() {
    const {
      removeLine,
      setSearchModalVisible,
      onVariantQuantityChange,
      onVariantBuyPriceChange,
    } = this.props;
    const { BuylistRulesStore, CustomersStore, MainStore } = this.props.store;
    const fC = MainStore.currencyBuilder;
    const { completed, paymentType, cards } = BuylistRulesStore.buylistDetails;
    const isPaidInCash = paymentType === "Cash";
    const currencySymbol = MainStore.currency || "$";

    if (!CustomersStore.customerVariants) return <Loader />;

    return (
      <div className="tiledPanels buylistItem">
        {cards.map((buylistDetail, index) => {
          const { imageUrl, cardName, cardId, type, setName } = buylistDetail;
          let rowClass = "";
          try {
            const cardCount = Object.values(buylistDetail.variants).reduce(
              (count, variant) => count + parseInt(variant.quantity, 10),
              0
            );
            if (cardCount === 0) {
              rowClass = "error";
            }
          } catch (err) {
            console.error(err);
          }
          return (
            <div className="panelWrapper" key={index}>
              <div className={`panel ${rowClass}`}>
                {!completed && (
                  <span className="remove" onClick={() => removeLine(index)}>
                    Remove <i className="fas fa-times-circle" />
                  </span>
                )}
                <div className="topBlock">
                  <div className="cardImg">
                    <img src={imageUrl} />
                  </div>
                  <div className="titleInfo">
                    <span className="cardName">{`${cardName} ${
                      type ? "- " + type : ""
                    }`}</span>
                    <span className="cartSet">{setName}</span>
                  </div>
                </div>
                <hr />
                <div className="cardInfo">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "50% 20% 30%",
                    }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      Condition
                    </span>
                    <span style={{ textDecoration: "underline" }}>Qty</span>
                    <span style={{ textDecoration: "underline" }}>
                      Buy Price
                    </span>
                    {CustomersStore.customerVariants.map((variant, _index) => {
                      let quantity = 0;
                      let buyPrice = 0;
                      if (
                        Object.keys(buylistDetail.variants).includes(
                          String(variant.id)
                        )
                      ) {
                        const _variant = buylistDetail.variants[variant.id];
                        quantity = _variant.quantity;
                        buyPrice = isPaidInCash
                          ? _variant.cashBuyPrice
                          : _variant.storeCreditBuyPrice;
                      }
                      return (
                        <Fragment key={_index}>
                          <span>{variant.name}</span>
                          <span className="buylist-item-input">
                            {completed ? (
                              quantity
                            ) : (
                              <input
                                className="quantity"
                                type="number"
                                min={0}
                                step={1}
                                value={quantity}
                                onChange={(event) =>
                                  onVariantQuantityChange(
                                    cardName,
                                    cardId,
                                    setName,
                                    type,
                                    variant.id,
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </span>
                          <span className="buylist-item-input">
                            {currencySymbol}
                            {completed ? (
                              buyPrice
                            ) : (
                              <input
                                className="buy-price"
                                type="number"
                                min={0}
                                step={0.01}
                                value={buyPrice}
                                onChange={(event) =>
                                  onVariantBuyPriceChange(
                                    cardName,
                                    cardId,
                                    setName,
                                    type,
                                    variant.id,
                                    isPaidInCash,
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </span>
                        </Fragment>
                      );
                    })}
                  </div>
                  <hr />
                  <span>
                    Total Buy Price{" "}
                    <em>
                      {fC(
                        Object.values(buylistDetail.variants).reduce(
                          (sum, variant) =>
                            sum +
                            parseFloat(variant.quantity) *
                              parseFloat(
                                isPaidInCash
                                  ? variant.cashBuyPrice
                                  : variant.storeCreditBuyPrice
                              ),
                          0
                        )
                      )}
                    </em>
                  </span>
                  <span>
                    Total Sell Price{" "}
                    <em>
                      {fC(
                        Object.values(buylistDetail.variants).reduce(
                          (sum, variant) =>
                            sum +
                            (variant.quantity > 0
                              ? parseFloat(variant.quantity) *
                                parseFloat(variant.storeSellPrice)
                              : 0),
                          0
                        )
                      ) || "N/A"}
                    </em>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        {!completed && (
          <div className="panelWrapper addNew">
            <div className="panel" onClick={() => setSearchModalVisible(true)}>
              <p>
                <i className="fal fa-plus"></i> Add a Card
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

GridView.propTypes = {
  onVariantQuantityChange: PropTypes.func.isRequired,
  onVariantBuyPriceChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  setSearchModalVisible: PropTypes.func.isRequired,
};

export default GridView;

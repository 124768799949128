import React, { useState } from "react";
import { toast } from "react-toastify";
import { useShowError } from "../../hooks/errorHooks";
import { useUpdateServiceSetting } from "../../hooks/ebayHooks";
import ButtonComponent from "../generic/ButtonComponent";
import CheckboxComponent from "../generic/CheckboxComponent";
import "./EbayMiscSetting.scss";

interface EbayMiscSettingProps {
  parameter: string;
  name: string;
  description: string;
  savedData: unknown;
  type: string;
}

function EbayMiscSetting(props: EbayMiscSettingProps) {
  const { parameter, name, description, savedData, type } = props;
  const showError = useShowError();
  const [value, setValue] = useState(savedData);
  const [hasChanged, setHasChanged] = useState(false);
  const { mutateAsync, isMutating } = useUpdateServiceSetting();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasChanged(true);
    if (type === "boolean") {
      setValue(event.target.checked);
    } else {
      setValue(event.target.value);
    }
  };

  const handleSave = () => {
    const payload = { name: parameter, value: `${value}` };
    mutateAsync(payload)
      .then(() => {
        toast.info("Setting saved");
        setHasChanged(false);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to save setting",
          "There was an error updating your eBay settings. Please try again"
        );
      });
  };

  const renderInput = () => {
    if (type === "boolean") {
      return (
        <CheckboxComponent
          name={parameter}
          defaultChecked={value === "true"}
          label={value ? "Enabled" : "Disabled"}
          onChange={handleChange}
          data-testid="input"
        />
      );
    }
    return (
      <input
        type="text"
        name={parameter}
        defaultValue={value as string}
        onChange={handleChange}
        data-testid="input"
      />
    );
  };

  return (
    <div className="ebay-misc-setting">
      <div className="ebay-misc-setting__main">
        <p className="ebay-misc-setting__title">{name}</p>
        <div className="ebay-misc-setting__controls">
          {renderInput()}
          <ButtonComponent
            disabled={isMutating || !hasChanged}
            onClick={handleSave}
          >
            {isMutating ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default EbayMiscSetting;

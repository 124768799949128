import { POSMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/generic/Loader";
import "./MiscSettings.scss";

@inject("store")
@observer
class MiscSettings extends React.Component {
  @observable updatingSettings = true;

  @action setUpdatingSettings(updatingSettings) {
    this.updatingSettings = updatingSettings;
  }

  @observable loadingSettings = true;
  @action setLoadingSettings(loadingSettings) {
    this.loadingSettings = loadingSettings;
  }

  @observable cartExpireEnabled = { settingValue: false };

  @action setCartExpireEnabled(cartExpireEnabled) {
    this.cartExpireEnabled = cartExpireEnabled;
    if (
      cartExpireEnabled.settingValue == "true" ||
      cartExpireEnabled.settingValue === true
    ) {
      this.cartExpireEnabled.settingValue = true;
    } else {
      this.cartExpireEnabled.settingValue = false;
    }
  }

  @observable useSplitCheckout = { settingValue: false };

  @action setUseSplitCheckout(useSplitCheckout) {
    this.useSplitCheckout = useSplitCheckout;
    if (
      useSplitCheckout.settingValue == "true" ||
      useSplitCheckout.settingValue === true
    ) {
      this.useSplitCheckout.settingValue = true;
    } else {
      this.useSplitCheckout.settingValue = false;
    }
  }

  @observable useBarcodeQuantityCheck = { settingValue: false };

  @action setUseBarcodeQuantityCheck(useBarcodeQuantityCheck) {
    this.useBarcodeQuantityCheck = useBarcodeQuantityCheck;
    if (
      useBarcodeQuantityCheck.settingValue == "true" ||
      useBarcodeQuantityCheck.settingValue === true
    ) {
      this.useBarcodeQuantityCheck.settingValue = true;
    } else {
      this.useBarcodeQuantityCheck.settingValue = false;
    }
  }

  @observable savingSettings;

  @action setSavingSettings(savingSettings) {
    this.savingSettings = savingSettings;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchMiscSettings();
    document.title = "Misc Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.TillStore.rehydrateTills();
  }

  fetchMiscSettings() {
    Promise.all([
      this.props.store.SettingsStore.fetchCustomerSettingForType("cartExpire")
        .then((result) => {
          this.setCartExpireEnabled(result);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load settings",
            "There was an error retrieving your cart expiration settings. Please refresh and try again"
          );
        }),

      this.props.store.SettingsStore.fetchCustomerSettingForType(
        "posUseSplitCheckout"
      )
        .then((result) => {
          this.setUseSplitCheckout(result);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load settings",
            "There was an error retrieving your split checkout settings. Please refresh and try again"
          );
        }),

      this.props.store.SettingsStore.fetchCustomerSettingForType(
        "barcodeQuantityCheck"
      )
        .then((result) => {
          this.setUseBarcodeQuantityCheck(result);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load settings",
            "There was an error retrieving your barcode settings. Please refresh and try again"
          );
        }),
    ]).then(() => this.setLoadingSettings(false));
    this.setUpdatingSettings(true);
  }

  saveMiscSettings() {
    this.setSavingSettings(true);
    const promises = [];
    const cartExpire = this.cartExpireEnabled.settingValue;
    const cartExpireSettings = {
      settingName: "cartExpire",
      settingValue: `${cartExpire}`,
    };
    promises.push(
      this.props.store.SettingsStore.updateSetting(cartExpireSettings)
    );

    const useSplitCheckout = this.useSplitCheckout.settingValue;
    const useSplitCheckoutSettings = {
      settingName: "posUseSplitCheckout",
      settingValue: `${useSplitCheckout}`,
    };
    promises.push(
      this.props.store.SettingsStore.updateSetting(useSplitCheckoutSettings)
    );

    const useBarcodeQuantityCheck = this.useBarcodeQuantityCheck.settingValue;
    const useBarcodeQuantityCheckSettings = {
      settingName: "barcodeQuantityCheck",
      settingValue: `${useBarcodeQuantityCheck}`,
    };
    promises.push(
      this.props.store.SettingsStore.updateSetting(
        useBarcodeQuantityCheckSettings
      )
    );

    Promise.all(promises)
      .then(() => {
        this.props.store.MainStore.toast("Misc settings updated!");
        this.setUpdatingSettings(true);
        this.fetchMiscSettings();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to update settings",
          "There was an error submitting your updated settings. Please try again"
        );
      });
  }

  render() {
    if (this.loadingSettings) {
      return <Loader />;
    }

    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Misc Settings</span>
          </h2>
        </div>
        <div className="infoInputPages">
          <div className="col-md-12 content">
            <div
              style={{
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "20px",
              }}
            >
              <div className="miscSettings">
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <br />
                    <div className="control">
                      <label className="checkbox">
                        <input
                          id="expireCart"
                          type="checkbox"
                          checked={this.cartExpireEnabled.settingValue}
                          onChange={(e) => {
                            this.setCartExpireEnabled({
                              settingValue: e.target.checked,
                            });
                            this.setUpdatingSettings(false);
                          }}
                        />
                        <span className="checkmark" /> Expire unsaved cart and
                        return stock after 1 hour
                      </label>
                      <span className="checkmark" />
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <br />
                    <div className="control">
                      <label className="checkbox">
                        <input
                          id="optOutOfCatalogUpdates"
                          type="checkbox"
                          checked={this.useSplitCheckout.settingValue}
                          onChange={(e) => {
                            this.setUseSplitCheckout({
                              settingValue: e.target.checked,
                            });
                            this.setUpdatingSettings(false);
                          }}
                        />
                        <span className="checkmark" /> Use Split Checkout{" "}
                        <span className="beta">beta</span>
                      </label>
                      <span className="checkmark" />
                    </div>
                    <p>
                      Uses the new checkout process, which handles split
                      payments of the same tender type. Please note this is
                      currently not compatible with intergrated payment
                      terminals.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <br />
                    <div className="control">
                      <label className="checkbox">
                        <input
                          id="barcodeQuantityCheck"
                          type="checkbox"
                          checked={this.useBarcodeQuantityCheck.settingValue}
                          onChange={(e) => {
                            this.setUseBarcodeQuantityCheck({
                              settingValue: e.target.checked,
                            });
                            this.setUpdatingSettings(false);
                          }}
                        />
                        <span className="checkmark" /> Check inventory of
                        scanned items
                      </label>
                      <span className="checkmark" />
                    </div>
                    <p>
                      When scanning the barcode of an item that has no
                      inventory, a prompt will appear to warn about possible
                      overselling.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <button
                      className="button buttonWide"
                      style={{ marginRight: "10px" }}
                      onClick={() => this.saveMiscSettings()}
                      disabled={this.updatingSettings}
                    >
                      Save Settings
                    </button>
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <button
                      className="button buttonWide"
                      style={{ marginRight: "10px", background: "#ab4747" }}
                      onClick={() => this.fetchMiscSettings()}
                      disabled={this.updatingSettings}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MiscSettings.propTypes = { store: PropTypes.object };

export default MiscSettings;

import React from "react";
import { inject } from "mobx-react";
import { useFeature } from "../../hooks/globalSettingsHooks";

function CartIdleCheck() {
  const cartIdleCheckEnabled = useFeature("posCartIdleCheck");
  if (!cartIdleCheckEnabled) {
    return null;
  }
  return <CartIdleCheckEnable />;
}

interface CartIdleCheckEnableProps {
  posStore?: {
    setCartIdleCheckActive: (active: boolean) => void;
  };
}

@inject("posStore")
class CartIdleCheckEnable extends React.Component<CartIdleCheckEnableProps> {
  componentDidMount() {
    this.props.posStore.setCartIdleCheckActive(true);
  }

  render() {
    return null;
  }
}

export default CartIdleCheck;

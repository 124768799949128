import React from "react";
import Logout from "./Logout";
import MobileMenu from "./MobileMenu";
import UserAvatar from "./UserAvatar";
import "./MobileHeader.scss";
import Watermark from "../../../assets/img/watermark.png";
import Logo from "../../../assets/img/logo.png";

declare global {
  interface Window {
    FreshWidget: {
      show: () => void;
    };
  }
}

interface MobileHeaderProps {
  screenSettings: ScreenSettings;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
  handleClick: (menu: MenuStructure | null | undefined) => void;
  handleLogout: () => void;
}

function MobileHeader(props: MobileHeaderProps) {
  const {
    screenSettings,
    mobileMenuOpen,
    setMobileMenuOpen,
    handleClick,
    handleLogout,
  } = props;

  const openFresh = () => {
    window.FreshWidget.show();
  };

  return (
    <>
      <div className="watermark">
        <img src={Watermark} />
      </div>
      <div className="topCnrBg" />
      <div className="brand">
        <img src={Logo} />
      </div>
      <nav
        id="main-nav"
        className="navbar is-light mobile"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="MobileHeader__topNav">
          <div className="MobileHeader__navIcon">
            <i
              title="Support"
              className="fas fa-user-headset"
              onClick={openFresh}
            />
          </div>
          <Logout onLogout={handleLogout} />
          <UserAvatar />
        </div>
        <MobileMenu
          mobileMenuOpen={mobileMenuOpen}
          screenSettings={screenSettings}
          handleClick={handleClick}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      </nav>
    </>
  );
}

export default MobileHeader;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { remapTenderName } from "../../utils/tenders";
import Loader from "../../components/generic/Loader";
import TenderPayment from "./TenderPayment";
import TenderSelect from "./TenderSelect";
import "./MultiTenderCheckout.scss";

@inject("posStore")
@observer
class MultiTenderCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTenderSelect: true,
      selectedTender: null,
    };
  }

  showTenderSelect = (visible) => {
    this.setState({ showTenderSelect: visible });
  };

  selectTender = (tender) => {
    this.setState({ selectedTender: tender, showTenderSelect: false });
  };

  addTender = (type, value) => {
    this.props.posStore.addTender(type, value);
    this.setState({ selectedTender: null });
  };

  removeTender = (index) => {
    this.props.posStore.removeTender(index);
  };

  getRemainingStoreCredit = () => {
    const store = this.props.posStore;
    if (!store.selectedCustomer) return 0;
    const usedStoreCredit =
      store.tenders?.reduce((accumulator, tender) => {
        if (tender.type.toLowerCase() === "store credit") {
          return accumulator + Number(tender.amount);
        }
        return accumulator;
      }, 0) || 0;
    return store.selectedCustomer.storeCredit - usedStoreCredit;
  };

  storeCreditAvailable = (remainingBalance, remainingStoreCredit) => {
    if (!this.props.posStore.selectedCustomer) return false;
    if (remainingBalance < 0) {
      // Buys add to store credit
      return true;
    }
    return remainingStoreCredit > 0;
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;

    const totalTendered =
      store.tenders?.reduce(
        (accumulator, tender) => accumulator + Number(tender.amount),
        0
      ) || 0;

    const remainingBalance = Number(store.total) - totalTendered;
    const remainingStoreCredit = this.getRemainingStoreCredit();

    if (store.submittingCart) {
      return (
        <div className="modal display-block">
          <Loader text="Submitting cart..." />
        </div>
      );
    }

    if (this.state.showTenderSelect) {
      return (
        <TenderSelect
          tenders={store.availableTenders}
          storeCreditAvailable={this.storeCreditAvailable(
            remainingBalance,
            remainingStoreCredit
          )}
          onTenderSelect={this.selectTender}
          onClose={() => this.showTenderSelect(false)}
        />
      );
    }

    if (this.state.selectedTender) {
      return (
        <TenderPayment
          tenderType={this.state.selectedTender}
          remainingStoreCredit={remainingStoreCredit}
          remainingBalance={remainingBalance}
          onAddTender={this.addTender}
          onClose={() => this.selectTender(undefined)}
        />
      );
    }

    return (
      <>
        <div className="modal display-block">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Checkout</p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.props.handleClose}
              >
                <i className="fal fa-times"></i> Close
              </button>
            </header>
            <section className="modal-card-body">
              <div className="multiTenderCheckout__balance">
                Sale Total:<em>{fC(store.total)}</em>
              </div>
              <div className="multiTenderCheckout__balance">
                Amount received: <em>{fC(totalTendered)}</em>
              </div>
              <div className="multiTenderCheckout__balance">
                Amount outstanding: <em>{fC(Math.max(0, remainingBalance))}</em>
              </div>
              <div className="multiTenderCheckout__tenders">
                {store.tenders?.map((tender, index) => {
                  if (tender.amount === 0 || tender.amount === "0.00")
                    return null;
                  return (
                    <div key={index} className="multiTenderCheckout__tender">
                      <span className="multiTenderCheckout__tenderName">
                        {remapTenderName(tender.type)}
                      </span>
                      <em className="multiTenderCheckout__tenderAmount">
                        {fC(tender.amount)}
                      </em>
                      <button
                        className="multiTenderCheckout__tenderRemove"
                        data-testid="tenderRemove"
                        onClick={() => this.removeTender(index)}
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    </div>
                  );
                })}
              </div>
              {store.changeDue > 0 ? (
                <>
                  <hr />
                  <div className="multiTenderCheckout__balance multiTenderCheckout__balance--changeDue">
                    Change Due: <em>{fC(store.changeDue)}</em>
                  </div>
                </>
              ) : null}
            </section>
            <footer className="modal-card-foot">
              <div>
                <button
                  className={`modalBtn action multiTenderCheckout__tenderAdd ${
                    remainingBalance > 0
                      ? ""
                      : "multiTenderCheckout__tenderAdd--optional"
                  }`}
                  onClick={() => this.showTenderSelect(true)}
                >
                  <i className="fal fa-plus" />
                  Add Payment
                </button>
              </div>
              <button
                className="modalBtn cancel"
                onClick={() => {
                  store.resetTenders();
                  this.props.handleClose();
                }}
              >
                <i className="fal fa-times"></i> Cancel
              </button>
              <button
                className="modalBtn action"
                disabled={remainingBalance > 0}
                onClick={store.processPayments}
              >
                Finalize Sale <i className="fas fa-caret-right"></i>
              </button>
            </footer>
          </div>
        </div>
        {/* {this.props.posStore.processingActive ? <CreditProcess /> : null} */}
      </>
    );
  }
}

export default MultiTenderCheckout;

import React, { Component, Fragment, createRef } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { SettingsMenuStructure } from "../../menuStructures";
import { Loader, Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import {
  fetchProductList,
  fetchWPNReportProducts,
  saveWPNReportProducts,
} from "../../api/rest/settings";

@inject("store")
@observer
class ReportSettings extends Component {
  @observable isLoading = true;
  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable updatingReportSettings = false;
  @action setUpdatingReportSettings = (updating) => {
    this.updatingReportSettings = updating;
  };

  @observable reportSettings;
  @action setReportSettings = (settings) => {
    this.reportSettings = settings;
  };

  @observable savingSettings;
  @action setSavingSettings = (saving) => {
    this.savingSettings = saving;
  };

  @observable availableProducts = [];
  @action setAvailableProducts = (products) => {
    this.availableProducts = products;
  };

  @observable selectedProducts = [];
  @action setSelectedProducts = (products) => {
    this.selectedProducts = products;
  };

  constructor(props) {
    super(props);
    this.formRef = createRef();
  }

  componentDidMount() {
    document.title = "Report Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.loadAvailableProducts()
      .then(() => {
        return this.loadSelectedProducts();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load products",
          "There was an error retrieving your list of selected products. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  loadAvailableProducts = async () => {
    try {
      const result = await fetchProductList();
      this.setAvailableProducts(
        result.filter((product) => product && product.length)
      );
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load products",
        "There was an error retrieving the list of available products. Please try again"
      );
    }
  };

  loadSelectedProducts = async () => {
    try {
      const result = await fetchWPNReportProducts();
      if (result.settingValue) {
        const products = JSON.parse(result.settingValue);
        this.setSelectedProducts(products);
      }
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load products",
        "There was an error retrieving your selected products. Please try again"
      );
    }
  };

  updateWPNProducts = (event) => {
    const { checked, name } = event.target;
    let updatedProducts;
    if (checked) {
      updatedProducts = [...this.selectedProducts, name];
    } else {
      updatedProducts = this.selectedProducts.filter(
        (product) => product !== name
      );
    }
    this.setSelectedProducts(updatedProducts);
  };

  saveSelectedProducts = (event) => {
    event.preventDefault();
    this.setSavingSettings(true);
    saveWPNReportProducts(this.selectedProducts)
      .then(() => {
        this.props.store.MainStore.toast("Report settings updated");
        this.setUpdatingReportSettings(false);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save products",
          "There was an error submitting your selected products. Please try again"
        );
      })
      .finally(() => {
        this.setSavingSettings(false);
      });
  };

  render() {
    if (this.isLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Report Settings</span>
            <button
              className="btn1"
              type="button"
              onClick={() =>
                this.setUpdatingReportSettings(!this.updatingReportSettings)
              }
            >
              {this.updatingReportSettings ? "Cancel" : "Edit Settings"}
            </button>
          </h2>
        </div>
        <div className="report-setting infoInputPages">
          <form
            ref={this.formRef}
            onSubmit={this.saveSelectedProducts}
            noValidate
          >
            <Spinner isLoading={this.savingSettings}>
              <h3>WPN Report</h3>
              <p>
                The following product types will be used to generate your WPN
                Report:
              </p>
              {this.updatingReportSettings ? (
                <Fragment>
                  {this.availableProducts.map((product, index) => (
                    <div className="row" key={index}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name={product}
                          onChange={this.updateWPNProducts}
                          checked={this.selectedProducts.includes(product)}
                        />
                        <span className="checkmark"></span>
                      </label>{" "}
                      {product}
                    </div>
                  ))}
                  <button className="btn">Save Changes</button>
                </Fragment>
              ) : (
                <Fragment>
                  <ul>
                    {this.selectedProducts.map((product, index) => (
                      <li key={index}>{product}</li>
                    ))}
                    {this.selectedProducts.length === 0 && (
                      <li key="none">You currency have no selected products</li>
                    )}
                  </ul>
                </Fragment>
              )}
            </Spinner>
          </form>
        </div>
      </Fragment>
    );
  }
}

ReportSettings.propTypes = { store: PropTypes.object };

export default ReportSettings;

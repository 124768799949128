import React from "react";

interface ProductProps {
  listIndex: number;
  syncName: string;
  product: {
    tcgImage: string;
    title: string;
    variants: unknown[];
    img: string;
    collectorNumber: string;
  };
  children: React.ReactChild;
}

function Product(props: ProductProps) {
  const { listIndex, product, syncName, children } = props;
  const { tcgImage, title, img, collectorNumber } = product;
  const [productTitle, productSetFull] = title.split("[");
  const productSet = productSetFull?.replace("]", "");

  return (
    <div key={listIndex} className="container-fluid">
      <div className="row">
        <div className="line-item">
          <div className="product-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="imgWrapper">
                    <img
                      src={tcgImage && tcgImage.length > 0 ? tcgImage : img}
                    />
                  </div>
                  <div className="productTitle">
                    {productTitle}
                    <span>{productSet}</span>
                    {collectorNumber && length > 0 ? (
                      <span># {collectorNumber}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="integration-variants-info">
            <div className="variant-select-titles">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-2">Variant</div>
                  <div className="col-sm-7">
                    <div className="row">
                      <div className="col-sm-1">Enable sync</div>
                      <div className="col-sm-3">Price Markup Percentage</div>
                      <div className="col-sm-2">Reserve Stock</div>
                      <div className="col-sm-3">
                        Maximum to list on {syncName}
                      </div>
                      <div className="col-sm-3">Price Override</div>
                    </div>
                  </div>
                  <div className="col-sm-3">Actions</div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;

import React, { Component } from "react";
import TimeUtils from "../../utils/TimeUtils";
import { inject, observer } from "mobx-react";
import ReactToPrint from "react-to-print";
import Big from "big.js";

@inject("posStore")
@observer
class Receipt extends Component {
  render() {
    const fC = this.props.posStore.fCurr;
    const store = this.props.posStore;
    return (
      <div className="receipt">
        <div className="wrapper">
          <span className="name">{store.storeInfo?.storeName}</span>
          <span className="details">Tel: {store.storeInfo?.phoneNumber}</span>
          <span className="details">{store.storeInfo?.addressLine1}</span>
          <span className="details">{store.storeInfo?.addressLine2}</span>
          <span className="details">
            {store.storeInfo?.city} {store.storeInfo?.state}{" "}
          </span>
          <span className="details">
            {store.storeInfo?.country} {store.storeInfo?.zipCode}{" "}
          </span>
          {store.taxNumber ? (
            <span className="taxNumber">
              {store.taxWording + " " + store.taxNumber}
            </span>
          ) : (
            ""
          )}
          {store.selectedCustomer ? (
            <span className="saleId">
              {store.selectedCustomer.firstName}{" "}
              {store.selectedCustomer.lastName}
              <em>
                Credit:{" "}
                {fC(
                  (
                    store.selectedCustomer.storeCredit -
                    store.tenders
                      .filter(
                        (tender) => tender.type.toLowerCase() === "store credit"
                      )
                      .reduce(
                        (acc, payment) => acc + parseFloat(payment.amount),
                        0
                      )
                  ).toFixed(2)
                )}
              </em>
            </span>
          ) : (
            ""
          )}
          <span className="saleId">
            Sale# {store?.cartId}
            <em>Till: {store?.tillId}</em>
          </span>
          {store.completedReceipt ? (
            <span className="dateTime">
              {TimeUtils.nowDate()}
              <em>{TimeUtils.nowTime()}</em>
            </span>
          ) : (
            <span className="dateTime">
              CART NOT YET SUBMITTED
              <em>CART NOT YET SUBMITTED</em>
            </span>
          )}
          <hr />
          <table>
            <tbody>
              {store.cart.map((item, index) => (
                <tr key={index}>
                  <td className="qty">{item.qty}</td>
                  <td className="itemTitle">
                    {item.title}
                    <em>
                      {["-", "default title"].includes(
                        item?.variantTitle?.toLowerCase()
                      )
                        ? ""
                        : " - [" + item.variantTitle + "]"}
                    </em>
                  </td>
                  <td className="price">
                    {item.discountAmount ? (
                      <del>{fC(item.actualPrice)}</del>
                    ) : null}
                    <a>{fC(item.price)}</a>
                    <em className="lineTotal">{fC(item.lineTotal)}</em>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          {store.completedReceipt ? (
            <span className="itemTotalCount">
              ** {store.totalItems} Items purchased on this sale **
            </span>
          ) : (
            <span className="itemTotalCount">
              ** {store.totalItems} Items on this draft **
            </span>
          )}

          {parseFloat(store.globalDiscount.amount) ? (
            <span className="subTotal">
              Discount{" "}
              {store.globalDiscount.type == "percentage"
                ? "-" + store.globalDiscount.amount + "%"
                : "-$" + store.globalDiscount.amount}
              <em>{fC(store.discountAmount)}</em>
            </span>
          ) : (
            ""
          )}
          <span className="subTotal">
            SubTotal <em>{fC((store.total - store.taxTotal).toFixed(2))}</em>
          </span>

          <span className="GST">
            {store.taxWording}({store.taxRateDisplay}%)
            <em>{fC(store.taxTotal.toFixed(2))}</em>
          </span>
          {
            <React.Fragment>
              {store.integratedPaymentData.map((card) => (
                <span className="GST" key={card.id}>
                  Credit Card Surcharge
                  <em>{fC(card?.surchargeFee)}</em>
                </span>
              ))}
              <span className="total">
                Total:
                <em>
                  {fC(
                    store.integratedPaymentData
                      .reduce(
                        (bin, card) => Big(bin).plus(Big(card.surchargeFee)),
                        Big(0)
                      )
                      .plus(Big(store.total))
                  )}
                </em>
              </span>
            </React.Fragment>
          }
          <hr />
          {store.tenders.map((tender, index) => (
            <React.Fragment key={index}>
              {tender.amount != 0 ? (
                <span className="subTotal smaller">
                  {tender.type == "Credit" ? "Credit Card" : tender.type}:{" "}
                  <em>{fC(tender.amount)}</em>
                </span>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
          {store.changeDue ? (
            <span className="subTotal">
              Change: <em>{fC(store.changeDue)}</em>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

@inject("posStore")
@observer
class ReceiptModal extends Component {
  render() {
    const store = this.props.posStore;

    return (
      <div className={store.receiptModal ? "modal is-active" : "modal"}>
        <div className="modal-background" />
        <div className="modal-card receiptSize">
          <header className="modal-card-head">
            <p className="modal-card-title">Receipt Preview</p>
            <button
              className="delete"
              aria-label="close"
              onClick={store.closeReceipt}
            >
              <i className="fal fa-times"></i> Close
            </button>
          </header>
          <section className="modal-card-body">
            <Receipt ref={(el) => (this.componentRef = el)} />
          </section>
          <footer className="modal-card-foot">
            <ReactToPrint
              trigger={() => (
                <button className={"modalBtn action full"}>Print</button>
              )}
              content={() => this.componentRef}
            />
          </footer>
        </div>
      </div>
    );
  }
}

export default ReceiptModal;

import React from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { TimeUtils } from "../../utils";

@inject("store")
@observer
export default class QueueLine extends React.Component {
  @observable jobQueueItem;
  @action setJobQueueItem(jobQueueItem) {
    this.jobQueueItem = jobQueueItem;
  }

  @observable queueInterval;
  @action setQueueInterval(queueInterval) {
    this.queueInterval = queueInterval;
  }

  constructor(props) {
    super(props);
    this.setJobQueueItem(this.props.jobQueueItem);
    this.keepPollingForStatus();
  }

  componentWillUnmount() {
    clearInterval(this.queueInterval);
  }

  buildFieldsUpdate(fieldsUpdated, type) {
    var fieldsUpdatedString = "";
    if (type != "variantUpdate") {
      if (fieldsUpdated.stock || fieldsUpdated.stock == 0) {
        if (fieldsUpdated.stockUpdateType == "add") {
          if (this.jobQueueItem.status == "COMPLETED") {
            fieldsUpdatedString += "Stock added: ";
          } else {
            fieldsUpdatedString += "Adding stock: ";
          }
        } else if (fieldsUpdated.stockUpdateType == "remove") {
          if (this.jobQueueItem.status == "COMPLETED") {
            fieldsUpdatedString += "Stock removed: ";
          } else {
            fieldsUpdatedString += "Removing stock: ";
          }
        } else {
          if (this.jobQueueItem.status == "COMPLETED") {
            fieldsUpdatedString += "Stock set to: ";
          } else {
            fieldsUpdatedString += "Setting stock to: ";
          }
        }
        fieldsUpdatedString += fieldsUpdated.stock + ", ";
      }
      if (fieldsUpdated.priceOverride || fieldsUpdated.priceOverride == 0) {
        fieldsUpdatedString +=
          "Price update: " +
          this.props.store.MainStore.currencyBuilder(
            parseFloat(fieldsUpdated.priceOverride)
          ) +
          ", ";
      }
    }
    if (fieldsUpdated.cashBuyPrice || fieldsUpdated.cashBuyPrice == 0) {
      fieldsUpdatedString +=
        "Cash buy price: " +
        this.props.store.MainStore.currencyBuilder(
          parseFloat(fieldsUpdated.cashBuyPrice)
        ) +
        ", ";
    }
    if (fieldsUpdated.cashBuyPriceRemove) {
      fieldsUpdatedString += "Cash buy price will be removed, ";
    }
    if (
      fieldsUpdated.cashBuyPercentage ||
      fieldsUpdated.cashBuyPercentage == 0
    ) {
      fieldsUpdatedString +=
        "Cash buy percent: " + fieldsUpdated.cashBuyPercentage + "%, ";
    }
    if (fieldsUpdated.cashBuyPercentageRemove) {
      fieldsUpdatedString += "Cash buy percent will be removed, ";
    }
    if (fieldsUpdated.creditBuyPrice || fieldsUpdated.creditBuyPrice == 0) {
      fieldsUpdatedString +=
        "Credit buy price: " +
        this.props.store.MainStore.currencyBuilder(
          parseFloat(fieldsUpdated.creditBuyPrice)
        ) +
        ", ";
    }
    if (fieldsUpdated.creditBuyPriceRemove) {
      fieldsUpdatedString += "Credit buy price will be removed, ";
    }
    if (
      fieldsUpdated.creditBuyPercentage ||
      fieldsUpdated.creditBuyPercentage == 0
    ) {
      fieldsUpdatedString +=
        "Credit buy percent: " + fieldsUpdated.creditBuyPercentage + "%, ";
    }
    if (fieldsUpdated.creditBuyPercentageRemove) {
      fieldsUpdatedString += "Credit buy percent will be removed, ";
    }
    if (fieldsUpdated.buyLimit || fieldsUpdated.buyLimit == 0) {
      fieldsUpdatedString += "Buy limit: " + fieldsUpdated.buyLimit + ", ";
    }
    if (fieldsUpdated.buyLimitRemove) {
      fieldsUpdatedString += "Buy limit will be removed, ";
    }
    if (
      fieldsUpdated.maxInstockBuyPrice ||
      fieldsUpdated.maxInstockBuyPrice == 0
    ) {
      fieldsUpdatedString +=
        "Over-limit Cash buy price: " +
        this.props.store.MainStore.currencyBuilder(
          parseFloat(fieldsUpdated.maxInstockBuyPrice)
        ) +
        ", ";
    }
    if (fieldsUpdated.maxInstockBuyPriceRemove) {
      fieldsUpdatedString += "Over-limit Cash buy price will be removed, ";
    }
    if (
      fieldsUpdated.maxInstockBuyPercentage ||
      fieldsUpdated.maxInstockBuyPercentage == 0
    ) {
      fieldsUpdatedString +=
        "Over-limit Cash buy percent: " +
        fieldsUpdated.maxInstockBuyPercentage +
        "%, ";
    }
    if (fieldsUpdated.maxInstockBuyPercentageRemove) {
      fieldsUpdatedString += "Over-limit Cash buy percent will be removed, ";
    }
    if (
      fieldsUpdated.maxInstockCreditBuyPrice ||
      fieldsUpdated.maxInstockCreditBuyPrice == 0
    ) {
      fieldsUpdatedString +=
        "Over-limit Credit buy price: " +
        this.props.store.MainStore.currencyBuilder(
          parseFloat(fieldsUpdated.maxInstockCreditBuyPrice)
        ) +
        ", ";
    }
    if (fieldsUpdated.maxInstockCreditBuyPriceRemove) {
      fieldsUpdatedString += "Over-limit Credit buy price will be removed, ";
    }
    if (
      fieldsUpdated.maxInstockCreditBuyPercentage ||
      fieldsUpdated.maxInstockCreditBuyPercentage == 0
    ) {
      fieldsUpdatedString +=
        "Over-limit Credit buy percent: " +
        fieldsUpdated.maxInstockCreditBuyPercentage +
        "%, ";
    }
    if (fieldsUpdated.maxInstockCreditBuyPercentageRemove) {
      fieldsUpdatedString += "Over-limit Credit buy percent will be removed, ";
    }
    return fieldsUpdatedString.substring(0, fieldsUpdatedString.length - 2);
  }

  buildSearchString(searchFilters) {
    var searchUpdated = "";
    if (searchFilters.game && searchFilters.game.length > 0) {
      searchUpdated += "Game: " + searchFilters.game + " > ";
    }
    if (searchFilters.title && searchFilters.title.length > 0) {
      searchUpdated += "Title: " + searchFilters.title + " > ";
    }
    if (searchFilters.productTypes && searchFilters.productTypes.length > 0) {
      searchUpdated +=
        "Product Types: " +
        this.buildListForSearchFilters(searchFilters.productTypes) +
        " > ";
    }
    if (searchFilters.vendors && searchFilters.vendors.length > 0) {
      searchUpdated +=
        "Vendors: " +
        this.buildListForSearchFilters(searchFilters.vendors) +
        " > ";
    }
    if (searchFilters.tags && searchFilters.tags.length > 0) {
      searchUpdated +=
        "Tags: " + this.buildListForSearchFilters(searchFilters.tags) + " > ";
    }
    if (searchFilters.setNames && searchFilters.setNames.length > 0) {
      searchUpdated +=
        "Set Names: " +
        this.buildListForSearchFilters(searchFilters.setNames) +
        " > ";
    }
    if (searchFilters.variants && searchFilters.variants.length > 0) {
      searchUpdated +=
        "Variants: " +
        this.buildListForSearchFilters(searchFilters.variants) +
        " > ";
    }
    if (searchFilters.colors && searchFilters.colors.length > 0) {
      searchUpdated +=
        "Colors: " +
        this.buildListForSearchFilters(searchFilters.colors) +
        " > ";
    }
    if (searchFilters.types && searchFilters.types.length > 0) {
      searchUpdated +=
        "Types: " + this.buildListForSearchFilters(searchFilters.types) + " > ";
    }
    if (searchFilters.rarities && searchFilters.rarities.length > 0) {
      searchUpdated +=
        "Rarities: " +
        this.buildListForSearchFilters(searchFilters.rarities) +
        " > ";
    }
    if (searchFilters.monsterTypes && searchFilters.rarities.monsterTypes > 0) {
      searchUpdated +=
        "Monster types: " +
        this.buildListForSearchFilters(searchFilters.monsterTypes) +
        " > ";
    }
    if (searchFilters.priceGreaterThan || searchFilters.priceGreaterThan == 0) {
      searchUpdated += "Price > " + searchFilters.priceGreaterThan + " > ";
    }
    if (searchFilters.priceLessThan || searchFilters.priceLessThan == 0) {
      searchUpdated += "Price < " + searchFilters.priceLessThan + " > ";
    }
    if (
      searchFilters.overallQuantityGreaterThan ||
      searchFilters.overallQuantityGreaterThan == 0
    ) {
      searchUpdated +=
        "Overall Quantity > " +
        searchFilters.overallQuantityGreaterThan +
        " > ";
    }
    if (
      searchFilters.overallQuantityLessThan ||
      searchFilters.overallQuantityLessThan == 0
    ) {
      searchUpdated +=
        "Overall Quantity < " + searchFilters.overallQuantityLessThan + " > ";
    }
    if (
      searchFilters.quantityGreaterThan ||
      searchFilters.quantityGreaterThan == 0
    ) {
      searchUpdated +=
        "Quantity > " + searchFilters.quantityGreaterThan + " > ";
    }
    if (searchFilters.quantityLessThan || searchFilters.quantityLessThan == 0) {
      searchUpdated += "Quantity < " + searchFilters.quantityLessThan + " > ";
    }
    if (searchFilters.barcode && searchFilters.barcode.length > 0) {
      searchUpdated += "Barcode: " + searchFilters.barcode + " > ";
    }
    if (searchFilters.sku && searchFilters.sku.length > 0) {
      searchUpdated += "Sku: " + searchFilters.sku + " > ";
    }
    return searchUpdated.substring(0, searchUpdated.length - 2);
  }

  buildListForSearchFilters(list) {
    var listOfItemsToReturn = "[";
    list.forEach((item) => {
      listOfItemsToReturn += item + ", ";
    });
    listOfItemsToReturn = listOfItemsToReturn.substring(
      0,
      listOfItemsToReturn.length - 2
    );
    listOfItemsToReturn += "]";
    return listOfItemsToReturn;
  }

  getQueueType(type) {
    if (type == "priceUpdate") {
      return "Price update";
    }
    if (type == "stockUpdate") {
      return "Stock update";
    }
    if (type == "bulkUpdate") {
      return "Bulk update";
    }
    if (type == "variantUpdate") {
      return "Variant update";
    }
  }

  keepPollingForStatus() {
    if (this.jobQueueItem.status != "COMPLETED" && !this.queueInterval) {
      var interval = setInterval(() => {
        this.props.store.ProductsStore.fetchQueuedJobStatus(
          this.jobQueueItem.jobId,
          this.jobQueueItem.type
        )
          .then((result) => {
            if (result.status == "COMPLETED") {
              clearInterval(this.queueInterval);
            }
            this.setJobQueueItem(result);
          })
          .catch((err) => {
            console.error(err);
          });
      }, 5000);
      this.setQueueInterval(interval);
    }
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    let searchFilters;
    if (
      this.jobQueueItem.searchFilters &&
      this.jobQueueItem.searchFilters.length > 0
    ) {
      searchFilters = JSON.parse(this.jobQueueItem.searchFilters);
    }
    let fieldsUpdated;
    if (
      this.jobQueueItem.fieldsUpdated &&
      this.jobQueueItem.fieldsUpdated.length > 0
    ) {
      fieldsUpdated = JSON.parse(this.jobQueueItem.fieldsUpdated);
    }
    return (
      <React.Fragment>
        <div
          className={
            this.jobQueueItem && this.jobQueueItem.status == "COMPLETED"
              ? "queueLine"
              : "queueLine inProgress"
          }
        >
          <div className="queueJobHeader">
            <div className="container-fluid">
              <div className="row">
                {this.jobQueueItem.jobId && this.jobQueueItem.jobId != 0 ? (
                  <div className="col-xl-2">
                    Job ID: <em>{this.jobQueueItem.jobId}</em>
                  </div>
                ) : (
                  <div className="col-xl-2">
                    ID: <em>{this.jobQueueItem.id}</em>
                  </div>
                )}
                <div className="col-xl-2">
                  Type: <em>{this.getQueueType(this.jobQueueItem.type)}</em>
                </div>
                <div className="col-xl-2">
                  Status: <em>{this.jobQueueItem.status}</em>
                </div>
                <div className="col-xl-3">
                  Started:{" "}
                  <em>
                    {this.jobQueueItem.readableDateProcessed &&
                    this.jobQueueItem.readableDateProcessed.length > 0
                      ? TimeUtils.convertDateToHumanReadable(
                          this.jobQueueItem.readableDateProcessed
                        )
                      : ""}
                  </em>
                </div>
                <div className="col-xl-3">
                  Completed:{" "}
                  <em>
                    {this.jobQueueItem.readableDateCompleted &&
                    this.jobQueueItem.readableDateCompleted.length > 0
                      ? TimeUtils.convertDateToHumanReadable(
                          this.jobQueueItem.readableDateCompleted
                        )
                      : ""}
                  </em>
                </div>
              </div>
            </div>
          </div>

          {this.jobQueueItem.productName && this.jobQueueItem.variantName ? (
            <React.Fragment>
              <div className="info">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="searchFilters">
                        Updated{" "}
                        <em>
                          {this.jobQueueItem.productName} -{" "}
                          {this.jobQueueItem.variantName}
                        </em>
                      </div>
                    </div>
                    {this.jobQueueItem.priceBefore != undefined &&
                    this.jobQueueItem.priceAfter != undefined ? (
                      <React.Fragment>
                        <div className="col-md-6">
                          <div className="updates">
                            <div className="queueAdjustment">
                              <strong>Price before:</strong>{" "}
                              {fC(this.jobQueueItem.priceBefore)}
                            </div>
                            <div className="queueAdjustment">
                              <strong>Price after:</strong>{" "}
                              {fC(this.jobQueueItem.priceAfter)}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    {this.jobQueueItem.stockBefore != undefined &&
                    this.jobQueueItem.stockAfter != undefined ? (
                      <React.Fragment>
                        <div className="col-md-6">
                          <div className="updates">
                            <div className="queueAdjustment">
                              <strong>Stock before:</strong>{" "}
                              {this.jobQueueItem.stockBefore}
                            </div>
                            <div className="queueAdjustment">
                              <strong>Stock after:</strong>{" "}
                              {this.jobQueueItem.stockAfter}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div></div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
          {!this.jobQueueItem.productName && !this.jobQueueItem.variantName ? (
            <div className="info">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    {searchFilters ? (
                      <div className="searchFilters">
                        Search Filters:{" "}
                        <em>{this.buildSearchString(searchFilters)}</em>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    {fieldsUpdated ? (
                      <div className="updates">
                        Updates applied:{" "}
                        <em>
                          {this.buildFieldsUpdate(
                            fieldsUpdated,
                            this.jobQueueItem.type
                          )}
                        </em>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="progress">
            <div
              className={
                this.jobQueueItem.jobQueueStatus
                  ? "progress-bar progress-bar-striped"
                  : "progress-bar"
              }
              role="progressbar"
              aria-valuenow={
                this.jobQueueItem.jobQueueStatus
                  ? this.jobQueueItem.jobQueueStatus.progress + "%"
                  : "100.00%"
              }
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: this.jobQueueItem.jobQueueStatus
                  ? this.jobQueueItem.jobQueueStatus.progress + "%"
                  : "100.00%",
              }}
            >
              {this.jobQueueItem.jobQueueStatus
                ? this.jobQueueItem.jobQueueStatus.progress + "% LOADED"
                : "100.00% LOADED"}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

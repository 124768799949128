import { observable, action, computed } from "mobx";
import { API_STATE } from "../constants/api";
import { mixedSearchCompareFunction, objectDive } from "../utils/data";
import { SortOrder } from "react-base-table";

class ReportsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable apiState = API_STATE.INITIAL;

  @action setApiState = (state) => {
    this.apiState = state;
  };

  @action reset = () => {
    this.apiState = API_STATE.INITIAL;
    this.totalRowCount = 0;
    this.sourceData = [];
    this.tableRowCount = 0;
    this.tableData = [];
    this.currentPage = 0;
    this.filters = [];
    this.filterValues = {};
  };

  @observable totalRowCount = 0;

  @observable sourceData = [];

  @action setSourceData = (data) => {
    if (!data) {
      data = [];
    }
    this.sourceData = data;
    this.totalRowCount = data.length;
    this.setTableData(data);
  };

  @observable tableRowCount = 0;

  @observable tableData = [];

  @action setTableData = (data) => {
    if (!data) {
      data = [];
    }
    this.tableData = data;
    this.tableRowCount = data.length;
  };

  @observable sortColumn = {};

  @action setSortColumn = (sort) => {
    const { key, order, column } = sort;
    const { dataKey, dataGetter } = column;
    this.sortColumn = {
      key,
      order,
      dataKey,
      dataGetter,
    };
  };

  @observable rowsPerPage = 20;

  @action setRowsPerPage = (rows) => {
    this.rowsPerPage = rows;
    this.recalculateCurrentPage();
  };

  @observable currentPage = 0;

  @action setCurrentPage = (page) => {
    this.currentPage = page;
  };

  @observable filters = [];

  @action setFilters = (filters) => {
    this.filters = filters;
  };

  @observable filterValues = {};

  @action setFilterValues = (values) => {
    this.filterValues = values;
  };

  @action updateFilterValue = (key, value) => {
    this.filterValues[key] = value;
    this.setTableData(this.filteredData(this.sourceData));
    this.recalculateCurrentPage();
  };

  @action recalculateCurrentPage = () => {
    const maxPage = Math.ceil(this.tableRowCount / this.rowsPerPage);
    if (this.currentPage >= maxPage) {
      const newPage = maxPage > 0 ? maxPage - 1 : 0;
      this.setCurrentPage(newPage);
    }
  };

  sortedData = (data) => {
    const { dataGetter, dataKey, order } = this.sortColumn;
    if (!dataKey && !dataGetter) {
      return data;
    }
    const sortedData = data.slice().sort((a, b) => {
      if (dataGetter) {
        return mixedSearchCompareFunction(
          dataGetter({ rowData: a }),
          dataGetter({ rowData: b })
        );
      }
      return mixedSearchCompareFunction(
        objectDive(a, dataKey),
        objectDive(b, dataKey)
      );
    });
    if (order === SortOrder.DESC) {
      sortedData.reverse();
    }
    return sortedData;
  };

  filteredData = (data) => {
    let filteredData = data;
    this.filters.forEach(({ condition, filterFunction }) => {
      if (condition(this.filterValues)) {
        filteredData = filteredData.filter((row) =>
          filterFunction(row, this.filterValues)
        );
      }
    });
    return filteredData;
  };

  paginatedData = (data) => {
    if (!data) {
      return [];
    }
    if (
      this.currentPage >= 0 &&
      this.currentPage < Math.ceil(data.length / this.rowsPerPage)
    ) {
      return data.slice(
        this.currentPage * this.rowsPerPage,
        (this.currentPage + 1) * this.rowsPerPage
      );
    }
    return [];
  };

  @computed({ keepAlive: true }) get computedTableData() {
    const sortedData = this.sortedData(this.tableData);
    const paginatedData = this.paginatedData(sortedData);
    return paginatedData;
  }

  @observable overrideTableHeight = 0;

  @action setOverrideTableHeight = (height) => {
    this.overrideTableHeight = height;
  };

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };
}

export default ReportsStore;

import { localSettingsKey } from "../constants/settings";

export const parseSetting = (setting: { settingValue: string }) => {
  try {
    return JSON.parse(setting.settingValue);
  } catch (_error) {
    return null;
  }
};

export const saveSettingsToLocalStorage = (settings: LocalSettings) => {
  try {
    localStorage.setItem(localSettingsKey, JSON.stringify(settings));
  } catch (error) {
    // setItem() may throw an exception if the storage is full. Particularly,
    // in Mobile Safari (since iOS 5) it always throws when the user enters
    // private mode. Not much we can really do about it.
  }
};

export const loadSettingsFromLocalStorage = (): LocalSettings => {
  const serialisedSettings = localStorage.getItem(localSettingsKey);
  if (!serialisedSettings) {
    return {};
  }
  try {
    return JSON.parse(serialisedSettings);
  } catch (error) {
    // If the data in storage is not valid JSON, we may as well clear it
    try {
      localStorage.removeItem(localSettingsKey);
    } catch (err) {
      // we tried
    }
    return {};
  }
};

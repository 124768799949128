import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { toast } from "react-toastify";
import { notificationParameters } from "../../constants/notifications";

const isNil = (value, zeroIsOkay = false) => {
  if (value === undefined) return true;
  if (value === null) return true;
  // TODO:: Find out why some values are false. It makes no sense
  if (value === false) return true;
  if (value === "") return true;
  if (zeroIsOkay && value === 0) return true;
  return false;
};

const areSame = (fieldValue, originalValue, zeroIsOkay = false) => {
  if (isNil(fieldValue, zeroIsOkay) && isNil(originalValue, zeroIsOkay)) {
    return true;
  }
  return fieldValue === originalValue;
};

@inject("store")
@observer
export default class ProductLineVariantItem extends React.Component {
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.updateVariant();
    }
    if (event.key === "w" || event.key === "W") {
      event.preventDefault();
      if (this.parentLineItemId == 0 && this.currentLineItemId == 0) {
        return;
      }
      if (this.currentLineItemId - 1 == -1) {
        var foundLines = document.querySelectorAll(
          "[id^=variantline-" + (this.parentLineItemId - 1) + "-]"
        );
        document
          .querySelector(
            "#variantlineitem-" +
              (this.parentLineItemId - 1) +
              "-" +
              (foundLines.length - 1) +
              "-" +
              this.props.currentInput()
          )
          .focus();
      } else {
        document
          .querySelector(
            "#variantlineitem-" +
              this.parentLineItemId +
              "-" +
              (this.currentLineItemId - 1) +
              "-" +
              this.props.currentInput()
          )
          .focus();
      }
    }
    if (event.key === "s" || event.key === "S") {
      event.preventDefault();
      var foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          (this.currentLineItemId + 1) +
          "-" +
          this.props.currentInput()
      );
      if (foundElement) {
        foundElement.focus();
      } else {
        foundElement = document.querySelector(
          "#variantlineitem-" +
            (this.parentLineItemId + 1) +
            "-0-" +
            this.props.currentInput()
        );
        if (foundElement) {
          foundElement.focus();
        }
      }
    }
    if (event.key === "a" || event.key === "A") {
      event.preventDefault();
      this.determineIfVisibleAndFocusLeft(this.props.currentInput() - 1);
    }
    if (event.key === "d" || event.key === "D") {
      event.preventDefault();
      this.determineIfVisibleAndFocusRight(this.props.currentInput() + 1);
    }
  };

  determineIfVisibleAndFocusRight(inputNumber) {
    if (inputNumber < 12) {
      var foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          this.currentLineItemId +
          "-" +
          inputNumber
      );
      if (foundElement) {
        if (
          foundElement.offsetWidth ||
          foundElement.offsetHeight ||
          foundElement.getClientRects().length
        ) {
          this.props.updateCurrentInput(inputNumber);
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusRight(inputNumber + 1);
        }
      }
    }
  }

  determineIfVisibleAndFocusLeft(inputNumber) {
    if (inputNumber > 0) {
      var foundElement = document.querySelector(
        "#variantlineitem-" +
          this.parentLineItemId +
          "-" +
          this.currentLineItemId +
          "-" +
          inputNumber
      );
      if (foundElement) {
        if (
          foundElement.offsetWidth ||
          foundElement.offsetHeight ||
          foundElement.getClientRects().length
        ) {
          this.props.updateCurrentInput(inputNumber);
          foundElement.focus();
        } else {
          this.determineIfVisibleAndFocusLeft(inputNumber - 1);
        }
      }
    }
  }

  parentLineItemId;
  currentLineItemId;

  @observable changeHasApplied = false;
  @action setChangeHasApplied(bool) {
    this.changeHasApplied = bool;
  }

  @observable isUpdating = false;
  @action setIsUpdating(bool) {
    this.isUpdating = bool;
  }

  @observable hasUpdated = false;
  @action setHasUpdated(bool) {
    this.hasUpdated = bool;
  }

  constructor(props) {
    super(props);
    this.state = {
      variant: {
        buyLimit: this.props.variant.maxPurchaseQuantity,
        cashBuyPercentage: this.props.variant.cashBuyPercent,
        cashBuyPrice: this.props.variant.cashBuyPrice,
        creditBuyPrice: this.props.variant.storeCreditBuyPrice,
        creditBuyPercentage: this.props.variant.creditBuyPercent,
        id: this.props.variant.id,
        maxInstockBuyPercentage: this.props.variant.maxInstockBuyPercentage,
        maxInstockBuyPrice: this.props.variant.maxInstockBuyPrice,
        maxInstockCreditBuyPrice: this.props.variant.maxInstockCreditBuyPrice,
        maxInstockCreditBuyPercentage:
          this.props.variant.maxInstockCreditBuyPercentage,
        priceOverride: this.props.variant.priceOverride,
        reserveQuantity: this.props.variant.reserveQuantity,
        shopifyId: this.props.variant.shopifyId,
        stock: this.props.variant.quantity,
        stockUpdateType: "set",
      },
    };
  }

  componentDidMount() {
    this.props.setUpdateVariant(this.updateVariant.bind(this));
  }

  returnNullOrValue(value) {
    return value && value.length > 0 ? parseFloat(value) : null;
  }

  hasVariantChanged = () => {
    if (
      !areSame(
        this.state.variant.buyLimit,
        this.props.variant.maxPurchaseQuantity
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.cashBuyPercentage,
        this.props.variant.cashBuyPercent
      )
    )
      return true;
    if (
      !areSame(this.state.variant.cashBuyPrice, this.props.variant.cashBuyPrice)
    )
      return true;
    if (
      !areSame(
        this.state.variant.creditBuyPrice,
        this.props.variant.storeCreditBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.creditBuyPercentage,
        this.props.variant.creditBuyPercent
      )
    )
      return true;

    if (
      !areSame(
        this.state.variant.maxInstockBuyPercentage,
        this.props.variant.maxInstockBuyPercentage
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockBuyPrice,
        this.props.variant.maxInstockBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockCreditBuyPrice,
        this.props.variant.maxInstockCreditBuyPrice
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.maxInstockCreditBuyPercentage !=
          this.props.variant.maxInstockCreditBuyPercentage
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.priceOverride,
        this.props.variant.priceOverride
      )
    )
      return true;
    if (
      !areSame(
        this.state.variant.reserveQuantity,
        this.props.variant.reserveQuantity,
        true
      )
    )
      return true;

    if (!areSame(this.state.variant.stock, this.props.variant.quantity, true))
      return true;
    return false;
  };

  updateVariant(showNotification = false) {
    if (!this.hasVariantChanged()) {
      if (showNotification) {
        toast.warn("No changes to save", notificationParameters);
      }
      return;
    }
    this.setChangeHasApplied(false);
    this.setIsUpdating(true);
    let newStock = 0;
    if (this.state.variant.stock) {
      newStock = this.state.variant.stock;
    }
    const variantToSend = {
      ...this.state.variant,
      stockUpdateType: this.props.getStockUpdateType(),
      stock:
        newStock == this.state.currentStock &&
        this.props.getStockUpdateType == "set"
          ? null
          : newStock,
      reserveQuantity: this.state.variant.reserveQuantity || 0,
    };
    this.props.store.ProductsStore.updateVariant(variantToSend)
      .then((data) => {
        if (showNotification) {
          if (data?.message === "Variant update queued") {
            toast.info(data.message, notificationParameters);
          } else {
            toast.warn(
              "Update may not be queued. Please try again",
              notificationParameters
            );
          }
        }
        this.setIsUpdating(false);
        this.setHasUpdated(true);
        this.setChangeHasApplied(true);
        setTimeout(() => {
          this.setHasUpdated(false);
        }, 2000);
        this.props.onComplete(this.props.variant.id);
      })
      .catch((err) => {
        console.error(err);
        this.setIsUpdating(false);
        this.setHasUpdated(false);
        this.setChangeHasApplied(false);
        this.props.store.MainStore.setError(
          err,
          "Failed to update variant",
          "There was an error updating your selected variant. Please try again"
        );
      });
  }

  onFocus(event, currentInput) {
    event.target.select();
    this.props.updateCurrentInput(currentInput);
  }

  handleVariant(props) {
    const newVariant = { ...this.state.variant, ...props };
    this.props.onChange(newVariant, this.props.variant);
    this.setState({ variant: newVariant });
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    this.parentLineItemId = this.props.parentListIndex;
    this.currentLineItemId = this.props.listIndex;
    return (
      <div
        key={this.props.listIndex}
        id={
          "variantline-" + this.parentLineItemId + "-" + this.currentLineItemId
        }
        className="variantLine"
        data-testid="ProductLineVariantItem"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3 col-xl-1">
              <div className="variantTitle">{this.props.variant.title}</div>
            </div>
            <div className="col-sm-12 col-xl-10 row-wrapper">
              <div className="grouping">
                <div
                  className={
                    "input-group mb-3 " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("stock")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Stock Qty</p>
                  <div className="cluster">
                    <span
                      className="circleBtn minus"
                      onClick={() =>
                        this.handleVariant({
                          stock: this.state.variant.stock - 1,
                        })
                      }
                      title="Decrease stock"
                    >
                      <i className="fal fa-minus"></i>
                    </span>
                    <input
                      onChange={(event) =>
                        this.handleVariant({
                          stock: Number(event.target.value),
                        })
                      }
                      value={Number(this.state.variant.stock)}
                      id={
                        "variantlineitem-" +
                        this.parentLineItemId +
                        "-" +
                        this.currentLineItemId +
                        "-1"
                      }
                      onFocus={(e) => this.onFocus(e, 1)}
                      onKeyPress={this.handleKeyPress}
                      type="number"
                      step="1"
                      className="form-control"
                    />
                    <span
                      className="circleBtn plus"
                      onClick={() =>
                        this.handleVariant({
                          stock: this.state.variant.stock + 1,
                        })
                      }
                      title="Increase stock"
                    >
                      <i className="fal fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "input-group mb-3 " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("reserveStock")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Reserve Qty</p>
                  <div className="cluster">
                    <span
                      className="circleBtn minus"
                      onClick={() =>
                        this.handleVariant({
                          reserveQuantity:
                            this.state.variant.reserveQuantity - 1,
                        })
                      }
                      disabled={this.state.variant.reserveQuantity === 0}
                      title="Decrease reserve quantity"
                    >
                      <i className="fal fa-minus"></i>
                    </span>
                    <input
                      onChange={(event) =>
                        this.handleVariant({
                          reserveQuantity: Number(event.target.value),
                        })
                      }
                      value={Number(this.state.variant.reserveQuantity)}
                      id={
                        "variantlineitem-" +
                        this.parentLineItemId +
                        "-" +
                        this.currentLineItemId +
                        "-1a"
                      }
                      onFocus={(e) => this.onFocus(e, 1)}
                      onKeyPress={this.handleKeyPress}
                      onKeyDown={this.handleKeyDown}
                      type="number"
                      step="1"
                      className="form-control"
                      defaultValue={this.props.variant.reserveQuantity || 0}
                    />
                    <span
                      className="circleBtn plus"
                      onClick={() =>
                        this.handleVariant({
                          reserveQuantity:
                            this.state.variant.reserveQuantity + 1,
                        })
                      }
                      title="Increase reserve quantity"
                    >
                      <i className="fal fa-plus"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="grouping">
                <div
                  className={
                    "currency-input " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("sellPrice")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Sell Price</p>
                  <div className="text-center price">
                    {fC(parseFloat(this.props.variant.price))}
                  </div>
                </div>
                <div
                  className={
                    "currency-input " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("sellPriceOverride")
                      ? ""
                      : " hidden")
                  }
                  style={{ width: "5em" }}
                >
                  <p className="variant-input-header">Sell Override</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        priceOverride: event.target.value || null,
                      })
                    }
                    value={this.state.variant.priceOverride}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-2"
                    }
                    onFocus={(e) => this.onFocus(e, 2)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className="form-control"
                  />
                </div>
                <div
                  className={
                    "text-small " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("cashBuyPrice") ||
                    this.props.columnsToShow.includes("cashBuyPercent")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Cash Buy</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        cashBuyPrice: event.target.value || null,
                      })
                    }
                    value={this.state.variant.cashBuyPrice}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-3"
                    }
                    onFocus={(e) => this.onFocus(e, 3)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control currency-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes("cashBuyPrice")
                        ? this.props.columnsToShow.includes("cashBuyPercent")
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder={this.props.store.MainStore.currency}
                    title="Cash buy"
                  />
                  <span
                    className={
                      this.props.columnsToShow.length == 0 ||
                      (this.props.columnsToShow.includes("cashBuyPrice") &&
                        this.props.columnsToShow.includes("cashBuyPercent"))
                        ? ""
                        : "hidden"
                    }
                  >
                    {" "}
                    or{" "}
                  </span>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        cashBuyPercentage: event.target.value || null,
                      })
                    }
                    value={this.state.variant.cashBuyPercentage}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-4"
                    }
                    onFocus={(e) => this.onFocus(e, 4)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control percentage-input" +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes("cashBuyPercent")
                        ? this.props.columnsToShow.includes("cashBuyPrice")
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder="%"
                    title="Cash buy percentage"
                  />
                </div>
                <div
                  className={
                    "text-small " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("creditBuyPrice") ||
                    this.props.columnsToShow.includes("creditBuyPercent")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Credit Buy</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        creditBuyPrice: event.target.value || null,
                      })
                    }
                    value={this.state.variant.creditBuyPrice}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-5"
                    }
                    onFocus={(e) => this.onFocus(e, 5)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control currency-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes("creditBuyPrice")
                        ? this.props.columnsToShow.includes("creditBuyPercent")
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder={this.props.store.MainStore.currency}
                    title="Credit buy"
                  />
                  <span
                    className={
                      this.props.columnsToShow.length == 0 ||
                      (this.props.columnsToShow.includes("creditBuyPrice") &&
                        this.props.columnsToShow.includes("creditBuyPercent"))
                        ? ""
                        : "hidden"
                    }
                  >
                    {" "}
                    or{" "}
                  </span>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        creditBuyPercentage: event.target.value || null,
                      })
                    }
                    value={this.state.variant.creditBuyPercentage}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-6"
                    }
                    onFocus={(e) => this.onFocus(e, 6)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control  percentage-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes("creditBuyPercent")
                        ? this.props.columnsToShow.includes("creditBuyPrice")
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder="%"
                    title="Credit buy percentage"
                  />
                </div>
              </div>
              <div className="grouping">
                <div
                  className={
                    "quantity-input " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("buyLimit")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Buy Limit</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        buyLimit: event.target.value || null,
                      })
                    }
                    value={this.state.variant.buyLimit}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-7"
                    }
                    onFocus={(e) => this.onFocus(e, 7)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="1"
                    className="form-control"
                    placeholder="∞"
                  />
                </div>
                <div
                  className={
                    "text-small " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes(
                      "overstockCashBuyPrice"
                    ) ||
                    this.props.columnsToShow.includes("overstockCashBuyPercent")
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Over-limit Cash Buy</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        maxInstockBuyPrice: event.target.value || null,
                      })
                    }
                    value={this.state.variant.maxInstockBuyPrice}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-8"
                    }
                    onFocus={(e) => this.onFocus(e, 8)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control currency-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes("overstockCashBuyPrice")
                        ? this.props.columnsToShow.includes(
                            "overstockCashBuyPercent"
                          )
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder={this.props.store.MainStore.currency}
                  />
                  <span
                    className={
                      this.props.columnsToShow.length == 0 ||
                      (this.props.columnsToShow.includes(
                        "overstockCashBuyPrice"
                      ) &&
                        this.props.columnsToShow.includes(
                          "overstockCashBuyPercent"
                        ))
                        ? ""
                        : "hidden"
                    }
                  >
                    {" "}
                    or{" "}
                  </span>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        maxInstockBuyPercentage: event.target.value || null,
                      })
                    }
                    value={this.state.variant.maxInstockBuyPercentage}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-9"
                    }
                    onFocus={(e) => this.onFocus(e, 9)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control  percentage-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes(
                        "overstockCashBuyPercent"
                      )
                        ? this.props.columnsToShow.includes(
                            "overstockCashBuyPrice"
                          )
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder="%"
                  />
                </div>
                <div
                  className={
                    "text-small " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes(
                      "overstockCreditBuyPrice"
                    ) ||
                    this.props.columnsToShow.includes(
                      "overstockCreditBuyPercent"
                    )
                      ? ""
                      : " hidden")
                  }
                >
                  <p className="variant-input-header">Over-limit Credit Buy</p>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        maxInstockCreditBuyPrice: event.target.value || null,
                      })
                    }
                    value={this.state.variant.maxInstockCreditBuyPrice}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-10"
                    }
                    onFocus={(e) => this.onFocus(e, 10)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control currency-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes(
                        "overstockCreditBuyPrice"
                      )
                        ? this.props.columnsToShow.includes(
                            "overstockCreditBuyPercent"
                          )
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder={this.props.store.MainStore.currency}
                  />
                  <span
                    className={
                      this.props.columnsToShow.length == 0 ||
                      (this.props.columnsToShow.includes(
                        "overstockCreditBuyPrice"
                      ) &&
                        this.props.columnsToShow.includes(
                          "overstockCreditBuyPercent"
                        ))
                        ? ""
                        : "hidden"
                    }
                  >
                    {" "}
                    or{" "}
                  </span>
                  <input
                    onChange={(event) =>
                      this.handleVariant({
                        maxInstockCreditBuyPercentage:
                          event.target.value || null,
                      })
                    }
                    value={this.state.variant.maxInstockCreditBuyPercentage}
                    id={
                      "variantlineitem-" +
                      this.parentLineItemId +
                      "-" +
                      this.currentLineItemId +
                      "-11"
                    }
                    onFocus={(e) => this.onFocus(e, 11)}
                    onKeyPress={this.handleKeyPress}
                    type="number"
                    step="0.01"
                    className={
                      "form-control  percentage-input " +
                      (this.props.columnsToShow.length == 0 ||
                      this.props.columnsToShow.includes(
                        "overstockCreditBuyPercent"
                      )
                        ? this.props.columnsToShow.includes(
                            "overstockCreditBuyPrice"
                          )
                          ? " halfInput"
                          : ""
                        : " hidden")
                    }
                    placeholder="%"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-1">
              <div className="row">
                <div
                  className={
                    "col-xl-12 " +
                    (this.props.columnsToShow.length == 0 ||
                    this.props.columnsToShow.includes("updateButton")
                      ? ""
                      : " hidden")
                  }
                >
                  <button
                    tabIndex={-1}
                    className={`btn1 ${
                      this.hasUpdated || this.changeHasApplied
                        ? "is-success"
                        : "is-link"
                    } ${this.isUpdating ? " is-loading" : ""}`}
                    disabled={this.hasUpdated}
                    onClick={() => this.updateVariant(true)}
                  >
                    {!this.hasUpdated ? "Update" : "Queued!"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

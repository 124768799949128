import React from "react";
import PropTypes from "prop-types";
import { TimeUtils } from "../../utils";
import { commaSplitWithAnd } from "../../utils/formatting";
import { generateTextForRecurring } from "../../utils/events";

function ViewEventModal(props) {
  const { event, setModalVisible } = props;
  if (!event) {
    return null;
  }
  const {
    title,
    banner,
    calendarIcon,
    game,
    type,
    date,
    time,
    description,
    maxParticipants,
    prizeStructure,
    participants,
    recurring,
    isTicketed,
    ticketPrice,
    ticketImage,
  } = event;
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => setModalVisible(false)}
          >
            <i className="fal fa-times"></i> Close
          </button>
        </header>
        <section className="modal-card-body">
          <div className="wrapper">
            <div className="content">
              {!!banner && (
                <React.Fragment>
                  <h5>Background image</h5>
                  <img className="eventBanner" src={banner} />
                </React.Fragment>
              )}
              {!!calendarIcon && (
                <React.Fragment>
                  <h5>Icon shown on calendar</h5>
                  <img className="eventImage" src={calendarIcon}></img>
                </React.Fragment>
              )}
              <h5>Game</h5>
              <p>{game}</p>
              <h5>Type</h5>
              <p>{type}</p>
              <h5>Date</h5>
              <p>
                {new Date(date.substring(0, 17)).toLocaleDateString(undefined, {
                  timeZone: "Etc/GMT",
                })}
              </p>
              <h5>Time</h5>
              <p>{TimeUtils.convertTime(time)}</p>
              {!!description && (
                <React.Fragment>
                  <h5>Description</h5>
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                  <br />
                </React.Fragment>
              )}
              {!!maxParticipants && (
                <React.Fragment>
                  <h5>Max participants</h5>
                  <p>{maxParticipants}</p>
                </React.Fragment>
              )}
              {!!prizeStructure && (
                <React.Fragment>
                  <h5>Prize structure</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: prizeStructure }}
                  ></div>
                  <br />
                </React.Fragment>
              )}
              {participants && participants.length > 0 && (
                <React.Fragment>
                  <h5>Current participants</h5>
                  <p>{commaSplitWithAnd(participants)}</p>
                </React.Fragment>
              )}
              {recurring && (
                <React.Fragment>
                  <h5>Recurring details</h5>
                  <p>{generateTextForRecurring(event)}</p>
                </React.Fragment>
              )}
              {isTicketed && (
                <React.Fragment>
                  <h5>Ticket Price</h5>
                  <p>${ticketPrice}</p>
                  {!!banner && (
                    <React.Fragment>
                      <h5>Ticket image</h5>
                      <img
                        className="eventImage"
                        src={
                          ticketImage ||
                          "https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png"
                        }
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="modalBtn action full"
            onClick={() => setModalVisible(false)}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

ViewEventModal.propTypes = {
  event: PropTypes.object,
  setModalVisible: PropTypes.func.isRequired,
};

export default ViewEventModal;

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  fetchVariantSyncSettings,
  removeVariantSyncSettings,
  updateVariantSyncSettings,
} from "../../api/rest/integrations";
import EbayResyncInventoryButton from "../../components/integrations/EbayResyncInventoryButton";
import ForceReauthoriseButton from "../../components/integrations/ForceReauthoriseButton";
import ProductPagination from "../../components/integrations/ProductPagination";
import TabNavigation from "../../components/integrations/TabNavigation";
import VariantSettingsForm from "../../components/integrations/VariantSettingsForm";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { notificationParameters } from "../../constants/notifications";
import IntegrationsSideMenu from "../../menuStructures/IntegrationsSideMenu";
import { useIntegrationFriendlyName } from "../../hooks/integrationHooks";

const RESULT_LIMIT = 25;

function ReviewVariants() {
  const { integration } = useParams<{ integration: string }>();
  const syncFriendlyName = useIntegrationFriendlyName(integration);
  const scrollToRef = useRef<HTMLDivElement>();
  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadVariantSyncSettings = () => {
    setIsLoading(true);
    fetchVariantSyncSettings(integration, offset, RESULT_LIMIT)
      .then((response: { total: number; data: any[] }) => {
        const { total, data } = response;
        setResults(data);
        setResultsCount(total);
      })
      .catch((error: Error) => setErrorMessage(String(error)))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveVariantSettings = (
    variantSyncSettings: unknown,
    setSubmitting: Function
  ) => {
    setSubmitting(true);
    updateVariantSyncSettings(variantSyncSettings)
      .then(() => {
        toast.info("Settings saved", notificationParameters);
      })
      .catch((error: Error) => {
        console.error(error);
        toast.error("Failed to save settings", notificationParameters);
      })
      .finally(() => setSubmitting(false));
  };

  const resetVariant = (
    variantId: number,
    setSubmitting: Function,
    handleReset: Function
  ) => {
    setSubmitting(true);
    removeVariantSyncSettings(integration, variantId)
      .then(() => {
        handleReset();
        toast.info("Reset successful", notificationParameters);
      })
      .catch((error: Error) => {
        console.error(error);
        toast.error("Failed to save settings", notificationParameters);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    document.title = "Integration Sync | BinderPOS";
  }, []);

  useEffect(() => {
    if (!isLoading) loadVariantSyncSettings();
    scrollToRef?.current?.scrollIntoView();
  }, [offset]);

  if (errorMessage) {
    return (
      <div className="error">
        An error occurred. Please refresh and try again
      </div>
    );
  }

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`${syncFriendlyName} Integration Settings`}>
        <>
          <EbayResyncInventoryButton integration={integration} />
          <ForceReauthoriseButton integration={integration} />
        </>
      </SectionHeaderLayout>
      <div className="integration-review-variants">
        <TabNavigation integrationId={integration} />
        <div className="integration-variants-info" ref={scrollToRef}>
          {results && results.length > 0
            ? results.map((item, index) => {
                const { variant, ...variantSyncSettings } = item;
                const {
                  tcgImage,
                  productTitle: title,
                  img,
                  collectorNumber,
                } = variant;
                // @ts-ignore
                const [productTitle, productSetOrig] = title.split("[");
                const productSet = productSetOrig?.replace("]", "");
                const fudgedVariant = {
                  ...variant,
                  variantSyncSettings: [variantSyncSettings],
                };
                return (
                  <div key={index} className="line-item container-fluid">
                    <div className="row">
                      <div className="container-fluid">
                        <div className="product-header">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col">
                                <div className="imgWrapper">
                                  <img
                                    src={
                                      tcgImage && tcgImage.length > 0
                                        ? tcgImage
                                        : img
                                    }
                                  />
                                </div>
                                <div className="productTitle">
                                  {productTitle}
                                  <span>{productSet}</span>
                                  {collectorNumber &&
                                  collectorNumber.length > 0 ? (
                                    <span># {collectorNumber}</span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="variant-select-titles row">
                          <div className="col-lg-2 col-md-12 text-left">
                            Variant
                          </div>
                          <div className="col-lg-7 col-md-12">
                            <div className="row">
                              <div className="col-lg-1 col-md-12">
                                Enable sync
                              </div>
                              <div className="col-lg-3 col-md-12 centered">
                                Price Markup Percentage
                              </div>
                              <div className="col-lg-2 col-md-12 centered">
                                Reserve Stock
                              </div>
                              <div className="col-lg-3 col-md-12 centered">
                                Maximum to list on {integration}
                              </div>
                              <div className="col-lg-3 col-md-12 centered">
                                Price Override
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-9">Actions</div>
                        </div>

                        <VariantSettingsForm
                          key={variant.id}
                          syncName={integration}
                          variant={fudgedVariant}
                          saveVariant={saveVariantSettings}
                          resetVariant={resetVariant}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : "There are no integration sync settings for any particular variants."}
          <ProductPagination
            resultsCount={resultsCount}
            limit={RESULT_LIMIT}
            offset={offset}
            setOffset={setOffset}
          />
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
  );
}

export default ReviewVariants;

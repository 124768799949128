import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Select from "react-select";
import { getDailyPOSSalesData } from "../../api/graphql/reports";
import { defaultChartOptions } from "../../constants/charts";

class SalesBarChartBreakDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSales: "0.00",
      selectedRange: {
        value: {
          startDate: moment().startOf("day").toISOString(),
          endDate: moment().endOf("day").toISOString(),
          binSize: 3600,
        },
        label: "Today",
      },
      selectedLabel: "Today",
      options: {
        ...defaultChartOptions,
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return "$" + val;
            },
          },
        },
      },
      series: [{ name: "Sales by Tender", data: [] }],
    };
  }

  updateRange = async (e) => {
    this.setState({
      selectedRange: e,
      selectedLabel: e.label,
    });
    const { startDate, endDate, binSize } = e.value;
    const reportData = await getDailyPOSSalesData(startDate, endDate, binSize);
    if (reportData.Metrics.SalesByRange) {
      this.reportData = Object.values(reportData.Metrics.Sales).slice(1);
      this.setState({
        series: [
          {
            name: "Sales By Tender",
            data: this.reportData || [],
          },
        ],
        options: {
          xaxis: {
            categories: [
              "Store Credit Used",
              "Store Credit Issued",
              "Cash Received",
              "Cash Given",
              "Credit Received",
              "Credit Refunded",
              "EFTPOS Receieved",
              "EFTPOS Refunds",
            ],
          },
        },
      });
    } else {
      this.setState({
        series: [
          {
            name: "Sales By Tender",
            data: [],
          },
        ],
      });
    }
    if (reportData?.Metrics?.Sales?.Total_In) {
      this.setState({ totalSales: reportData?.Metrics?.Sales?.Total_In });
    } else {
      this.setState({ totalSales: "0.00" });
    }
  };

  async componentDidMount() {
    this.updateRange({
      value: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
        binSize: 3600 * 24 * 7,
      },
      label: "This Month",
    });
  }

  options = [
    {
      value: {
        startDate: moment().startOf("day").toISOString(),
        endDate: moment().endOf("day").toISOString(),
        binSize: 3600,
      },
      label: "Today",
    },
    {
      value: {
        startDate: moment().subtract(1, "days").startOf("day").toISOString(),
        endDate: moment().subtract(1, "days").endOf("day").toISOString(),
        binSize: 3600,
      },
      label: "Yesterday",
    },
    {
      value: {
        startDate: moment().startOf("isoWeek").toISOString(),
        endDate: moment().endOf("isoWeek").toISOString(),
        binSize: 3600 * 24,
      },
      label: "This Week",
    },
    {
      value: {
        startDate: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .toISOString(),
        endDate: moment().subtract(1, "weeks").endOf("isoWeek").toISOString(),
        binSize: 3600 * 24,
      },
      label: "Last Week",
    },
    {
      value: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
        binSize: 3600 * 24,
      },
      label: "This Month",
    },
    {
      value: {
        startDate: moment()
          .subtract(1, "months")
          .startOf("month")
          .toISOString(),
        endDate: moment().subtract(1, "months").endOf("month").toISOString(),
        binSize: 3600 * 24,
      },
      label: "Last Month",
    },
    {
      value: {
        startDate: moment().startOf("year").toISOString(),
        endDate: moment().endOf("year").toISOString(),
        binSize: 3600 * 24 * 7,
      },
      label: "This Year",
    },
    {
      value: {
        startDate: moment().subtract(1, "years").startOf("year").toISOString(),
        endDate: moment().subtract(1, "years").endOf("year").toISOString(),
        binSize: 3600 * 24 * 7,
      },
      label: "Last Year",
    },
  ];

  render() {
    return (
      <div>
        <div className="wrapper">
          <div className="dashBoardFilter">
            <div className="row">
              <div className="col-sm-6">
                <Select
                  options={[{ label: "Point of Sale", value: "pos" }]}
                  defaultValue={{ label: "Point of Sale", value: "pos" }}
                />
              </div>
              <div className="col-sm-6">
                <Select
                  options={this.options}
                  onChange={this.updateRange}
                  defaultValue={{ label: "This Month", value: "" }}
                />
              </div>
            </div>
          </div>
          <span className="selectedRange">{this.state.selectedLabel}</span>
          <h3>{this.state.selectedLabel.toUpperCase()}S SALES</h3>
          <p className="statistic alt">
            {Number(this.state.totalSales).toLocaleString({
              style: "currency",
            })}
          </p>
          <div id="chart">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="250"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SalesBarChartBreakDown;

import React from "react";
import "./SectionHeaderLayout.scss";

interface SectionHeaderLayoutProps {
  title: string;
  children?: React.ReactChild;
}

function SectionHeaderLayout(props: SectionHeaderLayoutProps) {
  const { title, children } = props;

  return (
    <header className="section-header-layout">
      <h2>{title}</h2>
      <div>{children}</div>
    </header>
  );
}

export default SectionHeaderLayout;

import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import "./ButtonComponent.scss";

type IconPosition = "left" | "right";

type LinkProps =
  | {
      isLink: true;
      to: string;
    }
  | {
      isLink?: false;
      to?: string;
    };

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  fullWidth?: boolean;
  halfWidth?: boolean;
  icon?: string;
  iconPosition?: IconPosition;
  type?: "submit" | "reset" | "button";
}

type ButtonComponentProps = LinkProps & ButtonProps;

const getIcon = (iconCode?: string) => {
  if (!iconCode) return null;
  return <i className={iconCode} />;
};

const getContent = (
  children: ReactNode,
  icon?: string,
  iconPosition?: IconPosition
) =>
  iconPosition === "left" ? (
    <>
      {getIcon(icon)}
      {children}
    </>
  ) : (
    <>
      {children}
      {getIcon(icon)}
    </>
  );

const getClassName = (
  secondary?: boolean,
  danger?: boolean,
  fullWidth?: boolean,
  halfWidth?: boolean,
  className?: string
) =>
  [
    "button-component",
    secondary ? "button-component__secondary" : null,
    danger ? "button-component__danger" : null,
    fullWidth ? "button-component--full-width" : null,
    halfWidth ? "button-component--half-width" : null,
    className || null,
  ].join(" ");

const ButtonComponent = React.forwardRef((props: ButtonComponentProps, ref) => {
  const {
    children,
    className,
    icon,
    iconPosition,
    disabled,
    type,
    secondary,
    fullWidth,
    halfWidth,
    danger,
    isLink,
    to,
    ...rest
  } = props;

  const mergedClassName = getClassName(
    secondary,
    danger,
    fullWidth,
    halfWidth,
    className
  );

  if (isLink)
    return (
      <Link to={to} className={mergedClassName}>
        {getContent(children, icon, iconPosition)}
      </Link>
    );

  return (
    <button
      type={type}
      className={mergedClassName}
      disabled={disabled}
      {...rest} // @ts-ignore
      ref={ref}
    >
      {getContent(children, icon, iconPosition)}
    </button>
  );
});
ButtonComponent.defaultProps = {
  disabled: false,
  secondary: false,
  type: "button",
  iconPosition: "left",
};

export default ButtonComponent;

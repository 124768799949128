import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createIntegrationSyncSetting,
  deleteIntegrationSyncSetting,
  fetchCardGames,
  fetchCardSetNames,
  fetchIntegrationSyncSettings,
  fetchProductTypes,
  Game,
  Setting,
  updateIntegrationSyncSetting,
} from "../../api/rest/integrations";
import Loader from "../../components/generic/Loader";
import Spinner from "../../components/generic/Spinner";
import ErrorModalBasic from "../../components/generic/ErrorModalBasic";
import EbayResyncInventoryButton from "../../components/integrations/EbayResyncInventoryButton";
import DeleteMultipleSettingsModal from "../../components/integrations/DeleteMultipleSettingsModal";
import DeleteSettingModal from "../../components/integrations/DeleteSettingModal";
import ForceReauthoriseButton from "../../components/integrations/ForceReauthoriseButton";
import IntegrationSettingForm from "../../components/integrations/IntegrationSettingForm";
import TabNavigation from "../../components/integrations/TabNavigation";
import ViewSettingModal from "../../components/integrations/ViewSettingModal";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import IntegrationsSideMenu from "../../menuStructures/IntegrationsSideMenu";
import { useStoreDetails } from "../../hooks/storeHooks";
import { useIntegrationFriendlyName } from "../../hooks/integrationHooks";

const limit = 25;

function BasicSettings() {
  const { integration, settingId } = useParams<{
    integration: string;
    settingId: string;
  }>();
  const syncFriendlyName = useIntegrationFriendlyName(integration);
  const history = useHistory();
  const { customerId } = useStoreDetails();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [viewingSetting, setViewingSetting] = useState<number>();
  const [deletingSetting, setDeletingSetting] = useState<number>();
  const [showDeleteMultipleConfirmation, setShowDeleteMultipleConfirmation] =
    useState(false);
  const [selectedSettings, setSelectedSettings] = useState<number[]>([]);

  const [games, setGames] = useState<Game[]>([]);
  const [setNames, setSetNames] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [totalSettings, setTotalSettings] = useState(0);
  const [errorMessage, setErrorMessage] = useState<DetailedError>();

  const loadGames = () =>
    fetchCardGames()
      .then((data) => setGames(data))
      .catch((error: DetailedError) => setErrorMessage(error));

  const loadProductTypes = () =>
    fetchProductTypes()
      .then((data) => setProductTypes(data))
      .catch((error: DetailedError) => setErrorMessage(error));

  const loadSettings = (newOffset?: number) => {
    setIsLoading(true);
    fetchIntegrationSyncSettings(integration, newOffset ?? offset, limit)
      .then((response) => {
        const { data, total } = response;
        setSettings(data);
        setTotalSettings(total);
      })
      .catch((error: DetailedError) => setErrorMessage(error))
      .finally(() => setIsLoading(false));
  };

  const saveSetting = (setting: Setting, setSubmitting: Function) => {
    setSubmitting(true);
    setting.syncName = integration;
    setting.customerId = customerId;
    const saveAction = setting.id
      ? updateIntegrationSyncSetting
      : createIntegrationSyncSetting;
    saveAction(setting)
      .then(() => {
        loadSettings();
        history.push(`/integrations/${integration}`);
        toast.info("New setting created", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error: DetailedError) => setErrorMessage(error))
      .finally(() => setSubmitting(false));
  };

  const deleteSetting = (id: number) => {
    setIsDeleting(true);
    deleteIntegrationSyncSetting(id)
      .then(() => {
        loadSettings();
        history.push(`/integrations/${integration}`);
        toast.info("Setting deleting", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error: DetailedError) => setErrorMessage(error))
      .finally(() => {
        setIsDeleting(false);
        setDeletingSetting(undefined);
        setSelectedSettings([]);
      });
  };

  const deleteMultipleSettings = () => {
    setIsDeleting(true);
    const toDelete = selectedSettings.map((id) =>
      deleteIntegrationSyncSetting(id)
    );
    return Promise.all(toDelete)
      .then(() => {
        loadSettings();
        history.push(`/integrations/${integration}`);
        toast.info("Settings deleting", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error: DetailedError) => setErrorMessage(error))
      .finally(() => {
        setIsDeleting(false);
        setShowDeleteMultipleConfirmation(false);
        setSelectedSettings([]);
      });
  };

  const loadSets = (game: string) =>
    fetchCardSetNames(game)
      .then((response) => setSetNames(response))
      .catch((error: DetailedError) => setErrorMessage(error))
      .finally(() => setIsLoading(false));

  const checkDuplicates = (
    id: number,
    game: string,
    setName: string,
    productType: string
  ) => {
    const gameFixed = game === "" || game === undefined ? null : game;
    const setNameFixed = setName === "" || game === undefined ? null : setName;
    return settings.find(
      (setting) =>
        setting.game === gameFixed &&
        setting.setName === setNameFixed &&
        setting.productType === productType &&
        setting.id !== id
    );
  };

  const handleOnChangeForCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked } = event.target;
    if (checked) {
      if (!selectedSettings.includes(id)) {
        setSelectedSettings([...selectedSettings, id]);
      }
    } else {
      setSelectedSettings(selectedSettings.filter((val) => val !== id));
    }
  };

  const handleChangeAllCheckboxes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedSettings(
        settings.filter((setting) => setting.game).map((setting) => setting.id)
      );
    } else {
      setSelectedSettings([]);
    }
  };

  const handleOnNextPage = () => {
    setOffset(offset + limit);
    loadSettings(offset + limit);
  };

  const handleOnPrevPage = () => {
    setOffset(offset - limit);
    loadSettings(offset - limit);
  };

  const handleCloseError = () => setErrorMessage(undefined);

  useEffect(() => {
    document.title = "Integration Sync | BinderPOS";
    loadSettings();
    loadGames();
    loadProductTypes();
  }, [integration]);

  if (isLoading) {
    return <Loader />;
  }

  if (settingId) {
    return (
      <>
        <IntegrationSettingForm
          integration={integration}
          integrationSyncFriendlyName={integration}
          games={games}
          sets={setNames}
          productTypes={productTypes}
          loadSets={loadSets}
          checkDuplicates={checkDuplicates}
          saveSetting={saveSetting}
          setting={settings.find((setting) => setting.id === Number(settingId))}
        />
        {errorMessage ? (
          <ErrorModalBasic
            error={errorMessage?.data ?? errorMessage}
            onClose={handleCloseError}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`${syncFriendlyName} Integration Settings`}>
        <>
          <EbayResyncInventoryButton integration={integration} />
          <ForceReauthoriseButton integration={integration} />
        </>
      </SectionHeaderLayout>
      <div className="integration-settings-list">
        <TabNavigation integrationId={integration} />
        <Spinner isLoading={isLoading}>
          <table>
            <thead>
              <tr>
                <td colSpan={2}>
                  {totalSettings > 1 ? (
                    <span className="multipleSelect">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          onChange={handleChangeAllCheckboxes}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span
                        className="text"
                        onClick={() => setShowDeleteMultipleConfirmation(true)}
                      >
                        {selectedSettings.length} selected{" "}
                        <i className="far fa-trash-alt" />
                      </span>
                    </span>
                  ) : null}
                </td>
                <td>
                  <Link
                    to={`/integrations/${integration}/settings/new`}
                    className="tableLink"
                  >
                    Create new setting <i className="fal fa-plus-square" />
                  </Link>
                </td>
              </tr>
            </thead>
            <tbody>
              {totalSettings === 0 && (
                <tr>
                  <td className="no-settings">
                    You current have no settings saved for this integration.
                    &nbsp;
                    <Link to={`/integrations/${integration}/settings/new`}>
                      Create a new setting
                    </Link>
                  </td>
                </tr>
              )}
              {settings &&
                settings.map((setting, i) => {
                  const gameName = games.filter(
                    (game) => game.gameId === setting.game
                  )[0]?.gameName;
                  return (
                    <tr key={i}>
                      <td>
                        {setting.game || setting.productType ? (
                          <label className="checkbox">
                            <input
                              id={"additionalInfoRequired" + i}
                              type="checkbox"
                              checked={selectedSettings.includes(setting.id)}
                              onChange={(event) =>
                                handleOnChangeForCheckbox(event, setting.id)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        ) : null}
                      </td>
                      <td className="text-bold">
                        {gameName || setting.productType}
                        {setting.setName && ` - ${setting.setName}`}
                      </td>
                      <td className="text-right">
                        <a
                          className="userActions"
                          onClick={() => setViewingSetting(setting.id)}
                        >
                          <i className="far fa-eye" />
                        </a>
                        <Link
                          className="userActions"
                          to={`/integrations/${integration}/settings/${setting.id}`}
                        >
                          <i className="far fa-edit" />
                        </Link>
                        {setting.game || setting.productType ? (
                          <a
                            className="userActions"
                            onClick={() => setDeletingSetting(setting.id)}
                          >
                            <i className="far fa-trash-alt" />
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            {totalSettings > offset ? (
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <span className="top-pagination">
                      <span>
                        Showing {offset + 1} to{" "}
                        {Math.min(limit + offset, totalSettings)} of{" "}
                        {totalSettings}
                      </span>
                      <button
                        disabled={offset == 0}
                        className="paging-nav small"
                        onClick={handleOnPrevPage}
                      >
                        <i className="fas fa-caret-left" />
                      </button>
                      <button
                        disabled={limit > settings.length}
                        className="paging-nav small"
                        onClick={handleOnNextPage}
                      >
                        <i className="fas fa-caret-right" />
                      </button>
                    </span>
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </table>
        </Spinner>
        <div></div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
        />
      </div>
      <ViewSettingModal
        visible={viewingSetting !== undefined}
        setting={settings?.find((setting) => setting.id === viewingSetting)}
        games={games}
        integration={integration}
        integrationSyncFriendlyName={integration}
        handleClose={() => setViewingSetting(undefined)}
      />
      <DeleteSettingModal
        visible={deletingSetting !== undefined}
        id={deletingSetting}
        loading={isDeleting}
        deleteSetting={deleteSetting}
        handleClose={() => setDeletingSetting(undefined)}
      />
      <DeleteMultipleSettingsModal
        visible={showDeleteMultipleConfirmation}
        loading={isDeleting}
        deleteSelectedSettings={deleteMultipleSettings}
        handleClose={() => setShowDeleteMultipleConfirmation(undefined)}
      />
    </>
  );
}

export default BasicSettings;

import { action } from "mobx";
import TimeUtils from "../../utils/TimeUtils";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";

@inject("posStore")
@observer
class FloatModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingAdjustment: false,
      floatAdjustmentOpen: false,
    };
  }

  viewFloatAdjustmentMenu = (visible) => {
    this.setState({ floatAdjustmentOpen: visible });
  };

  @action
  updateTenderClose(event, tenderIndex) {
    const { value } = event.target;
    if (this.props.posStore.tenderClose.length <= tenderIndex)
      this.props.posStore.tenderClose.length = tenderIndex + 1;
    let tenderValue;
    if (isNaN(value) && value != "-") {
      tenderValue = value.replace(/[^0-9.-]/g, "");
      // TODO:: Is this next state even possible?
      if (isNaN(tenderValue)) {
        tenderValue = "";
      }
    } else {
      tenderValue = value;
    }
    this.props.posStore.setTenderClose(tenderValue, tenderIndex);
  }

  updateAdjustment = (event) => {
    const { value: adjustment } = event.target;
    let amount;
    if (isNaN(adjustment) && adjustment != "-") {
      amount = adjustment.replace(/[^0-9.-]/g, "");
      // TODO:: Is this next state even possible?
      if (isNaN(amount)) {
        amount = "";
      }
    } else {
      amount = adjustment;
    }
    this.props.posStore.setAdjustAmount(amount);
  };

  changeAdjustTender = (event) => {
    const { value: tenderIndex } = event.target;
    this.props.posStore.setAdjustTender(
      this.props.posStore.tenders[tenderIndex].type
    );
  };

  closeModal = () => {
    this.props.posStore.closeFloatModal();
  };

  formatTenderType = (tenderType) => {
    if (tenderType === "Cash") {
      return (
        <>
          <i className="fas fa-money-bill-wave" /> Cash
        </>
      );
    }
    if (tenderType == "Credit") {
      return (
        <>
          <i className="fas fa-credit-card-front" /> Credit Card
        </>
      );
    }
    if (tenderType === "EFTPOS") {
      return (
        <>
          <i className="fas fa-credit-card" /> EFTPOS
        </>
      );
    }
    return (
      <>
        <i className="fas fa-money-bill-wave" /> {tenderType}
      </>
    );
  };

  render() {
    const store = this.props.posStore;
    const currentTill =
      store.tillList.find((till) => till.id == store.tillId)?.name || "till";
    return (
      <div className="modal display-block">
        <section className="modal-main dark">
          <div className="closeTill">
            <div className="modal-card">
              <header className="modal-card-head">
                <button
                  className="delete"
                  aria-label="close"
                  onClick={this.closeModal}
                  disabled={!store.floatStatus}
                >
                  <i className="fal fa-times" /> Close
                </button>
                <div className="toggle">
                  <span
                    role="button"
                    className={
                      !this.state.floatAdjustmentOpen ? "btn2 active" : "btn2"
                    }
                    onClick={() => this.viewFloatAdjustmentMenu(false)}
                  >
                    Close Till
                  </span>
                  <span
                    role="button"
                    className={
                      this.state.floatAdjustmentOpen ? "btn2 active" : "btn2"
                    }
                    onClick={() => this.viewFloatAdjustmentMenu(true)}
                  >
                    Float Adjustment
                  </span>
                </div>
              </header>
              {!this.state.floatAdjustmentOpen ? (
                <>
                  <section className="modal-card-body">
                    <div className="closeToggle">
                      <div className="closeTitle">Close {currentTill}</div>
                      <span className="openDate">
                        ** Till opened on the{" "}
                        {TimeUtils.convertDateToHumanReadable(
                          store.float.dateOpened
                        )}
                        **
                      </span>
                      <div className="wrapper">
                        <table className="closeTillTable">
                          <thead>
                            <tr>
                              <td>Payment Type</td>
                              <td>Expected</td>
                              <td>Difference</td>
                              <td>End of Day Count</td>
                            </tr>
                          </thead>
                          <tbody>
                            {store.tenders
                              .filter((tender) => tender.type != "Store Credit")
                              .map((tender, index) => (
                                <tr key={index}>
                                  <td className="type">
                                    {this.formatTenderType(tender.type)}
                                  </td>
                                  <td className="expected">
                                    {store.float.float &&
                                      store.float.float.length >= index &&
                                      store.float.float[
                                        index
                                      ].currentAmount.toFixed(2)}
                                  </td>
                                  <td
                                    className={
                                      store.tenderDiff &&
                                      store.tenderDiff.length >= index &&
                                      store.tenderDiff[index] == 0
                                        ? "balanced difference"
                                        : "difference"
                                    }
                                  >
                                    {store.tenderDiff[index] ?? null}
                                  </td>
                                  <td className="counted">
                                    <input
                                      type="textbox"
                                      key={index}
                                      placeholder="0.00"
                                      value={store.tenderClose[index] ?? ""}
                                      onChange={(e) =>
                                        this.updateTenderClose(e, index)
                                      }
                                      data-testid="tender-close"
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="modalBtn action full"
                      onClick={store.openTillWarning}
                      disabled={!store.floatStatus}
                    >
                      <i className="fas fa-power-off"></i> Close Till
                    </button>
                  </footer>
                </>
              ) : (
                <>
                  <section className="modal-card-body">
                    <div className="closeTitle">Float adjustment</div>
                    <span className="openDate">
                      Use the form below to adjust your ledgers
                    </span>
                    <div className="wrapper floatAdj">
                      <div className="col-md-12">
                        <span>Type:</span>
                        <select
                          className="tillDropdown"
                          onChange={this.changeAdjustTender}
                          disabled={!store.floatStatus}
                          data-testid="change-adjust"
                          //value={store.tillId ? store.tillId : -1}
                        >
                          {store.tenders
                            .filter((ten) => ten.type != "Store Credit")
                            .map((till, index) => (
                              <option value={index} key={index}>
                                {till.type}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="adjustmentAmount">
                          Adjustment Amount:
                        </label>
                        <input
                          id="adjustmentAmount"
                          value={store.adjustAmount}
                          type="textbox"
                          placeholder="e.g, 50.00 or -50.00"
                          onChange={this.updateAdjustment}
                          data-testid="adjustment-amount"
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="note">Notes:</label>
                        <input
                          id="note"
                          type="textbox"
                          placeholder="Type to add a note"
                          onChange={(e) => {
                            store.updateNote(e.target.value);
                          }}
                          value={store.adjustNote}
                        />
                      </div>
                    </div>
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="modalBtn action full"
                      disabled={
                        !store.floatStatus || this.state.submittingAdjustment
                      }
                      onClick={async () => {
                        try {
                          this.setState({ submittingAdjustment: true });
                          await store.adjustFloat();
                        } finally {
                          this.setState({ submittingAdjustment: false });
                        }
                      }}
                    >
                      {this.state.submittingAdjustment
                        ? "Submitting Adjustment..."
                        : "Submit Adjustment"}{" "}
                      <i className="fas fa-caret-right"></i>
                    </button>
                  </footer>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FloatModal;

import {
  doCompleteBuylist,
  doDeclineBuylist,
  fetchBuylistDetails,
  fetchCardPrices,
  saveBuylist,
} from "../../api/rest/buylist";
import { Loader, Spinner } from "../../components";
import { BuylistMenuStructure } from "../../menuStructures";
import { action, observable, runInAction } from "mobx";
import Cookie from "mobx-cookie";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast, ToastContainer } from "react-toastify";
import { TimeUtils } from "../../utils";
import {
  chunkCards,
  getSortFunction,
  groupBuylistVariants,
  splitBuylistVariants,
} from "../../utils/buylist";
import { convertToCsv } from "../../utils/data";
import {
  DeclineModal,
  GridView,
  ListView,
  PrintBuylist,
  SearchModal,
  SortDirection,
} from "../../components/buylist";
import UncompleteBuylist from "../../components/buylist/UncompleteBuylist";
import PushStock from "../../components/buylist/PushStock";
import PushStoreCredit from "../../components/buylist/PushStoreCredit";
import ApproveBuylistModal from "../../components/buylist/ApproveBuylistModal";

const PRICE_LOAD_GROUP_SIZE = 20;

@inject("store")
@observer
class BuylistDetails extends Component {
  @observable cookie = new Cookie("buylistListView");

  @observable listView = this.cookie.value;

  @action setListView = (value) => {
    this.listView = value;
  };

  @observable noRedirect = false;

  @action setNoRedirect = (redirect) => {
    this.noRedirect = redirect;
  };

  @observable modalVisible = false;

  @action setModalVisible = (visible) => {
    this.modalVisible = visible;
    if (!visible) {
      this.noRedirect = visible;
    }
  };

  @observable modalTitle;

  @action setModalTitle = (title) => {
    this.modalTitle = title;
  };

  @observable modalText;

  @action setModalText = (text) => {
    this.modalText = text;
  };

  @observable declineModalTitle;

  @action setDeclineModalTitle = (title) => {
    this.declineModalTitle = title;
  };

  @observable declineModalVisible;

  @action setDeclineModalVisible = (visible) => {
    this.declineModalVisible = visible;
  };

  @observable declineModalLoading;

  @action setDeclineModalLoading = (loading) => {
    this.declineModalLoading = loading;
  };

  @observable buylistDetailToDecline;

  @action setBuylistDetailToDecline = (buylistDetail) => {
    this.buylistDetailToDecline = buylistDetail;
  };

  @observable approveModalVisible;

  @action setApproveModalVisible = (visible) => {
    this.approveModalVisible = visible;
  };

  @observable completeModalLoading;

  @action setCompleteModalLoading = (loading) => {
    this.completeModalLoading = loading;
  };

  @observable completeModalTitle;

  @action setCompleteModalTitle = (title) => {
    this.completeModalTitle = title;
  };

  @observable completeModalVisible;

  @action setCompleteModalVisible = (visible) => {
    this.completeModalVisible = visible;
  };

  @observable buylistDetailToApprove;

  @action setBuylistDetailToApprove = (buylistDetail) => {
    this.buylistDetailToApprove = splitBuylistVariants(buylistDetail);
  };

  @observable buylistDetailToComplete;

  @action setBuylistDetailToComplete = (buylistDetail) => {
    this.buylistDetailToComplete = splitBuylistVariants(buylistDetail);
  };

  @observable searchModalVisible;

  @action setSearchModalVisible = (visible) => {
    this.searchModalVisible = visible;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable sortField = "game";

  @action setSortField = (field) => {
    this.sortField = field;
    this.updateSort();
  };

  @observable sortAscending = true;

  @action setSortAscending = (ascending) => {
    this.sortAscending = ascending;
    this.updateSort();
  };

  @action updateSort = () => {
    const paymentType =
      this.props.store.BuylistRulesStore.buylistDetails?.paymentType;
    this.props.store.BuylistRulesStore.buylistDetails.cards.replace(
      this.props.store.BuylistRulesStore.buylistDetails.cards
        .slice()
        .sort(getSortFunction(this.sortField, this.sortAscending, paymentType))
    );
  };

  loadBuylistDetails = (buylistId) => {
    this.setIsLoading(true);
    const { BuylistRulesStore, MainStore } = this.props.store;
    BuylistRulesStore.setBuylistDetails(null);
    fetchBuylistDetails(buylistId)
      .then((result) => {
        const groupedResult = groupBuylistVariants(result);
        BuylistRulesStore.setBuylistDetails(groupedResult);
        setTimeout(() => this.batchCardsToGetPrices(groupedResult.cards), 0);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist details",
          "There was an error retrieving your buylist details. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  batchCardsToGetPrices = async (cards) => {
    toast.warn("Loading prices...", { autoClose: false });
    const groups = chunkCards(cards, PRICE_LOAD_GROUP_SIZE);
    let foundErrors = false;
    let groupCount = 0;
    // eslint-disable-next-line no-restricted-syntax
    for await (const group of groups) {
      try {
        await this.getBuylistCardPrices(group);
        groupCount += 1;
        if (groupCount === groups.length) {
          toast.dismiss();
          if (foundErrors) {
            toast.error("Failed to load prices for some cards");
          } else {
            setTimeout(() => toast.success("Prices loaded"), 500);
          }
        }
      } catch (err) {
        foundErrors = true;
      }
    }
  };

  getBuylistCardPrices = async (cards) => {
    const cardsByGame = {};
    cards.forEach((card) => {
      const { gameId, cardId } = card;
      if (Object.keys(cardsByGame).includes(gameId)) {
        cardsByGame[gameId].add(cardId);
      } else {
        cardsByGame[gameId] = new Set([cardId]);
      }
    });
    const searchData = [];
    Object.entries(cardsByGame).forEach(([gameId, cardIds]) => {
      const gameData = {
        game: gameId,
        ids: Array.from(cardIds),
      };
      searchData.push(gameData);
    });
    try {
      const result = await fetchCardPrices(searchData);
      runInAction(() => {
        result.forEach((matchedCard) => {
          const buylistCards =
            this.props.store.BuylistRulesStore.buylistDetails.cards.filter(
              (card) =>
                card.cardName === matchedCard.cardName &&
                card.setName === matchedCard.setName
            );
          // Iterate here as there may be one foil and one non-foil
          buylistCards.forEach((buylistCard) => {
            const { type } = buylistCard;
            matchedCard.variants.forEach((variant) => {
              const pricing = variant.cardBuylistTypes.find(
                (typePrice) => typePrice.type === type
              );
              if (
                !Object.keys(buylistCard.variants).includes(String(variant.id))
              ) {
                buylistCard.variants[variant.id] = {
                  quantity: 0,
                  cashBuyPrice: (pricing && pricing.buyPrice) || 0,
                  storeCreditBuyPrice: (pricing && pricing.creditBuyPrice) || 0,
                  storeSellPrice: (pricing && pricing.storeSellPrice) || 0,
                  variantId: variant.id,
                  variantName: variant.variantName,
                  game: matchedCard.game,
                };
              } else {
                // Just upsert the sell price
                if (!buylistCard.variants[variant.id].storeSellPrice) {
                  buylistCard.variants[variant.id].storeSellPrice =
                    (pricing && pricing.storeSellPrice) || 0;
                }
                buylistCard.variants[variant.id].game = matchedCard.game;
              }
            });
          });
        });
      });
    } catch (error) {
      this.props.store.MainStore.setError(
        error,
        "Failed to load prices",
        "There was an error retrieving your buylist card prices. Please refresh and try again"
      );
    }
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Buylist details | BinderPOS";
    const { CardStore, CustomersStore, MainStore, MenuStore } =
      this.props.store;
    MainStore.getCurrency();
    MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.setModalVisible(false);
    this.setModalTitle("");
    this.setModalText("");
    const buylistId = this.props.match?.params?.buylistId || null;
    CustomersStore.getCustomerVariants()
      .then((result) => {
        CustomersStore.setCustomerVariants(result);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist variants",
          "There was an error retrieving your store's variants. Please refresh and try again"
        );
      });
    CardStore.fetchCardGames()
      .then((result) => {
        CardStore.setGames(result);
      })
      .catch((error) => {
        MainStore.setError(
          error,
          "Failed to load buylist games",
          "There was an error retrieving your store's supported games. Please refresh and try again"
        );
      });

    if (buylistId && buylistId > 0) {
      this.loadBuylistDetails(buylistId);
    }
  }

  @action onVariantQuantityChange = (
    cardName,
    cardId,
    setName,
    type,
    variantId,
    newQuantity
  ) => {
    const { buylistDetails } = this.props.store.BuylistRulesStore;
    const buylistCard = buylistDetails.cards.find(
      (card) =>
        card.cardName === cardName &&
        card.setName === setName &&
        card.cardId === cardId &&
        card.type === type
    );
    if (!buylistCard) {
      console.log(
        `No matching card found: ${cardName} (${cardId}) ${setName} - ${type}`
      );
      return;
    }
    const variant = buylistCard.variants[variantId];
    if (variant) {
      variant.quantity = newQuantity;
    }
  };

  @action onVariantBuyPriceChange = (
    cardName,
    cardId,
    setName,
    type,
    variantId,
    isPaidInCash,
    newPrice
  ) => {
    const { buylistDetails } = this.props.store.BuylistRulesStore;
    const buylistCard = buylistDetails.cards.find(
      (card) =>
        card.cardName === cardName &&
        card.cardId === cardId &&
        card.setName === setName &&
        card.type === type
    );
    if (!buylistCard) {
      console.log(
        `No matching card found: ${cardName} (${cardId}) - ${setName} - ${type}`
      );
      return;
    }
    const variant = buylistCard.variants[variantId];
    if (variant) {
      if (isPaidInCash) {
        variant.cashBuyPrice = newPrice;
      } else {
        variant.storeCreditBuyPrice = newPrice;
      }
    }
  };

  @action removeLine = (index) => {
    this.props.store.BuylistRulesStore.buylistDetails.cards.splice(index, 1);
  };

  showDeclineModal = (buylistDetail) => {
    this.setBuylistDetailToDecline(buylistDetail);
    this.setDeclineModalTitle("Decline buylist request");
    this.setDeclineModalVisible(true);
  };

  declineBuylist = (event, reason) => {
    event.preventDefault();
    this.setDeclineModalLoading(true);
    const buylistDetail = JSON.parse(
      JSON.stringify(this.buylistDetailToDecline)
    );
    buylistDetail.declinedReason = reason;
    doDeclineBuylist(buylistDetail)
      .then(() => {
        this.props.store.BuylistRulesStore.rehydratePendingBuylists();
        this.setDeclineModalVisible(false);
        this.setDeclineModalLoading(false);
        this.setModalTitle("Buylist declined");
        this.setModalText(
          `You have declined the buylist from ${buylistDetail.shopifyCustomer.firstName} ${buylistDetail.shopifyCustomer.lastName}`
        );
        this.setModalVisible(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to decline buylists",
          "There was an error declining yourbuylist. Please try again"
        );
      });
  };

  showApproveModal = (buylistDetail) => {
    this.setBuylistDetailToApprove(buylistDetail);
    this.setApproveModalVisible(true);
  };

  showCompleteModal = (buylistDetail) => {
    this.setBuylistDetailToComplete(buylistDetail);
    this.setCompleteModalTitle("Complete buylist request");
    this.setCompleteModalVisible(true);
  };

  saveChanges = (buylistDetail) => {
    this.setNoRedirect(true);
    saveBuylist(splitBuylistVariants(buylistDetail))
      .then(() => {
        this.setModalTitle("Buylist changes saved!");
        this.setModalText(
          "The changes you have made to the buylist have been saved!"
        );
        this.setModalVisible(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save buylist",
          "There was an error saving your changes. Please try again"
        );
        this.setNoRedirect(false);
      });
  };

  completeBuylist = () => {
    this.setCompleteModalLoading(true);
    const buylistDetail = JSON.parse(
      JSON.stringify(this.buylistDetailToComplete)
    );
    if (this.formRef.current.elements.approveReason?.value) {
      buylistDetail.approvedNotes =
        this.formRef.current.elements.approveReason.value;
    }
    buylistDetail.pushProducts =
      this.formRef.current.elements.pushStock.checked;
    if (this.formRef.current.elements.applyStoreCredit) {
      buylistDetail.applyStoreCredit =
        this.formRef.current.elements.applyStoreCredit.checked;
    }

    doCompleteBuylist(buylistDetail)
      .then(() => {
        this.props.store.BuylistRulesStore.rehydrateApprovedBuylists();
        this.setApproveModalVisible(false);
        this.setCompleteModalLoading(false);
        this.setModalTitle("Buylist Completed!");
        this.setModalText(
          `You have completed the buylist from ${buylistDetail.shopifyCustomer.firstName} ${buylistDetail.shopifyCustomer.lastName}`
        );
        this.setModalVisible(true);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to complete buylist",
          "There was an error submitting your complete buylist. Please try again"
        );
      });
  };

  @action addCardToList = (buylistCard) => {
    let found = false;
    this.props.store.BuylistRulesStore.buylistDetails.cards.forEach(
      (existing) => {
        if (
          buylistCard.cardName === existing.cardName &&
          buylistCard.setName === existing.setName &&
          buylistCard.type === existing.type
        ) {
          found = true;
        }
      }
    );
    if (found) {
      // Card already exists. TODO:: Perhaps focus cursor on it?
      return;
    }
    const variants = {};
    Object.keys(buylistCard.variants).forEach((variantId) => {
      const variant = buylistCard.variants[variantId];
      const variantType = variant.cardBuylistTypes.find(
        (cardBuylistType) => cardBuylistType.type === buylistCard.type
      );
      variants[variant.id] = {
        cashBuyPrice: variantType?.buyPrice || 0,
        quantity: 0,
        storeCreditBuyPrice: variantType?.creditBuyPrice || 0,
        storeSellPrice: variantType?.storeSellPrice || 0,
        variantId: variant.id,
        variantName: variant.variantName,
      };
    });
    const card = {
      cardId: buylistCard.cardId,
      cardName: buylistCard.cardName,
      foil: buylistCard.foil,
      game: buylistCard.game,
      gameId: buylistCard.gameId,
      imageUrl: buylistCard.imageUrl,
      setName: buylistCard.setName,
      shopifyCustomerBuylistId: buylistCard.shopifyCustomerBuylistId,
      type: buylistCard.type,
      variants,
    };
    this.props.store.BuylistRulesStore.buylistDetails.cards.push(card);
    this.setSearchModalVisible(false);
  };

  changeListView = () => {
    this.cookie.set("enabled", { expires: 365 });
    this.setListView(this.cookie.value);
  };

  changeGridView = () => {
    this.cookie.set("disabled", { expires: 365 });
    this.setListView(this.cookie.value);
  };

  saveToCSV = () => {
    const { BuylistRulesStore, MainStore } = this.props.store;
    const { buylistDetails } = BuylistRulesStore;
    const fC = MainStore.currencyBuilder;
    const { id, shopifyCustomer, cards, paymentType } = buylistDetails;
    const rows = cards.map((card) => {
      const { variants, ...cardDetails } = card;
      return Object.values(variants)
        .filter((variant) => variant.quantity)
        .map((variant) => ({
          ...cardDetails,
          ...variant,
        }));
    });

    const credit = paymentType === "Store Credit";
    const csvHeaders = [
      { name: "Card", key: "cardName" },
      { name: "Game", key: "game" },
      { name: "Set", key: "setName" },
      { name: "Printing", key: "type" },
      { name: "Condition", key: "variantName" },
      { name: "Quantity", key: "quantity" },
      {
        name: `${credit ? "Store Credit" : "Cash"} Buy Price (each)`,
        key: credit ? "storeCreditBuyPrice" : "cashBuyPrice",
        formatter: (val) => fC(val),
      },
      {
        name: "Sell Price (each)",
        key: "storeSellPrice",
        formatter: (val) => fC(val),
      },
    ];
    const { firstName, lastName } = shopifyCustomer;
    const csvFilename = `Buylist ${id} - ${firstName} ${lastName}.csv`;
    convertToCsv(csvHeaders, rows.flat()).then((csv) => {
      const file = new File([csv], csvFilename, { type: "text/csv" });
      const exportUrl = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.style = "display: none";
      document.body.appendChild(a);
      a.href = exportUrl;
      a.download = csvFilename;
      a.click();
      URL.revokeObjectURL(exportUrl);
    });
  };

  render() {
    const { AuthStore, BuylistRulesStore, CardStore, MainStore } =
      this.props.store;
    const fC = MainStore.currencyBuilder;

    if (!AuthStore.screenSettings.buylist) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    let buylistTotal = 0;
    const isPaidInCash =
      BuylistRulesStore.buylistDetails?.paymentType === "Cash";
    let zeroQuantityDetected = false;
    BuylistRulesStore.buylistDetails &&
      BuylistRulesStore.buylistDetails.cards.forEach((card) => {
        const totalQuantity = Object.values(card.variants).reduce(
          (sum, variant) => sum + parseFloat(variant.quantity),
          0
        );
        if (totalQuantity === 0) {
          zeroQuantityDetected = true;
        }
        const cardTotal = Object.values(card.variants).reduce(
          (sum, variant) =>
            sum +
            parseFloat(variant.quantity) *
              parseFloat(
                isPaidInCash
                  ? variant.cashBuyPrice
                  : variant.storeCreditBuyPrice
              ),
          0
        );
        buylistTotal += cardTotal;
      });

    return (
      <>
        {BuylistRulesStore.buylistDetails && CardStore.games ? (
          <>
            <div className="app-header with-buttons">
              <h2 className="title is-2">
                <span className="header-text">
                  {BuylistRulesStore.buylistDetails.finalBuylistDetails &&
                  BuylistRulesStore.buylistDetails.finalBuylistDetails.length >
                    0
                    ? "Viewing Completed buylist"
                    : "Review Submitted Buylist"}
                </span>
              </h2>
              <span className="buttons spaced-buttons">
                {BuylistRulesStore.buylistDetails.completed ? (
                  <>
                    <UncompleteBuylist
                      buylistId={BuylistRulesStore.buylistDetails.id}
                    />
                    <PushStock
                      buylistId={BuylistRulesStore.buylistDetails.id}
                      pushProducts={
                        BuylistRulesStore.buylistDetails.pushProducts
                      }
                    />
                    <PushStoreCredit
                      buylistId={BuylistRulesStore.buylistDetails.id}
                      applyStoreCredit={
                        BuylistRulesStore.buylistDetails.applyStoreCredit
                      }
                      paymentType={BuylistRulesStore.buylistDetails.paymentType}
                    />
                  </>
                ) : (
                  <>
                    {BuylistRulesStore.buylistDetails.approved ? (
                      <button
                        className="btn1"
                        onClick={() =>
                          this.showCompleteModal(
                            BuylistRulesStore.buylistDetails
                          )
                        }
                      >
                        Complete
                      </button>
                    ) : (
                      <button
                        className="btn1"
                        onClick={() =>
                          this.showApproveModal(
                            BuylistRulesStore.buylistDetails
                          )
                        }
                      >
                        Approve
                      </button>
                    )}
                    &nbsp;
                    <button
                      className="btn1 decline"
                      onClick={() =>
                        this.showDeclineModal(BuylistRulesStore.buylistDetails)
                      }
                    >
                      Decline
                    </button>
                    &nbsp;
                    <Link to="/buylists/pending" className="btn1 cancel">
                      Cancel
                    </Link>
                  </>
                )}
              </span>
            </div>
            <div className="buylistDetailsPanels">
              <div className="card buylist-metadata">
                <div className="card-header">
                  <div
                    className={
                      BuylistRulesStore.buylistDetails.paymentType == "Cash"
                        ? "avatar red"
                        : "avatar blue"
                    }
                  >
                    {BuylistRulesStore.buylistDetails.shopifyCustomer
                      .firstName &&
                    BuylistRulesStore.buylistDetails.shopifyCustomer.firstName
                      .length > 0 ? (
                      <>
                        {BuylistRulesStore.buylistDetails.shopifyCustomer.firstName.charAt(
                          0
                        )}
                        {BuylistRulesStore.buylistDetails.shopifyCustomer
                          .lastName &&
                        BuylistRulesStore.buylistDetails.shopifyCustomer
                          .lastName.length > 0 ? (
                          <>
                            {BuylistRulesStore.buylistDetails.shopifyCustomer.lastName.charAt(
                              0
                            )}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {BuylistRulesStore.buylistDetails.shopifyCustomer
                          .email &&
                        BuylistRulesStore.buylistDetails.shopifyCustomer.email
                          .length > 0 ? (
                          <>
                            {BuylistRulesStore.buylistDetails.shopifyCustomer.email.charAt(
                              0
                            )}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                  <span className="customer-name">
                    {BuylistRulesStore.buylistDetails.shopifyCustomer.firstName}{" "}
                    {BuylistRulesStore.buylistDetails.shopifyCustomer.lastName}
                  </span>
                  <span className="customer-email">
                    {BuylistRulesStore.buylistDetails.shopifyCustomer.email}
                  </span>
                </div>
                <div className="card-body">
                  <span className="label">Buylst:</span>
                  <span className="value" data-testid="buylistId">
                    #{BuylistRulesStore.buylistDetails.id}
                  </span>
                  <span className="label">Date Submitted:</span>
                  <span className="value">
                    {TimeUtils.convertDateToHumanReadable(
                      BuylistRulesStore.buylistDetails.readableSubmittedDate
                    )}
                  </span>
                  {BuylistRulesStore.buylistDetails.completed && (
                    <>
                      <span className="label">Date Completed:</span>
                      <span className="value">
                        {TimeUtils.convertDateToHumanReadable(
                          BuylistRulesStore.buylistDetails.readableCompletedDate
                        )}
                      </span>
                      <span className="label">Approved:</span>
                      <span className="value">
                        {BuylistRulesStore.buylistDetails.approved
                          ? "Yes"
                          : "No"}
                      </span>
                    </>
                  )}
                  {BuylistRulesStore.buylistDetails.completed && (
                    <>
                      {BuylistRulesStore.buylistDetails.approved ? (
                        <>
                          <span className="label">Approval note:</span>
                          <span className="value">
                            {BuylistRulesStore.buylistDetails.approvedNotes &&
                            BuylistRulesStore.buylistDetails.approvedNotes != ""
                              ? BuylistRulesStore.buylistDetails.approvedNotes
                              : "No approval notes provided"}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="label">Reason for declining:</span>
                          <span className="value">
                            {BuylistRulesStore.buylistDetails.declinedReason &&
                            BuylistRulesStore.buylistDetails.declinedReason !=
                              ""
                              ? BuylistRulesStore.buylistDetails.declinedReason
                              : "There was no reason provided for declining this buylist"}
                          </span>
                        </>
                      )}
                    </>
                  )}
                  <span className="label">Payment Type:</span>
                  <span className="value">
                    {BuylistRulesStore.buylistDetails.paymentType}
                  </span>
                  <span className="label">Buylist Value:</span>
                  <span className="value">{fC(buylistTotal)}</span>
                </div>
              </div>
            </div>
            <br />
            <div className="hidden">
              <PrintBuylist ref={(el) => (this.componentRef = el)} />
            </div>
            <div className="row buylist-controls">
              <div className="col sort">
                Sort by{" "}
                <select
                  className="mr-sm-2"
                  onChange={(event) => this.setSortField(event.target.value)}
                  data-testid="select-sort"
                >
                  <option value="game">Game</option>
                  <option value="name">Card Name</option>
                  <option value="set">Set Name</option>
                  <option value="buy">Total Buy Price</option>
                  <option value="sell">Total Sell Price</option>
                  <option value="qty">Quantity</option>
                </select>{" "}
                <SortDirection
                  ascending={this.sortAscending}
                  setAscending={this.setSortAscending}
                />
              </div>
              <div className="viewChange col">
                <ReactToPrint
                  onBeforePrint={() => {
                    const printWindow = document.getElementById("printWindow");
                    if (printWindow) {
                      printWindow.style.width = "100%";
                    }
                  }}
                  trigger={() => (
                    <button className="printBuylist">
                      <i className="far fa-print" /> Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />
                <button
                  type="button"
                  className="printBuylist"
                  onClick={this.saveToCSV}
                >
                  <i className="far fa-save" /> Export CSV
                </button>
                <span
                  style={{ marginRight: "20px" }}
                  className={this.cookie.value == "disabled" ? "active" : ""}
                  onClick={this.changeGridView}
                >
                  <i className="fas fa-th" /> Grid View
                </span>
                <span
                  className={this.cookie.value == "disabled" ? "" : "active"}
                  onClick={this.changeListView}
                >
                  <i className="fas fa-th-list" /> List View
                </span>
              </div>
            </div>
            <Spinner isLoading={this.isLoading}>
              {this.listView == "enabled" ? (
                <ListView
                  onVariantQuantityChange={this.onVariantQuantityChange}
                  onVariantBuyPriceChange={this.onVariantBuyPriceChange}
                  removeLine={this.removeLine}
                  setSearchModalVisible={this.setSearchModalVisible}
                />
              ) : (
                <GridView
                  onVariantQuantityChange={this.onVariantQuantityChange}
                  onVariantBuyPriceChange={this.onVariantBuyPriceChange}
                  removeLine={this.removeLine}
                  setSearchModalVisible={this.setSearchModalVisible}
                />
              )}
            </Spinner>
            <div className="actions buylistReview">
              <div className="buttons">
                {BuylistRulesStore.buylistDetails.completed === true ? (
                  <Link to="/buylists/completed" className="btn1">
                    Done
                  </Link>
                ) : (
                  <>
                    {BuylistRulesStore.buylistDetails.approved === true ? (
                      <>
                        <button
                          className="btn1"
                          onClick={() =>
                            this.saveChanges(BuylistRulesStore.buylistDetails)
                          }
                        >
                          Save changes
                        </button>
                        &nbsp;
                        <button
                          className="btn1 decline"
                          onClick={() =>
                            this.showDeclineModal(
                              BuylistRulesStore.buylistDetails
                            )
                          }
                        >
                          Decline
                        </button>
                        &nbsp;
                        <button
                          className="btn1"
                          onClick={() =>
                            this.showCompleteModal(
                              BuylistRulesStore.buylistDetails
                            )
                          }
                        >
                          Complete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn1"
                          onClick={() =>
                            this.saveChanges(BuylistRulesStore.buylistDetails)
                          }
                        >
                          Save changes
                        </button>
                        &nbsp;
                        <button
                          className="btn1"
                          onClick={() =>
                            this.showApproveModal(
                              BuylistRulesStore.buylistDetails
                            )
                          }
                        >
                          Approve
                        </button>
                        &nbsp;
                        <button
                          className="btn1 decline"
                          onClick={() =>
                            this.showDeclineModal(
                              BuylistRulesStore.buylistDetails
                            )
                          }
                        >
                          Decline
                        </button>
                        &nbsp;
                        <Link to="/buylists/pending" className="btn1 cancel">
                          Cancel
                        </Link>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {this.searchModalVisible && (
              <SearchModal
                setVisible={this.setSearchModalVisible}
                addCardToList={this.addCardToList}
              />
            )}
            {this.declineModalVisible && (
              <DeclineModal
                isLoading={this.declineModalLoading}
                setVisible={this.setDeclineModalVisible}
                declineBuylist={this.declineBuylist}
              />
            )}
            {this.approveModalVisible ? (
              <ApproveBuylistModal
                zeroQuantityDetected={zeroQuantityDetected}
                paymentType={BuylistRulesStore.buylistDetails.paymentType}
                buylistDetail={this.buylistDetailToApprove}
                handleClose={() => this.setApproveModalVisible(false)}
              />
            ) : null}
            {this.completeModalVisible ? (
              <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card">
                  <form ref={this.formRef} noValidate>
                    <header className="modal-card-head">
                      <p className="modal-card-title">
                        {this.completeModalTitle}
                      </p>
                      <button
                        className="delete"
                        aria-label="close"
                        disabled={this.completeModalLoading}
                        onClick={() => this.setCompleteModalVisible(false)}
                      >
                        <i className="fal fa-times" /> Close
                      </button>
                    </header>
                    <section className="modal-card-body">
                      {zeroQuantityDetected && (
                        <div className="warning">
                          <i className="fas fa-exclamation-triangle" />
                          One of more cards in this buylist have no quantity
                          set. To go back and correct this, click{" "}
                          <em>Cancel</em>.
                        </div>
                      )}
                      <div className="wrapper reviewInputs">
                        {BuylistRulesStore.buylistDetails.approved ? (
                          <p>Are you sure you want to complete this buylist?</p>
                        ) : (
                          <div className="field">
                            <label className="label">
                              Do you want to add any notes? These will be sent
                              to the customer once completed.
                            </label>
                            <div className="control">
                              <textarea
                                id="approveReason"
                                className="input"
                                placeholder="E.g. Most cards are in great condition! Thank you."
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className="field"
                          style={
                            BuylistRulesStore.buylistDetails.pushProducts
                              ? { visibility: "hidden", display: "inline" }
                              : {}
                          }
                        >
                          <label className="checkbox">
                            <input id="pushStock" type="checkbox" />
                            <span className="checkmark" />
                            &nbsp; Push stock automatically
                          </label>
                        </div>
                        {BuylistRulesStore.buylistDetails.paymentType ==
                        "Store Credit" ? (
                          <div
                            className="field"
                            style={
                              BuylistRulesStore.buylistDetails.applyStoreCredit
                                ? { visibility: "hidden", display: "inline" }
                                : {}
                            }
                          >
                            <label className="checkbox">
                              <input id="applyStoreCredit" type="checkbox" />
                              <span className="checkmark" />
                              &nbsp; Apply store credit
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </section>
                    <footer className="modal-card-foot">
                      <button
                        type="button"
                        className="modalBtn cancel"
                        onClick={() => this.setCompleteModalVisible(false)}
                        disabled={this.completeModalLoading}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`modalBtn action ${
                          this.completeModalLoading ? " is-loading" : ""
                        }`}
                        disabled={this.completeModalLoading}
                        onClick={() => this.completeBuylist()}
                        data-testid="confirm-complete"
                      >
                        {this.completeModalLoading ? "Processing" : "Complete"}
                      </button>
                    </footer>
                  </form>
                </div>
              </div>
            ) : null}

            {this.modalVisible ? (
              <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-card">
                  <header className="modal-card-head">
                    <p className="modal-card-title">{this.modalTitle}</p>
                  </header>
                  <section
                    className="modal-card-body"
                    dangerouslySetInnerHTML={{ __html: this.modalText }}
                  />
                  <footer className="modal-card-foot">
                    {this.hasValidationErrors || this.noRedirect ? (
                      <button
                        className="modalBtn action full"
                        onClick={() => this.setModalVisible(false)}
                      >
                        Ok
                      </button>
                    ) : (
                      <>
                        {BuylistRulesStore.buylistDetails.finalBuylistDetails &&
                        BuylistRulesStore.buylistDetails.finalBuylistDetails
                          .length > 0 ? (
                          <Link
                            to="/buylists/completed"
                            className="modalBtn action full"
                          >
                            Ok
                          </Link>
                        ) : (
                          <>
                            {BuylistRulesStore.buylistDetails.approved ? (
                              <Link
                                to="/buylists/approved"
                                className="modalBtn action full"
                              >
                                Ok
                              </Link>
                            ) : (
                              <Link
                                to="/buylists/pending"
                                className="modalBtn action full"
                              >
                                Ok
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </footer>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default BuylistDetails;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { fetchTills } from "../../api/rest/posSettings";

@inject("store")
@observer
class TillSelect extends Component {
  @observable tillList = [];
  @action setTillList = (tills) => {
    this.tillList = tills;
  };

  componentDidMount() {
    this.loadTills();
  }

  loadTills = () => {
    fetchTills()
      .then((result) => {
        this.setTillList(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tills",
          "There was an error retrieving your list of tills. Please refresh and try again"
        );
      });
  };

  render() {
    const { value, setValue, title } = this.props;

    return (
      <select
        onChange={(event) => setValue(event.target.value)}
        value={value}
        title={title}
      >
        <option value="">- All -</option>
        {this.tillList.map((till) => (
          <option value={till.id} key={till.id}>
            {till.name}
          </option>
        ))}
      </select>
    );
  }
}

TillSelect.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default TillSelect;

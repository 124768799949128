import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

interface TabNavigationProps {
  integrationId: string;
}

function TabNavigation({ integrationId }: TabNavigationProps) {
  return (
    <ul className="integration-tab-navigation">
      <li>
        <NavLink
          to={`/integrations/${integrationId}`}
          exact
          activeClassName="selected"
        >
          <span>Bulk Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/integrations/${integrationId}/advanced`}
          exact
          activeClassName="selected"
        >
          <span>Individual Settings</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`/integrations/${integrationId}/variants`}
          exact
          activeClassName="selected"
        >
          <span>Review Variants</span>
        </NavLink>
      </li>
      {integrationId === "ebay" ? (
        <>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/policies`}
              activeClassName="selected"
            >
              <span>Ebay Policies</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/logs`}
              activeClassName="selected"
            >
              <span>Logs</span>
            </NavLink>
          </li>
        </>
      ) : null}
      {integrationId === "cfbmarket" ? (
        <>
          <li>
            <NavLink
              to={`/integrations/${integrationId}/configure`}
              activeClassName="selected"
            >
              <span>Configure</span>
            </NavLink>
          </li>
        </>
      ) : null}
    </ul>
  );
}

TabNavigation.propTypes = {
  integrationId: PropTypes.string.isRequired,
};

export default TabNavigation;

import React from "react";
import "./BasicModal.scss";

interface BasicModalProps {
  title: string;
  small?: boolean;
  onClose: Function;
  children: React.ReactChild;
}

function BasicModal(props: BasicModalProps) {
  const { title, onClose, small, children } = props;

  return (
    <div className="basic-modal modal is-active">
      <div className="modal-background" />
      <div
        className={small ? "modal-card" : "basic-modal__container modal-card"}
      >
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => onClose()}
          />
        </header>
        <section
          className={
            small ? "modal-card-body" : "basic-modal__contents modal-card-body"
          }
        >
          {children}
        </section>
        <footer className="modal-card-foot">
          <button className="modalBtn action full" onClick={() => onClose()}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

export default BasicModal;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { remapTenderName } from "../../utils/tenders";
import "./TenderPayment.scss";

@inject("posStore")
@observer
class TenderPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentAmount: "",
    };
  }

  setPaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  addPaymentAmount = (value) => {
    this.setState({
      paymentAmount: Number(this.state.paymentAmount) + Number(value),
    });
  };

  renderCashDenominations = () => {
    const { tenderType } = this.props;
    const store = this.props.posStore;

    if (tenderType.toLowerCase() !== "cash") return null;

    return (
      <div className="tenderPayment__cashDenominations">
        {store.cashDenoms.map((demonination) => (
          <button
            className="tenderPayment__cashDenomination"
            key={demonination}
            onClick={() => this.addPaymentAmount(demonination)}
          >
            {store.fCurr(demonination)}
          </button>
        ))}
      </div>
    );
  };

  getPayBalanceButton = () => {
    const { tenderType, remainingBalance, remainingStoreCredit, onAddTender } =
      this.props;
    if (remainingBalance === 0) return null;
    const { fCurr } = this.props.posStore;
    if (
      tenderType.toLowerCase() === "store credit" &&
      remainingStoreCredit < remainingBalance
    ) {
      return (
        <button
          className="tenderPayment__payRemainingButton"
          onClick={() => onAddTender(tenderType, remainingStoreCredit)}
        >
          Use all available store credit: {fCurr(remainingStoreCredit)}
        </button>
      );
    }
    return (
      <button
        className="tenderPayment__payRemainingButton"
        onClick={() => onAddTender(tenderType, remainingBalance)}
      >
        Pay remaining balance: {fCurr(remainingBalance)}
      </button>
    );
  };

  getPaymentErrors = () => {
    const { tenderType, remainingStoreCredit, remainingBalance } = this.props;
    if (tenderType.toLowerCase() === "store credit") {
      if (this.state.paymentAmount > remainingStoreCredit) {
        return "Amount exceeds the store credit available";
      }
      if (this.state.paymentAmount > remainingBalance) {
        return "Amount exceeds the balance left to pay";
      }
    }
    return null;
  };

  render() {
    const { tenderType, remainingStoreCredit, onAddTender, onClose } =
      this.props;

    const paymentErrors = this.getPaymentErrors(remainingStoreCredit);

    return (
      <div className="modal display-block" data-testid="tenderPayment">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{remapTenderName(tenderType)}</p>
            <button className="delete" aria-label="close" onClick={onClose}>
              <i className="fal fa-times"></i> Close
            </button>
          </header>
          <section className="modal-card-body">
            <div className="tenderPayment__remaining">
              {this.getPayBalanceButton()}
            </div>
            <div className="tenderPayment__other">
              <label htmlFor="paymentAmount">Pay other amount:</label>
              <input
                id="paymentAmount"
                value={this.state.paymentAmount}
                onChange={(event) => this.setPaymentAmount(event.target.value)}
              />
              {this.renderCashDenominations()}
            </div>
            {paymentErrors !== null ? (
              <div className="tenderPayment__error">{paymentErrors}</div>
            ) : null}
          </section>
          <footer className="modal-card-foot">
            <button className="modalBtn cancel" onClick={onClose}>
              <i className="fal fa-times" /> Cancel
            </button>
            <button
              className={`modalBtn action`}
              disabled={paymentErrors !== null}
              onClick={() =>
                onAddTender(tenderType, Number(this.state.paymentAmount))
              }
            >
              <i className="fas fa-caret-right" /> Continue
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default TenderPayment;

import React, { useEffect } from "react";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/globalSettingsHooks";
import "./ToggleVariantsButton.scss";

const SETTING_NAME = "collapseProductVariants";

interface ToggleVariantsButtonProps {
  collapseVariants: boolean;
  setCollapseVariants: (collapse: boolean) => void;
}

function ToggleVariantsButton(props: ToggleVariantsButtonProps) {
  const { collapseVariants, setCollapseVariants } = props;
  const updateSetting = useLocalSettingUpdate();
  const currentSetting = Boolean(useLocalSetting(SETTING_NAME));

  useEffect(() => {
    setCollapseVariants(currentSetting);
    updateSetting(SETTING_NAME, currentSetting);
  }, [currentSetting]);

  const handleClick = () => {
    const newValue = !collapseVariants;
    setCollapseVariants(newValue);
    updateSetting(SETTING_NAME, newValue);
  };

  return (
    <button className="ToggleVariantsButton" onClick={handleClick}>
      {collapseVariants ? "Show all variants" : "Hide all variants"}{" "}
      <i
        className={`fas fa-angle-double-${collapseVariants ? "down" : "up"}`}
      />
    </button>
  );
}

export default ToggleVariantsButton;

import React from "react";
import { EbaySettingBase } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import LinkComponent from "../../components/generic/LinkComponent";
import "./EbaySettingsList.scss";

interface EbaySettingsListProps {
  settings: EbaySettingBase[];
  deletingSetting: number;
  refreshSettings?: Function;
  setEditSetting: Function;
  setDeletingSetting: Function;
}

function EbaySettingsList(props: EbaySettingsListProps) {
  const {
    settings,
    deletingSetting,
    refreshSettings,
    setEditSetting,
    setDeletingSetting,
  } = props;
  return (
    <div className="ebay-settings-list">
      <div className="ebay-settings-list__entries">
        {settings.map((setting: EbaySettingBase) => (
          <React.Fragment key={setting.id}>
            <span key={setting.id} className="ebay-settings-list__entry-name">
              {setting.name}
            </span>
            <ButtonComponent
              key={`edit_${setting.id}`}
              icon="fas fa-edit"
              onClick={() => setEditSetting(setting.id)}
            >
              Edit
            </ButtonComponent>
            <ButtonComponent
              key={`delete_${setting.id}`}
              icon="fas fa-trash-alt"
              onClick={() => setDeletingSetting(setting.id)}
              disabled={deletingSetting === setting.id}
            >
              Delete
            </ButtonComponent>
          </React.Fragment>
        ))}
      </div>
      <div className="ebay-settings-list__general-actions">
        <ButtonComponent icon="fas fa-plus" onClick={() => setEditSetting(-1)}>
          Add
        </ButtonComponent>
        {refreshSettings ? (
          <ButtonComponent icon="fas fa-sync" onClick={() => refreshSettings()}>
            Refresh Policies from Ebay
          </ButtonComponent>
        ) : null}
        <div>
          <LinkComponent to="/integrations/ebay/policies">
            Return to policies
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

EbaySettingsList.defaultProps = {
  refreshSettings: null,
};

export default EbaySettingsList;

import React, { Component } from "react";
import { remapTenderName, getTenderIcon } from "../../utils/tenders";
import "./TenderSelect.scss";

class TenderSelect extends Component {
  render() {
    const { tenders, storeCreditAvailable, onTenderSelect, onClose } =
      this.props;

    return (
      <div className="modal display-block">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Select Payment Method</p>
            <button className="delete" aria-label="close" onClick={onClose}>
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            <div className="tenderSelect">
              {tenders?.map((tender) => (
                <button
                  className="tenderSelect__tender"
                  key={tender}
                  disabled={tender === "Store Credit" && !storeCreditAvailable}
                  onClick={() => onTenderSelect(tender)}
                >
                  <span className="tenderSelect__label">
                    <i className={getTenderIcon(tender)} />
                    {remapTenderName(tender)}
                  </span>
                </button>
              ))}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default TenderSelect;

import { getWPNReportData } from "../../api/graphql/reports";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import { Link } from "react-router-dom";
import { reformatWPNReport } from "../../utils/reports";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class WPNReport extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getWPNReportData(fromDate, toDate)
      .then((data) => {
        console.log({ wpnReportData: data });
        const reportData = reformatWPNReport(data);
        this.props.store.ReportsStore.setSourceData(reportData);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your WPN report. Please try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;
    const { transactionId, upc, description = "" } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_transaction_id" && (
                <TextInputFilter
                  value={transactionId}
                  setValue={(value) =>
                    updateFilterValue("transactionId", value)
                  }
                />
              )}
              {key === "col_upc" && (
                <TextInputFilter
                  value={upc}
                  setValue={(value) => updateFilterValue("upc", value)}
                />
              )}
              {key === "col_description" && (
                <TextInputFilter
                  value={description}
                  setValue={(value) => updateFilterValue("description", value)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "transaction id",
      condition: (filterValues) =>
        filterValues.transactionId && filterValues.transactionId.length,
      filterFunction: (row, filterValues) =>
        row.transactionId &&
        row.transactionId
          .toLowerCase()
          .includes(filterValues.transactionId.toLowerCase()),
    },
    {
      name: "upc",
      condition: (filterValues) => filterValues.upc && filterValues.upc.length,
      filterFunction: (row, filterValues) =>
        row.upc &&
        row.upc.toLowerCase().includes(filterValues.upc.toLowerCase()),
    },
    {
      name: "description",
      condition: (filterValues) =>
        filterValues.description && filterValues.description.length,
      filterFunction: (row, filterValues) =>
        row.description &&
        row.description
          .toLowerCase()
          .includes(filterValues.description.toLowerCase()),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Transaction Time", key: "transactionTime" },
      { name: "Transaction Id", key: "transactionId" },
      { name: "UPC", key: "UPC" },
      { name: "Product Description", key: "description" },
      {
        name: "Individual Unit Price",
        key: "unitPrice",
        formatter: (number) => number && number.toFixed(2),
      },
      { name: "Quantity Sold", key: "quantity" },
      {
        name: "Total Sale Price",
        key: "totalPrice",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Currency",
        key: "currency",
        formatter: () => this.props.store.MainStore.currencyCode,
      },
    ];

    const csvFilename = "WPN Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">WPN Report</span>
          </h2>
        </div>
        <div className="report">
          <div className="info">
            <Link to="/settings/reports">Click here</Link> to select which
            product types appear in this report.
          </div>
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col_transaction_time"
                        dataKey="transactionTime"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        title="Transaction Time"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_transaction_id"
                        dataKey="transactionId"
                        width={100}
                        title="Transaction Id"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_upc"
                        dataKey="UPC"
                        width={100}
                        title="UPC"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_description"
                        dataKey="description"
                        width={300}
                        flexGrow={1}
                        title="Description"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_unit_price"
                        dataKey="unitPrice"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Individual Unit Price"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_quantity"
                        dataKey="quantity"
                        width={100}
                        align="right"
                        title="Quantity Sold"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_total_price"
                        dataKey="totalPrice"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Total Sale Price"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_currency"
                        title="Currency"
                        width={100}
                        align="right"
                        cellRenderer={() => MainStore.currencyCode}
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

WPNReport.propTypes = { store: PropTypes.object };

export default WPNReport;

import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Spinner } from "../../components";

class DeclineModal extends Component {
  form = createRef();

  render() {
    const { isLoading, setVisible, declineBuylist } = this.props;

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <Spinner isLoading={isLoading}>
            <form
              ref={this.form}
              onSubmit={(event) =>
                declineBuylist(
                  event,
                  this.form.current.elements.declineReason.value
                )
              }
              noValidate
            >
              <header className="modal-card-head">
                <p className="modal-card-title">Decline buylist request</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => setVisible(false)}
                  type="button"
                >
                  <i className="fal fa-times" /> Close
                </button>
              </header>
              <section className="modal-card-body">
                <div className="wrapper reviewInputs">
                  <div className="field">
                    <label className="label">
                      Enter the reason for declining the buylist. This will be
                      emailed to the customer, leave blank for no email to be
                      sent.
                    </label>
                    <div className="control">
                      <textarea
                        id="declineReason"
                        className="input"
                        placeholder="E.g. Most cards are too damaged to sell"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot">
                <button
                  type="button"
                  className="modalBtn cancel"
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="modalBtn action"
                  data-testid="confirm-decline"
                >
                  Decline
                </button>
              </footer>
            </form>
          </Spinner>
        </div>
      </div>
    );
  }
}

DeclineModal.propTypes = {
  isLoading: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  declineBuylist: PropTypes.func.isRequired,
};

export default DeclineModal;

import { getStockInventoryValue } from "../../api/graphql/reports";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import { reformatStockInventoryValue } from "../../utils/reports";

@inject("store")
@observer
class StockValue extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  onLoadDataClick = () => {
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getStockInventoryValue()
      .then((data) => {
        const reportData = reformatStockInventoryValue(data);
        this.props.store.ReportsStore.setSourceData(reportData);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your stock value report. Please try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { tableData, filterValues, updateFilterValue } =
      this.props.store.ReportsStore;
    const { productType = "" } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_productType" && (
                <TextInputFilter
                  value={productType}
                  setValue={(value) => updateFilterValue("productType", value)}
                />
              )}
              {key === "col_value" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.totalValue, 0)
                  }
                  style={{ textAlign: "right" }}
                />
              )}
              {key === "col_cost" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.totalCost, 0)
                  }
                  style={{ textAlign: "right" }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "product type",
      condition: (filterValues) =>
        filterValues.productType && filterValues.productType.length,
      filterFunction: (row, filterValues) =>
        row.productType &&
        row.productType
          .toLowerCase()
          .includes(filterValues.productType.toLowerCase()),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Product Type", key: "productType" },
      { name: "Count", key: "count" },
      {
        name: "Value",
        key: "totalValue",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cost",
        key: "totalCost",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "Stock Inventory Value Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Stock Inventory Value</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            loadData={this.onLoadDataClick}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col_productType"
                        dataKey="productType"
                        width={200}
                        flexGrow={1}
                        title="Product Type"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_count"
                        dataKey="count"
                        width={100}
                        title="Count"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_value"
                        dataKey="totalValue"
                        width={150}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Value"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cost"
                        dataKey="totalCost"
                        width={150}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Cost"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

StockValue.propTypes = { store: PropTypes.object };
export default StockValue;

import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, RefObject } from "react";
import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";
import Customer from "../../views/customers/Customer";
import CustomerLine from "./CustomerLine";
import CustomerNote from "./CustomerNote";

interface CustomerSelectProps {
  posStore: any;
}

@inject("posStore")
@observer
class CustomerSelect extends Component<CustomerSelectProps> {
  autocomplete: RefObject<HTMLDivElement>;
  search: string;

  @observable
  lastController: AbortController;

  constructor(props: CustomerSelectProps) {
    super(props);
    this.state = {};
    this.autocomplete = React.createRef();
  }

  handleClick(event: MouseEvent) {
    if (
      !this.autocomplete.current ||
      this.autocomplete.current.contains(event.target as Node)
    )
      return;
    this.props.posStore.addCustomerResults([]);
    this.props.posStore.setCustomerInput("");
  }

  handleKeypress(event: KeyboardEvent) {
    if (event.key !== "Escape") return;
    this.props.posStore.addCustomerResults([]);
    this.props.posStore.setCustomerInput("");
  }

  componentDidMount() {
    document.addEventListener("click", (event) => this.handleClick(event));
    document.addEventListener("keydown", (event) => this.handleKeypress(event));
  }

  componentWillUnmount() {
    document.removeEventListener("click", (event) => this.handleClick(event));
    document.removeEventListener("keydown", (event) =>
      this.handleKeypress(event)
    );
  }

  @observable showAddCustomer = false;
  @action setShowAddCustomer = (showAddCustomer: boolean) => {
    this.showAddCustomer = showAddCustomer;
  };

  closeCustomerModal = (value: boolean) => {
    this.setShowAddCustomer(value);
  };

  @action setSelectedCustomer = (customer: any) => {
    this.props.posStore.selectedCustomer = customer;
    this.props.posStore.validateCart();
  };

  @action
  customerSearch = () => {
    if (!this.props.posStore.customerInput)
      return (this.props.posStore.customerResults = []);

    this.lastController?.abort();
    const controller = new AbortController();
    this.lastController = controller;
    _fetch({
      endpoint:
        `${BASE_URL}/customers?keyword=` +
        encodeURIComponent(this.props.posStore.customerInput),
      method: "GET",
      signal: controller.signal,
    }).then((data) => {
      if (this.props.posStore.customerInput) {
        this.props.posStore.addCustomerResults(data);
      }
    });
  };

  @action
  clearCustomer = () => {
    this.props.posStore.selectedCustomer = null;
    this.props.posStore.customerInput = "";
    //zero the tenders out so store credit doesn't get stuck with non zero value
    this.props.posStore.zeroTenders();
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.posStore.customerInput = e.target.value;
    e.target.className = "customerChoosen";
    this.customerSearch();
  };

  render() {
    const store = this.props.posStore;
    return (
      <React.Fragment>
        <div
          className={
            store.selectedCustomer
              ? "customer-select selected"
              : "customer-select"
          }
        >
          <i className="far fa-search"></i>
          <input
            className={store.selectedCustomer ? "customerChosen" : ""}
            onClick={this.clearCustomer}
            type="search"
            placeholder="Select a customer"
            value={
              store.selectedCustomer
                ? (store.selectedCustomer.firstName &&
                  store.selectedCustomer.firstName.length > 0
                    ? store.selectedCustomer.firstName
                    : "") +
                  " " +
                  (store.selectedCustomer.lastName &&
                  store.selectedCustomer.lastName.length > 0
                    ? store.selectedCustomer.lastName
                    : "")
                : store.customerInput
            }
            onChange={this.handleInputChange}
          />
          <span
            title="Add a new customer"
            className="userActions addCustomer"
            onClick={() => this.setShowAddCustomer(true)}
          >
            <i className="fal fa-users-medical"></i>
          </span>
          {store.selectedCustomer ? (
            <div className="CustomerInfo">
              <span onClick={this.clearCustomer}>
                <strong>Email:</strong> {store.selectedCustomer.email}
              </span>
              <div className="CustomerInfo__row">
                <CustomerNote customer={store.selectedCustomer} />
                <span>
                  <strong>Credit:</strong>{" "}
                  {store.fCurr(store.selectedCustomer.storeCredit?.toFixed(2))}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            ref={this.autocomplete}
            className={
              store.customerResults.length === 0 || !store.customerResults[0]
                ? "autocomplete-items hidden"
                : "autocomplete-items"
            }
          >
            {store.customerResults.length === 0 || !store.customerResults[0]
              ? null
              : store.customerResults
                  .slice(0, 10)
                  .map((customer: any) => (
                    <CustomerLine
                      store={store}
                      key={customer.id}
                      customer={customer}
                      onSelect={() => this.lastController.abort()}
                    />
                  ))}
          </div>
        </div>
        {this.showAddCustomer ? (
          <div className="modal is-active">
            <div
              className="modal-background"
              onClick={() => this.setShowAddCustomer(false)}
            />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Add new customer</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => this.setShowAddCustomer(false)}
                >
                  <i className="fal fa-times"></i> Close
                </button>
              </header>
              <section className="modal-card-body POScustomer">
                <Customer
                  isPos={true}
                  setShowAddCustomer={(value: boolean) =>
                    this.closeCustomerModal(value)
                  }
                  setSelectedCustomerForPOS={(customer: any) =>
                    this.setSelectedCustomer(customer)
                  }
                />
              </section>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CustomerSelect;

/**
 * Reformats buylist details so that cards of different grouped together under
 * the same parent card.
 *
 * @param {object} buylistDetails
 *
 * @returns {object}
 */
export function groupBuylistVariants(buylistDetails = {}) {
  if (
    !Object.prototype.hasOwnProperty.call(
      buylistDetails,
      "shopifyCustomerBuylistDetails"
    )
  ) {
    return buylistDetails;
  }

  const { completed, finalBuylistDetails, shopifyCustomerBuylistDetails } =
    buylistDetails;
  let cards;
  if (completed && finalBuylistDetails && finalBuylistDetails.length) {
    cards = finalBuylistDetails;
  } else {
    cards = shopifyCustomerBuylistDetails;
  }
  const cardsHash = {};
  cards.forEach((card) => {
    const variant = {
      cashBuyPrice: card.cashBuyPrice,
      id: card.id,
      quantity: card.quantity,
      storeCreditBuyPrice: card.storeCreditBuyPrice,
      storeCreditSellPrice: card.storeCreditSellPrice,
      variantId: card.variantId,
      variantName: card.variantName,
    };
    const { cardName, cardId, setName, type } = card;
    const key = [cardName, cardId, setName, type].join("---");
    if (Object.keys(cardsHash).includes(key)) {
      const _card = cardsHash[key];
      _card.variants[variant.variantId] = variant;
      cardsHash[key] = _card;
    } else {
      const _card = {
        cardId: card.cardId,
        cardName: card.cardName,
        foil: card.foil,
        gameId: card.gameId,
        game: card.game,
        imageUrl: card.imageUrl,
        setName: card.setName,
        shopifyCustomerBuylistId: card.shopifyCustomerBuylistId,
        type: card.type,
        variants: {
          [variant.variantId]: variant,
        },
      };
      cardsHash[key] = _card;
    }
  });
  buylistDetails.cards = Object.values(cardsHash);
  return buylistDetails;
}

/**
 * Reformats buylist details so that each variant has its own entry, as
 * expected by the API
 *
 * @param {object} buylistDetails
 *
 * @returns {object}
 */
export function splitBuylistVariants(buylistDetails = {}) {
  if (!Object.prototype.hasOwnProperty.call(buylistDetails, "cards")) {
    return buylistDetails;
  }
  const shopifyCustomerBuylistDetails = [];
  buylistDetails.cards.forEach((card) => {
    Object.values(card.variants).forEach((variant) => {
      // destructuring used to remove variants
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { variants, ...cardDetails } = card;
      const _variant = {
        ...cardDetails,
        ...variant,
      };
      if (variant.quantity > 0) {
        shopifyCustomerBuylistDetails.push(_variant);
      }
    });
  });
  const response = {
    ...buylistDetails,
    shopifyCustomerBuylistDetails,
  };
  delete response.cards;
  return response;
}

/**
 * Adds up the total buy price of all the selected variants of a card
 *
 * @param {object} variants
 * @param {string} paymentType
 *
 * @returns {float}
 */
export function getTotalBuyPrice(variants, paymentType) {
  const isPaidInCash = paymentType === "Cash";
  return Object.values(variants).reduce(
    (sum, variant) =>
      sum +
      parseFloat(variant.quantity) *
        parseFloat(
          isPaidInCash ? variant.cashBuyPrice : variant.storeCreditBuyPrice
        ),
    0
  );
}

/**
 * Adds up the total sell price of all the selected variants of a card
 *
 * @param {object} variants
 *
 * @returns {float}
 */
export function getTotalSellPrice(variants) {
  return Object.values(variants).reduce(
    (sum, variant) =>
      sum +
      (variant.quantity > 0
        ? parseFloat(variant.quantity) * parseFloat(variant.storeSellPrice)
        : 0),
    0
  );
}

/**
 * Adds up the total quantities of all the selected variants of a card
 *
 * @param {object} variants
 *
 * @returns {number}
 */
export function getTotalQuantity(variants) {
  return Object.values(variants).reduce(
    (sum, variant) => sum + parseInt(variant.quantity, 10),
    0
  );
}

/**
 * Returns the appropriate compare function to be used in the sorting of
 * buylist cards
 *
 * @param {string} field
 * @param {boolean} ascending
 * @param {string} paymentType
 *
 * @returns {function}
 */
export function getSortFunction(field, ascending, paymentType) {
  const cmp1 = ascending ? -1 : 1;
  const cmp2 = ascending ? 1 : -1;
  switch (field) {
    case "game":
      return (a, b) => {
        const gameA = a.gameId ? a.gameId : a.game;
        const gameB = b.gameId ? b.gameId : b.game;
        if (gameA < gameB) return cmp1;
        if (gameA > gameB) return cmp2;
        return 0;
      };
    case "set":
      return (a, b) => {
        if (a.setName < b.setName) return cmp1;
        if (a.setName > b.setName) return cmp2;
        return 0;
      };
    case "buy":
      return (a, b) => {
        const priceA = getTotalBuyPrice(a.variants, paymentType);
        const priceB = getTotalBuyPrice(b.variants, paymentType);
        if (priceA < priceB) return cmp1;
        if (priceA > priceB) return cmp2;
        return 0;
      };
    case "sell":
      return (a, b) => {
        const priceA = getTotalSellPrice(a.variants);
        const priceB = getTotalSellPrice(b.variants);
        if (priceA < priceB) return cmp1;
        if (priceA > priceB) return cmp2;
        return 0;
      };
    case "qty":
      return (a, b) => {
        const qtyA = getTotalQuantity(a.variants);
        const qtyB = getTotalQuantity(b.variants);
        if (qtyA < qtyB) return cmp1;
        if (qtyA > qtyB) return cmp2;
        return 0;
      };
    case "name":
    default:
      return (a, b) => {
        if (a.cardName < b.cardName) return cmp1;
        if (a.cardName > b.cardName) return cmp2;
        return 0;
      };
  }
}

/**
 * Splits a list of cards into multiple arrays, each of "size" cards
 *
 * @param {Array} list
 * @param {number} size
 *
 * @returns {Array}
 */
export const chunkCards = (list, size) =>
  list.reduce(
    (r, v) =>
      (!r.length || r[r.length - 1].length === size
        ? r.push([v])
        : r[r.length - 1].push(v)) && r,
    []
  );

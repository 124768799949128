import React from "react";
import { NavLink } from "react-router-dom";
import "./MobileNavItem.scss";

interface MobileNavItemProps {
  menuLink: string;
  menuIcon: string;
  menuHeaderText: string;
  menuStructure?: MenuStructure | null;
  handleClick: (menuStructure: MenuStructure | null | undefined) => void;
}

function MobileNavItem(props: MobileNavItemProps) {
  const { menuLink, menuIcon, menuHeaderText, menuStructure, handleClick } =
    props;

  return (
    <>
      <NavLink
        className="MobileNavItem__header"
        activeClassName="active"
        to={menuLink}
        onClick={() => handleClick(menuStructure)}
      >
        <i className={menuIcon} /> {menuHeaderText}
      </NavLink>
      {menuStructure?.links?.map((link) => (
        <div className="MobileNavItem__subItem" key={link.href}>
          <NavLink
            className="MobileNavItem__subItemLink"
            activeClassName="active"
            to={`/${link.href}`}
            onClick={() => handleClick(menuStructure)}
          >
            <span title={link.title}>{link.title}</span>
          </NavLink>
        </div>
      ))}
    </>
  );
}

export default MobileNavItem;

import { _fetch, paramsToQueryString } from "../../api";
import { BASE_URL } from "../../constants/api";
import {
  DEFAULT_SUBMITTED_CARTS_PER_PAGE,
  DEFAULT_TILLS_PER_PAGE,
} from "../../constants/pos";

export async function fetchAllCarts(params = {}) {
  const queryString = paramsToQueryString(
    params,
    DEFAULT_SUBMITTED_CARTS_PER_PAGE
  );
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/pos/carts/all?submitted=true&${queryString}`,
  });
}

export async function fetchTills(params = {}) {
  const queryString = paramsToQueryString(params, DEFAULT_TILLS_PER_PAGE);
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/pos/tills?${queryString}`,
  });
}

import { inject, observer } from "mobx-react";
import React, { Component, useState } from "react";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { listRequests } from "../../utils/api";

const getDetailedMessage = (error) => {
  if (error.error === "Not Found") {
    return "Unable to find the resource you are looking for.";
  }
  return error.detailedMessage;
};

const getTraceID = (error) => {
  if (error.traceId) {
    return "Trace ID: " + error.traceId;
  }
  return null;
};

export function ErrorModal(props) {
  const { errorDetails, handleDismiss } = props;
  const [showDetails, setShowDetails] = useState(false);

  const hasValidMessage = errorDetails?.error !== undefined;

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {hasValidMessage ? errorDetails.error : "Unknown error"}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={handleDismiss}
          />
        </header>
        <section className="modal-card-body">
          {hasValidMessage ? (
            <React.Fragment>
              <div className="field">{getDetailedMessage(errorDetails)}</div>
              <div className="field">{getTraceID(errorDetails)}</div>
            </React.Fragment>
          ) : (
            <div className="field">
              An unknown error has occurred, please go back and try again. If
              the problem persists, contact BinderPOS support.
            </div>
          )}
          <br />
          <div className="modal-error-details">
            {showDetails ? (
              <>
                <b>Error Details</b>
                <ul>
                  <li>Current route {window.location.hash}</li>
                  {listRequests().map((request, index) => (
                    <li key={index}>
                      [{request.method}] {request.endpoint}: {request.status}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <ButtonComponent onClick={() => setShowDetails(true)}>
                Show Full Details
              </ButtonComponent>
            )}
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className={"modalBtn action full"} onClick={handleDismiss}>
            OK
          </button>
        </footer>
      </div>
    </div>
  );
}

@inject("store")
@observer
class ErrorModalWrapper extends Component {
  clearErrorMessage = () => {
    this.props.store.MainStore.setError(null);
  };

  render() {
    return (
      <ErrorModal
        errorDetails={this.props.store.MainStore.error}
        handleDismiss={this.clearErrorMessage}
      />
    );
  }
}

export default ErrorModalWrapper;

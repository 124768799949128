import { Loader } from "../../components";
import { SettingsMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { ToastContainer } from "react-toastify";
import SunEditor, { buttonList } from "suneditor-react";
import { StoreLogo } from "../../components/settings";

@inject("store")
@observer
class StoreSettings extends React.Component {
  @observable updatingStoreSettings = false;

  @action setUpdatingStoreSettings(bool) {
    this.updatingStoreSettings = bool;
  }

  @observable storeSettings;

  @action setStoreSettings(storeSettings) {
    this.storeSettings = storeSettings;
  }

  @observable savingSettings;

  @action setSavingSettings(savingSettings) {
    this.savingSettings = savingSettings;
  }

  @observable buylistConfirmationText;

  @action setBuylistConfirmationText(value) {
    this.buylistConfirmationText = value;
  }

  @observable buylistConfirmationTextHTML;

  @action setBuylistConfirmationTextHTML(html) {
    this.buylistConfirmationTextHTML = html;
  }

  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.buylistConfirmationTextEditor = React.createRef();
    this.buylistConfirmationTextRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Store Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.props.store.SettingsStore.fetchCustomerSettingForType(
      "buylistConfirmationText"
    )
      .then((result) => {
        console.log({ customerSetting: result });
        this.setBuylistConfirmationText(result.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load setting",
          "There was an error retrieving your buylist confirmation text. Please refresh and try again"
        );
      });
    this.refreshStoreSettings();
    this.props.store.SettingsStore.fetchSettings()
      .then((result) => {
        if (result) {
          this.props.store.SettingsStore.setSettings(result);
        }
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load settings",
          "There was an error retrieving your store settings. Please refresh and try again"
        );
      });
  }

  refreshStoreSettings() {
    this.setStoreSettings(null);
    this.props.store.SettingsStore.fetchStoreSettings()
      .then((result) => {
        this.setStoreSettings(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load settings",
          "There was an error refreshing your store settings. Please try again"
        );
      });
  }

  saveStoreSettings(e) {
    e.preventDefault();

    const storeSettingsToSave = {
      storeName: this.form.current.elements.storeName.value,
      addressLine1: this.form.current.elements.addressLine1.value,
      addressLine2: this.form.current.elements.addressLine2.value,
      city: this.form.current.elements.city.value,
      state: this.form.current.elements.state.value,
      country: this.form.current.elements.country.value,
      zipCode: this.form.current.elements.zipCode.value,
      phoneNumber: this.form.current.elements.phoneNumber.value,
    };

    console.log({ storeSettingsToSave });

    this.props.store.SettingsStore.updateStoreSettings(storeSettingsToSave)
      .then(() => {
        this.setUpdatingStoreSettings(false);
        this.refreshStoreSettings();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save settings",
          "There was an error submitting your store settings. Please try again"
        );
      });
  }

  updateBuylistConfirmationText() {
    const setting = {
      settingName: "buylistConfirmationText",
      settingValue: `${this.buylistConfirmationTextHTML}`,
    };
    this.props.store.SettingsStore.updateSetting(setting)
      .then(() => {
        this.props.store.MainStore.toast("Buylist confirmation text updated!");
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save setting",
          "There was an error submitting your buylist confirmation text. Please try again"
        );
        this.setSavingSettings(true);
      });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {this.props.store.SettingsStore.settings && this.storeSettings ? (
          <>
            <div className="app-header">
              <h2 className="title is-2">
                <span className="header-text">Store Settings</span>
                {!this.updatingStoreSettings ? (
                  <button
                    className="btn1"
                    onClick={() => this.setUpdatingStoreSettings(true)}
                  >
                    Edit settings
                  </button>
                ) : (
                  <button
                    className="btn1"
                    type="button"
                    onClick={() => this.setUpdatingStoreSettings(false)}
                  >
                    Cancel
                  </button>
                )}
              </h2>
            </div>
            <div className="infoInputPages">
              <div className="col-md-12 content">
                {this.updatingStoreSettings ? (
                  <form
                    ref={this.form}
                    onSubmit={(e) => this.saveStoreSettings(e)}
                    noValidate
                  >
                    <div
                      style={{
                        paddingLeft: "20px",
                        paddingTop: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div>
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <label className="label">Store name</label>
                            <div className="control">
                              <input
                                id="storeName"
                                className="input"
                                type="text"
                                required
                                placeholder="BinderPOS"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.storeName
                                    ? this.storeSettings.storeName
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <label className="label">Phone Number</label>
                            <div className="control">
                              <input
                                id="phoneNumber"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.phoneNumber
                                    ? this.storeSettings.phoneNumber
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <label className="label">Address line 1</label>
                            <div className="control">
                              <input
                                id="addressLine1"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.addressLine1
                                    ? this.storeSettings.addressLine1
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <label className="label">Address line 2</label>
                            <div className="control">
                              <input
                                id="addressLine2"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.addressLine2
                                    ? this.storeSettings.addressLine2
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-lg-6 col-xl-3">
                            <label className="label">City</label>
                            <div className="control">
                              <input
                                id="city"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings && this.storeSettings.city
                                    ? this.storeSettings.city
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 col-xl-3">
                            <label className="label">State</label>
                            <div className="control">
                              <input
                                id="state"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings && this.storeSettings.state
                                    ? this.storeSettings.state
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 col-xl-3">
                            <label className="label">Country</label>
                            <div className="control">
                              <input
                                id="country"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.country
                                    ? this.storeSettings.country
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 col-xl-3">
                            <label className="label">
                              Zip Code / Post Code
                            </label>
                            <div className="control">
                              <input
                                id="zipCode"
                                className="input"
                                type="text"
                                defaultValue={
                                  this.storeSettings &&
                                  this.storeSettings.zipCode
                                    ? this.storeSettings.zipCode
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className="button buttonWide"
                          type="submit"
                          style={{ marginRight: "10px" }}
                        >
                          Save store settings
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="infoInputPages">
                    <div className="row">
                      <div className="col-lg-12 col-xl-6">
                        <label className="label">Store name</label>
                        <div className="control">
                          <input
                            disabled
                            id="storeName"
                            className="input"
                            type="text"
                            required
                            placeholder="BinderPOS"
                            defaultValue={
                              this.storeSettings && this.storeSettings.storeName
                                ? this.storeSettings.storeName
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-6">
                        <label className="label">Phone Number</label>
                        <div className="control">
                          <input
                            disabled
                            id="phoneNumber"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings &&
                              this.storeSettings.phoneNumber
                                ? this.storeSettings.phoneNumber
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-6">
                        <label className="label">Address line 1</label>
                        <div className="control">
                          <input
                            disabled
                            id="addressLine1"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings &&
                              this.storeSettings.addressLine1
                                ? this.storeSettings.addressLine1
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-6">
                        <label className="label">Address line 2</label>
                        <div className="control">
                          <input
                            disabled
                            id="addressLine2"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings &&
                              this.storeSettings.addressLine2
                                ? this.storeSettings.addressLine2
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-6 col-xl-3">
                        <label className="label">City</label>
                        <div className="control">
                          <input
                            disabled
                            id="city"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings && this.storeSettings.city
                                ? this.storeSettings.city
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-3">
                        <label className="label">State</label>
                        <div className="control">
                          <input
                            disabled
                            id="state"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings && this.storeSettings.state
                                ? this.storeSettings.state
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-3">
                        <label className="label">Country</label>
                        <div className="control">
                          <input
                            disabled
                            id="country"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings && this.storeSettings.country
                                ? this.storeSettings.country
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6 col-xl-3">
                        <label className="label">Zip Code / Post Code</label>
                        <div className="control">
                          <input
                            disabled
                            id="zipCode"
                            className="input"
                            type="text"
                            defaultValue={
                              this.storeSettings && this.storeSettings.zipCode
                                ? this.storeSettings.zipCode
                                : "Not setup yet"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 content">
              <StoreLogo
                toast={this.props.store.MainStore.toast}
                burntToast={this.props.store.MainStore.burntToast}
              />
            </div>
            <div className="EventListing">
              <div className="col-md-12 content">
                <div className="field">
                  <label className="label">Buylist confirmation text</label>
                  <SunEditor
                    setContents={this.buylistConfirmationText}
                    onChange={(content) =>
                      this.setBuylistConfirmationTextHTML(content)
                    }
                    setOptions={{ buttonList: buttonList.complex, height: 200 }}
                  />
                </div>
                <a
                  className="button buttonWide"
                  type="button"
                  onClick={() => this.updateBuylistConfirmationText()}
                  style={{
                    color: "white",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  Update buylist confirmation text
                </a>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

StoreSettings.propTypes = { store: PropTypes.object };

export default StoreSettings;

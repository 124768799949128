import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { SignInForm } from "../../components";
import FullLogo from "../../../assets/img/fullLogo.png";
import Loader from "../../components/generic/Loader";
import SignInServiceStatus from "../../components/auth/SignInServiceStatus";
import { getCurrentUser } from "../../helpers/authHelpers";
import { signOut } from "../../services/firebase";

@inject("store")
@observer
class LoginPage extends Component {
  render() {
    if (!this.props.store.AuthStore.user) {
      return (
        <div id="login-page" className="login-center">
          <div className="row">
            <div className="col-md-7 leftBg">
              <img className="login-logo" src={FullLogo} />
            </div>
            <div className="col-md-5">
              <SignInForm />
            </div>
          </div>
        </div>
      );
    }

    const currentUser = getCurrentUser();

    return (
      <div id="login-page" className="login-center">
        <div className="row">
          <div className="col-md-7 leftBg">
            <img className="login-logo" src={FullLogo} />
          </div>
          <div className="col-md-5">
            <div className="loginWrapper">
              {this.props.store.AuthStore.screenSettings == "No account" ? (
                <>
                  <p className="title recover">
                    Not a<em>BinderPOS</em> account
                  </p>
                  <p className="wrongAccount">
                    {currentUser.email} does not have an account with BinderPOS.
                  </p>
                  <button
                    className="signin"
                    onClick={() => {
                      signOut();
                      this.props.store.AuthStore.logUserOut();
                    }}
                  >
                    Go back
                  </button>
                </>
              ) : null}
              {this.props.store.AuthStore.screenSettings === "Error" ? (
                <SignInServiceStatus
                  signOut={() => {
                    signOut();
                    this.props.store.AuthStore.logUserOut();
                  }}
                />
              ) : null}
              {this.props.store.AuthStore.screenSettings !== "No account" &&
              this.props.store.AuthStore.screenSettings !== "Error" ? (
                <>
                  <Loader />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;

import {
  Cart,
  CartModal,
  CloseTillWarning,
  FloatModal,
  Navbar,
  OrderModal,
  POSErrorModal,
  QuickLinkEditMenu,
  ReceiptModal,
  ResultsGrid,
  SearchBar,
  ViewSwipePOS,
} from "../components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PAX from "./PAX.js";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpenTillModal from "../components/pos/OpenTillModal";
import EditCustomerNote from "../components/pos/EditCustomerNote";
import OutOfStockWarning from "../components/pos/OutOfStockWarning";
import { OperationalMode, operationalMode } from "../constants/mode";
import KioskCart from "../components/pos/KioskCart";
import TaxErrorModal from "../components/pos/TaxErrorModal";
import CartIdleCheck from "../components/pos/CartIdleCheck";
import Watermark from "../../assets/img/watermark.png";

@inject("store")
@observer
export default class Pos extends Component {
  @observable fetchingProducts = false;

  componentDidMount() {
    document.title = "Point of Sale | BinderPOS";
    const mainElement = document.querySelector("main");
    mainElement.id = "binderpos-pos";
    const body = document.querySelector("body");
    body.className = "pos";
    posStore.fetchAllProducts(this.props.store.AuthStore.user.email);
    posStore.loadSplitTillSetting();
    posStore.loadBarcodeQuantityCheckSetting();
    posStore.fetchTills();
    posStore.fetchFloat();
    this.props.store.WindowSizeStore.setWindow();
    window.PAX = new PAX();
  }

  openPOSMenu() {
    if (!this.posMenuVisible) {
      this.setposMenuVisible(true);
    } else {
      this.setposMenuVisible(false);
    }
  }

  @observable posMenuVisible = false;

  @action setposMenuVisible(bool) {
    this.posMenuVisible = bool;
  }

  componentWillUnmount() {
    const mainElement = document.querySelector("main");
    mainElement.id = "binderpos-app";

    const body = document.querySelector("body");
    body.className = "";
  }

  toast(msg) {
    toast.info(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    const isKioskMode = operationalMode === OperationalMode.Kiosk;

    return (
      <>
        {this.props.store.AuthStore.screenSettings.pos ? (
          <>
            <CartIdleCheck />
            {this.props.store.WindowSizeStore.windowWidth > 1365 ? (
              <div className="App desktop">
                <div className="watermarkPos1">
                  <img src={Watermark} />
                </div>
                <div className="watermarkPos2">
                  <img src={Watermark} />
                </div>
                <Navbar />
                <div className="body">
                  <div className="product-search">
                    <SearchBar
                      store={posStore}
                      updateFetchingProds={this.updateFetchingProds}
                    />
                    <ResultsGrid store={posStore} />
                  </div>
                  {isKioskMode ? (
                    <KioskCart store={posStore} />
                  ) : (
                    <Cart store={posStore} />
                  )}
                </div>
              </div>
            ) : (
              <div className="App mobile">
                <div className="watermarkPos1">
                  <img src={Watermark} />
                </div>
                <div className="ham-menu">
                  <i
                    className="far fa-bars"
                    onClick={() => this.openPOSMenu()}
                  />
                </div>
                <Navbar menuState={this.posMenuVisible} />
                <ViewSwipePOS />
              </div>
            )}
            {posStore.floatStatus ? null : <OpenTillModal />}
            {posStore.floatModal ? <FloatModal /> : null}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <POSErrorModal />
            <QuickLinkEditMenu />
            <ReceiptModal />
            {posStore.tillWarning ? <CloseTillWarning /> : null}
            {posStore.cartModal ? <CartModal /> : null}
            {posStore.orderModal ? (
              <OrderModal
                closeRef={posStore.orderModal}
                close={posStore.closeOrderModal}
              />
            ) : null}
            {posStore.customerNoteModalVisible ? (
              <EditCustomerNote
                onClose={() => {
                  posStore.setCustomerToEdit(null);
                  posStore.setCustomerNoteModalVisible(false);
                }}
              />
            ) : null}
            {posStore.showTaxErrorModal ? <TaxErrorModal /> : null}
            {posStore.showOutOfStockWarning ? <OutOfStockWarning /> : null}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

import React, { createContext, useEffect, useState } from "react";
import {
  fetchCustomerSetting,
  fetchStoreSetting,
  fetchStoreDetails,
} from "../api/rest/settings";

// Store as in shop, not state
type SetFunction = React.Dispatch<React.SetStateAction<string>>;
const loadStoreSetting = (settingName: string, setFunction: SetFunction) =>
  fetchStoreSetting(settingName).then((response: SettingResponse) => {
    if (response?.settingValue) {
      setFunction(response.settingValue);
    }
  });

export interface StoreContextProps {
  customerId: string | undefined;
  customerName: string | undefined;
  currencySymbol: string | undefined;
  currencyCode: string | undefined;
  taxRate: string | undefined;
}

export const StoreContext = createContext<StoreContextProps>(null);

interface StoreProviderProps {
  children: React.ReactNode;
}

function StoreProvider(props: StoreProviderProps) {
  const { children } = props;
  const [customerId, setCustomerId] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [currencyCode, setCurrencyCode] = useState<string>();
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [taxRate, setTaxRate] = useState<string>();

  useEffect(() => {
    loadStoreSetting("currency", setCurrencyCode);
    loadStoreSetting("currencySymbol", setCurrencySymbol);
    fetchCustomerSetting("taxRate").then((response: SettingResponse) => {
      if (response?.settingValue) {
        setTaxRate(response.settingValue);
      }
    });
    fetchStoreDetails().then((data: StoreDetails) => {
      setCustomerId(data?.id);
      setCustomerName(data?.name);
    });
  }, []);

  const contextValue = {
    customerId,
    customerName,
    currencyCode,
    currencySymbol,
    taxRate,
  };

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;

import React from "react";
import PropTypes from "prop-types";

function Paging(props) {
  const {
    pageOffset = 0,
    currentPageItemCount = 0,
    maxItemsPerPage = 0,
    getPrevPage,
    getNextPage,
  } = props;
  return (
    <div className="paging">
      <button
        className="button is-info"
        disabled={pageOffset === 0}
        onClick={getPrevPage}
      >
        Prev
      </button>
      <button
        className="button is-info"
        disabled={currentPageItemCount < maxItemsPerPage}
        onClick={getNextPage}
      >
        Next
      </button>
    </div>
  );
}

Paging.propTypes = {
  pageOffset: PropTypes.number,
  currentPageItemCount: PropTypes.number,
  maxItemsPerPage: PropTypes.number,
  getPrevPage: PropTypes.func.isRequired,
  getNextPage: PropTypes.func.isRequired,
};

export default Paging;

import { merge } from "lodash";
import React from "react";
import { EbayCategoryTree, EbayCategoryType } from "../../api/rest/ebay";
import "./EbayCategoryList.scss";
import EbayCategoryListItem from "./EbayCategoryListItem";

const reformatCategoryTree = (categoryTree: EbayCategoryTree) =>
  categoryTree.categorySuggestions.reduce(
    (accumulator, suggestion: EbayCategoryType) =>
      merge(
        accumulator,
        suggestion?.categoryTreeNodeAncestors?.reduce(
          (all, category) => ({
            [category.categoryId]: {
              name: category.categoryName,
              children: all,
            },
          }),
          {
            [suggestion.category.categoryId]: {
              name: suggestion.category.categoryName,
            },
          }
        )
      ),
    {}
  );

interface EbayCategoryListProps {
  categoryTree: EbayCategoryTree;
  searchTerm: string;
}

type EbayCategoryListItemType = {
  [key: string]: {
    name: string;
    children: EbayCategoryListItemType;
  };
};

function EbayCategoryList(props: EbayCategoryListProps) {
  const { searchTerm, categoryTree } = props;

  if (!searchTerm || searchTerm.length < 1) {
    return null;
  }

  if (!categoryTree?.categorySuggestions) {
    return <div>No matching suggestions</div>;
  }

  const options = reformatCategoryTree(
    categoryTree
  ) as EbayCategoryListItemType;

  return (
    <div className="ebay-category-list">
      <span className="ebay-category-list__heading">Matching Categories:</span>
      {Object.entries(options).map(([id, values]) => (
        <EbayCategoryListItem key={id} id={id} {...values} />
      ))}
    </div>
  );
}

export default EbayCategoryList;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { validateFile } from "../../utils/file";
import { fetchStoreLogoUrl, saveStoreLogoUrl } from "../../api/rest/settings";

function StoreLogo(props) {
  const { toast, burntToast } = props;
  const [logoUrl, setLogoUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [, setIsLogoSaving] = useState(false);
  const logoButtonRef = useRef();

  useEffect(() => {
    loadLogo().then();
  }, []);

  async function loadLogo() {
    return fetchStoreLogoUrl()
      .then((data) => {
        if (data?.publicUrl) {
          setLogoUrl(data.publicUrl);
        }
      })
      .catch((error) => {
        console.warn(error);
        burntToast("Unable to load store logo");
      });
  }

  async function saveLogo() {
    console.log(
      `!${logoButtonRef.current.files} || ${logoButtonRef.current.files[0]}`
    );
    if (!logoButtonRef.current.files || !logoButtonRef.current.files[0]) {
      return;
    }
    setIsLogoSaving(true);
    saveStoreLogoUrl(logoButtonRef.current.files[0])
      .then(() => {
        toast("Store logo updated");
      })
      .catch((error) => {
        setFileError(error.message);
        burntToast(error.message);
      })
      .finally(() => {
        setIsLogoSaving(false);
      });
  }

  const onLogoChange = (event) => {
    setFileError(null);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        if (readerEvent.target.error) {
          setFileError(readerEvent.target.error.message);
          return;
        }
        const header = file.slice(0, 4);
        validateFile(header)
          .then(() => {
            setPreviewUrl(readerEvent.target.result);
            saveLogo();
          })
          .catch((err) => {
            setFileError(err);
            readerEvent.target.value = "";
          });
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(
    `Error: ${!!fileError}, logoUrl: ${logoUrl}, previewUrl: ${previewUrl}`
  );

  return (
    <div className="store-logo">
      <label className="label">Store logo</label>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="logoUpload" className="store-logo__upload-button">
            Select a logo
          </label>
          <input
            id="logoUpload"
            type="file"
            accept=".jpg,.jpeg,.png,.gif, image/jpeg, image/gif, image/png"
            ref={logoButtonRef}
            onChange={onLogoChange}
          />
          <p className="store-logo__description">
            2MB max file size.
            <br />
            Permitted file types are jpeg, png and gif.
          </p>
        </div>
        <div className="col-md-6">
          {fileError ? (
            <div className="store-logo__file-error">{fileError}</div>
          ) : logoUrl || previewUrl ? (
            <div
              className="store-logo__image"
              style={{
                backgroundImage: `url(${logoUrl ? logoUrl : previewUrl})`,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

StoreLogo.propTypes = {
  toast: PropTypes.func.isRequired,
  burntToast: PropTypes.func.isRequired,
};

export default StoreLogo;

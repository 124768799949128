import EventMenuStructure from "./EventMenuStructure";
import CustomerMenuStructure from "./CustomerMenuStructure";
import ProductMenuStructure from "./ProductMenuStructure";
import BuylistMenuStructure from "./BuylistMenuStructure";
import POSMenuStructure from "./POSMenuStructure";
import ReportsMenuStructure from "./ReportsMenuStructure";
import IntegrationsMenuStructure from "./IntegrationsMenuStructure";

export const mainMenuStructure: RootMenuItem[] = [
  {
    screenSetting: null,
    target: "/dashboard",
    title: "Dashboard",
    iconClass: "fas fa-home",
    menuStructure: null,
  },
  {
    screenSetting: "events",
    target: "/events",
    title: "Events",
    iconClass: "fas fa-calendar-alt",
    menuStructure: EventMenuStructure,
  },
  {
    screenSetting: "customers",
    target: "/customers",
    title: "Customers",
    iconClass: "fas fa-users",
    menuStructure: CustomerMenuStructure,
  },
  {
    screenSetting: "inventoryManagement",
    target: "/products",
    title: "Products",
    iconClass: "fas fa-inventory",
    menuStructure: ProductMenuStructure,
  },
  {
    screenSetting: "buylist",
    target: "/buylists",
    title: "Online Buylist",
    iconClass: "fas fa-shopping-cart",
    menuStructure: BuylistMenuStructure,
  },
  {
    screenSetting: "pos",
    target: "/pointOfSale",
    title: "POS Settings",
    iconClass: "fas fa-store-alt",
    menuStructure: POSMenuStructure,
  },
  {
    // TODO:: Replace this with a proper permissions check for reports
    screenSetting: undefined,
    target: "/reports",
    title: "Reports",
    iconClass: "fas fa-chart-bar",
    menuStructure: ReportsMenuStructure,
  },
  {
    screenSetting: "integrations",
    target: "/integrations",
    title: "Integrations",
    iconClass: "fas fa-vector-square",
    menuStructure: IntegrationsMenuStructure([]),
  },
  // TODO:: Hide this class on mobile devices
  {
    screenSetting: "pos",
    target: "/pos",
    title: "Point of Sale",
    iconClass: "fas fa-store-alt",
    menuStructure: null,
    classNameOverride: "DesktopMenu__link DesktopMenu__link--pos",
  },
];

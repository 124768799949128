import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { SettingsMenuStructure } from "../../menuStructures";
import { Loader, ConfirmationModal } from "../../components";
import { Link } from "react-router-dom";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";

@inject("store")
@observer
class Users extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Users | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.props.store.UsersStore.rehydrateUsers();
  }

  enableUser(id) {
    this.props.store.UsersStore.enableUser(id);
    this.props.store.UsersStore.rehydrateUsers();
  }

  disableUser(id) {
    this.props.store.UsersStore.disableUser(id);
    this.props.store.UsersStore.rehydrateUsers();
  }

  @observable showPasswordResetConfirmation;

  @action setShowPassowrdResetConfirmation(showPasswordResetConfirmation) {
    this.showPasswordResetConfirmation = showPasswordResetConfirmation;
  }

  @observable userToResetPassword;

  @action setUserToResetPassword(userToResetPassword) {
    this.userToResetPassword = userToResetPassword;
  }

  showResetPassword(user) {
    this.setUserToResetPassword(user);
    this.setShowPassowrdResetConfirmation(true);
  }

  cancelPasswordReset = () => {
    this.setUserToResetPassword(null);
    this.setShowPassowrdResetConfirmation(false);
  };

  sendPasswordReset = () => {
    this.props.store.UsersStore.sendPasswordReset(this.userToResetPassword.id);
    this.setUserToResetPassword(null);
    this.setShowPassowrdResetConfirmation(false);
    this.props.store.UsersStore.rehydrateUsers();
  };

  render() {
    return (
      <>
        {this.props.store.UsersStore.users ? (
          <>
            <div id="userList">
              <SectionHeaderLayout title="Manage users">
                <Link to="/settings/users/add" className="btn1">
                  Add user
                </Link>
              </SectionHeaderLayout>
              <div className="customer_list">
                <table>
                  <thead>
                    <tr>
                      <td style={{ paddingLeft: "29px" }} colSpan="2">
                        Email
                      </td>
                      <td>Name</td>
                      <td>Status</td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.store.UsersStore.users.map((user, i) => (
                      <tr className="list_item" key={i}>
                        <td
                          style={{ width: "60px", paddingLeft: "23px" }}
                          className={
                            user.enabled
                              ? "text-center blue"
                              : "text-center red"
                          }
                        >
                          <div
                            className={
                              user.enabled ? "avatar blue" : "avatar red"
                            }
                          >
                            {user.firstName && user.firstName.length > 0 ? (
                              <>
                                {user.firstName.charAt(0)}
                                {user.lastName && user.lastName.length > 0 ? (
                                  <>{user.lastName.charAt(0)}</>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {user.email && user.email.length > 0 ? (
                                  <>{user.email.charAt(0)}</>
                                ) : null}
                              </>
                            )}
                          </div>
                        </td>
                        <td className="name">{user.email}</td>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>
                          {user.enabled ? (
                            <div>Enabled</div>
                          ) : (
                            <div>Disabled</div>
                          )}
                        </td>
                        <td className="text-right">
                          <Link
                            to={`/settings/users/update/${user.id}`}
                            className="userActions"
                            title="Edit user details."
                          >
                            <i className="fal fa-user-edit" />
                          </Link>
                          {!user.isMe ? (
                            <>
                              {user.enabled ? (
                                <a
                                  title="Disable this user."
                                  className="userActions"
                                  onClick={() => this.disableUser(user.id)}
                                >
                                  <i className="fal fa-lock-alt" />
                                </a>
                              ) : (
                                <a
                                  className="userActions"
                                  title="Enable this user."
                                  onClick={() => this.enableUser(user.id)}
                                >
                                  <i className="fal fa-lock-open-alt" />
                                </a>
                              )}
                            </>
                          ) : null}
                          <a
                            title="Send a password reset."
                            className="userActions"
                            onClick={() => this.showResetPassword(user)}
                          >
                            <i className="fal fa-paper-plane" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.showPasswordResetConfirmation ? (
                  <ConfirmationModal
                    type="confirm"
                    title="Send password reset?"
                    text={`Do you want to send a password reset email to ${this.userToResetPassword.email}`}
                    cancelAction={this.cancelPasswordReset}
                    confirmAction={this.sendPasswordReset}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

Users.propTypes = {
  store: PropTypes.object,
};

export default Users;

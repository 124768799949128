import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import { ReportsMenuStructure } from "../../menuStructures";
import { API_STATE } from "../../constants/api";
import { getEndOfDayReports } from "../../api/graphql/reports";
import { reformatEodReport } from "../../utils/reports";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import { CurrencyRenderer } from "../../components/reports/CellTypes";
import TimeUtils from "../../utils/TimeUtils";
import "react-base-table/styles.css";

@inject("store")
@observer
class EODReports extends React.Component {
  componentDidMount() {
    document.title = "End of Day Reports | BinderPOS";
    const { reset, setApiState, setSourceData, setSortColumn } =
      this.props.store.ReportsStore;
    reset();
    this.props.store.MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    setApiState(API_STATE.LOADING);
    getEndOfDayReports("2020-01-01", "2099-12-12")
      .then((data) => {
        const reportData = reformatEodReport(data);
        setSourceData(reportData);
        setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load day report",
          "There was an error retrieving your end of day report. Please refresh and try again"
        );
        setApiState(API_STATE.ERROR);
      });
    // By default, order from newest to oldest
    setSortColumn({
      key: "col1",
      order: "desc",
      column: {
        dataKey: "openedDate",
      },
    });
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getEndOfDayReports(fromDate, toDate)
      .then((data) => {
        const reportData = reformatEodReport(data);
        this.props.store.ReportsStore.setSourceData(reportData);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load end of day reports",
          "There was an error retrieving your end of day reports. Please try again"
        );
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
      });
  };

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Till", key: "name" },
      { name: "Opening Time", key: "openedDate" },
      { name: "Closing Time", key: "closedDate" },
      {
        name: "Cash Opening",
        key: "Cash.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cash Current",
        key: "Cash.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cash Diff",
        key: "Cash.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        key: "Cash.closingAmount",
        name: "Cash Closing",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Opening",
        key: "Credit.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Current",
        key: "Credit.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Diff",
        key: "Credit.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Closing",
        key: "Credit.closingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Opening",
        key: "EFTPOS.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Current",
        key: "EFTPOS.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Diff",
        key: "EFTPOS.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Closing",
        key: "EFTPOS.closingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Store Credit",
        key: "Store_Credit.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "End of Day Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { ReportsStore } = this.props.store;
    const {
      currentPage,
      rowsPerPage,
      sortColumn,
      totalRowCount,
      apiState,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">End of Day Reports</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      fixed
                      width={width}
                      height={height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col0"
                        dataKey="name"
                        width={200}
                        title="Till"
                        sortable
                        resizable
                      />
                      <Column
                        key="col1"
                        dataKey="openedDate"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(props.cellData)
                        }
                        title="Opening Time"
                        sortable
                        resizable
                      />
                      <Column
                        key="col2"
                        dataKey="closedDate"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(props.cellData)
                        }
                        title="Closing Time"
                        sortable
                        resizable
                      />
                      <Column
                        key="col3"
                        dataKey="Cash.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col4"
                        dataKey="Cash.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col25"
                        dataKey="Cash.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col26"
                        dataKey="Cash.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col5"
                        dataKey="Credit.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col6"
                        dataKey="Credit.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col7"
                        dataKey="Credit.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col8"
                        dataKey="Credit.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col9"
                        dataKey="EFTPOS.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col10"
                        dataKey="EFTPOS.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col11"
                        dataKey="EFTPOS.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col12"
                        dataKey="EFTPOS.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col13"
                        dataKey="Store_Credit.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Store Credit"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={totalRowCount}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
EODReports.propTypes = { store: PropTypes.object };
export default EODReports;

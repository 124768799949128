import React from "react";
import PropTypes from "prop-types";
import "./DateSelect.css";

function DateSelect({ filterValues, updateFilterValue }) {
  return (
    <div className="date-select">
      <label htmlFor="start-date">Submitted between</label>
      <input
        id="start-date"
        className="start-date"
        type="date"
        value={filterValues.startDate || ""}
        onChange={(event) => updateFilterValue("startDate", event.target.value)}
      />
      <label htmlFor="end-date">and</label>
      <input
        id="end-date"
        className="end-date"
        type="date"
        value={filterValues.endDate || ""}
        onChange={(event) => updateFilterValue("endDate", event.target.value)}
      />
    </div>
  );
}

DateSelect.propTypes = {
  filterValues: PropTypes.object,
  updateFilterValue: PropTypes.func.isRequired,
};

export default DateSelect;

export const localSettingsKey = "portalSettings";

export const optOutFields = [
  {
    value: "sku",
    label: "Product SKU",
  },
  {
    value: "bodyHtml",
    label: "HTML description",
  },
  {
    value: "inventoryManagement",
    label: "Inventory Management",
  },
  {
    value: "price",
    label: "Price",
  },
  {
    value: "images",
    label: "Images",
  },
  {
    value: "vendor",
    label: "Vendor",
  },
  {
    value: "productType",
    label: "Product Type",
  },
  {
    value: "title",
    label: "Title",
  },

  {
    value: "tags",
    label: "Tags",
  },
  {
    value: "published",
    label: "Published",
  },
  {
    value: "status",
    label: "Status",
  },
  {
    value: "weight",
    label: "Product weight",
  },
  {
    value: "weightUnit",
    label: "Weight unit",
  },
  {
    value: "barcode",
    label: "Barcode",
  },
  {
    value: "variants",
    label: "Variants",
  },
];

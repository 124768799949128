import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchServiceSettings,
  fetchServiceSettingsData,
  updateServiceSetting,
} from "../api/rest/ebay";

export const useFetchServiceSettings = () =>
  useQuery("ebayServiceSettings", fetchServiceSettings);

export const useFetchServiceSettingsData = () =>
  useQuery("ebayServiceSettingsData", fetchServiceSettingsData);

export const useUpdateServiceSetting = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (settingData: EbayServiceSettingData) => updateServiceSetting(settingData),
    {
      onSuccess: (
        response: EbayServiceSettingData,
        variables: EbayServiceSettingData
      ) => {
        queryClient.setQueryData<EbayServiceSettingData[]>(
          "ebayServiceSettingsData",
          (prev): EbayServiceSettingData[] => {
            if (prev) {
              return prev.map((serviceSetting) => {
                if (serviceSetting.name === variables.name) {
                  return response;
                }
                return serviceSetting;
              });
            }
            return [variables];
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";
import CustomerSelect from "./CustomerSelect";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { OperationalMode, operationalMode } from "../../constants/mode";

@inject("posStore")
@observer
class Navbar extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  @action
  toggleFloatModal = () => {
    this.props.posStore.floatModal = true;
    this.props.posStore.fetchFloat();
  };

  createNewCart() {
    this.props.posStore.newCart();
    this.props.posStore.toast("You have created a new cart!");
  }

  saveCart() {
    if (this.props.posStore.cart.length > 0) {
      this.props.posStore.saveCart();
      this.props.posStore.toast(
        "Cart #" + this.props.posStore.cartId + " has been saved!"
      );
    } else {
      this.props.posStore.toast("Your cart appears to be empty...");
    }
  }

  toggleSingles() {
    this.props.posStore.toggleSingle();
    this.props.posStore.toast(
      this.props.posStore.includeSingles
        ? "Your searches now include singles"
        : "Your searches will now exclude singles"
    );
  }

  render() {
    if (operationalMode === OperationalMode.Kiosk) {
      return (
        <div className="navbar">
          <div className="posMenu posMenu--kiosk">
            <div
              className={
                this.props.menuState
                  ? "mobileMenu show"
                  : "mobileMenu mobileMenu--kiosk"
              }
            >
              <div className="link-icon-pos stockLock">
                <i
                  className={
                    !this.props.posStore?.kioskSettings?.sellZero
                      ? "far fa-lock-alt"
                      : "far fa-lock-open-alt"
                  }
                ></i>
              </div>
              <div
                className="link-icon-pos newCart"
                onClick={() => this.createNewCart()}
                title="Create a cart"
              >
                <i className="far fa-cart-plus"></i> New Cart
              </div>
              <div
                className="link-icon-pos productSwap"
                onClick={() => this.toggleSingles()}
                title="Display all products or exclude singles?"
              >
                <i className="far fa-search-plus"></i>{" "}
                {this.props.posStore.includeSingles
                  ? "All Products"
                  : "Excluding Singles"}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="navbar">
        <div className="posMenu">
          <div
            className={this.props.menuState ? "mobileMenu show" : "mobileMenu"}
          >
            <div
              className="link-icon-pos left"
              onClick={this.toggleFloatModal}
              title="Open or close a till"
            >
              <i className="far fa-power-off" /> Open/Close
            </div>
            <div
              className="link-icon-pos newCart"
              onClick={() => this.createNewCart()}
              title="Create a cart"
            >
              <i className="far fa-cart-plus"></i> New
            </div>
            <div
              className="link-icon-pos loadCart"
              onClick={() => this.props.posStore.openCartModal()}
              title="Load a previous cart"
            >
              <i className="far fa-file-download"></i> Load
            </div>
            <div
              className="link-icon-pos saveCart"
              onClick={() => this.saveCart()}
              title="Save your current cart"
            >
              <i className="far fa-file-upload"></i> Save
            </div>
            <div
              className="link-icon-pos orders"
              onClick={() => this.props.posStore.openOrderModal()}
              title="View history and do refunds"
            >
              <i className="far fa-history"></i> History & Refunds
            </div>
            <div
              className="link-icon-pos productSwap"
              onClick={() => this.toggleSingles()}
              title="Display all products or exclude singles?"
            >
              <i className="far fa-search-plus"></i>{" "}
              {this.props.posStore.includeSingles
                ? "All Products"
                : "Excluding Singles"}
            </div>
            <div
              className={
                "link-icon-pos cashSwap" +
                (this.props.posStore.buyMode ? "" : " hidden")
              }
              onClick={() => this.props.posStore.toggleCashPrice()}
            >
              <i
                className={
                  this.props.posStore.cashPrice
                    ? "fal fa-money-bill-alt"
                    : "far fa-credit-card-front"
                }
                aria-hidden="true"
              />{" "}
              {this.props.posStore.cashPrice ? "Cash Price" : "Credit Price"}
            </div>
            <span
              className="link-icon-pos"
              onClick={() => this.props.history.push("/dashboard")}
              title="Exit the Point of Sale"
            >
              <i className="far fa-times"></i> EXIT
            </span>
          </div>
          <CustomerSelect />
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);

import { _fetch, _fetchWithFormData } from "../../api";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";

export async function fetchStoreSetting(settingName) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/${settingName}`,
  });
}

export async function fetchCustomerSetting(settingName) {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/${settingName}/forMe`,
  });
}

export async function updateCustomerSetting(setting) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/settings/save`,
    payload: setting,
  });
}

export async function fetchSupportedProducts() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/cards/games/supported`,
  });
}

export async function fetchProductList() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/products/productTypes`,
  });
}

export async function fetchWPNReportProducts() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/wpnReportTypes/forMe`,
  });
}

export async function saveWPNReportProducts(products = []) {
  return await _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/settings/save`,
    payload: {
      id: null,
      settingName: "wpnReportTypes",
      settingValue: JSON.stringify(products),
      binderCustomerId: null,
    },
  });
}

export async function fetchStoreLogoUrl() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/settings/logo`,
  });
}

export async function saveStoreLogoUrl(logo) {
  const formData = new FormData();
  formData.append("logo", logo);
  return await _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/settings/logo`,
    payload: formData,
  });
}

export async function fetchLocations() {
  return await _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/shopify/locations`,
  });
}

export async function updateLocations(locationData) {
  return await _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/shopify/locations`,
    payload: locationData,
  });
}

export async function fetchStoreDetails() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/customer/settings`,
  });
}

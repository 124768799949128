import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addEventParticipant,
  fetchEventAdditionalInfo,
  fetchEventParticipants,
  fetchEventParticipant,
  removeEventParticipant,
  updateEventParticipant,
} from "../api/rest/events";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

export const useFetchEventAdditionalInfo = (eventId: number) =>
  useQuery(
    ["eventAddditionalInfo", { eventId }],
    () => fetchEventAdditionalInfo(eventId),
    {
      staleTime: LONG_LIFE_DATA_STALE_TIME,
    }
  );

export const useFetchEventParticipants = (eventId: number) =>
  useQuery(["eventParticipants", { eventId }], () =>
    fetchEventParticipants(eventId)
  );

type EventParticipantVariables = {
  eventId: number | string;
  participantId?: number | string;
  participant?: EventParticipant;
};

export const useFetchEventParticipant = ({
  eventId,
  participantId,
}: EventParticipantVariables) =>
  useQuery(
    ["eventParticipant", { eventId, participantId }],
    () => fetchEventParticipant(eventId, participantId),
    { enabled: participantId !== undefined }
  );

export const useAddEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participant }: EventParticipantVariables) =>
      addEventParticipant(eventId, participant),
    {
      onSuccess: (
        response: EventParticipant,
        variables: EventParticipantVariables
      ) => {
        queryClient.setQueryData<EventParticipant[]>(
          ["eventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipant[] => {
            if (prev) {
              return [...prev, response];
            }
            return [response];
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useRemoveEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participantId }: EventParticipantVariables) =>
      removeEventParticipant(eventId, participantId),
    {
      onSuccess: (response: [], variables: EventParticipantVariables) => {
        queryClient.setQueryData<EventParticipant[]>(
          ["eventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipant[] => {
            if (prev) {
              return prev.filter(
                (participant) => participant.id !== variables.participantId
              );
            }
            return [];
          }
        );
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateEventParticipant = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    ({ eventId, participantId, participant }: EventParticipantVariables) =>
      updateEventParticipant(eventId, participantId, participant),
    {
      onSuccess: (
        response: EventParticipant,
        variables: EventParticipantVariables
      ) => {
        queryClient.setQueryData<EventParticipant[]>(
          ["eventParticipants", { eventId: Number(variables.eventId) }],
          (prev): EventParticipant[] => {
            if (prev) {
              return prev.map((participant) => {
                if (participant.id === variables.participantId) {
                  return response;
                }
                return participant;
              });
            }
            return [variables.participant];
          }
        );
        queryClient.setQueryData<EventParticipant>(
          [
            "eventParticipant",
            {
              eventId: variables.eventId,
              participantId: variables.participantId,
            },
          ],
          () => response
        );
      },
    }
  );
  return { isMutating, ...rest };
};

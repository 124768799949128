import React from "react";
import CartNoteModel from "./cart-note.model";

export default function CartNotes({ rawNotes }: { rawNotes?: string }) {
  const parsedNotes: CartNoteModel | null = rawNotes && JSON.parse(rawNotes);

  return (
    parsedNotes && (
      <>
        <table style={{ marginBottom: "24px" }}>
          <thead>
            <tr>
              <td>Note</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{parsedNotes.note}</td>
            </tr>
          </tbody>
        </table>
        {parsedNotes.customFields && (
          <div style={{ overflowX: "scroll" }}>
            <table>
              <thead>
                <tr>
                  {Object.keys(parsedNotes.customFields).map((field) => (
                    <td key={field} style={{ paddingLeft: "15px" }}>
                      {field}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderLeft: 0 }}>
                  {Object.keys(parsedNotes.customFields).map((field) => (
                    <td style={{ paddingLeft: "15px" }} key={field}>
                      {parsedNotes.customFields?.[field]}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </>
    )
  );
}

import React from "react";
import LinkComponent from "../../../components/generic/LinkComponent";
import "./SettingsMenu.scss";

function SettingsMenu() {
  return (
    <div className="ebay-settings">
      <div className="ebay-settings__navigation">
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Location</h5>
          <p className="ebay-settings__navigation-description">
            Record the address for your store and or warehouse, as well as
            contact details and website address.
          </p>
          <LinkComponent to="/integrations/ebay/policies/location">
            Manage Location Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Payment</h5>
          <p className="ebay-settings__navigation-description">
            Define your payment policies, including accepted credit cards and
            Paypal email address.
          </p>
          <LinkComponent to="/integrations/ebay/policies/payment">
            Manage Payment Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Returns</h5>
          <p className="ebay-settings__navigation-description">
            Set what kind of returns your return periods for both demoestic and
            international buyers.
          </p>
          <LinkComponent to="/integrations/ebay/policies/returns">
            Manage Returns Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Fulfilment</h5>
          <p className="ebay-settings__navigation-description">
            Provide different shipping options for buyers, including shipping
            services, international shopping and handling times.
          </p>
          <LinkComponent to="/integrations/ebay/policies/fulfillment">
            Manage Fulfilment Policies &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Listing Templates</h5>
          <p className="ebay-settings__navigation-description">
            Create templates that can be used to quickly populate the listings
            fields for each product.
          </p>
          <LinkComponent to="/integrations/ebay/policies/templates">
            Manage Templates &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Misc Settings</h5>
          <p className="ebay-settings__navigation-description">
            All other settings used to control how your listings are published
            on Ebay.
          </p>
          <LinkComponent to="/integrations/ebay/policies/misc">
            Manage Misc Settings &gt;
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;

import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  useFetchServiceSettings,
  useFetchServiceSettingsData,
} from "../../../hooks/ebayHooks";
import { useShowError } from "../../../hooks/errorHooks";
import Loader from "../../../components/generic/Loader";
import EbayMiscSetting from "../../../components/integrations/EbayMiscSetting";
import LinkComponent from "../../../components/generic/LinkComponent";

const wrapTitle = (children: React.ReactNode) => (
  <>
    <h2>Misc Settings</h2>
    {children}
    <ToastContainer />
  </>
);

const getSavedSettingValue = (
  settingName: string,
  settingValues: EbayServiceSettingData[]
) => settingValues.find((setting) => setting.name === settingName)?.value || "";

function MiscSettingsContainer() {
  const showError = useShowError();
  const {
    data: miscSettings,
    isLoading: isLoadingSettings,
    error: settingsError,
  } = useFetchServiceSettings();
  const {
    data: settingsData,
    isLoading: isLoadingData,
    error: dataError,
  } = useFetchServiceSettingsData();

  useEffect(() => {
    if (settingsError) {
      showError(
        settingsError as DetailedError,
        "Failed to load misc settings",
        "There was an error retrieving your ebay settings. Please refresh and try again"
      );
    } else if (dataError) {
      showError(
        dataError as DetailedError,
        "Failed to load misc settings values",
        "There was an error retrieving your ebay settings values. Please refresh and try again"
      );
    }
  }, [settingsError, dataError]);

  if (isLoadingSettings || isLoadingData) {
    return wrapTitle(<Loader />);
  }

  return wrapTitle(
    <>
      {(miscSettings || []).map((setting: EbayServiceSetting) => (
        <EbayMiscSetting
          key={setting.code}
          parameter={setting.code}
          name={setting.title}
          description={setting.description}
          savedData={getSavedSettingValue(setting.code, settingsData)}
          type={setting.type}
        />
      ))}
      <div>
        <LinkComponent to="/integrations/ebay/policies">
          Return to policies
        </LinkComponent>
      </div>
      <ToastContainer />
    </>
  );
}

export default MiscSettingsContainer;

import React from "react";
import { Link } from "react-router-dom";
import "./IconButton.scss";

type LinkProps =
  | {
      isLink: true;
      to: string;
    }
  | {
      isLink?: false;
      to?: string;
    };

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  className?: string;
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
}

type IconButtonProps = LinkProps & ButtonProps;

const getClassName = (
  secondary?: boolean,
  danger?: boolean,
  className?: string
) =>
  [
    "IconButton",
    secondary ? "IconButton--secondary" : null,
    danger ? "IconButton--danger" : null,
    className || null,
  ].join(" ");

const IconButton = React.forwardRef((props: IconButtonProps) => {
  const { className, icon, disabled, secondary, danger, isLink, to, ...rest } =
    props;

  const mergedClassName = getClassName(secondary, danger, className);

  if (isLink)
    return (
      // @ts-ignore
      <Link to={to} className={mergedClassName} {...rest}>
        <i className={icon} />
      </Link>
    );

  return (
    <button
      className={mergedClassName}
      disabled={disabled}
      {...rest} // @ts-ignore
    >
      <i className={icon} />
    </button>
  );
});

IconButton.defaultProps = {
  disabled: false,
  secondary: false,
};

export default IconButton;

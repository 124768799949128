import React from "react";
import Spinner from "../generic/Spinner";

interface DeleteMultipleSettingsModalProps {
  visible?: boolean;
  loading?: boolean;
  handleClose: Function;
  deleteSelectedSettings: Function;
}

function DeleteMultipleSettingsModal(props: DeleteMultipleSettingsModalProps) {
  const { visible, loading, handleClose, deleteSelectedSettings } = props;

  if (!visible) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => handleClose()}
          />
        </header>
        <Spinner isLoading={loading}>
          <section className="modal-card-body">
            <p>
              If you proceed with deleting your settings, you will not be able
              to restore it.
            </p>
            <br />
            <p>
              Are you sure you want to delete your integration sync settings?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button className="modalBtn cancel" onClick={() => handleClose()}>
              <i className="fal fa-times" /> Cancel
            </button>
            <button
              className={`modalBtn action ${
                loading ? "is-loading disabled" : ""
              }`}
              onClick={() => deleteSelectedSettings()}
            >
              Delete <i className="fas fa-caret-right" />
            </button>
          </footer>
        </Spinner>
      </div>
    </div>
  );
}

DeleteMultipleSettingsModal.defaultProps = {
  visible: false,
  loading: false,
};

export default DeleteMultipleSettingsModal;

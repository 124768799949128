import React from "react";
import PropTypes from "prop-types";

function TableSpinner(props) {
  if (!props.isLoading) {
    return null;
  }

  return (
    <div className="spinner-container table-loader">
      <div className="spinner-overlay">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

TableSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default TableSpinner;

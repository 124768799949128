import { gql } from "apollo-boost";
import { DEVBLOG_GRAPHQL_ENDPOINT } from "../../constants/api";
import { _graphQLRequest } from "../index.js";

export type Post = {
  node: {
    id: string;
    featuredImage: {
      node: {
        mediaItemUrl?: string;
      };
    };
    link?: string;
    title?: string;
    excerpt?: string;
  };
};

export async function getLatestBlogPosts(count: number) {
  const query = gql`
  {
    posts(last: ${count}, where: {tagSlugIn: ["portal"]}) {
      edges {
        node {
          id
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          link
          title
          excerpt
        }
      }
    }
  }
  `;
  return _graphQLRequest(query, DEVBLOG_GRAPHQL_ENDPOINT);
}

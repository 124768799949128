import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { EventMenuStructure } from "../../menuStructures";
import { retryFailedToDelete } from "../../api/rest/events";
import { Loader } from "../../components";
import TimeUtils from "../../utils/TimeUtils";
import NoBanner from "../../../assets/img/noBanner.png";

@inject("store")
@observer
class EventFailedToDelete extends React.Component {
  @observable offset = 0;

  @action setOffset(offset) {
    this.offset = offset;
  }

  limit = 100;

  @observable messageModalText;

  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;

  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;

  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;

  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalProduct;

  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Failed events | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);

    this.props.store.EventsStore.rehydrateFailedToDelete(
      this.offset,
      this.limit
    );
  }

  getNextFailedToDelete() {
    this.setOffset(this.offset + 100);
    this.props.store.EventsStore.rehydrateFailedToDelete(
      this.offset,
      this.limit
    );
  }

  getPrevFailedToDelete() {
    let offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.EventsStore.rehydrateFailedToDelete(
      this.offset,
      this.limit
    );
  }

  tryDeleteAgain(failedToDelete) {
    this.setProcessing(true);
    console.log({ failedToDeleteId: failedToDelete.id });
    retryFailedToDelete(failedToDelete.eventToDeleteId)
      .then((result) => {
        if (!result.actionPass) {
          this.setMessageModalText(result.message);
          this.setMessageModalTitle("Issue pushing through event to Shopify");
          this.setMessageModalVisible(true);
        } else {
          this.setMessageModalText("Event has been deleted from Shopify");
          this.setMessageModalTitle("Success!");
          this.setMessageModalVisible(true);
          this.props.store.EventsStore.rehydrateFailedToDelete(
            this.offset,
            this.limit
          );
        }
        this.setProcessing(false);
      })
      .catch((error) => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete event",
          "There was an error deleting your event from Shopify. Please try again"
        );
      });
  }

  render() {
    return (
      <>
        {this.props.store.AuthStore.screenSettings.events ? (
          <>
            {this.props.store.EventsStore.failedToDeleteEvents ? (
              <>
                {this.processing ? <Loader /> : null}
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="header-text">
                      Event tickets that had issues being deleted
                    </span>
                    <div className="top-pagination">
                      <button
                        className="paging-nav large"
                        disabled={this.offset == 0}
                        onClick={() => this.getPrevFailedToDelete()}
                      >
                        <i className="fas fa-caret-left" /> Prev
                      </button>
                      <button
                        className="paging-nav large"
                        disabled={
                          !!(
                            this.props.store.EventsStore.failedToDeleteEvents &&
                            this.props.store.EventsStore.failedToDeleteEvents
                              .length < this.limit
                          )
                        }
                        onClick={() => this.getNextFailedToDelete()}
                      >
                        Next <i className="fas fa-caret-right" />
                      </button>
                    </div>
                  </h2>
                </div>
                <br />

                <div className="tiledPanels">
                  {this.props.store.EventsStore.failedToDeleteEvents.map(
                    (failedToDelete, i) => (
                      <div className="panelWrapper" key={i}>
                        <div className="panel">
                          <div className="eventImage">
                            <span className="ticketIcon">
                              <img src={failedToDelete.calendarIcon} />
                            </span>
                            <img
                              src={
                                !failedToDelete.banner == null ||
                                !failedToDelete.banner == ""
                                  ? failedToDelete.banner
                                  : NoBanner
                              }
                            />
                          </div>
                          <span className="eventTitle">
                            {failedToDelete.title}
                          </span>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6">
                                <p className="types">
                                  Game Type:
                                  <em>{failedToDelete.game}</em>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="types">
                                  Event Type:
                                  <em>{failedToDelete.type}</em>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="info">
                                  <i className="far fa-calendar-alt" />
                                  {failedToDelete.recurring
                                    ? "Recurring"
                                    : TimeUtils.convertDateToHumanReadable(
                                        failedToDelete.date
                                      )}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="info">
                                  <i className="far fa-clock" />
                                  {TimeUtils.convertTime(failedToDelete.time)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <>
                            <span
                              onClick={() =>
                                this.tryDeleteAgain(failedToDelete)
                              }
                              className="editBtn"
                            >
                              <i className="far fa-calendar-edit" /> Retry event
                              deletion
                            </span>
                          </>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {this.messageModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          {this.messageModalTitle}
                        </p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() => this.setMessageModalVisible(false)}
                        >
                          <i className="fal fa-times" /> Close
                        </button>
                      </header>
                      <section className="modal-card-body">
                        <p>{this.messageModalText}</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          type="button"
                          className="modalBtn action full"
                          onClick={() => this.setMessageModalVisible(false)}
                        >
                          Ok
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

EventFailedToDelete.propTypes = { store: PropTypes.object };

export default EventFailedToDelete;

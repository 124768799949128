import React, { Component } from "react";
import GridItem from "./GridItem";
import QuickLinks from "./QuickLinks";
import { observer } from "mobx-react";
import { Loader } from "../../components";
import AdditionalInfo from "./AdditionalInfo";
import { action } from "mobx";
import { LineItem } from "../../pos/ItemStore";
import ResultsPagination from "./ResultsPagination";

@observer
class ResultsGrid extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  @action
  addItem = (item) => {
    if (this.props.store.buyMode && item.selectedBuyVariant > -1) {
      item.variants[item.selectedBuyVariant].quantity++;
    }
    if (!this.props.store.buyMode && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity--;
    }

    this.props.store.addToCart(
      new LineItem(
        item,
        this.props.store.buyMode,
        this.props.store.cashPrice,
        this.props.store.allTax
      )
    );
    this.props.store.setAdditionalInfoItem(null);
  };

  @action addItemWithAdditionalInfo = (item) => {
    var lineItemValues = [];
    item.event.additionalInfo.forEach((additionalInfo) => {
      var additionalInfoItem = {
        name: additionalInfo.id + "",
        value:
          this.form.current.elements["additionalInfo" + additionalInfo.id]
            .value,
      };
      lineItemValues.push(additionalInfoItem);
    });

    this.props.store.addToCart(
      new LineItem(
        item,
        this.props.store.buyMode,
        this.props.store.cashPrice,
        this.props.store.allTax,
        lineItemValues
      )
    );
    this.props.store.setAdditionalInfoItem(null);
  };

  render() {
    const store = this.props.store;
    if (store.fetchingSearch) return <Loader text="Searching products" />;
    if (!store.quickLinksReady) return <Loader text="Loading quick links..." />;

    return (
      <>
        <ResultsPagination />
        <div className="results-grid" tabIndex="-1">
          {!!this.props.store.additionalInfoItem && (
            <AdditionalInfo
              addItem={() => this.addItem(this.props.store.additionalInfoItem)}
              item={this.props.store.additionalInfoItem}
              form={this.form}
              addItemWithAdditionalInfo={(event) => {
                event.preventDefault();
                this.addItemWithAdditionalInfo(
                  this.props.store.additionalInfoItem
                );
              }}
              close={() => this.props.store.setAdditionalInfoItem(null)}
            />
          )}
          {store.items.map((item) => (
            <GridItem
              store={store}
              item={item}
              key={item.id}
              addItem={this.addItem}
            />
          ))}
          {store.items.length == 0 &&
          !store.fetchingSearch &&
          store.searchTerm != "" &&
          !store.waitingToSearch ? (
            <div className="emptySearch">No products found...</div>
          ) : (
            ""
          )}
          {store.searchTerm == "" && store.quickLinksReady ? (
            <QuickLinks menu={store.quickLinkData} />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default ResultsGrid;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

@inject("posStore")
@observer
class TaxErrorModal extends Component {
  clearError = () => {
    this.props.posStore.setShowTaxErrorModal(false);
  };

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Missing Tax Settings</p>
          </header>
          <section className="modal-card-body">
            <div className="field">
              No tax rate has been entered for your store. Until a tax rate is
              set, the checkout functionality will be disabled.
            </div>
            <div className="field">
              Your tax rate can be changed on the{" "}
              <Link to="/pointOfSale/settings/tax">
                Point of Sales &gt; Tax Settings
              </Link>{" "}
              page.
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="modalBtn action full" onClick={this.clearError}>
              OK
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default TaxErrorModal;

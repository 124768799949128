import { Field, Form, Formik } from "formik";
import React from "react";
import { EbayParam } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import ForceReauthoriseButton from "./ForceReauthoriseButton";
import { useStoreDetails } from "../../hooks/storeHooks";
import "./EbayProvisioningForm.scss";

interface EbayProvisioningFormProps {
  marketplaces: EbayParam[];
  currencies: EbayParam[];
  locales: EbayParam[];
  handleSubmit: Function;
}

const getOptions = (options: EbayParam[]) =>
  options.map((option) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

const initialValues = {
  marketplace: "EBAY_US",
  currency: "USD",
  locale: "en_US",
};

function EbayProvisioningForm(props: EbayProvisioningFormProps) {
  const { marketplaces, currencies, locales, handleSubmit } = props;
  const { customerName } = useStoreDetails();

  return (
    <div className="ebay-provisioning-form">
      <div className="ebay-provisioning-form__header">
        <h3>Provisioning Settings</h3>
        <ForceReauthoriseButton integration="ebay" />
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(customerName, values, setSubmitting)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="ebay-provisioning-form__inputs">
              <label htmlFor="marketplace">Marketplace</label>
              <Field as="select" name="marketplace">
                {getOptions(marketplaces)}
              </Field>
              <label htmlFor="currency">Currency</label>
              <Field as="select" name="currency">
                {getOptions(currencies)}
              </Field>
              <label htmlFor="locale">Locale</label>
              <Field as="select" name="locale">
                {getOptions(locales)}
              </Field>
            </div>
            <div className="ebay-provisioning-form__actions">
              <ButtonComponent type="submit" disabled={isSubmitting}>
                Save and continue
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayProvisioningForm;

import { _fetch } from "../../api";
import { API_V2_BASE_URL } from "../../constants/api";

export type FeatureSwitch = {
  id: number;
  settingName: string;
  settingValue: string;
};

export const fetchFeatureSwitches = (
  keys: string[]
): Promise<FeatureSwitch[]> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/featureSwitches?${keys
      .map((key) => `key=${key}`)
      .join("&")}`,
  });

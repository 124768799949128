import { inject } from "mobx-react";
import { Component } from "react";

type Link = {
  title: string;
  href: string;
  exact?: boolean;
};

type MenuStructure = {
  title: string;
  activeClass: string;
  links: Link[];
};

interface SetActiveMenuProps {
  menuStructure: MenuStructure | Function;
  availableIntegrations?: IntegrationDetails[];
  store?: {
    IntegrationSyncStore: {
      availableIntegrations: unknown;
      loadAvailableIntegrations: Function;
    };
    MenuStore: {
      setSideMenuToDisplay: Function;
    };
  };
}

@inject("store")
class SetActiveMenu extends Component<SetActiveMenuProps> {
  componentDidMount() {
    const { menuStructure } = this.props;
    if (menuStructure && typeof menuStructure !== "function") {
      this.props.store.MenuStore.setSideMenuToDisplay(menuStructure);
    }
  }

  componentDidUpdate(prevProps: SetActiveMenuProps) {
    const { menuStructure, availableIntegrations, store } = this.props;
    if (
      prevProps.menuStructure === menuStructure &&
      prevProps.availableIntegrations === availableIntegrations
    ) {
      return;
    }
    const { MenuStore } = store;
    if (availableIntegrations && typeof menuStructure === "function") {
      MenuStore.setSideMenuToDisplay(menuStructure(availableIntegrations));
    } else {
      MenuStore.setSideMenuToDisplay(menuStructure);
    }
  }

  render() {
    return null;
  }
}

export default SetActiveMenu;

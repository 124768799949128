import React from "react";
import { useStoreDetails } from "../hooks/storeHooks";
import "./NewsFeed.scss";
import CFBLogo from "../../assets/img/cfb-logo-black-stacked.svg";

function NewsFeed() {
  const { currencyCode } = useStoreDetails();

  // Initially, hide this for non-US customers
  if (currencyCode !== "USD") return null;

  return (
    <div className="NewsFeed">
      <div className="NewsFeed__container">
        <img src={CFBLogo} />
        <div className="NewsFeed__content">
          <p className="NewsFeed__title--cfb">
            Want to sell products on ChannelFireball.com?
          </p>
          <p className="NewsFeed__title">
            Your first steps to selling on the ChannelFireball Marketplace.
          </p>
          <a
            className="NewsFeed__link--cfb"
            href="https://channelfireball.com/page/start-selling"
            target="_blank"
            rel="noreferrer"
          >
            Register now!
            <svg
              width="20"
              height="20"
              viewBox="0 0 25.317 44.635"
              style={{ transform: "rotate(180deg)" }}
            >
              <g transform="translate(3 4.243)">
                <path
                  fill="none"
                  stroke="#f68e3b"
                  d="M0,0,18.075,18.075,36.149,0"
                  transform="translate(18.75) rotate(90)"
                  style={{
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: 8,
                  }}
                ></path>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NewsFeed;

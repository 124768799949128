import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";
import { Loader } from "../../components";
import { OperationalMode, operationalMode } from "../../constants/mode";

@inject("store")
@observer
export default class SignInForm extends React.Component {
  @observable signInFailedMessage;
  @action setSignInFailedMessage(signInFailedMessage) {
    this.signInFailedMessage = signInFailedMessage;
  }

  @observable showResetPassword = false;
  @action setShowResetPassword(showResetPassword) {
    this.showResetPassword = showResetPassword;
  }

  @observable resetPasswordMessage;
  @action setResetPasswordMessage(resetPasswordMessage) {
    this.resetPasswordMessage = resetPasswordMessage;
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
    this.userEmail = React.createRef();
    this.userPassword = React.createRef();
  }

  removeMessageAndShowResetPassword(value) {
    this.setResetPasswordMessage(null);
    this.setSignInFailedMessage(null);
    this.setShowResetPassword(value);
  }

  signUserIn() {
    this.setState({ showLoader: true });
    firebase
      .auth()
      .signInWithEmailAndPassword(
        this.userEmail.current.value,
        this.userPassword.current.value
      )
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          this.setSignInFailedMessage(
            "You do not have an account. Contact BinderPOS for more information"
          );
        } else if (error.code == "auth/invalid-email") {
          this.setSignInFailedMessage("Please enter a valid email address");
        } else if (
          error.code == "auth/wrong-password" &&
          operationalMode !== OperationalMode.Kiosk
        ) {
          this.setSignInFailedMessage(
            'Username or password is incorrect, is it a Google account? Sign in using "Sign in with Google"'
          );
        } else if (
          error.code == "auth/wrong-password" &&
          operationalMode === OperationalMode.Kiosk
        ) {
          this.setSignInFailedMessage("Username or password is incorrect");
        } else {
          this.setSignInFailedMessage(error.message);
        }
      })
      .finally(() => this.setState({ showLoader: false }));
  }

  resetPassword() {
    this.setState({ showLoader: true });
    const email = this.userEmail.current.value;
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setResetPasswordMessage(
          "An email has been sent to " +
            email +
            " which contains instructions to reset your password"
        );
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          this.setSignInFailedMessage(
            "You do not have an account. Contact BinderPOS for more information"
          );
        } else if (error.code == "auth/invalid-email") {
          this.setSignInFailedMessage("Please enter a valid email address");
        } else if (
          error.code == "auth/wrong-password" &&
          operationalMode !== OperationalMode.Kiosk
        ) {
          this.setSignInFailedMessage(
            'Username or password is incorrect, is it a Google account? Sign in using "Sign in with Google"'
          );
        } else if (
          error.code == "auth/wrong-password" &&
          operationalMode === OperationalMode.Kiosk
        ) {
          this.setSignInFailedMessage("Username or password is incorrect");
        } else {
          this.setSignInFailedMessage(error.message);
        }
      })
      .finally(() => this.setState({ showLoader: false }));
  }

  render() {
    if (this.state.showLoader)
      return (
        <div className="loginWrapper">
          <Loader />
        </div>
      );

    return (
      <div className="loginWrapper">
        {this.signInFailedMessage ? (
          <p className="failed">
            <i className="fal fa-exclamation-triangle"></i>
            {this.signInFailedMessage}
          </p>
        ) : null}
        {this.resetPasswordMessage ? (
          <p className="reset">
            <i className="fal fa-check-circle"></i>
            {this.resetPasswordMessage}
          </p>
        ) : null}
        {!this.showResetPassword ? (
          <React.Fragment>
            <p className="title">
              Sign into <em>BinderPOS</em>
            </p>
            {operationalMode !== OperationalMode.Kiosk ? (
              <React.Fragment>
                <StyledFirebaseAuth
                  uiConfig={this.uiConfig}
                  firebaseAuth={firebase.auth()}
                />
                <p className="subTitle">or</p>
              </React.Fragment>
            ) : null}
            <label>
              Email
              <input
                ref={this.userEmail}
                id="useEmail"
                type="email"
                placeholder="Your email"
              />
            </label>
            <label>
              Password
              <input
                placeholder="*********************"
                ref={this.userPassword}
                id="userPassword"
                type="password"
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    this.signUserIn();
                  }
                }}
              />
            </label>
            <button
              className="signin"
              type="button"
              onClick={() => this.signUserIn()}
            >
              Sign in
            </button>
            <p className="link">
              <a onClick={() => this.removeMessageAndShowResetPassword(true)}>
                Reset password
              </a>
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="title recover">Recover your password</p>
            <label>
              Email
              <input
                ref={this.userEmail}
                id="useEmail"
                type="email"
                placeholder="Your email"
              />
            </label>
            <button
              className="signin"
              type="button"
              onClick={() => this.resetPassword()}
            >
              Reset my password
            </button>
            <p className="link">
              <a onClick={() => this.removeMessageAndShowResetPassword(false)}>
                Back to login
              </a>
            </p>
          </React.Fragment>
        )}
      </div>
    );
  }
}

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Loader, Paging, DateSelect } from "../../components";
import { DEFAULT_SUBMITTED_CARTS_PER_PAGE } from "../../constants/pos";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import {
  NumberInputFilter,
  TextInputFilter,
  TimestampRenderer,
} from "../../components/reports/CellTypes";
import { fetchAllCarts } from "../../api/rest/posSettings";
import { TableSpinner } from "../../components/reports";

@inject("store")
@inject("posStore")
@observer
class OrderHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  @observable pageOffset = 0;
  @action setPageOffset = (offset) => {
    this.pageOffset = offset;
  };

  @observable isLoading = false;
  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable cartFilters = {
    cartId: "",
    shopifyOrderId: "",
    tillId: "",
    firstName: "",
    lastName: "",
  };
  @action updateCartFilter = (parameter, value) => {
    if (parameter === "offset") {
      this.setPageOffset(value);
    } else {
      this.cartFilters[parameter] = value;
    }
  };

  @computed get cartParams() {
    // The line at the end removes parameters where a value is not set
    return {
      oldestFirst: false,
      offset: this.pageOffset,
      limit: DEFAULT_SUBMITTED_CARTS_PER_PAGE,
      ...Object.fromEntries(
        Object.entries(this.cartFilters).filter(([, value]) => !!value)
      ),
    };
  }

  componentDidMount() {
    this.loadCarts();
    this.props.store.MainStore.buildReceiptData();
  }

  loadCarts = () => {
    this.setIsLoading(true);
    fetchAllCarts(this.cartParams)
      .then((result) => {
        this.props.store.CartStore.setCarts(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load carts",
          "There was an error retrieving the list of carts. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  prevPage = () => {
    let newOffset = this.pageOffset - DEFAULT_SUBMITTED_CARTS_PER_PAGE;
    if (newOffset < 0) {
      newOffset = 0;
    }
    this.setPageOffset(newOffset);
    this.loadCarts();
  };

  nextPage = () => {
    const newOffset = this.pageOffset + DEFAULT_SUBMITTED_CARTS_PER_PAGE;
    this.setPageOffset(newOffset);
    this.loadCarts();
  };

  onCartFilterChange = (parameter, value) => {
    this.updateCartFilter(parameter, value);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(this.loadCarts, 500);
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }

    const {
      cartId = "",
      shopifyOrderId = "",
      firstName = "",
      lastName = "",
      containsNote = "",
    } = this.cartFilters;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_id" && (
                <NumberInputFilter
                  title="Filter by ID"
                  value={cartId}
                  setValue={(value) => this.onCartFilterChange("cartId", value)}
                />
              )}
              {key === "col_shopifyOrderId" && (
                <NumberInputFilter
                  title="Filter by shopify ID"
                  value={shopifyOrderId}
                  setValue={(value) =>
                    this.onCartFilterChange("shopifyOrderId", value)
                  }
                />
              )}

              {key === "col_firstName" && (
                <TextInputFilter
                  title="Filter by first name"
                  value={firstName}
                  setValue={(value) =>
                    this.onCartFilterChange("firstName", value)
                  }
                />
              )}
              {key === "col_lastName" && (
                <TextInputFilter
                  title="Filter by last name"
                  value={lastName}
                  setValue={(value) =>
                    this.onCartFilterChange("lastName", value)
                  }
                />
              )}
              {key === "col_note" && (
                <TextInputFilter
                  title="Filter by note"
                  value={containsNote}
                  setValue={(value) =>
                    this.onCartFilterChange("containsNote", value)
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  onRowClick = ({ rowData }) => {
    this.props.setOrderNumber(rowData.id);
  };

  render() {
    const { CartStore, MainStore } = this.props.store;

    if (!CartStore.carts) {
      return <Loader />;
    }

    const fC = MainStore.currencyBuilder;

    // Have to destructure here to force re-render
    const isLoading = this.isLoading;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Submitted Carts</span>
          </h2>
          <div className="table-controls">
            <DateSelect
              filterValues={this.cartFilters}
              updateFilterValue={this.onCartFilterChange}
            />
            <Paging
              pageOffset={this.pageOffset}
              currentPageItemCount={CartStore.carts && CartStore.carts.length}
              maxItemsPerPage={1}
              getPrevPage={this.prevPage}
              getNextPage={this.nextPage}
            />
          </div>
        </div>
        <div>
          <div className="orders">
            <div className="order_list">
              <div className="data-table">
                <div
                  style={{
                    height: `${
                      CartStore.carts ? CartStore.carts.length * 50 + 80 : 0
                    }px`,
                  }}
                >
                  <AutoResizer>
                    {({ width }) => (
                      <BaseTable
                        data={CartStore.carts}
                        width={width}
                        height={
                          CartStore.carts ? CartStore.carts.length * 50 + 80 : 0
                        }
                        rowHeight={50}
                        headerHeight={[40, 40]}
                        headerRenderer={this.headerRenderer}
                        overlayRenderer={() => (
                          <TableSpinner isLoading={isLoading} />
                        )}
                        rowEventHandlers={{ onClick: this.onRowClick }}
                        rowClassName="table-row-clickable"
                      >
                        <Column
                          key="col_submitted"
                          title="Date"
                          dataKey="dateSubmitted"
                          cellRenderer={TimestampRenderer}
                          width={190}
                          resizable
                        />
                        <Column
                          key="col_id"
                          title="Cart"
                          dataKey="id"
                          width={110}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_shopifyOrderId"
                          title="Shopify Order"
                          dataKey="shopifyOrderId"
                          width={140}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_processedBy"
                          title="Completed By"
                          dataKey="submittedBy"
                          width={120}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_firstName"
                          title="First Name"
                          dataKey="customer"
                          cellRenderer={({ cellData }) =>
                            cellData?.firstName || "Guest"
                          }
                          width={120}
                          resizable
                        />
                        <Column
                          key="col_lastName"
                          title="Last Name"
                          dataKey="customer.lastName"
                          width={120}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_note"
                          title="Notes"
                          dataKey="notes"
                          width={120}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_sale_total"
                          title="Sale Total"
                          dataKey="totalPrice"
                          width={120}
                          resizable
                          cellRenderer={({ cellData }) => fC(cellData)}
                        />
                        <Column
                          key="col_type"
                          title="Type"
                          dataKey="cartType"
                          cellRenderer={({ cellData }) => cellData || "POS"}
                          width={120}
                          resizable
                        />
                        <Column
                          key="col_return"
                          dataKey="id"
                          cellRenderer={({ rowData }) =>
                            rowData.cartType !== "return" && (
                              <i
                                title="Undo"
                                onClick={() =>
                                  this.props.createReturn(rowData.id)
                                }
                                className="fas fa-undo-alt"
                              />
                            )
                          }
                          width={120}
                        />
                      </BaseTable>
                    )}
                  </AutoResizer>
                </div>
              </div>
              {CartStore.carts && CartStore.carts.length > 10 && (
                <Paging
                  pageOffset={this.pageOffset}
                  currentPageItemCount={
                    CartStore.carts && CartStore.carts.length
                  }
                  maxItemsPerPage={1}
                  getPrevPage={this.prevPage}
                  getNextPage={this.nextPage}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

OrderHistoryTable.propTypres = {
  setOrderNumber: PropTypes.func.isRequired,
  createReturn: PropTypes.func.isRequired,
};

export default OrderHistoryTable;

import { getSalesByOrderBetaReport } from "../../api/graphql/reports";
import TimeUtils from "../../utils/TimeUtils";
import {
  Pagination,
  ReportControls,
  SalesSourceSelect,
  TableSpinner,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import { reformatSalesByOrderBeta } from "../../utils/reports";
import CheckboxComponent from "../../components/generic/CheckboxComponent";

@inject("store")
@observer
class SalesByOrderBeta extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  @observable showFilterSourceModal = false;
  @action setShowFilterSourceModal = (show) => {
    this.showFilterSourceModal = show;
  };

  @observable filterSoures = {};
  @action setFilterSources = (sources) => {
    this.filterSources = sources;
    this.props.store.ReportsStore.updateFilterValue("sources", sources);
  };

  updateFilterSources = (selected) => {
    this.setFilterSources(selected);
    this.setShowFilterSourceModal(false);
  };

  @observable sourceTypes = [];
  @action setSourceTypes = (types) => {
    this.sourceTypes = types;
  };

  @observable tenderTypes = [];
  @action setTenderTypes = (types) => {
    this.tenderTypes = types;
  };

  @observable showTenders = false;
  @action setShowTenders = (show) => {
    this.showTenders = show;
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getSalesByOrderBetaReport(fromDate, toDate)
      .then((data) => {
        const { rows, tenders, sources } = reformatSalesByOrderBeta(data);
        this.setSourceTypes(sources);
        this.setTenderTypes(tenders);
        const selectedSources = sources.reduce((selected, source) => {
          selected[source] = true;
          return selected;
        }, {});
        this.updateFilterSources(selectedSources);
        this.props.store.ReportsStore.setSourceData(rows);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your sales by order report. Please  try again"
        );
      });
  };

  getColumnTotal = (tableData, key) => {
    const tenderType = key.split("_")?.pop();
    if (!tenderType) return;
    return (
      tableData.length &&
      tableData.reduce((sum, row) => sum + (row[tenderType] || 0), 0)
    );
  };

  getTenderTotal = (tableData, key) => {
    const tenderType = key.split("_^")?.pop();
    if (!tenderType) return;
    return (
      tableData.length &&
      tableData.reduce(
        (sum, row) =>
          sum +
          ((row.tenders &&
            row.tenders.find((tender) => tender.type === tenderType)?.amount) ||
            0),
        0
      )
    );
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue, tableData } =
      this.props.store.ReportsStore;
    const { orderId = "" } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          let { key, width, flexGrow, align } = column;
          if (key.includes("col_lineCount")) {
            width = "9em";
          }
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow, textAlign: align }}
              key={`header_${key}`}
            >
              {key === "col_order_id" && (
                <TextInputFilter
                  value={orderId}
                  setValue={(value) => updateFilterValue("orderId", value)}
                />
              )}
              {key === "col_source" && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.setShowFilterSourceModal(true)}
                >
                  Sources
                </button>
              )}
              {key.includes("col_tender_") && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getTenderTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key.includes("col_sale") && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getColumnTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key.includes("col_refund") && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getColumnTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_totalTax" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.totalTax, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_beforeTax" && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getColumnTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key.includes("col_buy") && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getColumnTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_total" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.total, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "orderId",
      condition: (filterValues) => filterValues.orderId,
      filterFunction: (row, filterValues) =>
        row.orderId && row.orderId.includes(filterValues.orderId),
    },
    {
      name: "source",
      condition: () => true,
      filterFunction: (row, filterValues) =>
        row.source && filterValues.sources[row.source],
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders1 = [
      {
        name: "Date",
        key: "createdAt",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      { name: "Order Id", key: "orderId" },
      { name: "Source", key: "source" },
    ];
    const csvHeaders2 = [
      {
        name: "Sales",
        key: "sale",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Refunds",
        key: "refund",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Tax",
        key: "totalTax",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Sales Before Tax",
        key: "beforeTax",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Buys",
        key: "buy",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Total (Incl. Tax)",
        key: "total",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "Sales By Order (beta).csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    const csvTenderHeaders = this.showTenders
      ? this.tenderTypes.reduce(
          (arr, tender) => [
            ...arr,
            {
              name: tender,
              key: tender,
              formatter: (number) => number && number.toFixed(2),
            },
          ],
          []
        )
      : [];

    const csvHeaders = [...csvHeaders1, ...csvTenderHeaders, ...csvHeaders2];

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Sales By Order - Beta</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          >
            <span className="checkbox">
              <CheckboxComponent
                name="showTenders"
                label="Show Tenders"
                onChange={(e) => this.setShowTenders(e.target.checked)}
              />
            </span>
          </ReportControls>
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      fixed
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      rowKey="orderId"
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col_date"
                        dataKey="createdAt"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        title="Date"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_order_id"
                        dataKey="orderId"
                        width={150}
                        title="Order Id"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_source"
                        dataKey="source"
                        width={150}
                        align="right"
                        title="Source"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) =>
                          cellData.replace(/_/g, " ")
                        }
                      />
                      {this.tenderTypes.map((tenderType, index) => (
                        <Column
                          key={`col_tender_${index}_^${tenderType}`}
                          dataKey={tenderType}
                          width={110}
                          align="right"
                          title={tenderType}
                          hidden={!this.showTenders}
                          sortable
                          resizable
                          currency={MainStore.currencyCode}
                          cellRenderer={CurrencyRenderer}
                        />
                      ))}
                      <Column
                        key="col_sale"
                        dataKey="sale"
                        width={150}
                        align="right"
                        title="Sales"
                        sortable
                        resizable
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                      />
                      <Column
                        key="col_refund"
                        dataKey="refund"
                        width={150}
                        align="right"
                        title="Refunds"
                        sortable
                        resizable
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                      />
                      <Column
                        key="col_totalTax"
                        dataKey="totalTax"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Tax"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_beforeTax"
                        dataKey="beforeTax"
                        width={150}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Sales Before Tax"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_buy"
                        dataKey="buy"
                        width={150}
                        align="right"
                        title="Buys"
                        sortable
                        resizable
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                      />
                      <Column
                        key="col_total"
                        dataKey="total"
                        width={150}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Total (Incl. Tax)"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
        {this.showFilterSourceModal && (
          <SalesSourceSelect
            options={this.sourceTypes}
            value={this.filterSources}
            setValue={this.updateFilterSources}
            onClose={() => this.setShowFilterSourceModal(false)}
          />
        )}
      </Fragment>
    );
  }
}

SalesByOrderBeta.propTypes = { store: PropTypes.object };
export default SalesByOrderBeta;

/**
 * Formats the GraphQL output of the End of Day report into something
 * more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatEodReport = (report) => {
  const floatEntries = report?.POSReports?.FloatEntries;
  if (!floatEntries) {
    return [];
  }
  const reformattedEodReport = [];
  floatEntries.forEach((line, index) => {
    const { tenders, ...props } = line;
    const newLine = { ...props };
    newLine.id = index;
    tenders.forEach((tender) => {
      const { name, ...amounts } = tender;
      const keyName = name.replace(" ", "_");
      newLine[keyName] = amounts;
      const difference = amounts.closingAmount - amounts.currentAmount;
      newLine[keyName].difference = difference || 0;
    });
    reformattedEodReport.push(newLine);
  });
  return reformattedEodReport;
};

/**
 * Formats the GraphQL output of the Stock Inventory Value report into
 * something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatStockInventoryValue = (report) => {
  const retailValue = report?.Metrics?.RetailValue;
  if (!retailValue) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return retailValue.map((row, index) => ({ ...row, id: index }));
};

/**
 * Formats the GraphQL output of the Total Sales By Category report into
 * something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatTotalSalesByCategory = (report) => {
  const categorySales = report?.Metrics?.CategorySales;
  if (!categorySales) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return categorySales.map((row, index) => ({
    ...row,
    id: index,
    buys: 0 - row.buys,
    total: row.buys + row.sales,
  }));
};

/**
 * Formats the GraphQL output of the WPN Report into something more
 * table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatWPNReport = (report) => {
  const wpnReport = report?.Metrics?.WPNReport;
  if (!wpnReport) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return wpnReport.map((row, index) => ({
    ...row,
    id: index,
  }));
};

/**
 * Formats the GraphQL output of the Sales By Range report into
 * something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatSalesByRange = (report) => {
  const salesByRange = report?.Metrics?.SalesByRange;
  if (!salesByRange) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return salesByRange.map((row, index) => ({
    ...row,
    id: index,
  }));
};

const formatKind = (kind) => {
  if (kind === "void" || kind === "refund") return "refund";
  return kind;
};

export const reformatSalesByTender = (report) => {
  const salesByTender = report?.Metrics?.SalesByTender;
  if (!salesByTender) return [];
  const rows = [];
  salesByTender.forEach((row) => {
    row.tenders &&
      row.tenders.forEach((tender) => {
        if (!tender) return;
        rows.push({
          id: tender.id,
          dateCreated: tender.dateCreated,
          orderId: row.orderId,
          source: row.source,
          tenderType: tender.type,
          [formatKind(tender.kind)]: tender.amount,
          total: tender.amount,
        });
      });
  });

  const tenderTypes = [...new Set(rows.map((row) => row.tenderType).flat())];
  tenderTypes.sort();

  const sources = [...new Set(rows.map((row) => row.source))];

  return {
    rows,
    tenderTypes,
    sources,
  };
};
/**
 * Formats the GraphQL output of the Sales By Order report into
 * something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatSalesByOrder = (report) => {
  let salesByOrder = report?.Metrics?.SalesByOrder;
  if (!salesByOrder) {
    salesByOrder = [];
  }

  // Split out tenders and add an ID field so the table can use it as a key
  const rows = salesByOrder.map((row, index) => {
    row.tenders &&
      row.tenders.forEach(
        (tender) => tender && (row[tender.type] = tender.amount)
      );
    return {
      ...row,
      id: index,
    };
  });

  const tenderTypes = [
    ...new Set(
      salesByOrder
        .filter((order) => order.tenders)
        .map((order) => order.tenders.map((tender) => tender.type))
        .flat()
    ),
  ];
  tenderTypes.sort();

  const sources = [...new Set(salesByOrder.map((order) => order.source))];

  return {
    rows,
    tenderTypes,
    sources,
  };
};

export const reformatSalesByOrderBeta = (report) => {
  const salesByOrder = report?.Metrics?.SalesByOrderBeta;
  if (!salesByOrder) return [];
  const tenderTypes = new Set();
  const rows = [];
  salesByOrder.forEach((row) => {
    const newRow = { ...row };
    row.tenders &&
      row.tenders.forEach((tender) => {
        if (!tender) return;
        const kind = formatKind(tender.kind);
        if (kind in newRow) {
          newRow[kind] = newRow[kind] + tender.amount;
        } else {
          newRow[kind] = tender.amount;
        }
        // newRow[formatKind(tender.kind)] = tender.amount;
        newRow[tender.type] = tender.amount;
        tenderTypes.add(tender.type);
      });
    newRow.beforeTax =
      (newRow.sale || 0) + (newRow.refund || 0) - (newRow.totalTax || 0);
    rows.push(newRow);
  });
  const sources = [...new Set(rows.map((row) => row.source))];
  return {
    rows,
    sources,
    tenders: [...tenderTypes].sort(),
  };
};

/**
 * Formats the GraphQL output of the Buylist report into
 * something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatBuylistReport = (report) => {
  const buylistDetails = report?.Metrics?.BuylistDetails;
  if (!buylistDetails) {
    return [];
  }
  return buylistDetails;
};

/**
 * Formats the GraphQL outputStoreCreditTransactionSummary
 * into something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatStoreCreditTransactionSummary = (report) => {
  const reportData = report?.Metrics?.StoreCreditByRange;
  if (!reportData) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return reportData.map((row, index) => ({
    ...row,
    id: index,
  }));
};

/**
 * Formats the GraphQL POSCustomer
 * into something more table-friendly.
 *
 * @param {object} report GraphQL Output
 *
 * @returns {object}
 */
export const reformatStoreCreditOustanding = (report) => {
  const reportData = report?.Customers;
  if (!reportData) {
    return [];
  }
  // Add an ID field so the table can use it as a key
  return reportData.map((row, index) => ({
    ...row,
    id: index,
  }));
};

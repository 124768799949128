import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import Loader from "../generic/Loader";
import Spinner from "../generic/Spinner";
import {
  useFetchEventParticipant,
  useRemoveEventParticipant,
} from "../../hooks/eventHooks";
import { useHistory, useParams } from "react-router-dom";

type RouteParams = {
  eventId: string;
  participantId: string;
};

function RemoveParticipantModal() {
  const { eventId, participantId } = useParams<RouteParams>();
  const history = useHistory();
  const { isLoading, data: participant } = useFetchEventParticipant({
    eventId,
    participantId,
  });
  const { isMutating, mutateAsync } = useRemoveEventParticipant();

  const onClose = () => {
    history.push(`/events/participants/${eventId}`);
  };

  const handleRemove = () =>
    mutateAsync(
      { eventId, participantId },
      {
        onSuccess: onClose,
      }
    );

  if (isLoading) {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <Spinner isLoading={isMutating}>
          <header className="modal-card-head">
            <p className="modal-card-title">Remove participant</p>
            <button className="delete" aria-label="close" onClick={onClose}>
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            <p>
              Are you sure you want to remove{" "}
              {participant?.participantName ?? "the participant"} from this
              event?
            </p>
            <p>
              Please note that removing a participant will not refund any ticket
              purchase payment.
            </p>
          </section>
          <footer className="modal-card-foot">
            <ButtonComponent
              icon="fal fa-times"
              iconPosition="right"
              secondary
              halfWidth
              onClick={onClose}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              disabled={isMutating}
              halfWidth
              danger
              onClick={handleRemove}
              icon="fas fa-caret-right"
              iconPosition="right"
            >
              Remove Participant
            </ButtonComponent>
          </footer>
        </Spinner>
      </div>
    </div>
  );
}

export default RemoveParticipantModal;

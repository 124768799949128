import React from "react";
import { NavLink } from "react-router-dom";
import "./SideMenu.scss";

interface SideMenuProps {
  sideMenu?: MenuStructure;
}

function SideMenu(props: SideMenuProps) {
  const { sideMenu } = props;

  if (!sideMenu) {
    return null;
  }

  return (
    <div className="SideMenu">
      <h2 className="SideMenu__title">{sideMenu.title}</h2>
      <div>
        {(sideMenu?.links || []).map((link) => {
          return (
            <div className="SideMenu__item" key={link.title}>
              <NavLink
                className="SideMenu__link"
                activeClassName="SideMenu__link--active"
                to={`/${link.href}`}
                exact={link.exact}
              >
                <span>{link.title}</span>
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideMenu;

import React from "react";

/**
 * Renders a tiny loading message.
 */
const SideLabelNumberInput = (props) => (
  <div className="fieldBlock">
    <div className="block">
      <p className="title">{props.label}</p>
      {props.helpText ? <p className="help">{props.helpText}</p> : null}
      <input
        id={props.inputId}
        className="input"
        type="number"
        digits={props.digits}
        min={props.min}
        placeholder={props.placeholder}
        required={props.required}
        defaultValue={props.defaultValue}
      />
    </div>
  </div>
);

export default SideLabelNumberInput;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { TimeUtils } from "../../utils";

function ParentEvent(props) {
  const {
    parentEvent,
    showViewEventModalForEvent,
    setEventInStore,
    showDeleteEventModalForDelete,
  } = props;
  const { id, title, game, type, date, time } = parentEvent;

  return (
    <Fragment>
      <p className="h4">Parent Event</p>
      <div className="customer_list reviewBg">
        <table>
          <thead>
            <tr>
              <td className="col1 eventTitle">Title</td>
              <td className="col2">Game</td>
              <td className="col3">Type</td>
              <td className="col3">Start date &amp; time</td>
              <td className="col3"></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="eventTitle1">{title}</td>
              <td>{game}</td>
              <td>{type}</td>
              <td>
                {TimeUtils.convertDateToHumanReadableNoTime(date)} at{" "}
                {TimeUtils.convertTime(time)}
              </td>
              <td className="actions">
                <a
                  title="View event details."
                  onClick={() => showViewEventModalForEvent(id)}
                >
                  <i className="fal fa-eye" />
                </a>
                <Link
                  title="Update master event information."
                  to={`/events/update/${id}`}
                  onClick={() => setEventInStore(parentEvent)}
                >
                  <i className="fal fa-edit" />
                </Link>
                <a
                  title="Delete the event including recurring events."
                  onClick={() => showDeleteEventModalForDelete(id)}
                >
                  <i className="fal fa-trash-alt" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

ParentEvent.propTypes = {
  parentEvent: PropTypes.object,
  showViewEventModalForEvent: PropTypes.func.isRequired,
  showDeleteEventModalForDelete: PropTypes.func.isRequired,
  setEventInStore: PropTypes.func.isRequired,
};

export default ParentEvent;

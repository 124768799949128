import { commaSplitWithAnd, getOrdinal } from "../utils/formatting";
import { TimeUtils } from "../utils";

/**
 * Generates a human readable description of a recurring event
 *
 * @param {object} event
 *
 * @returns {string}
 */
export function generateTextForRecurring(event = {}) {
  const {
    recurring,
    recurringType,
    recurringFrequency,
    recurringDays,
    recurringDate,
    recurringEndDate,
  } = event;
  if (recurring) {
    let typeName = `${recurringType}s`;
    if (recurringFrequency == 1) {
      if (recurringType.toLowerCase() == "day") {
        typeName = "daily";
      } else {
        typeName = `${recurringType}ly`;
      }
    }
    let value = `Event scheduled ${
      recurringFrequency == 1 ? "" : `for every ${recurringFrequency} `
    }${typeName}`;
    if (recurringType == "week") {
      value += ` on ${commaSplitWithAnd(recurringDays)}`;
    }
    if (event.recurringType == "month") {
      value += ` on ${getOrdinal(recurringDate)} of every month`;
    }
    if (event.recurringEndDate) {
      value += ` and ends on ${TimeUtils.convertDateToHumanReadableNoTime(
        recurringEndDate
      )}`;
    }
    return value;
  }
  return "This is a single event";
}

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Spinner from "../../components/generic/Spinner";

function DeleteEventModal(props) {
  const {
    id,
    title,
    parentEventId,
    isLoading,
    setDeleteModalVisible,
    deleteEvent,
  } = props;
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <Spinner isLoading={isLoading}>
          <header className="modal-card-head">
            <p className="modal-card-title">Are you sure?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setDeleteModalVisible(false)}
            >
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            {parentEventId ? (
              <Fragment>
                <p>
                  If you proceed with disabling this event:{" "}
                  <strong>{title}</strong>, customers will not be able to
                  register for this event if you do so.
                </p>
                <p>Are you sure you want to disable the event?</p>
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  If you proceed with deleting your event:{" "}
                  <strong>{title}</strong>, you will not be able to restore it.
                </p>
                <p>Are you sure you want to delete the event?</p>
              </Fragment>
            )}
          </section>
          <footer className="modal-card-foot">
            <button
              className="modalBtn cancel"
              onClick={() => setDeleteModalVisible(false)}
            >
              <i className="fal fa-times" /> Cancel
            </button>
            <button
              className="modalBtn action"
              disabled={isLoading}
              onClick={() => deleteEvent(id)}
            >
              {parentEventId ? "Disable " : "Delete "}
              <i className="fas fa-caret-right" />
            </button>
          </footer>
        </Spinner>
      </div>
    </div>
  );
}

DeleteEventModal.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  parentEventId: PropTypes.bool,
  isLoading: PropTypes.bool,
  setDeleteModalVisible: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
};

export default DeleteEventModal;

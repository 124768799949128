import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import { ReportsMenuStructure } from "../../menuStructures";
import { API_STATE } from "../../constants/api";
import { getEndOfDayReports } from "../../api/graphql/reports";
import { reformatEodReport } from "../../utils/reports";
import {
  Pagination,
  ReportControls,
  TableSpinner,
  LedgerReport,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import "react-base-table/styles.css";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class FloatReport extends React.Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
    // By default, order from newest to oldest
    ReportsStore.setSortColumn({
      key: "col1",
      order: "desc",
      column: {
        dataKey: "openedDate",
      },
    });
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  @observable showLedger;
  @action setShowLedger = (id) => {
    this.showLedger = id;
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getEndOfDayReports(fromDate, toDate)
      .then((data) => {
        const reportData = reformatEodReport(data);
        this.props.store.ReportsStore.setSourceData(reportData);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your float report. Please try again"
        );
      });
  };

  tendersDataGetter = ({ column, rowData }) => {
    const { dataKey } = column;
    const { tenders } = rowData;
    const [name, field] = dataKey.split(this.SEPERATOR);
    const tenderValues = tenders.filter((tender) => tender.name === name);
    if (tenderValues.length < 1) {
      return undefined;
    }
    return tenderValues[0][field];
  };

  discrepencyDataGetter = ({ column, rowData }) => {
    const { dataKey } = column;
    const { tenders } = rowData;
    const tenderValues = tenders.filter((tender) => tender.name === dataKey);
    if (tenderValues.length < 1) {
      return undefined;
    }
    const { currentAmount = 0, closingAmount = 0 } = tenderValues[0];
    const discrepency = closingAmount - currentAmount;
    return discrepency;
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;
    const { name } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_till_name" && (
                <TextInputFilter
                  value={name}
                  setValue={(value) => updateFilterValue("name", value)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "till name",
      condition: (filterValues) =>
        filterValues.name && filterValues.name.length,
      filterFunction: (row, filterValues) =>
        row.name &&
        row.name.toLowerCase().includes(filterValues.name.toLowerCase()),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Till Name", key: "name" },
      {
        name: "Opening Time",
        key: "openedDate",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      {
        name: "Closing Time",
        key: "closedDate",
        formatter: (date) =>
          date ? TimeUtils.convertDateToHumanReadable(date) : "",
      },
      {
        name: "Cash Openining",
        key: "Cash.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cash Current",
        key: "Cash.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cash Difference",
        key: "Cash.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Cash Closing",
        key: "Cash.closingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Opening",
        key: "Credit.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Current",
        key: "Credit.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Difference",
        key: "Credit.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Closing",
        key: "Credit.closingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Opening",
        key: "EFTPOS.openingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Current",
        key: "EFTPOS.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Difference",
        key: "EFTPOS.difference",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "EFTPOS Closing",
        key: "EFTPOS.closingAmount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Store Credit",
        key: "Store_Credit.currentAmount",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "Float Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      totalRowCount,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Float Report</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      fixed
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col_actionLedger"
                        dataKey="floatId"
                        width={75}
                        flexGrow={1}
                        title="Ledger"
                        resizable
                        cellRenderer={({ cellData }) =>
                          !!cellData && (
                            <button
                              onClick={() => this.setShowLedger(cellData)}
                              className="button"
                            >
                              Ledger
                            </button>
                          )
                        }
                      />
                      <Column
                        key="col_till_name"
                        dataKey="name"
                        width={200}
                        title="Name"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_opened_date"
                        dataKey="openedDate"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        title="Opening Time"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_closed_date"
                        dataKey="closedDate"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        title="Closing Time"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cash_opening"
                        dataKey="Cash.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cash_current"
                        dataKey="Cash.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cash_diff"
                        dataKey="Cash.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cash_closing"
                        dataKey="Cash.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Cash Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_credit_opening"
                        dataKey="Credit.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_credit_current"
                        dataKey="Credit.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_credit_diff"
                        dataKey="Credit.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_credit_closing"
                        dataKey="Credit.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Credit Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_eftpos_opening"
                        dataKey="EFTPOS.openingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Opening"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_eftpos_current"
                        dataKey="EFTPOS.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Current"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_eftpos_diff"
                        dataKey="EFTPOS.difference"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Diff"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_eftpos_closing"
                        dataKey="EFTPOS.closingAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="EFTPOS Closing"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_storecredit_current"
                        dataKey="Store_Credit.currentAmount"
                        width={150}
                        align="right"
                        cellRenderer={CurrencyRenderer}
                        title="Store Credit"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={totalRowCount}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
        {!!this.showLedger && (
          <LedgerReport
            id={this.showLedger}
            customerTimezone={this.props.store.SettingsStore.customerTimezone}
            currencyCode={this.props.store.MainStore.currencyCode}
            onClose={() => this.setShowLedger()}
          />
        )}
      </Fragment>
    );
  }
}

FloatReport.propTypes = { store: PropTypes.object };
export default FloatReport;

export const reformatParticipantAdditionalInfo = (
  additionalInfo: (
    | EventParticipantAdditionalInfo
    | EventParticipantAdditionalInfoBasic
  )[]
): EventParticipantAdditionalInfoBasic[] => {
  if (additionalInfo.length < 1) return [];
  if ("value" in additionalInfo[0]) {
    return additionalInfo as EventParticipantAdditionalInfoBasic[];
  }
  return (additionalInfo as EventParticipantAdditionalInfo[]).map((info) => ({
    id: info.id,
    value: info.info?.value,
  }));
};

import {
  addEvent,
  fetchEventCategories,
  fetchEventInfo,
  fetchPotentialExistingMainEvents,
  fetchPotentialNewMainEvents,
  updateEvent,
} from "../../api/rest/events";
import { Loader, SearchBarLocation } from "../../components";
import { EventMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import SunEditor, { buttonList } from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
import { TimeUtils } from "../../utils";
import { generateTextForRecurring } from "../../utils/events";
import UploadImage from "../../../assets/img/uploadImage.png";

@inject("store")
@observer
class Event extends React.Component {
  @observable isRecurring;

  @observable recurringType;

  @action setRecurring(isRecurring) {
    this.isRecurring = isRecurring;
  }

  @action setRecurringType(recurringType) {
    this.recurringType = recurringType;
  }

  @observable removingParentEvent = false;

  @action setRemovingParentEvent(val) {
    this.removingParentEvent = val;
  }

  @observable mainEventToRemove;

  @action setMainEventToRemove(val) {
    this.mainEventToRemove = val;
  }

  @observable ticketed;

  @action setTicketed(ticketed) {
    this.ticketed = ticketed;
  }

  @observable mainEvents;

  @action setMainEvents(mainEvent) {
    this.mainEvents = mainEvent;
  }

  @observable additionalInfo;

  @action setAdditionalInfo(additionalInfo) {
    this.additionalInfo = additionalInfo;
  }

  @observable updating;

  @action setUpdating(bool) {
    this.updating = bool;
  }

  @observable modalVisible = false;

  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;

  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;

  @action setModalText(text) {
    this.modalText = text;
  }

  @observable hasValidationErrors = false;

  @action setHasValidationErrors(bool) {
    this.hasValidationErrors = bool;
  }

  @observable calendarIconImage;

  @action setCalendarIconImage(image) {
    this.calendarIconImage = image;
  }

  @observable calendarBannerImage;

  @action setCalendarBannerImage(image) {
    this.calendarBannerImage = image;
  }

  @observable calendarTicketImage;

  @action setCalendarTicketImage(image) {
    this.calendarTicketImage = image;
  }

  @observable additionalInfoList = [
    { header: "", headerDescription: "", timestamp: new Date().getTime() },
  ];

  @action addAdditionalInfo() {
    const additionalInfo = {
      header: "",
      headerDescription: "",
      timestamp: new Date().getTime(),
    };
    this.additionalInfoList.push(additionalInfo);
  }

  @action setAdditionalInfoList(additionalInfo) {
    this.additionalInfoList = additionalInfo;
  }

  @action removeAdditionalInfoFromList(index) {
    const tmpArray = this.additionalInfoList.slice();
    if (index > -1) {
      tmpArray.splice(index, 1);
    }
    this.additionalInfoList = tmpArray;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      description: "",
      prize: "",
      results: "",
    };
  }

  fetchEventInfo(id) {
    return fetchEventInfo(id)
      .then((event) => {
        this.props.store.EventsStore.setEvent(event);
        this.setState({
          description: event.description,
          prize: event.prizeStructure,
          results: event.results,
        });
        this.setMainEventToRemove(event.mainEvent);
        if (
          this.props.store.EventsStore.event.calendarIcon &&
          this.props.store.EventsStore.event.calendarIcon.length > 0
        ) {
          this.setCalendarIconImage({
            src: this.props.store.EventsStore.event?.calendarIcon,
            name: "Calendar icon",
          });
        }
        if (
          this.props.store.EventsStore.event.banner &&
          this.props.store.EventsStore.event.banner.length > 0
        ) {
          this.setCalendarBannerImage({
            src: this.props.store.EventsStore.event?.banner,
            name: "Background image",
          });
        }
        if (
          this.props.store.EventsStore.event.ticketImage &&
          this.props.store.EventsStore.event.ticketImage.length > 0
        ) {
          this.setCalendarTicketImage({
            src: this.props.store.EventsStore.event?.ticketImage,
            name: "Ticket image",
          });
        }
        if (this.props.store.EventsStore.event?.isTicketed) {
          this.setTicketed(true);
        }
        if (this.props.store.EventsStore.event?.additionalInfoRequired) {
          this.setAdditionalInfo(
            this.props.store.EventsStore.event?.additionalInfoRequired
          );
        }
        if (this.props.store.EventsStore.event?.additionalInfo) {
          var additionalInfo = [];
          this.props.store.EventsStore.event.additionalInfo.forEach(
            (additionalInfoItem) => {
              const addInfo = {
                id: additionalInfoItem.id,
                header: additionalInfoItem.header,
                headerDescription: additionalInfoItem.headerDescription,
                timestamp: additionalInfoItem.id,
              };
              additionalInfo.push(addInfo);
            }
          );
        }
        this.setAdditionalInfoList(
          additionalInfo && additionalInfo.length > 0 ? additionalInfo : []
        );
        return Promise.resolve();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load event",
          "There was an error retrieving the event information. Please refresh and try again"
        );
      });
  }

  fetchCategoryInfo() {
    return fetchEventCategories()
      .then((result) => {
        this.props.store.EventsStore.setEventCategories(result);
        return Promise.resolve();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load event categories",
          "There was an error retrieving the list of event categories. Please refresh and try again"
        );
      });
  }

  showCalendarIconImage() {
    const input = this.form.current.elements.eventCalendarIcon;
    if (input.files && input.files[0]) {
      const fileSize = input.files[0].size / 1024 / 1024;
      const fileName = input.files[0].name;
      const ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        const reader = new FileReader();
        reader.onload = (e) => {
          ticketObj.src = e.target.result;
          this.setCalendarIconImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        const modalText =
          "<p>Your calendar icon cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload again</p>";
        const modalHeader = "Calendar icon uploaded is too large";
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventCalendarIcon.value = null;
        this.setCalendarIconImage(null);
      }
    }
  }

  showCalendarBannerImage() {
    const input = this.form.current.elements.eventBanner;
    if (input.files && input.files[0]) {
      const fileSize = input.files[0].size / 1024 / 1024;
      const fileName = input.files[0].name;
      const ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        const reader = new FileReader();
        reader.onload = (e) => {
          ticketObj.src = e.target.result;
          this.setCalendarBannerImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        const modalText =
          "<p>Your background cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload it again</p>";
        const modalHeader = "background uploaded is too large";
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventBanner.value = null;
        this.setCalendarBannerImage(null);
      }
    }
  }

  showCalendarTicketImage() {
    const input = this.form.current.elements.eventTicketImage;
    if (input.files && input.files[0]) {
      const fileSize = input.files[0].size / 1024 / 1024;
      const fileName = input.files[0].name;
      const ticketObj = {
        name: fileName,
      };
      if (fileSize <= 2) {
        const reader = new FileReader();
        reader.onload = (e) => {
          ticketObj.src = e.target.result;
          this.setCalendarTicketImage(ticketObj);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        const modalText =
          "<p>Your event ticket image cannot be more than <strong>2mb</strong> in size. Please reduce the size of the image and upload it again</p>";
        const modalHeader = "Event ticket image uploaded is too large";
        this.showErrorModal(modalText, modalHeader);
        this.form.current.elements.eventTicketImage.value = null;
        this.setCalendarTicketImage(null);
      }
    }
  }

  showErrorModal(modalText, modalHeader) {
    this.setUpdating(false);
    this.setModalVisible(true);
    this.setModalTitle(modalHeader);
    this.setModalText(modalText);
    this.setHasValidationErrors(true);
  }

  componentDidMount() {
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);
    this.props.store.EventsStore.setEvent(null);
    const id = this.props.match?.params?.eventId || null;
    if (id) {
      document.title = "Update Event | BinderPOS";
      this.fetchEventInfo(id);
      fetchPotentialExistingMainEvents(id).then((result) => {
        this.setMainEvents(result);
      });
    } else {
      document.title = "Add Event | BinderPOS";
      fetchPotentialNewMainEvents(id).then((result) => {
        this.setMainEvents(result);
      });
    }
    if (!this.props.store.EventsStore.eventCategories) {
      this.fetchCategoryInfo();
    }
    this.props.store.WindowSizeStore.setWindow();
    this.setState({
      description: this.props.EventsStore?.event?.description || "",
      prize: this.props.EventsStore?.event?.prizeStructure || "",
      results: this.props.EventsStore?.event?.results || "",
    });
  }

  getDateToday() {
    return new Date().getDate();
  }

  getDaysArray() {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  }

  changeRecurringText() {
    const recurringFrequency =
      this.form.current.elements.recurringFrequency.value;
    if (!recurringFrequency) {
      return;
    }
    if (recurringFrequency > 1) {
      this.form.current.elements.recurringSelect.options[0].text = "Days";
      this.form.current.elements.recurringSelect.options[1].text = "Weeks";
      this.form.current.elements.recurringSelect.options[2].text = "Months";
      this.form.current.elements.recurringSelect.options[3].text = "Years";
    } else {
      this.form.current.elements.recurringSelect.options[0].text = "Day";
      this.form.current.elements.recurringSelect.options[1].text = "Week";
      this.form.current.elements.recurringSelect.options[2].text = "Month";
      this.form.current.elements.recurringSelect.options[3].text = "Year";
    }
  }

  showOrHideRecurringInfo() {
    this.setRecurring(
      this.form.current.elements.eventRecurring.options[
        this.form.current.elements.eventRecurring.selectedIndex
      ].value != 0
    );
  }

  showOrHideEventPrice() {
    this.setTicketed(this.form.current.elements.eventIsTicketed.checked);
  }

  showOrHideAdditionalInfo() {
    this.setAdditionalInfo(
      this.form.current.elements.additionalInfoRequired.checked
    );
  }

  showRepeatOptions() {
    this.setRecurringType(
      this.form.current.elements.recurringSelect.options[
        this.form.current.elements.recurringSelect.selectedIndex
      ].value
    );
  }

  makeEndsOnChecked() {
    this.form.current.elements.endsOn.checked = true;
  }

  checkForFormErrorsAdding(elm) {
    let modalText = "";
    let canSubmit = true;
    if (!elm.eventTitle.value || elm.eventTitle.value == "") {
      modalText +=
        "<p>Please make sure a <strong>title</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!elm.eventGameCategory.value || elm.eventGameCategory.value == "") {
      modalText +=
        "<p>Please make sure a <strong>game</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!elm.eventTypeCategory.value || elm.eventTypeCategory.value == "") {
      modalText +=
        "<p>Please make sure a <strong>type</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!elm.eventDate.value || elm.eventDate.value == "") {
      modalText +=
        "<p>Please make sure a <strong>date</strong> has been entered for this event</p>";
      canSubmit = false;
    } else if (elm.eventDate.value && elm.eventDate.value != "") {
      const todayDate = new Date();
      const myDate = new Date(elm.eventDate.value);
      if (myDate < todayDate) {
        // modalText += `<p>Please make sure the <strong>date</strong> is for today or in the future</p>`;
        canSubmit = true;
      }
    }
    if (!elm.eventTime.value || elm.eventTime.value == "") {
      modalText +=
        "<p>Please make sure a <strong>time</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle("Fix the following issues with your event");
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  checkForFormErrorsUpdating(elm, isRecurring) {
    let modalText = "";
    let canSubmit = true;
    if (!elm.eventTitle.value || elm.eventTitle.value == "") {
      modalText +=
        "<p>Please make sure a <strong>title</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!elm.eventGameCategory.value || elm.eventGameCategory.value == "") {
      modalText +=
        "<p>Please make sure a <strong>game</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!elm.eventTypeCategory.value || elm.eventTypeCategory.value == "") {
      modalText +=
        "<p>Please make sure a <strong>type</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!isRecurring) {
      if (!elm.eventDate.value || elm.eventDate.value == "") {
        modalText +=
          "<p>Please make sure a <strong>date</strong> has been entered for this event</p>";
        canSubmit = false;
      } else if (elm.eventDate.value && elm.eventDate.value != "") {
        const todayDate = new Date();
        const myDate = new Date(elm.eventDate.value);
        if (myDate < todayDate) {
          // modalText += `<p>Please make sure the <strong>date</strong> is for today or in the future</p>`;
          canSubmit = true;
        }
      }
    }
    if (!elm.eventTime.value || elm.eventTime.value == "") {
      modalText +=
        "<p>Please make sure a <strong>time</strong> has been entered for this event</p>";
      canSubmit = false;
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle("Fix the following issues with your event");
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  handleOnChangeMainEvent() {
    const elm = this.form.current.elements;
    if (this.props.match?.params?.eventId) {
      const { event } = this.props.store.EventsStore;
      if (event) {
        if (
          event.mainEvent &&
          elm.mainEvent[elm.mainEvent.selectedIndex].value != event.mainEvent
        ) {
          this.setRemovingParentEvent(true);
        } else if (
          this.mainEventToRemove ==
          elm.mainEvent[elm.mainEvent.selectedIndex].value
        ) {
          this.setRemovingParentEvent(false);
        }
      }
    }
  }

  save(e) {
    e.preventDefault();
    this.setHasValidationErrors(false);
    this.setUpdating(true);

    const elm = this.form.current.elements;

    const { event } = this.props.store.EventsStore;
    const formData = new FormData();
    if (elm.eventCalendarIcon.files[0]) {
      formData.append("calendarIcon", elm.eventCalendarIcon.files[0]);
    } else if (event?.calendarIcon) {
      formData.append("calendarIconUrl", event.calendarIcon);
    }
    if (elm.eventBanner.files[0]) {
      formData.append("banner", elm.eventBanner.files[0]);
    } else if (event?.banner) {
      formData.append("bannerUrl", event.banner);
    }
    if (elm.eventTicketImage.files[0]) {
      formData.append("ticketImage", elm.eventTicketImage.files[0]);
    } else if (event?.ticketImage) {
      formData.append("ticketImageUrl", event.ticketImage);
    }
    if (this.props.store.EventsStore?.event?.id) {
      const eventToUpdate = JSON.parse(
        JSON.stringify(this.props.store.EventsStore.event)
      );
      if (this.checkForFormErrorsUpdating(elm, eventToUpdate.recurring)) {
        delete eventToUpdate.date;
        eventToUpdate.title = elm.eventTitle.value;
        eventToUpdate.game = elm.eventGameCategory.value;
        eventToUpdate.type = elm.eventTypeCategory.value;
        eventToUpdate.buildingName = elm.eventBuilding.value;
        eventToUpdate.streetAddress = elm.eventStreetAddress.value;
        eventToUpdate.city = elm.eventCity.value;
        if (
          elm.mainEvent &&
          elm.mainEvent[elm.mainEvent.selectedIndex] &&
          elm.mainEvent[elm.mainEvent.selectedIndex].value
        ) {
          eventToUpdate.mainEvent =
            elm.mainEvent[elm.mainEvent.selectedIndex].value != ""
              ? elm.mainEvent[elm.mainEvent.selectedIndex].value
              : null;
        }
        eventToUpdate.oldMainEvent = this.removingParentEvent
          ? this.mainEventToRemove
          : null;
        eventToUpdate.removeMainEvent = this.removingParentEvent;
        eventToUpdate.prizePool = elm.prizePool.value;
        eventToUpdate.state = elm.eventState.value;
        eventToUpdate.zipCode = elm.eventZipCode.value;
        if (!eventToUpdate.recurring) {
          eventToUpdate.date = elm.eventDate.value;
        }
        eventToUpdate.time = elm.eventTime.value;
        eventToUpdate.description = this.state.description;
        eventToUpdate.prizeStructure = this.state.prize;
        eventToUpdate.results = this.state.results;
        eventToUpdate.isTicketed = elm.eventIsTicketed.checked;
        eventToUpdate.ticketed = elm.eventIsTicketed.checked;
        if (elm.eventIsTicketed.checked) {
          eventToUpdate.ticketPrice =
            elm.eventPrice.value && elm.eventPrice.value != ""
              ? elm.eventPrice.value
              : 0;
          eventToUpdate.maxParticipants = elm.maxParticipants.value;
          eventToUpdate.taxable = elm.eventIsTaxed.checked;
        }

        if (elm.additionalInfoRequired.checked) {
          eventToUpdate.additionalInfoRequired =
            elm.additionalInfoRequired.checked;
          eventToUpdate.additionalInfo = [];
          for (var i = 0; i < this.additionalInfoList.length; i++) {
            if (
              elm[`additionalInfoHeader-${i}`].value &&
              elm[`additionalInfoHeader-${i}`].value != ""
            ) {
              const additionalInfoObj = {
                header: elm[`additionalInfoHeader-${i}`].value,
                headerDescription: elm[`additionalInfoDescription-${i}`].value,
              };
              if (
                elm[`additionalInfoId-${i}`] &&
                elm[`additionalInfoId-${i}`].value
              ) {
                additionalInfoObj.id = elm[`additionalInfoId-${i}`].value;
              }
              eventToUpdate.additionalInfo.push(additionalInfoObj);
            }
          }
        }

        formData.append("event", JSON.stringify(eventToUpdate));

        const { eventId } = this.props.match.params;
        updateEvent(eventId, formData)
          .then(() => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle("Event updated");
            this.setModalText("Your event has successfully been updated!");
            this.props.store.EventsStore.rehydrate();
          })
          .catch((error) => {
            console.error(error);
            this.setUpdating(false);
            this.props.store.MainStore.setError(
              error,
              "Failed to save event",
              "There was an error updating the event details. Please try again"
            );
          });
      }
    } else if (this.checkForFormErrorsAdding(elm)) {
      const event = {
        game: elm.eventGameCategory.value,
        type: elm.eventTypeCategory.value,
        title: elm.eventTitle.value,
        date: elm.eventDate.value,
        time: elm.eventTime.value,
        description: this.state.description,
        prizeStructure: this.state.prize,
        prizePool: elm.prizePool.value,
        results: this.state.results,
        recurring: elm.eventRecurring.checked,
        isTicketed: elm.eventIsTicketed.checked,
        additionalInfoRequired: elm.additionalInfoRequired.checked,
        buildingName: elm.eventBuilding.value,
        streetAddress: elm.eventStreetAddress.value,
        city: elm.eventCity.value,
        state: elm.eventState.value,
        zipCode: elm.eventZipCode.value,
      };

      if (
        elm.mainEvent &&
        elm.mainEvent[elm.mainEvent.selectedIndex] &&
        elm.mainEvent[elm.mainEvent.selectedIndex].value
      ) {
        event.mainEvent =
          elm.mainEvent[elm.mainEvent.selectedIndex].value != ""
            ? elm.mainEvent[elm.mainEvent.selectedIndex].value
            : null;
      }

      if (elm.additionalInfoRequired.checked) {
        event.additionalInfo = [];
        for (i = 0; i < this.additionalInfoList.length; i++) {
          if (
            elm[`additionalInfoHeader-${i}`].value &&
            elm[`additionalInfoHeader-${i}`].value != ""
          ) {
            const additionalInfoObj = {
              header: elm[`additionalInfoHeader-${i}`].value,
              headerDescription: elm[`additionalInfoDescription-${i}`].value,
            };
            event.additionalInfo.push(additionalInfoObj);
          }
        }
      }

      if (elm.eventIsTicketed.checked) {
        event.ticketPrice =
          elm.eventPrice.value && elm.eventPrice.value != ""
            ? elm.eventPrice.value
            : 0;
        event.maxParticipants = elm.maxParticipants.value;
        event.taxable = elm.eventIsTaxed.checked;
      }

      if (elm.eventRecurring[elm.eventRecurring.selectedIndex].value == "1") {
        event.recurring = true;
        event.recurringFrequency = elm.recurringFrequency.value;
        event.recurringType =
          elm.recurringSelect[elm.recurringSelect.selectedIndex].value;
        if (event.recurringType == "week") {
          const days = [];
          this.getDaysArray().forEach((day) => {
            if (elm[`${day}Checkbox`].checked) {
              days.push(day);
            }
          });
          event.recurringDays = days.join(",");
        }
        if (event.recurringType == "month") {
          event.recurringDate =
            elm.monthlySelect[elm.monthlySelect.selectedIndex].value;
        }
        if (
          elm.endsOn.checked &&
          elm.eventEndDate.value &&
          elm.eventEndDate.value != ""
        ) {
          event.recurringEndDate = elm.eventEndDate.value;
        }
      }

      formData.append("event", JSON.stringify(event));
      addEvent(formData)
        .then(() => {
          this.setUpdating(false);
          this.setModalVisible(true);
          this.setModalTitle("Event created");
          this.setModalText("Your event has successfully been added!");
          this.props.store.EventsStore.rehydrate();
        })
        .catch((error) => {
          console.error(error);
          this.setUpdating(false);
          this.props.store.MainStore.setError(
            error,
            "Failed to save event",
            "There was an error submitting the event details. Please refresh and try again"
          );
        });
    }
  }

  render() {
    const { event, setEvent } = this.props.store.EventsStore;

    return (
      <>
        {this.props.store.AuthStore.screenSettings.events ? (
          <>
            {this.props.store.EventsStore.eventCategories &&
            ((this.props.match?.params?.eventId &&
              this.props.store.EventsStore.event?.title) ||
              !this.props.match?.params?.eventId) ? (
              <>
                <form
                  ref={this.form}
                  onSubmit={(e) => this.save(e)}
                  noValidate
                  key={this.props.store.EventsStore.event?.id}
                >
                  <div className="app-header">
                    <h2 className="title is-2">
                      <span className="header-text">
                        {this.props.store.EventsStore?.event?.id
                          ? `Update ${this.props.store.EventsStore.event.title}`
                          : "Create new event"}
                      </span>
                      {this.props.store.WindowSizeStore.windowWidth > 650 ? (
                        <>
                          <button
                            className={`btn1 ${
                              this.updating ? " is-loading" : ""
                            }`}
                            type="submit"
                          >
                            {this.props.store.EventsStore.event?.id
                              ? "Update"
                              : "Save"}
                          </button>
                          <button
                            className="btn1"
                            onClick={() => {
                              setEvent({ ...event, id: null });
                              this.setCalendarIconImage({
                                src: event.calendarIcon,
                              });
                              this.setCalendarTicketImage({
                                src: event.ticketImage,
                              });
                              this.setCalendarBannerImage({
                                src: event.banner,
                              });
                            }}
                            disabled={!event?.id}
                            type="button"
                          >
                            <i className="fa fa-clone" aria-hidden="true"></i>{" "}
                            Duplicate
                          </button>
                        </>
                      ) : (
                        <button
                          className={`btn1 mobile ${
                            this.updating ? " is-loading" : ""
                          }`}
                          type="submit"
                        >
                          <i className="far fa-save" />
                        </button>
                      )}
                    </h2>
                  </div>
                  <div
                    className={`EventListing ${
                      this.props.store.EventsStore.event ? "update" : ""
                    }`}
                  >
                    <div className="topcontent">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 col-xl-4">
                            <label className="label" htmlFor="eventTitle">
                              Event title:
                            </label>
                            <div className="control">
                              <input
                                id="eventTitle"
                                className="input"
                                type="text"
                                placeholder="E.g. Friday Commander Night"
                                required
                                defaultValue={
                                  this.props.store.EventsStore.event?.title ||
                                  ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-4">
                            <label
                              className="label"
                              htmlFor="eventGameCategory"
                            >
                              Event game:
                            </label>
                            <div className="control has-icons-left">
                              <input
                                list="eventGameCategoryDatalist"
                                id="eventGameCategory"
                                placeholder="Search game"
                                className="input"
                                type="text"
                                required
                                defaultValue={
                                  this.props.store.EventsStore.event?.game || ""
                                }
                              />
                              <datalist id="eventGameCategoryDatalist">
                                {this.props.store.EventsStore.eventGames.map(
                                  (category, i) => (
                                    <option key={i} value={category.category} />
                                  )
                                )}
                              </datalist>
                              <span className="icon is-small is-left">
                                <i className="far fa-search" />
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-4">
                            <label
                              className="label"
                              htmlFor="eventTypeCategory"
                            >
                              Event type:
                            </label>
                            <div className="control has-icons-left">
                              <input
                                list="eventTypeCategoryDatalist"
                                id="eventTypeCategory"
                                placeholder="Search event type"
                                className="input"
                                type="text"
                                required
                                defaultValue={
                                  this.props.store.EventsStore.event?.type || ""
                                }
                              />
                              <datalist id="eventTypeCategoryDatalist">
                                {this.props.store.EventsStore.eventTypes.map(
                                  (category, i) => (
                                    <option key={i} value={category.category} />
                                  )
                                )}
                              </datalist>
                              <span className="icon is-small is-left">
                                <i className="far fa-search" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventInformation">
                      <div className="container-fluid">
                        <p className="subHeading">Event information</p>
                        <div className="row">
                          <div className="col-lg-12 col-xl-4">
                            <label className="label" htmlFor="eventDate">
                              Event date:
                            </label>
                            {this.props.store.EventsStore.event?.recurring ? (
                              <p>
                                {TimeUtils.convertDateToHumanReadableNoTime(
                                  this.props.store.EventsStore.event.date
                                )}
                              </p>
                            ) : (
                              <div className="control">
                                <input
                                  id="eventDate"
                                  className="input"
                                  type="date"
                                  placeholder="E.g. 18/07/2018"
                                  required
                                  defaultValue={
                                    event?.date
                                      ? TimeUtils.dateInput(event?.date)
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-lg-12 col-xl-4">
                            <label className="label" htmlFor="eventTime">
                              Event time
                            </label>
                            <div className="control">
                              <input
                                id="eventTime"
                                className="input"
                                type="time"
                                placeholder="E.g. 10:00"
                                required
                                defaultValue={
                                  this.props.store.EventsStore.event?.time || ""
                                }
                              />
                            </div>
                          </div>
                          {!this.props.store.EventsStore.event?.id ? (
                            <>
                              <div className="col-lg-12 col-xl-4">
                                <label
                                  className="label"
                                  htmlFor="eventRecurring"
                                >
                                  Event Frequency
                                </label>
                                <div className="control">
                                  <select
                                    id="eventRecurring"
                                    onChange={this.showOrHideRecurringInfo.bind(
                                      this
                                    )}
                                  >
                                    <option value="0">One off event</option>
                                    <option value="1">Recurring</option>
                                  </select>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="eventPanel">
                      {!this.props.store.EventsStore.event?.id ? (
                        <>
                          <div
                            id="recurringSection"
                            className={`col-sm-12 recurring-section${
                              !this.isRecurring ? " hideItem" : ""
                            }`}
                          >
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-xl-2">
                                  <div className="field-label is-normal">
                                    <label
                                      className="label"
                                      htmlFor="recurringFrequency"
                                    >
                                      Repeat every
                                    </label>
                                  </div>
                                  <input
                                    id="recurringFrequency"
                                    className="input"
                                    type="number"
                                    max="52"
                                    min="1"
                                    onChange={this.changeRecurringText.bind(
                                      this
                                    )}
                                    defaultValue="1"
                                  />

                                  <select
                                    data-testid="recurring-unit"
                                    id="recurringSelect"
                                    onChange={this.showRepeatOptions.bind(this)}
                                  >
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                  </select>
                                </div>
                                <div
                                  id="weeklySelection"
                                  className={`col-lg-12 col-xl-10${
                                    this.recurringType === "week"
                                      ? ""
                                      : " hideItem"
                                  }`}
                                >
                                  <div className="container-fluid">
                                    <div className="repeatOn">
                                      <label className="label">Repeat on</label>
                                    </div>
                                    <div className="weekSwatchWrapper">
                                      {this.getDaysArray().map((day, i) => (
                                        <div className="weekSwatches" key={i}>
                                          <label className="checkbox">
                                            <input
                                              id={`${day}Checkbox`}
                                              type="checkbox"
                                            />
                                            {` ${day}`}
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="monthlySelection"
                                  className={`col-lg-12 col-xl-2${
                                    this.recurringType === "month"
                                      ? ""
                                      : " hideItem"
                                  }`}
                                >
                                  <div className="field-label is-normal">
                                    <label
                                      className="label"
                                      htmlFor="monthlySelect"
                                    >
                                      Repeat
                                    </label>
                                  </div>
                                  <div className="field-body">
                                    <div className="field">
                                      <span className="select">
                                        <select id="monthlySelect">
                                          <option value={this.getDateToday()}>
                                            {`Monthly on day ${this.getDateToday()}`}
                                          </option>
                                        </select>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-xl-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <label
                                        className="label"
                                        htmlFor="neverEnds"
                                      >
                                        Ends
                                      </label>
                                    </div>
                                    <div className="col-sm-6 col-xl-4">
                                      <label className="radio inline">
                                        <input
                                          className="inline"
                                          id="neverEnds"
                                          type="radio"
                                          name="ends"
                                          defaultChecked
                                        />
                                        {" Never"}
                                      </label>
                                    </div>
                                    <div className="col-sm-6 col-xl-8">
                                      <label className="radio inline">
                                        <input
                                          data-testid="ends-on"
                                          className="inline"
                                          id="endsOn"
                                          type="radio"
                                          name="ends"
                                        />
                                        {" On"}
                                      </label>
                                      <div className="control inline">
                                        <input
                                          id="eventEndDate"
                                          className="input"
                                          type="date"
                                          placeholder="E.g. 18/07/2018"
                                          onChange={this.makeEndsOnChecked.bind(
                                            this
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-sm-4 field">
                          <label className="label">Event frequency</label>
                          <p>
                            {generateTextForRecurring(
                              this.props.store.EventsStore.event
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="eventPanel">
                      <div className="eventInformation">
                        <SearchBarLocation
                          event={this.props.store.EventsStore.event}
                        />
                      </div>
                    </div>
                    <div className="eventPanel">
                      <div className="eventInformation">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-lg-12 col-xl-4">
                              <label className="label" htmlFor="prizePool">
                                Prizepool:
                              </label>
                              <div className="control">
                                <input
                                  id="prizePool"
                                  className="input"
                                  type="text"
                                  placeholder="$5000"
                                  required
                                  defaultValue={
                                    this.props.store.EventsStore.event
                                      ?.prizePool || ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Description</label>
                      <SunEditor
                        setContents={this.state.description}
                        onChange={(description) =>
                          this.setState({ description })
                        }
                        setOptions={{
                          height: 200,
                          buttonList: [...buttonList.formatting, ["link"]],
                        }}
                      />
                    </div>
                    <div className="field">
                      <label className="label">Prize structure</label>
                      <SunEditor
                        setContents={this.state.prize}
                        onChange={(prize) => this.setState({ prize })}
                        setOptions={{
                          height: 200,
                          buttonList: [...buttonList.formatting, ["link"]],
                        }}
                      />
                    </div>
                    <div className="field">
                      <label className="label">Event results</label>
                      <SunEditor
                        setContents={this.state.results}
                        onChange={(results) => this.setState({ results })}
                        setOptions={{
                          height: 200,
                          buttonList: [...buttonList.formatting, ["link"]],
                        }}
                      />
                    </div>
                    <div className="eventPanel">
                      <div id="eventImages">
                        <div className="container-fluid">
                          <p className="subHeading">Event images</p>
                          <div className="row">
                            <div className="col-md-12 col-lg-6 col-xl-4">
                              <label
                                className="label"
                                htmlFor="eventCalendarIcon"
                              >
                                Event calendar icon:
                              </label>
                              <div className="uploadSection">
                                <div className="imageWrapper">
                                  <img
                                    id="calendarIconImage"
                                    className="eventImage"
                                    src={
                                      this.calendarIconImage
                                        ? this.calendarIconImage.src
                                        : "https://storage.googleapis.com/binderpos-event-images/binder-event-icon.png"
                                    }
                                  />
                                </div>
                                <div className="control">
                                  <label className="custom-file-upload">
                                    <input
                                      data-testid="event-calendar-icon"
                                      id="eventCalendarIcon"
                                      className="input"
                                      type="file"
                                      onChange={this.showCalendarIconImage.bind(
                                        this
                                      )}
                                    />
                                    {this.props.store.EventsStore.event &&
                                    this.props.store.EventsStore.event
                                      .calendarIcon ? (
                                      <div>
                                        <i className="fal fa-upload" /> Change{" "}
                                      </div>
                                    ) : (
                                      <div>
                                        <i className="fal fa-upload" /> Upload{" "}
                                      </div>
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-6 col-xl-4">
                              <label className="label" htmlFor="eventBanner">
                                Event feature image:
                              </label>
                              <div className="uploadSection">
                                <div
                                  className={
                                    this.calendarBannerImage
                                      ? "imageWrapper"
                                      : "imageWrapper background"
                                  }
                                >
                                  <img
                                    id="calendarBannerImage"
                                    className="eventBanner"
                                    src={
                                      this.calendarBannerImage
                                        ? this.calendarBannerImage.src
                                        : UploadImage
                                    }
                                  />
                                </div>
                                <div className="control">
                                  <label className="custom-file-upload">
                                    <input
                                      data-testid="event-banner"
                                      id="eventBanner"
                                      className="input"
                                      type="file"
                                      onChange={this.showCalendarBannerImage.bind(
                                        this
                                      )}
                                    />
                                    {this.props.store.EventsStore.event &&
                                    this.props.store.EventsStore.event
                                      .banner ? (
                                      <div>
                                        <i className="fal fa-upload" /> Change{" "}
                                      </div>
                                    ) : (
                                      <div>
                                        <i className="fal fa-upload" /> Upload{" "}
                                      </div>
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-4">
                              <label
                                className="label"
                                htmlFor="eventTicketImage"
                              >
                                Event ticket image
                              </label>
                              <div className="uploadSection">
                                <div className="imageWrapper">
                                  <img
                                    id="calendarTicketImage"
                                    className="eventImageTickets"
                                    src={
                                      this.calendarTicketImage
                                        ? this.calendarTicketImage.src
                                        : "https://storage.googleapis.com/binderpos-event-images/binderpos-ticket.png"
                                    }
                                  />
                                </div>
                                <div className="control">
                                  <label className="custom-file-upload">
                                    <input
                                      data-testid="event-ticket-image"
                                      id="eventTicketImage"
                                      className="input"
                                      type="file"
                                      onChange={this.showCalendarTicketImage.bind(
                                        this
                                      )}
                                    />
                                    {this.props.store.EventsStore.event &&
                                    this.props.store.EventsStore.event
                                      .ticketImage ? (
                                      <div>
                                        <i className="fal fa-upload" /> Change{" "}
                                      </div>
                                    ) : (
                                      <div>
                                        <i className="fal fa-upload" /> Upload{" "}
                                      </div>
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventPanel">
                      <div id="ticketInfo">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12">
                              <label className="checkbox">
                                <input
                                  id="eventIsTicketed"
                                  type="checkbox"
                                  onChange={this.showOrHideEventPrice.bind(
                                    this
                                  )}
                                  defaultChecked={
                                    this.props.store.EventsStore.event
                                      ?.isTicketed
                                  }
                                />
                                <span className="checkmark" />
                                &nbsp; Does this event require a ticket?
                              </label>
                            </div>
                            <div
                              className={`col-lg-12 col-xl-4${
                                this.ticketed ? "" : " hideItem"
                              }`}
                              id="eventPriceDiv"
                            >
                              <label className="label" htmlFor="eventPrice">
                                Price of ticket:
                              </label>
                              <p className="textNorm">
                                Entering 0 will mean the ticket is free
                              </p>
                              <div className="control has-icons-left">
                                <input
                                  id="eventPrice"
                                  className="input"
                                  type="number"
                                  min="1"
                                  placeholder="E.g. 29.95"
                                  step=".01"
                                  defaultValue={
                                    this.props.store.EventsStore.event
                                      ?.ticketPrice || ""
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={`col-lg-12 col-xl-4${
                                this.ticketed ? "" : " hideItem"
                              }`}
                            >
                              <label
                                className="label"
                                htmlFor="maxParticipants"
                              >
                                Maximum participants (Max tickets):
                              </label>
                              <p className="textNorm">
                                Entering 0 indicates that there are unlimited
                                tickets available
                              </p>
                              <div className="control">
                                <input
                                  id="maxParticipants"
                                  className="input"
                                  type="number"
                                  min="0"
                                  placeholder="E.g. 50"
                                  defaultValue={
                                    this.props.store.EventsStore.event
                                      ?.maxParticipants || 0
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={`col-lg-12 col-xl-4${
                                this.ticketed ? "" : " hideItem"
                              }`}
                            >
                              <label className="checkbox">
                                <input
                                  id="eventIsTaxed"
                                  type="checkbox"
                                  defaultChecked={
                                    this.props.store.EventsStore.event
                                      ?.taxable || false
                                  }
                                />
                                <span className="checkmark" />
                                &nbsp; Charge tax on event ticket?
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`eventPanel${
                        this.ticketed ? "" : " hideItem"
                      }`}
                    >
                      <div id="additionalInfo">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="checkbox">
                                <input
                                  id="additionalInfoRequired"
                                  type="checkbox"
                                  onChange={this.showOrHideAdditionalInfo.bind(
                                    this
                                  )}
                                  defaultChecked={this.additionalInfo}
                                  disabled={
                                    this.props.store.EventsStore.event
                                      ?.additionalInfoRequired
                                  }
                                />
                                <span className="checkmark" />
                                &nbsp; Does this event require additional
                                information? <em>(For example DCI number)</em>
                              </label>
                            </div>
                          </div>
                          <div
                            id="additionalInfoSection"
                            className={`additional-info-section${
                              !this.additionalInfo ? " hideItem" : ""
                            }`}
                          >
                            <div className="row">
                              {this.additionalInfoList.map(
                                (additionalInfo, i) => (
                                  <div
                                    className="col-lg-12 col-xl-4"
                                    key={additionalInfo.timestamp}
                                  >
                                    <div className="card-content">
                                      {!additionalInfo.id ? (
                                        <button
                                          title="Remove additional info"
                                          className="deleteInfo"
                                          type="button"
                                          onClick={this.removeAdditionalInfoFromList.bind(
                                            this,
                                            i
                                          )}
                                        >
                                          <i className="fal fa-trash-alt" />
                                        </button>
                                      ) : (
                                        <input
                                          id={`additionalInfoId-${i}`}
                                          type="hidden"
                                          value={additionalInfo.id}
                                        />
                                      )}
                                      <div className="field">
                                        <label
                                          className="label"
                                          htmlFor={`additionalInfoHeader-${i}`}
                                        >
                                          Additional Info header
                                        </label>
                                        <p>
                                          This is the text that will be
                                          displayed for the customer. E.g
                                          {"'"}Please enter your DCI number{"'"}
                                        </p>
                                        <div className="control">
                                          <input
                                            id={`additionalInfoHeader-${i}`}
                                            className="input"
                                            type="text"
                                            defaultValue={additionalInfo.header}
                                          />
                                        </div>
                                      </div>
                                      <div className="field">
                                        <label
                                          className="label"
                                          htmlFor={`additionalInfoDescription-${i}`}
                                        >
                                          Additional Info description
                                        </label>
                                        <p>
                                          This is the helper text if needed that
                                          will be displayed for the customer.
                                          E.g {"'"}You can find this number by
                                          contacting the store owner or looking
                                          on your register card{"'"}
                                        </p>
                                        <div className="control">
                                          <textarea
                                            id={`additionalInfoDescription-${i}`}
                                            className="textarea"
                                            defaultValue={
                                              additionalInfo.headerDescription
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="col-lg-12 col-xl-4">
                                <div
                                  className="card-content addNew"
                                  onClick={this.addAdditionalInfo.bind(this)}
                                >
                                  <span>
                                    <i className="far fa-plus-square" />
                                    Add Info
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.updating ? (
                      <Loader
                        text={
                          this.props.store.EventsStore.event?.id
                            ? "Updating your event..."
                            : "Adding your new event..."
                        }
                      />
                    ) : null}
                    <button
                      className={`EventBtn ${
                        this.updating ? " is-loading" : ""
                      }`}
                    >
                      {this.props.store.EventsStore.event?.id
                        ? "Save and update event"
                        : "Save and publish event"}{" "}
                      <i className="fas fa-caret-right" />
                    </button>

                    {this.modalVisible ? (
                      <div className="modal is-active eventModal">
                        <div className="modal-background" />
                        <div className="modal-card">
                          <header className="modal-card-head">
                            <p className="modal-card-title">
                              {this.modalTitle}
                            </p>
                          </header>
                          <section
                            className="modal-card-body"
                            dangerouslySetInnerHTML={{ __html: this.modalText }}
                          />
                          <footer className="modal-card-foot">
                            {this.hasValidationErrors ? (
                              <button
                                className="modalBtn action full"
                                onClick={() => this.setModalVisible(false)}
                              >
                                Ok
                              </button>
                            ) : (
                              <>
                                {this.props.store.EventsStore.event
                                  ?.parentEventId ? (
                                  <Link
                                    to={`/events/view/${this.props.store.EventsStore.event.parentEventId}`}
                                    className="modalBtn action full"
                                  >
                                    Ok
                                  </Link>
                                ) : (
                                  <Link
                                    to="/events"
                                    className="modalBtn action full"
                                  >
                                    Ok
                                  </Link>
                                )}
                              </>
                            )}
                          </footer>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

Event.propTypes = {
  store: PropTypes.object,
  match: PropTypes.object,
  EventsStore: PropTypes.object,
};

export default Event;

import React from "react";
import Spinner from "../generic/Spinner";

interface DeleteSettingModalProps {
  id: number;
  visible?: boolean;
  loading?: boolean;
  handleClose: Function;
  deleteSetting: Function;
}

function DeleteSettingModal(props: DeleteSettingModalProps) {
  const { id, visible, loading, handleClose, deleteSetting } = props;

  if (!visible) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => handleClose()}
          >
            <i className="fal fa-times" /> Close
          </button>
        </header>
        <Spinner isLoading={loading}>
          <section className="modal-card-body">
            <p>
              If you proceed with deleting this setting, you will not be able to
              restore it.
            </p>
            <br />
            <p>
              Are you sure you want to delete this integration sync setting?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button className="modalBtn cancel" onClick={() => handleClose()}>
              <i className="fal fa-times"></i> Cancel
            </button>
            <button
              className={`modalBtn action ${
                loading ? "is-loading disabled" : ""
              }`}
              onClick={() => deleteSetting(id)}
            >
              Delete <i className="fas fa-caret-right" />
            </button>
          </footer>
        </Spinner>
      </div>
    </div>
  );
}

DeleteSettingModal.defaultProps = {
  visible: false,
  boolean: false,
};

export default DeleteSettingModal;

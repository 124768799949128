import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AdvancedSearchParameters,
  createVariantSyncSettings,
  fetchAdvancedSearchResults,
  fetchCardGames,
  fetchCardSetNames,
  fetchColors,
  fetchMonsterTypes,
  fetchProductTypes,
  fetchRarities,
  fetchTags,
  fetchTypes,
  fetchVariants,
  fetchVendors,
  Game,
  removeVariantSyncSettings,
  updateVariantSyncSettings,
} from "../../api/rest/integrations";
import Loader from "../../components/generic/Loader";
import Spinner from "../../components/generic/Spinner";
import AdvancedSearchParametersForm, {
  initialParameters,
} from "../../components/integrations/AdvancedSearchParametersForm";
import EbayResyncInventoryButton from "../../components/integrations/EbayResyncInventoryButton";
import ForceReauthoriseButton from "../../components/integrations/ForceReauthoriseButton";
import Product from "../../components/integrations/Product";
import ProductPagination from "../../components/integrations/ProductPagination";
import TabNavigation from "../../components/integrations/TabNavigation";
import VariantSettingsForm from "../../components/integrations/VariantSettingsForm";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { notificationParameters } from "../../constants/notifications";
import IntegrationsSideMenu from "../../menuStructures/IntegrationsSideMenu";
import { useIntegrationFriendlyName } from "../../hooks/integrationHooks";
const ADVANCED_SEARCH_RESULSTS_LIMIT = 25;

const getOptionLoader =
  (loader: Function, setter: Function, errorHandler: Function) =>
  (game?: string) =>
    loader(game)
      .then((data: unknown) => setter(data))
      .catch((error: Error) => errorHandler(String(error)));

function AdvancedSettings() {
  const { integration } = useParams<{ integration: string }>();
  const syncFriendlyName = useIntegrationFriendlyName(integration);
  const scrollToRef = useRef<HTMLDivElement>();
  const [resultsCount, setResultsCount] = useState<number>();
  const [results, setResults] = useState([]);
  const [offset, setOffset] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  // TODO:: Replace all this with a reducer
  const [games, setGames] = useState<Game[]>([]);
  const [setNames, setSetNames] = useState<string[]>([]);
  const [rarities, setRarities] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [monsterTypes, setMonsterTypes] = useState<string[]>([]);
  const [variants, setVariants] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [vendors, setVendors] = useState<string[]>([]);
  const [advancedSearchParameters, setAdvancedSearchParameters] =
    useState<AdvancedSearchParameters>(initialParameters);
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadGames = getOptionLoader(fetchCardGames, setGames, setErrorMessage);
  const loadSets = getOptionLoader(
    fetchCardSetNames,
    setSetNames,
    setErrorMessage
  );
  const loadRarities = getOptionLoader(
    fetchRarities,
    setRarities,
    setErrorMessage
  );
  const loadTypes = getOptionLoader(fetchTypes, setTypes, setErrorMessage);
  const loadColors = getOptionLoader(fetchColors, setColors, setErrorMessage);
  const loadMonsterTypes = getOptionLoader(
    fetchMonsterTypes,
    setMonsterTypes,
    setErrorMessage
  );
  const loadVariants = getOptionLoader(
    fetchVariants,
    setVariants,
    setErrorMessage
  );
  const loadProductTypes = getOptionLoader(
    fetchProductTypes,
    setProductTypes,
    setErrorMessage
  );
  const loadTags = getOptionLoader(fetchTags, setTags, setErrorMessage);
  const loadVendors = getOptionLoader(
    fetchVendors,
    setVendors,
    setErrorMessage
  );

  const loadGenericOptions = () => {
    setIsLoading(true);
    const loaders = [
      loadProductTypes(),
      loadTags(),
      loadVendors(),
      loadGames(),
    ];
    Promise.all(loaders).finally(() => setIsLoading(false));
  };

  const handleGameChange = (game: string) => {
    setIsLoadingOptions(true);
    const loaders = [
      loadRarities(game),
      loadTypes(game),
      loadColors(game),
      loadMonsterTypes(game),
      loadSets(game),
      loadVariants(game),
    ];
    Promise.all(loaders).finally(() => setIsLoadingOptions(false));
  };

  const loadAdvancedSearchResults = (
    parameters: AdvancedSearchParameters,
    setSubmitting?: Function,
    newOffset?: number
  ) => {
    if (offset === undefined && newOffset === undefined) return;
    if (setSubmitting) setSubmitting(true);
    setIsLoadingResults(true);

    fetchAdvancedSearchResults(
      ADVANCED_SEARCH_RESULSTS_LIMIT,
      newOffset,
      parameters
    )
      .then((response) => {
        const { count, products } = response;
        setResults(products);
        setResultsCount(count);
        setOffset(newOffset);
      })
      .catch((error: Error) => setErrorMessage(String(error)))
      .finally(() => {
        if (setSubmitting) {
          setSubmitting(false);
        }
        setIsLoadingResults(false);
      });
  };

  const updateSearchParameters = (
    parameters: AdvancedSearchParameters,
    setSubmitting: Function
  ) => {
    setAdvancedSearchParameters(parameters);
    loadAdvancedSearchResults(parameters, setSubmitting, 0);
  };

  const saveVariantSettings = (
    variantSyncSettings: { id: unknown },
    setSubmitting: Function
  ) => {
    setSubmitting(true);
    const upsert = variantSyncSettings.id
      ? updateVariantSyncSettings
      : createVariantSyncSettings;
    upsert(variantSyncSettings)
      .then(() => {
        toast.info("Settings saved", notificationParameters);
      })
      .catch((error: Error) => {
        console.error(error);
        toast.error("Failed to save settings", notificationParameters);
      })
      .finally(() => setSubmitting(false));
  };

  const resetVariant = (
    variantId: number,
    setSubmitting: Function,
    handleReset: Function
  ) => {
    setSubmitting(true);
    removeVariantSyncSettings(integration, variantId)
      .then(() => {
        handleReset();
        toast.info("Reset successful", notificationParameters);
      })
      .catch((error: Error) => {
        console.error(error);
        toast.error("Failed to save settings", notificationParameters);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    document.title = "Integration Sync | BinderPOS";
    loadGenericOptions();
  }, []);

  const handleOffsetChange = (newOffset: number) => {
    setOffset(newOffset);
    if (!isLoading) {
      loadAdvancedSearchResults(advancedSearchParameters, undefined, newOffset);
      scrollToRef?.current?.scrollIntoView();
    }
  };

  if (errorMessage) {
    return (
      <div className="error">
        An error occurred. Please refresh and try again
      </div>
    );
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title={`${syncFriendlyName} Integration Settings`}>
        <>
          <EbayResyncInventoryButton integration={integration} />
          <ForceReauthoriseButton integration={integration} />
        </>
      </SectionHeaderLayout>
      <div className="integration-advanced-settings" id="inventoryManagement">
        <TabNavigation integrationId={integration} />
        <AdvancedSearchParametersForm
          isLoading={isLoadingOptions}
          games={games}
          setNames={setNames}
          rarities={rarities}
          types={types}
          colors={colors}
          monsterTypes={monsterTypes}
          variants={variants}
          productTypes={productTypes}
          tags={tags}
          vendors={vendors}
          updateSearchParameters={updateSearchParameters}
          handleGameChange={handleGameChange}
          catalogOnly={integration == "cfbmarket"}
        />
      </div>
      {resultsCount !== undefined ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="searchCount">
                  {resultsCount > 0
                    ? `Showing ${offset + 1} to ${Math.min(
                        offset + ADVANCED_SEARCH_RESULSTS_LIMIT,
                        resultsCount
                      )} of ${resultsCount} products`
                    : "No results"}
                  <br />
                </div>
              </div>
              <div className="col-md-6">
                <div className="container-fluid">
                  <div className="row">
                    <ProductPagination
                      resultsCount={resultsCount}
                      limit={ADVANCED_SEARCH_RESULSTS_LIMIT}
                      offset={offset}
                      setOffset={handleOffsetChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid integration-advanced-results-panel"
            ref={scrollToRef}
          >
            <Spinner isLoading={isLoadingResults}>
              {results.map((product, index) => {
                return (
                  <Product
                    key={index}
                    listIndex={index}
                    product={product}
                    syncName={integration}
                  >
                    {product.variants.map((variant) => (
                      <VariantSettingsForm
                        key={variant.id}
                        syncName={integration}
                        variant={variant}
                        saveVariant={saveVariantSettings}
                        resetVariant={resetVariant}
                      />
                    ))}
                  </Product>
                );
              })}
            </Spinner>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-6">&nbsp;</div>
                <ProductPagination
                  resultsCount={resultsCount}
                  limit={ADVANCED_SEARCH_RESULSTS_LIMIT}
                  offset={offset}
                  setOffset={handleOffsetChange}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
  );
}

export default AdvancedSettings;

import React from "react";
import { signOut } from "../../services/firebase";
import "./Logout.scss";

const Logout = ({ onLogout }: { onLogout: () => void }): JSX.Element => (
  <a
    title="Log out"
    className="Logout"
    onClick={(): void => {
      signOut();
      onLogout();
    }}
  >
    <i className="fad fa-sign-out-alt"></i>
  </a>
);

export default Logout;

import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import { Spinner } from "../../components";
import { fetchCardBuylistDetails } from "../../api/rest/buylist";
import { buildSelectOptionsList } from "../../helpers/inputHelpers";

@inject("store")
@observer
class SearchModal extends Component {
  cardSearch = createRef();
  gameSelect = createRef();

  @observable isLoading = false;
  @action setIsLoading(bool) {
    this.isLoading = bool;
  }

  @observable selectedGame;
  @action setSelectedGame = (selectedGame) =>
    (this.selectedGame = selectedGame);

  @observable selectedSet;
  @action setSelectedSet = (selectedSet) => (this.selectedSet = selectedSet);

  componentDidMount() {
    if (this.props.store.CardStore.games.length > 0) {
      this.setSelectedGame(this.props.store.CardStore.games[0]);
      this.props.store.CardStore.getCardSets(
        this.props.store.CardStore.games[0]?.gameId
      );
    }
  }

  @action
  handleGameSelect = (selectedGame) => {
    const { gameId } = selectedGame;
    if (gameId) {
      this.props.store.CardStore.getCardSets(gameId);
    }
    this.setSelectedGame(selectedGame);
    this.setSelectedSet("");
  };

  @action
  handleSelectedSetsChange = (option) => (this.selectedSet = option);

  handleEnterSearch = (event) => {
    if (event.key === "Enter") {
      this.searchForBuylistCards();
    }
  };

  @observable returnedBuylistCards;
  @action setReturnedBuylistCards(returnedBuylistCards) {
    this.returnedBuylistCards = returnedBuylistCards;
  }

  searchForBuylistCards = () => {
    this.setIsLoading(true);
    fetchCardBuylistDetails(
      this.selectedGame.gameId,
      this.selectedSet?.value ?? "",
      this.cardSearch.current.value
    ).then((results) => {
      const resultsWithConditions = [];
      results.forEach((result) => {
        result.cardTypes.forEach((cardType) => {
          const obj = {
            id: Math.floor(Math.random() * 10000000000000000 + 1),
            cardName: result.cardName,
            setName: result.setName,
            cardId: result.id,
            game: result.game,
            gameId: result.gameId,
            imageUrl: result.imageUrl,
            variants: result.variants,
            type: cardType,
            shopifyCustomerBuylistId:
              this.props.store.BuylistRulesStore.buylistDetails.id,
          };
          resultsWithConditions.push(obj);
        });
      });
      this.setReturnedBuylistCards(resultsWithConditions);
      this.setIsLoading(false);
    });
  };

  render() {
    const { setVisible, addCardToList } = this.props;
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <Spinner isLoading={this.isLoading}>
            <header className="modal-card-head">
              <p className="modal-card-title">
                Search for card to add to the list
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setVisible(false)}
              >
                <i className="fal fa-times" /> Close
              </button>
            </header>
            <section className="modal-card-body">
              <div className="wrapper reviewInputs">
                <div className="field">
                  <label className="label">
                    What game are you searching for?
                  </label>
                  <div className="control has-icons-left">
                    <div className="select">
                      <Select
                        onChange={this.handleGameSelect}
                        options={this.props.store.CardStore.games}
                        getOptionLabel={(option) => option.gameName}
                        getOptionValue={(option) => option.gameId}
                        value={this.selectedGame}
                        menuPortalTarget={document.querySelector(".modal")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="field">
                      <label className="label">Filter cards by set:</label>
                      <div className="control has-icons-left">
                        <Select
                          isClearable={true}
                          onChange={this.handleSelectedSetsChange}
                          options={buildSelectOptionsList(
                            this.props.store.CardStore.sets
                          )}
                          value={this.selectedSet}
                          placeholder="All sets"
                          menuPortalTarget={document.querySelector(".modal")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Card name</label>
                  <div className="control">
                    <input
                      ref={this.cardSearch}
                      id="cardSearch"
                      className="input"
                      type="text"
                      required
                      placeholder="E.g. Fatal Push"
                      onKeyPress={this.handleEnterSearch}
                    />
                  </div>
                </div>
                {this.returnedBuylistCards ? (
                  <React.Fragment>
                    <hr />
                    <table className="table is-fullwidth">
                      <thead>
                        <tr>
                          <td className="buylistCardImg"></td>
                          <td className="buylistCardDetails">Card details</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.returnedBuylistCards.map((buylistCard, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <img
                                  src={buylistCard.imageUrl}
                                  style={{ marginLeft: "19px", width: "35px" }}
                                />
                              </td>
                              <td>
                                <span>
                                  {buylistCard.cardName} - {buylistCard.type}
                                </span>
                                <span>{buylistCard.setName}</span>
                              </td>
                              <td>
                                <button
                                  className="btn1"
                                  type="button"
                                  onClick={() => addCardToList(buylistCard)}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </React.Fragment>
                ) : null}
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                type="button"
                className="modalBtn cancel"
                onClick={() => setVisible(false)}
              >
                Close
              </button>
              <button
                type="button"
                className={`modalBtn action ${
                  this.isLoading ? " is-loading" : ""
                }`}
                onClick={this.searchForBuylistCards}
              >
                Search
              </button>
            </footer>
          </Spinner>
        </div>
      </div>
    );
  }
}

SearchModal.propTypes = {
  setVisible: PropTypes.func.isRequired,
  addCardToList: PropTypes.func.isRequired,
};

export default SearchModal;

import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { parseSetting } from "../../helpers/settingHelpers";
import Loader from "../../components/generic/Loader";
import {
  fetchCustomerSetting,
  fetchSupportedProducts,
  updateCustomerSetting,
} from "../../api/rest/settings";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import { useShowError } from "../../hooks/errorHooks";
import { optOutFields } from "../../constants/settings";
import "./CatalogUpdates.scss";

const ALL_GAMES = "allGames";

function CatalogUpdates() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [products, setProducts] = useState<Game[]>([]);
  const [selectedProduct, setSelectedProduct] = useState(ALL_GAMES);
  const [settingData, setSettingData] = useState<OptOutSetting[]>([]);

  const showError = useShowError();

  const getSupportedProducts = () =>
    fetchSupportedProducts()
      .then((data) => {
        // Remove any games without names
        const filteredProducts = data.filter(
          (product) => product.gameName && product.gameName.length > 0
        );
        setProducts(filteredProducts);
      })
      .catch(showError);

  const getOptOutSettings = () =>
    fetchCustomerSetting("updateOptOut")
      .then((setting) => setSettingData(parseSetting(setting)))
      .catch(showError);

  const gameCodeToProductName = (gameCode: string) =>
    products.find((product) => product.gameId === gameCode)?.gameName ||
    gameCode;

  const getGamesForAttribute = (attribute: string) => {
    if (!settingData) return null;
    const allGamesData = settingData.find(
      (setting) => setting.gameCode === ALL_GAMES
    );
    if (allGamesData) {
      if (allGamesData.skip && allGamesData.skip.includes(attribute)) {
        return null;
      }
    }

    const productList = settingData
      .filter((setting) => setting.gameCode !== ALL_GAMES)
      .filter((setting) => setting.skip && setting.skip.includes(attribute))
      .map((setting) => gameCodeToProductName(setting.gameCode));
    if (productList.length < 1) {
      return null;
    }
    return productList.join(", ");
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: string,
    attribute: string
  ) => {
    const updateSetting =
      settingData?.find((setting) => setting.gameCode === product) ||
      ({
        gameCode: product,
      } as OptOutSetting);
    const otherSettings = settingData?.filter(
      (setting) => setting.gameCode !== product
    );

    const { checked } = event.target;
    if (checked) {
      if (!updateSetting.skip) {
        updateSetting.skip = [];
      }
      updateSetting.skip.push(attribute);
    } else {
      if (updateSetting.skip) {
        updateSetting.skip = updateSetting.skip.filter(
          (existingAttribute) => existingAttribute !== attribute
        );
      }
    }
    if (otherSettings) {
      setSettingData([updateSetting, ...otherSettings]);
    } else {
      setSettingData([updateSetting]);
    }
  };

  const handleSave = () => {
    setIsSaving(true);
    const outOutOfCatalogUpdateSettings = {
      settingName: "updateOptOut",
      settingValue: JSON.stringify(settingData),
    };
    updateCustomerSetting(outOutOfCatalogUpdateSettings)
      .then(() => {
        toast.info("Catalog opt-out settings updated");
      })
      .catch(showError)
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    Promise.all([getSupportedProducts(), getOptOutSettings()]).then(() =>
      setIsLoading(false)
    );
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const activeProduct =
    settingData &&
    settingData.find((setting) => setting.gameCode === selectedProduct);

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Catalog Update Settings" />
      <div className="CatalogUpdates__descriptionText">
        <p>
          If you have made changes to certain products after the initial product
          has been pushed then these changes will be overwritten by future
          updates.
        </p>
        <p>
          To prevent yor changes from being overwritten, select the fields that
          you have made updates to below. For example product images, SKUs or
          barcodes.
        </p>
        <p>
          These fields can be set on a per-product basis, or for all products
          (including future products).
        </p>
      </div>
      <form>
        <label>
          Apply to:
          <select
            className="CatalogUpdates__selectProduct"
            onChange={(event) => setSelectedProduct(event.target.value)}
          >
            <option key={ALL_GAMES} value={ALL_GAMES}>
              All products (including future products)
            </option>
            {products.map((product) => (
              <option key={product.gameId} value={product.gameId}>
                {product.gameName}
              </option>
            ))}
          </select>
        </label>
        <div key={selectedProduct} className="CatalogUpdates__attributeTable">
          <div className="CatalogUpdates__attributeTableHeader">
            <div className="CatalogUpdates__attributeLabel">Attribute</div>
            <div className="CatalogUpdates__attributeCheckbox">Opt-out</div>
          </div>
          {optOutFields.map((field) => (
            <div
              key={field.value}
              className="CatalogUpdates__attributeTableRow"
            >
              <div className="CatalogUpdates__attributeLabel">
                <label htmlFor={field.value}>{field.label}</label>
              </div>
              <div className="CatalogUpdates__attributeCheckbox">
                <input
                  type="checkbox"
                  id={field.value}
                  checked={
                    (activeProduct?.skip &&
                      activeProduct.skip.includes(field.value)) ||
                    false
                  }
                  onChange={(event) =>
                    handleChange(event, selectedProduct, field.value)
                  }
                  ref={(elem) => {
                    if (
                      elem &&
                      selectedProduct === ALL_GAMES &&
                      getGamesForAttribute(field.value) !== null
                    ) {
                      elem.indeterminate = true;
                    }
                  }}
                />
              </div>
              <div className="CatalogUpdates__attributeOtherGames">
                {selectedProduct === ALL_GAMES
                  ? getGamesForAttribute(field.value)
                  : null}
              </div>
            </div>
          ))}
        </div>
        <div className="CatalogUpdates__saveButton">
          <ButtonComponent disabled={isSaving} onClick={() => handleSave()}>
            Save
          </ButtonComponent>
        </div>
      </form>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
  );
}

export default CatalogUpdates;

import { useEffect } from "react";
import { FRESH_CHAT_TOKEN, FRESH_CHAT_URL } from "../../constants/services";
import { FreshChatConfig, HeaderProperty } from "./FreshChatType";
import { useLocation } from "react-router-dom";
import { useFeature } from "../../hooks/globalSettingsHooks";
import { getCurrentUser } from "../../helpers/authHelpers";

const scriptSrc = (config: FreshChatConfig, customUserData: any = {}) => `
function initFreshChat() {
  window.fcWidget.init(${JSON.stringify(config)});
  window.fcWidget.on("widget:loaded", function(resp) {
    console.log('Widget Loaded');
    window.fcWidget.user.setProperties(${JSON.stringify(customUserData)});
  });
}

function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);

initialize(document, "freshchat-js-sdk");
`;

const loadScript = (
  user: CurrentUser,
  binderUser: any,
  direction: HeaderProperty
) => {
  const scriptTag = document.createElement("script");
  let defaultName: string | null = user?.displayName;
  if (!defaultName?.trim()) {
    defaultName = user?.email;
  }
  if (defaultName) {
    defaultName = ` - ${defaultName}`;
  }

  scriptTag.innerHTML = scriptSrc(
    {
      externalId: user?.uid,
      token: FRESH_CHAT_TOKEN,
      host: FRESH_CHAT_URL,
      email: user?.email,
      firstName: binderUser?.name,
      lastName: defaultName,
      config: { headerProperty: direction },
    },
    {
      storeName: binderUser?.name,
      siteUrl: binderUser?.siteUrl,
      binderId: binderUser?.id,
    }
  );
  scriptTag.type = "text/javascript";
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
};

function FreshChat(props) {
  const freshChatEnabled = useFeature("freshChat");
  const currentUser = getCurrentUser();
  const { storeSettings } = props;
  const { pathname } = useLocation();
  const direction = { direction: pathname.match(/\pos$/) ? "ltr" : "rtl" };

  useEffect(() => {
    if (freshChatEnabled) loadScript(currentUser, storeSettings, direction);
  }, [freshChatEnabled]);

  return null;
}

export default FreshChat;

import React, { useEffect, useState } from "react";
import ProductLineVariantHeader from "./ProductLineVariantHeader";
import ProductLineVariantItem from "./ProductLineVariantItem";

const getProductTitle = (value: string) => value.split("[")[0];

const getProductSet = (value: string) => {
  const split = value.split("[");
  if (split && split.length > 1) {
    return split[1].replace("]", "");
  }
  return "";
};

interface ProductLineItemProps {
  setUpdateProduct: (func: Function) => void;
  product: Product;
  listIndex: number;
  columnsToShow: string[];
  currentInput: () => number;
  updateCurrentInput: (value: number) => void;
  getStockUpdateType: () => SelectOptionBase;
  onChange: (newVariant: Variant, oldVariant: Variant) => void;
  onComplete: (id: string) => void;
  currency: string;
  collapseVariants: boolean;
}

function ProductLineItem(props: ProductLineItemProps) {
  const {
    setUpdateProduct,
    product,
    listIndex,
    columnsToShow,
    currentInput,
    updateCurrentInput,
    getStockUpdateType,
    onChange,
    onComplete,
    currency,
    collapseVariants,
  } = props;
  const [expandProduct, setExpandProduct] = useState(!collapseVariants);
  const variantLineItemsToProcess: Function[] = [];

  useEffect(() => {
    setUpdateProduct(() => process(variantLineItemsToProcess));
  }, []);

  useEffect(() => {
    setExpandProduct(!collapseVariants);
  }, [collapseVariants]);

  const getCurrentInput = () => currentInput();

  const process = (processFunctions: Function[]) => {
    processFunctions.forEach((functionToProcess) => {
      functionToProcess();
    });
  };

  return (
    <div key={listIndex} className="container-fluid">
      <div className="row">
        <div className="lineItem">
          <div className="productHeader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="imgWrapper">
                    <img
                      src={
                        product.tcgImage && product.tcgImage.length > 0
                          ? product.tcgImage
                          : product.img
                      }
                    />
                  </div>
                  <div className="productTitle">
                    {getProductTitle(product.title)}
                    <span>{getProductSet(product.title)}</span>
                    {product.collectorNumber &&
                    String(product.collectorNumber).length > 0 ? (
                      <span># {product.collectorNumber}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="productData">
                    Total Stock: {product.overallQuantity}
                  </div>
                  <button
                    className="toggleVariantsButton"
                    onClick={() => setExpandProduct(!expandProduct)}
                  >
                    {expandProduct ? "Hide variants" : "Show variants"}{" "}
                    <i
                      className={`fas fa-angle-double-${
                        expandProduct ? "up" : "down"
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {expandProduct ? (
            <div className="variantsInfo">
              <ProductLineVariantHeader
                columnsToShow={columnsToShow}
                currency={currency}
              />
              {product.variants.map((variant, i) => {
                return (
                  <ProductLineVariantItem
                    setUpdateVariant={(theFunction: Function) =>
                      variantLineItemsToProcess.push(theFunction)
                    }
                    key={i}
                    variant={variant}
                    parentListIndex={listIndex}
                    listIndex={i}
                    columnsToShow={columnsToShow}
                    currentInput={getCurrentInput}
                    updateCurrentInput={updateCurrentInput}
                    getStockUpdateType={getStockUpdateType}
                    onChange={onChange}
                    onComplete={onComplete}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ProductLineItem;

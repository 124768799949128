import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class TillListFilters extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  @observable showFilters = false;
  @action setShowFilters = (show) => {
    this.showFilters = show;
  };

  @observable disabledTills = false;
  @action setDisabledTills = (value) => {
    this.disabledTills = value;
  };

  onStatusChange = (event) => {
    const value = event.target.value === "disabled";
    this.setDisabledTills(value);
    this.updateFilter("showDisabled", value);
    this.props.loadTills();
  };

  updateFilter = (parameter, value) => {
    if (value === "") {
      value = null;
    }
    this.props.updateTillFilter(parameter, value);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(this.props.loadTills, 500);
  };

  resetFilters = () => {
    this.props.resetTillFilters();
    this.props.loadTills();
  };

  render() {
    const { tillFilters } = this.props;
    return (
      <div className="tills-filter">
        <form>
          <div className="always-visible">
            <div className="radio-group form-group">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="tillFilter"
                  id="allTills"
                  value="enabled"
                  checked={!this.disabledTills}
                  onChange={this.onStatusChange}
                />
                <label className="form-check-label" htmlFor="allTills">
                  Enabled Tills
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="tillFilter"
                  id="enabledTills"
                  value="disabled"
                  checked={this.disabledTills}
                  onChange={this.onStatusChange}
                />
                <label className="form-check-label" htmlFor="enabledTills">
                  Disabled Tills
                </label>
              </div>
            </div>
            <button
              className="btn1"
              type="button"
              onClick={() => this.setShowFilters(!this.showFilters)}
            >
              {this.showFilters ? (
                <span>
                  Hide Filters <i className="fas fa-caret-up" />
                </span>
              ) : (
                <span>
                  More Filters <i className="fas fa-caret-down" />
                </span>
              )}
            </button>
          </div>
          <div
            className={`togglable-filters ${this.showFilters ? "visible" : ""}`}
          >
            <button
              type="button"
              className="reset-button float-md-right"
              onClick={this.resetFilters}
            >
              <i className="fas fa-sync" /> Reset Filters
            </button>
            <div className="form-group">
              <label htmlFor="searchTitle">Name contains</label>
              <input
                id="searchTitle"
                type="text"
                value={tillFilters.name || ""}
                onChange={(event) =>
                  this.updateFilter("name", event.target.value)
                }
              />
              <label htmlFor="searchDescription">Description contains</label>
              <input
                id="searchDescription"
                type="text"
                value={tillFilters.description || ""}
                onChange={(event) =>
                  this.updateFilter("description", event.target.value)
                }
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

TillListFilters.propTypes = {
  tillFilters: PropTypes.object,
  resetTillFilters: PropTypes.func.isRequired,
  updateTillFilter: PropTypes.func.isRequired,
  loadTills: PropTypes.func.isRequired,
};

export default TillListFilters;

import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { SettingsMenuStructure } from "../../menuStructures";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { Loader } from "../../components";

@inject("store")
@observer
class TimezoneSettings extends React.Component {
  @observable updating;

  @action setUpdating(updating) {
    this.updating = updating;
  }

  @observable selectedTimezone;

  @action setSelectedTimezone(selectedTimezone) {
    this.selectedTimezone = selectedTimezone;
  }

  handleSelectedTimezoneChange = (selectedOptions) => {
    this.setSelectedTimezone(selectedOptions.value);
  };

  constructor(props) {
    super(props);
    this.taxRateRef = React.createRef();
    this.taxIncludedRef = React.createRef();
    this.taxNumberRef = React.createRef();
    this.tradeInTaxRef = React.createRef();
    this.taxWordingRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Timezone Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
    this.props.store.SettingsStore.getCustomerTimezone();
    this.props.store.SettingsStore.getTimezones();
  }

  buildOptionsList(listToConvert) {
    const options = [];
    listToConvert.forEach((item) => {
      if (item.zoneName && item.zoneName.length > 0) {
        options.push({ value: item.zoneName, label: item.zoneName });
      }
    });
    return options;
  }

  updateTimezone() {
    this.props.store.SettingsStore.setCustomerTimezone(null);
    this.setUpdating(true);
    const timezoneUpdate = {
      timezone: this.selectedTimezone,
    };
    this.props.store.SettingsStore.updateCustomerTimezone(timezoneUpdate)
      .then(() => {
        this.props.store.MainStore.setInfo({
          title: "Timezone updated!",
          message: `You have updated your timezone to ${this.selectedTimezone}`,
        });
        this.props.store.SettingsStore.getCustomerTimezone();
        this.setUpdating(false);
      })
      .catch((err) => {
        this.props.store.MainStore.setError(
          err,
          "Failed to load setting",
          "There was an error submitting your new timezone. Please try again"
        );
      });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Timezone Settings</span>
          </h2>
        </div>
        {this.props.store.SettingsStore.timezones &&
        this.props.store.SettingsStore.timezones.length > 0 &&
        this.props.store.SettingsStore.customerTimezone &&
        this.props.store.SettingsStore.customerTimezone.length > 0 &&
        !this.updating ? (
          <div className="infoInputPages">
            <div className="row">
              <div className="col-md-12 field">
                <label className="label">Your timezone</label>
                <Select
                  onChange={this.handleSelectedTimezoneChange}
                  options={this.buildOptionsList(
                    this.props.store.SettingsStore.timezones
                  )}
                  defaultValue={{
                    label: this.props.store.SettingsStore.customerTimezone,
                    value: this.props.store.SettingsStore.customerTimezone,
                  }}
                />
              </div>
              <button
                className="button buttonWide"
                onClick={() => this.updateTimezone()}
              >
                Update timezone
              </button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}
TimezoneSettings.propTypes = {
  store: PropTypes.object,
};
export default TimezoneSettings;

import { BASE_URL } from "../constants/api";
import firebase from "firebase/app";
import "firebase/auth";
import debounce from "lodash/debounce";
import { action, configure, extendObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { CSV_URL } from "../constants/api";
import { _fetch, _fetchWithFile } from "../api";
import { getFirebaseConfig } from "../utils/ConfigUtils";
import BuylistRulesStore from "./BuylistRulesStore";
import EventsStore from "./EventsStore";
import IntegrationSyncStore from "./IntegrationSyncStore";
import ReportsStore from "./ReportsStore";

configure({ enforceActions: "observed" });

const config = getFirebaseConfig();
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

class TillStore {
  @observable tills;

  @action setTills(tills) {
    this.tills = tills;
  }

  constructor(root) {
    this.root = root;
  }

  rehydrateTills() {
    this.setTills(null);
    this.fetchTills()
      .then((result) => {
        this.setTills(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to load tills",
          "There was an error retrieving your till details. Please refresh and try again"
        );
      });
  }

  async fetchTills() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/tills`,
    });
  }

  async addNewTill(till) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/tills`,
      payload: till,
    });
  }

  async disableTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/disable`,
    });
  }

  async archiveTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/archive`,
    });
  }

  async enableTill(tillId) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/tills/byId/${tillId}/enable`,
    });
  }
}

class SettingsStore {
  @observable currencySymbol;

  @action setCurrencySymbol(currencySymbol) {
    this.currencySymbol = currencySymbol;
  }

  @observable storeSettings;

  @action setStoreSettings(storeSettings) {
    this.storeSettings = storeSettings;
  }

  @observable settings;

  @action setSettings(settings) {
    this.settings = settings;
  }

  @observable customerSettings;

  @action setCustomerSettings(settings) {
    this.customerSettings = settings;
  }

  @observable customerTimezone;

  @action setCustomerTimezone(timezone) {
    this.customerTimezone = timezone;
  }

  @observable timezones;

  @action setTimezones(timezones) {
    this.timezones = timezones;
  }

  constructor(root) {
    this.root = root;
  }

  getTimezones() {
    this.fetchTimezones()
      .then((result) => {
        this.setTimezones(result);
      })
      .catch((err) => {
        this.root.MainStore.setError(
          err,
          "Failed to get timezones",
          "There was an error retrieving a list of available timezone. Please refresh and try again"
        );
      });
  }

  getCustomerTimezone() {
    this.fetchCustomerTimezone()
      .then((result) => {
        this.setCustomerTimezone(result.timezone);
      })
      .catch((err) => {
        this.root.MainStore.setError(
          err,
          "Failed to get timezone details",
          "There was an error retrieving your store's timezone. Please refresh and try again"
        );
      });
  }

  async fetchTimezones() {
    return await _fetch({ method: "GET", endpoint: `${BASE_URL}/timezones` });
  }

  async fetchCustomerTimezone() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/timezone`,
    });
  }

  async updateCustomerTimezone(timezone) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/settings/timezone`,
      payload: timezone,
    });
  }

  async fetchStoreSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/store`,
    });
  }

  async updateStoreSettings(storeSettingsToSave) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/settings/store`,
      payload: storeSettingsToSave,
    });
  }

  async fetchSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/customer/settings`,
    });
  }

  async fetchCustomerSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/forMe`,
    });
  }

  async fetchCustomerSettingForType(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/${type}/forMe`,
    });
  }

  async updateSetting(setting) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/settings/save`,
      payload: setting,
    });
  }

  async fetchCustomerTillSettingForType(tillId, type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/settings/forTill/${tillId}/${type}`,
    });
  }

  async updateTillSetting(tillId, setting) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/settings/forTill/save`,
      payload: { ...setting, binderCustomerTillId: tillId },
    });
  }

  async fetchCustomerCurrencySymbol() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/currencySymbol`,
    });
  }
}

/**
 * Products Store
 */
class ProductsStore {
  @observable productTypes;

  @action setProductTypes(productTypes) {
    this.productTypes = productTypes;
  }

  @observable tags;

  @action setTags(tags) {
    this.tags = tags;
  }

  @observable vendors;

  @action setVendors(vendors) {
    this.vendors = vendors;
  }

  @observable products;

  @action setProducts(products) {
    this.products = products;
  }

  @observable savedSearches;

  @action setSavedSearches(savedSearches) {
    this.savedSearches = savedSearches;
  }

  @observable queuedJobs;

  @action setQueuedJobs(queuedJobs) {
    this.queuedJobs = queuedJobs;
  }

  constructor(root) {
    this.root = root;
  }

  getSavedSearches() {
    this.fetchSavedSearches().then((result) => {
      this.setSavedSearches(result);
    });
  }

  rehydrateSavedSearches() {
    this.setSavedSearches(null);
    this.getSavedSearches();
  }

  getQueuedJobs(type, limit, offset) {
    if (!type) {
      type == "bulkUpdate";
    }
    if (!limit) {
      limit = 20;
    }
    if (!offset) {
      offset = 0;
    }
    this.fetchQueuedJobs(type, limit, offset)
      .then((results) => {
        this.setQueuedJobs(results);
        return Promise.resolve();
      })
      .catch((error) => {
        this.root.MainStore.setError(error);
        return Promise.reject(
          error,
          "Failed to get queued jobs",
          "There was an error retrieving the list of queued jobs. Please refresh and try again"
        );
      });
  }

  getProductTypes() {
    this.fetchProductTypes()
      .then((results) => {
        this.setProductTypes(results);
        return Promise.resolve();
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get product types",
          "There was an error retrieving a list of product types. Please refresh and try again"
        );
        return Promise.reject(error);
      });
  }

  getTags() {
    this.fetchTags()
      .then((result) => {
        this.setTags(result);
        return Promise.resolve();
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get tags",
          "There was an error retrieving your store's tags. Please refresh and try again"
        );
        return Promise.reject(error);
      });
  }

  getTagsStartsWith(startsWith) {
    this.fetchTagsStartsWith(startsWith)
      .then((result) => {
        this.setTags(result);
        return Promise.resolve();
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get tags",
          "There was an error searching for tags. Please try again"
        );
        return Promise.reject(error);
      });
  }

  getVendors() {
    this.fetchVendors()
      .then((result) => {
        this.setVendors(result);
        return Promise.resolve();
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to vendors",
          "There was an error retrieving vendor details. Please refresh and try again"
        );
        return Promise.reject(error);
      });
  }

  buildGenericFilters() {
    const promises = [];
    promises.push(this.getProductTypes());
    promises.push(this.getTags());
    promises.push(this.getVendors());
    return Promise.all(promises);
  }

  async fetchProducts(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products?offset=${offset}&limit=${limit}&includeBuyprice=true`,
    });
  }

  async fetchProductTypes() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/productTypes`,
    });
  }

  async fetchTags() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/tags`,
    });
  }

  async fetchTagsStartsWith(startsWith) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/tags?startsWith=${startsWith}`,
    });
  }

  async fetchVendors() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/vendors`,
    });
  }

  async advancedSearch(searchFilters) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/products/advancedSearch`,
      payload: searchFilters,
    });
  }

  async updateVariant(variant) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/products/variant/update`,
      payload: variant,
    });
  }

  async saveSearch(searchToSave) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/products/advancedSearch/save`,
      payload: searchToSave,
    });
  }

  async fetchSavedSearches() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/advancedSearch/saved`,
    });
  }

  async deleteSavedSearchFilter(id) {
    return await _fetch({
      method: "DELETE",
      endpoint: `${BASE_URL}/products/advancedSearch/saved/${id}`,
    });
  }

  async fetchQueuedJobs(type, limit, offset) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/queuedJobs?type=${type}&offset=${offset}&limit=${limit}`,
    });
  }

  async fetchQueuedJobStatus(queuedJobId, type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/products/queuedJobs/${queuedJobId}/type/${type}`,
    });
  }

  async uploadCSV(file) {
    const formData = new FormData();
    formData.append("file", file);
    return _fetchWithFile({
      endpoint: "https://import.binderpos.com/upload-csv/binder",
      payload: formData,
    });
  }

  async uploadTcgCSV(file) {
    const formData = new FormData();
    formData.append("file", file);
    return _fetchWithFile({
      endpoint: "https://import.binderpos.com/upload-csv/",
      payload: formData,
    });
  }

  async fetchColumnsToShow() {
    const result = await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/productInventoryColumns/forMe`,
    });
    try {
      return JSON.parse(result.settingValue);
    } catch (_error) {
      return null;
    }
  }

  async updateColumnsToShow(columns) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/settings/save`,
      payload: {
        id: null,
        settingName: "productInventoryColumns",
        settingValue: JSON.stringify(columns),
        binderCustomerId: null,
      },
    });
  }
}

class CSVStore {
  constructor(root) {
    this.root = root;
  }

  async exportProductsCSV(searchFilters, type) {
    return await _fetch({
      method: "POST",
      endpoint: `${CSV_URL}/export/variants?type=${type}`,
      payload: searchFilters,
    });
  }

  async postCustomerCSV(file) {
    return await _fetchWithFile({
      endpoint: `${CSV_URL}/upload/customers`,
      payload: file,
    });
  }
}

class POSBuylistRulesStore {
  @observable posFailedToSyncList;

  @action setPOSFailedToSyncList(posFailedToSyncList) {
    this.posFailedToSyncList = posFailedToSyncList;
  }

  rehydratePOSFailedToSync(offset, limit) {
    this.setPOSFailedToSyncList(null);
    this.fetchPOSFailedToSyncProducts(offset, limit)
      .then((result) => {
        this.setPOSFailedToSyncList(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to products",
          "There was an error retrieving the list of products that failed to sync. Please refresh and try again"
        );
      });
  }

  async fetchPOSFailedToSyncProducts(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/failedProducts?offset=${offset}&limit=${limit}`,
    });
  }

  async processPOSFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/pos/pushFailedProduct`,
      payload: buylistItemToProcess,
    });
  }

  async removePOSFailedProduct(buylistItemToProcess) {
    return await _fetch({
      method: "DELETE",
      endpoint: `${BASE_URL}/pos/removeFailedProduct/byId/${buylistItemToProcess.id}`,
    });
  }
}

class CardStore {
  constructor(root) {
    this.root = root;
  }

  @observable cardVariants;

  @action setCardVariants(cardVariants) {
    this.cardVariants = cardVariants;
  }

  @observable searchResults;

  @action setSearchResults(results) {
    this.searchResults = results;
  }

  @observable rarities;

  @action setRarities(results) {
    this.rarities = results;
  }

  @observable games;

  @action setGames(results) {
    this.games = results;
  }

  @observable sets;

  @action setSets(results) {
    this.sets = results;
  }

  @observable cardTypes;

  @action setCardTypes(cardTypes) {
    this.cardTypes = cardTypes;
  }

  @observable colors;

  @action setColors(colors) {
    this.colors = colors;
  }

  @observable monsterTypes;

  @action setMonsterTypes(monsterTypes) {
    this.monsterTypes = monsterTypes;
  }

  @observable classes;

  @action setClasses(classes) {
    this.classes = classes;
  }

  @observable subTypes;

  @action setSubTypes(subTypes) {
    this.subTypes = subTypes;
  }

  @observable finishes;

  @action setFinishes(finishes) {
    this.finishes = finishes;
  }

  @observable editions;

  @action setEditions(editions) {
    this.editions = editions;
  }

  @observable cards;

  @action setCards(results) {
    this.cards = results;
  }

  buildGameTypeData(game) {
    const promises = [];
    promises.push(this.getRarities(game));
    promises.push(this.getCardTypes(game));
    promises.push(this.getCardColors(game));
    promises.push(this.getCardMonsterTypes(game));
    promises.push(this.getCardSets(game));
    promises.push(this.getCardVariants(game));
    promises.push(this.getCardClasses(game));
    promises.push(this.getCardSubTypes(game));
    promises.push(this.getCardFinishes(game));
    promises.push(this.getCardEditions(game));
    return Promise.all(promises);
  }

  clearGameTypeData() {
    this.setRarities(null);
    this.setCardTypes(null);
    this.setColors(null);
    this.setMonsterTypes(null);
    this.setSets(null);
    this.setCardVariants(null);
    this.setClasses(null);
    this.setSubTypes(null);
    this.setFinishes(null);
    this.setEditions(null);
  }

  getRarities(game) {
    this.fetchCardRarities(game)
      .then((result) => {
        this.setRarities(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get rarities",
          "There was an error retrieving the list of rarities. Please refresh and try again"
        );
      });
  }

  getCardTypes(game) {
    this.fetchCardTypes(game)
      .then((result) => {
        this.setCardTypes(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get card types",
          "There was an error retrieving the list of card types. Please refresh and try again"
        );
      });
  }

  getCardColors(game) {
    this.fetchCardColors(game)
      .then((result) => {
        this.setColors(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get card colors",
          "There was an error retrieving the list of card colors. Please refresh and try again"
        );
      });
  }

  getCardMonsterTypes(game) {
    this.fetchCardMonsterTypes(game)
      .then((result) => {
        this.setMonsterTypes(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get monster types",
          "There was an error retrieving the list of monster types. Please refresh and try again"
        );
      });
  }

  getCardSets(game) {
    this.fetchCardSetNames(game)
      .then((result) => {
        this.setSets(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get sets",
          "There was an error retrieving the list of set names. Please refresh and try again"
        );
      });
  }

  getCardClasses(game) {
    this.fetchCardClasses(game)
      .then((result) => {
        this.setClasses(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get classes",
          "There was an error retrieving list of card classes. Please refresh and try again"
        );
      });
  }

  getCardSubTypes(game) {
    this.fetchCardSubTypes(game)
      .then((result) => {
        this.setSubTypes(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get sub types",
          "There was an error retrieving list of card sub types. Please refresh and try again"
        );
      });
  }

  getCardFinishes(game) {
    this.fetchCardFinishes(game)
      .then((result) => {
        this.setFinishes(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get finishes",
          "There was an error retrieving the list of card finishes. Please refresh and try again"
        );
      });
  }

  getCardEditions(game) {
    this.fetchCardEditions(game)
      .then((result) => {
        this.setEditions(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get editions",
          "There was an error retrieving list of card editions. Please refresh and try again"
        );
      });
  }

  getCardVariants(game) {
    this.fetchCardVariants(game)
      .then((result) => {
        this.setCardVariants(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get variants",
          "There was an error retrieving list of product variants. Please refresh and try again"
        );
      });
  }

  async fetchCardSetNames(type, searchTerm) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/sets${
        searchTerm ? `?keyword=${encodeURIComponent(searchTerm)}` : ""
      }`,
    });
  }

  async fetchCardRarities(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/rarities`,
    });
  }

  async fetchCardGames() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/games/supported`,
    });
  }

  async fetchCardTypes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/types`,
    });
  }

  async fetchCardColors(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/colors`,
    });
  }

  async fetchCardMonsterTypes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/monsterTypes`,
    });
  }

  async fetchCardVariants(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/variants/byGame/${type}/types`,
    });
  }

  async fetchCardClasses(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/classes`,
    });
  }

  async fetchCardSubTypes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/subTypes`,
    });
  }

  async fetchCardFinishes(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/finishes`,
    });
  }

  async fetchCardEditions(type) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/editions`,
    });
  }

  async fetchCardsWithinSet(type, setName) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/cards/${type}/set/${setName}`,
    });
  }
}

/**
 * Customers Store
 */
class CustomersStore {
  @observable customers;

  @action setCustomers(customers) {
    this.customers = customers;
  }

  @observable customer;

  @action setCustomer(customer) {
    this.customer = customer;
  }

  @observable customerVariants;

  @action setCustomerVariants(variants) {
    this.customerVariants = variants;
  }

  constructor(root) {
    this.root = root;
  }

  rehydrate(offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 100;
    }
    this.setCustomers(null);
    this.fetchCustomers(offset, limit)
      .then((result) => {
        this.setCustomers(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get customer list",
          "There was an error retrieving list of customers. Please refresh and try again"
        );
      });
  }

  async fetchCustomerById(id) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/customers/byId/${id}`,
    });
  }

  async fetchCustomers(offset, limit, keyword) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/customers?offset=${offset}&limit=${limit}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }`,
    });
  }

  async refreshCustomers(offset, limit) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/customers?offset=${offset}&limit=${limit}&refresh=true`,
    });
  }

  async addCustomer(customerToAdd) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/customers/add`,
      payload: customerToAdd,
    });
  }

  async updateCustomer(customerToUpdate) {
    return await _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/customers/update`,
      payload: customerToUpdate,
    });
  }

  async getCustomerVariants() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/variants`,
    });
  }
}

class CartStore {
  constructor(root) {
    this.root = root;
  }

  @observable carts;

  @action setCarts(carts) {
    this.carts = carts;
  }

  @observable selectedCart;

  @action setSelectedCart(cart) {
    this.selectedCart = cart;
  }

  rehydrateAllCarts(offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 50;
    }
    this.setCarts(null);
    this.fetchAllCarts(offset, limit)
      .then((result) => {
        this.setCarts(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get carts",
          "There was an error retrieving list of carts. Please refresh and try again"
        );
      });
  }

  rehydrateAllCartsForTill(tillId, offset, limit) {
    if (!offset) {
      offset = 0;
    }
    if (!limit) {
      limit = 50;
    }
    this.setCarts(null);
    this.fetchAllCartsForTill(tillId, offset, limit)
      .then((result) => {
        this.setCarts(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get carts",
          "There was an error retrieving list of carts for this till. Please refresh and try again"
        );
      });
  }

  async fetchAllCarts(offset, limit, startDate, endDate) {
    if (startDate && endDate) {
      return await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/pos/carts/all?limit=${limit}&offset=${offset}&submitted=true&startDate=${startDate}&endDate=${endDate}`,
      });
    }
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/carts/all?limit=${limit}&offset=${offset}&submitted=true`,
    });
  }

  async fetchAllCartsForTill(tillId, offset, limit, startDate, endDate) {
    if (startDate && endDate) {
      return await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/pos/carts/forTill/${tillId}?limit=${limit}&offset=${offset}&submitted=true&startDate=${startDate}&endDate=${endDate}`,
      });
    }
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/carts/forTill/${tillId}?limit=${limit}&offset=${offset}&submitted=true`,
    });
  }

  async fetchCartById(id) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/pos/carts/byId/${id}`,
    });
  }
}

class UsersStore {
  constructor(root) {
    this.root = root;
  }

  @observable users;

  @action setUsers(users) {
    this.users = users;
  }

  @observable user;

  @action setUser(user) {
    this.user = user;
  }

  fetchAndSetUser(id) {
    this.setUser(null);
    this.fetchUser(id)
      .then((result) => {
        this.setUser(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get user",
          "There was an error retrieving the user details. Please refresh and try again"
        );
      });
  }

  rehydrateUsers() {
    this.setUsers(null);
    this.fetchUsers()
      .then((result) => {
        this.setUsers(result);
      })
      .catch((error) => {
        this.root.MainStore.setError(
          error,
          "Failed to get users",
          "There was an error retrieving list of users. Please refresh and try again"
        );
      });
  }

  async fetchUser(id) {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/users/byId/${id}`,
    });
  }

  async fetchUsers() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/users/list`,
    });
  }

  async createUser(user) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/users/create`,
      payload: user,
    });
  }

  async updateUser(user) {
    return await _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/users/update`,
      payload: user,
    });
  }

  async disableUser(id) {
    return await _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/users/${id}/disable`,
    });
  }

  async enableUser(id) {
    return await _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/users/${id}/enable`,
    });
  }

  async sendPasswordReset(id) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/users/${id}/passwordReset`,
    });
  }
}

class AuthStore {
  @observable hasLoaded = false;

  @action setHasLoaded(hasLoaded) {
    this.hasLoaded = hasLoaded;
  }

  @observable user = null;

  @action setUser(user) {
    this.user = user;
  }

  @observable screenSettings;

  @action setScreenSettings(screenSettings) {
    this.screenSettings = screenSettings;
  }

  async fetchScreenSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/screens/forMe`,
    });
  }

  constructor(root) {
    this.root = root;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setUser(user);
        if (window.fcWidget && window.fcWidget.user) {
          window.fcWidget.user.setEmail(user ? user.email : null);
        }
        this.root.SettingsStore.getCustomerTimezone();
        this.fetchScreenSettings()
          .then((result) => {
            this.setScreenSettings(result);
          })
          .catch((err) => {
            console.error("Error: ", err);
            if (typeof err === "object" && err.error === "Unauthorized") {
              this.setScreenSettings("No account");
            } else {
              this.setScreenSettings("Error");
            }
          });
        this.root.SettingsStore.fetchSettings()
          .then((result) => {
            if (result) {
              this.root.SettingsStore.setStoreSettings(result);
            }
          })
          .catch((error) => {
            this.root.MainStore.setError(
              error,
              "Failed to get settings",
              "There was an error retrieving list of settings for your store. Please refresh and try again"
            );
          });
      }
      this.setHasLoaded(true);
    });
  }

  logUserOut() {
    this.setUser(null);
    Store.SettingsStore.setSettings(null);
    Store.EventsStore.setEvents(null);
  }
}

class MenuStore {
  @observable sideMenuToDisplay;

  @action setSideMenuToDisplay(sideMenuToSet) {
    this.sideMenuToDisplay = sideMenuToSet;
  }

  constructor(root) {
    this.root = root;
  }
}

class MainStore {
  @observable showSupportModal = false;

  @action setShowSupportModal(value) {
    this.showSupportModal = value;
  }

  @observable error;

  @action setError(error, fallbackTitle = null, fallbackMessage = null) {
    if (error?.error || !fallbackTitle) {
      this.error = error;
    } else {
      this.error = {
        error: fallbackTitle,
        detailedMessage: fallbackMessage,
      };
    }
  }

  @observable info;

  @action setInfo(info) {
    this.info = info;
  }

  @observable posSalesData;

  @action setPosSalesData(posSalesData) {
    this.posSalesData = posSalesData;
  }

  @observable appUpdate;

  @action setAppUpdate(appUpdate) {
    this.appUpdate = appUpdate;
  }

  // TODO:: Rename this to currencySymbol
  @observable currency;

  @action setCurrency(currency) {
    this.currency = currency;
  }

  @observable currencyCode;

  @action setCurrencyCode = (code) => {
    this.currencyCode = code;
  };

  @observable storeInfo;

  @action setStoreInfo(storeInfo) {
    this.storeInfo = storeInfo;
  }

  @observable taxWording;

  @action setTaxWording(taxWording) {
    this.taxWording = taxWording;
  }

  @observable taxNumber;

  @action setTaxNumber(taxNumber) {
    this.taxNumber = taxNumber;
  }

  @observable taxRate;

  @action setTaxRate(taxRate) {
    this.taxRate = taxRate;
  }

  @observable storeInfo;

  constructor(root) {
    this.root = root;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getAppUpdate() {
    this.fetchAppUpdate()
      .then((result) => {
        this.setAppUpdate(result);
      })
      .catch(() => {
        this.setAppUpdate(null);
      });
  }

  toast(msg) {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  burntToast(msg) {
    toast.warn(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  buildReceiptData() {
    this.getStoreInfo();
    this.getTaxWording();
    this.getTaxNumber();
    this.getCurrency();
    this.getTaxRate();
  }

  getPosSalesData() {
    this.fetchPOSSaleData()
      .then((data) => {
        this.setPosSalesData(data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get Sales Data",
          "There was an error getting the sales data. Please try again"
        );
      });
  }

  getStoreInfo() {
    this.fetchStoreInfo()
      .then((storeInfo) => {
        this.setStoreInfo(storeInfo);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get store info",
          "There was an error retrieving your store information. Please refresh and try again"
        );
      });
  }

  getTaxWording() {
    this.fetchTaxWording()
      .then((taxWording) => {
        this.setTaxWording(taxWording.settingValue);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get tax details",
          "There was an error retrieving your tax wording. Please refresh and try again"
        );
      });
  }

  getTaxNumber() {
    this.fetchTaxNumber()
      .then((taxNumber) => {
        this.setTaxNumber(taxNumber.settingValue);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get tax details",
          "There was an error retrieving your tax number. Please refresh and try again"
        );
      });
  }

  getCurrency() {
    this.fetchCurrency()
      .then((currencySymbol) => {
        this.setCurrency(currencySymbol.settingValue);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get currency details",
          "There was an error retrieving your store's currency. Please refresh and try again"
        );
      });
  }

  getCurrencyCode = () => {
    this.fetchCurrencyCode()
      .then((setting) => {
        const currencyCode = setting.settingValue || "USD";
        this.setCurrencyCode(currencyCode);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get currency details",
          "There was an error retrieving your store's currency symbol. Please refresh and try again"
        );
      });
  };

  getTaxRate() {
    this.fetchTaxRate()
      .then((taxRate) => {
        this.setTaxRate(taxRate.settingValue);
      })
      .catch((error) => {
        this.setError(
          error,
          "Failed to get tax details",
          "There was an error retrieving your tax rate. Please refresh and try again"
        );
      });
  }

  currencyBuilder = (amount) => {
    if (amount) {
      if (amount >= 0) {
        return this.currency + amount.toFixed(2);
      }
      return `-${this.currency}${Math.abs(amount).toFixed(2)}`;
    }
    return amount;
  };

  async fetchAppUpdate() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/binderpos/latestUpdate`,
    });
  }

  async fetchCurrency() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/currencySymbol`,
    });
  }

  async fetchCurrencyCode() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/currency`,
    });
  }

  async fetchStoreInfo() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/store`,
    });
  }

  async fetchTaxWording() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/taxWording/forMe`,
    });
  }

  async fetchTaxNumber() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/taxNumber/forMe`,
    });
  }

  async fetchTaxRate() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/taxRate/forMe`,
    });
  }

  async fetchPriceRuleSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/priceRulesForGames`,
    });
  }

  async fetchDefaultPriceRuleSettings() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/priceRulesForGames/default`,
    });
  }

  async updatePriceRuleSettings(priceRuleSettings) {
    return await _fetch({
      method: "POST",
      endpoint: `${BASE_URL}/settings/priceRulesForGames/update`,
      payload: priceRuleSettings,
    });
  }

  async fetchPOSSaleData() {
    return await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/reports/pos/sales`,
    });
  }
}

class WindowSizeStore {
  constructor() {
    extendObservable(this, {
      windowWidth: null,
      screenWindow: null,
    });
  }

  @action
  setWindow = () => {
    if (typeof window === "object") {
      this.screenWindow = window;
      this.handleWindowWidthChange();
      this.screenWindow.addEventListener(
        "resize",
        this.handleWindowWidthChange
      );
    }
  };

  handleWindowWidthChange = debounce(() => {
    const width = this.screenWindow.innerWidth;
    this.setWindowWidth(width);
  }, 100);

  @action
  setWindowWidth = (width) => {
    this.windowWidth = width;
    return this.windowWidth;
  };
}

/**
 * Root
 * */
class RootStore {
  constructor() {
    this.MainStore = new MainStore(this);
    this.AuthStore = new AuthStore(this);
    this.CustomersStore = new CustomersStore(this);
    this.ProductsStore = new ProductsStore(this);
    this.CSVStore = new CSVStore(this);
    this.EventsStore = new EventsStore(this);
    this.SettingsStore = new SettingsStore(this);
    this.BuylistRulesStore = new BuylistRulesStore(this);
    this.POSBuylistRulesStore = new POSBuylistRulesStore(this);
    this.CardStore = new CardStore(this);
    this.TillStore = new TillStore(this);
    this.MenuStore = new MenuStore(this);
    this.CartStore = new CartStore(this);
    this.UsersStore = new UsersStore(this);
    this.ReportsStore = new ReportsStore(this);
    this.WindowSizeStore = new WindowSizeStore(this);
    this.IntegrationSyncStore = new IntegrationSyncStore(this);
    this.BuylistRulesStore = new BuylistRulesStore(this);
  }
}

const Store = new RootStore();
export default Store;

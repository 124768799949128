import React, { useEffect, useState } from "react";

export interface Setting {
  id: number | null;
  binderCustomerId: number | null;
  settingName: string | null;
  settingValue: string | null;
}

export default function NotesSettings({
  updateSetting,
  getSetting,
}: {
  updateSetting: (setting: Setting) => Promise<void>;
  getSetting: () => Promise<Setting>;
}) {
  const [setting, setSetting] = useState<Setting>({
    id: null,
    binderCustomerId: null,
    settingName: "customFields",
    settingValue: null,
  });
  const [customFields, setCustomFields] = useState<string[]>([]);
  const [newField, setNewField] = useState("");

  useEffect(() => {
    getSetting().then((newSetting) => {
      if (!newSetting.settingValue) return;
      setCustomFields(JSON.parse(newSetting.settingValue));
    });
  }, [getSetting]);

  const addField = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!newField) return;
    if (customFields.includes(newField)) return;
    const newFields = customFields ? [...customFields, newField] : [newField];
    setCustomFields(newFields);
    const newSetting = {
      ...setting,
      settingName: "customFields",
      settingValue: JSON.stringify(newFields),
    };
    setSetting(newSetting);
    setNewField("");
    await updateSetting(newSetting);
  };

  return (
    <div>
      <h2 className="title is-2">
        <span className="header-text">Customer fields of POS Cart Notes</span>
      </h2>
      <div style={{ display: "grid", gridTemplateColumns: "300px 1fr" }}>
        <ul style={{ margin: 5 }}>
          {customFields.map((field) => (
            <li
              key={field}
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            >
              {field}
              <button
                style={{
                  border: 0,
                  background: "transparent",
                  justifySelf: "right",
                }}
                onClick={async () => {
                  const newFields = customFields.filter((f) => f !== field);
                  setCustomFields(newFields);
                  await updateSetting({
                    ...setting,
                    settingValue: JSON.stringify(newFields),
                  });
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </li>
          ))}
        </ul>
        <form onSubmit={addField} style={{ marginLeft: 10 }}>
          <label htmlFor="new-note" style={{ display: "block" }}>
            Add new note field
          </label>
          <input
            id="new-note"
            value={newField}
            onChange={(e) => setNewField(e.target.value)}
          />
          <button
            style={{
              marginLeft: 5,
              background: "#0a162e",
              border: 0,
              color: "white",
            }}
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
}

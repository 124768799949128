import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import { TextInputFilter } from "../reports/CellTypes";
import { TimeUtils } from "../../utils";
import { TableSpinner } from "../reports";

function PaymentTypeSelect({ value, setValue }) {
  return (
    <select onChange={(event) => setValue(event.target.value)} value={value}>
      <option value="">- All -</option>
      <option>Cash</option>
      <option>Store Credit</option>
    </select>
  );
}

function DateRenderer({ cellData }) {
  return <div>{TimeUtils.convertDateToHumanReadable(cellData)}</div>;
}

function AvatarRenderer({ rowData }) {
  const { paymentType, shopifyCustomerId } = rowData;
  const { firstName, email } = shopifyCustomerId;
  return (
    <div className={paymentType == "Cash" ? "avatar red" : "avatar blue"}>
      {firstName && firstName.length > 0
        ? firstName.charAt(0)
        : email && email.length > 0
        ? email.charAt(0)
        : null}
    </div>
  );
}

function BuylistTable(props) {
  const headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }

    const { filterValues, updateFilterValue } = props;
    const {
      firstName = "",
      lastName = "",
      email = "",
      paymentType = "",
    } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "firstName" && (
                <TextInputFilter
                  data-testid="first-name"
                  value={firstName}
                  setValue={(value) => updateFilterValue("firstName", value)}
                />
              )}
              {key === "lastName" && (
                <TextInputFilter
                  value={lastName}
                  setValue={(value) => updateFilterValue("lastName", value)}
                />
              )}
              {key === "email" && (
                <TextInputFilter
                  value={email}
                  setValue={(value) => updateFilterValue("email", value)}
                />
              )}
              {key === "paymentType" && (
                <PaymentTypeSelect
                  value={paymentType}
                  setValue={(value) => updateFilterValue("paymentType", value)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const linkRenderer = ({ cellData }) => {
    const { viewUrl } = props;
    return (
      <Link to={`${viewUrl}/${cellData}`} className="btn1">
        {showStatus ? "View" : "Review"} <i className="fas fa-caret-right" />
      </Link>
    );
  };
  const { buylists, showStatus, sortBy, onColumnSort } = props;

  return (
    <div className="data-table">
      <div style={{ height: `${buylists ? buylists.length * 50 + 80 : 0}px` }}>
        <AutoResizer>
          {({ width }) => (
            <BaseTable
              data={buylists}
              width={width}
              height={buylists ? buylists.length * 50 + 80 : 0}
              rowHeight={50}
              headerHeight={[40, 40]}
              headerRenderer={headerRenderer}
              overlayRenderer={() => (
                <TableSpinner isLoading={props.isLoading} />
              )}
              sortBy={sortBy}
              onColumnSort={onColumnSort}
            >
              <Column
                key="id"
                title="#"
                dataKey="id"
                width={120}
                resizable
                sortable
              />
              <Column
                key="col_avatar"
                width={80}
                cellRenderer={AvatarRenderer}
                flexShrink={0}
              />
              <Column
                key="firstName"
                title="First Name"
                dataKey="shopifyCustomerId.firstName"
                width={120}
                resizable
                sortable
                flexGrow={1}
              />
              <Column
                key="lastName"
                title="Last Name"
                dataKey="shopifyCustomerId.lastName"
                width={120}
                resizable
                sortable
                flexGrow={1}
              />
              <Column
                key="email"
                title="Email"
                dataKey="shopifyCustomerId.email"
                width={250}
                resizable
                sortable
                flexGrow={2}
              />
              <Column
                key="dateSubmitted"
                title="Date Submitted"
                dataKey="readableSubmittedDate"
                width={200}
                resizable
                sortable
                cellRenderer={DateRenderer}
              />
              <Column
                key="col_paymentType"
                title="Payment Type"
                dataKey="paymentType"
                width={120}
              />
              {showStatus && (
                <Column
                  key="col_status"
                  title="Status"
                  dataKey="status"
                  width={120}
                />
              )}
              <Column
                key="col_link"
                width={160}
                dataKey="id"
                cellRenderer={linkRenderer}
              />
            </BaseTable>
          )}
        </AutoResizer>
      </div>
    </div>
  );
}

BuylistTable.propTypes = {
  buylists: PropTypes.array,
  showStatus: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterValues: PropTypes.object,
  viewUrl: PropTypes.string,
  updateFilterValue: PropTypes.func.isRequired,
};

export default BuylistTable;

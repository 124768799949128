import { API_STATE } from "../../constants/api";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { convertToCsv } from "../../utils/data";
import DateRangeSelect from "./DateRangeSelect";

@inject("store")
@observer
class ReportControls extends Component {
  onSaveCSVClick = () => {
    let { csvHeaders, csvFilename } = this.props;
    if (!csvFilename) {
      csvFilename = "report.csv";
    }
    convertToCsv(csvHeaders, this.props.store.ReportsStore.sourceData).then(
      (csv) => {
        const file = new File([csv], csvFilename, { type: "text/csv" });
        const exportUrl = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = exportUrl;
        a.download = csvFilename;
        a.click();
        URL.revokeObjectURL(exportUrl);
      }
    );
  };

  onPrintClick = () => {
    const { ReportsStore } = this.props.store;
    const originalPage = ReportsStore.currentPage;
    const originalRowsPerPage = ReportsStore.rowsPerPage;
    ReportsStore.setRowsPerPage(ReportsStore.totalRowCount);
    ReportsStore.setCurrentPage(0);
    ReportsStore.setOverrideTableHeight(ReportsStore.totalRowCount * 50 + 75);
    setTimeout(() => {
      window.print();
      ReportsStore.setCurrentPage(originalPage);
      ReportsStore.setRowsPerPage(originalRowsPerPage);
      ReportsStore.setOverrideTableHeight(0);
    }, 100);
  };

  render() {
    const {
      loadData,
      setDateRange,
      store,
      children,
      setTimeZone,
      timeZone,
      defaultFromDate,
      defaultToDate,
    } = this.props;
    const { apiState } = store.ReportsStore;

    if (!!loadData && apiState === API_STATE.INITIAL) {
      return (
        <div className="report-buttons">
          <button
            type="button"
            className="full-width"
            onClick={loadData}
            disabled={apiState === API_STATE.LOADING}
          >
            <i className="fas fa-table" />
            Load Data
          </button>
        </div>
      );
    }

    if (!loadData || apiState === API_STATE.SUCCESS) {
      return (
        <div className="report-buttons">
          {setDateRange ? (
            <DateRangeSelect
              setDateRange={setDateRange}
              setTimeZone={setTimeZone}
              timeZone={timeZone}
              defaultFromDate={defaultFromDate}
              defaultToDate={defaultToDate}
            />
          ) : (
            <div />
          )}
          {children}
          <div className="export">
            {
              /*
              Hide CSV exports for browsers that don't support the File API 
              */
              window.File && (
                <button type="button" onClick={this.onSaveCSVClick}>
                  <i className="fas fa-download" />
                  Save as CSV
                </button>
              )
            }
            <button type="button" onClick={this.onPrintClick}>
              <i className="fas fa-print" />
              Print
            </button>
          </div>
        </div>
      );
    }

    return null;
  }
}

ReportControls.propTypes = {
  dateSelect: PropTypes.bool,
  csvHeaders: PropTypes.array,
  csvFilename: PropTypes.string,
  loadData: PropTypes.func,
  setDateRange: PropTypes.func,
  defaultFromDate: PropTypes.string,
  defaultToDate: PropTypes.string,
};

export default ReportControls;

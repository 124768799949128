import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { doUncompleteBuylist } from "../../api/rest/buylist";
import ConfirmationModal from "../generic/ConfirmationModal";

interface UncompleteBuylistProps {
  buylistId: number;
}

function UncompleteBuylist(props: UncompleteBuylistProps) {
  const { buylistId } = props;
  const [isUncompleting, setIsUncompleting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory();

  const handleConfirmClick = () => {
    setShowConfirmation(false);
    setIsUncompleting(true);
    doUncompleteBuylist(buylistId)
      .then(() => {
        setIsUncompleting(false);
        history.push(`/buylists/pending/moreDetail/${buylistId}`);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to uncomplete this buylist.");
        setIsUncompleting(false);
      });
  };

  const handleCancelClick = () => setShowConfirmation(false);

  const handleUncompleteClick = () => setShowConfirmation(true);

  return (
    <>
      <button
        className="btn1 cancel"
        onClick={() => handleUncompleteClick()}
        disabled={isUncompleting}
      >
        {isUncompleting ? "Uncompleting" : "Uncomplete"}
      </button>
      {showConfirmation ? (
        <ConfirmationModal
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          title="Uncomplete Buylist"
          text="Are you sure you want move this buylist back to Pending?"
        />
      ) : null}
    </>
  );
}

export default UncompleteBuylist;

import React, { Component } from "react";
import { inject } from "mobx-react";
import { SearchBar, ResultsGrid, Cart } from "../../components";
import SwipeableViews from "react-swipeable-views";
import { OperationalMode, operationalMode } from "../../constants/mode";
import KioskCart from "./KioskCart";

@inject("store")
class ViewSwipePOS extends Component {
  render() {
    const styles = {
      slideContainer: {
        height: "calc(100vh - 85px)",
        margin: "11px auto 0px",
      },
    };

    const isKioskMode = operationalMode === OperationalMode.Kiosk;

    return (
      <SwipeableViews style={styles.slideContainer} enableMouseEvents={true}>
        <div className="bodyPanel" style={Object.assign({})}>
          <div className="wrapper">
            <SearchBar
              store={posStore}
              updateFetchingProds={this.updateFetchingProds}
            />
            <ResultsGrid store={posStore} />
          </div>
        </div>
        <div className="cartPanel" style={Object.assign({})}>
          {isKioskMode ? (
            <KioskCart store={posStore} />
          ) : (
            <Cart store={posStore} />
          )}
        </div>
      </SwipeableViews>
    );
  }
}

export default ViewSwipePOS;

import React from "react";
import LinkComponent from "../../../components/generic/LinkComponent";
import "./SettingsMenu.scss";

function SettingsMenu() {
  return (
    <div className="ebay-settings">
      <div className="ebay-settings__navigation">
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">
            Stock Change History
          </h5>
          <p className="ebay-settings__navigation-description">
            View details of changes made to stock levels, pricing and details of
            any errors returned by Ebay.
          </p>
          <LinkComponent to="/integrations/ebay/logs/history">
            Stock Change History &gt;
          </LinkComponent>
        </div>
        <div className="ebay-settings__navigation-item">
          <h5 className="ebay-settings__navigation-title">Orders</h5>
          <p className="ebay-settings__navigation-description">
            View incoming orders from Ebay.
          </p>
          <LinkComponent to="/integrations/ebay/logs/orders">
            Orders &gt;
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;

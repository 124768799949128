const DEFAULT_HOST = "https://app-dev.binderpos.com";
const DEFAULT_GRAPHQL_ENDPOINT = "https://api-dev.binderpos.com/graphql";
const DEFAULT_REPORTING_GRAPHQL_ENDPOINT =
  "https://graphql.binderpos.com/graphql";

const HOST_URL = process?.env?.REACT_APP_ENV_URL ?? DEFAULT_HOST;

export const API_STATE = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  SAVING: "SAVING",
  ERROR: "ERROR",
};

export const BASE_URL = `${HOST_URL}/api`;
export const API_V2_BASE_URL = `${HOST_URL}/apiv2`;
export const CSV_URL = `${HOST_URL}/csv`;
export const EBAY_BASE_URL = `${HOST_URL}/ebay`;
export const CFB_MARKETPLACE_BASE_URL = `${HOST_URL}/cfbmarket`;

export const GRAPHQL_ENDPOINT =
  process?.env?.REACT_APP_GRAPHQL_URL ?? DEFAULT_GRAPHQL_ENDPOINT;

export const REPORTING_GRAPHQL_ENDPOINT =
  process?.env?.REACT_APP_REPORTING_GRAPHQL_URL ??
  DEFAULT_REPORTING_GRAPHQL_ENDPOINT;

export const DEVBLOG_GRAPHQL_ENDPOINT = "https://devblog.binderpos.com/graphql";

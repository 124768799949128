import React from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { ToastContainer } from "react-toastify";
import PAX from "../../pos/PAX.js";

@inject("store")
@observer
class PaxSettings extends React.Component {
  @observable updatingTax = true;

  @action setUpdatingTax(updatingTax) {
    this.updatingTax = updatingTax;
  }

  @observable paxIP;

  @action setPaxIP(paxIP) {
    this.paxIP = paxIP;
    if (paxIP == "null") {
      this.paxIP = "";
    }
  }

  @observable paxSerial;

  @action setPaxSerial(paxSerial) {
    this.paxSerial = paxSerial;
    if (paxSerial == "null") {
      this.paxSerial = "";
    }
  }

  @observable paxEnabled = false;

  @action setPaxEnabled(paxEnabled) {
    if (paxEnabled === "true" || paxEnabled === true) {
      this.paxEnabled = true;
    } else {
      this.paxEnabled = false;
    }
  }

  @observable dnsResolver = false;

  @action setDnsResolver(dnsResolver) {
    if (dnsResolver === "true" || dnsResolver === true) {
      this.dnsResolver = true;
    } else {
      this.dnsResolver = false;
    }
  }

  @observable savingSettings;

  @action setSavingSettings(savingSettings) {
    this.savingSettings = savingSettings;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Pax Terminal Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.TillStore.rehydrateTills();
  }

  fetchPaxSettings() {
    if (this.selectedTill) {
      this.props.store.SettingsStore.fetchCustomerTillSettingForType(
        this.selectedTill,
        "paxIP"
      )
        .then((result) => {
          this.setPaxIP(result?.settingValue);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load Pax settings",
            "There was an error retrieving your Pax IP settings. Please refresh and try again"
          );
        });

      this.props.store.SettingsStore.fetchCustomerTillSettingForType(
        this.selectedTill,
        "paxEnabled"
      )
        .then((result) => {
          this.setPaxEnabled(result?.settingValue);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load Pax settings",
            "There was an error retrieving your Pax enabled setting. Please refresh and try again"
          );
        });

      this.props.store.SettingsStore.fetchCustomerTillSettingForType(
        this.selectedTill,
        "dnsResolver"
      )
        .then((result) => {
          this.setDnsResolver(result?.settingValue);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load Pax settings",
            "There was an error retrieving your Pax DNS settings. Please refresh and try again"
          );
        });

      this.props.store.SettingsStore.fetchCustomerTillSettingForType(
        this.selectedTill,
        "paxSerial"
      )
        .then((result) => {
          this.setPaxSerial(result?.settingValue);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load Pax settings",
            "There was an error retrieving your Pax serial settings. Please refresh and try again"
          );
        });
    }
    this.setUpdatingTax(true);
  }

  savePaxSettings() {
    this.setSavingSettings(true);
    const newPaxIP = this.paxIP;

    const paxIPSettings = {
      settingName: "paxIP",
      settingValue: `${newPaxIP}`,
    };

    const newPaxSerial = this.paxSerial;

    const paxSerialSettings = {
      settingName: "paxSerial",
      settingValue: `${newPaxSerial}`,
    };

    const newPaxEnabled = this.paxEnabled;

    const paxEnabledSettings = {
      settingName: "paxEnabled",
      settingValue: `${newPaxEnabled}`,
    };

    const newDnsResolver = this.dnsResolver;

    const dnsResolverSettings = {
      settingName: "dnsResolver",
      settingValue: `${newDnsResolver}`,
    };

    const promises = [];
    promises.push(
      this.props.store.SettingsStore.updateTillSetting(
        this.selectedTill,
        paxIPSettings
      )
    );

    promises.push(
      this.props.store.SettingsStore.updateTillSetting(
        this.selectedTill,
        paxSerialSettings
      )
    );
    promises.push(
      this.props.store.SettingsStore.updateTillSetting(
        this.selectedTill,
        paxEnabledSettings
      )
    );
    promises.push(
      this.props.store.SettingsStore.updateTillSetting(
        this.selectedTill,
        dnsResolverSettings
      )
    );
    Promise.all(promises)
      .then(() => {
        this.props.store.MainStore.toast("Payment Terminal settings updated!");
        this.setUpdatingTax(false);
        this.setSavingSettings(true);
        this.fetchPaxSettings();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to save Pax settings",
          "There was an error submitting your Pax settings. Please try again"
        );
        this.setSavingSettings(true);
      });
  }

  testTerminalSettings = async () => {
    let newPaxIP = this.paxIP;
    if (this.dnsResolver) {
      newPaxIP = `${this.selectedTill}.dns.binderpos.com:10009`;
    }

    const pax = new PAX(`https://${newPaxIP}`);
    pax.creditSale(0.01).then(console.log("test"));
  };

  @observable
  selectedTill = null;

  @action
  changeTill = (e) => {
    this.selectedTill = e.target.value;
    this.fetchPaxSettings();
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Payment Terminal Settings</span>
          </h2>
        </div>
        <div className="infoInputPages">
          <div className="col-md-12 content">
            <div
              style={{
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "20px",
              }}
            >
              <div>
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <select
                      className="tillDropdown"
                      onChange={this.changeTill}
                      data-testid="select-till"
                    >
                      <option value={-1} key="-1">
                        Please Select a Till
                      </option>
                      {this.props.store.TillStore.tills
                        ? this.props.store.TillStore.tills.map((till) => (
                            <option value={till.id} key={till.id}>
                              {`${till.name}\t-\t${till.description}`}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <label className="label">
                      The serial number of the unit you want to bond with this
                      till. On the back of an S300 it looks like S/N: xxxxxxxx
                    </label>
                    <input
                      title="PAX serial number"
                      onChange={(e) => {
                        this.setPaxSerial(e.target.value);
                        this.setUpdatingTax(false);
                      }}
                      ref={this.paxSerialRef}
                      id="paxIP"
                      className="input"
                      type="text"
                      required
                      placeholder="Example: 1222334"
                      value={this.paxSerial ? this.paxSerial : ""}
                    />
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <label className="label">
                      The Local IP Address of your payment terminal including
                      port. This should autoupdate when the PAX unit is plugged
                      in.
                    </label>
                    <input
                      onChange={(e) => {
                        this.setPaxIP(e.target.value);
                        this.setUpdatingTax(false);
                      }}
                      title="PAX IP address"
                      id="paxIP"
                      className="input"
                      type="text"
                      required
                      placeholder="Example: 192.168.1.200:10009"
                      value={this.paxIP ? this.paxIP : ""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xl-6">
                    <label className="checkbox">
                      <input
                        onChange={(e) => {
                          this.setPaxEnabled(e.target.checked);
                          this.setUpdatingTax(false);
                        }}
                        id="paxEnabled"
                        className="input"
                        type="checkbox"
                        checked={this.paxEnabled}
                      />
                      <span className="checkmark" /> Payment Terminal Enabled?
                    </label>
                  </div>
                  <div className="col-lg-12 col-xl-6">
                    <label className="checkbox">
                      <input
                        onChange={(e) => {
                          this.setDnsResolver(e.target.checked);
                          this.setUpdatingTax(false);
                        }}
                        id="dnsResolver"
                        className="input"
                        type="checkbox"
                        checked={this.dnsResolver}
                      />
                      <span className="checkmark" /> Use DNS Resolver?
                    </label>
                  </div>
                </div>

                <button
                  className="button buttonWide"
                  style={{ marginRight: "10px" }}
                  onClick={() => this.testTerminalSettings()}
                >
                  Test Terminal Communication
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <button
                  className="button buttonWide"
                  style={{ marginRight: "10px" }}
                  onClick={() => this.savePaxSettings()}
                  disabled={this.updatingTax}
                >
                  Save PAX Settings
                </button>
              </div>
              <div className="col-lg-12 col-xl-6">
                <button
                  className="button buttonWide"
                  style={{ marginRight: "10px", background: "#ab4747" }}
                  onClick={() => this.fetchPaxSettings()}
                  disabled={this.updatingTax}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
PaxSettings.propTypes = { store: PropTypes.object };
export default PaxSettings;

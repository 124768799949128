import { useContext } from "react";
import { StoreContext } from "../providers/StoreProvider";
import { roundToDecimalPlaces } from "../utils/formatting";

export const useStoreDetails = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStoreDetails hook must be used within StoreProvider");
  }
  const { customerId, customerName, currencySymbol, currencyCode, taxRate } =
    context;
  return {
    customerId,
    customerName,
    currencySymbol,
    currencyCode,
    taxRate,
  };
};

export const useFormatCurrency = (amount: string | number): string => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useFormatCurrency hook must be used within StoreProvider");
  }
  if (amount || amount === 0) {
    const value = Number(amount);
    const { currencySymbol } = context;
    if (value >= 0) {
      return `${currencySymbol}${roundToDecimalPlaces(value, 2)}`;
    } else {
      return `-${currencySymbol}${roundToDecimalPlaces(Math.abs(value), 2)}`;
    }
  }
};

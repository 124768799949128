import React from "react";
import { Post } from "../api/graphql/devBlog";
import { sanitize } from "dompurify";
import "./DevBlogPost.scss";
import FullLogo from "../../assets/img/fullLogo.png";

const READ_MORE_REGEX = /<a class="more-link".*<\/a>/;

interface DevBlogPostProps {
  post: Post;
}

function DevBlogPost(props: DevBlogPostProps) {
  const { post } = props;

  const excerpt = post.node.excerpt?.replace(READ_MORE_REGEX, "");

  return (
    <div className="DevBlogPost" data-testid="DevBlogPost">
      <img
        className="DevBlogPost__image"
        src={post?.node?.featuredImage?.node?.mediaItemUrl || FullLogo}
      />
      <a
        className="DevBlogPost__link"
        href={post.node.link}
        target="_blank"
        rel="noreferrer"
      >
        <div className="DevBlogPost__content">
          <p className="DevBlogPost__title">{post.node.title}</p>
          <p
            className="DevBlogPost__exceprt"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                // @ts-ignore
                excerpt
              ),
            }}
          />
        </div>
      </a>
    </div>
  );
}

export default DevBlogPost;

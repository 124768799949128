import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Loader } from "../../components";
import CreditProcess from "./CreditProcess";
import { disableFinalizeSale } from "../../utils/pos";

@inject("posStore")
@observer
class Checkout extends Component {
  @action tenderUpdate = (e, index) => {
    if (this.props.store.tenders.length <= index) {
      this.props.store.tenders.length = index + 1;
      this.props.store.tenders[index] = { amount: "0.00" };
    }
    if (isNaN(e.target.value) | (e.target.value == "")) {
      this.props.store.tenders[index].amount = e.target.value.replace(
        /[^0-9.-]/g,
        ""
      );
      if ((e.target.value == "") | (e.target.value == ".")) {
        this.props.store.tenders[index].amount = "0.00";
      }
    } else {
      this.props.store.tenders[index].amount = e.target.value;
    }
    this.verifyAmounts();
  };

  @action verifyAmounts = () => {
    this.props.store.tenders.forEach((tender, index) => {
      if (isNaN(tender.amount) | (tender.amount == "")) {
        if (tender.amount) {
          this.props.store.tenders[index].amount = tender.amount.replace(
            /[^0-9.-]/g,
            ""
          );
        }
        if ((tender.amount == "") | (tender.amount == ".")) {
          this.props.store.tenders[index].amount = "0.00";
        }
      } else {
        this.props.store.tenders[index].amount = tender.amount;
      }
    });
  };

  @action selectAll = (e, name) => {
    console.log(`Checkout@selectAll for name=${name}`);
    if (this.props.store.tenders.length <= name) {
      this.props.store.tenders.length = name + 1;
      this.props.store.tenders[name] = { amount: "0.00" };
    }
    this.props.store.tenders[name].amount = (
      parseFloat(this.props.store.tenders[name].amount) +
      parseFloat(this.props.store.balance)
    ).toFixed(2);

    console.dir({ CheckoutSelectAll: this.props.posStore.tenders[name] });
    if (
      this.props.posStore.tenders[name].type == "Store Credit" &&
      this.props.posStore.tenders[name].amount >
        this.props.posStore?.selectedCustomer?.storeCredit
    ) {
      this.props.posStore.tenders[name].amount =
        this.props.posStore.selectedCustomer.storeCredit;
    }
    e.target.select();
    this.verifyAmounts();
  };

  @action setTender = (e, index) => {
    this.props.posStore.activeTender = index;
    if (this.props.store.tenders.length <= index) {
      this.props.store.tenders.length = index + 1;
      this.props.store.tenders[index] = { amount: "0.00" };
    }
    if (this.props.store.tenders[index].amount == "") {
      this.props.store.tenders[index].amount = "0.00";
    }
    this.myInp.focus();
    this.myInp.select();
  };

  @action quickCash = (e, amount) => {
    if (this.props.store.tenders.length === 0)
      this.props.store.tenders = [{ amount: "0.00" }];
    this.props.store.tenders[0].amount = (
      parseFloat(this.props.store.tenders[0].amount) + amount
    ).toFixed(2);
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;

    return (
      <>
        <div className="modal display-block">
          {store.submittingCart ? (
            <Loader text="Submitting cart..." />
          ) : (
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Checkout</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={this.props.handleClose}
                >
                  <i className="fal fa-times"></i> Close
                </button>
              </header>
              <section className="modal-card-body">
                <div className="refresh" onClick={store.zeroTenders}>
                  <i className="far fa-redo"></i> Reset
                </div>
                <div className="wrapper">
                  <div className="tenderWrapper">
                    <span className="balance">
                      Balance to Pay:<em>{store.balance}</em>
                    </span>
                    <span className="tenderTitle">
                      Amount tendered ({this.props.posStore.currency}){" "}
                      <span className="tender">
                        <input
                          data-testid="tender-amount-input"
                          value={
                            store.tenders.length > store.activeTender
                              ? store.tenders[store.activeTender].amount
                              : 0.0
                          }
                          onChange={(e) =>
                            this.tenderUpdate(e, store.activeTender)
                          }
                          //onBlur={e => this.verifyAmounts()}
                          onFocus={(e) => this.selectAll(e, store.activeTender)}
                          ref={(ip) => (this.myInp = ip)}
                          type="textbox"
                        />
                      </span>
                    </span>
                    <div className="tenderAmounts">
                      <div className="row">
                        {store.tenders.map((tender, index) => (
                          <div
                            key={index}
                            className="col-xs-12 col-sm-12 col-md-6"
                          >
                            <div className="tenderBtn">
                              <input
                                value={
                                  store.tenders.length > index
                                    ? store.tenders[index].amount
                                    : null
                                }
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => this.tenderUpdate(e, index)}
                                disabled={
                                  !store.selectedCustomer &&
                                  tender.type == "Store Credit"
                                }
                              ></input>
                              <button
                                className="payment-button"
                                onClick={(e) => {
                                  this.setTender(e, index);
                                }}
                                key={index}
                                disabled={
                                  !store.selectedCustomer &&
                                  tender.type == "Store Credit"
                                }
                              >
                                {tender.type == "Credit"
                                  ? "Credit Card"
                                  : tender.type}
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <span className="tenderTitle quickPay">
                      Quick cash payment
                    </span>
                    <div className="quickBtns">
                      {store.cashDenoms.map((denom, index) => (
                        <button
                          key={index}
                          onClick={(e) => this.quickCash(e, denom)}
                          className="payment-button-cash"
                        >
                          {fC(denom)}
                        </button>
                      ))}
                      {store.changeDue ? (
                        <span className="changeDue">
                          Change Due: <em>{fC(store.changeDue)}</em>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot">
                <button
                  className="modalBtn cancel"
                  onClick={this.props.handleClose}
                >
                  <i className="fal fa-times"></i> Cancel
                </button>
                <button
                  key={!(store.balance == 0)}
                  className="modalBtn action"
                  disabled={disableFinalizeSale(
                    store.balance,
                    store.changeDue,
                    this.props.store.tenders
                  )}
                  onClick={store.processPayments}
                >
                  Finalize Sale <i className="fas fa-caret-right"></i>
                </button>
              </footer>
            </div>
          )}
        </div>
        {this.props.posStore.processingActive ? <CreditProcess /> : null}
      </>
    );
  }
}

export default Checkout;

import ApolloClient from "apollo-boost";
import { REPORTING_GRAPHQL_ENDPOINT } from "../constants/api";
import moment from "moment";
import { logRequest } from "../utils/api";
import { getUserToken } from "../helpers/authHelpers";

/**
 * Wrapper around 'fetch' for GET/POST, schema validation and error handling.
 */
export async function _fetch(props, isRetry) {
  const { method, endpoint, payload, signal } = props;
  let currentToken = await getUserToken(isRetry);
  try {
    const response = await fetch(endpoint, {
      credentials: "same-origin",
      method,
      body: payload ? JSON.stringify(payload) : null,
      signal,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      }),
    });
    logRequest(method, endpoint, response.status);
    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetch({ method, endpoint, payload }, true);
      }
      const error = await response.json();
      throw error;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    logRequest(method, endpoint, error);
    throw error;
  }
}

export async function _fetchWithFile({ endpoint, payload }, isRetry) {
  let currentToken = await getUserToken(isRetry);
  try {
    const response = await fetch(endpoint, {
      // credentials: "same-origin",
      method: "POST",
      body: payload,
      headers: new Headers({
        Authorization: `Bearer ${currentToken}`,
      }),
    });
    logRequest("POST", endpoint, response.status);
    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetchWithFile({ endpoint, payload }, true);
      }
      const error = await response.json();
      throw error;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    logRequest("POST", endpoint, error);
    throw error;
  }
}

export async function _fetchWithFormData(
  { method, endpoint, payload = undefined },
  isRetry
) {
  let currentToken = await getUserToken(isRetry);
  try {
    const response = await fetch(endpoint, {
      credentials: "same-origin",
      method,
      body: payload || null,
      enctype: "multipart/form-data",
      headers: new Headers({
        Authorization: `Bearer ${currentToken}`,
      }),
    });
    logRequest(method, endpoint, response.status);
    if (!response.ok && response.status != 201) {
      if (response.status == 401 && !isRetry) {
        return _fetchWithFormData({ method, endpoint, payload }, true);
      }
      const error = await response.json();
      throw error;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    logRequest(method, endpoint, error);
    throw error;
  }
}

export async function graphQlFetch(uri, options, isRetry) {
  let currentToken = await getUserToken(isRetry);
  options.headers.Authorization = `Bearer ${currentToken}`;
  return fetch(uri, options)
    .then((response) => {
      if (response.status !== 401) {
        return Promise.resolve(response);
      }
      logRequest("graphQL", uri, response.status);
      return getUserToken().then((newToken) => {
        // Only retry if token has changed
        const newAuthHeader = `Bearer ${newToken}`;
        if (
          newToken &&
          options.headers?.Authorization !== newAuthHeader &&
          !isRetry
        ) {
          currentToken = newToken;
          return graphQlFetch(uri, options, true);
        }
        return Promise.reject(response);
      });
    })
    .catch((error) => {
      logRequest("graphQL", uri, error);
      throw error;
    });
}

export async function _graphQLRequest(query, endpoint) {
  const client = new ApolloClient({
    uri: endpoint ?? REPORTING_GRAPHQL_ENDPOINT,
    fetch: graphQlFetch,
  });
  const response = await client.query({ query });
  return response.data;
}

/**
 * Converts an object of parameters to a query string.
 * Any parameters with an empty value are removed.
 * EndDate parameters are incremented by one day so that ranges include
 * the specified day.
 *
 * @param {object} params
 * @param {number} limit Default number of results to return
 *
 * @returns {string}
 */
export function paramsToQueryString(params = {}, limit = 20) {
  if (!params.limit) {
    params.limit = limit;
  }
  if (!params.offset) {
    params.offset = 0;
  }
  // Increment end date
  if (params.endDate) {
    params.endDate = moment(params.endDate)
      .add(1, "day")
      .format(moment.HTML5_FMT.DATE);
  }
  const queryString = Object.entries(params)
    .map(
      ([param, value]) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return queryString;
}

import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import BasicModal from "../../components/generic/BasicModal";
import ButtonComponent from "../../components/generic/ButtonComponent";
import ConfirmationModal from "../../components/generic/ConfirmationModal";
import Spinner from "../../components/generic/Spinner";
import { useFormatCurrency, useStoreDetails } from "../../hooks/storeHooks";
import { validateRequiredFields } from "../../utils/forms";
import StockChangeInfoContainer from "../../views/integrations/ebay/StockChangeInfoContainer";
import CFBFormError from "./CFBFormError";
import EbayListingForm from "./EbayListingForm";
import EbayListingFormError from "./EbayListingFormError";
import EbayResyncVariantButton from "./EbayResyncVariantButton";
import "./VariantSettingsForm.scss";

type VariantSyncSettings = {
  id?: number;
  variantId: number;
  syncVariant: boolean;
  reserveStock: number | "";
  maxToList: number | "";
  priceMarkup: number | "";
  priceOverride: number | "";
  ebayTitleTemplate?: string;
  ebayMobileTemplate?: string;
  ebayTemplate?: string;
  categoryId?: string;
  fulfilmentPolicyId?: string;
  merchantLocationKey?: string;
  paymentPolicyId?: string;
  returnPolicyId?: string;
  applyTax?: false;
  vatPercentage?: number | "";
};

const getMatchingVariantSyncSettings = (
  syncName: string,
  variantId: number,
  variantSyncSettings: VariantSyncSettings
) => {
  if (variantSyncSettings?.id !== undefined) {
    return variantSyncSettings;
  } else if (!Array.isArray(variantSyncSettings)) {
    return false;
  } else if (!syncName) {
    return false;
  }
  const syncSettings = variantSyncSettings.find(
    (variant) => variant.syncName === syncName
  );
  if (!syncSettings) return;
  delete syncSettings["variant"];
  syncSettings.variantId = variantId;
  Object.keys(syncSettings).forEach(
    (key) => (syncSettings[key] = syncSettings[key] || "")
  );
  return syncSettings;
};

const requiredFields = [] as string[];

const requiredEbayFields = [
  "ebayTitleTemplate",
  "ebayTemplate",
  "fulfilmentPolicyId",
  "merchantLocationKey",
  "paymentPolicyId",
  "returnPolicyId",
  "categoryName",
];

const getValidator = (integration: string) => (values: VariantSyncSettings) => {
  if (integration === "ebay") {
    return validateRequiredFields(
      [...requiredFields, ...requiredEbayFields],
      values
    );
  }
  if (integration === "cfbmarket") {
    return cfbValidator(requiredFields, values);
  }
  return validateRequiredFields(requiredFields, values);
};

const cfbValidator = (
  requiredFields: string[],
  values: VariantSyncSettings
) => {
  const errors = validateRequiredFields(requiredFields, values) as any;
  if (values.reserveStock !== "" && !Number.isInteger(values.reserveStock)) {
    errors.reserveStock = "Reserve stock should be a valid integer";
  }
  if (values.maxToList !== "" && !Number.isInteger(values.maxToList)) {
    errors.maxToList = "Maximum list should be a valid integer";
  }
  if (values.maxToList !== "" && values.maxToList < 0) {
    errors.maxToList = "Maximum list should be greater than or equal to zero";
  }
  if (values.priceOverride !== "" && values.priceOverride < 0.01) {
    errors.priceOverride = "Price overide must be greater than 0.01";
  }
  if (values.priceMarkup !== "" && values.priceMarkup <= -99) {
    errors.priceMarkup =
      "Negative markups can result in $0 price. Try a positive or higher number.";
  }
  return errors;
};

interface VariantSettingsFormProps {
  syncName: string;
  variant: {
    id: number;
    title: string;
    price: number;
    quantity: number;
    variantSyncSettings: VariantSyncSettings;
  };
  saveVariant: Function;
  resetVariant: Function;
}

const percentToDecimal = (setting: VariantSyncSettings) => {
  if (!setting?.priceMarkup) return setting;
  return {
    ...setting,
    priceMarkup: Number(setting.priceMarkup) / 100,
  };
};

const decimalToPercent = (setting: VariantSyncSettings) => {
  if (!setting?.priceMarkup) return setting;
  return {
    ...setting,
    priceMarkup: Number(setting.priceMarkup) * 100,
  };
};

function VariantSettingsForm(props: VariantSettingsFormProps) {
  const { currencySymbol } = useStoreDetails();
  const { syncName, variant, saveVariant, resetVariant } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showStockChangeModal, setShowStockChangeModal] = useState(false);
  const initialValues = {
    variantId: variant?.id,
    syncName: syncName,
    syncVariant: false,
    reserveStock: "",
    maxToList: "",
    priceMarkup: "",
    priceOverride: "",
    ebayTitleTemplate: "",
    ebayMobileTemplate: "",
    ebayTemplate: "",
    // categoryId: "",
    categoryName: "",
    fulfilmentPolicyId: "",
    merchantLocationKey: "",
    paymentPolicyId: "",
    returnPolicyId: "",
    ebayAttributes: null,
    applyTax: false,
    vatPercentage: "",
  } as VariantSyncSettings;

  const listingFields = [
    "ebayTitleTemplate",
    "ebayTemplate",
    "fulfilmentPolicyId",
    "merchantLocationKey",
    "paymentPolicyId",
    "returnPolicyId",
    "categoryName",
  ];

  const validate = getValidator(syncName);

  const { price, quantity } = variant;

  return (
    <div className="variant-line">
      <Formik
        initialValues={
          decimalToPercent(
            getMatchingVariantSyncSettings(
              syncName,
              variant?.id,
              variant?.variantSyncSettings
            )
          ) || initialValues
        }
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveVariant(percentToDecimal(values), setSubmitting)
        }
      >
        {({
          setFieldValue,
          setSubmitting,
          values,
          isSubmitting,
          isValid,
          handleReset,
          errors,
        }) => (
          <Form>
            <Spinner isLoading={isSubmitting}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-2 col-md-12">
                    <div className="variantTitle">{variant.title}</div>
                    <div className="inventory">
                      {`${useFormatCurrency(price)} Quantity: ${quantity}`}
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12">
                    <div className="row">
                      <div className="col-lg-1 col-md-12">
                        <div className="field center-input">
                          <div className="control">
                            <span className="md-only">Enable Sync:</span>
                            <label className="checkbox">
                              <Field type="checkbox" name="syncVariant" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-12 centered">
                        <label htmlFor="priceMarkup" className="md-only">
                          Price Markup Percentage:
                        </label>
                        <Field
                          className="form-control percentage"
                          type="number"
                          name="priceMarkup"
                        />
                        %
                      </div>
                      <div className="col-lg-2 col-md-12 centered">
                        <label htmlFor="reserveStock" className="md-only">
                          Reserve Stock:
                        </label>
                        <Field
                          className="form-control"
                          type="number"
                          name="reserveStock"
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 centered">
                        <label htmlFor="maxToList" className="md-only">
                          Maximum to list on {syncName}:
                        </label>
                        <Field
                          className="form-control"
                          type="number"
                          name="maxToList"
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 centered">
                        <label htmlFor="priceOverride" className="md-only">
                          Price Override:
                        </label>
                        {currencySymbol}
                        <Field
                          className="form-control currencySymbol"
                          type="number"
                          name="priceOverride"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-9">
                    {syncName === "cfbmarket" ? (
                      <CFBFormError errors={errors} />
                    ) : null}
                    {syncName === "ebay" ? (
                      <div className="row">
                        <div>
                          <ButtonComponent
                            secondary
                            className="btn2"
                            onClick={() => setShowDescriptionModal(true)}
                          >
                            Edit Listing
                          </ButtonComponent>
                        </div>{" "}
                        {variant.id ? (
                          <ButtonComponent
                            className="btn--info"
                            tabIndex={-1}
                            onClick={() => setShowStockChangeModal(true)}
                            disabled={isSubmitting}
                          >
                            i
                          </ButtonComponent>
                        ) : null}
                        <EbayListingFormError
                          errors={errors}
                          listingFields={listingFields}
                        />
                      </div>
                    ) : null}
                    {syncName === "cfbmarket" &&
                    Object.keys(errors).length ? null : (
                      <div className="row">
                        <ButtonComponent
                          className="btn1"
                          tabIndex={-1}
                          type="submit"
                          disabled={isSubmitting || !isValid}
                        >
                          {variant.id ? "Update" : "Save"}
                        </ButtonComponent>
                        {variant.id && (
                          <>
                            <ButtonComponent
                              className="btn1"
                              tabIndex={-1}
                              onClick={() => setShowConfirmationModal(true)}
                              disabled={isSubmitting}
                            >
                              Reset
                            </ButtonComponent>
                            <EbayResyncVariantButton
                              integration={syncName}
                              variantId={variant.id}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Spinner>
            {showConfirmationModal ? (
              <ConfirmationModal
                type="confirm"
                title="Are you sure?"
                text="Resetting this variant cannot be undone"
                cancelAction={() => setShowConfirmationModal(false)}
                confirmAction={() => {
                  setShowConfirmationModal(false);
                  resetVariant(variant.id, setSubmitting, handleReset);
                }}
              />
            ) : null}
            {showDescriptionModal ? (
              <BasicModal
                title="Descriptions"
                onClose={() => setShowDescriptionModal(false)}
              >
                <>
                  <div className="variant-settings-form__reminder">
                    Any changes made here need to be saved by clicking the
                    &quot;Update&quot; button on the variant settings.
                  </div>
                  <EbayListingForm
                    integration={syncName}
                    game="mtg"
                    applyTax={values.applyTax}
                    vatPercentage={values.vatPercentage}
                    setFieldValue={setFieldValue}
                  />
                </>
              </BasicModal>
            ) : null}
            {showStockChangeModal ? (
              <BasicModal
                title="Stock Changes"
                onClose={() => setShowStockChangeModal(false)}
              >
                <StockChangeInfoContainer id={variant.id} />
              </BasicModal>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default VariantSettingsForm;

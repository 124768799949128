import firebase from "firebase/app";
import "firebase/auth";
import { deleteUserToken } from "../helpers/authHelpers";
import { getFirebaseConfig } from "../utils/ConfigUtils";

if (!firebase.apps.length) {
  firebase.initializeApp(getFirebaseConfig());
} else {
  firebase.app();
}

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const signOut = () => auth.signOut().then(deleteUserToken);

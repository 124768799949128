import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Loader from "../generic/Loader";
import ConfirmationModal from "../generic/ConfirmationModal";
import CartLine from "./CartLine";

@inject("posStore", "store")
@observer
class KioskCart extends Component {
  async componentDidMount() {
    //if the component mounts while already logged into a till
    //load tax, latest cart, and quicklinks
    //otherwise we expect the float modal to be active and will handle
    //this logic once log in is complete
    if (!this.props.posStore.floatStatus) return;
    await this.props.posStore.getTax();
    await this.props.posStore.getLatestCart();
    await this.props.posStore.getQuickLinkData();
    await this.props.posStore.getCustomFields();
  }

  state = { saveCartModal: false };

  showSaveCartModal = (visible) => this.setState({ saveCartModal: visible });

  saveCart = () => {
    this.props.posStore.newCart();
    this.showSaveCartModal(false);
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;
    const roundCents = this.props.posStore.roundCents;

    return (
      <React.Fragment>
        <div className="cartNumber">Cart #{this.props.posStore.cartId}</div>
        <div
          className={
            this.props.posStore.disableLineItems ? " cart disable" : "cart"
          }
        >
          {this.props.store.gettingLatestCart ? (
            <Loader text="Loading cart..." />
          ) : (
            <div className="cart-items">
              {store.cart.map((item, index) => (
                <CartLine store={store} item={item} key={index} />
              ))}
            </div>
          )}
          {/*This div is used as a reference to autoscroll to the bottom*/}
          <div style={{ float: "left", clear: "both" }}></div>
          <div className="cartTotals">
            <span className="subTotal">
              Subtotal ({store.totalItems} Item)
              <em>{fC(roundCents(store.subTotal))}</em>
            </span>
            {/* <span className="discount">Add discount</span> */}
            <span className="tax">
              Tax ({store.taxRateDisplay}%)
              {store.negatedTaxTotal ? (
                <span className="taxNegated">
                  {"("} {fC(roundCents(store.negatedTaxTotal))}{" "}
                  {"negated by trade ins)"}
                </span>
              ) : (
                ""
              )}
              <em>{fC(roundCents(store.taxTotal))}</em>
            </span>
            <span className="total">
              Total<em>{fC(roundCents(store.total))}</em>
            </span>
          </div>
          <button
            className="buy-button"
            type="button"
            onClick={() => this.showSaveCartModal(true)}
          >
            Submit <i className="fas fa-caret-right"></i>
          </button>
        </div>
        {this.state.saveCartModal ? (
          <ConfirmationModal
            text={
              "Are you sure you want to submit this cart? Once you submit it, please notify staff you are ready to pick up your order. Confirmation number is #" +
              this.props.posStore.cartId
            }
            cancelAction={() => this.showSaveCartModal(false)}
            confirmAction={this.saveCart}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default KioskCart;

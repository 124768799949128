import React from "react";
import PropTypes from "prop-types";

function Spinner(props) {
  if (!props.isLoading) {
    return props.children;
  }

  return (
    <div className="spinner-container">
      <div className="spinner-overlay">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {props.children}
    </div>
  );
}

Spinner.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Spinner;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";

@inject("posStore")
@observer
class CloseTillWarning extends Component {
  render() {
    const store = this.props.posStore;
    const currentTill =
      store.tillList.find((till) => till.id == store.tillId)?.name || "till";

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Close {currentTill}</p>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <p>Are you sure you want to close this till?</p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              className="modalBtn cancel"
              onClick={store.closeTillWarning}
            >
              <i className="fal fa-times" /> Cancel
            </button>
            <button className="modalBtn action" onClick={store.closeFloat}>
              OK
              <i className="fas fa-caret-right" />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default CloseTillWarning;

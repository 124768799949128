const getFirebaseConfig = () => {
  const environment = process?.env?.REACT_APP_ENV;
  if (["production", "beta"].includes(environment)) {
    return {
      apiKey: "AIzaSyDYmFPRKRkVFM9SQvbtCAT8oWKh4RhBGXg",
      authDomain: "hobby-pos.firebaseapp.com",
      projectId: "hobby-pos",
    };
  }
  if (environment === "staging") {
    return {
      apiKey: "AIzaSyB3bAHolLjUADKG8WrojoRR8RvxeysKsrM",
      authDomain: "binderpos-staging.firebaseapp.com",
      projectId: "binderpos-staging",
    };
  }
  return {
    apiKey: "AIzaSyA7h34WfYXmkN4lu_kWhqhYa_6LYwXu2oQ",
    authDomain: "binderpos-dev.firebaseapp.com",
    projectId: "binderpos-dev",
  };
};

export { getFirebaseConfig };

import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Spinner } from "../../components";

function AddTill(props) {
  const { isLoading, addNewTill, setAddNewTillModalVisible } = props;
  const formRef = createRef();

  const onSubmit = (event) => {
    event.preventDefault();
    const { elements } = formRef.current;
    const settings = {
      name: elements.tillName.value,
      description: elements.tillDescription.value,
    };
    addNewTill(settings);
  };
  return (
    <div className="modal till is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <Spinner isLoading={isLoading}>
          <form ref={formRef} onSubmit={onSubmit} noValidate>
            <header className="modal-card-head">
              <p className="modal-card-title">Add a new Till / Register</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setAddNewTillModalVisible(false)}
              >
                <i className="fal fa-times" /> Close
              </button>
            </header>
            <section className="modal-card-body">
              <div className="wrapper">
                <div className="field">
                  <label className="label">Till / Register name</label>
                  <div className="control">
                    <input
                      id="tillName"
                      className="input"
                      type="text"
                      required
                      placeholder="Register 1"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Till / Register description</label>
                  <div className="control">
                    <textarea
                      id="tillDescription"
                      className="input"
                      placeholder="My awesome front of house register"
                    />
                  </div>
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                type="button"
                className="modalBtn cancel"
                onClick={() => setAddNewTillModalVisible(false)}
              >
                <i className="fal fa-times" /> Cancel
              </button>
              <button className={`modalBtn action`} disabled={isLoading}>
                Add <i className="fas fa-caret-right" />
              </button>
            </footer>
          </form>
        </Spinner>
      </div>
    </div>
  );
}

AddTill.propTypes = {
  isLoading: PropTypes.bool,
  addNewTill: PropTypes.func.isRequired,
  setAddNewTillModalVisible: PropTypes.func.isRequired,
};

export default AddTill;

const SettingsMenuStructure = {
  title: "Settings",
  activeClass: "orange",
  links: [
    {
      title: "Store settings",
      href: "settings/store",
    },
    {
      title: "Pricing settings",
      href: "settings/pricing",
    },
    {
      title: "Timezone settings",
      href: "settings/timezone",
    },
    {
      title: "Report settings",
      href: "settings/reports",
    },
    {
      title: "Users",
      href: "settings/users/list",
    },
    {
      title: "Add user",
      href: "settings/users/add",
    },
  ],
};

export default SettingsMenuStructure;

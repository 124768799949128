import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";

export async function fetchCarts() {
  return await _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/pos/carts/all?limit=100&offset=0&oldestFirst=false&submitted=false`,
  });
}

export const fetchSearchResults = async (
  query,
  includeSingles,
  buyMode,
  offset = 0,
  limit = 50
) =>
  _fetch({
    endpoint:
      `${BASE_URL}/products?` +
      `limit=${limit}` +
      `&offset=${offset}` +
      `&keyword=${encodeURIComponent(query)}` +
      `&includeSingles=${includeSingles.toString()}` +
      `&includeBuyprice=${buyMode.toString()}`,
    method: "GET",
  });

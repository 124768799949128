import React from "react";
import { EbayStockChangeInfo as EbayStockChangeInfoProps } from "../../api/rest/ebay";
import "./EbayStockChangeInfo.scss";
import TimeUtils from "../../utils/TimeUtils";

function EbayStockChangeInfo(props: EbayStockChangeInfoProps) {
  const {
    lastUpdated,
    success,
    binderProductId,
    binderSkuId,
    offerId,
    listingId,
    ebayCondition,
    name,
    mobileDescription,
    description,
    imageUrl,
    newPrice,
    newStockLevel,
    oldPrice,
    oldStockLevel,
    allErrors,
  } = props;

  const statusClass = success
    ? "ebay-stock-change-info__status--success"
    : "ebay-stock-change-info__status--problem";

  return (
    <div className="ebay-stock-change-info">
      <div className="ebay-stock-change-info__header">
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Status:</span>
          <span className={statusClass}>{success ? "Success" : "Error"}</span>
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Last Updated:</span>
          {TimeUtils.convertDateToHumanReadable(lastUpdated)}
        </div>
      </div>

      {allErrors ? (
        <div className="ebay-stock-change-info__error-list">
          {[...allErrors]
            .sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))
            .map((allError) =>
              allError.errors ? (
                <div className="ebay-stock-change-info__error-group">
                  <span className="ebay-stock-change-info__error-date">
                    {TimeUtils.convertDateToHumanReadable(allError.lastUpdated)}
                  </span>
                  {allError.errors.map((error) => (
                    <div
                      key={error.errorId}
                      className="ebay-stock-change-info__error"
                    >
                      {error.longMessage ? error.longMessage : error.message}
                      {error.parameters ? (
                        <div className="ebay-stock-change-info__error-params">
                          {error.parameters.map((param) => (
                            <pre key={param.name}>
                              Name {param.name}: Value {param.value}
                            </pre>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null
            )}
        </div>
      ) : null}

      <div className="ebay-stock-change-info__section">
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Old Price:</span>
          {oldPrice}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">New Price:</span>
          {newPrice}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">
            Old Stock Level:
          </span>
          {oldStockLevel}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">
            New Stock Level:
          </span>
          {newStockLevel}
        </div>

        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">
            BinderPOS Product Id:
          </span>
          {binderProductId}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">BinderPOS Sku:</span>
          {binderSkuId}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Offer Id:</span>
          {offerId}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Listing Id:</span>
          {listingId}
        </div>
        <div className="ebay-stock-change-info__item">
          <span className="ebay-stock-change-info__label">Ebay Condition:</span>
          {ebayCondition}
        </div>
      </div>
      <div className="ebay-stock-change-info__section--wide">
        <div className="ebay-stock-change-info__listing">
          <div className="ebay-stock-change-info__item">
            <span className="ebay-stock-change-info__label">Listing:</span>
            {name}
          </div>
          <div className="ebay-stock-change-info__item">
            <span className="ebay-stock-change-info__label">
              Mobile Description:
            </span>
            {mobileDescription}
          </div>
          <div className="ebay-stock-change-info__item">
            <span className="ebay-stock-change-info__label">Description:</span>
            {description}
          </div>
        </div>
        <div className="ebay-stock-change-info__image">
          {imageUrl ? <img src={imageUrl} alt="" /> : "No image"}
        </div>
      </div>
    </div>
  );
}

export default EbayStockChangeInfo;

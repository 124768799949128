import React from "react";
import { Link } from "react-router-dom";

interface CustomerConfirmationModalProps {
  isUpdate: boolean;
  customerTitle: string;
  isPos: boolean;
  handleClose: () => void;
}

function CustomerConfirmationModal(props: CustomerConfirmationModalProps) {
  const { isUpdate, customerTitle, isPos, handleClose } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {isUpdate ? "Customer has been updated" : "Customer has been added"}
          </p>
        </header>
        <section>
          {isUpdate
            ? `${customerTitle} has been updated successfully.`
            : `${customerTitle} has been added.`}
        </section>
        <footer className="modal-card-foot">
          <>
            {isPos ? (
              <>
                <button
                  type="button"
                  className="modalBtn action full"
                  onClick={handleClose}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <Link to="/customers" className="modalBtn action full">
                  Ok
                </Link>
              </>
            )}
          </>
        </footer>
      </div>
    </div>
  );
}

export default CustomerConfirmationModal;

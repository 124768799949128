import React, { FormEvent, useRef, useState } from "react";
import { adjustCustomerCredit } from "../../api/rest/customers";
import { useShowError } from "../../hooks/errorHooks";

type AdjustmentType = "increase" | "decrease" | "overwrite";

export interface AdjustStoreCreditModalProps {
  customer: Customer;
  onComplete: () => void;
  handleClose: () => void;
}

function AdjustStoreCreditModal(props: AdjustStoreCreditModalProps) {
  const { customer, onComplete, handleClose } = props;
  const formRef = useRef(null);
  const showError = useShowError();
  const [isUpdating, setIsUpdating] = useState(false);
  const [adjustmentType, setAdjustmentType] =
    useState<AdjustmentType>("increase");

  const handleAdjustmentTypeChange = (newType: AdjustmentType) =>
    setAdjustmentType(newType);

  const adjustStoreCredit = (event: FormEvent) => {
    event.preventDefault();

    // @ts-ignore
    const elm = formRef.current?.elements;
    const toUpdate = {
      customerId: customer.id,
      amountToAdjust: elm.adjustPrice.value,
      typeOfAdjustment: adjustmentType,
      publicNotes: elm.publicNotes.value,
      privateNotes: elm.privateNotes.value,
    };

    adjustCustomerCredit(toUpdate)
      .then(() => {
        setIsUpdating(false);
        onComplete();
      })
      .catch((error: DetailedError) => {
        setIsUpdating(false);
        showError(
          error,
          "Failed to update customer",
          "There was an error submitting the adjusted store credit. Please try again"
        );
      });
  };

  const adjustText = {
    increase: "Enter how much you want to add to their store credit.",
    decrease: "Enter how much you want to remove from their store credit.",
    overwrite:
      "Enter the amount of store credit they have. This will overwrite the current value.",
  };

  return (
    <div className="adjustStoreCreditModal modal is-active">
      <div className="modal-background" />
      <div className="modal-card storecredit">
        <form ref={formRef} onSubmit={adjustStoreCredit} noValidate>
          <header className="modal-card-head">
            <p className="modal-card-title">
              Adjust store credit for {customer.firstName} {customer.lastName}
            </p>
            <button className="delete" aria-label="close" onClick={handleClose}>
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            <div className="wrapper">
              <div className="field">
                <span className="select">
                  <label className="label" htmlFor="adjustType">
                    Adjustment type:
                  </label>
                  <select
                    id="adjustType"
                    onChange={(event) =>
                      handleAdjustmentTypeChange(
                        event.target.value as AdjustmentType
                      )
                    }
                    value={adjustmentType}
                  >
                    <option value="increase">Increase</option>
                    <option value="decrease">Decrease</option>
                    <option value="overwrite">Overwrite</option>
                  </select>
                </span>
              </div>
              <div className="field">
                <label className="label">{adjustText[adjustmentType]}</label>
                <div className="control has-icons-left">
                  <input
                    data-testid="adjustmentValue"
                    id="adjustPrice"
                    className="input"
                    type="number"
                    min="0"
                    required
                    placeholder="E.g. 29.95"
                    step=".01"
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="publicNotes" className="label">
                  Public Notes
                </label>
                <div className="control">
                  <textarea
                    id="publicNotes"
                    className="input"
                    rows={5}
                    placeholder="E.g. Won tournament and $50.00 store credit was applied"
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="privateNotes" className="label">
                  Internal Notes
                </label>
                <div className="control">
                  <textarea
                    id="privateNotes"
                    className="input"
                    rows={5}
                    placeholder="E.g. Customer used $20.00 store credit while the internet was not working, correcting their store credit."
                  />
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="modalBtn cancel"
              onClick={handleClose}
            >
              <i className="fal fa-times" /> Cancel
            </button>
            <button disabled={isUpdating} className="modalBtn action">
              Adjust <i className="fas fa-caret-right" />
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
}

export default AdjustStoreCreditModal;

import { _fetch } from "../../api";
import { BASE_URL, EBAY_BASE_URL } from "../../constants/api";

export type EbayParam = {
  code: string;
  description?: string;
};

export type EbaySettingBase = {
  id?: number;
  name: string;
};

type Address = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  county?: string;
  postalCode: string;
  stateOrProvince: string;
  country: string;
};

type TimeDuration =
  | "YEAR"
  | "MONTH"
  | "DAY"
  | "HOUR"
  | "CALENDAR_DAY"
  | "BUSINESS_DAY"
  | "MINUTE"
  | "SECOND"
  | "MILLISECOND";

type TimePeriod = {
  unit: TimeDuration;
  value: number;
};

export type ShippingService = {
  description?: string;
  additionalShippingCost?: number;
  freeShipping: boolean;
  shippingCost: number;
  shippingServiceName: string;
  ebayTypeShippingServiceId: {
    shippingServiceId: number;
    marketplace: string;
  };
  carrierName: string;
  ebayTypeShippingCarrierId: {
    carrierId: number;
    marketplace: string;
  };
  sortOrder: number;
  shipToLocationsIncluded?: string[];
  shipToLocationsExcluded?: string[];
};

export type ShippingServiceProvider = {
  ebayTypeShippingServiceId: {
    shippingServiceId: number;
    marketplace: string;
  };
  serviceTypes: string[];
  description: string;
  serviceName: string;
  shippingCarriers?: string[];
  shippingPackages?: string[];
  shippingCategory: string;
  shippingTimeMax?: number;
  shippingTimeMin?: number;
  internationalShipping: boolean;
  weightRequired: boolean;
  dimensionsRequired: boolean;
  surchargeApplicable: boolean;
  expeditedService: boolean;
};

export type ShippingOption = {
  costType: "CALCULATED" | "FLAT_RATE" | "NOT_SPECIFIED";
  optionType: "DOMESTIC" | "INTERNATIONAL";
  packageHandlingCost: number;
  shippingServices?: ShippingService[];
};

export interface EbayLocation extends EbaySettingBase {
  locationTypes?: string[];
  location: {
    address: Address;
  };
  locationAdditionalInformation?: string;
  locationInstructions?: string;
  locationWebUrl?: string;
  phone?: string;
}

export interface EbayPayment extends EbaySettingBase {
  immediatePay: boolean;
  paymentPolicyId: string;
  policyUrl?: string;
  paymentInstructions: string;
  paymentMethods: string[];
  paypalEmail?: string;
  paymentInstrumentBrands?: string[];
}

export interface EbayReturn extends EbaySettingBase {
  description: string;
  refundMethod: string;
  returnsAccepted: boolean;
  returnShippingCostPayer?: "SELLER" | "BUYER";
  returnPeriod?: TimePeriod;
  internationalOverride_returnsAccepted: boolean;
  internationalOverride_returnShippingCostPayer?: "SELLER" | "BUYER";
  internationalOverride_returnPeriod?: TimePeriod;
}

export interface EbayFulfillment extends EbaySettingBase {
  description: string;
  freightShipping: boolean;
  localPickup: boolean;
  handlingTime: TimePeriod;
  regionsIncluded: string[];
  regionsExcluded: string[];
  shippingOptions: ShippingOption[];
}

export interface EbayTemplate {
  id?: number;
  name: string;
  template: string;
}

export type EbayCategoryType = {
  category?: EbayCategoryType;
  categoryId: number;
  categoryName: string;
  categoryTreeNodeLevel?: number;
  categorySubtreeNodeHref?: string;
  categoryTreeNodeAncestors?: EbayCategoryType[];
};

export interface EbayCategoryTree {
  categoryTreeId: number;
  categoryTreeVersion: number;
  categorySuggestions: EbayCategoryType[];
}

export type EbayStockChangeError = {
  parameters: [
    {
      name: string;
      value: string;
    }
  ];
  inputRefIds: string[];
  outputRefIds: string[];
  errorId: number;
  category: string;
  domain: string;
  message: string;
  longMessage: string;
  subdomain: string;
};

export type EbayStockChangeAllErrors = {
  lastUpdated: string;
  errors: EbayStockChangeError[];
};

export type EbayStockChangeInfo = {
  lastUpdated: string;
  binderProductId: number;
  binderSkuId: number;
  description: string;
  name: string;
  imageUrl: string;
  listingId: string;
  mobileDescription: string;
  newPrice: number;
  newStockLevel: number;
  offerId: string;
  oldPrice: number;
  oldStockLevel: number;
  success: boolean;
  allErrors: EbayStockChangeAllErrors[];
  ebayCondition: string;
};

export type EbayOrder = {
  binderOrderId: string;
  createOn: number;
  lastUpdated: number;
  customerEmail: DOMStringList;
  id: number;
  orderId: string;
  status: string;
  total: number;
};

type EbayCurrencyAmount = {
  convertedFromCurrency?: string;
  convertedFromValue: string;
  currency: string;
  value: string;
};

type EbayLineItem = {
  appliedPromotions: unknown[];
  deliveryCost: {
    importCharges?: number;
    shippingCost: EbayCurrencyAmount;
    shippingIntermediationFee?: number;
  };
  discountedLineItemCost?: number;
  ebayCollectAndRemitTaxes: [
    {
      amount: EbayCurrencyAmount;
      taxType: string;
      collectionMethod: string;
    }
  ];
  giftDetails?: string;
  legacyItemId: string;
  legacyVariationId: string;
  lineItemCost: EbayCurrencyAmount;
  lineItemFulfillmentInstructions: {
    destinationTimeZone?: string;
    guaranteedDelivery: boolean;
    maxEstimatedDeliveryDate: number;
    minEstimatedDeliveryDate: number;
    shipByDate: number;
    sourceTimeZone?: string;
  };
  lineItemFulfillmentStatus: string;
  lineItemId: string;
  listingMarketplaceId: string;
  properties: {
    buyerProtection: boolean;
    fromBestOffer?: unknown;
    soldViaAdCampaign?: unknown;
  };
  purchaseMarketplaceId: string;
  quantity: number;
  refunds?: unknown;
  sku: string;
  soldFormat: string;
  taxes: unknown[];
  title: string;
  total: EbayCurrencyAmount;
};

export type EbayOrderDetails = {
  buyer: {
    taxAddress: unknown;
    username: string;
  };
  buyerCheckoutNotes?: string;
  cacnelStatus: {
    cancelState: string;
    cancelledDate?: string;
    cancelRequests: unknown[];
  };
  creationDate: number;
  ebayCollectAndRemitTax: boolean;
  fulfillmentHrefs: string[];
  fulfillmentStartInstructions: unknown[];
  lastModifiedDate: number;
  legacyOrderId: string;
  lineItems: EbayLineItem[];
  orderFulfillmentStatus: string;
  orderId: string;
  orderPaymentStatus: string;
  paymentSummary: unknown;
  pricingSummary: {
    adjustmnet?: unknown;
    deliveryCost: EbayCurrencyAmount;
    priceSubtotal: EbayCurrencyAmount;
    tax?: EbayCurrencyAmount;
    total: EbayCurrencyAmount;
  };
  salesRecordReference: string;
  sellerId: string;
  totalFeeBasisAmount: EbayCurrencyAmount;
  totalMarketplaceFee: EbayCurrencyAmount;
};

export const fetchCustomerEbayProvisioningDetails = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer`,
  });

export const fetchLocationSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/location`,
  });

export const fetchPaymentSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/payment`,
  });

export const fetchReturnSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/return`,
  });

export const fetchFulfillmentSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/fulfillment`,
  });

export const fetchMarketplaces = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/marketplaces`,
  });

export const fetchCurrencies = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/currencyCodes`,
  });

export const fetchLocales = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/locales`,
  });

export const fetchConditions = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/conditions`,
  });

export const provisionStore = (
  customerName: string,
  marketplace: string,
  currency: string,
  locale: string
) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer`,
    payload: {
      customerName,
      defaultMarketplace: marketplace,
      defaultCurrency: currency,
      defaultLocale: locale,
    },
  });

export const updateLocation = (location: EbayLocation) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/location/`,
    payload: location,
  });

export const deleteLocation = (locationId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/customer/settings/location/${locationId}`,
  });

export const updatePayment = (payment: EbayPayment) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/payment/`,
    payload: payment,
  });

export const deletePayment = (paymentId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/customer/settings/payment/${paymentId}`,
  });

export const updateReturn = (payload: EbayReturn) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/return/`,
    payload,
  });

export const deleteReturn = (returnId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/customer/settings/return/${returnId}`,
  });

//--

export const updateFulfillment = (payload: EbayFulfillment) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/fulfillment/`,
    payload,
  });

export const deleteFulfillment = (fulfillmentId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/customer/settings/fulfillment/${fulfillmentId}`,
  });
//--

export const fetchAuthUrl = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/auth/auth-url`,
  });

export const authCallback = (code: string, state: string, expiresIn: number) =>
  _fetch({
    method: "POST",
    endpoint: `${EBAY_BASE_URL}/public/auth/callback`,
    payload: {
      code,
      state,
      expires_in: expiresIn,
    },
  });

export const fetchShippingServices = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/shipping/services`,
  });

export const fetchLocationTypes = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/locationTypes`,
  });

export const fetchPaymentMethods = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/paymentMethods`,
  });

export const fetchPaymentBrands = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/paymentBrands`,
  });

export const fetchRefundMethods = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/refundMethods`,
  });

export const fetchCountries = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/countryCodes`,
  });

export const fetchTimeDuraitons = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/timeDuration`,
  });

export const fetchIncludeRegions = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/includeRegions`,
  });

export const fetchExcludeRegions = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/excludeRegions`,
  });

export const fetchShippingOptionTypes = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/fulfillment/shippingOptionTypes`,
  });

export const fetchTemplates = () =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/integrations/forMe/ebayTemplates`,
  });

export const fetchTemplateDetails = (templateId: number) =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/integrations/forMe/ebayTemplate/${templateId}`,
  });

export const updateTemplate = (payload: EbayTemplate) =>
  _fetch({
    method: "PUT",
    endpoint: `${BASE_URL}/integrations/forMe/ebayTemplate`,
    payload,
  });

export const deleteTemplate = (templateId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/integrations/forMe/ebayTemplate/${templateId}`,
  });

export const fetchWeightMeasurementUnits = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/weightMeasurementUnits`,
  });

export const fetchLenghtMeasurementUnits = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/lengthMeasurementUnits`,
  });

export const fetchPackageTypes = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/packageTypes`,
  });

export const fetchCategories = (query: string) =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/categories?q=${query}`,
  });

export const fetchFromEbayLocationSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/fromEbay/setting/location`,
  });

export const fetchFromEbayPaymentSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/fromEbay/setting/payment`,
  });

export const fetchFromEbayReturnSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/fromEbay/setting/return`,
  });

export const fetchFromEbayFulfillmentSettings = () =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/fromEbay/setting/fulfillment`,
  });

export const fetchEbayStockChangeInfo = (
  ids: string,
  successful: string,
  orderDir: string,
  offset = 0,
  limit = 25
) => {
  console.log(`ids; ${ids}, successful: ${successful}`);
  let url = `${EBAY_BASE_URL}/binder/customer/stockChangeInfo?offset=${offset}&limit=${limit}`;
  if (ids) url += `&ids=${ids}`;
  if (successful) url += `&successful=${successful}`;
  if (orderDir) url += `&orderDir=${orderDir}`;
  return _fetch({
    method: "GET",
    endpoint: url,
  });
};

export const fetchEbayStockChangeInfoBySku = (id: number) =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/stockChangeInfo/bySku/${id}`,
  });

export const fetchOrders = (offset = 0, limit = 25, properties?: string) =>
  _fetch({
    method: "GET",
    endpoint:
      `${EBAY_BASE_URL}/binder/customer/orders` +
      `?offset=${offset}&limit=${limit}&properties=${properties}`,
  });

export const fetchOrderDetails = (ebayOrderTxId: number) =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/order/byEbayOrderId/${ebayOrderTxId}`,
  });

// -----

export const fetchServiceSettings = (): Promise<EbayServiceSetting[]> =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/categories/serviceSettings`,
  });

export const fetchServiceSettingsData = (): Promise<EbayServiceSettingData[]> =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/service`,
  });

export const fetchServiceSettingById = (
  settingId: number
): Promise<EbayServiceSettingData> =>
  _fetch({
    method: "GET",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/service/${settingId}`,
  });

export const deleteServiceSetting = (settingId: number) =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/service/${settingId}`,
  });

export const updateServiceSetting = (payload: EbayServiceSettingData) =>
  _fetch({
    method: "PUT",
    endpoint: `${EBAY_BASE_URL}/binder/customer/setting/service`,
    payload,
  });

export const syncAllInventory = () =>
  _fetch({
    method: "POST",
    endpoint: `${EBAY_BASE_URL}/binder/customer/inventory/sync`,
  });

export const syncVariantInventory = (variantId: string | number) =>
  _fetch({
    method: "POST",
    endpoint: `${EBAY_BASE_URL}/binder/variant/${variantId}/inventory/sync`,
  });

export const reauthEbay = () =>
  _fetch({
    method: "DELETE",
    endpoint: `${EBAY_BASE_URL}/binder/auth/reAuth/customer/`,
  });

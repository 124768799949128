import React from "react";
import "./Loader.scss";
import Loader1 from "../../../assets/img/loader1.jpg";
import Loader2 from "../../../assets/img/loader2.jpg";
import Loader3 from "../../../assets/img/loader3.jpg";
import Loader4 from "../../../assets/img/loader4.jpg";
import Loader5 from "../../../assets/img/loader5.jpg";

interface LoaderProps {
  text?: string;
  delay?: number;
}

function Loader(props: LoaderProps) {
  const { text } = props;
  return (
    <div className="customLoader" data-testid="loader">
      <div className="customLoader__cards">
        <div className="customLoader__cardWrapper">
          <img
            src={Loader1}
            className="customLoader__card customLoader__card--first"
          />
        </div>
        <div className="customLoader__cardWrapper">
          <img
            src={Loader2}
            className="customLoader__card customLoader__card--second"
          />
        </div>
        <div className="customLoader__cardWrapper">
          <img
            src={Loader3}
            className="customLoader__card customLoader__card--third"
          />
        </div>
        <div className="customLoader__cardWrapper">
          <img
            src={Loader4}
            className="customLoader__card customLoader__card--fourth"
          />
        </div>
        <div className="customLoader__cardWrapper">
          <img
            src={Loader5}
            className="customLoader__card customLoader__card--fifth"
          />
        </div>
      </div>
      <p className="customLoader__text">
        {text && text.length > 0 ? text : "Loading... Please wait"}
      </p>
    </div>
  );
}

export default Loader;

import React from "react";
import "./ActionButtonsLayout.scss";

interface ActionButtonsLayoutProps {
  children: React.ReactNode;
}

function ActionButtonsLayout(props: ActionButtonsLayoutProps) {
  const { children } = props;

  return <div className="ActionButtonsLayout">{children}</div>;
}

export default ActionButtonsLayout;

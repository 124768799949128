import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Dashboard from "../views/Dashboard";

import Customer from "../views/customers/Customer";
import CustomerList from "../views/customers/CustomerList";

import ProductList from "../views/products/ProductList";
import JobQueueList from "../views/products/JobQueueList";

import PendingBuylist from "../views/buylist/PendingBuylist";
import BuylistDetails from "../views/buylist/BuylistDetails";
import ApprovedBuylist from "../views/buylist/ApprovedBuylist";
import CompletedBuylist from "../views/buylist/CompletedBuylist";
import BuylistRuleList from "../views/buylist/BuylistRuleList";
import BuylistRule from "../views/buylist/BuylistRule";
import FailedBuylistProducts from "../views/buylist/FailedBuylistProducts";

import TillList from "../views/pos/TillList";
import CartList from "../views/pos/CartList";
import CartView from "../views/pos/CartView";
import EODReports from "../views/pos/EODReports";
import TaxSettings from "../views/pos/TaxSettings";
import POSFailedBuylistProducts from "../views/pos/POSFailedBuylistProducts";
import PaxSettings from "../views/pos/PaxSettings";
import CatalogUpdates from "../views/pos/CatalogUpdates";
import LocationSettings from "../views/pos/LocationSettings";
import MiscSettings from "../views/pos/MiscSettings";

import Pos from "../pos/Pos";

import StoreSettings from "../views/settings/StoreSettings";
import NotesSettings from "../views/settings/NotesSettings";
import PricingSettings from "../views/settings/PricingSettings";
import TimezoneSettings from "../views/settings/TimezoneSettings";
import ReportSettings from "../views/settings/ReportSettings";
import Users from "../views/settings/Users";
import User from "../views/settings/User";

import EventRouter from "./EventRouter";
import IntegrationRouter from "./IntegrationRouter";
import ReportsRouter from "./ReportsRouter";
import CheckPermission from "../components/auth/CheckPermission";

function DefaultAppRouter() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>

      <Route path="/events">
        <EventRouter />
      </Route>

      <Route exact path="/customers">
        <Redirect to="/customers/list" />
      </Route>
      <Route exact path="/customers/list">
        <CheckPermission screenName="customers">
          <CustomerList />
        </CheckPermission>
      </Route>
      <Route exact path="/customers/add">
        <CheckPermission screenName="customers">
          <Customer />
        </CheckPermission>
      </Route>
      <Route exact path="/customers/update/:customerId">
        <CheckPermission screenName="customers">
          <Customer />
        </CheckPermission>
      </Route>

      <Route exact path="/products">
        <ProductList />
      </Route>
      <Route exact path="/products/jobQueue">
        <JobQueueList />
      </Route>

      <Route exact path="/buylists">
        <Redirect to="/buylists/pending" />
      </Route>
      <Route exact path="/buylists/pending">
        <PendingBuylist />
      </Route>
      <Route
        exact
        path="/buylists/pending/moreDetail/:buylistId"
        render={(props) => <BuylistDetails {...props} />}
      />
      <Route exact path="/buylists/approved">
        <ApprovedBuylist />
      </Route>
      <Route
        exact
        path="/buylists/approved/moreDetail/:buylistId"
        render={(props) => <BuylistDetails {...props} />}
      />
      <Route exact path="/buylists/completed">
        <CompletedBuylist />
      </Route>
      <Route
        exact
        path="/buylists/completed/moreDetail/:buylistId"
        render={(props) => <BuylistDetails {...props} />}
      />
      <Route exact path="/buylists/rules">
        <BuylistRuleList />
      </Route>
      <Route exact path="/buylists/rules/add">
        <BuylistRule />
      </Route>
      <Route
        exact
        path="/buylists/rules/update/:buylistRuleId"
        render={(props) => <BuylistRule {...props} />}
      />
      <Route exact path="/buylists/failedToSyncProducts">
        <FailedBuylistProducts />
      </Route>

      <Route exact path="/pointOfSale">
        <Redirect to="/pointOfSale/tills" />
      </Route>
      <Route exact path="/pointOfSale/tills">
        <TillList />
      </Route>
      <Route exact path="/pointOfSale/carts">
        <CartList />
      </Route>
      <Route
        exact
        path="/pointOfSale/carts/:cartId"
        render={(props) => <CartView {...props} />}
      />
      <Route exact path="/pointOfSale/eodReports">
        <EODReports />
      </Route>
      <Route exact path="/pointOfSale/settings/tax">
        <TaxSettings />
      </Route>
      <Route exact path="/pointOfSale/failedToSyncProducts">
        <POSFailedBuylistProducts />
      </Route>
      <Route exact path="/pointOfSale/paxSettings">
        <PaxSettings />
      </Route>
      <Route exact path="/pointOfSale/catalogUpdates">
        {" "}
        <CatalogUpdates />
      </Route>
      <Route exact path="/pointOfSale/locationSettings">
        {" "}
        <LocationSettings />
      </Route>
      <Route exact path="/pointOfSale/miscSettings">
        <MiscSettings />
      </Route>

      <Route
        exact
        path="/pos"
        render={(props) => <Pos screenSettings={props} />}
      />

      <Route exact path="/settings">
        <Redirect to="/settings/store" />
      </Route>
      <Route exact path="/settings/store">
        <StoreSettings />
      </Route>
      <Route
        exact
        path="/settings/notes"
        render={() => (
          <NotesSettings
            getSetting={() =>
              this.props.store.SettingsStore.fetchCustomerSettingForType(
                "customFields"
              )
            }
            updateSetting={(setting) =>
              this.props.store.SettingsStore.updateSetting(setting)
            }
          />
        )}
      />
      <Route exact path="/settings/pricing">
        <PricingSettings />
      </Route>
      <Route exact path="/settings/timezone">
        <TimezoneSettings />
      </Route>
      <Route exact path="/settings/reports">
        <ReportSettings />
      </Route>
      <Route exact path="/settings/users">
        <Redirect to="/settings/users/list" />
      </Route>
      <Route exact path="/settings/users/list">
        <Users />
      </Route>
      <Route exact path="/settings/users/add">
        <User />
      </Route>
      <Route
        exact
        path="/settings/users/update/:userId"
        render={(props) => <User {...props} />}
      />
      <Route path="/reports">
        <ReportsRouter />
      </Route>
      <Route path="/integrations">
        <CheckPermission screenName="integrations">
          <IntegrationRouter />
        </CheckPermission>
      </Route>
    </>
  );
}

export default inject("store")(observer(DefaultAppRouter));

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { toast, ToastContainer } from "react-toastify";
import { API_STATE } from "../../constants/api";
import { getStoreCreditTransactionSummaryByCustoner } from "../../api/graphql/reports";
import { reformatStoreCreditTransactionSummary } from "../../utils/reports";
import { ReportsMenuStructure } from "../../menuStructures";
import AsyncSelect from "react-select/async";
import { fetchCustomers } from "../../api/rest/customers";
import { notificationParameters } from "../../constants/notifications";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import {
  Pagination,
  ReportControls,
  TableSpinner,
  ViewNotes,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import "react-base-table/styles.css";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class StoreCreditByCustomer extends Component {
  constructor(props) {
    super(props);
    this.selectedStart = null;
    this.selectedEnd = null;
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  @observable selectedCustomerId;
  @action setSelectedCustomerId = (customer) => {
    if ("value" in customer) {
      this.selectedCustomerId = parseInt(customer.value, 10);
      this.loadData(this.selectedFromDate, this.selectedToDate);
    }
  };

  @observable showNotes;
  @action setShowNotes = (notes) => {
    this.showNotes = notes;
  };

  searchCustomers = (keyword) => {
    return fetchCustomers(keyword).catch((error) => {
      console.error(error);
      toast.error("Unable to search customers. Please try again");
    });
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    if (!this.selectedCustomerId) {
      return;
    }
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    this.selectedFromDate = fromDate;
    this.selectedToDate = toDate;
    getStoreCreditTransactionSummaryByCustoner(
      fromDate,
      toDate,
      this.selectedCustomerId
    )
      .then((data) => {
        const rows = reformatStoreCreditTransactionSummary(data);
        this.props.store.ReportsStore.setSourceData(rows);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your store credit report. Please try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;
    const {
      actionedBy = "",
      invoiceNumber = "",
      cartId = "",
      shopifyCustomerBuylistId = "",
    } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_actioned_by" && (
                <TextInputFilter
                  value={actionedBy}
                  setValue={(value) => updateFilterValue("actionedBy", value)}
                />
              )}
              {key === "col_invoiceNumber" && (
                <TextInputFilter
                  value={invoiceNumber}
                  setValue={(value) =>
                    updateFilterValue("invoiceNumber", value)
                  }
                />
              )}
              {key === "col_cartId" && (
                <TextInputFilter
                  value={cartId}
                  setValue={(value) => updateFilterValue("cartId", value)}
                />
              )}
              {key === "col_shopifyCustomerBuylistId" && (
                <TextInputFilter
                  value={shopifyCustomerBuylistId}
                  setValue={(value) =>
                    updateFilterValue("shopifyCustomerBuylistId", value)
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "actioned by",
      condition: (filterValues) =>
        filterValues.actionedBy && filterValues.actionedBy.length,
      filterFunction: (transaction, filterValues) =>
        transaction.actionedBy &&
        String(transaction.actionedBy).includes(filterValues.actionedBy),
    },
    {
      name: "invoice number",
      condition: (filterValues) =>
        filterValues.invoiceNumber && filterValues.invoiceNumber.length,
      filterFunction: (transaction, filterValues) =>
        transaction.invoiceNumber &&
        String(transaction.invoiceNumber).includes(filterValues.invoiceNumber),
    },
    {
      name: "cart ID",
      condition: (filterValues) =>
        filterValues.cartId && filterValues.cartId.length,
      filterFunction: (transaction, filterValues) =>
        transaction.cartId &&
        String(transaction.cartId).includes(filterValues.cartId),
    },
    {
      name: "shopify customer buylist ID",
      condition: (filterValues) =>
        filterValues.shopifyCustomerBuylistId &&
        filterValues.shopifyCustomerBuylistId.length,
      filterFunction: (transaction, filterValues) =>
        transaction.shopifyCustomerBuylistId &&
        String(transaction.shopifyCustomerBuylistId).includes(
          filterValues.shopifyCustomerBuylistId
        ),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      {
        name: "Date",
        key: "updatedDate",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      { name: "Actioned By", key: "actionedBy" },
      {
        name: "Credit Before",
        key: "amountBeforeChange",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit After",
        key: "amountAfterChange",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Credit Difference",
        key: "amountChanged",
        formatter: (number) => number && number.toFixed(2),
      },
      { name: "Shopify Order Number", key: "invoiceNumber" },
      { name: "BinderPOS Cart", key: "cartId" },
      { name: "Action Notes", key: "actionNotes" },
      { name: "Public Notes", key: "publicNotes" },
      { name: "Private Notes", key: "privateNotes" },
    ];

    const csvFilename = "Customer Store Credit Transactions Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              Customer Store Credit Transactions Report
            </span>
          </h2>
        </div>
        <div className="report">
          <div className="row">
            <div className="col-sm-3">Select Customer</div>
            <div className="col-sm-9">
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={this.searchCustomers}
                onChange={this.setSelectedCustomerId}
                placeholder="Type customer name or email"
                noOptionsMessage={() => "No matches found"}
              />
            </div>
          </div>
          <br />
          {!!this.selectedCustomerId && (
            <ReportControls
              setDateRange={this.loadData}
              csvHeaders={csvHeaders}
              csvFilename={csvFilename}
            />
          )}
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                      rowKey="buylistId"
                    >
                      <Column
                        key="col_updatedDate"
                        dataKey="updatedDate"
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        width={120}
                        flexGrow={1}
                        title="Date"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_actionedBy"
                        dataKey="actionedBy"
                        width={100}
                        flexGrow={1}
                        title="Actioned By"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_amountBeforeChange"
                        dataKey="amountBeforeChange"
                        width={120}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Credit Before"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_amountAfterChange"
                        dataKey="amountAfterChange"
                        width={120}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Credit After"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_amountChanged"
                        dataKey="amountChanged"
                        width={120}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Credit Difference"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_invoiceNumber"
                        dataKey="invoiceNumber"
                        width={100}
                        align="right"
                        title="Shopify Order"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_cartId"
                        dataKey="cartId"
                        width={100}
                        align="right"
                        title="BinderPOS Cart"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_shopifyCustomerBuylistId"
                        dataKey="shopifyCustomerBuylistId"
                        width={100}
                        align="right"
                        title="BinderPOS Buylist"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_actionNotes"
                        dataKey="actionNotes"
                        width={100}
                        flexGrow={1}
                        title="Action Notes"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) =>
                          !!cellData && (
                            <button
                              onClick={() => this.setShowNotes(cellData)}
                              className="button"
                            >
                              View
                            </button>
                          )
                        }
                      />
                      <Column
                        key="col_publicNotes"
                        dataKey="publicNotes"
                        width={100}
                        flexGrow={1}
                        title="Public Notes"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) =>
                          !!cellData && (
                            <button
                              onClick={() => this.setShowNotes(cellData)}
                              className="button"
                            >
                              View
                            </button>
                          )
                        }
                      />
                      <Column
                        key="col_privateNotes"
                        dataKey="privateNotes"
                        width={100}
                        flexGrow={1}
                        title="Private Notes"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) =>
                          !!cellData && (
                            <button
                              onClick={() => this.setShowNotes(cellData)}
                              className="button"
                            >
                              {cellData}
                            </button>
                          )
                        }
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
        {!!this.showNotes && (
          <ViewNotes
            notes={this.showNotes}
            onClose={() => this.setShowNotes()}
          />
        )}
        <ToastContainer {...notificationParameters} />
      </Fragment>
    );
  }
}

StoreCreditByCustomer.propTypes = { store: PropTypes.object };
export default StoreCreditByCustomer;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Loader, ReceiptReprint } from "../../components";
import GiftReceipt from "../generic/GiftReceipt";
import CartNotes from "./CartNotes";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@inject("posStore")
@observer
class OrderDetails extends Component {
  @observable showReceiptModal = false;
  @action setShowReceiptModal(showReceiptModal) {
    this.showReceiptModal = showReceiptModal;
  }

  @observable showGiftReceiptModal = false;
  @action setShowGiftReceiptModal = (showGiftReceiptModal) =>
    (this.showGiftReceiptModal = showGiftReceiptModal);

  closeReceiptModal = () => {
    this.setShowReceiptModal(false);
    this.setShowGiftReceiptModal(false);
  };

  createReturn = (cartId) => {
    this.props.posStore.returnCartById(cartId);
    this.props.closeModal();
  };

  render() {
    const { AuthStore, MainStore, CartStore } = this.props.store;
    const fC = MainStore.currencyBuilder;

    if (!AuthStore.screenSettings.pos) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    if (!CartStore.selectedCart) {
      return <Loader />;
    }

    const {
      id,
      parentCartId,
      returnCarts,
      customer,
      dateSubmitted,
      orderNumber,
      tenders,
      cartIntegratedPayments,
      cartItems,
      subTotal,
      totalTax,
      totalPrice,
      cartNotes,
    } = CartStore.selectedCart;

    return (
      <Fragment>
        {this.showReceiptModal ? (
          <ReceiptReprint closeReceipt={this.closeReceiptModal} />
        ) : null}
        {this.showGiftReceiptModal ? (
          <GiftReceipt closeReceipt={this.closeReceiptModal} />
        ) : null}
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              {parentCartId ? "Return " : "Cart "}# {id}
            </span>
            <button
              className="btn1"
              onClick={() => this.setShowGiftReceiptModal(true)}
            >
              Print Gift Receipt
            </button>
            <button
              className="btn1"
              onClick={() => this.setShowReceiptModal(true)}
            >
              Reprint Receipt
            </button>
            {!!returnCarts?.length && (
              <button className="btn1" onClick={() => this.createReturn(id)}>
                Return Order
              </button>
            )}
          </h2>
        </div>
        <div className="cartviewTop" style={{ marginBottom: "24px" }}>
          {returnCarts && (
            <table>
              <thead>
                <tr>
                  <td>Attached Returns</td>
                </tr>
              </thead>
              <tbody>
                {returnCarts?.map((returnCart, index) => {
                  if (returnCart.dateSubmitted) {
                    console.log({ itemsLength: returnCart.cartItems.length });
                    return (
                      <tr key={index}>
                        <td
                          onClick={() => {
                            this.props.setOrderNumber(returnCart.id);
                          }}
                        >
                          A return was made see order #{returnCart.id}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          )}
          {parentCartId && (
            <table>
              <thead>
                <tr>
                  <td>Parent Cart</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    onClick={() => {
                      this.props.setOrderNumber(parentCartId);
                    }}
                  >
                    This return was created for cart# {parentCartId}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <table>
            <thead>
              <tr>
                <Fragment>
                  <td style={{ paddingLeft: "15px" }}>Customer</td>
                  {CartStore.selectedCart.customer && (
                    <td className="col1">Email</td>
                  )}
                  <td className="storeCredit col2">Date submitted</td>
                  <td className="col2">Order #</td>
                </Fragment>
              </tr>
            </thead>
            <tbody>
              <tr style={{ borderLeft: "0px" }}>
                {customer ? (
                  <Fragment>
                    <td style={{ textAlign: "center" }} className="name">
                      {customer.firstName} {customer.lastName}
                    </td>
                    <td>{customer.email}</td>
                  </Fragment>
                ) : (
                  <Fragment>
                    <td style={{ textAlign: "center" }}>
                      <div>Guest</div>
                    </td>
                  </Fragment>
                )}
                <td>{TimeUtils.convertDateToHumanReadable(dateSubmitted)}</td>
                <td>{orderNumber}</td>
              </tr>
            </tbody>
          </table>
          {CartStore.selectedCart.tenders &&
          CartStore.selectedCart.tenders.length > 0 ? (
            <Fragment>
              <table className="cartViewTenders">
                <thead>
                  <tr>
                    <td style={{ paddingLeft: "15px" }}>Payment Details</td>
                  </tr>
                </thead>
                <tbody>
                  {tenders.map((tender, index) => (
                    <Fragment key={index}>
                      <tr style={{ borderLeft: "0px" }}>
                        <td
                          style={{
                            paddingLeft: "15px",
                          }}
                        >
                          <em>{tender.type} :</em>{" "}
                          {fC(parseFloat(tender.amount))}
                        </td>
                      </tr>
                      {tender.type.toLowerCase() === "credit" &&
                        cartIntegratedPayments.map((payment, index) => (
                          <tr key={index}>
                            <table className="cartViewTenders">
                              <thead>
                                <tr>
                                  <td>Batch</td>
                                  <td>Transaction Number</td>
                                  <td>Amount</td>
                                  <td>Card Holder</td>
                                  <td>Card Number</td>
                                  <td>Action</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{payment.batchNumber}</td>
                                  <td>{payment.transactionNumber}</td>
                                  <td>{payment.approveAmount}</td>
                                  <td>{payment.cardHolder}</td>
                                  <td>{payment.lastFour}</td>
                                  <td>VOID TRANSACTION</td>
                                </tr>
                              </tbody>
                            </table>
                          </tr>
                        ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </Fragment>
          ) : (
            <p>No tenders used</p>
          )}
        </div>
        <CartNotes rawNotes={cartNotes} />
        <div className="cartviewBottom" style={{ marginTop: "24px" }}>
          <table className="cartView">
            <thead>
              <tr>
                <td></td>
                <td>Product</td>
                <td>Bought / Sold</td>
                <td>Quantity</td>
                <td>Price</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((cartItem, index) => (
                <Fragment key={index}>
                  <tr style={{ borderLeft: "0px" }}>
                    <td>
                      <img
                        className="cart-view-image"
                        src={cartItem.imageSrc}
                      />
                    </td>
                    <td>
                      <div className="cartViewTitle">
                        {cartItem.productTitle} - {cartItem.variantTitle}
                      </div>
                    </td>
                    <td>{cartItem.buying ? "Bought" : "Sold"}</td>
                    <td>{cartItem.quantity}</td>
                    <td>
                      {fC(
                        parseFloat(
                          parseFloat(cartItem.price) /
                            parseFloat(cartItem.quantity)
                        )
                      )}
                    </td>
                    <td>{fC(parseFloat(cartItem.price))}</td>
                  </tr>
                </Fragment>
              ))}
              <tr className="cartViewTotals" style={{ borderLeft: "0px" }}>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan="3">
                  <div
                    className="cartViewTitle"
                    style={{
                      marginTop: "19px",
                      marginRight: "22px",
                      color: "#8b8c92",
                    }}
                  >
                    Sub Total: <em>{fC(subTotal)}</em>
                  </div>
                  <div
                    className="cartViewTitle"
                    style={{
                      marginRight: "22px",
                      color: "#8b8c92",
                    }}
                  >
                    Tax included: <em>{fC(totalTax)}</em>
                  </div>
                  <div
                    className="cartViewTitle"
                    style={{
                      marginRight: "22px",
                      color: "#8b8c92",
                    }}
                  >
                    Total: <em>{fC(totalPrice)}</em>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

OrderDetails.propTypres = {
  closeModal: PropTypes.func.isRequired,
  setOrderNumber: PropTypes.func.isRequired,
};

export default OrderDetails;

const POSMenuStructure = {
  title: "Point of Sale",
  activeClass: "cyan",
  links: [
    {
      title: "Point of Sale",
      href: "pos",
    },
    {
      title: "Tills / Registers",
      href: "pointOfSale/tills",
    },
    {
      title: "Submitted Carts",
      href: "pointOfSale/carts",
    },
    {
      title: "End of Day Reports",
      href: "pointOfSale/eodReports",
    },
    {
      title: "Tax settings",
      href: "pointOfSale/settings/tax",
    },
    {
      title: "Failed to Sync from POS",
      href: "pointOfSale/failedToSyncProducts",
    },
    {
      title: "PAX Terminal Settings",
      href: "pointOfSale/paxSettings",
    },
    {
      title: "Catalog Update Settings",
      href: "pointOfSale/catalogUpdates",
    },
    {
      title: "Location Settings",
      href: "pointOfSale/locationSettings",
    },
    {
      title: "Misc Settings",
      href: "pointOfSale/miscSettings",
    },
    {
      title: "Extra Notes Fields",
      href: "settings/notes",
    },
  ],
};

export default POSMenuStructure;

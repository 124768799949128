export const parseEbayError = (error: any) => {
  if (!error.data) return null;
  try {
    const data = JSON.parse(error.data);
    if (!data.message) return null;
    return data.message;
  } catch (error) {
    return null;
  }
};

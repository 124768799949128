import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { SettingsMenuStructure } from "../../menuStructures";
import { Loader, ConfirmationModal } from "../../components";

@inject("store")
@observer
class User extends React.Component {
  @observable updating;

  @action setUpdating(bool) {
    this.updating = bool;
  }

  @observable modalVisible = false;

  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;

  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;

  @action setModalText(text) {
    this.modalText = text;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.store.UsersStore.setUser(null);
    this.clearFields();
    const id = this.props.match?.params?.userId || null;
    if (id) {
      document.title = "Update User | BinderPOS";
      this.props.store.UsersStore.fetchAndSetUser(id);
    } else {
      document.title = "Add User | BinderPOS";
    }
    this.props.store.MenuStore.setSideMenuToDisplay(SettingsMenuStructure);
  }

  clearFields() {
    if (this.form && this.form.current) {
      const elm = this.form.current.elements;
      elm.email.value = "";
      elm.firstName.value = "";
      elm.lastName.value = "";
    }
  }

  save(e) {
    e.preventDefault();
    this.setUpdating(true);

    const elm = this.form.current.elements;

    if (!this.props.store.UsersStore.user && elm.password.value.length < 8) {
      this.setModalVisible(true);
      this.setModalTitle("Password too short");
      this.setModalText(
        "Please make sure the password is 8 characters or more"
      );
    } else if (
      !this.props.store.UsersStore.user &&
      elm.password.value != elm.confirmPassword.value
    ) {
      this.setModalVisible(true);
      this.setModalTitle("Passwords do not match");
      this.setModalText(
        "Please make sure that the passwords match and try again"
      );
    } else {
      const theUser = {
        email: elm.email.value,
        firstName: elm.firstName.value,
        lastName: elm.lastName.value,
      };

      if (this.props.match?.params?.userId) {
        theUser.id = this.props.match.params.userId;
        this.props.store.UsersStore.updateUser(theUser)
          .then(() => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle("User has been updated");
            const customerText =
              theUser.firstName && theUser.firstName.length > 0
                ? `${theUser.firstName} ${
                    theUser.lastName && theUser.lastName.length > 0
                      ? theUser.lastName
                      : ""
                  }`
                : theUser.email && theUser.email.length > 0
                ? theUser.email
                : "";
            this.setModalText(`${customerText} has been updated successfully.`);
            this.props.store.UsersStore.rehydrateUsers();
          })
          .catch((error) => {
            this.setUpdating(false);
            this.setModalVisible(false);
            this.props.store.MainStore.setError(
              error,
              "Failed to save user",
              "There was an error updating your user details. Please try again"
            );
          });
      } else {
        theUser.password = elm.password.value;
        this.props.store.UsersStore.createUser(theUser)
          .then((theUser) => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle("User has been added");
            const customerText =
              theUser.firstName && theUser.firstName.length > 0
                ? `${theUser.firstName} ${
                    theUser.lastName && theUser.lastName.length > 0
                      ? theUser.lastName
                      : ""
                  }`
                : theUser.email && theUser.email.length > 0
                ? theUser.email
                : "";
            this.setModalText(`${customerText} has been added.`);
            this.props.store.UsersStore.rehydrateUsers();
          })
          .catch((error) => {
            this.setUpdating(false);
            this.setModalVisible(false);
            this.props.store.MainStore.setError(
              error,
              "Failed to save user",
              "There was an error submitting your user details. Please try again"
            );
          });
      }
    }
  }

  render() {
    return (
      <>
        {(this.props.match?.params?.userId &&
          this.props.store.UsersStore.user) ||
        !this.props.match?.params?.userId ? (
          <>
            <form ref={this.form} onSubmit={(e) => this.save(e)} noValidate>
              <div className="app-header">
                <h2 className="title is-2">
                  <span className="header-text">
                    {this.props.store.UsersStore.user
                      ? `Update ${this.props.store.UsersStore.user.email}`
                      : "Add user"}
                  </span>
                  <button
                    className={`btn1 ${this.updating ? " is-loading" : ""}`}
                    type="submit"
                  >
                    {this.props.store.UsersStore.user ? "Update" : "Save"}
                  </button>
                </h2>
              </div>
              <div className="infoInputPages">
                <div className="col-md-12 content">
                  <div className="eventPanel">
                    <div className="row">
                      <div className="col-md-12 field">
                        <label className="label" htmlFor="email">
                          Email address
                        </label>
                        <div className="control">
                          <input
                            id="email"
                            className="input"
                            type="text"
                            placeholder="E.g. example@binderpos.com"
                            required
                            defaultValue={
                              this.props.store.UsersStore.user?.email || ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-6">
                        <label className="label" htmlFor="firstName">
                          First name
                        </label>
                        <div className="control">
                          <input
                            id="firstName"
                            className="input"
                            type="text"
                            placeholder="E.g. John"
                            required
                            defaultValue={
                              this.props.store.UsersStore.user?.firstName || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-6">
                        <label className="label" htmlFor="lastName">
                          Last name
                        </label>
                        <div className="control">
                          <input
                            id="lastName"
                            className="input"
                            type="text"
                            placeholder="E.g. Smith"
                            required
                            defaultValue={
                              this.props.store.UsersStore.user?.lastName || ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {!this.props.store.UsersStore.user ? (
                      <>
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label" htmlFor="password">
                              Password
                            </label>
                            <div className="control">
                              <input
                                id="password"
                                className="input"
                                type="password"
                                required
                                placeholder="****************"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 field">
                            <label className="label" htmlFor="confirmPassword">
                              Confirm password
                            </label>
                            <div className="control">
                              <input
                                id="confirmPassword"
                                className="input"
                                type="password"
                                required
                                placeholder="****************"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <button
                    className={`button buttonWide ${
                      this.updating ? " is-loading" : ""
                    }`}
                    type="submit"
                  >
                    {this.props.store.UsersStore.user
                      ? "Save and update user"
                      : "Save and add new user"}
                  </button>
                </div>
              </div>
            </form>
            {this.modalVisible ? (
              <ConfirmationModal
                title={this.modalTitle}
                text={this.modalText}
                confirmWord="Ok"
                link="/settings/users/list"
              />
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

User.propTypes = {
  store: PropTypes.object,
  match: PropTypes.object,
};

export default User;

import React from "react";
import TimeUtils from "../../utils/TimeUtils";

export function DateTimeRenderer({ cellData }) {
  if (!cellData) {
    return <div>-</div>;
  }

  return TimeUtils.convertDateToHumanReadable(cellData);
}

export function TimestampRenderer({ cellData }) {
  return TimeUtils.convertDateToHumanReadable(cellData);
}

export function CurrencyRenderer({ cellData, column, style = {} }) {
  const floatValue = parseFloat(cellData);
  if (isNaN(floatValue)) {
    return "-";
  }
  const locale = navigator.languages[0];
  const currencyCode = column.currency || "USD";
  const negativeClass = floatValue < 0 ? "negative" : null;
  let formattedData = floatValue.toLocaleString(locale, {
    style: "currency",
    currency: currencyCode,
  });
  return (
    <div className={negativeClass} style={style}>
      {formattedData}
    </div>
  );
}

export function TextInputFilter({ value, setValue, title }) {
  return (
    <input
      type="text"
      value={value === null || value === undefined ? "" : value}
      onChange={(event) => setValue(event.target.value)}
      title={title}
    />
  );
}

export function NumberInputFilter({ value, setValue, title }) {
  return (
    <input
      title={title}
      type="text"
      value={value === null || value === undefined ? "" : value}
      onChange={(event) => {
        const value = event.target.value;
        if (!value || !value.length) {
          setValue(null);
          return;
        }
        const number = parseInt(value, 10);
        if (isNaN(number)) {
          return;
        }
        setValue(number);
      }}
    />
  );
}

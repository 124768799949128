import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

@inject("posStore")
@observer
class OpenTillModal extends Component {
  floatUpdate = (event) => {
    const { value } = event.target;
    if (isNaN(value) && value != "-") {
      this.props.posStore.setFloatOpenAmount(value.replace(/[^0-9.-]/g, ""));
    } else {
      this.props.posStore.setFloatOpenAmount(value);
    }
  };

  validateFormat = (event) => {
    const { value } = event.target;
    if (value === "" || isNaN(value)) {
      this.props.posStore.setFloatOpenAmount("0.00");
    } else {
      this.props.posStore.setFloatOpenAmount(parseFloat(value).toFixed(2));
    }
  };

  selectAll = (event) => {
    event.target.select();
  };

  changeTill = (event) => {
    const { value: selectedTill } = event.target;
    if (selectedTill != this.props.posStore.tillId) {
      this.props.posStore.setTill(selectedTill);
    }
    if (
      this.props.posStore.tillList.find((till) => till.id == selectedTill).open
    ) {
      this.props.posStore.setForceTill(true);
    } else {
      this.props.posStore.setForceTill(false);
    }
  };

  render() {
    const store = this.props.posStore;

    return (
      <div className="modal display-block">
        <section className="modal-main dark">
          <div className={store.floatStatus ? "openTill hidden" : "openTill"}>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">BinderPOS - Point of Sale</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  <i className="fal fa-door-open"></i> Leave POS
                </button>
              </header>
              <section className="modal-card-body">
                <div className="wrapper floatAdj">
                  <h5 className="title-lrg">Select till</h5>
                  <div className="tillSelector">
                    <div className="tillDropdownDiv">
                      <label htmlFor="till" className="till">
                        Till:
                      </label>
                      <select
                        id="till"
                        className="tillDropdown"
                        onChange={this.changeTill}
                        disabled={store.floatStatus}
                        value={store.tillId ? store.tillId : -1}
                        data-testid="till-dropdown"
                      >
                        <option value={-1} key="-1">
                          No Till Selected
                        </option>
                        {store.tillList.map((till) => (
                          <option value={till.id} key={till.id}>
                            {till.name +
                              "\t-\t" +
                              till.description +
                              (till.open ? " (Opened)" : "")}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <hr />
                  <label htmlFor="openingFloat" className="till">
                    Opening float:
                  </label>
                  <input
                    id="openingFloat"
                    className="OpeningAmount"
                    value={
                      this.props.posStore.forceTill
                        ? "Opened"
                        : store.floatOpenAmount
                    }
                    onChange={this.floatUpdate}
                    onFocus={this.selectAll}
                    disabled={store.forceTill}
                    onBlur={this.validateFormat}
                    type="textbox"
                    data-testid="opening-float"
                  />
                </div>
              </section>
              <footer className="modal-card-foot">
                <button
                  className="modalBtn action full"
                  onClick={store.submitFloat}
                  disabled={store.floatStatus}
                >
                  {store.forceTill
                    ? "Force Login to Open Till"
                    : "Set Opening Float"}
                  <i className="fas fa-caret-right"></i>
                </button>{" "}
              </footer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(OpenTillModal);

import { _graphQLRequest } from "../../api";
import { gql } from "apollo-boost";
import moment from "moment";

export async function getDailyPOSSalesData(startDate, endDate, binSize = 3600) {
  const offset = moment.tz(moment.tz.guess()).format("Z");
  const query = gql`
    {
      Metrics {
        SalesByRange(fromTime: "${startDate}", toTime: "${endDate}", binSize: ${binSize}, timeZone: "${offset}") {
          time
          totalSales
          Total_In
          Store_Credit_In
          Store_Credit_Out
          Cash_In
          Cash_Out
          Credit_In
          Credit_Out
          EFTPOS_In
          EFTPOS_Out
        }
        Sales(fromTime: "${startDate}", toTime: "${endDate}"){
          Total_In
          Store_Credit_In
          Store_Credit_Out
          Cash_In
          Cash_Out
          Credit_In
          Credit_Out
          EFTPOS_In
          EFTPOS_Out

        }
      }
    }
  `;

  const data = await _graphQLRequest(query);
  console.log({ posSalesData: data });
  return data;
}

export async function getEndOfDayReports(startDate, endDate) {
  const query = gql`
    {
      POSReports {
      FloatEntries(fromTime: "${startDate}", toTime: "${endDate}") {
        floatId
        name
        openedDate
        closedDate
        status,
        tenders {
          name
          openingAmount
          closingAmount
          currentAmount
        }
      }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getLedgerEntries(id) {
  const query = gql`
    {
      POSReports {
        LedgerEntries(id: ${id}) {
          entryId
          createdAt
          amount
          notes
          tender
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getSalesByTenderReport(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        Sales(fromTime: "${startDate}", toTime: "${endDate}") {
          totalSales,
          Cash_In,
          Cash_Out
          Credit_In,
          Credit_Out,
          EFTPOS_In,
          EFTPOS_Out,
          Store_Credit_In,
          Store_Credit_Out,
          Total_In
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getSalesByTenderBetaReport(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        SalesByTender(fromTime: "${startDate}", toTime: "${endDate}") {
          orderId
          total
          createdAt
          source
          tenders {
            type
            amount
            kind
            dateCreated
            dateProcessed
            status
            id
          }
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getStockInventoryValue() {
  const query = gql`
    {
      Metrics {
        RetailValue {
          productType
          count
          totalCost
          totalValue
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getSalesByCategory(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        CategorySales(fromTime: "${startDate}", toTime: "${endDate}") {
          productType
          buys
          sales
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getWPNReportData(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        WPNReport(fromTime: "${startDate}", toTime: "${endDate}") {
          transactionTime
          transactionId
          UPC
          unitPrice
          totalPrice
          quantity
          description
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getSalesByOrder(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        SalesByOrder(fromTime: "${startDate}", toTime: "${endDate}"){
          orderId
          subTotal
          shipping
          discount
          tax
          total
          lineCount
          closedAt
          source
          tenders{
            type
            amount
          }
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getSalesByOrderBetaReport(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        SalesByOrderBeta(fromTime: "${startDate}", toTime: "${endDate}") {
          createdAt
          orderId
          tenders {
            type
            amount
            kind
            status
            id
          }
          source
          totalTax
          total
        }
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getBuylistReport(startDate, endDate) {
  const query = gql`
  {
    Metrics{
      BuylistDetails(fromTime: "${startDate}", toTime: "${endDate}"){
        buylistId
        dateSubmitted
        dateCompleted
        status
        paymentType
        totalBuyPrice
        totalItems
        firstName
        lastName
        email
      }
    }
  }
  `;
  return await _graphQLRequest(query);
}

export async function getStoreCreditTransactionSummary(startDate, endDate) {
  const query = gql`
    {
      Metrics {
        StoreCreditByRange(fromTime: "${startDate}", toTime: "${endDate}") {
          updatedDate
          actionedBy
          amountBeforeChange
          amountAfterChange
          amountChanged
          invoiceNumber
          cartId
          shopifyCustomerBuylistId
          firstName
          lastName
          email
          actionNotes
          publicNotes
          privateNotes
        } 
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getStoreCreditOutstanding() {
  const query = gql`
    {
      Customers {
        firstName
        lastName
        phone
        email
        storeCredit
      }
    }
  `;
  return await _graphQLRequest(query);
}

export async function getStoreCreditTransactionSummaryByCustoner(
  startDate,
  endDate,
  customerId
) {
  const query = gql`
    {
      Metrics {
        StoreCreditByRange: StoreCreditByRangeForCustomer(fromTime: "${startDate}", toTime: "${endDate}", customerId: ${customerId}) {
          updatedDate
          actionedBy
          amountBeforeChange
          amountAfterChange
          amountChanged
          invoiceNumber
          cartId
          shopifyCustomerBuylistId
          actionNotes
          publicNotes
          privateNotes
        } 
      }
    }
  `;
  return await _graphQLRequest(query);
}

import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Loader from "../generic/Loader";
import OptionModal from "../generic/OptionModal";
import CartLine from "./CartLine";
import Checkout from "./Checkout";
import MultiTenderCheckout from "./MultiTenderCheckout";
import CustomLineComponent from "./CustomLineComponent";
import EditCartNotes from "./EditCartNotes";
import { isTaxRateValid } from "../../utils/pos";

@inject("posStore", "store")
@observer
class Cart extends Component {
  constructor() {
    super();
    this.state = {
      showNote: false,
    };
  }

  async componentDidMount() {
    //if the component mounts while already logged into a till
    //load tax, latest cart, and quicklinks
    //otherwise we expect the float modal to be active and will handle
    //this logic once log in is complete
    if (!this.props.posStore.floatStatus) return;
    await this.props.posStore.getTax();
    await this.props.posStore.getLatestCart();
    await this.props.posStore.getQuickLinkData();
    await this.props.posStore.getCustomFields();
  }

  //we mix state stuff and mobx observables, part of the learning process. Both function
  //well so I don't see a huge reason to refactor outside of consistency.
  state = { show: false, results: [], itemShow: false, discountModal: false };

  @observable deleteModal = null;
  @action setDeleteModal = (value) => {
    this.deleteModal = value;
  };

  @action
  discountChange = (e) => {
    const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$");
    if (e.target.id == "discountAmount") {
      if (e.target.value === "" || floatRegExp.test(e.target.value)) {
        this.props.posStore.globalDiscount.amount = e.target.value || "0";
      }
    }
    if (e.target.id == "percent") {
      this.props.posStore.globalDiscount.type = "percentage";
    }
    if (e.target.id == "amount") {
      this.props.posStore.globalDiscount.type = "amount";
    }
  };

  @action
  showModal = () => {
    if (!this.props.posStore.showCustomItemComponent) {
      this.props.posStore.checkoutModal = true;
      this.props.posStore.setDisableLineItems(true);
    } else {
      this.props.posStore.toast("Unsaved custom item in cart");
    }
  };
  @action
  hideModal = () => {
    this.props.posStore.checkoutModal = false;
    this.props.posStore.setDisableLineItems(false);
    this.props.posStore.cartLoading = false;
  };

  toggleItem = () => {
    this.setState({ itemShow: !this.state.itemShow });
  };

  toggleDiscount = () => {
    this.setState({ discountModal: !this.state.discountModal });
  };

  @action
  handleInputChange = (e) => {
    this.props.posStore.customerInput = e.target.value;
    e.target.className = "customerChoosen";
    this.customerSearch();
  };

  render() {
    const store = this.props.posStore;
    const fC = this.props.posStore.fCurr;
    const roundCents = this.props.posStore.roundCents;
    return (
      <React.Fragment>
        <div className="cart-header">
          <div className="cartNumber">
            <i className="fas fa-print" onClick={store.openReceipt}></i>
            Cart #{this.props.posStore.cartId}
            <i
              title="Delete this cart"
              className="far fa-trash-alt"
              onClick={() => this.setDeleteModal(store.cartId)}
            ></i>
          </div>
          <div
            className="customItem"
            onClick={this.props.posStore.toggleCustomItem}
          >
            {!this.state.itemShow ? "Add a custom item" : "Close custom item"}{" "}
            <i
              className={
                !this.state.itemShow ? "fal fa-plus-square" : "fal fa-times"
              }
            ></i>{" "}
          </div>
          <button
            className="cartNote"
            onClick={() => this.setState({ showNote: true })}
          >
            {this.props.posStore.cartNotes ? (
              <>
                Edit note <i className="fas fa-edit"></i>
              </>
            ) : (
              <>
                Add Note <i className="fal fa-plus-square"></i>
              </>
            )}
          </button>
          {this.state.showNote && (
            <EditCartNotes
              customFields={this.props.posStore.customFields}
              cartNotes={this.props.posStore.cartNotes}
              onClose={() => this.setState({ showNote: false })}
              posStore={this.props.posStore}
            />
          )}
        </div>
        <div
          className={
            this.props.posStore.disableLineItems ? " cart disable" : "cart"
          }
        >
          {this.props.store.gettingLatestCart ? (
            <Loader text="Loading cart..." />
          ) : (
            <div className="cart-items">
              {store.cart.map((item, index) => (
                <CartLine store={store} item={item} key={index} />
              ))}
            </div>
          )}
          {this.props.posStore.showCustomItemComponent ? (
            <CustomLineComponent />
          ) : null}
          {/*This div is used as a reference to autoscroll to the bottom*/}
          <div style={{ float: "left", clear: "both" }}></div>
          <div className="cartTotals">
            <span className="discounts">
              Discount:{" "}
              <div className="discountWrapper">
                <button
                  id="amount"
                  className={
                    this.props.posStore.globalDiscount.type == "amount"
                      ? "amount"
                      : "amount notActive"
                  }
                  onClick={this.discountChange}
                >
                  $
                </button>
                <input
                  type="text"
                  name="discountAmount"
                  id="discountAmount"
                  value={store.globalDiscount.amount}
                  onChange={this.discountChange}
                />
                <button
                  id="percent"
                  className={
                    this.props.posStore.globalDiscount.type == "percentage"
                      ? "percentage"
                      : "percentage notActive"
                  }
                  onClick={this.discountChange}
                >
                  %
                </button>
              </div>
              <em>{fC(roundCents(store.discountAmount))}</em>
            </span>
            <span className="subTotal">
              Subtotal ({store.totalItems} Item)
              <em>{fC(roundCents(store.subTotal))}</em>
            </span>
            {/* <span className="discount">Add discount</span> */}
            <span className="tax">
              Tax ({store.taxRateDisplay}%)
              {store.negatedTaxTotal ? (
                <span className="taxNegated">
                  {"("} {fC(roundCents(store.negatedTaxTotal))}{" "}
                  {"negated by trade ins)"}
                </span>
              ) : (
                ""
              )}
              <em>{fC(roundCents(store.taxTotal))}</em>
            </span>
            <span className="total">
              Total<em>{fC(roundCents(store.total))}</em>
            </span>
          </div>
          <button
            className="buy-button"
            type="button"
            disabled={!isTaxRateValid(this.props.posStore.taxRate)}
            onClick={this.showModal}
          >
            {store.cartType == "return" ? "RETURN" : "PAY"}
            <i className="fas fa-caret-right"></i>
          </button>
          {store.checkoutModal && !store.useSplitTill ? (
            <Checkout
              store={this.props.posStore}
              handleClose={this.hideModal}
              checkout={this.checkout}
            />
          ) : null}
          {store.checkoutModal && store.useSplitTill ? (
            <MultiTenderCheckout
              store={this.props.posStore}
              handleClose={this.hideModal}
              checkout={this.checkout}
            />
          ) : null}
        </div>

        {this.deleteModal ? (
          <OptionModal
            text={
              "Please choose how you want to delete cart #" + this.deleteModal
            }
            title="Cart Deletion"
            actions={[
              {
                action: async () => {
                  await store.deleteCartById(null, this.deleteModal, false);
                  this.setDeleteModal(false);
                },
                wording: "Delete",
              },
              {
                action: async () => {
                  await store.deleteCartById(null, this.deleteModal, true);
                  this.setDeleteModal(false);
                },
                wording: "Delete and Return Stock",
                color: "#0a162e",
              },
              {
                action: () => {
                  this.setDeleteModal(false);
                },
                wording: "Cancel",
              },
            ]}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Cart;

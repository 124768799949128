import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { action } from "mobx";

@inject("posStore")
@observer
class LineDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = { discountModal: false };
  }
  componentDidMount() {
    this.discountInput.focus();
  }

  @action
  discountChange = (e) => {
    const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$");
    if (e.target.id == "discountAmount") {
      if (e.target.value === "" || floatRegExp.test(e.target.value)) {
        this.props.item.discountAmount = e.target.value;
      }
    }
    if (e.target.id == "percent") {
      this.props.item.discountType = "percentage";
      this.props.close();
    }
    if (e.target.id == "amount") {
      this.props.item.discountType = "amount";
      this.props.close();
    }
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.props.close();
    }
  };

  render() {
    var item = this.props.item;
    return (
      <span className={"discounts"}>
        Choose a discount:{" "}
        <div className="discountWrapper">
          <button
            id="amount"
            className={
              item.discountType == "amount" ? "amount" : "amount notActive"
            }
            onClick={this.discountChange}
          >
            $
          </button>
          <input
            type="text"
            name="discountAmount"
            id="discountAmount"
            value={item.discountAmount}
            onChange={this.discountChange}
            onBlur={this.blurInput}
            onKeyDown={this.handleEnter}
            ref={(input) => {
              this.props.discountInput(input);
              this.discountInput = input;
            }}
          />
          <button
            id="percent"
            className={
              item.discountType == "percentage"
                ? "percentage"
                : "percentage notActive"
            }
            onClick={this.discountChange}
          >
            %
          </button>
        </div>
      </span>
    );
  }
}

export default LineDiscount;

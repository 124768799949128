import React, { Component } from "react";

import { action } from "mobx";

class CustomerLine extends Component {
  @action
  selectCustomer = () => {
    this.props.store.selectedCustomer = this.props.customer;
    this.props.store.validateCart();
    this.props.store.customerResults = [];
    this.props.onSelect();
  };

  render() {
    const fC = this.props.store.fCurr;
    return (
      <div className="customer" onClick={this.selectCustomer}>
        <span className="initials">
          <p>
            {this.props.customer.firstName &&
            this.props.customer.firstName.length > 0
              ? this.props.customer.firstName.charAt(0)
              : "?"}{" "}
            {this.props.customer.lastName &&
            this.props.customer.lastName.length > 0
              ? this.props.customer.lastName.charAt(0)
              : "?"}
          </p>
        </span>
        <div className="customerSearchInfo">
          <span className="name">
            {this.props.customer.firstName &&
            this.props.customer.firstName.length > 0
              ? this.props.customer.firstName
              : "Unknown"}{" "}
            {this.props.customer.lastName &&
            this.props.customer.lastName.length > 0
              ? this.props.customer.lastName
              : "Unknown"}
          </span>
          <span className="email">
            {this.props.customer.email
              ? this.props.customer.email
              : "No Email Provided"}
          </span>
        </div>
        <span className="credit">
          {fC(this.props.customer.storeCredit.toFixed(2))}
        </span>
      </div>
    );
  }
}

export default CustomerLine;

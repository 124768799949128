import { getSalesByOrder } from "../../api/graphql/reports";
import {
  Pagination,
  ReportControls,
  SalesSourceSelect,
  TableSpinner,
  TenderTypeSelect,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { ReportsMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import { reformatSalesByOrder } from "../../utils/reports";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class SalesByTender extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  @observable showFilterTenderModal = false;
  @action setShowFilterTenderModal = (show) => {
    this.showFilterTenderModal = show;
  };

  @observable showFilterSourceModal = false;
  @action setShowFilterSourceModal = (show) => {
    this.showFilterSourceModal = show;
  };

  @observable filterTenders = {};
  @action setFilterTenders = (tenders) => {
    this.filterTenders = tenders;
    this.props.store.ReportsStore.updateFilterValue("tenderTypes", tenders);
  };

  updateFilterTenders = (selected) => {
    this.setFilterTenders(selected);
    this.setShowFilterTenderModal(false);
  };

  @observable filterSoures = {};
  @action setFilterSources = (sources) => {
    this.filterSources = sources;
    this.props.store.ReportsStore.updateFilterValue("sources", sources);
  };

  updateFilterSources = (selected) => {
    this.setFilterSources(selected);
    this.setShowFilterSourceModal(false);
  };

  @observable tenderTypes = [];
  @action setTenderTypes = (types) => {
    this.tenderTypes = types;
  };

  @observable sourceTypes = [];
  @action setSourceTypes = (types) => {
    this.sourceTypes = types;
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getSalesByOrder(fromDate, toDate)
      .then((data) => {
        const { rows, tenderTypes, sources } = reformatSalesByOrder(data);
        this.setTenderTypes(tenderTypes);
        this.setSourceTypes(sources);
        const selectedTenders = tenderTypes.reduce((selected, tender) => {
          selected[tender] = true;
          return selected;
        }, {});
        const selectedSources = sources.reduce((selected, source) => {
          selected[source] = true;
          return selected;
        }, {});
        this.updateFilterTenders(selectedTenders);
        this.updateFilterSources(selectedSources);
        this.props.store.ReportsStore.setSourceData(rows);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your sales by tender. Please try again"
        );
      });
  };

  getTenderTotal = (tableData, key) => {
    const tenderType = key.split("_^")?.pop();
    if (!tenderType) return;
    return (
      tableData.length &&
      tableData.reduce(
        (sum, row) =>
          sum +
          ((row.tenders &&
            row.tenders.find((tender) => tender.type === tenderType)?.amount) ||
            0),
        0
      )
    );
  };

  getCellTotal = (tenders, tenderType) =>
    tenders?.reduce((accumulator, tender) => {
      if (tender.type === tenderType)
        return accumulator + Number(tender.amount);
      return accumulator;
    }, 0) || 0;

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue, tableData } =
      this.props.store.ReportsStore;
    const { orderId = "" } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          let { key, width, flexGrow, align } = column;
          if (key.includes("col_lineCount")) {
            width = "9em";
          }
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow, textAlign: align }}
              key={`header_${key}`}
            >
              {key === "col_order_id" && (
                <TextInputFilter
                  value={orderId}
                  setValue={(value) => updateFilterValue("orderId", value)}
                />
              )}
              {key === "col_source" && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.setShowFilterSourceModal(true)}
                >
                  Sources
                </button>
              )}
              {key.includes("col_lineCount") && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.setShowFilterTenderModal(true)}
                >
                  Tender Types
                </button>
              )}
              {key.includes("col_tender_") && (
                <CurrencyRenderer
                  column={column}
                  cellData={this.getTenderTotal(tableData, key)}
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_subtotal" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.subTotal, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_shipping" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.shipping, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_discount" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.discount, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_tax" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.tax, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
              {key === "col_total" && (
                <CurrencyRenderer
                  column={column}
                  cellData={
                    tableData.length &&
                    tableData.reduce((sum, row) => sum + row.total, 0)
                  }
                  style={{ textAlign: "right", paddingRight: "0.8em" }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "orderId",
      condition: (filterValues) => filterValues.orderId,
      filterFunction: (row, filterValues) =>
        row.orderId && row.orderId.includes(filterValues.orderId),
    },
    {
      name: "tender type",
      condition: () => true,
      filterFunction: (row, filterValues) => {
        if (
          Object.values(filterValues.tenderTypes).filter((enabled) => enabled)
            .length === this.tenderTypes.length
        ) {
          // No filtering in use, return all rows
          return row;
        }
        return (
          row.tenders &&
          !!row.tenders.find((tender) => filterValues.tenderTypes[tender.type])
        );
      },
    },
    {
      name: "source",
      condition: () => true,
      filterFunction: (row, filterValues) =>
        row.source && filterValues.sources[row.source],
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders1 = [
      {
        name: "Date",
        key: "closedAt",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      { name: "Order Id", key: "orderId" },
      { name: "Source", key: "source" },
      { name: "Items", key: "lineItems" },
    ];
    const csvHeaders2 = [
      {
        name: "Subtotal",
        key: "subTotal",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Shipping",
        key: "shipping",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Discount",
        key: "discount",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Tax",
        key: "tax",
        formatter: (number) => number && number.toFixed(2),
      },
      {
        name: "Total",
        key: "total",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "Sales By Tender.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;
    console.log({ data });
    const csvHeadersWithTenders = this.tenderTypes.reduce(
      (arr, tender) => [
        ...arr,
        {
          name: tender,
          key: tender,
          formatter: (number) => number && number.toFixed(2),
        },
      ],
      csvHeaders1
    );
    const csvHeaders = [...csvHeadersWithTenders, ...csvHeaders2];

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Sales By Tender</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      fixed
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="col_date"
                        dataKey="closedAt"
                        width={200}
                        cellRenderer={(props) =>
                          TimeUtils.convertDateToHumanReadable(
                            props.cellData,
                            this.timeZone
                          )
                        }
                        title="Date"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_order_id"
                        dataKey="orderId"
                        width={150}
                        title="Order Id"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_source"
                        dataKey="source"
                        width={150}
                        align="right"
                        title="Source"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) =>
                          cellData.replace(/_/g, " ")
                        }
                      />
                      <Column
                        key="col_lineCount"
                        dataKey="lineCount"
                        width={100}
                        align="right"
                        title="Items"
                        sortable
                        resizable
                      />
                      {Object.keys(this.filterTenders)
                        .filter((type) => this.filterTenders[type])
                        .map((tenderType, index) => (
                          <Column
                            key={`col_tender_${index}_^${tenderType}`}
                            dataGetter={({ rowData }) =>
                              this.getCellTotal(rowData?.tenders, tenderType)
                            }
                            width={110}
                            align="right"
                            title={tenderType}
                            sortable
                            resizable
                            currency={MainStore.currencyCode}
                            cellRenderer={CurrencyRenderer}
                          />
                        ))}
                      <Column
                        key="col_subtotal"
                        dataKey="subTotal"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Subtotal"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_shipping"
                        dataKey="shipping"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Shipping"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_discount"
                        dataKey="discount"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Discount"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_tax"
                        dataKey="tax"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Tax"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_total"
                        dataKey="total"
                        width={100}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Total"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
        {this.showFilterTenderModal && (
          <TenderTypeSelect
            options={this.tenderTypes}
            value={this.filterTenders}
            setValue={this.updateFilterTenders}
            onClose={() => this.setShowFilterTenderModal(false)}
          />
        )}
        {this.showFilterSourceModal && (
          <SalesSourceSelect
            options={this.sourceTypes}
            value={this.filterSources}
            setValue={this.updateFilterSources}
            onClose={() => this.setShowFilterSourceModal(false)}
          />
        )}
      </Fragment>
    );
  }
}

SalesByTender.propTypes = { store: PropTypes.object };
export default SalesByTender;

export const showMenuItem = (
  userSettings: ScreenSettings,
  menuItemSetting: undefined | null | string
) => {
  // Icons that can be shown to any user
  if (menuItemSetting === null) {
    return true;
  }
  // User needs settings, but no specific setting
  if (menuItemSetting === undefined && userSettings) {
    return true;
  }
  return userSettings[menuItemSetting];
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { ReportsMenuStructure } from "../../menuStructures";

@inject("store")
@observer
class Home extends Component {
  componentDidMount() {
    document.title = "Events | BinderPOS";
    const { MenuStore, WindowSizeStore } = this.props.store;
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    WindowSizeStore.setWindow();
  }

  render() {
    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              Reports<span className="beta">BETA</span>
            </span>
          </h2>
        </div>
        <div className="reports-menu row">
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Sales Reports</h5>
                <p className="card-text">
                  View sales data grouped by various parameters, such as
                  category and tender type. Generate WPN reports.
                </p>
                <Link to="/reports/sales" className="btn btn-primary">
                  View Sales Reports
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Stock Reports</h5>
                <p className="card-text">View reports of stock values.</p>
                <Link to="/reports/stock" className="btn btn-primary">
                  View Stock Reports
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Store Credit Reports</h5>
                <p className="card-text">
                  View store credit spending and amounts outstanding.
                </p>
                <Link to="/reports/store-credit" className="btn btn-primary">
                  View Store Credit Reports
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Online Buylist Reports</h5>
                <p className="card-text">View online buylist data by date.</p>
                <Link to="/reports/buylist" className="btn btn-primary">
                  View Online Buylist Reports
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Home.propTypes = { store: PropTypes.object };
export default Home;

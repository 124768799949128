import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ButtonComponent from "../../components/generic/ButtonComponent";
import IconButton from "../../components/generic/IconButton";
import Loader from "../../components/generic/Loader";
import ActionButtonsLayout from "../../components/layout/ActionButtonsLayout";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import { useShowError } from "../../hooks/errorHooks";
import {
  useFetchEventParticipants,
  useFetchEventAdditionalInfo,
} from "../../hooks/eventHooks";
import EventMenuStructure from "../../menuStructures/EventMenuStructure";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import { reformatParticipantAdditionalInfo } from "../../helpers/eventHelpers";

type RouteParams = {
  eventId: string;
};

function EventParticipants() {
  const { eventId } = useParams<RouteParams>();
  const showError = useShowError();

  const {
    data: participants,
    isLoading: isLoadingParticipants,
    error: participantsError,
  } = useFetchEventParticipants(Number(eventId));

  const {
    data: additionalInfo,
    isLoading: isLoadingAdditionalInfo,
    error: additionalInfoError,
  } = useFetchEventAdditionalInfo(Number(eventId));

  useEffect(() => {
    document.title = "Event Participants | BinderPOS";
  }, []);

  useEffect(() => {
    if (participantsError) {
      showError(
        null,
        "Failed to load event details",
        "There was an error retrieving your event participants. Please refresh and try again"
      );
    } else if (additionalInfoError) {
      showError(
        null,
        "Failed to load event details",
        "There was an error retrieving your event details. Please refresh and try again"
      );
    }
  }, [participantsError, additionalInfoError]);

  const downloadCSV = (csv: string, filename: string) => {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const exportTableToCsv = () => {
    const csv = [];
    const pushRow = (row: string[]) => csv.push(row.join(","));
    const header = [
      "Name",
      "Email",
      ...additionalInfo.map((field) => field.header),
    ];
    pushRow(header);
    participants.forEach((participant) =>
      pushRow([
        participant.participantName,
        participant.participantEmail,
        ...reformatParticipantAdditionalInfo(participant.additionalInfo).map(
          (info) => info.value
        ),
      ])
    );
    downloadCSV(csv.join("\n"), "eventParticipants.csv");
  };

  if (isLoadingAdditionalInfo || isLoadingParticipants) {
    return <Loader />;
  }

  if (participantsError || additionalInfoError) {
    return null;
  }

  return (
    <>
      <SetActiveMenu menuStructure={EventMenuStructure} />
      <SectionHeaderLayout title="Event Participants">
        <ActionButtonsLayout>
          <ButtonComponent
            isLink={true}
            to={`/events/participants/${eventId}/add`}
            icon="fa fa-plus"
            iconPosition="left"
          >
            Add Participant
          </ButtonComponent>
          <ButtonComponent
            onClick={exportTableToCsv}
            icon="far fa-download"
            iconPosition="left"
          >
            Download participant list
          </ButtonComponent>
        </ActionButtonsLayout>
      </SectionHeaderLayout>
      <div className="wrapper eventPartipants">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {additionalInfo.map((field) => (
                <th key={field.id}>{field.header}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {participants.map((participant) => (
              <tr key={participant.id}>
                <td>{participant.participantName}</td>
                <td>{participant.participantEmail}</td>
                {reformatParticipantAdditionalInfo(
                  participant.additionalInfo
                ).map((info) => (
                  <td key={info.id}>{info.value}</td>
                ))}
                <td>
                  <IconButton
                    icon="fal fa-user-edit"
                    isLink
                    to={`/events/participants/${eventId}/update/${participant.id}`}
                    title="Edit participant"
                  />
                  <IconButton
                    icon="fal fa-ban"
                    danger
                    isLink
                    to={`/events/participants/${eventId}/remove/${participant.id}`}
                    title="Remove participant"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EventParticipants;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { doApproveBuylist, doCompleteBuylist } from "../../api/rest/buylist";
import { useShowError } from "../../hooks/errorHooks";
import {
  useLocalSetting,
  useLocalSettingUpdate,
} from "../../hooks/globalSettingsHooks";

const APPLY_CREDIT_SETTING = "buylistApplyStoreCredit";
const PUSH_STOCK_SETTING = "buylistPushStock";

enum ModalType {
  Approve = "approve",
  Complete = "complete",
}

interface ApproveBuylistModalProps {
  zeroQuantityDetected: boolean;
  paymentType: string;
  buylistDetail: BuylistDetail;
  handleClose: () => void;
}

type ShopifyCustomer = {
  firstName: string;
  lastName: string;
};

type BuylistDetail = {
  shopifyCustomer?: ShopifyCustomer;
};

const formatConfimationMessage = (
  modalType: ModalType,
  shopifyCustomer?: ShopifyCustomer
) => {
  const action =
    modalType === ModalType.Complete ? "approved and completed" : "approved";
  const customer = shopifyCustomer
    ? `from ${[shopifyCustomer.firstName, shopifyCustomer.lastName].join(" ")}`
    : "";
  return `You have ${action} the buylist ${customer}`;
};

function ApproveBuylistModal(props: ApproveBuylistModalProps) {
  const { zeroQuantityDetected, paymentType, buylistDetail, handleClose } =
    props;
  const history = useHistory();
  const showError = useShowError();
  const updateSetting = useLocalSettingUpdate();
  const defaultApplyCreditSetting = Boolean(
    useLocalSetting(APPLY_CREDIT_SETTING)
  );
  const defaultPushStockSetting = Boolean(useLocalSetting(PUSH_STOCK_SETTING));
  const [isLoading, setIsLoading] = useState(false);
  const [pushProducts, setPushProducts] = useState(false);
  const [applyStoreCredit, setApplyStoreCredit] = useState(false);
  const [approvedNotes, setApprovedNotes] = useState("");
  const [showModal, setShowModal] = useState<ModalType | undefined>();

  useEffect(() => {
    setApplyStoreCredit(defaultApplyCreditSetting);
  }, [defaultApplyCreditSetting]);

  useEffect(() => {
    setPushProducts(defaultPushStockSetting);
  }, [defaultPushStockSetting]);

  const handleChangePushProducts = () => {
    const _pushProducts = !pushProducts;
    setPushProducts(_pushProducts);
    updateSetting(PUSH_STOCK_SETTING, _pushProducts);
  };

  const handleChangeApplyStoreCredit = () => {
    const _applyStoreCredit = !applyStoreCredit;
    setApplyStoreCredit(_applyStoreCredit);
    updateSetting(APPLY_CREDIT_SETTING, _applyStoreCredit);
  };

  const handleApprove = () => {
    setIsLoading(true);
    const _buylistDetail = JSON.parse(JSON.stringify(buylistDetail));
    _buylistDetail.approvedNotes = approvedNotes;
    _buylistDetail.pushProducts = pushProducts;
    if (paymentType === "Store Credit") {
      _buylistDetail.applyStoreCredit = applyStoreCredit;
    }

    let buylistAction: Function;
    let modal: ModalType;
    if (pushProducts && applyStoreCredit) {
      buylistAction = doCompleteBuylist;
      modal = ModalType.Complete;
    } else {
      buylistAction = doApproveBuylist;
      modal = ModalType.Approve;
    }

    buylistAction(_buylistDetail)
      .then(() => {
        setShowModal(modal);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          `Failed to approve buylist`,
          "There was an error submitting your buylist. Please try again"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirm = () => {
    handleClose();
    history.push("/buylists/pending");
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <form noValidate>
          <header className="modal-card-head">
            <p className="modal-card-title">Approve buylist request</p>
            <button
              className="delete"
              aria-label="close"
              disabled={isLoading}
              onClick={handleClose}
            >
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            {zeroQuantityDetected ? (
              <div className="warning">
                <i className="fas fa-exclamation-triangle" />
                One of more cards in this buylist have no quantity set. To go
                back and correct this, click <em>Cancel</em>.
              </div>
            ) : null}
            <div className="wrapper reviewInputs">
              <div className="field">
                <label className="label">
                  Do you want to add any notes? These will be sent to the
                  customer once approved.
                </label>
                <div className="control">
                  <textarea
                    className="input"
                    placeholder="E.g. Most cards are in great condition! Thank you."
                    value={approvedNotes}
                    onChange={(event) => setApprovedNotes(event.target.value)}
                  />
                </div>
              </div>
              <p>
                Checking both &apos;Push stock automatically&apos; and
                &apos;Apply store credit&apos; will mark the buylist as
                completed
              </p>
              <div className="field">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={pushProducts}
                    onChange={handleChangePushProducts}
                  />
                  <span className="checkmark" />
                  &nbsp; Push stock automatically
                </label>
              </div>
              {paymentType == "Store Credit" ? (
                <div className="field">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={applyStoreCredit}
                      onChange={handleChangeApplyStoreCredit}
                    />
                    <span className="checkmark" />
                    &nbsp; Apply store credit
                  </label>
                </div>
              ) : null}
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              type="button"
              className="modalBtn cancel"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`modalBtn action ${isLoading ? " is-loading" : ""}`}
              disabled={isLoading}
              onClick={() => handleApprove()}
            >
              {isLoading ? "Processing" : "Approve"}
            </button>
          </footer>
        </form>
      </div>
      {showModal ? (
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {showModal === ModalType.Complete
                  ? "Buylist Approved and Completed!"
                  : "Buylist Approved"}
              </p>
            </header>
            <section className="modal-card-body">
              {formatConfimationMessage(
                showModal,
                buylistDetail?.shopifyCustomer
              )}
            </section>
            <footer className="modal-card-foot">
              <button className="modalBtn action full" onClick={handleConfirm}>
                Ok
              </button>
            </footer>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ApproveBuylistModal;

import React, { useEffect, useState } from "react";
import {
  EbayStockChangeInfo as StockChangeInfo,
  fetchEbayStockChangeInfoBySku,
} from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import EbayStockChangeInfo from "../../../components/integrations/EbayStockChangeInfo";

const parseError = (err: { data: string }, setErrorText: Function) => {
  try {
    const errObj = JSON.parse(err.data);
    if (errObj.message) setErrorText(errObj.message);
  } catch (e) {
    // no-op
  }
};

interface StockChangeInfoContainerProps {
  id: number;
}

function StockChangeInfoContainer(props: StockChangeInfoContainerProps) {
  const { id } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const [stockChangeInfo, setStockChangeInfo] = useState<StockChangeInfo>();

  useEffect(() => {
    fetchEbayStockChangeInfoBySku(id)
      .then((data: StockChangeInfo) => setStockChangeInfo(data))
      .catch((err) => {
        setError(true);
        parseError(err, setErrorText);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  if (error) return <p>{errorText ? errorText : "Unable to load details"}</p>;

  return <EbayStockChangeInfo {...stockChangeInfo} />;
}

export default StockChangeInfoContainer;

import React, { ReactChild } from "react";
import { Link } from "react-router-dom";
import "./LinkComponent.scss";

interface LinkComponentProps {
  children: ReactChild;
  to: string;
}

function LinkComponent(props: LinkComponentProps) {
  const { children, to } = props;

  return (
    <Link to={to} className="link-component">
      {children}
    </Link>
  );
}

export default LinkComponent;

import React from "react";
import { NavLink } from "react-router-dom";
import Logout from "./Logout";
import DesktopMenu from "./DesktopMenu";
import SideMenu from "./SideMenu";
import UserAvatar from "./UserAvatar";
import SettingsMenuStructure from "../../menuStructures/SettingsMenuStructure";
import "./DesktopHeader.scss";
import Watermark from "../../../assets/img/watermark.png";
import Logo from "../../../assets/img/logo.png";

declare global {
  interface Window {
    FreshWidget: {
      show: () => void;
    };
  }
}

interface DesktopHeaderProps {
  storeName?: string;
  currentTill?: string;
  sideMenu?: MenuStructure;
  screenSettings: ScreenSettings;
  updateMenu: (menu: MenuStructure | undefined | null) => void;
  handleLogout: () => void;
}

function DesktopHeader(props: DesktopHeaderProps) {
  const {
    sideMenu,
    storeName,
    currentTill,
    screenSettings,
    updateMenu,
    handleLogout,
  } = props;

  const openFresh = () => {
    window.FreshWidget.show();
  };

  return (
    <>
      <div className="watermark">
        <img src={Watermark} />
      </div>
      <div className="topCnrBg" />
      <div className="brand">
        <img src={Logo} />
      </div>
      <div className="company">
        {storeName} {currentTill ? `(${currentTill})` : null}
      </div>
      <div className="app-sidebar">
        <div className="topCnrBgLight" />
        <div className="leftBgAngle" />
        <div className="leftBgBlur" />
        <div className="POSangle" />
        <DesktopMenu screenSettings={screenSettings} updateMenu={updateMenu} />
      </div>
      <nav
        className="DesktopHeader__navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <SideMenu sideMenu={sideMenu} />
        <div className="DesktopHeader__topNav">
          <NavLink
            className="supportlink"
            activeClassName="active"
            to="/settings"
            onClick={() => updateMenu(SettingsMenuStructure)}
          >
            <div className="DesktopHeader__navIcon" title="Settings">
              <i className="fas fa-cogs" />
            </div>
          </NavLink>
          <div className="supportlink DesktopHeader__navIcon">
            <i
              className="fas fa-user-headset"
              onClick={() => openFresh()}
              title="Support"
            />
          </div>
          <Logout onLogout={handleLogout} />
          <UserAvatar />
        </div>
      </nav>
    </>
  );
}
export default DesktopHeader;

/**
 * Compare function for use in sort function.
 * Handles strings, numbers, dates (as strings).
 * Strings are compared in a case-insenitive manner
 *
 * @param {(string|number)} a
 * @param {(string|number)} b
 *
 * @returns int
 */
export const mixedSearchCompareFunction = (a, b) =>
  a !== null &&
  String(a).localeCompare(String(b), undefined, {
    sensitivity: "base",
    numeric: true,
  });

/**
 * Dives down through nested objects to match a string of keys seperated by dot notation
 * e.g objectDive(obj, "first.second.third") would return 5 if a.first.second.third = 5;
 *
 * @param {object} obj
 * @param {string} keys
 *
 * @returns any
 */
export const objectDive = (obj, keys) => {
  const [nextKey, ...remainder] = keys.split(".");
  if (!obj || !Object.prototype.hasOwnProperty.call(obj, nextKey)) {
    return null;
  }
  if (!remainder.length) {
    return obj[nextKey];
  }
  return objectDive(obj[nextKey], remainder.join("."));
};

/**
 * Converts data to an RFC4180 compliant CSV structure
 *
 * @param {array} headers Object containing a name field for display and a key to refence a column in the data
 * @param {array} data Array of objects, where each object represents a line in the CSV file
 *
 * returns Promise
 */
export const convertToCsv = (headers, data) => {
  const charsNeedingEscape = new RegExp(/,|"|\r|\n/);
  const escapeField = (field) =>
    charsNeedingEscape.test(String(field))
      ? `"${String(field).replace(/"/g, '""')}"`
      : field;
  return new Promise((resolve) => {
    const response = [
      headers.map((header) => escapeField(header.name)).join(","),
    ];
    data.forEach((row) =>
      response.push(
        headers
          .map((header) =>
            escapeField(
              header.formatter
                ? header.formatter(objectDive(row, header.key))
                : objectDive(row, header.key)
            )
          )
          .join(",")
      )
    );
    resolve(response.join("\r\n"));
  });
};

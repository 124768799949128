import React, { Fragment, Component } from "react";
import { inject, observer } from "mobx-react";
import { API_STATE } from "../../constants/api";
import { getStoreCreditOutstanding } from "../../api/graphql/reports";
import { reformatStoreCreditOustanding } from "../../utils/reports";
import { ReportsMenuStructure } from "../../menuStructures";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import "react-base-table/styles.css";
import PropTypes from "prop-types";

@inject("store")
@observer
class StoreCreditOutstanding extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
    this.loadData();
  }

  loadData = () => {
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getStoreCreditOutstanding()
      .then((data) => {
        const rows = reformatStoreCreditOustanding(data);
        this.props.store.ReportsStore.setSourceData(rows);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your outstanding store credit report. Please try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;
    const {
      customerPhone = "",
      customerName = "",
      customerEmail = "",
    } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_customerName" && (
                <TextInputFilter
                  value={customerName}
                  setValue={(value) => updateFilterValue("customerName", value)}
                />
              )}
              {key === "col_customerEmail" && (
                <TextInputFilter
                  value={customerEmail}
                  setValue={(value) =>
                    updateFilterValue("customerEmail", value)
                  }
                />
              )}
              {key === "col_customerPhone" && (
                <TextInputFilter
                  value={customerPhone}
                  setValue={(value) =>
                    updateFilterValue("customerPhone", value)
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "customer name",
      condition: (filterValues) =>
        filterValues.customerName && filterValues.customerName.length,
      filterFunction: (customer, filterValues) =>
        (customer.firstName &&
          customer.firstName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())) ||
        (customer.lastName &&
          customer.lastName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())),
    },
    {
      name: "customer email",
      condition: (filterValues) =>
        filterValues.customerEmail && filterValues.customerEmail.length,
      filterFunction: (customer, filterValues) =>
        customer.email &&
        customer.email
          .toLowerCase()
          .includes(filterValues.customerEmail.toLowerCase()),
    },
    {
      name: "customer phone",
      condition: (filterValues) =>
        filterValues.customerPhone && filterValues.customerPhone.length,
      filterFunction: (customer, filterValues) =>
        customer.phone &&
        String(customer.phone).includes(filterValues.customerPhone),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Customer First Name", key: "firstName" },
      { name: "Customer Last Name", key: "lastName" },
      { name: "Customer Email", key: "email" },
      { name: "Customer Phone", key: "phone" },
      {
        name: "Store Credit",
        key: "storeCredit",
        formatter: (number) => number && number.toFixed(2),
      },
    ];

    const csvFilename = "Outstanding Store Credit Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Outstanding Store Credit Report</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls csvHeaders={csvHeaders} csvFilename={csvFilename} />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                      rowKey="buylistId"
                    >
                      <Column
                        key="col_customerName"
                        dataKey="customerName"
                        dataGetter={({ rowData }) =>
                          [rowData.firstName, rowData.lastName].join(" ")
                        }
                        align="left"
                        title="Customer Name"
                        width={100}
                        flexGrow={3}
                        sortable
                        resizable
                      />
                      <Column
                        key="col_customerEmail"
                        dataKey="email"
                        align="left"
                        title="Customer Email"
                        width={100}
                        flexGrow={3}
                        sortable
                        resizable
                      />
                      <Column
                        key="col_customerPhone"
                        dataKey="phone"
                        width={150}
                        flexGrow={1}
                        align="right"
                        title="Phone Number"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_storeCredit"
                        dataKey="storeCredit"
                        width={150}
                        flexGrow={1}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Store Credit"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

StoreCreditOutstanding.propTypes = { store: PropTypes.object };
export default StoreCreditOutstanding;

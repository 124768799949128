import React from "react";
import PropTypes from "prop-types";
import { API_STATE } from "../../constants/api";

function Pagination(props) {
  const {
    apiState,
    currentPage,
    totalRowCount,
    rowsPerPage,
    rowPerPageOptions,
    setCurrentPage,
    setRowsPerPage,
  } = props;

  if (apiState !== API_STATE.SUCCESS) {
    return null;
  }

  return (
    <div className="pagination">
      <button
        className="previous"
        type="button"
        aria-label="previous page"
        disabled={currentPage < 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        Previous
      </button>
      <span className="page-info">
        Page
        <input
          type="number"
          aria-label="jump to page"
          min="1"
          max={Math.ceil(totalRowCount / rowsPerPage)}
          value={currentPage + 1}
          onChange={(event) => {
            setCurrentPage(parseInt(event.target.value, 10) - 1);
          }}
        />
        of {Math.ceil(totalRowCount / rowsPerPage)}
      </span>
      <select
        aria-label="rows per page"
        value={rowsPerPage}
        onChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
      >
        {rowPerPageOptions.map((number) => (
          <option key={number} value={number}>
            {number} rows
          </option>
        ))}
      </select>
      <button
        className="next"
        type="button"
        aria-label="next page"
        disabled={(currentPage + 1) * rowsPerPage >= totalRowCount}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
}

Pagination.propTypes = {
  apiState: PropTypes.string,
  currentPage: PropTypes.number,
  totalRowCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowPerPageOptions: PropTypes.array.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};

export default Pagination;

import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import "./EbayRemoveShippingService.scss";

interface EbayRemoveShippingServiceProps {
  sortOrder: number;
  description?: string;
  deleteShippingOption: Function;
  cancel: Function;
}

function EbayRemoveShippingService(props: EbayRemoveShippingServiceProps) {
  const { sortOrder, description, deleteShippingOption, cancel } = props;

  return (
    <div className="modal is-active ebay-remove-shipping-service">
      <div className="modal-background" />
      <div className="modal-card">
        Are you sure you want to delete the shipping service {description}?
        <div className="modal-foot ebay-remove-shipping-service__actions">
          <ButtonComponent secondary onClick={() => cancel()}>
            Cancel
          </ButtonComponent>
          <ButtonComponent onClick={() => deleteShippingOption(sortOrder)}>
            Ok
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
}

export default EbayRemoveShippingService;

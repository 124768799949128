import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";

@inject("posStore")
@observer
class CreditProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  @observable
  overrideCounter = 20;

  @action
  decrementOverrideCounter() {
    this.overrideCounter--;
  }

  componentDidMount() {
    const timeout = setInterval(() => {
      this.decrementOverrideCounter();
      if (this.overrideCounter < 1) {
        clearInterval(timeout);
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  @action
  cancel = () => {
    this.props.posStore.endProcessing();
    this.props.posStore.setSubmittingCart(false);
  };

  render() {
    return (
      <div className={"modal is-active"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Charging Amount: {this.props.posStore.processingData.amount}
            </p>
            <button
              data-testid="close-button"
              className="delete"
              aria-label="close"
              onClick={() => this.cancel()}
            />
          </header>
          <section className="modal-card-body">
            <div className="field">{this.props.posStore.processingMessage}</div>
            {this.overrideCounter ? null : (
              <div className="field">
                Ensure payment was processed before clicking FORCE FINALIZE
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <button className={"modalBtn cancel"} onClick={() => this.cancel()}>
              Cancel
            </button>
            {this.props.posStore.processingAction ? (
              <button
                className={"modalBtn cancel"}
                onClick={() =>
                  this.props.posStore.processingAction.action(
                    this.props.posStore.processingAction.args
                  )
                }
              >
                {this.props.posStore.processingAction.text}
              </button>
            ) : null}
            <button
              disabled={this.overrideCounter}
              className={"modalBtn cancel"}
              style={{ background: "red" }}
              onClick={() => this.props.posStore.forceFinalizeCredit()}
            >
              FORCE FINALIZE{" "}
              {this.overrideCounter ? this.overrideCounter : null}
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default CreditProcess;

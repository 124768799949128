import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ReactToPrint from "react-to-print";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class Receipt extends Component {
  calculateTotalItems() {
    let items = 0;
    this.props.store.CartStore.selectedCart.cartItems.forEach((cartItem) => {
      if (cartItem.quantity > 0) {
        items += cartItem.quantity;
      }
    });
    return items;
  }

  render() {
    const store = this.props.store.MainStore;
    const cart = this.props.store.CartStore.selectedCart;
    const totalItems = this.calculateTotalItems();
    return (
      <div className="receipt">
        <div className="wrapper">
          <span className="name">{store.storeInfo?.storeName}</span>
          <span className="details">Tel: {store.storeInfo?.phoneNumber}</span>
          <span className="details">{store.storeInfo?.addressLine1}</span>
          <span className="details">{store.storeInfo?.addressLine2}</span>
          <span className="details">
            {store.storeInfo?.city} {store.storeInfo?.state}{" "}
          </span>
          <span className="details">
            {store.storeInfo?.country} {store.storeInfo?.zipCode}{" "}
          </span>
          {store.taxNumber ? (
            <span className="taxNumber">
              {store.taxWording + " " + store.taxNumber}
            </span>
          ) : (
            ""
          )}
          <span className="giftReceipt">Gift Receipt</span>
          <span className="saleId">
            Sale# {cart.id}
            <em>Till: {cart.till.name}</em>
          </span>
          <span className="dateTime">
            {TimeUtils.convertDateToHumanReadableNoTime(cart.dateSubmitted)}
            <em>{TimeUtils.convertTime(cart.dateSubmitted)}</em>
          </span>
          <hr />
          <table>
            <tbody>
              {cart.cartItems.map((item, index) => (
                <tr key={index}>
                  <td className="qty">{item.quantity}</td>
                  <td className="itemTitle">
                    {item.productTitle}
                    <em>
                      {item.variantTitle != "-"
                        ? " - [" + item.variantTitle + "]"
                        : ""}
                    </em>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <span className="itemTotalCount">
            ** {totalItems} Items purchased on this sale **
          </span>
        </div>
      </div>
    );
  }
}

@observer
class GiftReceipt extends Component {
  closeReceipt = () => {
    this.props.closeReceipt();
  };

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card receiptSize">
          <header className="modal-card-head">
            <p className="modal-card-title">Gift Receipt</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => this.closeReceipt()}
            >
              {" "}
              <i className="fal fa-times"></i> Close{" "}
            </button>
          </header>
          <section className="modal-card-body">
            <Receipt ref={(el) => (this.componentRef = el)} />
          </section>
          <footer className="modal-card-foot">
            <ReactToPrint
              trigger={() => (
                <button className={"modalBtn action full"}>Print</button>
              )}
              content={() => this.componentRef}
            />
          </footer>
        </div>
      </div>
    );
  }
}

export default GiftReceipt;

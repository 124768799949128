import React, { useState } from "react";
import PropTypes from "prop-types";

function TenderTypeSelect({ options = [], value = {}, setValue, onClose }) {
  const [selected, setSelected] = useState(value);

  const handleClick = (event) => {
    const { name, checked } = event.target;
    setSelected({ ...selected, [name]: checked });
  };

  return (
    <div className={"modal is-active"}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Filter Tender Type</p>
          <button className="delete" aria-label="close" onClick={onClose}>
            <i className="fal fa-times" /> Close
          </button>
        </header>
        <section className="modal-card-body">
          {options.map((option, index) => (
            <div className="field" key={index}>
              <label className="checkbox">
                <input
                  type="checkbox"
                  name={option}
                  checked={selected[option] || false}
                  onChange={handleClick}
                />
                <span className="checkmark"></span>
                {option}
              </label>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setValue(selected);
            }}
          >
            Update
          </button>
        </section>
      </div>
    </div>
  );
}

TenderTypeSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TenderTypeSelect;

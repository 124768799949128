import React, { Component } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import prettierArray from "./prettierArray.js";

@inject("posStore")
@observer
class QuickMenuEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "fas fa-address-book",
    };
  }
  @action
  closeModal = () => {
    console.log("Closing modal...");
    this.props.posStore.editingQuickItem = null;
    this.props.posStore.updateQuickLinks();
  };

  @action
  updateQuickTitle = (e) => {
    console.log({ updatedQuickTitle: e.target.value });
    this.props.posStore.editingQuickItem.value.title = e.target.value;
    this.props.posStore.editingQuickItem.value.cardName = null;
    this.props.posStore.editingQuickItem.value.setName = null;
  };

  @action
  updateIcon = (value) => {
    console.log({ updatedIcon: value });
    this.props.posStore.editingQuickItem.icon = value;
  };

  @action
  updateColor = (e) => {
    console.log({ updatedColor: e.target.value });
    this.props.posStore.editingQuickItem.color = e.target.value;
  };

  render() {
    // prettier-ignore
    const props = {
      icons: prettierArray,
      theme: "bluegrey",
      renderUsing: "class",
      value: this.props.posStore.editingQuickItem
        ? this.props.posStore.editingQuickItem.icon
        : "fas fa-address-book",
      onChange: this.updateIcon,
      isMulti: false
    };
    const store = this.props.posStore;
    return store.editingQuickItem ? (
      <div className={"modal display-block"}>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit the Quicklink</p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.closeModal}
            >
              <i className="fal fa-times"></i> Close
            </button>
          </header>
          <section className="modal-card-body color">
            <p>
              {" "}
              Title:{" "}
              <input
                type="text"
                value={this.props.posStore.editingQuickItem.value.title}
                onChange={this.updateQuickTitle}
                data-testid="quick-title"
              ></input>{" "}
            </p>
            {this.props.posStore.editingQuickItem.type == "folder" ? (
              <div>
                <span style={{ fontWeight: 600 }}>Icon</span>:{" "}
                <FontIconPicker {...props} />
              </div>
            ) : null}
            <input
              type="color"
              onChange={this.updateColor}
              value={this.props.posStore.editingQuickItem.color}
            />
          </section>

          <footer className="modal-card-foot">
            <button
              className={`modalBtn action full`}
              onClick={this.closeModal}
            >
              Done <i className="fas fa-caret-right"></i>
            </button>
          </footer>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default QuickMenuEdit;

import React, { useEffect, useState } from "react";
import { getLatestBlogPosts, Post } from "../api/graphql/devBlog";
import { useFeature } from "../hooks/globalSettingsHooks";
import DevBlogPost from "./DevBlogPost";

const POSTS = 5;

function DevBlog() {
  const [posts, setPosts] = useState<Post[]>([]);
  const featureEnabled = useFeature("devBlog");

  const fetchBlogPosts = () =>
    getLatestBlogPosts(POSTS)
      .then((data) => {
        if (data?.posts) {
          setPosts(data?.posts?.edges);
        }
      })
      .catch((error) => console.error(error));

  useEffect(() => {
    if (featureEnabled) {
      fetchBlogPosts();
    }
  }, [featureEnabled]);

  if (!posts || posts.length < 1) return null;

  return (
    <div className="DevBlog">
      <h2>Latest updates</h2>
      {posts.map((post) => (
        <DevBlogPost key={post.node.id} post={post} />
      ))}
    </div>
  );
}

export default DevBlog;

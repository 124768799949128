import React, { useState } from "react";
import { toast } from "react-toastify";
import { doPushStock } from "../../api/rest/buylist";
import ConfirmationModal from "../generic/ConfirmationModal";

interface PushStockProps {
  buylistId: number;
  pushProducts?: boolean;
}

function PushStock(props: PushStockProps) {
  const { buylistId, pushProducts } = props;
  const [isPushing, setIsPushing] = useState(false);
  const [hasPushed, setHasPushed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmClick = () => {
    setShowConfirmation(false);
    setIsPushing(true);
    doPushStock(buylistId)
      .then(() => {
        setIsPushing(false);
        setHasPushed(true);
        toast.info("Stock has been pushed successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to push stock");
        setIsPushing(false);
      });
  };

  const handleCancelClick = () => setShowConfirmation(false);

  const handleUncompleteClick = () => setShowConfirmation(true);

  if (pushProducts !== false) {
    return null;
  }

  return (
    <>
      <button
        className="btn1 cancel"
        onClick={() => handleUncompleteClick()}
        disabled={isPushing || hasPushed}
      >
        {isPushing ? "Pushing..." : "Push Stock"}
      </button>
      {showConfirmation ? (
        <ConfirmationModal
          cancelAction={handleCancelClick}
          confirmAction={handleConfirmClick}
          title="Push Stock"
          text="Are you sure you want to push the stock for this buylist"
        />
      ) : null}
    </>
  );
}

export default PushStock;

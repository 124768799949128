import { ConfirmationModal, ErrorModal, Header, Loader } from "./components";
import { inject, observer } from "mobx-react";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import "./services/firebase";

import "../css/portal-style.scss";
import LoginPage from "./views/auth/LoginPage";

import PosRouteWatcher from "./routers/PosRouteWatcher";
import FreshChat from "./components/services/FreshChat";
import ApiProvider from "./providers/ApiProvider";
import GlobalSettingsProvider from "./providers/GlobalSettingsProvider";
import ErrorProvider from "./providers/ErrorProvider";
import StoreProvider from "./providers/StoreProvider";
import DefaultAppRouter from "./routers/DefaultAppRouter";
import KioskRouter from "./routers/KioskRouter";
import { OperationalMode, operationalMode } from "./constants/mode";
import ErrorBoundary from "./components/error/ErrorBoundary";
import FullLogo from "../assets/img/fullLogo.png";

@inject("store")
@observer
class App extends React.Component {
  menuToOpen = (dataFromChild) => {
    this.props.store.MenuStore.setSideMenuToDisplay(dataFromChild);
  };

  render() {
    const router =
      operationalMode === OperationalMode.Kiosk ? (
        <KioskRouter />
      ) : (
        <DefaultAppRouter />
      );
    return (
      <ErrorBoundary>
        <ErrorProvider>
          {this.props.store.MainStore.error ? <ErrorModal /> : null}
          {this.props.store.MainStore.info ? (
            <ConfirmationModal
              type="confirm"
              title={this.props.store.MainStore.info.title}
              text={this.props.store.MainStore.info.message}
              confirmAction={() => this.props.store.MainStore.setInfo(null)}
            />
          ) : null}
          <Router>
            {this.props.store.AuthStore.hasLoaded ? (
              <>
                {this.props.store.AuthStore.user &&
                this.props.store.AuthStore.screenSettings &&
                this.props.store.AuthStore.screenSettings != "No account" &&
                this.props.store.AuthStore.screenSettings !== "Error" ? (
                  <ApiProvider>
                    <GlobalSettingsProvider>
                      <StoreProvider>
                        <Header
                          sideMenu={
                            this.props.store.MenuStore.sideMenuToDisplay
                          }
                          sideMenuUpdate={this.menuToOpen}
                          screenSettings={
                            this.props.store.AuthStore.screenSettings
                          }
                        />

                        <main
                          id="binderpos-app"
                          className={
                            this.props.store.MenuStore.sideMenuToDisplay
                              ? "hasSubMenu"
                              : ""
                          }
                        >
                          <PosRouteWatcher />
                          <div className="app-content">
                            <section className="section">{router}</section>
                          </div>
                        </main>
                        {operationalMode !== OperationalMode.Kiosk ? (
                          <FreshChat
                            storeSettings={
                              this.props.store.SettingsStore.storeSettings
                            }
                          />
                        ) : null}
                      </StoreProvider>
                    </GlobalSettingsProvider>
                  </ApiProvider>
                ) : (
                  <LoginPage />
                )}
              </>
            ) : (
              <div id="login-page" className="login-center">
                <div className="row">
                  <div className="col-md-7 leftBg">
                    <img className="login-logo" src={FullLogo} />
                  </div>
                  <div className="col-md-5">
                    <div className="loginWrapper">
                      <Loader />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Router>
        </ErrorProvider>
      </ErrorBoundary>
    );
  }
}

export default App;

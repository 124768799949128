import React, { Component } from "react";
import PropTypes from "prop-types";

class ViewNotes extends Component {
  render() {
    return (
      <div className="ViewNotes modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">View Notes</p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.props.onClose}
            />
          </header>
          <section className="modal-card-body">
            <div className="ViewNotes__content field">{this.props.notes}</div>
          </section>
          <footer className="modal-card-foot">
            <button
              className={"ViewNotes__close-button"}
              onClick={this.props.onClose}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

ViewNotes.defaultProps = {
  notes: "",
};

ViewNotes.propTypes = {
  notes: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ViewNotes;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomerMenuStructure from "../../menuStructures/CustomerMenuStructure";
import { useShowError } from "../../hooks/errorHooks";
import { useDebounce } from "../../hooks/inputHooks";
import { fetchCustomerList } from "../../api/rest/customers";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Paging from "../../components/generic/Paging";
import Spinner from "../../components/generic/Spinner";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import CustomerAvatar from "../../components/customers/CustomerAvatar";
import AdjustStoreCreditModal from "../../components/customers/AdjustStoreCreditModal";
import StoreCreditHistory from "../../components/customers/StoreCreditHistory";
import CustomerCsvUploadModal from "../../components/customers/CustomerCsvUploadModal";
import "./CustomerList.scss";

const WAIT_INTERVAL = 625;
const LIMIT = 10;

function CustomerList() {
  const [offset, setOffset] = useState(0);
  const [searchString, setSearchString] = useState<string>();
  const debouncedSearchString: string = useDebounce<string>(
    searchString,
    WAIT_INTERVAL
  );
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [adjustCreditModalVisible, setAdjustCreditModalVisible] =
    useState(false);
  const [customerToAdjust, setCustomerToAdjust] = useState<Customer>();
  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [showHistoryForCustomer, setShowHistoryForCustomer] =
    useState<Customer>();
  const showError = useShowError();

  useEffect(() => {
    document.title = "Customers | BinderPOS";
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (offset > 0) {
      setOffset(0);
    } else {
      fetchCustomers();
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    fetchCustomers();
  }, [offset]);

  const fetchCustomers = (refresh = false) => {
    setIsLoading(true);
    fetchCustomerList(offset, LIMIT, debouncedSearchString, refresh)
      .then((result: Customer[]) => {
        setCustomers(result);
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to load customers",
          "There was an error retrieving your list of customers. Please refresh and try again"
        );
      })
      .finally(() => setIsLoading(false));
  };

  const onAdjustStoreCreditComplete = () => {
    fetchCustomers();
    setAdjustCreditModalVisible(false);
    setCustomerToAdjust(undefined);
  };

  const viewHistory = (customer: Customer) => {
    setShowHistoryForCustomer(customer);
  };

  const showAdjustModal = (customer: Customer) => {
    setCustomerToAdjust(customer);
    setAdjustCreditModalVisible(true);
  };

  const getNextCustomers = () => {
    const newOffset = offset + LIMIT;
    setOffset(newOffset);
  };

  const getPrevCustomers = () => {
    const newOffset = Math.max(offset - LIMIT, 0);
    setOffset(newOffset);
  };

  return (
    <div className="CustomerList">
      <SetActiveMenu menuStructure={CustomerMenuStructure} />
      <SectionHeaderLayout title="My Customers">
        <div>
          <i className="far fa-search CustomerList__searchIcon" />
          <input
            autoComplete="off"
            className="CustomerList__search"
            type="text"
            placeholder="Search customer"
            onChange={(event) => setSearchString(event.target.value)}
            value={searchString || ""}
          />
        </div>
      </SectionHeaderLayout>
      <div className="CustomerList__actions">
        <Link to="/customers/add" className="CustomerList__link">
          <i className="fa fa-plus" />
          Add Customer
        </Link>
        <ButtonComponent
          onClick={() => setUploadFileModalVisible(true)}
          icon="fal fa-upload"
          iconPosition="left"
        >
          Upload Store Credit
        </ButtonComponent>
        <ButtonComponent
          onClick={() => fetchCustomers(true)}
          icon="fas fa-sync-alt"
          iconPosition="left"
        >
          Refresh
        </ButtonComponent>
      </div>
      <Spinner isLoading={isLoading}>
        <table>
          <thead>
            <tr>
              <td style={{ paddingLeft: "29px" }} colSpan={2}>
                Name
              </td>
              <td>Email</td>
              <td>Phone</td>
              <td>Store Credit</td>
              <td className="text-center">Edit customer details</td>
              <td className="text-center">Store credit history</td>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr className="list_item" key={customer.id}>
                <td
                  style={{
                    width: "60px",
                    paddingLeft: "23px",
                  }}
                  className={
                    customer.storeCredit > 0
                      ? "text-center red"
                      : "text-center blue"
                  }
                >
                  <CustomerAvatar
                    customer={customer}
                    className={customer.storeCredit > 0 ? "red" : "blue"}
                  />
                </td>
                <td className="text-bold">
                  {customer.firstName} {customer.lastName}
                </td>
                <td>
                  {customer.email ? (
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                  ) : (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      />
                      <span className="noEmail"> No Email Provided</span>
                    </>
                  )}
                </td>
                <td>
                  {customer.phone ? (
                    <a href={`tel:${customer.phone}`}>{customer.phone}</a>
                  ) : (
                    <>
                      <span>N/A</span>
                    </>
                  )}
                </td>
                <td>
                  <a
                    title="Adjust store credit"
                    className="userActions"
                    onClick={() => showAdjustModal(customer)}
                  >
                    <i className="fal fa-badge-dollar" /> $
                    {
                      // @ts-ignore
                      !isNaN(customer.storeCredit)
                        ? // @ts-ignore
                          customer.storeCredit.toFixed(2)
                        : " - "
                    }
                  </a>
                </td>
                <td className="text-center">
                  <Link
                    title="Edit customer details."
                    to={`/customers/update/${customer.id}`}
                    className="userActions"
                  >
                    <i className="far fa-edit edit" />
                  </Link>
                </td>
                <td className="text-center">
                  {" "}
                  <a
                    className="userActions"
                    title="Store credit history"
                    onClick={() => viewHistory(customer)}
                  >
                    <i className="far fa-history history" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Paging
          pageOffset={offset}
          currentPageItemCount={customers.length}
          maxItemsPerPage={LIMIT}
          getPrevPage={getPrevCustomers}
          getNextPage={getNextCustomers}
        />
      </Spinner>
      {showHistoryForCustomer ? (
        <StoreCreditHistory
          customer={showHistoryForCustomer}
          handleClose={() => setShowHistoryForCustomer(undefined)}
        />
      ) : null}
      {adjustCreditModalVisible ? (
        <AdjustStoreCreditModal
          customer={customerToAdjust}
          onComplete={onAdjustStoreCreditComplete}
          handleClose={() => setAdjustCreditModalVisible(false)}
        />
      ) : null}
      {uploadFileModalVisible ? (
        <CustomerCsvUploadModal
          handleClose={() => setUploadFileModalVisible(false)}
        />
      ) : null}
    </div>
  );
}

export default CustomerList;

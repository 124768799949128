import React from "react";
import "./Thanksgiving.scss";

const END_OF_MESSAGE = 1638183600 * 1000; // Mon Nov 29 2021 11:00:00 GMT+0000

function Thanksgiving() {
  const now = Date.now();

  if (now > END_OF_MESSAGE) {
    return null;
  }

  return (
    <div className="Thanksgiving">
      <p className="Thanksgiving__greeting">Hello Everyone,</p>
      <p>
        We will have limited support this week due to the Thanksgiving Holiday
        in the US. Our team will be away starting the 25th of November for a
        long weekend. If you need our support please send us your inquiry to{" "}
        <a href="mailto:support@binderpos.com" className="Thanksgiving__link">
          support@binderpos.com
        </a>{" "}
        and we will get back to you as soon as possible.
      </p>
      <p>Best,</p>
      <p className="Thanksgiving__binderpos">BinderPOS</p>
    </div>
  );
}

export default Thanksgiving;

import React from "react";
import ButtonComponent from "../generic/ButtonComponent";
import "./EbayDeleteSetting.scss";

interface EbayDeleteSettingProps {
  id: number;
  name: string;
  isDeleting: boolean;
  handleCancel: Function;
  handleDelete: Function;
}

function EbayDeleteSetting(props: EbayDeleteSettingProps) {
  const { id, name, isDeleting, handleCancel, handleDelete } = props;

  return (
    <div className="ebay-delete-setting">
      <p className="ebay-delete-setting__prompt">
        Are you sure you want to delete {name}?
      </p>
      <div className="ebay-delete-setting__actions">
        <ButtonComponent secondary onClick={() => handleCancel()}>
          Cancel
        </ButtonComponent>
        <ButtonComponent onClick={() => handleDelete(id)} disabled={isDeleting}>
          Delete
        </ButtonComponent>
      </div>
    </div>
  );
}

export default EbayDeleteSetting;

import React from "react";
import { Game, Setting } from "../../api/rest/integrations";
import { useFormatCurrency } from "../../hooks/storeHooks";
import ViewEbaySettings from "./ViewEbaySettings";

interface ViewSettingModalProps {
  visible?: boolean;
  setting: Setting;
  games: Game[];
  integration: string;
  integrationSyncFriendlyName: string;
  handleClose: Function;
}

function ViewSettingModal(props: ViewSettingModalProps) {
  const {
    visible,
    games,
    setting,
    integration,
    integrationSyncFriendlyName,
    handleClose,
  } = props;

  if (!visible) return null;

  const gameName = games.find((game) => game.gameId === setting.game)?.gameName;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card modal-card-large">
        <header className="modal-card-head">
          <p className="modal-card-title">View setting</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => handleClose()}
          >
            <i className="fal fa-times" /> Close
          </button>
        </header>
        <section className="modal-card-body">
          <div className="wrapper view-integration-settings">
            {gameName ? (
              <>
                <span className="label">Game</span>
                <span className="value">{gameName}</span>
                <span className="label">Set</span>
                <span className="value">{setting.setName || "All Sets"}</span>
              </>
            ) : null}
            {setting?.productType ? (
              <>
                <span className="label">Product</span>
                <span className="value">{setting.productType}</span>
              </>
            ) : null}
            <span className="label">Sync enabled</span>
            <span className="value">
              {setting.syncEnabled ? "Enabled" : "Disabled"}
            </span>
            <span className="label">Price markup percentage</span>
            <span className="value">
              {Number.isFinite(setting.priceMarkup)
                ? `${Number(setting.priceMarkup) * 100}%`
                : null}
            </span>
            <span className="label">Reserve Stock</span>
            <span className="value">{setting.reserveStock}</span>
            <span className="label">
              Maximum to list on {integrationSyncFriendlyName}
            </span>
            <span className="value">{setting.maxToList}</span>
            <span className="label">Minimum Price</span>
            <span className="value">
              {Number.isFinite(setting.minPrice)
                ? useFormatCurrency(setting.minPrice)
                : null}
            </span>
            <span className="label">Maximum Price</span>
            <span className="value">
              {Number.isFinite(setting.maxPrice)
                ? useFormatCurrency(setting.maxPrice)
                : null}
            </span>
            {integration === "ebay" ? (
              <ViewEbaySettings setting={setting} />
            ) : null}
          </div>
        </section>
      </div>
    </div>
  );
}

ViewSettingModal.defaultProps = {
  visible: false,
};

export default ViewSettingModal;

import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Buylist,
  EndOfDay,
  FloatReport,
  Home,
  SalesByOrderBeta,
  SalesByTender,
  SalesByTenderBeta,
  SalesHome,
  StockHome,
  StockValue,
  StoreCreditByCustomer,
  StoreCreditHome,
  StoreCreditOutstanding,
  StoreCreditTransactions,
  SubmittedCarts,
  TotalSalesByCategory,
  WPNReport,
} from "../views/reports";

function ReportsRouter() {
  return (
    <Switch>
      <Route exact path="/reports/sales" component={SalesHome} />
      <Route exact path="/reports/sales/tender" component={SalesByTender} />
      <Route
        exact
        path="/reports/sales/tender-beta"
        component={SalesByTenderBeta}
      />
      <Route
        exact
        path="/reports/sales/order-beta"
        component={SalesByOrderBeta}
      />
      <Route
        exact
        path="/reports/sales/category"
        component={TotalSalesByCategory}
      />
      <Route exact path="/reports/sales/carts" component={SubmittedCarts} />
      <Route exact path="/reports/sales/float" component={FloatReport} />
      <Route exact path="/reports/sales/wpn" component={WPNReport} />
      <Route exact path="/reports/sales/end-of-day" component={EndOfDay} />
      <Route exact path="/reports/stock" component={StockHome} />
      <Route exact path="/reports/stock/value" component={StockValue} />
      <Route exact path="/reports/buylist" component={Buylist} />
      <Route exact path="/reports/store-credit" component={StoreCreditHome} />
      <Route
        exact
        path="/reports/store-credit/transactions"
        component={StoreCreditTransactions}
      />
      <Route
        exact
        path="/reports/store-credit/outstanding"
        component={StoreCreditOutstanding}
      />
      <Route
        exact
        path="/reports/store-credit/customer"
        component={StoreCreditByCustomer}
      />
      <Route path="/reports" component={Home} />
    </Switch>
  );
}

export default ReportsRouter;

import React, { useEffect, useState } from "react";
import {
  fetchSyncSettingsProductCount,
  Setting,
} from "../../api/rest/integrations";
import ButtonComponent from "../../components/generic/ButtonComponent";
import Loader from "../../components/generic/Loader";
import { percentToDecimal } from "../../utils/integrations";

const pluariseListings = (count?: number) =>
  count === 1 ? "listing" : "listings";

interface EbayListingConfirmationComponentProps {
  setting: Setting;
  isExistingSetting: boolean;
  setShowConfirmation: Function;
}

function EbayListingConfirmationComponent(
  props: EbayListingConfirmationComponentProps
) {
  const { setting, isExistingSetting, setShowConfirmation } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState<number>();

  const getCount = () => {
    fetchSyncSettingsProductCount(percentToDecimal(setting))
      .then((data: any) => {
        setCount(data?.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getCount(), []);

  if (isLoading)
    return (
      <div className="basic-modal modal is-active">
        <div className="modal-background" />
        <div className="basic-modal__container modal-card">
          <Loader />
        </div>
      </div>
    );

  if (!Number.isInteger(count))
    return (
      <div className="basic-modal modal is-active">
        <div className="modal-background" />
        <div className="basic-modal__container modal-card">
          Failed to create setting
          <div>
            <ButtonComponent
              secondary
              onClick={() => setShowConfirmation(false)}
            >
              Close
            </ButtonComponent>
          </div>
        </div>
      </div>
    );

  return (
    <div className="basic-modal modal is-active">
      <div className="modal-background" />
      <div className="basic-modal__container modal-card">
        <h3>
          {isExistingSetting
            ? `This setting will potentially update ${
                count || "multiple"
              } ${pluariseListings(
                count
              )}, including creating new listings. This is an estimate and the actual number may vary`
            : `This setting will create ${
                count || "multiple"
              } ${pluariseListings(count)}`}
        </h3>
        <p>
          Each listing created on eBay has a listing fee associated with it. For
          more details on fees,{" "}
          <a
            href="https://www.ebay.com/help/selling/fees-credits-invoices/store-selling-fees?id=4122"
            target="_blank"
            rel="noreferrer"
          >
            see here
          </a>
        </p>
        <p>
          Are you sure you want to {isExistingSetting ? "update" : "create"}{" "}
          this rule?
        </p>
        <span>
          <ButtonComponent type="submit">Continue</ButtonComponent>{" "}
          <ButtonComponent secondary onClick={() => setShowConfirmation(false)}>
            Cancel
          </ButtonComponent>
        </span>
      </div>
    </div>
  );
}

export default EbayListingConfirmationComponent;

import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import LineDiscount from "./LineDiscount";
import { OperationalMode, operationalMode } from "../../constants/mode";
import NoImage from "../../../assets/img/noimage.png";
import BuyIcon from "../../../assets/img/buy.svg";
import SellIcon from "../../../assets/img/sell.svg";

@inject("posStore")
@observer
class CartLine extends Component {
  constructor(props) {
    super(props);
    this.state = { discountModal: false };
  }
  componentDidMount() {
    if (typeof this.selfRef.scrollIntoView !== "function") return;
    this.selfRef.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
  state = {};

  @action.bound
  updateQty(e) {
    const qty = e.target.value;
    const delta = Number(qty) - this.props.item.qty;
    if (Number.isFinite(delta)) {
      this.updateInventory(delta);
    }
    if (qty <= 0) {
      this.deleteLine();
    } else {
      this.props.item.qty = e.target.value;
      this.props.posStore.validateCartNoRefresh();
    }
  }

  @action
  changeQty(increase) {
    clearTimeout(this.props.posStore.timer);
    if (increase) {
      this.props.item.qty = this.props.item.qty + 1;
      this.updateInventory(1);
    } else {
      this.updateInventory(-1);
      if (this.props.item.qty - 1 <= 0) {
        return this.deleteLine();
      } else {
        this.props.item.qty = this.props.item.qty - 1;
      }
    }
    this.props.posStore.validateCartNoRefresh();
  }

  @action updateInventory = (quantity) => {
    const item = this.props.posStore.items.find((_item) =>
      _item.variants.some((variant) => variant.id == this.props.item.variantId)
    );
    if (!item) return;
    if (this.props.store.buyMode && item.selectedBuyVariant > -1) {
      item.variants[item.selectedBuyVariant].quantity += quantity;
    }
    if (!this.props.store.buyMode && item.selectedVariant > -1) {
      item.variants[item.selectedVariant].quantity -= quantity;
    }
  };

  @action
  deleteLine = () => {
    this.props.posStore.removeFromCart(this.props.item);
  };

  @action
  toggleTax = () => {
    if (this.props.item.taxDisabledUI === false) {
      this.props.item.taxDisabledUI = true;
    } else {
      this.props.item.taxDisabledUI = false;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  openDiscountModal = () => {
    this.setState({ discountModal: !this.state.discountModal });
    this.toggled = true;
  };

  closeDiscountModal = () => {
    this.setState({ discountModal: false });
    this.toggleTax = false;
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updatePrice = (e) => {
    this.props.item.actualPrice = parseFloat(e.target.value);
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    if (!this.props.item.buyItem && this.props.item.actualPrice < 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  @action
  updateTotal = (e) => {
    this.props.item.actualPrice = e.target.value / this.props.item.qty;
    if (isNaN(this.props.item.actualPrice)) {
      this.props.item.actualPrice = 0.0;
    }
    if (this.props.item.buyItem && this.props.item.actualPrice > 0) {
      this.props.item.actualPrice = -1 * this.props.item.actualPrice;
    }
    this.props.posStore.validateCartNoRefresh();
  };

  blurOnEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };
  toggled = false;

  setDiscountInput = (x) => {
    this.discountInput = x;
    if (this.toggled && this.discountInput) {
      this.discountInput.focus();
      this.discountInput.select();
      this.toggled = false;
    }
  };

  render() {
    var item = this.props.item;
    const fC = this.props.posStore.fCurr;
    const roundCents = this.props.posStore.roundCents;
    let itemName;
    if (item.title.match(/(.+) \[(.+)\]/)) {
      itemName = item.title.match(/(.+) \[(.+)\]/)[1];
      var setName = item.title.match(/(.+) \[(.+)\]/)[2];
    } else {
      itemName = item.title;
    }

    const isKioskMode = operationalMode === OperationalMode.Kiosk;

    const removeButton = (
      <span className="remove" onClick={this.deleteLine}>
        <i className="fad fa-trash-alt" /> Remove
      </span>
    );

    return (
      <React.Fragment>
        <div
          className={
            (!item.buyItem ? "cartItem buy" : "cartItem sell") +
            (this.props.posStore.disableLineItems ||
            this.props.posStore.cartLoading ||
            this.props.posStore.isDeletingCartItem
              ? " disabled"
              : "")
          }
          key={Math.random()}
          ref={(el) => {
            this.selfRef = el;
          }}
        >
          <img className="img" src={item.imageUrl ? item.imageUrl : NoImage} />
          {!item.buyItem ? (
            <img className="cartBadge" src={BuyIcon} alt="" />
          ) : (
            <img className="cartBadge" src={SellIcon} alt="" />
          )}
          <div className="itemWrapper">
            <div className="data">
              <div className="titles">
                {itemName} <br />
                <strong>{setName}</strong>{" "}
                <small>
                  {item.variantTitle != "-"
                    ? "- [" + item.variantTitle + "]"
                    : ""}
                </small>
                {!item.variantId ? "[Custom Item]" : ""}
              </div>
              {!isKioskMode ? removeButton : null}
              <div className="inputs">
                <input
                  type="number"
                  onBlur={this.updatePrice}
                  decimalplaces="2"
                  onKeyPress={this.blurOnEnter}
                  defaultValue={parseFloat(item.tax.displayPrice).toFixed(2)}
                  data-testid="tax-input"
                />
                <input
                  className="lineTotal"
                  type="number"
                  decimalplaces="2"
                  onBlur={this.updateTotal}
                  onChange={this.formatPrice}
                  onKeyPress={this.blurOnEnter}
                  defaultValue={item.displayTotal.toFixed(2)}
                  data-testid="total-input"
                />
              </div>
            </div>
            <div className="actions">
              <div className="qtySelector">
                <span className="minus" onClick={() => this.changeQty(false)}>
                  -
                </span>
                <input
                  type="number"
                  step="1"
                  disabled={this.props.posStore.cartLoading}
                  defaultValue={item.qty}
                  onBlur={this.updateQty}
                  onKeyPress={this.blurOnEnter}
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                />
                <span className="plus" onClick={() => this.changeQty(true)}>
                  +
                </span>
              </div>
              {isKioskMode ? (
                <div className="kiosk-remove-wrapper">{removeButton}</div>
              ) : (
                <span className="lineDiscount" onClick={this.openDiscountModal}>
                  {item.discountValue ? (
                    <span
                      className="ItemDiscount"
                      onClick={this.openDiscountModal}
                    >
                      {item.discountType == "percentage"
                        ? item.discountAmount + "% "
                        : ""}
                      {"Discount: " + fC(roundCents(item.discountValue))}
                    </span>
                  ) : (
                    <span
                      className="ItemDiscount"
                      onClick={this.openDiscountModal}
                    >
                      {" "}
                      Add a Discount
                    </span>
                  )}
                </span>
              )}
              <div className="greybox">
                {!item.buyItem ? (
                  <span className="tax" onClick={this.toggleTax}>
                    {item.taxDisabledShopify
                      ? "Tax Free"
                      : item.taxDisabledUI
                      ? "Tax Disabled"
                      : item.specialTax
                      ? item.specialTax + " TAX"
                      : "Standard TAX"}
                    {}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <a className="hidden">{item.displayTotal}</a>
              <a className="hidden">{item.qty}</a>
              <a className="hidden">{item.tax.displayPrice}</a>
            </div>
            {this.state.discountModal ? (
              <LineDiscount
                item={item}
                close={this.closeDiscountModal}
                discountInput={this.setDiscountInput}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CartLine;

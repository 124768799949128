import { ReportsMenuStructure } from "../../menuStructures";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { betaCustomers } from "../../constants/reports";

@inject("store")
@observer
class Sales extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MenuStore } = this.props.store;
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
  }

  render() {
    const { SettingsStore } = this.props.store;
    const storeId = SettingsStore?.storeSettings?.id;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Sales Reports</span>
          </h2>
        </div>
        <div className="reports-menu row">
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Sales By Tender</h5>
                <p className="card-text">
                  View sales figures for each tender type.
                </p>
                <Link to="/reports/sales/tender" className="btn btn-primary">
                  View Report
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Sales By Category</h5>
                <p className="card-text">
                  View sales figures for different categories of products.
                </p>
                <Link to="/reports/sales/category" className="btn btn-primary">
                  View Stock Reports
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Till Floats</h5>
                <p className="card-text">
                  View details of float adjustments made in tills along with a
                  ledger history for each float.
                </p>
                <Link to="/reports/sales/float" className="btn btn-primary">
                  View Float Reports
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">WPN Premium Reports</h5>
                <p className="card-text">
                  Generate a report for the Wizards Play Network.
                </p>
                <Link to="/reports/sales/wpn" className="btn btn-primary">
                  View Report
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">End of Day Reports</h5>
                <p className="card-text">
                  Opening and closing amounts for tills, broken down by tender.
                </p>
                <Link
                  to="/reports/sales/end-of-day"
                  className="btn btn-primary"
                >
                  View Report
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Submitted Carts Report</h5>
                <p className="card-text">
                  See details of transactions between selected dates.
                </p>
                <Link to="/reports/sales/carts" className="btn btn-primary">
                  View Report
                </Link>
              </div>
            </div>
          </div>
          {betaCustomers.includes(storeId) ? (
            <>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Sales By Order - Beta</h5>
                    <p className="card-text">
                      Test report. Please do not use unless instructed.
                    </p>
                    <Link
                      to="/reports/sales/order-beta"
                      className="btn btn-primary"
                    >
                      View Report
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Sales By Tender - Beta</h5>
                    <p className="card-text">
                      Test report. Please do not use unless instructed.
                    </p>
                    <Link
                      to="/reports/sales/tender-beta"
                      className="btn btn-primary"
                    >
                      View Report
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

Sales.propTypes = { store: PropTypes.object };
export default Sales;

import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { EventMenuStructure } from "../../menuStructures";
import { repushFailedEvent } from "../../api/rest/events";
import { Loader } from "../../components";
import ConfirmationModal from "../../components/generic/ConfirmationModal";
import TimeUtils from "../../utils/TimeUtils";
import NoBanner from "../../../assets/img/noBanner.png";

@inject("store")
@observer
class EventFailedToCreate extends React.Component {
  @observable offset = 0;

  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;

  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable messageModalText;

  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;

  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;

  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;

  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;

  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Failed events | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);

    this.props.store.EventsStore.rehydrateFailedToCreate(
      this.offset,
      this.limit
    );
  }

  getNextFailedToCreate() {
    this.setOffset(this.offset + 100);
    this.props.store.EventsStore.rehydrateFailedToCreate(
      this.offset,
      this.limit
    );
  }

  getPrevFailedToCreate() {
    let offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.EventsStore.rehydrateFailedToCreate(
      this.offset,
      this.limit
    );
  }

  pushStockThrough(failedToCreate) {
    this.setProcessing(true);
    repushFailedEvent(failedToCreate.id)
      .then((result) => {
        if (result && result.length > 0) {
          this.props.store.EventsStore.setFailedEvents(result);
        } else {
          this.setMessageModalText(
            "Event pushed through to Shopify successfully!"
          );
          this.setMessageModalTitle("Success!");
          this.setMessageModalVisible(true);
        }
        this.setProcessing(false);
        this.props.store.EventsStore.rehydrateFailedToCreate(
          this.offset,
          this.limit
        );
      })
      .catch((error) => {
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to push event",
          "There was an error pushing the event to Shopify. Please try again"
        );
      });
  }

  render() {
    if (!this.props.store.AuthStore.screenSettings.events)
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    if (!this.props.store.EventsStore.failedToCreateEvents) return <Loader />;
    if (this.processing) return <Loader />;

    return (
      <>
        {this.props.store.EventsStore.failedEvents ? (
          <ConfirmationModal
            type="confirm"
            title={
              this.props.store.EventsStore.event
                ? "The following events failed to update"
                : "The following events failed to be created"
            }
            text={this.buildEventsFailedString()}
            confirmAction={() =>
              this.props.store.EventsStore.setFailedEvents(null)
            }
          />
        ) : null}
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              Event tickets that had issues being created
            </span>
            <div className="top-pagination">
              <button
                className="paging-nav large"
                disabled={this.offset == 0}
                onClick={() => this.getPrevFailedToCreate()}
              >
                <i className="fas fa-caret-left" /> Prev
              </button>
              <button
                className="paging-nav large"
                disabled={
                  !!(
                    this.props.store.EventsStore.failedToCreateEvents &&
                    this.props.store.EventsStore.failedToCreateEvents.length <
                      this.limit
                  )
                }
                onClick={() => this.getNextFailedToCreate()}
              >
                Next <i className="fas fa-caret-right" />
              </button>
            </div>
          </h2>
        </div>
        <br />
        <div className="tiledPanels">
          {this.props.store.EventsStore.failedToCreateEvents.map(
            (failedToCreate, i) => (
              <div className="panelWrapper" key={i}>
                <div className="panel">
                  <div className="eventImage">
                    <span className="ticketIcon">
                      <img src={failedToCreate.calendarIcon} />
                    </span>
                    <img
                      src={
                        !failedToCreate.banner == null ||
                        !failedToCreate.banner == ""
                          ? failedToCreate.banner
                          : NoBanner
                      }
                    />
                  </div>
                  <span className="eventTitle">{failedToCreate.title}</span>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="types">
                          Game Type:
                          <em>{failedToCreate.game}</em>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="types">
                          Event Type:
                          <em>{failedToCreate.type}</em>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="info">
                          <i className="far fa-calendar-alt" />
                          {failedToCreate.recurring
                            ? "Recurring"
                            : TimeUtils.convertDateToHumanReadable(
                                failedToCreate.date
                              )}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="info">
                          <i className="far fa-clock" />
                          {TimeUtils.convertTime(failedToCreate.time)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <>
                    <span
                      onClick={() => this.pushStockThrough(failedToCreate)}
                      className="editBtn"
                    >
                      <i className="far fa-calendar-edit" /> Retry event
                      creation
                    </span>
                  </>
                </div>
              </div>
            )
          )}
        </div>
        {this.messageModalVisible ? (
          <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">{this.messageModalTitle}</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => this.setMessageModalVisible(false)}
                />
              </header>
              <section className="modal-card-body">
                <p>{this.messageModalText}</p>
              </section>
              <footer className="modal-card-foot">
                <button
                  type="button"
                  className="modalBtn action full"
                  onClick={() => this.setMessageModalVisible(false)}
                >
                  Ok
                </button>
              </footer>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

EventFailedToCreate.propTypes = { store: PropTypes.object };

export default EventFailedToCreate;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { ReportsMenuStructure } from "../../menuStructures";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import {
  NumberInputFilter,
  TextInputFilter,
  TimestampRenderer,
} from "../../components/reports/CellTypes";
import { API_STATE } from "../../constants/api";
import { fetchAllCarts } from "../../api/rest/posSettings";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import { TillSelect } from "../../components/posSettings";
import TimeUtils from "../../utils/TimeUtils";

@inject("store")
@observer
class SubmittedCarts extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  componentDidMount() {
    document.title = "Point of Sale Submitted Carts | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
    this.getDateRangeFromParams();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getDateRangeFromParams();
    }
  }

  @observable timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  @action setTimeZone = (timeZone) => {
    this.timeZone = timeZone;
  };

  handleDateChange = (fromDate, toDate, timeZone) => {
    const params = new URLSearchParams();
    if (fromDate) params.set("fromDate", fromDate);
    if (toDate) params.set("toDate", toDate);
    if (timeZone) params.set("timezone", timeZone);
    this.props.history.push({
      search: "?" + params.toString(),
    });
  };

  getDateRangeFromParams = () => {
    const query = new URLSearchParams(this.props.location.search);
    if (query.has("fromDate")) {
      this.loadData(
        query.get("fromDate"),
        query.get("toDate"),
        query.get("timezone")
      );
    }
  };

  loadData = (fromDate, toDate, timeZone) => {
    this.setTimeZone(timeZone);
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    fetchAllCarts({ startDate: fromDate, endDate: toDate, limit: 999999 })
      .then((data) => {
        this.props.store.ReportsStore.setSourceData(data);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load report",
          "There was an error retrieving your submitted carts report. Please try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;

    const {
      id = "",
      shopifyOrderId = "",
      tillId = "",
      firstName = "",
      lastName = "",
    } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width, flexGrow }}
              key={`header_${key}`}
            >
              {key === "id" && (
                <NumberInputFilter
                  value={id}
                  setValue={(value) => updateFilterValue("id", value)}
                  title="Filter by cart ID"
                />
              )}
              {key === "shopifyOrderId" && (
                <NumberInputFilter
                  value={shopifyOrderId}
                  setValue={(value) =>
                    updateFilterValue("shopifyOrderId", value)
                  }
                  title="Filter by order ID"
                />
              )}
              {key === "tillName" && (
                <TillSelect
                  value={tillId}
                  setValue={(value) => updateFilterValue("tillId", value)}
                  title="Filter by till"
                />
              )}
              {key === "firstName" && (
                <TextInputFilter
                  value={firstName}
                  setValue={(value) => updateFilterValue("firstName", value)}
                  title="Filter by first name"
                />
              )}
              {key === "lastName" && (
                <TextInputFilter
                  value={lastName}
                  setValue={(value) => updateFilterValue("lastName", value)}
                  title="Filter by last name"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "id",
      condition: (filterValues) => filterValues.id,
      filterFunction: (row, filterValues) =>
        row.id && String(row.id).includes(filterValues.id),
    },
    {
      name: "shopifyOrderId",
      condition: (filterValues) => filterValues.shopifyOrderId,
      filterFunction: (row, filterValues) =>
        row.shopifyOrderId &&
        String(row.shopifyOrderId).includes(filterValues.shopifyOrderId),
    },
    {
      name: "tillId",
      condition: (filterValues) => filterValues.tillId,
      filterFunction: (row, filterValues) =>
        row.till?.id && row.till.id == filterValues.tillId,
    },
    {
      name: "firstName",
      condition: (filterValues) => filterValues.firstName,
      filterFunction: (row, filterValues) =>
        row.customer?.firstName &&
        row.customer.firstName.includes(filterValues.firstName),
    },
    {
      name: "lastName",
      condition: (filterValues) => filterValues.lastName,
      filterFunction: (row, filterValues) =>
        row.customer?.lastName &&
        row.customer.lastName.includes(filterValues.lastName),
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Cart Id", key: "id" },
      { name: "Shopify Order", key: "shopifyOrderId" },
      {
        name: "Date Submitted",
        key: "dateSubmitted",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      { name: "Till", key: "till.name" },
      {
        name: "First Name",
        key: "customer.firstName",
        formatter: (name) => name || "Guest",
      },
      {
        name: "Last Name",
        key: "customer.lastName",
        formatter: (name) => name || "",
      },
      { name: "Processed By", key: "submittedBy" },
    ];

    const csvFilename = "Submitted Carts Report.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    const query = new URLSearchParams(this.props.location.search);
    const defaultFromDate = query.get("fromDate");
    const defaultToDate = query.get("toDate");

    return (
      <>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Submitted Carts Reports</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.handleDateChange}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
            defaultFromDate={defaultFromDate}
            defaultToDate={defaultToDate}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={height}
                      rowHeight={50}
                      sortBy={sortColumn}
                      fixed
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                    >
                      <Column
                        key="id"
                        title="Cart #"
                        dataKey="id"
                        width={110}
                        flexGrow={1}
                        resizable
                        sortable
                      />
                      <Column
                        key="shopifyOrderId"
                        title="Shopify Order #"
                        dataKey="shopifyOrderId"
                        width={140}
                        flexGrow={1}
                        resizable
                        sortable
                      />
                      <Column
                        key="dateSubmitted"
                        title="Date Submitted"
                        dataKey="dateSubmitted"
                        cellRenderer={TimestampRenderer}
                        width={160}
                        resizable
                        sortable
                      />
                      <Column
                        key="tillName"
                        title="Till"
                        dataKey="till.name"
                        width={120}
                        resizable
                        sortable
                      />
                      <Column
                        key="firstName"
                        title="First Name"
                        dataKey="customer"
                        cellRenderer={({ cellData }) =>
                          cellData?.firstName || "Guest"
                        }
                        width={120}
                        resizable
                        sortable
                      />
                      <Column
                        key="lastName"
                        title="Last Name"
                        dataKey="customer.lastName"
                        width={120}
                        flexGrow={1}
                        resizable
                        sortable
                      />
                      <Column
                        key="col_processedBy"
                        title="Processed By"
                        dataKey="submittedBy"
                        width={120}
                        flexGrow={1}
                        resizable
                      />
                      <Column
                        key="col_view"
                        dataKey="id"
                        width={100}
                        cellRenderer={({ cellData }) => (
                          <Link
                            to={`/pointOfSale/carts/${cellData}`}
                            className="viewCart"
                          >
                            <i className="fal fa-eye" /> View
                          </Link>
                        )}
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

SubmittedCarts.propTypes = { store: PropTypes.object };

export default withRouter(SubmittedCarts);

import React from "react";

export default function NumberPagination({
  offset,
  setOffset,
  limit,
  productCount,
}: {
  offset: number;
  setOffset: (offset: number) => void;
  limit: number;
  productCount: number;
}) {
  const page = Math.floor(offset / limit + 1);
  const max = Math.floor(productCount / limit);

  return (
    <div
      className="paging-nav-body"
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: "auto",
      }}
    >
      <button disabled={offset == 0} onClick={() => setOffset(offset - limit)}>
        {"<<"}
      </button>
      {offset > 0 && <button onClick={() => setOffset(0)}>1</button>}
      {page - 2 > 2 && "..."}
      {page - 2 > 1 && (
        <button onClick={() => setOffset(offset - limit * 2)}>
          {page - 2}
        </button>
      )}
      {page - 1 > 1 && (
        <button onClick={() => setOffset(offset - limit)}>{page - 1}</button>
      )}
      <button style={{ background: "#00bdbc" }}>{page}</button>
      {page + 1 < max && (
        <button onClick={() => setOffset(offset + limit)}>{page + 1}</button>
      )}
      {page + 2 < max && (
        <button onClick={() => setOffset(offset + limit * 2)}>
          {page + 2}
        </button>
      )}
      {page + 3 < max && "..."}
      {page < max && (
        <button onClick={() => setOffset(productCount - limit)}>{max}</button>
      )}
      <button
        disabled={productCount < offset + limit}
        onClick={() => setOffset(offset + limit)}
      >
        {">>"}
      </button>
      <span>{productCount.toLocaleString()} total products</span>
    </div>
  );
}

import React from "react";
import TimeUtils from "../../utils/TimeUtils";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { Loader, ReceiptReprint } from "../../components";
import PropTypes from "prop-types";
import CartNotes from "../../components/pos/CartNotes";

@inject("store")
@inject("posStore")
@observer
class CartView extends React.Component {
  @observable showReceiptModal = false;

  @action setShowReceiptModal(showReceiptModal) {
    this.showReceiptModal = showReceiptModal;
  }

  closeReceiptModal = () => {
    this.setShowReceiptModal(false);
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Point of Sale | BinderPOS";
    this.props.store.CartStore.setSelectedCart(null);
    const id = this.props.match?.params?.cartId || null;
    if (id) {
      document.title = `Cart #${id} | BinderPOS`;
      this.props.store.CartStore.fetchCartById(id)
        .then((cart) => {
          this.props.store.CartStore.setSelectedCart(cart);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load cart",
            "There was an error retrieving cart details. Please refresh and try again"
          );
        });
    }
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.props.store.MainStore.buildReceiptData();
  }

  render() {
    const fC = this.props.store.MainStore.currencyBuilder;
    const selectedCart = this.props.store.CartStore?.selectedCart;
    return (
      <>
        {this.props.store.AuthStore.screenSettings.pos ? (
          <>
            {selectedCart ? (
              <>
                {this.showReceiptModal ? (
                  <ReceiptReprint closeReceipt={this.closeReceiptModal} />
                ) : null}
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="header-text">
                      {`Cart #${selectedCart.id}`}
                    </span>
                    <button
                      className="btn1"
                      onClick={() => this.setShowReceiptModal(true)}
                    >
                      Reprint Receipt
                    </button>
                  </h2>
                </div>
                <br />
                <div className="cartviewTop">
                  <table>
                    <thead>
                      <tr>
                        <>
                          <td
                            colSpan="2"
                            className="col2"
                            style={{ paddingLeft: "15px" }}
                          >
                            Customer
                          </td>
                          {selectedCart.customer ? (
                            <td className="col1">Email</td>
                          ) : null}
                          <td className="storeCredit col2">Date submitted</td>
                          <td className="col2">Order #</td>
                        </>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ borderLeft: "0px" }}>
                        {selectedCart.customer ? (
                          <>
                            <td
                              className="avatar"
                              style={{ marginLeft: "15px", marginTop: "10px" }}
                            >
                              <div>
                                {selectedCart.customer.firstName &&
                                selectedCart.customer.firstName.length > 0 ? (
                                  <>
                                    {selectedCart.customer.firstName.charAt(0)}
                                    {selectedCart.customer.lastName &&
                                    selectedCart.customer.lastName.length >
                                      0 ? (
                                      <>
                                        {selectedCart.customer.lastName.charAt(
                                          0
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {selectedCart.customer.email &&
                                    selectedCart.customer.email.length > 0 ? (
                                      <>
                                        {selectedCart.customer.email.charAt(0)}
                                      </>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="name">
                              {selectedCart.customer.firstName}{" "}
                              {selectedCart.customer.lastName}
                            </td>
                            <td>{selectedCart.customer.email}</td>
                          </>
                        ) : (
                          <>
                            <td
                              className="initials"
                              style={{ paddingLeft: "15px" }}
                            >
                              <div>Guest</div>
                            </td>
                            <td className="name">Guest cart</td>
                          </>
                        )}
                        <td>
                          {TimeUtils.convertDateToHumanReadable(
                            selectedCart.dateSubmitted
                          )}
                        </td>
                        <td>{selectedCart.orderNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                  {selectedCart.tenders && selectedCart.tenders.length > 0 ? (
                    <>
                      <table className="cartViewTenders">
                        <thead>
                          <tr>
                            <td style={{ paddingLeft: "15px" }}>
                              Cart tenders below
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedCart.tenders.map((tender, i) => (
                            <tr key={i} style={{ borderLeft: "0px" }}>
                              <td style={{ paddingLeft: "15px" }}>
                                <em>{tender.type} :</em>{" "}
                                {fC(parseFloat(tender.amount))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p>No tenders used</p>
                  )}
                </div>
                <CartNotes rawNotes={selectedCart.cartNotes} />
                <div className="cartviewBottom" style={{ marginTop: "24px" }}>
                  <table className="cartView">
                    <thead>
                      <tr>
                        <td />
                        <td>Product</td>
                        <td>Bought / Sold</td>
                        <td>Quantity</td>
                        <td>Price</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCart.cartItems.map((cartItem, i) => (
                        <React.Fragment key={i}>
                          <tr style={{ borderLeft: "0px" }}>
                            <td>
                              <img
                                className="cart-view-image"
                                src={cartItem.imageSrc}
                              />
                            </td>
                            <td>
                              <div className="cartViewTitle">
                                {cartItem.productTitle}
                              </div>
                              <div className="cartViewTitle">
                                {cartItem.variantTitle}
                              </div>
                            </td>
                            <td>{cartItem.buying ? "Bought" : "Sold"}</td>
                            <td>{cartItem.quantity}</td>
                            <td>
                              {fC(
                                parseFloat(
                                  parseFloat(cartItem.price) /
                                    parseFloat(cartItem.quantity)
                                )
                              )}
                            </td>
                            <td>{fC(parseFloat(cartItem.price))}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                      <tr
                        className="cartViewTotals"
                        style={{ borderLeft: "0px" }}
                      >
                        <td />
                        <td />
                        <td />
                        <td colSpan="3">
                          <div className="cartViewTitle">
                            Sub Total: <em>{fC(selectedCart.subTotal)}</em>
                          </div>
                          <div className="cartViewTitle">
                            Tax included: <em>{fC(selectedCart.totalTax)}</em>
                          </div>
                          <div className="cartViewTitle">
                            Total: <em>{fC(selectedCart.totalPrice)}</em>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

CartView.propTypes = { store: PropTypes.object, match: PropTypes.object };

export default CartView;

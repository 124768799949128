import React, { useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import { toast } from "react-toastify";
import { reauthEbay } from "../../api/rest/ebay";
import { parseEbayError } from "../../utils/ebay";

interface ForceReauthoriseButtonProps {
  integration: string;
}

function ForceReauthoriseButton(props: ForceReauthoriseButtonProps) {
  const { integration } = props;
  const [isReauthing, setIsReauthing] = useState(false);

  const handleResyncClick = () => {
    setIsReauthing(true);
    reauthEbay()
      .then(() => {
        toast.info("Successfully reauthenticated with eBay");
      })
      .catch((error: Error) => {
        const message = parseEbayError(error);
        if (message) {
          toast.error(message);
        } else {
          console.error(error);
          toast.error(
            "Failed to reauthenticate with eBay. Please try again later."
          );
        }
      })
      .finally(() => setIsReauthing(false));
  };

  if (integration !== "ebay") return null;

  return (
    <ButtonComponent disabled={isReauthing} onClick={() => handleResyncClick()}>
      <i className="fas fa-key" />
      Reauth with eBay
    </ButtonComponent>
  );
}

export default ForceReauthoriseButton;

export const bulkUpdateFields = [
  {
    fieldName: "cashBuyPrice",
    optionLabel: "Fixed buy price [ cash ]",
    removeLabel: "Remove buy price [ cash ]",
    inputLabel: "Set cash buy price override to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 29.95",
  },
  {
    fieldName: "cashBuyPercentage",
    optionLabel: "Percentage buy price [ cash ]",
    removeLabel: "Remove percentage buy price [ cash ]",
    inputLabel: "Set percentage buy price [ cash ] to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 30",
  },
  {
    fieldName: "creditBuyPrice",
    optionLabel: "Fixed buy price [ credit ]",
    removeLabel: "Remove buy price [ credit ]",
    inputLabel: "Set fixed buy price [ credit ] override to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 29.95",
  },
  {
    fieldName: "creditBuyPercentage",
    optionLabel: "Percentage buy price [ credit ]",
    removeLabel: "Remove percentage buy price [ credit ]",
    inputLabel: "Set fixed buy price [ credit ] override to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 30",
  },
  {
    fieldName: "buyLimit",
    optionLabel: "Update buy limit",
    removeLabel: "Remove buy limit",
    inputLabel: "Set buy limit to",
    min: 0,
    step: 1,
    placeholder: "E.g. 8",
    helpText:
      "When the buy limit is reached, if the overstocked prices are setup, the prices will change accordingly or the buylist will stop purchasing them altogether.",
  },
  {
    fieldName: "maxInstockBuyPrice",
    optionLabel: "Over-limit buy price [ cash ]",
    removeLabel: "Remove over-limit buy price [ cash ]",
    inputLabel: "Set over-limit buy price [ cash ] to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 29.95",
  },
  {
    fieldName: "maxInstockBuyPercentage",
    optionLabel: "Over-limit % price [ cash ]",
    removeLabel: "Remove over-limit % price [ cash ]",
    inputLabel: "Set over-limit % price [ cash ]",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 29.95",
  },
  {
    fieldName: "maxInstockCreditBuyPrice",
    optionLabel: "Over-limit buy price [ credit ]",
    removeLabel: "Remove over-limit buy price [ credit ]",
    inputLabel: "Set over-limit buy price [ credit ] to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 29.95",
  },
  {
    fieldName: "maxInstockCreditBuyPercentage",
    optionLabel: "Over-limit % price [ credit ]",
    removeLabel: "Remove over-limit % price [ credit ]",
    inputLabel: "Set over-limit % price [ credit ] to",
    min: 0.01,
    step: 0.01,
    placeholder: "E.g. 30",
  },
];

export const fieldLookup = {
  priceOverride: "Price update",
  stock: "Stock",
  cashBuyPrice: "Cash buy price",
  cashBuyPercentage: "Cash buy percent",
  creditBuyPrice: "Credit buy price",
  creditBuyPercentage: "Credit buy percent",
  buyLimit: "Buy limit",
  maxInstockBuyPrice: "Over-limit Cash buy price",
  maxInstockBuyPercentage: "Over-limit Cash buy percent",
  maxInstockCreditBuyPrice: "Over-limit Credit buy price",
  maxInstockCreditBuyPercentage: "Over-limit Credit buy price",
};

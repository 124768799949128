import React from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";

@inject("store")
@inject("posStore")
@observer
class TaxSettings extends React.Component {
  @observable updatingTax = true;

  @action setUpdatingTax(updatingTax) {
    this.updatingTax = updatingTax;
  }

  @observable taxRate;

  @action setTaxRate(taxRate) {
    this.taxRate = taxRate;
  }

  @observable taxIncluded = false;

  @action setTaxIncluded(taxIncluded) {
    if (taxIncluded === "true" || taxIncluded === true) {
      this.taxIncluded = true;
    } else {
      this.taxIncluded = false;
    }
  }

  @observable taxNumber;

  @action setTaxNumber(taxNumber) {
    this.taxNumber = taxNumber;
  }

  @observable tradeInTax = false;

  @action setTradeInTax(tradeInTax) {
    if (tradeInTax === "true" || tradeInTax === true) {
      this.tradeInTax = true;
    } else {
      this.tradeInTax = false;
    }
  }

  @observable taxWording;

  @action setTaxWording(taxWording) {
    this.taxWording = taxWording;
  }

  @observable savingSettings;

  @action setSavingSettings(savingSettings) {
    this.savingSettings = savingSettings;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Tax Settings | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.fetchTaxSettings();
  }

  fetchTaxSettings() {
    this.props.store.SettingsStore.fetchCustomerSettingForType("taxRate")
      .then((result) => {
        this.setTaxRate(result?.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tax settings",
          "There was an error retrieving your tax rate. Please refresh and try again"
        );
      });

    this.props.store.SettingsStore.fetchCustomerSettingForType("taxIncluded")
      .then((result) => {
        this.setTaxIncluded(result?.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tax settings",
          "There was an error retrieving your tax included setting. Please refresh and try again"
        );
      });

    this.props.store.SettingsStore.fetchCustomerSettingForType("taxNumber")
      .then((result) => {
        this.setTaxNumber(result?.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tax settings",
          "There was an error retrieving your tax number. Please refresh and try again"
        );
      });

    this.props.store.SettingsStore.fetchCustomerSettingForType("tradeInTax")
      .then((result) => {
        this.setTradeInTax(result?.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tax settings",
          "There was an error retrieving your trade in tax setting. Please refresh and try again"
        );
      });

    this.props.store.SettingsStore.fetchCustomerSettingForType("taxWording")
      .then((result) => {
        this.setTaxWording(result?.settingValue);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tax settings",
          "There was an error retrieving your tax wording. Please refresh and try again"
        );
      });
    this.setUpdatingTax(true);
  }

  saveTaxSettings() {
    this.setSavingSettings(true);
    console.log({ taxRateRef: this.taxRateRef });
    console.log({ taxIncludedRef: this.taxIncludedRef });
    const newTaxRate = this.taxRate;
    const newTaxIncluded = this.taxIncluded;
    const newTaxNumber = this.taxNumber;
    const newTradeInTax = this.tradeInTax;
    const newTaxWording = this.taxWording;

    const taxRateSettings = {
      settingName: "taxRate",
      settingValue: `${newTaxRate}`,
    };

    const taxIncludedSettings = {
      settingName: "taxIncluded",
      settingValue: `${newTaxIncluded}`,
    };

    const taxNumberSettings = {
      settingName: "taxNumber",
      settingValue: `${newTaxNumber}`,
    };

    const tradeInTaxSettings = {
      settingName: "tradeInTax",
      settingValue: `${newTradeInTax}`,
    };

    const taxWordingSettings = {
      settingName: "taxWording",
      settingValue: `${newTaxWording}`,
    };

    const promises = [];
    promises.push(
      this.props.store.SettingsStore.updateSetting(taxRateSettings)
    );
    promises.push(
      this.props.store.SettingsStore.updateSetting(taxIncludedSettings)
    );
    promises.push(
      this.props.store.SettingsStore.updateSetting(taxNumberSettings)
    );
    promises.push(
      this.props.store.SettingsStore.updateSetting(tradeInTaxSettings)
    );
    promises.push(
      this.props.store.SettingsStore.updateSetting(taxWordingSettings)
    );

    Promise.all(promises)
      .then(() => {
        this.props.store.MainStore.toast("Tax settings updated!");
        this.setUpdatingTax(false);
        this.setSavingSettings(true);
        this.fetchTaxSettings();
        this.props.posStore.getTax();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(error);
        this.setSavingSettings(true);
      });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <>
          <div className="app-header">
            <h2 className="title is-2">
              <span className="header-text">Tax Settings</span>
            </h2>
          </div>
          <div className="infoInputPages">
            <div className="col-md-12 content">
              <div
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                }}
              >
                <div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-6">
                      <label className="label">Your tax rate</label>
                      <div className="control">
                        <input
                          onChange={(e) => {
                            this.setTaxRate(e.target.value);
                            this.setUpdatingTax(false);
                          }}
                          id="taxRate"
                          className="input"
                          type="number"
                          min="0"
                          required
                          placeholder="For example 10%"
                          step="0.01"
                          value={this.taxRate}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <label className="label">Your tax number</label>
                      <div className="control">
                        <input
                          onChange={(e) => {
                            this.setTaxNumber(e.target.value);
                            this.setUpdatingTax(false);
                          }}
                          id="taxNumber"
                          className="input"
                          type="text"
                          placeholder="For example 123-345-678"
                          value={this.taxNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-6">
                      <label className="checkbox">
                        <input
                          onChange={(e) => {
                            this.setTaxIncluded(e.target.checked);
                            this.setUpdatingTax(false);
                          }}
                          id="taxIncluded"
                          type="checkbox"
                          checked={this.taxIncluded}
                        />
                        <span className="checkmark" /> Prices include tax?
                      </label>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <label className="checkbox">
                        <input
                          onChange={(e) => {
                            this.setTradeInTax(e.target.checked);
                            this.setUpdatingTax(false);
                          }}
                          id="tradeTax"
                          type="checkbox"
                          checked={this.tradeInTax}
                        />
                        <span className="checkmark" /> Trade ins include tax?
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-6">
                      <label className="label">Tax Wording for Receipt</label>
                      <div className="control">
                        <input
                          onChange={(e) => {
                            this.setTaxWording(e.target.value);
                            this.setUpdatingTax(false);
                          }}
                          id="taxWording"
                          className="input"
                          type="text"
                          placeholder="For example GST"
                          value={this.taxWording}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-6">
                      <button
                        className="button buttonWide"
                        style={{ marginRight: "10px" }}
                        onClick={() => this.saveTaxSettings()}
                        disabled={this.updatingTax}
                      >
                        Save Tax Settings
                      </button>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                      <button
                        className="button buttonWide"
                        style={{ marginRight: "10px", background: "#ab4747" }}
                        onClick={() => this.fetchTaxSettings()}
                        disabled={this.updatingTax}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

TaxSettings.propTypes = { store: PropTypes.object };

export default TaxSettings;

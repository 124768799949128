import { ErrorMessage, Field } from "formik";
import React, { useEffect } from "react";
import {
  EbayFulfillment,
  EbayLocation,
  EbayPayment,
  EbayReturn,
  EbayParam,
} from "../../api/rest/ebay";
import CategoryContainer from "../../views/integrations/ebay/CategoryContainer";
import { useEbayPolicies } from "../../views/integrations/ebay/EbayPoliciesContext";
import { useStoreDetails } from "../../hooks/storeHooks";
import ListingTemplateContainer from "../../views/integrations/ebay/ListingTemplateContainer";
import EbayCustomAttributes from "./EbayCustomAttributes";
import EbayDescriptionEditor from "./EbayDescriptionEditor";
import "./EbayListingForm.scss";

type PolicyType = EbayFulfillment | EbayLocation | EbayReturn | EbayPayment;

const getPolicies = (policies: PolicyType[]) =>
  policies.map((policy: PolicyType) => (
    <option key={policy.id} value={policy.id}>
      {policy.name}
    </option>
  ));

const getOptions = (options: EbayParam[]) =>
  options.map((option: EbayParam) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

interface EbayListingFormProps {
  integration: string;
  game: string;
  vatPercentage?: string | number;
  applyTax?: boolean;
  setFieldValue: Function;
}

function EbayListingForm(props: EbayListingFormProps) {
  const { integration, game, vatPercentage, applyTax, setFieldValue } = props;
  const { taxRate } = useStoreDetails();

  if (integration !== "ebay") return null;

  const {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
    defaultMarketplace,
    // weightMeasurementUnits,
    // lengthMeasurementUnits,
    // packageTypes,
  } = useEbayPolicies();

  useEffect(() => {
    if (!vatPercentage || String(vatPercentage).length < 1) {
      setFieldValue("vatPercentage", taxRate);
    }
  }, [taxRate]);

  useEffect(() => {
    // Set default policies
    if (locationPolicies && locationPolicies.length === 1) {
      setFieldValue("merchantLocationKey", locationPolicies[0].id);
    }
    if (paymentPolicies && paymentPolicies.length === 1) {
      setFieldValue("paymentPolicyId", paymentPolicies[0].id);
    }
    if (fulfillmentPolicies && fulfillmentPolicies.length === 1) {
      setFieldValue("fulfilmentPolicyId", fulfillmentPolicies[0].id);
    }
    if (returnsPolicies && returnsPolicies.length === 1) {
      setFieldValue("returnPolicyId", returnsPolicies[0].id);
    }
  }, [locationPolicies, paymentPolicies, fulfillmentPolicies, returnsPolicies]);

  return (
    <>
      <br />
      <h5>Listing</h5>
      <div className="ebay-listing-form__setting">
        <label htmlFor="ebayTitleTemplate" className="ebay-listing-form__label">
          Title
        </label>
        <Field name="ebayTitleTemplate">
          {({ field }: any) => (
            <EbayDescriptionEditor
              title
              game={game}
              className="ebay-listing-form__editor"
              name={field.name}
              value={field.value}
              setFieldValue={setFieldValue}
              onChange={field.onChange(field.name)}
            />
          )}
        </Field>
      </div>
      <ErrorMessage
        name="ebayTitleTemplate"
        className="ebay-listing-form__field-error"
        component="div"
      />
      <div className="ebay-listing-form__setting">
        <label
          htmlFor="ebayMobileTemplate"
          className="ebay-listing-form__label"
        >
          Mobile Description
        </label>
        <Field name="ebayMobileTemplate">
          {({ field }: any) => (
            <EbayDescriptionEditor
              mobile
              game={game}
              className="ebay-listing-form__editor"
              name={field.name}
              value={field.value}
              setFieldValue={setFieldValue}
              onChange={field.onChange(field.name)}
            />
          )}
        </Field>
        <ErrorMessage
          name="ebayMobileTemplate"
          className="ebay-listing-form__field-error"
          component="div"
        />
      </div>
      <ListingTemplateContainer
        integration={integration}
        game={game}
        setFieldValue={setFieldValue}
      />
      <ErrorMessage
        name="ebayTemplate"
        className="ebay-listing-form__field-error"
        component="div"
      />
      <div className="ebay-listing-form__setting">
        <Field name="ebayAttributes">
          {({ field }: any) => (
            <EbayCustomAttributes
              ebayCustomAttributes={field.value}
              setFieldValue={setFieldValue}
            />
          )}
        </Field>
      </div>
      <hr />
      <div className="ebay-listing-form__inputs">
        <label htmlFor="ebayCondition">Condition</label>
        <Field
          as="select"
          name="ebayCondition"
          className="ebay-listing-form__select-input"
        >
          {/* @ts-ignore */}
          <option value={null}>Default Condition</option>
          {getOptions(conditions)}
        </Field>
        {/* <h5>Package</h5>
        <span />
        <label htmlFor="packageType">Package Type</label>
        <Field
          as="select"
          name="packageType"
          className="ebay-listing-form__select-input"
        >
          <option value={null}>-- Select Package Type --</option>
          {getOptions(packageTypes)}
        </Field>
        <ErrorMessage
          name="packageType"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="weight">Weight</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="weight" />
          <Field
            as="select"
            name="weightUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(weightMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="weight"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <ErrorMessage
          name="weightUnit"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Width</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="width" />
          <Field
            as="select"
            name="widthUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="width"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Height</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="height" />
          <Field
            as="select"
            name="heightUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="height"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="packageType">Depth</label>
        <span className="ebay-listing-form__compound-input">
          <Field name="depth" />
          <Field
            as="select"
            name="depthUnit"
            className="ebay-listing-form__select-input"
          >
            <option value={null}>-- Select Measurement Unit --</option>
            {getOptions(lengthMeasurementUnits)}
          </Field>
        </span>
        <ErrorMessage
          name="depth"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <hr />
        <span /> */}
        <span>Category</span>
        <CategoryContainer />
        {defaultMarketplace !== "EBAY_US" ? (
          <>
            <h5>Tax</h5>
            <span />
            <label htmlFor="applyTax">Apply Tax</label>
            <Field
              type="checkbox"
              name="applyTax"
              style={{ justifySelf: "left" }}
            />
            <label htmlFor="vatPercentage">Tax % (included in price)</label>
            <Field name="vatPercentage" disabled={!applyTax} />
          </>
        ) : null}
        <h5>Policies</h5>
        <span />
        <label htmlFor="paymentPolicyId">Payment Policy</label>
        <Field
          as="select"
          name="paymentPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Payment Policy --</option>
          {getPolicies(paymentPolicies)}
        </Field>
        <ErrorMessage
          name="paymentPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="fulfilmentPolicyId">Fulfilment Policy</label>
        <Field
          as="select"
          name="fulfilmentPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Fulfilment Policy --</option>
          {getPolicies(fulfillmentPolicies)}
        </Field>
        <ErrorMessage
          name="fulfilmentPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="returnPolicyId">Return Policy</label>
        <Field
          as="select"
          name="returnPolicyId"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Return Policy --</option>
          {getPolicies(returnsPolicies)}
        </Field>
        <ErrorMessage
          name="returnPolicyId"
          className="ebay-listing-form__field-error"
          component="div"
        />
        <label htmlFor="merchantLocationKey">Location Policy</label>
        <Field
          as="select"
          name="merchantLocationKey"
          className="ebay-listing-form__select-input"
        >
          <option value="">-- Set Location Policy --</option>
          {getPolicies(locationPolicies)}
        </Field>
        <ErrorMessage
          name="merchantLocationKey"
          className="ebay-listing-form__field-error"
          component="div"
        />
      </div>
    </>
  );
}

export default EbayListingForm;

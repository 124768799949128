import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { EventMenuStructure } from "../../menuStructures";
import {
  doDeleteEvent,
  doEnableEvent,
  fetchEventInfo,
} from "../../api/rest/events";
import { ConfirmationModal, Loader, Paging, Spinner } from "../../components";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";
import {
  EventCard,
  ViewEventModal,
  DeleteEventModal,
  EnableEventModal,
  ParentEvent,
} from "../../components/events";

@inject("store")
@observer
class ChildEventList extends Component {
  @observable pageOffset = 0;

  @action setPageOffset = (offset) => {
    this.pageOffset = offset;
  };

  @observable modalVisible = false;

  @action setModalVisible = (visible) => {
    this.modalVisible = visible;
  };

  @observable selectedEvent;

  @action setSelectedEvent = (event) => {
    this.selectedEvent = event;
  };

  @observable confirmationModal = false;

  @action setShowConfirmationModal = (value) => {
    this.confirmationModal = value;
  };

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible = (visible) => {
    this.deleteModalVisible = visible;
  };

  @observable deleteModalLoading;

  @action setDeleteModalLoading = (loading) => {
    this.deleteModalLoading = loading;
  };

  @observable enableModalVisible = false;

  @action setEnableModalVisible = (visible) => {
    this.enableModalVisible = visible;
  };

  @observable enableModalLoading;

  @action setEnableModalLoading = (loading) => {
    this.enableModalLoading = loading;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  setEventInStore = (event) => {
    this.props.store.EventsStore.setEvent(event);
  };

  showViewEventModalForEvent = (eventId) => {
    let event = [];
    if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
      event.push(this.props.store.EventsStore.eventWithChildern);
    } else {
      event = this.props.store.EventsStore.eventWithChildern.childEvents.filter(
        (_event) => _event.id == eventId
      );
    }
    this.setSelectedEvent(event[0]);
    this.setModalVisible(true);
  };

  showDeleteEventModalForDelete = (eventId) => {
    let event = [];
    if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
      event.push(this.props.store.EventsStore.eventWithChildern);
    } else {
      event = this.props.store.EventsStore.eventWithChildern.childEvents.filter(
        (event) => event.id == eventId
      );
    }
    this.setDeleteModalVisible(true);
    this.setSelectedEvent(event[0]);
  };

  showEnableEventModalForEvent = (eventId) => {
    const event =
      this.props.store.EventsStore.eventWithChildern.childEvents.filter(
        (event) => event.id == eventId
      );
    this.setEnableModalVisible(true);
    this.setSelectedEvent(event[0]);
  };

  enableEvent = (eventId) => {
    this.setEnableModalLoading(true);
    doEnableEvent(eventId)
      .then(() => {
        this.props.store.EventsStore.rehydrateChildEvents(
          this.props.store.EventsStore.eventWithChildern.id
        );
      })
      .catch((error) => {
        console.error(error);
        this.props.store.MainStore.setError(
          error,
          "Failed to enable event",
          "There was an error updating the child event details. Please try again"
        );
      })
      .finally(() => {
        this.setEnableModalVisible(false);
        this.setSelectedEvent(null);
        this.setEnableModalLoading(false);
      });
  };

  deleteEvent = (eventId) => {
    this.setDeleteModalLoading(true);
    doDeleteEvent(eventId)
      .then(() => {
        if (this.props.store.EventsStore.eventWithChildern.id == eventId) {
          this.setShowConfirmationModal(true);
        } else {
          this.props.store.EventsStore.rehydrateChildEvents(
            this.props.store.EventsStore.eventWithChildern.id
          );
        }
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to delete event",
          "There was an error deleting the child ecent. Please try again"
        );
      })
      .finally(() => {
        this.setDeleteModalVisible(false);
        this.setSelectedEvent(null);
        this.setDeleteModalLoading(false);
      });
  };

  loadEvents = (params) => {
    this.setIsLoading(true);
    const { eventId } = this.props.match.params;
    fetchEventInfo(eventId, params)
      .then((result) => {
        this.props.store.EventsStore.setEventWithChildern(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load events",
          "There was an error retrieving the child events. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  prevEventsPage = () => {
    let newOffset = this.pageOffset - DEFAULT_EVENTS_PER_PAGE;
    if (newOffset < 0) {
      newOffset = 0;
    }
    this.setPageOffset(newOffset);
  };

  nextEventsPage = () => {
    const newOffset = this.pageOffset + DEFAULT_EVENTS_PER_PAGE;
    this.setPageOffset(newOffset);
  };

  componentDidMount() {
    document.title = "Events | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);
    this.props.store.EventsStore.setEventWithChildern(null);
    this.loadEvents();
  }

  render() {
    const { AuthStore, EventsStore } = this.props.store;

    if (!AuthStore.screenSettings.events) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    // TODO:: childern
    if (!EventsStore.eventWithChildern) {
      return <Loader />;
    }

    return (
      <>
        {this.confirmationModal && (
          <ConfirmationModal
            title="Event is preparing to delete"
            type="confirm"
            text="Your event has been queued for deletion. This will be processed shortly."
            link="/events/list"
          />
        )}
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              Viewing {EventsStore.eventWithChildern.title}
            </span>
          </h2>
        </div>
        <ParentEvent
          parentEvent={EventsStore.eventWithChildern}
          showViewEventModalForEvent={this.showViewEventModalForEvent}
          showDeleteEventModalForDelete={this.showDeleteEventModalForDelete}
          setEventInStore={this.setEventInStore}
        />
        <br />
        <p className="h4">Children Events</p>
        <Paging
          pageOffset={this.pageOffset}
          currentPageItemCount={
            EventsStore.eventWithChildern?.childEvents &&
            EventsStore.eventWithChildern.childEvents.length -
              DEFAULT_EVENTS_PER_PAGE * this.pageOffset
          }
          maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
          getPrevPage={this.prevEventsPage}
          getNextPage={this.nextEventsPage}
        />
        <Spinner isLoading={this.isLoading}>
          <div className="tiledPanels">
            {EventsStore.eventWithChildern.childEvents
              .slice(this.pageOffset, this.pageOffset + DEFAULT_EVENTS_PER_PAGE)
              .map((event, index) => (
                <EventCard
                  key={index}
                  event={event}
                  showViewEventModalForEvent={this.showViewEventModalForEvent}
                  showDeleteEventModalForEvent={
                    this.showDeleteEventModalForDelete
                  }
                  showEnableEventModalForEvent={
                    this.showEnableEventModalForEvent
                  }
                  setEventInStore={this.setEventInStore}
                />
              ))}
          </div>
        </Spinner>
        {this.modalVisible && (
          <ViewEventModal
            event={this.selectedEvent}
            setModalVisible={this.setModalVisible}
          />
        )}
        {this.deleteModalVisible && (
          <DeleteEventModal
            id={this.selectedEvent.id}
            title={this.selectedEvent.title}
            parentEventId={this.selectedEvent.parentEventId}
            isLoading={this.deleteModalLoading}
            setDeleteModalVisible={this.setDeleteModalVisible}
            deleteEvent={this.deleteEvent}
          />
        )}
        {this.enableModalVisible && (
          <EnableEventModal
            id={this.selectedEvent.id}
            title={this.selectedEvent.title}
            isLoading={this.enableModalLoading}
            setEnableModalVisible={this.setEnableModalVisible}
            enableEvent={this.enableEvent}
          />
        )}
      </>
    );
  }
}

export default ChildEventList;

import React, { useRef, useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import "./EbayCustomAttributes.scss";

type CustomAttributes = {
  [key: string]: string | number;
};

const parseAttributes = (attributes: string) => {
  if (!attributes) return {};
  try {
    return JSON.parse(attributes);
  } catch (error) {
    console.error(error);
    return {};
  }
};

interface EbayCustomAttributesProps {
  ebayCustomAttributes: string;
  setFieldValue: Function;
}

function EbayCustomAttributes(props: EbayCustomAttributesProps) {
  const { ebayCustomAttributes, setFieldValue } = props;
  const [attributes, setAttributes] = useState<CustomAttributes>(
    parseAttributes(ebayCustomAttributes)
  );
  const newAttributeRef = useRef<HTMLInputElement>(null);

  const addAttribute = () => {
    const newKey = newAttributeRef?.current?.value;
    if (newKey && newKey.length > 0) {
      setAttributes({ ...attributes, [newKey]: "" });
      newAttributeRef.current.value = "";
    }
  };

  const deleteAttribute = (key: string) => {
    const tempAttributes = { ...attributes };
    delete tempAttributes[key];
    setAttributes(tempAttributes);
  };

  const updateAttribute = (key: string, value: string | number) => {
    const tempAttributes = { ...attributes };
    tempAttributes[key] = value;
    setAttributes(tempAttributes);
    setFieldValue("ebayAttributes", JSON.stringify(tempAttributes));
  };

  return (
    <>
      <label
        htmlFor="ebayCustomAttributes"
        className="ebay-listing-form__label"
      >
        Custom attributes
      </label>
      <div className="ebay-custom-attributes">
        {Object.entries(attributes).map(([key, value]) => (
          <div key={key} className="ebay-custom-attributes__attribute">
            <span
              className="ebay-custom-attributes__key"
              data-testid="custom-attribute-key"
            >
              {key}
            </span>
            <input
              type="text"
              className="ebay-custom-attributes__value"
              data-testid="custom-attribute-value"
              defaultValue={value}
              placeholder="Attribute value"
              onBlur={(event) => updateAttribute(key, event.target.value)}
            />
            <ButtonComponent
              className="ebay-custom-attributes__delete"
              onClick={() => deleteAttribute(key)}
            >
              Delete
            </ButtonComponent>
          </div>
        ))}
      </div>
      <div className="ebay-custom-attributes__new">
        <input
          type="text"
          className="ebay-custom-attributes__value"
          placeholder="Attribute name"
          ref={newAttributeRef}
        />
        <ButtonComponent onClick={() => addAttribute()}>
          Add new attribute
        </ButtonComponent>
      </div>
    </>
  );
}

export default EbayCustomAttributes;

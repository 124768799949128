import * as Yup from "yup";

export const eventParticipantSchema = Yup.object().shape({
  participantName: Yup.string().required("Participant name is required"),
  participantEmail: Yup.string()
    .email("Please enter a valid email")
    .required("Participant email is required"),
  additionalInfo: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        value: Yup.string(),
      })
    )
    .optional(),
});

const TimeUtils = (function () {
  return {
    convertDateToHumanReadable(date, timeZone) {
      if (typeof date === "string")
        return new Date(date.replace(/\[[\w/]+\]/, "")).toLocaleString(
          undefined,
          {
            timeZone,
          }
        );
      if (typeof date === "number" && !isNaN(date))
        return new Date(date).toLocaleString(undefined, { timeZone });
      return "";
    },

    convertDateToHumanReadableNoTime(date) {
      if (typeof date === "number" && !isNaN(date))
        return new Date(date).toLocaleDateString();
      if (typeof date === "string")
        return new Date(date.replace(/\[[\w/]+\]/, "")).toLocaleDateString();
      return "";
    },

    convertDateToHumanReadbleNoTimeZ(date) {
      if (typeof date === "number" && !isNaN(date))
        return new Date(date).toLocaleDateString();
      if (typeof date === "string")
        return new Date(date.replace(/\[[\w/]+\]/, "")).toLocaleDateString(
          undefined,
          {
            timeZone: "Etc/GMT",
          }
        );
      return "";
    },

    convertTime(time) {
      if (typeof time === "number" && isNaN(time)) return "";
      if (typeof time === "number") return new Date(time).toLocaleTimeString();
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    },

    dateInput(date) {
      let d = date.split("T")[0];
      return d;
    },

    nowDate() {
      return new Date().toLocaleDateString();
    },

    nowTime() {
      return new Date().toLocaleTimeString();
    },
  };
})();

export default TimeUtils;

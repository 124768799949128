import { _fetch } from "../api";
import Big from "big.js";
import { BASE_URL } from "../constants/api";
import Dexie from "dexie";
import "firebase/auth";
import { action, computed, observable } from "mobx";
import Cookie from "mobx-cookie";
import { toast } from "react-toastify";
import Store from "../store/index";
import ReportsStore from "../store/ReportsStore";
import PAX from "./PAX.js";
import firebase from "firebase/app";
import { OperationalMode, operationalMode } from "../constants/mode";
import { isTaxRateValid } from "../utils/pos";
import { fetchSearchResults } from "../api/rest/pos";
import Worker from "../binder.worker.js";

window.Big = Big;

const CART_IDLE_TIMEOUT = 25 * 60; // seconds

class Item {
  @observable id;
  @observable name;
  constructor(name, image) {
    this.name = name;
    this.id = Date.now();
    this.image = image;
  }
}

class Register {}

class LineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable shopifyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable discountAmount = 0;
  @observable discountType = "percentage";
  @observable taxSetting;
  @observable discountModal = false;
  tags;
  taxDisabledShopify;
  taxDisabledUI;
  itemTaxIncluded;
  cartType;

  @computed get tax() {
    //If taxes are included in BinderPOS UI
    if (this.taxSetting.taxIncluded) {
      console.log("globalTaxIncluded");
      if (this.taxDisabledShopify) {
        var actualPrice = this.actualPrice;
        var itemPrice = this.actualPrice + this.discountValue / this.qty;
        var displayPrice = this.actualPrice + this.discountValue / this.qty;
        var itemTax = 0;
        console.log("shopifyTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax,
        };
      }
      if (this.taxDisabledUI) {
        actualPrice = this.actualPrice;
        itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        if (this.buyItem != "return") {
          itemPrice = this.actualPrice + this.discountValue / this.qty;
        }
        displayPrice = this.actualPrice + this.discountValue / this.qty;
        itemTax = 0;
        console.log("UITaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax,
        };
      }
      //if no settings

      actualPrice = this.actualPrice;
      itemPrice =
        (this.actualPrice + this.discountValue / this.qty) /
        (1 + this.itemTaxRate);
      if (this.buyItem && this.cartType != "return") {
        itemPrice = this.actualPrice + this.discountValue / this.qty;
      }
      displayPrice = this.actualPrice + this.discountValue / this.qty;
      itemTax = itemPrice * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax,
      };
    }
    //If taxes are NOT included in BinderPOS UI
    else {
      //if tax has been disabled in shopify or POS toggle
      if (this.taxDisabledShopify | this.taxDisabledUI) {
        actualPrice = this.actualPrice;
        itemPrice = this.actualPrice + this.discountValue / this.qty;
        displayPrice = this.actualPrice + this.discountValue / this.qty;
        itemTax = 0;
        console.log("itemTaxDisabled");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax,
        };
      }
      //if tax is included via tag or later db column
      if (this.itemTaxIncluded) {
        actualPrice = this.actualPrice;
        itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        displayPrice = this.actualPrice + this.discountValue / this.qty;
        itemTax = itemPrice * this.itemTaxRate;
        console.log("itemTaxIncluded");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax,
        };
      }
      //if no settings or tags have been set
      actualPrice = this.actualPrice;
      itemPrice = this.actualPrice + this.discountValue / this.qty;
      displayPrice = this.actualPrice + this.discountValue / this.qty;
      itemTax =
        (this.actualPrice + this.discountValue / this.qty) * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax,
      };
    }
  }

  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }
  @computed get lineTotalActual() {
    return this.qty * this.actualPrice;
  }
  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }
  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }
  @computed get itemTaxRate() {
    if (this.tags && this.tags.match(/(AT)\{(.+)\}([0-9.]+)/)) {
      return parseFloat(this.tags.match(/(AT)\{(.+)\}([0-9.]+)/)[3])
        ? parseFloat(this.tags.match(/(AT)\{(.+)\}([0-9.]+)/)[3]) / 100
        : this.taxSetting.taxRate;
    } else {
      return this.taxSetting.taxRate;
    }
  }

  @computed get specialTax() {
    if (this.tags && this.tags.match(/(AT)\{(.+)\}([0-9.]+)/)) {
      return this.tags.match(/(AT)\{(.+)\}([0-9.]+)/)[2];
    } else {
      return false;
    }
  }

  @computed get discountValue() {
    if (!this.discountType || !this.discountAmount) {
      return 0;
    }
    if (this.discountType == "percentage") {
      return -this.lineTotalActual * (this.discountAmount / 100);
    }
    return -this.discountAmount;
  }

  @computed get price() {
    return this.actualPrice + this.discountValue / this.qty;
  }

  constructor(
    product,
    buyMode,
    cashPrice,
    taxSetting,
    eventAdditionalInfo,
    cartType = null
  ) {
    this.tags = product.tags;
    this.taxSetting = taxSetting;
    this.cartType = cartType;
    //if we are creating the line item by adding within POS

    if (!Object.prototype.hasOwnProperty.call(product, "buying")) {
      var index = buyMode
        ? product.selectedBuyVariant
        : product.selectedVariant;
      this.buyItem = false;
      this.title = product.title;
      this.qty = 1;
      this.actualPrice = product.variants[index].price;
      this.shopifyPrice = product.variants[index].price;
      this.cashBuyPrice = product.variants[index].cashBuyPrice;
      this.creditBuyPrice = product.variants[index].storeCreditBuyPrice;
      this.variantTitle = product.variants[index].title;
      this.productId = product.id;
      this.variantId = product.variants[index].id;
      this.imageUrl = product.img;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.taxDisabledShopify =
        product.variants[index].taxable == null
          ? false
          : !product.variants[index].taxable;
      this.taxDisabledUI = false;
      this.itemTaxIncluded = false;
      this.discountAmount = product.discountAmount;
      this.discountType = product.discountType;
      if (buyMode) {
        this.buyItem = true;
        this.actualPrice = cashPrice
          ? product.variants[index].cashBuyPrice * -1
          : product.variants[index].storeCreditBuyPrice * -1;
        this.variantId = product.variants[index].id;
      }
      //if we are creating the line item from server response ie cart validation
    } else {
      this.buyItem = product.buying;
      this.title = product.productTitle;
      this.lineId = product.id;
      this.qty = product.quantity;
      this.productId = product.id;
      this.variantId = product.variantId;
      this.variantTitle = product.variantTitle;
      this.imageUrl = product.imageSrc;
      this.discount = 0;
      this.taxable = product.taxable;
      this.taxDisabledShopify =
        product.shopifyTaxable == null ? false : !product.shopifyTaxable;
      this.taxDisabledUI = product.taxable == null ? false : !product.taxable;
      this.itemTaxIncluded = false;
      this.eventAdditionalInfo = eventAdditionalInfo;
      this.shopifyPrice = product.shopifyPrice;
      this.actualPrice = product.actualPrice;
      this.discountAmount = product.discountAmount;
      this.discountType = product.discountType;
    }
  }
}

class CustomLineItem {
  @observable title;
  @observable buyItem;
  @observable qty;
  @observable actualPrice;
  @observable cashBuyPrice;
  @observable creditBuyPrice;
  @observable variantTitle;
  @observable productId;
  @observable variantId;
  @observable lineId;
  @observable imageUrl;
  @observable taxSetting;
  @observable discountAmount = 0;
  @observable discountType = "percentage";

  @computed get tax() {
    //If taxes are included in BinderPOS UI
    if (this.taxSetting.taxIncluded) {
      console.log("globalTaxIncluded");

      //if no settings
      var actualPrice = this.actualPrice;
      var itemPrice =
        (this.actualPrice + this.discountValue / this.qty) /
        (1 + this.itemTaxRate);
      if (this.buyItem) {
        itemPrice = this.actualPrice + this.discountValue / this.qty;
      }
      var displayPrice = this.actualPrice + this.discountValue / this.qty;
      var itemTax = itemPrice * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax,
      };
    }
    //If taxes are NOT included in BinderPOS UI
    else {
      //if tax is included via tag or later db column
      if (this.itemTaxIncluded) {
        actualPrice = this.actualPrice;
        itemPrice =
          (this.actualPrice + this.discountValue / this.qty) /
          (1 + this.itemTaxRate);
        displayPrice = this.actualPrice + this.discountValue / this.qty;
        itemTax = itemPrice * this.itemTaxRate;
        console.log("itemTaxIncluded");
        return {
          actualPrice,
          itemPrice,
          displayPrice,
          itemTax,
        };
      }
      //if no settings or tags have been set
      actualPrice = this.actualPrice;
      itemPrice = this.actualPrice + this.discountValue / this.qty;
      displayPrice = this.actualPrice + this.discountValue / this.qty;
      itemTax =
        (this.actualPrice + this.discountValue / this.qty) * this.itemTaxRate;
      console.log("itemTaxedNormal");
      return {
        actualPrice,
        itemPrice,
        displayPrice,
        itemTax,
      };
    }
  }

  @computed get lineTotal() {
    return this.qty * this.tax.itemPrice;
  }

  @computed get displayTotal() {
    return this.qty * this.tax.displayPrice;
  }
  @computed get lineTaxTotal() {
    return this.qty * this.tax.itemTax;
  }

  @computed get lineTotalActual() {
    return this.qty * this.actualPrice;
  }

  @computed get itemTaxRate() {
    return this.taxSetting.taxRate;
  }

  @computed get specialTax() {
    return false;
  }

  @computed get discountValue() {
    if (!this.discountType || !this.discountAmount) {
      return 0;
    }
    if (this.discountType == "percentage") {
      return -this.lineTotalActual * (this.discountAmount / 100);
    }
    return -this.discountAmount;
  }

  @computed get price() {
    return this.actualPrice + this.discountValue;
  }

  constructor(custom, buyMode, taxSetting, cartType) {
    this.taxSetting = taxSetting;
    this.cartType = cartType;
    this.buyItem = false;
    this.title = custom.name;
    this.qty = 1;
    if (custom.actualPrice < 0) {
      this.actualPrice = custom.actualPrice * -1;
      this.cashBuyPrice = custom.actualPrice * -1;
      this.creditBuyPrice = custom.actualPrice * -1;
    } else {
      this.actualPrice = custom.actualPrice;
      this.cashBuyPrice = custom.actualPrice;
      this.creditBuyPrice = custom.actualPrice;
    }

    this.variantTitle = "-";
    this.productId = null;
    this.variantId = null;
    this.imageUrl = "";
    this.currencySymbol = "$";
    this.taxable = true;
    if (buyMode) {
      this.buyItem = true;
      if (custom.actualPrice > 0) {
        this.actualPrice = custom.actualPrice * -1;
      } else {
        this.actualPrice = custom.actualPrice;
      }
      this.variantId = null;
    }
  }
}
/**
 * ItemList contains two arrays one for items in the
 * results grid and another for items in the cart. This
 * is just for messing around and will be restructured.
 */
class ItemList {
  constructor() {
    this.ReportsStore = new ReportsStore(this);
    this.cartIdleInterval = null;
    this.cartLastUpdated = Date.now();
  }

  @observable posActive;

  @action setPosActive = (isActive) => {
    this.posActive = isActive;
    if (isActive) {
      this.startCartIdleInterval();
    } else {
      // Clear cart idle timer when exiting pos
      this.clearCartIdleInterval();
    }
  };

  @observable cartIdleCheckActive = false;
  @action setCartIdleCheckActive = (cartIdleCheckActive) =>
    (this.cartIdleCheckActive = cartIdleCheckActive);

  startCartIdleInterval = () => {
    if (this.cartIdleCheckActive && !this.cartIdleInterval) {
      this.cartIdleInterval = setInterval(this.handleRefreshCart, 60 * 1000);
    }
  };

  clearCartIdleInterval = () => {
    if (this.cartIdleInterval) {
      clearInterval(this.cartIdleInterval);
    }
    this.cartIdleInterval = null;
  };

  updateCartLastUpdated = () => {
    this.cartLastUpdated = Date.now();
  };

  handleRefreshCart = () => {
    const elapsedSinceLastUpdate = Date.now() - this.cartLastUpdated;
    if (elapsedSinceLastUpdate > CART_IDLE_TIMEOUT * 1000) {
      this.validateCartNoRefresh();
    }
  };

  @observable items = [];
  @action clearSearchItems = () => {
    this.items = [];
  };
  @observable cart = [];
  @action setCart = (input) => {
    this.cart = input;
  };

  @observable customItem = { name: "", actualPrice: 0, qty: 1 };
  @observable showCustomItemComponent = false;
  @action toggleCustomItem = () => {
    this.showCustomItemComponent = !this.showCustomItemComponent;
  };

  @observable fetchingSearch = false;
  @observable searchNumber = 0;
  @observable timer;

  @action setTimer(timer) {
    this.timer = timer;
  }

  @observable searchTerm = "";
  @action setSearchTerm(term) {
    this.searchTerm = term;
    this.db.products
      .where("title")
      .startsWith(term.toLowerCase())
      .limit(10)
      .toArray()
      .then((e) => e.map((t) => t.title))
      .then((l) => this.setSearchSuggestions(l));
  }

  @action
  refreshSearch = () => {
    if (this.searchTerm) {
      this.loaderOn();
      return fetchSearchResults(
        this.searchTerm,
        this.includeSingles,
        this.buyMode,
        this.searchOffset,
        this.searchLimit
      )
        .then((data) => {
          this.loaderOff();
          this.emptyList();
          if (data[0]) {
            data.map((item) => this.addItem(item));
          }
        })
        .catch((error) => {
          console.error(error);
          this.loaderOff();
        });
    }
  };

  @observable waitingToSearch = false;
  @action setWaitingToSearch(value) {
    this.waitingToSearch = !!value;
  }
  @observable
  searchSuggestions = [];

  @observable cartNotes = "";

  @action
  setCartNotes = (notes) => {
    this.cartNotes = notes;
  };

  @observable customFields = [];
  @action setCustomFields(fields) {
    this.customFields = fields;
  }

  getCustomFields = async () => {
    const customFields = await _fetch({
      method: "GET",
      endpoint: `${BASE_URL}/settings/customFields/forMe`,
    });
    this.setCustomFields(JSON.parse(customFields.settingValue));
  };

  @action
  setSearchSuggestions = (value) => {
    this.searchSuggestions = value;
  };
  @observable suggestCookie = new Cookie("searchSuggest");

  @computed get suggestEnabled() {
    if ((this.suggestCookie.value == "false") | !this.suggestCookie.value) {
      return false;
    }
    return true;
  }

  @action toggleSuggest = () => {
    this.suggestCookie.set(!this.suggestEnabled, { expires: 365 });
  };

  @observable
  searchFocused;

  @action
  setSearchFocused = () => {
    this.searchFocused = true;
  };
  @action
  setSearchBlurred = async () => {
    this.searchFocused = false;
  };

  searchLimit = 50;

  @observable searchOffset = 0;
  @action setSearchOffset = (searchOffset) =>
    (this.searchOffset = searchOffset);

  @observable moreResultsAvailable = false;
  @action setMoreResultsAvailable = (moreResultsAvailable) =>
    (this.moreResultsAvailable = moreResultsAvailable);

  setSearchBlurredDelayed = async () => {
    await this.sleep(300);
    this.setSearchBlurred();
  };
  @action
  search = (e) => {
    this.setSearchOffset(0);
    this.setWaitingToSearch(true);
    this.searchNumber++;
    var currentSearch = this.searchNumber;
    this.loaderOff();
    clearTimeout(this.timer);
    var query = e.target.value;
    this.setSearchTerm(query);

    let localTimer = setTimeout(() => {
      if (query) {
        this.setWaitingToSearch(false);
        this.loaderOn();
        fetchSearchResults(
          this.searchTerm,
          this.includeSingles,
          this.buyMode,
          this.searchOffset,
          this.searchLimit + 1
        )
          .then((data) => {
            this.emptyList();
            if (data[0] && currentSearch == this.searchNumber) {
              if (data.length > this.searchLimit) {
                this.setMoreResultsAvailable(true);
              } else {
                this.setMoreResultsAvailable(false);
              }
              data
                .slice(0, this.searchLimit)
                .map((items) => this.addItem(items));
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => this.loaderOff());
      } else {
        this.emptyList();
      }
    }, 650);

    this.setTimer(localTimer);
  };

  @action changeSearchPage = (newOffset) => {
    this.loaderOn();
    this.setSearchOffset(newOffset);
    fetchSearchResults(
      this.searchTerm,
      this.includeSingles,
      this.buyMode,
      newOffset,
      this.searchLimit + 1
    )
      .then((data) => {
        this.loaderOff();
        this.emptyList();
        if (data[0]) {
          if (data.length > this.searchLimit) {
            this.setMoreResultsAvailable(true);
          } else {
            this.setMoreResultsAvailable(false);
          }
          data.slice(0, this.searchLimit).map((items) => this.addItem(items));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setSearchOffset(newOffset);
        this.loaderOff();
      });
  };

  @observable showOutOfStockWarning = false;
  @action setShowOutOfStockWarning = (showOutOfStockWarning) =>
    (this.showOutOfStockWarning = showOutOfStockWarning);

  @observable outOfStockItem;
  @action setOutOfStockItem = (outOfStockItem) =>
    (this.outOfStockItem = outOfStockItem);

  openCreditMenu = async () => {
    console.log("Opening credit menu...");
    await this.getPaxIP();
    const pax = new PAX("https://" + this.integratedPaySettings.paxIP);
    pax.creditMenu();
  };

  @action fetchBarcode = async (event) => {
    var barcode = event.target.value;
    if (event.key !== "Enter") {
      return null;
    }
    if (this.cartLoading) {
      event.target.select();
      return;
    }
    clearTimeout(this.timer);
    event.preventDefault();
    if (barcode == "CreditMenu") {
      await this.openCreditMenu();
      return this.setSearchTerm("");
    }
    this.loaderOn();
    event.persist();
    const result = await _fetch({
      endpoint:
        `${BASE_URL}/products/byBarcode/${barcode}` +
        (this.buyMode ? "?includeBuyprice=true" : ""),
    });
    if (result.error) {
      this.burntToast(result.error);
    }
    if (result.length === 0) {
      // No barcode matched, do normal search
      this.search({ target: { value: barcode } });
      event.target.select();
      return;
    }
    this.loaderOff();
    if (result.length === 1) {
      result[0].variants.forEach((variant, index) => {
        if (variant.barcode == barcode || variant.sku == barcode) {
          result[0].selectedVariant = index;
          result[0].selectedBuyVariant = index;
        }
      });
      const selectedVariant = result[0]?.variants?.[result[0]?.selectedVariant];
      if (
        selectedVariant?.quantity <= 0 &&
        this.useBarcodeQuantityCheck &&
        !this.buyMode
      ) {
        this.setOutOfStockItem(result[0]);
        this.setShowOutOfStockWarning(true);
      } else {
        this.addToCart(
          new LineItem(result[0], this.buyMode, null, this.allTax)
        );
      }
    } else {
      this.emptyList();
      result.forEach((res, resIndex) => {
        res.variants.forEach((variant, variantIndex) => {
          if (variant.barcode == barcode || variant.sku == barcode) {
            result[resIndex].selectedVariant = variantIndex;
            result[resIndex].selectedBuyVariant = variantIndex;
          }
        });
        this.addItem(res);
      });
    }
    return this.setSearchTerm("");
  };

  @action fetchVariantById = async (variantId) => {
    const product = await _fetch({
      endpoint: `${BASE_URL}/products/byVariantId/` + variantId,
    });
    if (product.event?.additionalInfo?.length)
      return this.setAdditionalInfoItem({
        ...product,
        selectedVariant: product.selectedVariant - 1,
      });
    product.variants.map((variant, index) => {
      if (variant.id == variantId) {
        product.selectedVariant = index;
        product.selectedBuyVariant = index;
      }
    });
    this.addToCart(new LineItem(product, this.buyMode, null, this.allTax));
  };

  @action loaderOn() {
    this.fetchingSearch = true;
    this.updateCartLastUpdated();
  }
  @action loaderOff() {
    this.fetchingSearch = false;
  }
  @observable cartLoading = false;
  @action cartLoadingOn() {
    this.cartLoading = true;
    this.updateCartLastUpdated();
  }
  @action cartLoadingOff() {
    this.cartLoading = false;
  }
  @observable gettingLatestCart = false;
  @action setGettingLatestCart(getting) {
    this.gettingLatestCart = getting;
    if (getting) this.updateCartLastUpdated();
  }

  bob = "hello3";

  @observable errorMessage = "";
  @action setErrorMessage(value, fallback) {
    this.errorMessage = value ?? fallback;
    this.errorTraceId = "";
  }

  @observable errorHeader = "";
  @action setHeaderMessage(value) {
    this.headerMessage = value;
    this.errorTraceId = "";
  }

  @observable cartInvalid = false;
  @action setCartInvalid = (cartInvalid) => (this.cartInvalid = cartInvalid);

  @observable additionalInfoItem;
  @action setAdditionalInfoItem(additionalInfoItem) {
    this.additionalInfoItem = additionalInfoItem;
  }

  @observable errorTraceId = "";
  @observable lastTraceId = "";
  @observable externalUrl = "";

  @action setAPIError(json) {
    this.errorHeader = json.error;
    this.errorMessage = json.detailedMessage;
    this.errorTraceId = json.traceId;
    this.lastTraceId = json.traceId;
    this.externalUrl = json.externalUrl;
  }

  @observable floatOpenAmount = "0.00";

  @action setFloatOpenAmount = (value) => (this.floatOpenAmount = value);

  @observable deleteModal = null;
  @action setDeleteModal = (id) => {
    this.deleteModal = id;
  };

  toast(msg) {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  burntToast(msg) {
    toast.warn(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  /**
   * This boolean tracks wether the POS is in buy or sell mode
   */
  @observable buyMode = false;
  @action toggleBuyMode = () => {
    this.buyMode = !this.buyMode;
    this.buyMode
      ? this.toast("Switched to Buy-Mode")
      : this.toast("Switched to Sell-Mode");
    this.refreshSearch();
  };
  @observable cashPrice = false;
  @action toggleCashPrice = () => {
    this.cashPrice = !this.cashPrice;
    this.cashPrice
      ? this.toast("Switched to cash pricing")
      : this.toast("Switched to credit pricing");
  };
  @observable floatModal = false;
  @action closeFloatModal() {
    this.floatModal = false;
  }

  @observable openTillModalVisible = false;
  @action setOpenTillModalVisible = (visible) =>
    (this.openTillModalVisible = visible);

  @observable selectedCustomer = null;
  @action setSelectedCustomer(customer) {
    this.selectedCustomer = customer;
  }
  @observable customerResults = [];
  @observable activeTender = 0;
  @action emptyList() {
    this.items = [];
  }
  @action addItem(item) {
    item.selectedBuyVariant = -1;
    if (item.selectedVariant < 0) {
      item.selectedVariant = -1;
      item.variants.some((variant, index) => {
        if (variant.quantity > 0) {
          item.selectedVariant = index;
          return true;
        }
      });
    }
    this.items.push(item);
  }
  @action
  addCustomerResults(data) {
    this.customerResults = data;
  }

  @observable customerInput = "";
  @action setCustomerInput(input) {
    this.customerInput = input;
  }

  @observable cartId = "";
  @action setCartId = (value) => {
    this.cartId = value;
  };
  @observable includeSingles = true;

  @action toggleSingle = () => {
    this.includeSingles = !this.includeSingles;
  };

  @computed get total() {
    return this.roundCents(
      parseFloat(this.subTotal) + parseFloat(this.taxTotal)
    );
    //return Number(Math.round(this.subTotal + this.taxTotal + "e2") + "e-2");
  }

  @computed get taxRates() {
    var taxes = [];
    var standardTax = { title: this.taxWording, price: 0, rate: this.taxRate };
    for (var line of this.cart) {
      if (!line.buyItem) {
        if (line.specialTax) {
          var exists = false;
          const taxLine = {
            title: line.specialTax,
            price: line.lineTaxTotal,
            rate: line.itemTaxRate,
          };
          if (taxes.length) {
            for (var tax of taxes) {
              if (taxLine.title == tax.title && taxLine.rate == tax.rate) {
                tax.price += taxLine.price;
                exists = true;
                break;
              }
            }
          }
          if (!exists) {
            taxes.push(taxLine);
          }
        } else {
          standardTax.price += line.lineTaxTotal;
        }
      }
    }
    taxes.push(standardTax);
    for (const tax of taxes) {
      if (tax.price < 0) {
        tax.price = 0;
      }
      if (this.discountPercentage) {
        tax.price = tax.price - tax.price * this.discountPercentage;
      }
      if (this.negatedTaxTotal) {
        tax.price =
          tax.price + this.negatedTaxTotal * (tax.price / this.saleTaxTotal);
      }
    }
    return taxes;
  }

  @computed get subTotal() {
    return this.saleTotal + this.buyTotal;
  }

  @observable
  globalDiscount = { type: "percentage", amount: "0.00" };

  @computed get globalDiscountBAD() {
    var globalDiscount = null;
    this.cart.forEach((item) => {
      var discount = item.title.match(/POS Discount ([0-9]+\.[0-9]{2})(%)/);
      if (discount) {
        if (discount[2]) {
          globalDiscount = { type: "percentage", amount: discount[1] };
        } else {
          globalDiscount = { type: "amount", amount: discount[1] };
        }
      }
    });
    return globalDiscount;
  }

  @computed get discountAmount() {
    var tempTotal;
    if (this.cart.length) {
      var totals = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) +
          parseFloat(
            line.buyItem && this.cartType != "return" ? 0 : line.lineTotal
          ),
        0
      );
      tempTotal = totals;
    } else {
      tempTotal = 0;
    }
    if (this.globalDiscount?.type == "percentage") {
      return parseFloat(
        this.roundCents(
          -tempTotal * (parseFloat(this.globalDiscount?.amount) / 100)
        )
      );
    } else if (this.globalDiscount?.type == "amount") {
      return -this.globalDiscount?.amount;
    }
    return 0;
  }

  @computed get discountPercentage() {
    return -this.discountAmount / (this.saleTotal + -this.discountAmount);
  }

  @computed get discountTaxAmount() {
    //this is some rough logic with a lot of special cases for returns
    //basically this is trying to figure out the amoutn of tax negated by a discount
    var tempTaxTotal;
    var discountTaxAmount = 0;
    if (this.cart.length) {
      var tots = 0;

      this.cart.forEach((line) => {
        if (!line.buyItem || this.cartType == "return") {
          tots += parseFloat(line.lineTaxTotal);
        }
      });
      tempTaxTotal = tots;
    } else {
      tempTaxTotal = 0;
    }

    var tempTotal;
    if (this.cart.length) {
      tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) +
          parseFloat(
            line.buyItem && this.cartType != "return" ? 0 : line.lineTotal
          ),
        0
      );

      tempTotal = tots;
    } else {
      tempTotal = 0;
    }

    if (this.globalDiscount?.type == "percentage") {
      discountTaxAmount =
        -tempTaxTotal * (parseFloat(this.globalDiscount.amount || 0) / 100);
    } else if (this.globalDiscount?.type == "amount" && tempTaxTotal) {
      discountTaxAmount =
        -(this.globalDiscount.amount || 0) * (tempTaxTotal / tempTotal);
    }
    console.log({ discountTaxAmount, tempTaxTotal, tempTotal });
    if (discountTaxAmount + tempTaxTotal < 0 && this.cartType != "return") {
      discountTaxAmount = -tempTaxTotal;
    }
    return discountTaxAmount;
  }

  @computed get saleTotal() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(line.buyItem ? 0 : line.lineTotal),
        0
      );

      return tots + this.discountAmount;
    }
    return 0 + this.discountAmount;
  }

  @computed get buyTotal() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) =>
          parseFloat(reducer) + parseFloat(!line.buyItem ? 0 : line.lineTotal),
        0
      );
      return tots;
    }
    return 0;
  }

  /* @computed get taxTotal() {
    var tax = 0;
    if (!this.taxTrades) {
      tax = this.subTotal * this.taxRate;
    } else {
      if (this.taxIncluded) {
        tax = this.saleTotal - this.saleTotal / (1 + this.taxRate);
      } else {
        tax = this.saleTotal * this.taxRate;
      }
    }
    return tax < 0 ? 0 : tax;
  }*/
  @computed get negatedTaxTotal() {
    if (this.cart.length && !this.taxTrades && this.cartType != "return") {
      var tots = 0;
      this.cart.forEach((line) => {
        if (line.buyItem) {
          tots += parseFloat(line.tax.itemTax * line.qty);
        }
      });
      if (this.saleTaxTotal + tots < 0) {
        return -this.saleTaxTotal;
      } else {
        return tots;
      }
    }
    return 0;
  }

  @computed get saleTaxTotal() {
    if (this.cart.length) {
      var tots = 0;

      this.cart.forEach((line) => {
        if (!line.buyItem || this.cartType == "return") {
          tots += parseFloat(line.lineTaxTotal);
        }
      });
      return tots + this.discountTaxAmount;
    }
    return 0 + this.discountTaxAmount;
  }

  @computed get taxTotal() {
    return this.saleTaxTotal + this.negatedTaxTotal < 0 &&
      this.cartType != "return"
      ? 0
      : this.saleTaxTotal + this.negatedTaxTotal;
  }

  @computed get totalItems() {
    if (this.cart.length) {
      var tots = this.cart.reduce(
        (reducer, line) => parseInt(reducer) + parseInt(line.qty),
        0
      );

      return tots;
    }
    return 0;
  }

  @action addToCart(item) {
    var newLine = true;
    this.cart.map((lines, index) => {
      if (
        lines.variantId == item.variantId &&
        item.variantId != null &&
        lines.buyItem != item.buyItem
      ) {
        newLine = false;
        lines.buyItem
          ? this.burntToast("You are already buying that exact item!")
          : this.burntToast("You are already selling that exact item!");
      } else if (lines.variantId == item.variantId && item.variantId != null) {
        this.cart[index].qty++;
        newLine = false;
      } else if (lines.lineId == item.lineId && item.lineId != null) {
        this.cart[index].qty++;
        newLine = false;
      }
    });
    if (newLine) {
      this.cart.push(item);
    }
    this.cartLoadingOn();
    _fetch({
      endpoint: `${BASE_URL}/pos/carts`,
      method: "PUT",
      payload: this.cartObject,
    })
      .then((response) => {
        if (response.error) {
          // this.setCart(oldCart);
          this.cartLoadingOff();
          this.setAPIError(response);
          this.getCartById(null, this.cartId);
        } else {
          this.cartLoadingOff();
          this.checkCart(response);
        }
      })
      .catch((error) => {
        //this.setCart(oldCart);
        this.setErrorMessage(error?.detailedMessage, "Unknown Error");
        this.cartLoadingOff();
        this.getCartById(null, this.cartId);
      });
  }

  @observable isDeletingCartItem;
  @action setIsDeletingCartItem(isDeletingCartItem) {
    this.isDeletingCartItem = isDeletingCartItem;
  }

  /**
   * matches an item by variantId and removes it if there is a match
   * @param {object} item
   */
  @action async removeFromCart(item) {
    this.cartLoadingOn();
    this.cart.map((lines, index) => {
      if (lines.lineId == item.lineId) {
        this.cart.splice(index, 1);
      }
    });
    await this.validateCart();
    this.cartLoadingOff();
  }

  @observable tenders = [{ type: "cash", amount: "0.00" }];

  @observable availableTenders = [];

  @computed get changeDue() {
    const tendersTotal = this.tenders.reduce(
      (acumulator, tender) => acumulator + Number(tender.amount),

      0
    );
    if (tendersTotal > this.total) {
      return (tendersTotal - this.total).toFixed(2);
    }
    return 0;
  }

  @computed get balance() {
    return this.roundCents(
      this.total -
        Object.values(this.tenders).reduce(
          (reducer, line) =>
            parseFloat(reducer) +
            parseFloat(line.amount == "" ? 0 : line.amount),
          0
        )
    );
  }

  @action zeroTenders = () => {
    this.tenders.forEach((tend) => {
      tend.amount = "0.00";
    });
  };

  @action addTender = (type, amount) => {
    this.tenders.push({ type, amount });
  };

  @action removeTender = (index) => {
    const tenders = [...this.tenders];
    tenders.splice(index, 1);
    this.tenders = tenders;
  };

  @observable tillList = [];

  @observable forceTill = false;
  @action setForceTill = (force) => (this.forceTill = force);

  @observable cartList = [];
  // @action setCartList = (list) => {
  //   this.cartList = list;
  // };

  @action setTillList(json) {
    this.tillList = json;
  }

  @action fetchTills() {
    return _fetch({ endpoint: `${BASE_URL}/pos/tills` }).then((json) => {
      if (json) {
        this.setTillList(json);
      }
    });
  }

  @action fetchFloat() {
    return _fetch({
      endpoint: `${BASE_URL}/pos/float/byTill/` + this.tillId,
    }).then((json) => this.setFloat(json));
  }

  @action
  adjustFloat = async () => {
    await _fetch({
      endpoint: `${BASE_URL}/pos/float/entry`,
      method: "POST",
      payload: this.adjustObject,
    });

    // we want to clear the settings after the call is made
    this.toast(
      this.adjustObject.float[0].name +
        " has been adjusted by " +
        this.fCurr(this.adjustAmount)
    );
    this.setAdjustAmount("0.00");
    this.updateNote("");
    this.closeFloatModal();
    return true;
  };

  @observable adjustAmount = "";
  @action setAdjustAmount = (amount) => (this.adjustAmount = amount);

  @observable adjustNote = "";
  @action updateNote(input) {
    this.adjustNote = input;
  }

  @observable adjustTender = "";
  @action setAdjustTender = (tenderType) => (this.adjustTender = tenderType);

  @computed get adjustObject() {
    var floatA = {
      name: this.adjustTender ? this.adjustTender : this.tenders[0].type,
      notes: this.adjustNote,
    };
    this.adjustAmount > 0
      ? (floatA.increment = this.adjustAmount)
      : (floatA.decrement = this.adjustAmount * -1);
    return {
      till: this.tillId,
      float: [floatA],
    };
  }

  @observable cookie = new Cookie("register");
  @observable float = {
    status:
      this.cookie.value != undefined &&
      this.cookie.value != -1 &&
      this.cookie.value != "No Till Selected"
        ? "open"
        : "closed",
  };
  @action setFloat = (float) => {
    this.float = float;
    this.tenders = [];
    this.tenderClose = float?.float ? new Array(float.float.length) : [];
    const availableTenders = new Set();
    float?.float?.map((tender) => {
      this.tenders.push({ type: tender.name, amount: 0.0 });
      availableTenders.add(tender.name);
    });
    this.availableTenders = [...availableTenders];
  };
  @computed get tillId() {
    //this.fetchTills();
    return this.cookie.value;
  }

  @computed get floatStatus() {
    return this.float.status == "open" ? true : false;
  }

  @action resetTenders = () => {
    this.tenders =
      this.float?.float?.map((tender) => ({ type: tender.name, amount: 0 })) ||
      [];
  };

  @action setTill = (value) => {
    if (!value) {
      value = -1;
    }
    this.cookie.set(value, { expires: 365 }); // 2 day expiry
    if (value != -1) {
      this.getTax();
      this.getLatestCart();
      this.getQuickLinkData();
      this.startCartIdleInterval();
    }
  };

  @action submitFloat = async () => {
    if (this.tillId != -1) {
      try {
        const json = await _fetch({
          endpoint: `${BASE_URL}/pos/float/open`,
          method: "POST",
          payload: this.floatObject,
        });
        this.setFloat(json);
      } catch (e) {
        console.error(e);
      }
      this.getLatestCart(0);
      this.closeFloatModal();
      this.startCartIdleInterval();
      if (this.forceTill) {
        this.fetchFloat();
      }
    } else {
      this.toast("Please select a valid till");
    }
  };

  @action closeFloat = () => {
    _fetch({
      endpoint: `${BASE_URL}/pos/float/byTill/${this.tillId}/close`,
      method: "POST",
      payload: this.floatObject,
    })
      .then((json) => this.setFloat(json))
      .then(() => {
        this.fetchTills();
        this.closeFloatModal();
        this.clearCartIdleInterval();
      });
    this.closeTillWarning();
  };

  @observable tillWarning = false;

  @action openTillWarning = () => {
    this.tillWarning = true;
  };
  @action closeTillWarning = () => {
    this.tillWarning = false;
  };
  @computed get floatObject() {
    return {
      till: this.tillId,
      float: [
        {
          name: "Cash",
          openingAmount: this.floatOpenAmount,
          closingAmount: this.tenderClose ? this.tenderClose[0] : null,
        },
        {
          name: "Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[1] : null,
        },
        {
          name: "EFTPOS",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[2] : null,
        },
        {
          name: "Store Credit",
          openingAmount: 0.0,
          closingAmount: this.tenderClose ? this.tenderClose[3] : null,
        },
      ],
    };
  }

  @computed get cartObject() {
    var cart = {};
    if (this.cartId) {
      cart.id = this.cartId;
    }
    cart.tillId = this.tillId;
    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;

    cart.cartItems = [];
    this.cart.map((lineItem) => {
      var line = {};
      line.variantTitle = lineItem.variantTitle;
      line.productTitle = lineItem.title;
      line.id = lineItem.lineId;
      line.variantId = lineItem.variantId;
      line.price = lineItem.displayTotal;
      line.actualPrice = lineItem.actualPrice;
      line.quantity = lineItem.qty;
      line.imageSrc = lineItem.imageUrl;
      line.buying = lineItem.buyItem;
      line.taxable = !lineItem.taxDisabledUI;
      line.shopifyTaxable = !lineItem.taxDisabledShopify;
      line.eventAdditionalInfo = lineItem.eventAdditionalInfo;
      line.shopifyPrice = lineItem.shopifyPrice;
      line.tags = lineItem.tags;
      line.discountAmount = lineItem.discountAmount;
      line.discountType = lineItem.discountType;
      line.discountValue = lineItem.discountValue;
      cart.cartItems.push(line);
    });
    cart.tenders = [];
    if (operationalMode === OperationalMode.Kiosk) {
      cart.cartType = "kiosk";
    }
    cart.totalTax = this.roundCents(this.taxTotal);
    this.tenders.map((tender) => {
      if (tender.amount != 0) {
        if (tender.type.toLowerCase() == "cash") {
          var temp = tender;
          cart.tenders.push({
            type: temp.type,
            amount: this.roundCents(temp.amount - this.changeDue),
          });
        } else {
          cart.tenders.push(tender);
        }
      }
    });

    cart.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    cart.taxLines = this.taxRates;

    cart.discountValue = this.globalDiscount.amount;
    cart.discountType = this.globalDiscount.type;
    cart.discountAmount = this.discountAmount;
    cart.cartNotes = this.cartNotes;
    if (this.integratedPaymentData) {
      cart.cartIntegratedPayments = this.integratedPaymentData;
    }

    return cart;
  }

  @observable submittingCart = false;
  @action setSubmittingCart(value) {
    this.submittingCart = value;
  }

  @action submitCart = async (transactionData = {}) => {
    this.cartLoadingOn();
    this.setSubmittingCart(true);
    try {
      if (this.cartObject.cartItems.length == 0) {
        this.setSubmittingCart(false);
        this.setErrorMessage(
          "You need an item in the cart to checkout! \n (Try adding a custom item for 0.00 if you are trying to exchange tenders.)"
        );
        this.cartLoadingOff();
      } else {
        const body = await _fetch({
          endpoint: `${BASE_URL}/pos/carts?submit=true`,
          method: "POST",
          payload: { ...this.cartObject, transactionData: transactionData },
        });
        this.setSearchTerm("");
        this.clearSearchItems();
        if (body.dateSubmitted) {
          this.openReceipt(null, true);
          //this.setErrorMessage("Cart successfully submitted!");
          //this.setReceiptData(body);
        } else if (body.error) {
          this.setAPIError(body);
        } else {
          this.setErrorMessage("Unknown error, cart submission failed");
        }
      }
    } finally {
      this.cartLoadingOff();
      this.setSubmittingCart(false);
    }
  };

  //This is the logic for handling integrated payments

  @action getPaxIP = async () => {
    //see if payment terminal is enabled
    const paxEnabled = await _fetch({
      endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/paxEnabled`,
    });
    this.integratedPaySettings.enabled =
      paxEnabled.settingValue == "true" ? true : false;

    const dnsResolver = await _fetch({
      endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/dnsResolver`,
    });
    if (dnsResolver.settingValue == "true") {
      this.integratedPaySettings.paxIP = `${this.tillId}.dns.binderpos.com:10009`;
    } else {
      const paxIP = await _fetch({
        endpoint: `${BASE_URL}/pos/settings/forTill/${this.tillId}/paxIP`,
      });
      this.integratedPaySettings.paxIP = paxIP.settingValue;
    }
  };

  //These are customer specific settings for the PAX terminal
  @observable integratedPaySettings = {
    paxIP: "192.168.1.118",
    enabled: false,
    dnsResolver: false,
  };

  @observable processingActive = false;
  @action endProcessing = () => {
    this.processingActive = false;
    this.clearProcessingAction();
  };
  @action startProcessing = () => {
    this.processingActive = true;
  };
  @observable processingData = {
    amount: 0.0,
  };

  @observable processingMessage = "";
  @action
  setProcessingMessage = (message) => {
    this.processingMessage = message;
  };

  @observable processingAction = null;
  @action
  setProcessingAction = (action, args, text = "Confirm") => {
    this.processingAction = { action: action, args: args, text: text };
  };

  @action
  clearProcessingAction = () => {
    this.processingAction = null;
  };

  @observable integratedPaymentData = [];
  @action
  clearPaymentData = () => {
    this.integratedPaymentData = [];
  };

  @action
  pushPaymentData = (data) => {
    this.integratedPaymentData.push(data);
  };

  forceFinalizeCredit = async () => {
    this.endProcessing();
    await this.submitCart();
    this.cartLoadingOff();
  };

  //this is always called when a cart is submitted it does nothing and calls
  //submitCart if no Credit Tender is present or the customer has no integrated
  //payments tied to their account
  @action processPayments = async () => {
    try {
      this.cartLoadingOn();
      if (!this.submittingCart) {
        this.setSubmittingCart(true);
        await this.getPaxIP();
        if (!this.processingActive) {
          //check if this order is is a credit card transaction
          if (
            !!this.cartObject.tenders.find((e) => e.type == "Credit") &&
            parseFloat(
              this.cartObject.tenders.find((e) => e.type == "Credit").amount
            ) != 0.0 &&
            this.integratedPaySettings.enabled
          ) {
            this.setProcessingMessage("Please continue transaction on pinpad");
            this.processingData.amount = this.cartObject.tenders.find(
              (e) => e.type == "Credit"
            ).amount;
            this.startProcessing();
            var leftToProcess = new Big(this.processingData.amount);
            console.log("Credit Transaction:" + this.processingData.amount);
            const pax = new PAX("https://" + this.integratedPaySettings.paxIP);
            var payment;

            try {
              if (this.processingData.amount < 0) {
                payment = await pax.creditReturn(-this.processingData.amount);
              } else {
                payment = await pax.creditSale(this.processingData.amount);
              }
              if (payment.status) {
                const paymentData = this.mapPaymentData(payment);
                if (
                  leftToProcess.lte(
                    Big(paymentData.approveAmount).minus(
                      paymentData.surchargeFee
                    )
                  )
                ) {
                  this.pushPaymentData(paymentData);
                  await this.submitCart();
                  this.cartLoadingOff();
                  this.endProcessing();
                } else {
                  console.log("NOT ENOUGH FUNDS");
                  this.setProcessingMessage(
                    `ONLY PARTIALLY AUTHORIZED FOR ${
                      paymentData.approveAmount - paymentData.surchargeFee
                    } REMOVE CARD AND VOID`
                  );
                  this.setProcessingAction(
                    async (e) => {
                      const pax = new PAX(
                        "https://" + this.integratedPaySettings.paxIP
                      );
                      await pax.creditVoid(e);
                      this.endProcessing();
                    },
                    paymentData.transactionNumber,
                    "VOID"
                  );
                }
              } else {
                this.setProcessingMessage(
                  payment.reason +
                    (payment.response.responseMessage
                      ? " - " + payment.response.responseMessage
                      : "")
                );
              }
            } catch (error) {
              console.log(error);
              this.setProcessingMessage(`Communication Error: ${error.reason}`);
            }
          } else {
            await this.submitCart();
            this.cartLoadingOff();
          }
        }
      } else {
        console.log("clicking tooo fassssttt");
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setSubmittingCart(false);
      this.cartLoadingOff();
    }
  };

  @action voidSale = async (saleId) => {
    const pax = new PAX("https://" + this.integratedPaySettings.paxIP);
    return pax.creditVoid(saleId);
  };

  mapPaymentData = (payment) => {
    var paymentData = {};
    paymentData.approveAmount =
      payment.response.amountInformation.approveAmount;
    paymentData.surchargeFee = payment.response.amountInformation.surchargeFee;
    paymentData.batchNumber = payment.response.hostInformation.batchNumber;
    paymentData.transactionNumber =
      payment.response.traceInformation.transactionNumber;
    paymentData.cardHolder = payment.response.accountInformation.cardHolder;
    paymentData.lastFour = payment.response.accountInformation.account;
    paymentData.timeStamp = payment.response.traceInformation.timeStamp;
    paymentData.hostResponseMessage =
      payment.response.hostInformation.hostResponseMessage;
    paymentData.fullResponseObject = JSON.stringify(payment.response);
    return paymentData;
  };

  @observable tenderClose = [];
  @action setTenderClose = (value, tenderIndex) => {
    if (tenderIndex < this.tenderClose.length) {
      this.tenderClose[tenderIndex] = value;
    }
  };
  @computed get tenderDiff() {
    var diff = new Array(this.tenderClose.length);
    this.tenderClose.map((close, index) => {
      if (isNaN(this.tenderClose[index])) return;
      diff[index] = (
        this.tenderClose[index] - this.float.float[index].currentAmount
      ).toFixed(2);
    });
    return diff;
  }

  @action clearCustomer() {
    this.selectedCustomer = null;
    this.customerInput = "";
  }

  @observable checkoutModal = false;
  @observable disableLineItems = false;
  @action setDisableLineItems(bool) {
    this.disableLineItems = bool;
  }

  @observable cartModal = false;
  @action closeCartModal = () => {
    this.cartModal = false;
  };
  @action openCartModal = () => {
    // this.fetchCarts();
    this.cartModal = true;
  };

  @observable orderModal = false;
  @action closeOrderModal = () => {
    this.orderModal = false;
  };
  @action openOrderModal = () => {
    this.orderModal = true;
  };

  @observable receiptModal = false;
  @action closeReceipt = () => {
    this.receiptModal = false;
    if (this.completedReceipt) {
      this.newCart();
      this.checkoutModalFalse();
      this.clearCustomer();
      this.getTax();
      this.zeroTenders();
      this.setDisableLineItems(false);
    }
    this.completedReceipt = false;
  };
  @observable completedReceipt = false;

  @action openReceipt = (e, completed) => {
    this.completedReceipt = completed;
    this.receiptModal = true;
  };

  @observable receiptData = {};

  @action setReceiptData = (data) => {
    this.receiptData = data;
  };
  @action checkoutModalFalse = () => {
    this.checkoutModal = false;
    this.setDisableLineItems(false);
  };

  @observable cashDenoms = [5, 10, 20, 50, 100];

  @action newCart = () => {
    var blankBody = {
      tillId: this.tillId,
      cartItems: [],
    };
    this.setCartInvalid(false);
    this.clearCustomer();
    this.clearDiscount();
    this.setSearchTerm("");
    this.clearSearchItems();
    this.clearPaymentData();
    this.buyMode = false;
    blankBody.customer = this.selectedCustomer
      ? { id: this.selectedCustomer.id }
      : null;
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts`,
      method: "POST",
      payload: blankBody,
    }).then((response) => {
      this.setCartId(response.id);
      this.getLatestCart();
    });
  };

  @action clearDiscount = () => {
    this.globalDiscount = { amount: 0, type: "percentage" };
  };

  @action saveCart = () => {
    var blankBody = {
      savedName: "",
      savedCart: "true",
      id: this.cartId,
    };
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts/save`,
      method: "POST",
      payload: blankBody,
    }).then((response) => {
      this.setCartId(response.id);
      this.getLatestCart();
    });
  };

  @action checkCart = (response) => {
    console.log({ response });
    this.cart = [];
    response.cartItems?.map((line) => {
      this.cart.push(
        new LineItem(
          line,
          null,
          null,
          this.allTax,
          line.eventAdditionalInfo,
          this.cartType
        )
      );
    });
    if (response.customer) {
      //this.selectedCustomer = response.customer;
    }
    this.cartType = response.cartType;
    if (response.discountAmount && response.discountType) {
      this.globalDiscount.amount = response.discountValue;
      this.globalDiscount.type = response.discountType;
    }
    this.cartNotes = response.cartNotes;
  };

  @action validateCart = () => {
    this.cartLoadingOn();
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts`,
      method: "PUT",
      payload: this.cartObject,
    }).then((response) => {
      if (response.error) {
        this.setAPIError(response);
        this.cartLoadingOff();
        //this.newCart();
      } else {
        this.checkCart(response);
        this.cartLoadingOff();
      }
      this.setIsDeletingCartItem(false);
      this.refreshSearch();
    });
  };

  @action validateCartNoRefresh = () => {
    this.cartLoadingOn();
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts`,
      method: "PUT",
      payload: this.cartObject,
    })
      .then((response) => {
        if (response.error) {
          this.setAPIError(response);
          this.cartLoadingOff();
          //this.newCart();
        } else {
          this.checkCart(response);
          this.cartLoadingOff();
        }
      })
      .catch((error) => {
        if (error?.error === "POSCart marked as abandoned") {
          this.setCartInvalid(true);
          this.setErrorMessage(
            "This cart has been abandoned automatically by BinderPOS due to being over 1 hour old. Items have been added back into stock. You will need to create a new cart."
          );
        }
        console.error(error);
      });
  };

  @action getLatestCart = async () => {
    try {
      this.setGettingLatestCart(true);
      const result = await _fetch({
        endpoint: `${BASE_URL}/pos/carts/latest/forTill/${this.tillId}`,
      });
      if (result.dateSubmitted | (result.id == null)) {
        this.newCart();
      } else {
        this.setCartId(result.id);
        this.setSelectedCustomer(result.customer);
        this.checkCart(result);
      }
    } finally {
      this.setGettingLatestCart(false);
    }
  };

  @action getCartById = (e, id) => {
    return _fetch({ endpoint: `${BASE_URL}/pos/carts/byId/` + id })
      .then((result) => {
        if (result.dateSubmitted | (result.id == null)) {
          this.toast("This cart has already been submitted");
        } else {
          if (result.abandoned) {
            this.newCart();
          } else {
            this.setCartId(result.id);
            this.setSelectedCustomer(result.customer);
            this.checkCart(result);
          }
        }
        this.closeCartModal();
      })
      .catch((error) => console.error(error));
  };

  @action returnCartById = (id) => {
    return _fetch({
      endpoint: `${BASE_URL}/pos/carts/createReturn/${id}`,
      method: "POST",
      payload: null,
    }).then((result) => {
      if (result.id == null) {
        this.toast("Error");
      } else {
        this.setCartId(result.id);
        this.setSelectedCustomer(result.customer);
        this.checkCart(result);
      }
    });
  };

  //this is called to delete a cart by id, if it's the current cart loaded we load a new one

  @action deleteCartById = async (e, cartId, removeStock = true) => {
    await _fetch({
      endpoint: `${BASE_URL}/pos/carts/byId/${cartId}?removeStock=${removeStock}`,
      method: "DELETE",
    });
    if (cartId == this.cartId) {
      await this.newCart();
    }
  };

  @action unsetTill = () => {
    this.cookie.remove();
  };

  @observable taxRate = undefined;
  @observable taxRateDisplay = 0;
  @observable taxNumber = "";
  @observable taxIncluded = true;
  @observable taxTrades = false;
  @observable taxWording = "";
  @observable currency = "$";

  @observable storeInfo = {};
  @action setTaxTrades = (value) => {
    this.taxTrades = value;
  };
  @action taxIncludedSet = (value) => {
    this.taxIncluded = value;
  };
  @action taxRateSet = (value) => {
    this.taxRate = isNaN(value) ? 0 : value;
  };
  @action taxRateDisplaySet = (value) => {
    this.taxRateDisplay = value;
  };
  @action taxNumberSet = (value) => {
    this.taxNumber = value;
  };
  @action setStoreInfo = (value) => {
    this.storeInfo = value;
  };
  @action taxWordingSet = (value) => {
    this.taxWording = value;
  };
  @action currencySet = (value) => {
    this.currency = value;
  };

  @observable currencyCode = "USD";
  @action currencyCodeSet = (value) => {
    this.currencyCode = value;
  };

  @action getTax = async () => {
    const taxRate = await _fetch({
      endpoint: `${BASE_URL}/settings/taxRate/forMe`,
    });
    if (isTaxRateValid(taxRate.settingValue)) {
      this.taxRateDisplaySet(taxRate.settingValue);
      this.taxRateSet(taxRate.settingValue / 100);
    } else {
      this.setShowTaxErrorModal(true);
      this.taxRateSet(null);
    }
    const taxIncluded = await _fetch({
      endpoint: `${BASE_URL}/settings/taxIncluded/forMe`,
    });
    this.taxIncludedSet(taxIncluded.settingValue == "true");

    const taxTrades = await _fetch({
      endpoint: `${BASE_URL}/settings/tradeInTax/forMe`,
    });
    this.setTaxTrades(taxTrades.settingValue == "true");

    const storeInfo = await _fetch({ endpoint: `${BASE_URL}/settings/store` });
    this.setStoreInfo(storeInfo);

    const taxNumber = await _fetch({
      endpoint: `${BASE_URL}/settings/taxNumber/forMe`,
    });
    this.taxNumberSet(taxNumber?.settingValue);

    const taxWording = await _fetch({
      endpoint: `${BASE_URL}/settings/taxWording/forMe`,
    });
    this.taxWordingSet(taxWording?.settingValue);

    const currencySymbol = await _fetch({
      endpoint: `${BASE_URL}/settings/currencySymbol`,
    });
    this.currencySet(currencySymbol?.settingValue);

    const currencyCode = await _fetch({
      endpoint: `${BASE_URL}/settings/currency`,
    });
    this.currencyCodeSet(currencyCode?.settingValue);

    //this.validateCartNoRefresh();
  };

  @action fetchCurrency() {
    _fetch({ endpoint: `${BASE_URL}/settings/currencySymbol` }).then(
      (result) => {
        this.currencySymbol(result?.settingValue);
      }
    );
  }

  @computed get allTax() {
    return {
      taxRate: isNaN(this.taxRate) ? 0 : this.taxRate,
      taxTrades: this.taxTrades,
      taxIncluded: this.taxIncluded,
    };
  }
  @observable stockLimit = false;

  logError = () => {
    // let time = new Date(Date.now()).toLocaleString();
    // console.log(toJS(this));
    // fetch("https://pos.alvarandhurriks.com:1880/binderLog", {
    //   method: "POST",
    //   body: JSON.stringify(
    //     Object.assign(
    //       {
    //         time: time,
    //         user: firebase.auth().currentUser.displayName
    //       },
    //       toJS(this)
    //     )
    //   )
    // });
  };

  roundCents = (amount) => {
    amount = parseFloat(amount);
    return (Math.round(Math.round(amount * 10000000) / 100000) / 100).toFixed(
      2
    );
  };

  fCurr = (amount) => {
    if (amount || amount === 0) {
      var theAmount = parseFloat(amount);
      if (theAmount >= 0) {
        return this.currency + this.roundCents(theAmount);
      } else {
        return "-" + this.currency + this.roundCents(Math.abs(theAmount));
      }
    }
    return amount;
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async fetchAllProducts(name) {
    this.db = new Dexie(name);
    this.db.version(1).stores({
      products: "id,title",
    });
    this.worker.postMessage({
      topic: "fetchProducts",
      name: name,
      token: await firebase.auth().currentUser?.getIdToken(),
    });
  }

  worker = new Worker();

  @action setQuickLinkData(data) {
    if (Array.isArray(data)) {
      this.quickLinkData = data;
      _fetch({
        endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
        method: "PUT",
        payload: this.quickLinkData,
      });
    }
  }

  @action setQuickLinkNav(data) {
    this.quickLinkNav.push(data);
  }

  @action updateQuickLinks() {
    _fetch({
      endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
      method: "PUT",
      payload: this.quickLinkData,
    });
  }

  @observable
  quickLinkNav = [];

  @action moveQuickLink(itemIndex, position) {
    if (itemIndex > position) {
      this.currentNav.splice(
        position,
        0,
        this.currentNav.splice(itemIndex, 1)[0]
      );
    } else {
      this.currentNav.splice(
        position - 1,
        0,
        this.currentNav.splice(itemIndex, 1)[0]
      );
    }
    this.updateQuickLinks();
  }

  @action moveQuickLinkToFolder(itemIndex, folderIndex) {
    this.currentNav[folderIndex].value.items.push(
      this.currentNav.splice(itemIndex, 1)[0]
    );
    this.updateQuickLinks();
  }

  @action moveQuickLinkUp(itemIndex) {
    const navCopy = [...this.quickLinkNav];
    navCopy.pop();
    var nav = this.quickLinkData;
    navCopy.forEach((index) => {
      nav = nav[index].value.items;
    });
    nav.push(this.currentNav.splice(itemIndex, 1)[0]);
    this.updateQuickLinks();
  }

  @action deleteQuickItem(itemIndex) {
    this.currentNav.splice(itemIndex, 1);
    this.updateQuickLinks();
  }

  @computed get currentNav() {
    var nav = this.quickLinkData;
    this.quickLinkNav.forEach((index) => {
      nav = nav[index].value.items;
    });
    return nav;
  }

  @computed get currentNavName() {
    var nav = this.quickLinkData;
    var name = "POS";
    this.quickLinkNav.forEach((value) => {
      name += " > " + nav[value].value.title;
      nav = nav[value].value.items;
    });
    return name;
  }

  @observable
  movingQuickLink = null;

  @action setMovingQuickLink(value) {
    this.movingQuickLink = value;
  }

  @observable
  quickLinkData = [];
  /**
   * Neat function
   *
   */
  @action
  getQuickLinkData() {
    return _fetch({
      endpoint: `${BASE_URL}/pos/quicklinks/forTill/${this.tillId}`,
    }).then((result) => {
      if (Object.keys(result).length === 0) {
        this.setQuickLinkData([]);
        this.setQuickLinksReady(true);
      } else {
        this.setQuickLinkData(result);
        this.setQuickLinksReady(true);
      }
    });
  }
  //this is used to make sure the quickLinks component doesn't mount prematurely
  //project://src/js/components/pos/ResultsGrid.jsx
  @observable
  quickLinksReady = false;

  @action
  setQuickLinksReady(value) {
    this.quickLinksReady = value;
  }

  @observable
  editingQuickItem = null;

  @observable
  posVersion = "2.1.1";

  //this computed tells us the current users info like store name etc
  @computed get customerInfo() {
    return Store.SettingsStore.storeSettings;
  }

  @observable
  useSplitTill = false;

  @action
  setUseSplitTill = (useSplitTill) => {
    this.useSplitTill = useSplitTill;
  };

  @action
  loadSplitTillSetting = async () => {
    try {
      const posUseSplitCheckout = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/posUseSplitCheckout/forMe`,
      });
      this.setUseSplitTill(posUseSplitCheckout?.settingValue === "true");
    } catch (error) {
      console.error(error);
    }
  };

  @observable customerNoteModalVisible = false;
  @action setCustomerNoteModalVisible = (visible) =>
    (this.customerNoteModalVisible = visible);

  @observable customerToEdit = null;
  @action setCustomerToEdit = (customer) => (this.customerToEdit = customer);

  @observable showTaxErrorModal = false;
  @action setShowTaxErrorModal = (showTaxErrorModal) =>
    (this.showTaxErrorModal = showTaxErrorModal);

  @observable
  useBarcodeQuantityCheck = false;

  @action
  setUseBarcodeQuantityCheck = (useBarcodeQuantityCheck) => {
    this.useBarcodeQuantityCheck = useBarcodeQuantityCheck;
  };

  @action
  loadBarcodeQuantityCheckSetting = async () => {
    try {
      const useBarcodeQuantityCheck = await _fetch({
        method: "GET",
        endpoint: `${BASE_URL}/settings/barcodeQuantityCheck/forMe`,
      });
      this.setUseBarcodeQuantityCheck(
        useBarcodeQuantityCheck?.settingValue === "true"
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export { Item, ItemList, LineItem, CustomLineItem, Register };

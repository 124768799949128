import React, { useEffect, useState } from "react";
import {
  deleteFulfillment,
  EbayFulfillment,
  EbayParam,
  fetchExcludeRegions,
  fetchFromEbayFulfillmentSettings,
  fetchFulfillmentSettings,
  fetchIncludeRegions,
  fetchShippingOptionTypes,
  fetchShippingServices,
  fetchTimeDuraitons,
  ShippingServiceProvider,
  updateFulfillment,
} from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import EbayDeleteSetting from "../../../components/integrations/EbayDeleteSetting";
import EbayErrorComponent from "../../../components/integrations/EbayErrorComponent";
import EbayFulfillmentForm from "../../../components/integrations/EbayFulfillmentForm";
import EbaySettingsList from "../../../components/integrations/EbaySettingsList";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { parseEbayErrorMessage } from "../../../utils/integrations";

const wrapTitle = (children: React.ReactChild, errors: string[]) => (
  <>
    <h2>Fulfillment Settings</h2>
    <EbayErrorComponent errors={errors} />
    {children}
  </>
);

function FulfillmentSettingsContainer() {
  const { customerId } = useStoreDetails();
  const [shippingServices, setShippingServices] = useState<
    ShippingServiceProvider[]
  >([]);
  const [includeRegions, setIncludeRegions] = useState<EbayParam[]>([]);
  const [excludeRegions, setExcludeRegions] = useState<EbayParam[]>([]);
  const [timeDurations, setTimeDurations] = useState<EbayParam[]>([]);
  const [shippingOptionTypes, setShippingOptionTypes] = useState<EbayParam[]>(
    []
  );
  const [settings, setSettings] = useState<EbayFulfillment[]>([]);
  const [editSetting, setEditSetting] = useState<number | null>();
  const [deletingSetting, setDeletingSetting] = useState<number | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const loadSettings = () => {
    setIsLoading(true);
    fetchFulfillmentSettings()
      .then((data: EbayFulfillment[]) => setSettings(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  const refreshSettings = () => {
    setIsLoading(true);
    fetchFromEbayFulfillmentSettings()
      .then((data: EbayFulfillment[]) => setSettings(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  const saveSetting = (values: EbayFulfillment, setSubmitting: Function) => {
    if (values.id < 0) values.id = null;
    setSubmitting(true);
    updateFulfillment(values)
      .then(() => {
        loadSettings();
        setEditSetting(null);
      })
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setSubmitting(false));
  };

  const deleteSetting = (settingId: number) => {
    setIsDeleting(true);
    deleteFulfillment(settingId)
      .then(() => loadSettings())
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => {
        setIsDeleting(false);
        setDeletingSetting(null);
      });
  };

  const loadShippingServices = () =>
    fetchShippingServices()
      .then((data: ShippingServiceProvider[]) => setShippingServices(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  const loadIncludeRegions = () =>
    fetchIncludeRegions()
      .then((data: EbayParam[]) => setIncludeRegions(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  const loadExcludeRegions = () =>
    fetchExcludeRegions()
      .then((data: EbayParam[]) => setExcludeRegions(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  const loadTimeDurations = () =>
    fetchTimeDuraitons()
      .then((data: EbayParam[]) => setTimeDurations(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  const loadShippingOptionTypes = () =>
    fetchShippingOptionTypes()
      .then((data: EbayParam[]) => setShippingOptionTypes(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  useEffect(() => {
    loadIncludeRegions();
    loadExcludeRegions();
    loadTimeDurations();
    loadShippingOptionTypes();
    loadShippingServices();
    if (customerId) {
      loadSettings();
    }
  }, [customerId]);

  useEffect(() => {
    setErrors([]);
  }, [editSetting, deletingSetting]);

  if (isLoading) {
    return wrapTitle(<Loader />, errors);
  }

  if (editSetting) {
    return wrapTitle(
      <EbayFulfillmentForm
        shippingServices={shippingServices}
        includeRegions={includeRegions}
        excludeRegions={excludeRegions}
        timeDurations={timeDurations}
        shippingOptionTypes={shippingOptionTypes}
        fulfillmentSetting={settings.find(
          (setting) => setting.id === editSetting
        )}
        setEditSetting={setEditSetting}
        saveSetting={saveSetting}
      />,
      errors
    );
  }

  if (deletingSetting) {
    const location = settings.find((setting) => setting.id === deletingSetting);
    if (location) {
      const { id, name } = location;
      return wrapTitle(
        <EbayDeleteSetting
          id={id}
          name={name}
          isDeleting={isDeleting}
          handleCancel={() => setDeletingSetting(null)}
          handleDelete={(deleteId: number) => deleteSetting(deleteId)}
        />,
        errors
      );
    }
  }

  return wrapTitle(
    <EbaySettingsList
      settings={settings}
      deletingSetting={deletingSetting}
      refreshSettings={refreshSettings}
      setEditSetting={setEditSetting}
      setDeletingSetting={setDeletingSetting}
    />,
    errors
  );
}

export default FulfillmentSettingsContainer;

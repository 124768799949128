import * as Yup from "yup";

export const bulkUpdateSchema = Yup.object().shape({
  stockUpdateType: Yup.string(),
  priceOverride: Yup.number().min(0, "Price cannot be less than zero"),
  stock: Yup.number(),
  cashBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  cashBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
  creditBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  creditBuyPercentage: Yup.number(),
  buyLimit: Yup.number().min(0, "Buy limit cannot be less than zero"),
  maxInstockBuyPrice: Yup.number().min(0, "Buy price cannot be less than zero"),
  maxInstockBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
  maxInstockCreditBuyPrice: Yup.number().min(
    0,
    "Buy price cannot be less than zero"
  ),
  maxInstockCreditBuyPercentage: Yup.number().min(
    -100,
    "Items cannot be discounted by more than 100%"
  ),
});

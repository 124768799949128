import React from "react";
import PropTypes from "prop-types";

function SortDirection(props) {
  const { ascending, setAscending } = props;

  const handleChange = () => {
    setAscending(!ascending);
  };

  return (
    <button
      className="button sort-direction"
      type="button"
      onClick={handleChange}
    >
      {ascending ? (
        <i className="fas fa-sort-amount-up-alt" title="Sort descending" />
      ) : (
        <i className="fas fa-sort-amount-down-alt" title="Sort ascending" />
      )}
    </button>
  );
}

SortDirection.propTypes = {
  ascending: PropTypes.bool.isRequired,
  setAscending: PropTypes.func.isRequired,
};

export default SortDirection;

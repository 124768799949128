import React, { useState } from "react";
import ButtonComponent from "../generic/ButtonComponent";
import { toast } from "react-toastify";
import { syncVariantInventory } from "../../api/rest/ebay";
import { parseEbayError } from "../../utils/ebay";

interface EbayResyncVariantButtonProps {
  integration: string;
  variantId: string | number;
}

function EbayResyncVariantButton(props: EbayResyncVariantButtonProps) {
  const { integration, variantId } = props;
  const [isResyncing, setIsResyncing] = useState(false);

  const handleResyncClick = () => {
    setIsResyncing(true);
    syncVariantInventory(variantId)
      .then(() => {
        toast.warn("Synchronizing inventory. This may take a few minutes");
      })
      .catch((error: Error) => {
        const message = parseEbayError(error);
        if (message) {
          toast.error(message);
        } else {
          console.error(error);
          toast.error(
            "Failed to synchronize inventory. Please try again later."
          );
        }
      })
      .finally(() => setIsResyncing(false));
  };

  if (integration !== "ebay") return null;

  return (
    <>
      <ButtonComponent
        className="btn1"
        disabled={isResyncing}
        onClick={() => handleResyncClick()}
      >
        Sync
      </ButtonComponent>{" "}
    </>
  );
}

export default EbayResyncVariantButton;

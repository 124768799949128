import {
  addBuylistRule,
  fetchBuylistRule,
  updateBuylistRule,
} from "../../api/rest/buylist";
import { Loader, SideLabelNumberInput } from "../../components";
import { BuylistMenuStructure } from "../../menuStructures";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

@inject("store")
@observer
export default class BuylistRule extends Component {
  @observable selectedCondition;

  @action setSelectedCondition(selectedCondition) {
    this.selectedCondition = selectedCondition;
  }

  @observable modalVisible = false;

  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable modalTitle;

  @action setModalTitle(title) {
    this.modalTitle = title;
  }

  @observable modalText;

  @action setModalText(text) {
    this.modalText = text;
  }

  @observable hasValidationErrors = false;

  @action setHasValidationErrors(bool) {
    this.hasValidationErrors = bool;
  }

  @observable updating;

  @action setUpdating(bool) {
    this.updating = bool;
  }

  @observable buylistRuleToApply;

  @action setBuylistRuleToApply(buylistRule) {
    this.buylistRuleToApply = buylistRule;
  }

  @observable buylistIdPresent;

  @action setBuylistIdPresent(isPresent) {
    this.buylistIdPresent = isPresent;
  }

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  getBuylistRule(id) {
    fetchBuylistRule(id)
      .then((result) => {
        this.props.store.BuylistRulesStore.setBuylistRule(result);
        const { buylistRule } = this.props.store.BuylistRulesStore;
        if (
          buylistRule.game &&
          buylistRule.game != "all" &&
          buylistRule.game != ""
        ) {
          this.fetchSetInfo(buylistRule.game);
          this.fetchCardRarities(buylistRule.game);
        }
        this.props.store.BuylistRulesStore.buylistRule.buylistVariants.forEach(
          (buylistVariant) => {
            buylistRule[`id-${buylistVariant.customerVariantId}`] =
              buylistVariant.id;
            buylistRule[`allowPurchase-${buylistVariant.customerVariantId}`] =
              buylistVariant.allowPurchase == null ||
              buylistVariant.allowPurchase == undefined
                ? false
                : buylistVariant.allowPurchase;
            buylistRule[`cashPercent-${buylistVariant.customerVariantId}`] =
              buylistVariant.cashPercent;
            buylistRule[
              `storeCreditPercent-${buylistVariant.customerVariantId}`
            ] = buylistVariant.storeCreditPercent;
            buylistRule[`quantity-${buylistVariant.customerVariantId}`] =
              buylistVariant.quantity;
            buylistRule[
              `overQuantityCashPercent-${buylistVariant.customerVariantId}`
            ] = buylistVariant.overQuantityCashPercent;
            buylistRule[
              `overQuantityAllowPurchase-${buylistVariant.customerVariantId}`
            ] =
              buylistVariant.overQuantityAllowPurchase == null ||
              buylistVariant.overQuantityAllowPurchase == undefined
                ? false
                : buylistVariant.overQuantityAllowPurchase;
            buylistRule[
              `overQuantityStoreCreditPercent-${buylistVariant.customerVariantId}`
            ] = buylistVariant.overQuantityStoreCreditPercent;
            buylistVariant.buylistVariantRarityRules.forEach(
              (buylistVariantRarity) => {
                const rarityNoSpace = buylistVariantRarity.rarity
                  .replace(/\s/g, "")
                  .replace(/[^a-zA-Z0-9 ]/g, "");
                buylistRule[
                  `id-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.id;
                buylistRule[
                  `allowPurchase-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] =
                  buylistVariantRarity.allowPurchase == null ||
                  buylistVariantRarity.allowPurchase == undefined
                    ? false
                    : buylistVariantRarity.allowPurchase;
                buylistRule[
                  `cashPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.cashPercent;
                buylistRule[
                  `storeCreditPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.storeCreditPercent;
                buylistRule[
                  `quantity-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.quantity;
                buylistRule[
                  `overQuantityCashPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.overQuantityCashPercent;
                buylistRule[
                  `overQuantityAllowPurchase-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] =
                  buylistVariantRarity.overQuantityAllowPurchase == null ||
                  buylistVariantRarity.overQuantityAllowPurchase == undefined
                    ? false
                    : buylistVariantRarity.overQuantityAllowPurchase;
                buylistRule[
                  `overQuantityStoreCreditPercent-${buylistVariant.customerVariantId}-${rarityNoSpace}`
                ] = buylistVariantRarity.overQuantityStoreCreditPercent;
              }
            );
          }
        );
        console.log({ buylistRule });
        this.setBuylistRuleToApply(buylistRule);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load buylist rule",
          "There was an error retrieving a rule for your buylists. Please refresh and try again"
        );
      });
  }

  fetchSetInfo(type) {
    if (type) {
      this.props.store.CardStore.setSearchResults(null);
      this.props.store.CardStore.fetchCardSetNames(type)
        .then((result) => {
          const filteredResults = result.filter((set) => set !== null);
          this.props.store.CardStore.setSearchResults(filteredResults);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load sets",
            "There was an error retrieving the list of set names. Please refresh and try again"
          );
        });
    }
  }

  fetchCardRarities(type) {
    if (type) {
      this.props.store.CardStore.setRarities(null);
      this.props.store.CardStore.fetchCardRarities(type)
        .then((result) => {
          const filteredResults = result.filter((rarity) => rarity !== null);
          this.props.store.CardStore.setRarities(filteredResults);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to load rarities",
            "There was an error retrieving the list of rarities. Please refresh and try again"
          );
        });
    }
  }

  fetchCustomerVariants() {
    this.props.store.CustomersStore.getCustomerVariants()
      .then((result) => {
        this.props.store.CustomersStore.setCustomerVariants(result);
        if (result.length > 0) {
          this.setSelectedCondition(result[0].id);
        }
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load variants",
          "There was an error retrieving the list of variants. Please refresh and try again"
        );
      });
  }

  fetchCardGames() {
    this.props.store.CardStore.fetchCardGames()
      .then((result) => {
        const filteredResults = result.filter((game) => game.gameName !== null);
        this.props.store.CardStore.setGames(filteredResults);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load games",
          "There was an error retrieving the list of games. Please refresh and try again"
        );
      });
  }

  componentDidMount() {
    this.props.store.CardStore.setRarities(null);
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);
    this.props.store.CardStore.setSearchResults(null);
    this.setBuylistIdPresent(null);
    const id = this.props.match?.params?.buylistRuleId || null;
    this.setBuylistRuleToApply(null);
    this.fetchCardGames();
    this.fetchCustomerVariants();
    if (id) {
      document.title = "Update Buylist Rule | BinderPOS";
      this.getBuylistRule(id);
      this.setBuylistIdPresent(true);
    } else {
      this.setBuylistIdPresent(false);
      document.title = "Add Buylist Rule | BinderPOS";
    }
  }

  fetchSetsForSelectedItem() {
    const gameSelect = document.querySelector("#game");
    const selectedGame = gameSelect.options[gameSelect.selectedIndex].value;
    if (selectedGame == "all") {
      this.props.store.CardStore.setSearchResults(null);
      this.props.store.CardStore.setRarities(null);
    } else {
      this.fetchSetInfo(selectedGame);
      this.fetchCardRarities(selectedGame);
    }
  }

  fetchCardsForSelectedSet() {
    const gameSelect = document.querySelector("#game");
    const selectedGame = gameSelect.options[gameSelect.selectedIndex].value;
    const setSelect = document.querySelector("#set");
    const selectedSet = setSelect.options[setSelect.selectedIndex].value;
    console.log({ selectedGame });
    if (selectedGame != "all") {
      this.props.store.CardStore.fetchCardsWithinSet(selectedGame, selectedSet)
        .then((result) => {
          this.props.store.CardStore.setCards(result);
        })
        .catch((error) => {
          this.props.store.MainStore.setError(
            error,
            "Failed to cards",
            "There was an error retrieving the list of cards for the selected set. Please refresh and try again"
          );
        });
    }
  }

  showOrHideField(key, className, checkbox, childCheckboxLabel) {
    const checkboxToFind = `#${checkbox}-${key}`;
    const divToFind = `#${className}-${key}`;
    const checkboxInput = document.querySelector(checkboxToFind);
    const divToShowOrHide = document.querySelector(divToFind);
    if (childCheckboxLabel && childCheckboxLabel.length > 0) {
      var childCheckboxLabelElement = document.querySelector(
        `#${childCheckboxLabel}`
      );
    }
    if (!checkboxInput.checked) {
      divToShowOrHide.classList.add("hideItem");
      if (childCheckboxLabelElement) {
        childCheckboxLabelElement.classList.add("hideItem");
      }
    } else {
      divToShowOrHide.classList.remove("hideItem");
      if (childCheckboxLabelElement) {
        childCheckboxLabelElement.classList.remove("hideItem");
      }
    }
  }

  validateBuylistRule(elm) {
    let modalText = "";
    let canSubmit = true;
    const { customerVariants } = this.props.store.CustomersStore;
    if (!elm.buylistRuleName.value || elm.buylistRuleName.value == "") {
      modalText += "<p>Please enter a name for your buylist rule</p><br/>";
      canSubmit = false;
    }
    for (let i = 0; i < customerVariants.length; i++) {
      if (
        elm[`allowPurchaseCheckbox-${customerVariants[i].id}`].checked == true
      ) {
        if (
          !elm[`cashPercent-${customerVariants[i].id}`].value ||
          elm[`cashPercent-${customerVariants[i].id}`].value == ""
        ) {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Cash buy percentage</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (
          !elm[`storeCreditPercent-${customerVariants[i].id}`].value ||
          elm[`storeCreditPercent-${customerVariants[i].id}`].value == ""
        ) {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Store credit buy percentage</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (
          !elm[`quantity-${customerVariants[i].id}`].value ||
          elm[`quantity-${customerVariants[i].id}`].value == ""
        ) {
          modalText += `<p>Value is required for <strong>${customerVariants[i].name} Stock level</strong> when you allow the purchase of this variant</p><br/>`;
          canSubmit = false;
        }
        if (
          elm[`allowOverStockPurchaseCheckbox-${customerVariants[i].id}`]
            .checked == true
        ) {
          if (
            !elm[`overQuantityCashPercent-${customerVariants[i].id}`].value ||
            elm[`overQuantityCashPercent-${customerVariants[i].id}`].value == ""
          ) {
            modalText += `<p>Value is required for <strong>${customerVariants[i].name} Cash buy percentage over stock level</strong> when you allow the purchase of this variant when over stocked</p><br/>`;
            canSubmit = false;
          }
          if (
            !elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`]
              .value ||
            elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`]
              .value == ""
          ) {
            modalText += `<p>Value is required for <strong>${customerVariants[i].name} Store credit buy percentage over stock level</strong> when you allow the purchase of this variant when over stocked</p><br/>`;
            canSubmit = false;
          }
        }
      }
    }
    if (!canSubmit) {
      this.setUpdating(false);
      this.setModalVisible(true);
      this.setModalTitle("Fix the following issues with your buylist rule");
      this.setModalText(modalText);
      this.setHasValidationErrors(true);
    }
    return canSubmit;
  }

  save(e) {
    e.preventDefault();
    this.setHasValidationErrors(false);
    this.setUpdating(true);

    const elm = this.form.current.elements;

    const canSubmit = this.validateBuylistRule(elm);

    if (canSubmit == true) {
      const listOfVariantRules = [];
      const { customerVariants } = this.props.store.CustomersStore;
      for (let i = 0; i < customerVariants.length; i++) {
        const listOfRarityRules = [];
        const { rarities } = this.props.store.CardStore;
        if (rarities && rarities.length > 0) {
          for (let j = 0; j < rarities.length; j++) {
            const rarityNoSpace = rarities[j]
              .replace(/\s/g, "")
              .replace(/[^a-zA-Z0-9 ]/g, "");
            const buylistVarirantRarityRule = {
              rarity: rarities[j],
              cashPercent:
                elm[`cashPercent-${customerVariants[i].id}-${rarityNoSpace}`]
                  .value,
              storeCreditPercent:
                elm[
                  `storeCreditPercent-${customerVariants[i].id}-${rarityNoSpace}`
                ].value,
              quantity:
                elm[`quantity-${customerVariants[i].id}-${rarityNoSpace}`]
                  .value,
              allowPurchase:
                !!elm[
                  `allowPurchaseCheckbox-${customerVariants[i].id}-${rarityNoSpace}`
                ].checked,
              overQuantityCashPercent:
                elm[
                  `overQuantityCashPercent-${customerVariants[i].id}-${rarityNoSpace}`
                ].value,
              overQuantityStoreCreditPercent:
                elm[
                  `overQuantityStoreCreditPercent-${customerVariants[i].id}-${rarityNoSpace}`
                ].value,
              overQuantityAllowPurchase:
                !!elm[
                  `allowOverStockPurchaseCheckbox-${customerVariants[i].id}-${rarityNoSpace}`
                ].checked,
            };
            if (this.props.match?.params?.buylistRuleId) {
              if (
                elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value &&
                elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value != ""
              ) {
                buylistVarirantRarityRule.id =
                  elm[`id-${customerVariants[i].id}-${rarityNoSpace}`].value;
              } else {
                buylistVarirantRarityRule.id = null;
              }
            }
            listOfRarityRules.push(buylistVarirantRarityRule);
          }
        }
        const buylistVarirantRule = {
          customerVariantId: customerVariants[i].id,
          cashPercent: elm[`cashPercent-${customerVariants[i].id}`].value,
          storeCreditPercent:
            elm[`storeCreditPercent-${customerVariants[i].id}`].value,
          quantity: elm[`quantity-${customerVariants[i].id}`].value,
          allowPurchase:
            !!elm[`allowPurchaseCheckbox-${customerVariants[i].id}`].checked,
          overQuantityCashPercent:
            elm[`overQuantityCashPercent-${customerVariants[i].id}`].value,
          overQuantityStoreCreditPercent:
            elm[`overQuantityStoreCreditPercent-${customerVariants[i].id}`]
              .value,
          overQuantityAllowPurchase:
            !!elm[`allowOverStockPurchaseCheckbox-${customerVariants[i].id}`]
              .checked,
          buylistVariantRarityRules: listOfRarityRules,
        };
        if (this.props.match?.params?.buylistRuleId) {
          buylistVarirantRule.id = elm[`id-${customerVariants[i].id}`].value;
        }
        listOfVariantRules.push(buylistVarirantRule);
      }

      if (this.props.match?.params?.buylistRuleId) {
        const buylistRule = {
          id: this.props.match.params.buylistRuleId,
          name: elm.buylistRuleName.value,
          price: elm.buylistRulePrice.value,
          priceRule:
            elm.buylistRulePriceRuleSelect[
              elm.buylistRulePriceRuleSelect.selectedIndex
            ].value,
          buylistVariants: listOfVariantRules,
        };
        updateBuylistRule(buylistRule)
          .then((result) => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle("Buylist rule updated");
            this.setModalText(result.message);
            this.props.store.BuylistRulesStore.rehydrate();
          })
          .catch((error) => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(
              error,
              "Failed to update buylist rule",
              "There was an error submitting your buylist rule. Please try again"
            );
          });
      } else {
        const buylistRule = {
          name: elm.buylistRuleName.value,
          price: elm.buylistRulePrice.value,
          priceRule:
            elm.buylistRulePriceRuleSelect[
              elm.buylistRulePriceRuleSelect.selectedIndex
            ].value,
          game: elm.game[elm.game.selectedIndex].value,
          buylistVariants: listOfVariantRules,
        };
        if (elm.set && elm.set.length > 0) {
          buylistRule.setName = elm.set[elm.set.selectedIndex].value;
        }
        if (elm.card && elm.card.length > 0) {
          buylistRule.cardId = elm.card[elm.card.selectedIndex].value;
          buylistRule.cardName = elm.card[elm.card.selectedIndex].text;
        }
        addBuylistRule(buylistRule)
          .then((result) => {
            this.setUpdating(false);
            this.setModalVisible(true);
            this.setModalTitle("Buylist rule added");
            this.setModalText(result.message);
            this.props.store.BuylistRulesStore.rehydrate();
          })
          .catch((error) => {
            this.setUpdating(false);
            this.props.store.MainStore.setError(
              error,
              "Failed to add buylist rule",
              "There was an error submitting your buylist rule. Please try again"
            );
          });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.store.AuthStore.screenSettings.buylist ? (
          <>
            {this.props.store.CustomersStore.customerVariants &&
            this.props.store.CardStore.games &&
            ((this.buylistIdPresent === true && this.buylistRuleToApply) ||
              this.buylistIdPresent === false) ? (
              <>
                <form ref={this.form} onSubmit={(e) => this.save(e)} noValidate>
                  <div className="app-header">
                    <h2 className="title is-2">
                      <span className="header-text">
                        {this.props.store.BuylistRulesStore.buylistRule
                          ? `Update ${this.props.store.BuylistRulesStore.buylistRule.name}`
                          : "Create new rule"}
                      </span>
                      <button type="submit" className="btn1">
                        {this.props.match?.params?.buylistRuleId
                          ? "Update rule"
                          : "Save rule"}{" "}
                        <i className="fas fa-caret-right" />
                      </button>
                    </h2>
                  </div>
                  <div className="newEvent">
                    <div className="topcontent">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 col-xl-4">
                            <label className="label" htmlFor="buylistRuleName">
                              Buylist Rule Name:
                            </label>
                            <div className="control">
                              <input
                                id="buylistRuleName"
                                className="input"
                                type="text"
                                placeholder="E.g. Price greater than $100 rule"
                                required
                                defaultValue={
                                  this.buylistRuleToApply?.name || ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eventInformation">
                      <div className="container-fluid">
                        <p className="subHeading">Basic Rules</p>
                        <div className="row">
                          <div className="col-lg-12 col-xl-5col">
                            <label className="label">Price to apply</label>
                            <div className="control">
                              <input
                                id="buylistRulePrice"
                                className="input"
                                type="number"
                                digits="2"
                                min="0"
                                placeholder="E.g. $100"
                                required
                                defaultValue={
                                  this.buylistRuleToApply &&
                                  (this.buylistRuleToApply.price ||
                                    this.buylistRuleToApply.price == 0)
                                    ? this.buylistRuleToApply.price
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-5col">
                            <label className="label">Price rule to apply</label>
                            <div className="control">
                              <div className="select">
                                <select
                                  id="buylistRulePriceRuleSelect"
                                  defaultValue={
                                    this.buylistRuleToApply?.priceRule || ""
                                  }
                                >
                                  <option value="">Don&apos;t apply</option>
                                  <option value="greaterThan">
                                    Greater than
                                  </option>
                                  <option value="greaterThanOrEqualTo">
                                    Greater than or Equal to
                                  </option>
                                  <option value="lessThan">Less than</option>
                                  <option value="lessThanOrEqualTo">
                                    Less than or Equal to
                                  </option>
                                  <option value="EqualTo">Equal to</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {!this.props.store.BuylistRulesStore.buylistRule ? (
                            <div className="col-lg-12 col-xl-5col">
                              <label className="label" htmlFor="game">
                                Rule to apply to Game
                              </label>
                              <div className="control has-icons-left">
                                <div className="select">
                                  <select
                                    id="game"
                                    onChange={this.fetchSetsForSelectedItem.bind(
                                      this
                                    )}
                                  >
                                    <option value="all">All games</option>
                                    {this.props.store.CardStore.games.map(
                                      (game, i) => (
                                        <option key={i} value={game.gameId}>
                                          {game.gameName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-12 col-xl-5col">
                              <label className="label">
                                Rule to apply to Game
                              </label>
                              <p>
                                {this.props.store.BuylistRulesStore.buylistRule
                                  .game &&
                                this.props.store.BuylistRulesStore.buylistRule
                                  .game != "all"
                                  ? this.props.store.BuylistRulesStore
                                      .buylistRule.gameName
                                  : "All games"}
                              </p>
                            </div>
                          )}
                          {!this.props.store.BuylistRulesStore.buylistRule ? (
                            <div
                              className={
                                this.props.store.CardStore.searchResults
                                  ? "col-lg-12 col-xl-5col"
                                  : "col-lg-12 col-xl-5col notActivePanel"
                              }
                            >
                              <label className="label" htmlFor="set">
                                Rule to apply to Set
                              </label>
                              <div className="control has-icons-left">
                                <div className="select">
                                  <select
                                    id="set"
                                    onChange={this.fetchCardsForSelectedSet.bind(
                                      this
                                    )}
                                  >
                                    <option value="">No set chosen</option>
                                    {this.props.store.CardStore
                                      .searchResults ? (
                                      <>
                                        {this.props.store.CardStore.searchResults.map(
                                          (set, i) => (
                                            <option key={i} value={set}>
                                              {set}
                                            </option>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {this.props.store.BuylistRulesStore.buylistRule
                                .setName ? (
                                <div className="col-lg-12 col-xl-5col">
                                  <label className="label">
                                    Rule to apply to Set
                                  </label>
                                  <p>
                                    {
                                      this.props.store.BuylistRulesStore
                                        .buylistRule.setName
                                    }
                                  </p>
                                </div>
                              ) : null}
                            </>
                          )}
                          {!this.props.store.BuylistRulesStore.buylistRule ? (
                            <div
                              className={
                                this.props.store.CardStore.cards
                                  ? "col-lg-12 col-xl-5col"
                                  : "col-lg-12 col-xl-5col notActivePanel"
                              }
                            >
                              <label className="label">
                                Rule to apply to Card
                              </label>
                              <div className="control has-icons-left">
                                <div className="select">
                                  <select id="card">
                                    <option value="">No card chosen</option>
                                    {this.props.store.CardStore.cards ? (
                                      <>
                                        {this.props.store.CardStore.cards.map(
                                          (card, i) => (
                                            <option key={i} value={card.id}>
                                              {card.cardName}
                                            </option>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {this.props.store.BuylistRulesStore.buylistRule
                                .cardName ? (
                                <div className="col-lg-12 col-xl-5col">
                                  <label className="label">
                                    Rule to apply to Card
                                  </label>
                                  <p>
                                    {
                                      this.props.store.BuylistRulesStore
                                        .buylistRule.cardName
                                    }
                                  </p>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="conditionSelector">
                      {this.props.store.CustomersStore.customerVariants.map(
                        (customerVariant, i) => (
                          <Fragment key={i}>
                            <span
                              style={{
                                width: `calc(calc(${
                                  100 /
                                  this.props.store.CustomersStore
                                    .customerVariants.length
                                }%) - 15px)`,
                              }}
                              className={`tab tabid-${customerVariant.id}${
                                this.selectedCondition == customerVariant.id
                                  ? " active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.setSelectedCondition(customerVariant.id)
                              }
                            >
                              {customerVariant.name}
                            </span>
                          </Fragment>
                        )
                      )}
                    </div>
                    <div className="rulesWrapper">
                      {this.props.store.CustomersStore.customerVariants.map(
                        (customerVariant, i) => (
                          <Fragment key={i}>
                            <div
                              className={`rulesBlock variants variant-${
                                customerVariant.id
                              }${
                                this.selectedCondition == customerVariant.id
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <div className="row">
                                <div className="col-lg-12 col-xl-6">
                                  <div className="field">
                                    <label className="checkbox">
                                      <input
                                        id={`allowPurchaseCheckbox-${customerVariant.id}`}
                                        type="checkbox"
                                        onChange={this.showOrHideField.bind(
                                          this,
                                          customerVariant.id,
                                          "allowPurchase",
                                          "allowPurchaseCheckbox"
                                        )}
                                        defaultChecked={
                                          this.buylistRuleToApply
                                            ? this.buylistRuleToApply[
                                                `allowPurchase-${customerVariant.id}`
                                              ]
                                            : false
                                        }
                                      />
                                      <span className="checkmark" />
                                      Allow the purchase of these cards from
                                      customers
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                  <div className="field">
                                    <label className="checkbox">
                                      <input
                                        id={`allowOverStockPurchaseCheckbox-${customerVariant.id}`}
                                        type="checkbox"
                                        onChange={this.showOrHideField.bind(
                                          this,
                                          customerVariant.id,
                                          "allowOverStockPurchase",
                                          "allowOverStockPurchaseCheckbox"
                                        )}
                                        defaultChecked={
                                          this.buylistRuleToApply
                                            ? this.buylistRuleToApply[
                                                `overQuantityAllowPurchase-${customerVariant.id}`
                                              ]
                                            : false
                                        }
                                      />
                                      <span className="checkmark" />
                                      Allow the purchase of these cards when
                                      stock level is too high
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="card-content content">
                                {this.props.store.BuylistRulesStore
                                  .buylistRule ? (
                                  <div className="hideItem">
                                    <input
                                      id={`id-${customerVariant.id}`}
                                      type="hidden"
                                      defaultValue={
                                        this.buylistRuleToApply
                                          ? this.buylistRuleToApply[
                                              `id-${customerVariant.id}`
                                            ]
                                          : ""
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div
                                  id={`allowPurchase-${customerVariant.id}`}
                                  className={
                                    this.buylistRuleToApply
                                      ? this.buylistRuleToApply[
                                          `allowPurchase-${customerVariant.id}`
                                        ]
                                        ? ""
                                        : "hideItem"
                                      : "hideItem"
                                  }
                                >
                                  <div className="row">
                                    <div className="col-lg-12 col-xl-4">
                                      <SideLabelNumberInput
                                        label="Cash buy percentage"
                                        inputId={`cashPercent-${customerVariant.id}`}
                                        digits="2"
                                        min="0"
                                        placeholder="E.g. 60%"
                                        required={false}
                                        helpText="For example the card is worth $1.00, if you put 60% below this indicates to your customers that you will buy it off them for $0.60 cash"
                                        defaultValue={
                                          this.buylistRuleToApply
                                            ? this.buylistRuleToApply[
                                                `cashPercent-${customerVariant.id}`
                                              ]
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-12 col-xl-4">
                                      <SideLabelNumberInput
                                        label="Store credit buy percentage"
                                        inputId={`storeCreditPercent-${customerVariant.id}`}
                                        digits="2"
                                        min="0"
                                        placeholder="E.g. 70%"
                                        required={false}
                                        helpText="For example the card is worth $1.00, if you put 70% below this indicates to your customers that you will buy it off them for $0.70 store credit"
                                        defaultValue={
                                          this.buylistRuleToApply
                                            ? this.buylistRuleToApply[
                                                `storeCreditPercent-${customerVariant.id}`
                                              ]
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-12 col-xl-4">
                                      <SideLabelNumberInput
                                        label="Stock level"
                                        inputId={`quantity-${customerVariant.id}`}
                                        digits="0"
                                        min="0"
                                        placeholder="E.g. 10"
                                        required={false}
                                        helpText="Cards that have the stock equal to or greater than the number entered below can have additional rules."
                                        defaultValue={
                                          this.buylistRuleToApply
                                            ? this.buylistRuleToApply[
                                                `quantity-${customerVariant.id}`
                                              ]
                                            : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    id={`allowOverStockPurchase-${customerVariant.id}`}
                                    className={`revised ${
                                      this.buylistRuleToApply
                                        ? this.buylistRuleToApply[
                                            `overQuantityAllowPurchase-${customerVariant.id}`
                                          ]
                                          ? ""
                                          : "hideItem"
                                        : "hideItem"
                                    }`}
                                  >
                                    <p className="subHeading">
                                      Overstock percentage overide
                                    </p>
                                    <div className="row">
                                      <div className="col-lg-12 col-xl-4">
                                        <SideLabelNumberInput
                                          label="Cash buy percentage"
                                          inputId={`overQuantityCashPercent-${customerVariant.id}`}
                                          digits="2"
                                          min="0"
                                          placeholder="E.g. 40%"
                                          required={false}
                                          helpText="For example the card is worth $1.00, if you put 40% below this indicates to your customers that you will buy it off them for $0.40 cash"
                                          defaultValue={
                                            this.buylistRuleToApply
                                              ? this.buylistRuleToApply[
                                                  `overQuantityCashPercent-${customerVariant.id}`
                                                ]
                                              : ""
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-12 col-xl-4">
                                        <SideLabelNumberInput
                                          label="Store credit buy percentage"
                                          inputId={`overQuantityStoreCreditPercent-${customerVariant.id}`}
                                          digits="2"
                                          min="0"
                                          placeholder="E.g. 45%"
                                          required={false}
                                          helpText="For example the card is worth $1.00, if you put 45% below this indicates to your customers that you will buy it off them for $0.45 store credit"
                                          defaultValue={
                                            this.buylistRuleToApply
                                              ? this.buylistRuleToApply[
                                                  `overQuantityStoreCreditPercent-${customerVariant.id}`
                                                ]
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="row">
                                    {this.props.store.CardStore.rarities ? (
                                      <div
                                        className={`rarityWrapper rarityContainer-${customerVariant.id}`}
                                      >
                                        <p className="subHeading centered">
                                          Rarities:
                                        </p>
                                        {this.props.store.CardStore.rarities.map(
                                          (rarity) => {
                                            const rarityNoSpace = rarity
                                              .replace(/\s/g, "")
                                              .replace(/[^a-zA-Z0-9 ]/g, "");
                                            return (
                                              <div
                                                className={`rarities variants rarity-${rarityNoSpace}`}
                                                key={`${customerVariant.id}-${rarityNoSpace}`}
                                              >
                                                <div className="card-content content">
                                                  <div className="row">
                                                    <div className="col-lg-6 col-xl-4">
                                                      <div className="field">
                                                        <label className="checkbox">
                                                          <input
                                                            id={`allowPurchaseCheckbox-${customerVariant.id}-${rarityNoSpace}`}
                                                            type="checkbox"
                                                            onChange={this.showOrHideField.bind(
                                                              this,
                                                              rarityNoSpace,
                                                              `allowPurchase-${customerVariant.id}`,
                                                              `allowPurchaseCheckbox-${customerVariant.id}`,
                                                              `allowOverStockPurchaseCheckboxLabel-${customerVariant.id}-${rarityNoSpace}`
                                                            )}
                                                            defaultChecked={
                                                              this
                                                                .buylistRuleToApply
                                                                ? this
                                                                    .buylistRuleToApply[
                                                                    `allowPurchase-${customerVariant.id}-${rarityNoSpace}`
                                                                  ]
                                                                : false
                                                            }
                                                          />
                                                          <span className="checkmark" />
                                                          Purchase {rarity}
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-4">
                                                      <div className="field">
                                                        <label
                                                          className={`checkbox ${
                                                            this
                                                              .buylistRuleToApply &&
                                                            this
                                                              .buylistRuleToApply[
                                                              `allowPurchase-${customerVariant.id}-${rarityNoSpace}`
                                                            ]
                                                              ? ""
                                                              : "hideItem"
                                                          }`}
                                                          id={`allowOverStockPurchaseCheckboxLabel-${customerVariant.id}-${rarityNoSpace}`}
                                                        >
                                                          <input
                                                            id={`allowOverStockPurchaseCheckbox-${customerVariant.id}-${rarityNoSpace}`}
                                                            type="checkbox"
                                                            onChange={this.showOrHideField.bind(
                                                              this,
                                                              rarityNoSpace,
                                                              `allowOverStockPurchase-${customerVariant.id}`,
                                                              `allowOverStockPurchaseCheckbox-${customerVariant.id}`
                                                            )}
                                                            defaultChecked={
                                                              this
                                                                .buylistRuleToApply
                                                                ? this
                                                                    .buylistRuleToApply[
                                                                    `overQuantityAllowPurchase-${customerVariant.id}-${rarityNoSpace}`
                                                                  ]
                                                                : false
                                                            }
                                                          />
                                                          <span className="checkmark" />{" "}
                                                          Allow overstocked
                                                          purchases
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {this.props.store
                                                    .BuylistRulesStore
                                                    .buylistRule ? (
                                                    <div className="hideItem">
                                                      <input
                                                        id={`id-${customerVariant.id}-${rarityNoSpace}`}
                                                        type="hidden"
                                                        defaultValue={
                                                          this
                                                            .buylistRuleToApply
                                                            ? this
                                                                .buylistRuleToApply[
                                                                `id-${customerVariant.id}-${rarityNoSpace}`
                                                              ]
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                  <div
                                                    id={`allowPurchase-${customerVariant.id}-${rarityNoSpace}`}
                                                    className={
                                                      this.buylistRuleToApply
                                                        ? this
                                                            .buylistRuleToApply[
                                                            `allowPurchase-${customerVariant.id}-${rarityNoSpace}`
                                                          ]
                                                          ? ""
                                                          : "hideItem"
                                                        : "hideItem"
                                                    }
                                                  >
                                                    <div className="row">
                                                      <div className="col-lg-12 col-xl-4">
                                                        <SideLabelNumberInput
                                                          helpText="Only input a % if overiding default rule settings"
                                                          label="Cash buy percentage"
                                                          inputId={`cashPercent-${customerVariant.id}-${rarityNoSpace}`}
                                                          digits="2"
                                                          min="0"
                                                          required={false}
                                                          defaultValue={
                                                            this
                                                              .buylistRuleToApply
                                                              ? this
                                                                  .buylistRuleToApply[
                                                                  `cashPercent-${customerVariant.id}-${rarityNoSpace}`
                                                                ]
                                                              : ""
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-lg-12 col-xl-4">
                                                        <SideLabelNumberInput
                                                          helpText="Only input a % if overiding default rule settings"
                                                          label="Store credit buy percentage"
                                                          inputId={`storeCreditPercent-${customerVariant.id}-${rarityNoSpace}`}
                                                          digits="2"
                                                          min="0"
                                                          required={false}
                                                          defaultValue={
                                                            this
                                                              .buylistRuleToApply
                                                              ? this
                                                                  .buylistRuleToApply[
                                                                  `storeCreditPercent-${customerVariant.id}-${rarityNoSpace}`
                                                                ]
                                                              : ""
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-lg-12 col-xl-4">
                                                        <SideLabelNumberInput
                                                          helpText="Only input a qty if overiding default rule settings"
                                                          label="Stock level"
                                                          inputId={`quantity-${customerVariant.id}-${rarityNoSpace}`}
                                                          digits="0"
                                                          min="0"
                                                          required={false}
                                                          defaultValue={
                                                            this
                                                              .buylistRuleToApply
                                                              ? this
                                                                  .buylistRuleToApply[
                                                                  `quantity-${customerVariant.id}-${rarityNoSpace}`
                                                                ]
                                                              : ""
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div
                                                      id={`allowOverStockPurchase-${customerVariant.id}-${rarityNoSpace}`}
                                                      className={`revised ${
                                                        this.buylistRuleToApply
                                                          ? this
                                                              .buylistRuleToApply[
                                                              `overQuantityAllowPurchase-${customerVariant.id}-${rarityNoSpace}`
                                                            ]
                                                            ? ""
                                                            : "hideItem"
                                                          : "hideItem"
                                                      }`}
                                                    >
                                                      <p className="subHeading">
                                                        Revised {rarity}{" "}
                                                        percentages if
                                                        overstocked
                                                      </p>
                                                      <div className="row">
                                                        <div className="col-lg-12 col-xl-4">
                                                          <SideLabelNumberInput
                                                            helpText="Only input a % if overiding default rule settings"
                                                            label="Cash buy percentage"
                                                            inputId={`overQuantityCashPercent-${customerVariant.id}-${rarityNoSpace}`}
                                                            digits="2"
                                                            min="0"
                                                            required={false}
                                                            defaultValue={
                                                              this
                                                                .buylistRuleToApply
                                                                ? this
                                                                    .buylistRuleToApply[
                                                                    `overQuantityCashPercent-${customerVariant.id}-${rarityNoSpace}`
                                                                  ]
                                                                : ""
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-lg-12 col-xl-4">
                                                          <SideLabelNumberInput
                                                            helpText="Only input a % if overiding default rule settings"
                                                            label="Store credit buy percentage"
                                                            inputId={`overQuantityStoreCreditPercent-${customerVariant.id}-${rarityNoSpace}`}
                                                            digits="2"
                                                            min="0"
                                                            required={false}
                                                            defaultValue={
                                                              this
                                                                .buylistRuleToApply
                                                                ? this
                                                                    .buylistRuleToApply[
                                                                    `overQuantityStoreCreditPercent-${customerVariant.id}-${rarityNoSpace}`
                                                                  ]
                                                                : ""
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )
                      )}
                    </div>
                    <button type="submit" className="btn1">
                      {this.props.match?.params?.buylistRuleId
                        ? "Update rule"
                        : "Save rule"}{" "}
                      <i className="fas fa-caret-right" />
                    </button>
                  </div>
                </form>
                {this.modalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{this.modalTitle}</p>
                      </header>
                      <section
                        className="modal-card-body"
                        dangerouslySetInnerHTML={{ __html: this.modalText }}
                      />
                      <footer className="modal-card-foot">
                        {this.hasValidationErrors ? (
                          <button
                            className="modalBtn action full"
                            onClick={() => this.setModalVisible(false)}
                          >
                            Ok
                          </button>
                        ) : (
                          <Link
                            to="/buylists/rules"
                            className="modalBtn action full"
                          >
                            Ok
                          </Link>
                        )}
                      </footer>
                    </div>
                  </div>
                ) : null}
                {this.updating ? (
                  <Loader
                    text={
                      this.props.store.BuylistRulesStore.buylistRule
                        ? "Updating your buylist rule..."
                        : "Adding your new buylist rule..."
                    }
                  />
                ) : null}
              </>
            ) : (
              <Loader
                text={
                  this.props.store.BuylistRulesStore.buylistRule
                    ? "Loading buylist rule information... Please wait a moment."
                    : "Loading information for buylist rules... Please wait a moment"
                }
              />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

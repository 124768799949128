import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import ItemTitle from "./ItemTitle";
import { OperationalMode, operationalMode } from "../../constants/mode";
import BuyIcon from "../../../assets/img/buy.svg";
import SellIcon from "../../../assets/img/sell.svg";

@inject("posStore")
@observer
class GridItem extends Component {
  attributes = {
    className: "custom-root",
    disabledClassName: "custom-disabled",
    dividerClassName: "custom-divider",
    selectedClassName: "custom-selected",
  };

  @action
  handleAddToQuickLinks = () => {
    const variantInfo =
      this.props.item.variants[this.props.item.selectedVariant];
    this.props.posStore.currentNav.push({
      color: "grey",
      icon: null,
      type: "variant",
      value: {
        title: this.props.item.title,
        variantTitle: variantInfo.title,
        variantId: variantInfo.id,
        imgUrl: this.props.item.img,
      },
    });
    this.props.posStore.updateQuickLinks();
  };

  constructor(props) {
    super(props);
  }

  @action
  updatePrice = (event) => {
    if (this.props.store.buyMode) {
      this.props.item.selectedBuyVariant = event.target.value;
    } else {
      this.props.item.selectedVariant = event.target.value;
    }
  };

  @action
  checkStock = () => {
    if (!this.props.store.stockLimit) {
      if (this.props.item?.selectedVariant < 0) {
        this.props.item.selectedBuyVariant = 0;
      } else if (this.props.item?.selectedVariant >= 0) {
        this.props.item.selectedBuyVariant = this.props.item.selectedVariant;
      }
      if (this.props.item?.selectedVariant < 1) {
        this.props.item.selectedVariant = 0;
      }
    }
  };

  @computed get totalInStock() {
    var total = 0;
    this.props.item.variants.forEach((variant) => {
      total += variant.quantity;
    });
    return total;
  }
  @action
  getStockedProduct() {
    if (this.props.item?.selectedVariant >= 0) {
      return;
    }
    var revArr = this.props.item.variants.slice().reverse();
    revArr.forEach((variant, index) => {
      if (variant.quantity > 0) {
        this.props.item.selectedVariant =
          this.props.item.variants.length - index - 1;
      }
    });
  }

  isItemForSale = (variant, buyMode, stockLimit, isKioskMode) => {
    if (buyMode) return true;
    if (isKioskMode) {
      return variant?.quantity > 0;
    }
    return !(variant?.quantity <= "0" && stockLimit);
  };

  componentDidMount() {
    this.checkStock(0);
    this.getStockedProduct();
  }

  render() {
    const item = this.props.item;
    const store = this.props.store;
    const isKioskMode = operationalMode === OperationalMode.Kiosk;

    return (
      <React.Fragment>
        <div
          className={
            "cardpos " +
            (this.totalInStock >= 1
              ? this.totalInStock == 1
                ? "stockLowStock"
                : "stockAvailable"
              : "stockOutofStock")
          }
          key={item.id}
          onContextMenu={this.handleContextMenu}
          contextMenu
        >
          <ContextMenuTrigger id={item.id.toFixed()}>
            {!this.props.store.buyMode ? (
              <img className="cartBadge" src={BuyIcon} alt="" />
            ) : (
              <img className="cartBadge" src={SellIcon} alt="" />
            )}
            <span className="stock">Stock: {this.totalInStock} </span>
            <div className="cardpos-container">
              <img src={item.img} alt="" />
            </div>
          </ContextMenuTrigger>
          <React.Fragment>
            <div className="productDetails">
              <ItemTitle title={item.title} event={item.event} />
              <span
                className={
                  this.props.store.buyMode
                    ? "sellPrice sellPrice--visible"
                    : "sellPrice sellPrice--hidden"
                }
              >
                Sell Price: {this.props.store.currency + " "}{" "}
                {item.variants[item.selectedBuyVariant]?.price?.toFixed(2)}
              </span>
              <span className="variant">
                <select
                  value={
                    this.props.store.buyMode
                      ? item.selectedBuyVariant
                      : item.selectedVariant
                  }
                  onChange={this.updatePrice}
                >
                  {item.variants.map((variant, index) => (
                    <option
                      className={
                        variant.quantity >= 0
                          ? variant.quantity == 0
                            ? "blackClass"
                            : "greenClass"
                          : "redClass"
                      }
                      disabled={
                        !this.isItemForSale(
                          variant,
                          this.props.store.buyMode,
                          store.stockLimit,
                          isKioskMode
                        )
                      }
                      value={index}
                      key={index}
                    >
                      {variant.title + "\tx" + variant.quantity}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            {!this.props.store.buyMode ? (
              this.props.item.selectedVariant > -1 ? (
                <span className="price">
                  {this.props.store.currency + " "}{" "}
                  {item.variants[item.selectedVariant]?.price?.toFixed(2)}
                </span>
              ) : (
                <span className="price noStock">Out of Stock</span>
              )
            ) : this.props.item.selectedBuyVariant > -1 ? (
              <span className="price">
                {this.props.store.currency + " "}
                {this.props.store.cashPrice
                  ? item.variants[
                      item.selectedBuyVariant
                    ]?.cashBuyPrice?.toFixed(2)
                  : item.variants[
                      item.selectedBuyVariant
                    ]?.storeCreditBuyPrice?.toFixed(2)}
              </span>
            ) : (
              <span className="price">N/A</span>
            )}
            {this.isItemForSale(
              item.variants[item.selectedVariant],
              this.props.store.buyMode,
              store.stockLimit,
              isKioskMode
            ) ? (
              <span
                className="addToCart"
                style={
                  store.cartLoading
                    ? { pointerEvents: "none", backgroundColor: "#606968" }
                    : {}
                }
                onClick={() => {
                  if (
                    item.event &&
                    item.event.additionalInfo &&
                    item.event.additionalInfo.length > 0
                  )
                    return this.props.store.setAdditionalInfoItem(item);
                  this.props.addItem(item);
                }}
              >
                <i className="fas fa-plus"></i> Add
              </span>
            ) : (
              <span className="no-stock">Unavailable</span>
            )}
          </React.Fragment>
        </div>

        {!isKioskMode ? (
          <ContextMenu id={item.id.toFixed()}>
            <MenuItem
              data={{ action: "addLink" }}
              onClick={this.handleAddToQuickLinks}
              attributes={this.attributes}
            >
              Add To QuickLinks
            </MenuItem>
            <MenuItem
              data={{ action: "addLink" }}
              onClick={() => {
                window.open(
                  `https://${this.props.store.customerInfo.siteUrl}/admin/products/${this.props.item.shopifyId}`,
                  "_blank"
                );
              }}
              attributes={this.attributes}
            >
              Open in Shopify
            </MenuItem>
          </ContextMenu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default GridItem;

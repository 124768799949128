const CustomerMenuStructure = {
  title: "Customers",
  activeClass: "purple",
  links: [
    {
      title: "Customers",
      href: "customers/list",
    },
    {
      title: "Add customer",
      href: "customers/add",
    },
  ],
};

export default CustomerMenuStructure;

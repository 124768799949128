import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";
import React, { createRef, Component, RefObject } from "react";
import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";
import { Customer } from "./CustomerNote";
import { toast } from "react-toastify";
import { notificationParameters } from "../../constants/notifications";

interface EditCustomerSelectProps {
  onClose: Function;
  posStore?: {
    customerToEdit: Customer | null;
  };
}

type EditCustomerNoteState = {
  isSaving: boolean;
};

@inject("posStore")
@observer
class EditCustomerNote extends Component<
  EditCustomerSelectProps,
  EditCustomerNoteState
> {
  notesRef: RefObject<HTMLTextAreaElement>;

  constructor(props) {
    super(props);
    this.state = { isSaving: false };
    this.notesRef = createRef<HTMLTextAreaElement>();
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const payload = {
      ...this.props.posStore.customerToEdit,
      notes: this.notesRef.current.value,
    };

    _fetch({
      method: "PUT",
      endpoint: `${BASE_URL}/customers/update`,
      payload,
    })
      .then(() => {
        runInAction(() => {
          this.props.posStore.customerToEdit.notes =
            this.notesRef.current.value;
        });
        this.props.onClose();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to save settings", notificationParameters);
      });
  };

  render() {
    const { onClose } = this.props;
    const { customerToEdit } = this.props.posStore;

    if (!customerToEdit) return null;

    return (
      <div className="basic-modal modal is-active">
        <div className="modal-background" />
        <div className="basic-modal__container modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Customer Notes</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => onClose()}
            />
          </header>
          <section className="basic-modal__contents modal-card-body">
            <textarea
              ref={this.notesRef}
              defaultValue={customerToEdit.notes}
              className="EditCustomerNote"
            />
          </section>
          <footer className="modal-card-foot">
            <button className="modalBtn cancel half" onClick={() => onClose()}>
              Cancel
            </button>
            <button
              className="modalBtn action half"
              onClick={() => this.handleSave()}
              disabled={this.state.isSaving}
            >
              Save Notes
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default EditCustomerNote;

import debounce from "lodash/debounce";
import TimeUtils from "../../../utils/TimeUtils.js";
import React, { useEffect, useRef, useState } from "react";
import {
  EbayStockChangeInfo as StockChangeInfo,
  fetchEbayStockChangeInfo,
} from "../../../api/rest/ebay";
import BasicModal from "../../../components/generic/BasicModal";
import Loader from "../../../components/generic/Loader";
import Paging from "../../../components/generic/Paging";
import EbayStockChangeInfo from "../../../components/integrations/EbayStockChangeInfo";
import "./StockChangeInfoView.scss";

const ITEMS_PER_PAGE = 25;

function StockChangeInfoView() {
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState<string>("");
  const [successful, setSuccessful] = useState<string>("any");
  const [offset, setOffset] = useState(0);
  const [stockChanges, setStockChanges] = useState<StockChangeInfo[]>([]);
  const [activeId, setActiveId] = useState<number>();

  const loadResults = (idList: string, success: string, pageOffset: number) => {
    setLoading(true);
    const idListParam = idList !== "" ? idList : null;
    const successfulParam = success !== "any" ? success : null;
    fetchEbayStockChangeInfo(
      idListParam,
      successfulParam,
      null,
      pageOffset,
      ITEMS_PER_PAGE
    )
      .then((data: StockChangeInfo[]) => setStockChanges(data))
      .finally(() => setLoading(false));
  };

  const loadResultsDebounced = useRef(debounce(loadResults, 500));

  const handleIdChange = (idList: string) => {
    setIds(idList);
    setOffset(0);
    loadResultsDebounced.current(idList, successful, 0);
  };

  const handleSuccessfulChange = (state: string) => {
    setSuccessful(state);
    setOffset(0);
    loadResults(ids, state, 0);
  };

  const getNextPage = () => {
    const newOffset = offset + ITEMS_PER_PAGE;
    setOffset(newOffset);
    loadResults(ids, successful, newOffset);
  };

  const getPrevPage = () => {
    const newOffset = offset - ITEMS_PER_PAGE;
    if (newOffset < 0) return;
    setOffset(newOffset);
    loadResults(ids, successful, newOffset);
  };

  useEffect(() => loadResults(ids, successful, offset), []);

  console.log(stockChanges);

  return (
    <div className="stock-change-info-view">
      <h3 className="stock-change-info-view__title">Stock Change History</h3>
      <div className="stock-change-info-view__filters">
        <div className="stock-change-info-view__filter">
          <span className="stock-change-info-view__label">Filter by id</span>
          <input
            type="text"
            className="stock-change-info-view__input stock-change-info-view__input--text"
            value={ids}
            onChange={(event) => handleIdChange(event.target.value)}
          />
        </div>
        <div className="stock-change-info-view__filter">
          <span className="stock-change-info-view__label">
            Filter by status
          </span>
          <select
            className="stock-change-info-view__input stock-change-info-view__input--select"
            value={successful}
            onChange={(event) => handleSuccessfulChange(event.target.value)}
          >
            <option value="any">All</option>
            <option value="true">Success</option>
            <option value="false">Error</option>
          </select>
        </div>
      </div>
      <div className="stock-change-info-view__results">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Last updated</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {stockChanges.map((stockChange: StockChangeInfo) => (
                  <tr
                    className="stock-change-info-view__result"
                    key={stockChange.listingId}
                  >
                    <td>{stockChange.binderProductId}</td>
                    <td>{stockChange.name.replace(/(<([^>]+)>)/gi, "")}</td>
                    <td>
                      {TimeUtils.convertDateToHumanReadable(
                        stockChange.lastUpdated
                      )}
                    </td>
                    <td>{stockChange.success ? "Success" : "Error"}</td>
                    <td>
                      <button
                        className="btn1 stock-change-info-view__button"
                        onClick={() => setActiveId(stockChange.binderProductId)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paging
              pageOffset={offset}
              currentPageItemCount={stockChanges.length}
              maxItemsPerPage={ITEMS_PER_PAGE}
              getNextPage={getNextPage}
              getPrevPage={getPrevPage}
            />
          </>
        )}
      </div>
      {activeId ? (
        <BasicModal
          title="Stock Changes"
          onClose={() => setActiveId(undefined)}
        >
          <EbayStockChangeInfo
            {...stockChanges.find(
              (stockChange) => stockChange.binderProductId === activeId
            )}
          />
        </BasicModal>
      ) : null}
    </div>
  );
}

export default StockChangeInfoView;

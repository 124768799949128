import { useContext } from "react";
import { GlobalSettingsContext } from "../providers/GlobalSettingsProvider";

export const useFeature = (featureName: string) => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      "useFeature hook must be used within GlobalSettingsProvider"
    );
  }
  return context.features?.[featureName] === "true";
};

export const useLocalSetting = (settingName: string) => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      "useLocalSetting hook must be used within GlobalSettingsProvider"
    );
  }
  return context.getLocalSetting(settingName);
};

export const useLocalSettingUpdate = () => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      "useLocalSettingUpdater hook must be used within GlobalSettingsProvider"
    );
  }
  return context.updateLocalSetting;
};

import React, { createContext, useEffect, useState } from "react";
import { fetchAvailableIntegrations } from "../api/rest/integrations";

export interface IntegrationContextProps {
  availableIntegrations: IntegrationDetails[];
  ebayDefaultMarketplace: string | undefined;
  setEbayDefaultMarketplace: React.Dispatch<React.SetStateAction<string>>;
}

export const IntegrationContext = createContext<IntegrationContextProps>(null);

interface IntegrationContextProviderProps {
  children: React.ReactChild | React.ReactNodeArray;
}

function IntegrationProvider(props: IntegrationContextProviderProps) {
  const { children } = props;
  const [availableIntegrations, setAvailableIntegrations] = useState<
    IntegrationDetails[]
  >([]);
  const [ebayDefaultMarketplace, setEbayDefaultMarketplace] =
    useState<string>();

  useEffect(() => {
    fetchAvailableIntegrations().then(setAvailableIntegrations);
  }, []);

  return (
    <IntegrationContext.Provider
      value={{
        availableIntegrations,
        ebayDefaultMarketplace,
        setEbayDefaultMarketplace,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
}

export default IntegrationProvider;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import QuickItem from "./QuickItem";
import { action } from "mobx";
import { OperationalMode, operationalMode } from "../../constants/mode";

@inject("posStore")
@observer
class QuickLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  @action
  navUp = () => {
    this.props.posStore.quickLinkNav.pop();
  };

  @action
  addQuickItem = () => {
    var view = this.props.posStore.currentNav;
    view.push({
      color: "grey",
      icon: "fas fa- folder",
      type: "folder",
      value: {
        title: "New Folder",
        items: [],
      },
    });
    this.props.posStore.updateQuickLinks();
  };

  render() {
    const isKioskMode = operationalMode === OperationalMode.Kiosk;

    return (
      <React.Fragment>
        {this.props.posStore.quickLinkNav.length ? (
          <div className="upNav" onClick={this.navUp}>
            {this.props.posStore.currentNavName}
          </div>
        ) : null}
        <div className="quickLinks">
          {this.props.posStore.currentNav.length
            ? this.props.posStore.currentNav.map((item, index) => (
                <QuickItem
                  menu={item}
                  parent={this.props.menu}
                  key={index}
                  navIndex={index}
                />
              ))
            : null}
          {!isKioskMode ? (
            <div
              className={
                "quickItem addItem" +
                (this.props.posStore.cartLoading ? " disabled" : "")
              }
              onClick={this.addQuickItem}
            >
              <div className="dot">
                <i className={"fas fa-plus fa-3x"}></i>
              </div>
              <div className="itemDesc">
                <h1>Add Folder</h1>
              </div>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default QuickLinks;

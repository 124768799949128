import React, { useEffect, useState } from "react";
import {
  deleteReturn,
  EbayParam,
  EbayReturn,
  fetchFromEbayReturnSettings,
  fetchRefundMethods,
  fetchReturnSettings,
  fetchTimeDuraitons,
  updateReturn,
} from "../../../api/rest/ebay";
import Loader from "../../../components/generic/Loader";
import EbayDeleteSetting from "../../../components/integrations/EbayDeleteSetting";
import EbayErrorComponent from "../../../components/integrations/EbayErrorComponent";
import EbayReturnsForm from "../../../components/integrations/EbayReturnsForm";
import EbaySettingsList from "../../../components/integrations/EbaySettingsList";
import { useStoreDetails } from "../../../hooks/storeHooks";
import { parseEbayErrorMessage } from "../../../utils/integrations";

const wrapTitle = (children: React.ReactChild, errors: string[]) => (
  <>
    <h2>Return Settings</h2>
    <EbayErrorComponent errors={errors} />
    {children}
  </>
);

const shippingCostPayers = [
  { code: "SELLER", description: "Seller" },
  { code: "BUYER", description: "Buyer" },
];

function ReturnSettingsContainer() {
  const { customerId } = useStoreDetails();
  const [refundMethods, setRefundMethods] = useState<EbayParam[]>([]);
  const [timeDurations, setTimeDurations] = useState<EbayParam[]>([]);
  const [settings, setSettings] = useState<EbayReturn[]>([]);
  const [editSetting, setEditSetting] = useState<number | null>();
  const [deletingSetting, setDeletingSetting] = useState<number | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const loadSettings = () => {
    setIsLoading(true);
    fetchReturnSettings()
      .then((data: EbayReturn[]) => setSettings(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  const refreshSettings = () => {
    setIsLoading(true);
    fetchFromEbayReturnSettings()
      .then((data: EbayReturn[]) => setSettings(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  const saveSetting = (values: EbayReturn, setSubmitting: Function) => {
    if (values.id < 0) values.id = null;
    setSubmitting(true);
    if (!values.internationalOverride_returnsAccepted) {
      delete values.internationalOverride_returnPeriod;
      delete values.internationalOverride_returnShippingCostPayer;
    }
    updateReturn(values)
      .then(() => {
        loadSettings();
        setEditSetting(null);
      })
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => setSubmitting(false));
  };

  const deleteSetting = (settingId: number) => {
    setIsDeleting(true);
    deleteReturn(settingId)
      .then(() => loadSettings())
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)))
      .finally(() => {
        setIsDeleting(false);
        setDeletingSetting(null);
      });
  };

  const loadRefundMethods = () =>
    fetchRefundMethods()
      .then((data: EbayParam[]) => setRefundMethods(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  const loadTimeDurations = () =>
    fetchTimeDuraitons()
      .then((data: EbayParam[]) => setTimeDurations(data))
      .catch((error: Error) => setErrors(parseEbayErrorMessage(error)));

  useEffect(() => {
    loadRefundMethods();
    loadTimeDurations();
    if (customerId) {
      loadSettings();
    }
  }, [customerId]);

  useEffect(() => {
    setErrors([]);
  }, [editSetting, deletingSetting]);

  if (isLoading) {
    return wrapTitle(<Loader />, errors);
  }

  if (editSetting) {
    return wrapTitle(
      <EbayReturnsForm
        refundMethods={refundMethods}
        timeDurations={timeDurations}
        shippingCostPayers={shippingCostPayers}
        returnSetting={settings.find((setting) => setting.id === editSetting)}
        setEditSetting={setEditSetting}
        saveSetting={saveSetting}
      />,
      errors
    );
  }

  if (deletingSetting) {
    const location = settings.find((setting) => setting.id === deletingSetting);
    if (location) {
      const { id, name } = location;
      return wrapTitle(
        <EbayDeleteSetting
          id={id}
          name={name}
          isDeleting={isDeleting}
          handleCancel={() => setDeletingSetting(null)}
          handleDelete={(id: number) => deleteSetting(id)}
        />,
        errors
      );
    }
  }

  return wrapTitle(
    <EbaySettingsList
      settings={settings}
      deletingSetting={deletingSetting}
      refreshSettings={refreshSettings}
      setEditSetting={setEditSetting}
      setDeletingSetting={setDeletingSetting}
    />,
    errors
  );
}

export default ReturnSettingsContainer;

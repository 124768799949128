import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class EventsFilter extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  @observable showFilters = false;
  @action setShowFilters = (show) => {
    this.showFilters = show;
  };

  @observable era = "all";
  @action setEra = (value) => {
    this.era = value;
  };

  componentDidMount() {
    const { EventsStore, MainStore } = this.props.store;
    EventsStore.getEventGames();
    EventsStore.getEventTypes();
    MainStore.getCurrency();
  }

  onEraChange = (event) => {
    const value = event.target.value;
    this.setEra(value);
    const now = new Date().toISOString().substring(0, 10);
    switch (value) {
      case "past":
        this.props.updateEventFilter("startDate", null);
        this.props.updateEventFilter("endDate", now);
        break;
      case "upcoming":
        this.props.updateEventFilter("startDate", now);
        this.props.updateEventFilter("endDate", null);
        break;
      case "all":
      default:
        this.props.updateEventFilter("startDate", null);
        this.props.updateEventFilter("endDate", null);
    }
    this.props.updateEventFilter("offset", 0);
    this.props.loadEvents();
  };

  updateFilter = (parameter, value) => {
    if (value === "") {
      value = null;
    }
    this.props.updateEventFilter("offset", 0);
    this.props.updateEventFilter(parameter, value);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(this.props.loadEvents, 500);
  };

  resetFilters = () => {
    this.props.resetEventFilters();
    this.setEra("all");
    this.props.loadEvents();
  };

  render() {
    const { EventsStore, MainStore } = this.props.store;
    const { eventFilters } = this.props;
    return (
      <div className="events-filter">
        <form>
          <div className="always-visible">
            <div className="radio-group form-group">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="upcomingFilter"
                  id="allEvents"
                  value="all"
                  checked={this.era === "all"}
                  onChange={this.onEraChange}
                />
                <label className="form-check-label" htmlFor="allEvents">
                  All Events
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="upcomingFilter"
                  id="upcomingEvents"
                  value="upcoming"
                  checked={this.era === "upcoming"}
                  onChange={this.onEraChange}
                />
                <label className="form-check-label" htmlFor="upcomingEvents">
                  Upcoming Events
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  name="upcomingFilter"
                  id="pastEvents"
                  value="past"
                  checked={this.era === "past"}
                  onChange={this.onEraChange}
                />
                <label className="form-check-label" htmlFor="pastEvents">
                  Past Events
                </label>
              </div>
            </div>
            <button
              className="btn1"
              type="button"
              onClick={() => this.setShowFilters(!this.showFilters)}
            >
              {this.showFilters ? (
                <span>
                  Hide Filters <i className="fas fa-caret-up" />
                </span>
              ) : (
                <span>
                  More Filters <i className="fas fa-caret-down" />
                </span>
              )}
            </button>
          </div>
          <div
            className={`togglable-filters ${this.showFilters ? "visible" : ""}`}
          >
            <button
              type="button"
              className="reset-button float-md-right"
              onClick={this.resetFilters}
            >
              <i className="fas fa-sync" /> Reset Filters
            </button>
            <div className="form-group">
              <label htmlFor="title">Event Title</label>
              <input
                type="text"
                onChange={(event) =>
                  this.updateFilter("title", event.target.value)
                }
                value={eventFilters.title || ""}
                id="title"
              />
              <label htmlFor="start-date">Between dates</label>
              <span>
                <input
                  className="start-date"
                  type="date"
                  value={eventFilters.startDate || ""}
                  onChange={(event) =>
                    this.updateFilter("startDate", event.target.value)
                  }
                  id="start-date"
                />
                <label
                  htmlFor="end-date"
                  style={{ marginLeft: "4px", marginRight: "4px" }}
                >
                  and
                </label>
                <input
                  id="end-date"
                  className="end-date"
                  data-testid="end-date"
                  type="date"
                  value={eventFilters.endDate || ""}
                  onChange={(event) =>
                    this.updateFilter("endDate", event.target.value)
                  }
                />
              </span>
              <label htmlFor="games">Game</label>
              <select
                onChange={(event) =>
                  this.updateFilter("gameType", event.target.value)
                }
                value={eventFilters.gameType || ""}
                id="games"
              >
                <option value="">- All -</option>
                {EventsStore.eventFilterGames.map((game, index) => (
                  <option key={index} value={game}>
                    {game}
                  </option>
                ))}
              </select>
              <label htmlFor="types">Event type</label>
              <select
                onChange={(event) =>
                  this.updateFilter("eventType", event.target.value)
                }
                value={eventFilters.eventType || ""}
                id="types"
              >
                <option value="">- All -</option>
                {EventsStore.eventFilterTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <label className="form-check-label" htmlFor="ticketed">
                Ticketed
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(event) =>
                    this.updateFilter("ticketed", event.target.checked)
                  }
                  value={eventFilters.ticketed || false}
                  id="ticketed"
                />
              </div>
              <label htmlFor="low-price">Between prices</label>
              <span>
                {MainStore.currency || "$"}
                <input
                  id="low-price"
                  type="number"
                  min={0}
                  disabled={!eventFilters.ticketed}
                  value={eventFilters.lowPrice || ""}
                  onChange={(event) =>
                    this.updateFilter("lowPrice", event.target.value)
                  }
                />
                <label
                  style={{ marginLeft: "4px", marginRight: "4px" }}
                  htmlFor="high-price"
                >
                  and
                </label>
                <span>
                  {MainStore.currency || "$"}
                  <input
                    data-testid="high-price"
                    id="high-price"
                    type="number"
                    min={0}
                    disabled={!eventFilters.ticketed}
                    value={eventFilters.highPrice || ""}
                    onChange={(event) =>
                      this.updateFilter("highPrice", event.target.value)
                    }
                  />
                </span>
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

EventsFilter.propTypes = {
  eventFilters: PropTypes.object,
  resetEventFilters: PropTypes.func.isRequired,
  updateEventFilter: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired,
};

export default EventsFilter;

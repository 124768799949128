import React from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Loader, QueueLine } from "../../components";
import { Link } from "react-router-dom";

@inject("store")
@observer
class JobQueueList extends React.Component {
  componentDidMount() {
    this.props.store.MainStore.getCurrency();
    this.props.store.ProductsStore.setQueuedJobs(null);
    document.title = "Queued jobs | BinderPOS";
    this.props.store.MainStore.getCurrency();
    this.props.store.ProductsStore.fetchQueuedJobs("bulkUpdate", 10, 0).then(
      (results) => {
        this.props.store.ProductsStore.setQueuedJobs(results);
        this.canProgress();
        this.canGoBack();
      }
    );
  }

  @observable listOfQueues = [
    { title: "Bulk queue", type: "bulkUpdate", offset: 0, limit: 10 },
    {
      title: "Batch query queue",
      type: "batchQueryUpdate",
      offset: 0,
      limit: 15,
    },
    { title: "Price queue", type: "priceUpdate", offset: 0, limit: 25 },
    { title: "Stock queue", type: "stockUpdate", offset: 0, limit: 25 },
    { title: "Other queue", type: "variantUpdate", offset: 0, limit: 100 },
  ];

  @observable selectedTab = "bulkUpdate";

  @action setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  @observable canGoPrev = false;

  @action setCanGoPrev(bool) {
    this.canGoPrev = bool;
  }

  @observable canGoNext = false;

  @action setCanGoNext(bool) {
    this.canGoNext = bool;
  }

  updateSelectedType(type) {
    this.setSelectedTab(type);
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach((item) => {
      if (item.type == type) {
        this.props.store.ProductsStore.fetchQueuedJobs(
          item.type,
          item.limit,
          item.offset
        ).then((results) => {
          this.props.store.ProductsStore.setQueuedJobs(results);
          this.canProgress();
          this.canGoBack();
        });
      }
    });
  }

  canProgress() {
    this.setCanGoNext(false);
    this.listOfQueues.forEach((item) => {
      if (item.type == this.selectedTab) {
        if (this.props.store.ProductsStore.queuedJobs.length >= item.limit) {
          this.setCanGoNext(true);
        }
      }
    });
  }

  canGoBack() {
    this.setCanGoPrev(false);
    this.listOfQueues.forEach((item) => {
      if (item.type == this.selectedTab) {
        if (item.offset > 0) {
          console.log({ itemOffset: item.offset });
          this.setCanGoPrev(true);
        }
      }
    });
  }

  @action
  getNextProducts() {
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach((item) => {
      if (item.type == this.selectedTab) {
        const theNewOffset = item.offset + item.limit;
        this.props.store.ProductsStore.fetchQueuedJobs(
          item.type,
          item.limit,
          theNewOffset
        )
          .then((result) => {
            item.offset = theNewOffset;
            this.props.store.ProductsStore.setQueuedJobs(result);
            this.canProgress();
            this.canGoBack();
          })
          .catch((err) => {
            this.props.store.MainStore.setError(
              err,
              "Failed to load products",
              "There was an error retrieving your next set of products. Please try again"
            );
          });
      }
    });
  }

  @action
  getPrevProducts() {
    this.props.store.ProductsStore.setQueuedJobs(null);
    this.listOfQueues.forEach((item) => {
      if (item.type == this.selectedTab) {
        let theNewOffset = item.offset - item.limit;
        if (theNewOffset < 0) {
          theNewOffset = 0;
        }
        this.props.store.ProductsStore.fetchQueuedJobs(
          item.type,
          item.limit,
          theNewOffset
        )
          .then((result) => {
            item.offset = theNewOffset;
            this.props.store.ProductsStore.setQueuedJobs(result);
            this.canProgress();
            this.canGoBack();
          })
          .catch((err) => {
            this.props.store.MainStore.setError(
              err,
              "Failed to load products",
              "There was an error retrieving your previous set of products. Please try again"
            );
          });
      }
    });
  }

  render() {
    return (
      <>
        {this.props.store.AuthStore.screenSettings.inventoryManagement ? (
          <>
            <div className="header">
              <div className="top-pagination">
                <div className="paging-nav">
                  <button
                    className="button"
                    disabled={!this.canGoPrev}
                    onClick={() => this.getPrevProducts()}
                  >
                    <i className="fas fa-caret-left" /> Prev
                  </button>
                  <button
                    className="button"
                    disabled={!this.canGoNext}
                    onClick={() => this.getNextProducts()}
                  >
                    Next <i className="fas fa-caret-right" />
                  </button>
                </div>
              </div>
              <h2 className="title is-2">
                <span className="header-text">
                  My Products {">"} My queued jobs
                </span>
                <Link to="/products">
                  <button className="btn1 jobQueue">
                    <i className="fas fa-caret-left" /> Back to My Products
                  </button>
                </Link>
              </h2>
            </div>
            <div className="queueFilterTabs">
              {this.listOfQueues.map((item, index) => (
                <span
                  key={index}
                  className={
                    !this.selectedTab || this.selectedTab == item.type
                      ? "active"
                      : ""
                  }
                  onClick={() => this.updateSelectedType(item.type)}
                >
                  {item.title}{" "}
                  {!this.selectedTab || this.selectedTab == item.type ? (
                    <i className="fas fa-caret-up" />
                  ) : (
                    <i className="fas fa-caret-down" />
                  )}
                </span>
              ))}
            </div>
            {this.props.store.ProductsStore.queuedJobs ? (
              <div className="queueResults">
                {this.props.store.ProductsStore.queuedJobs.map(
                  (queuedJob, index) => (
                    <QueueLine key={index} jobQueueItem={queuedJob} />
                  )
                )}
              </div>
            ) : (
              <Loader text="Loading products..." />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}
JobQueueList.propTypes = { store: PropTypes.object };
export default JobQueueList;

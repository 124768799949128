import { observable, action } from "mobx";
import {
  fetchEventInfo,
  fetchEvents,
  fetchFailedToCreateEvents,
  fetchFailedToDeleteEvents,
  fetchEventGamesForClient,
  fetchEventTypesForClient,
} from "../api/rest/events";

class EventsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable event;

  @observable events;

  @observable eventWithChildern;

  @observable eventGames;

  @observable eventTypes;

  @observable eventCategories;

  @observable failedEvents;

  @observable failedToCreateEvents;

  @observable failedToDeleteEvents;

  @observable eventFilterGames = [];

  @observable eventFilterTypes = [];

  @action setEvents = (events) => {
    this.events = events;
  };

  // TODO:: Correct "childern" to "children"
  @action setEventWithChildern = (eventWithChildern) => {
    this.eventWithChildern = eventWithChildern;
  };

  @action setEventCategories = (eventCategories) => {
    if (eventCategories) {
      this.eventGames = eventCategories.filter(
        (category) => category.categoryType == "game"
      );
      this.eventTypes = eventCategories.filter(
        (category) => category.categoryType == "eventType"
      );
    }
    this.eventCategories = eventCategories;
  };

  @action setFailedToCreateEvents = (failedToCreateEvents) => {
    this.failedToCreateEvents = failedToCreateEvents;
  };

  @action setFailedToDeleteEvents = (failedToDeleteEvents) => {
    this.failedToDeleteEvents = failedToDeleteEvents;
  };

  @action setFailedEvents = (failedEvents) => {
    this.failedEvents = failedEvents;
  };

  @action setEvent = (event) => {
    this.event = event;
  };

  @action setEventFilterGames = (games) => {
    this.eventFilterGames = games;
  };

  @action setEventFilterTypes = (types) => {
    this.eventFilterTypes = types;
  };

  rehydrateChildEvents = (eventId) => {
    this.setEventWithChildern(null);
    return fetchEventInfo(eventId)
      .then((result) => {
        this.setEventWithChildern(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load child events",
          "There was an error retrieving the event details. Please refresh and try again"
        );
      });
  };

  rehydrate = () => {
    this.setEvents(null);
    return fetchEvents()
      .then((result) => {
        this.setEvents(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load events",
          "There was an error retrieving your events. Please refresh and try again"
        );
      });
  };

  rehydrateFailedToCreate = (offset, limit) => {
    this.setFailedToCreateEvents(null);
    return fetchFailedToCreateEvents(offset, limit)
      .then((result) => {
        this.setFailedToCreateEvents(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load events",
          "There was an error retrieving the events that had failed to be created. Please refresh and try again"
        );
      });
  };

  rehydrateFailedToDelete = (offset, limit) => {
    this.setFailedToDeleteEvents(null);
    return fetchFailedToDeleteEvents(offset, limit)
      .then((result) => {
        this.setFailedToDeleteEvents(result);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load events",
          "There was an error retrieving the events that had failed to be deleted. Please refresh and try again"
        );
      });
  };

  getEventGames = () =>
    fetchEventGamesForClient()
      .then((data) => {
        this.setEventFilterGames(data);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load event options",
          "There was an error retrieving the list of supported games. Please refresh and try again"
        );
      });

  getEventTypes = () =>
    fetchEventTypesForClient()
      .then((data) => {
        this.setEventFilterTypes(data);
      })
      .catch((error) => {
        this.rootStore.MainStore.setError(
          error,
          "Failed to load event options",
          "There was an error retrieving the list of event types. Please refresh and try again"
        );
      });
}

export default EventsStore;

export const isTaxRateValid = (taxRate: number | string | undefined | null) =>
  taxRate !== null;

export function disableFinalizeSale(
  balance: string,
  changeDue: string,
  tenders: any
) {
  const parsedBalance = parseFloat(balance);
  const parsedChangeDue = changeDue == "" ? 0 : parseFloat(changeDue);
  if (!hasAtLeastOneTender(tenders) && parsedBalance !== 0) {
    return true;
  }
  if (parsedBalance + parsedChangeDue !== 0) {
    return true;
  }
  return false;
}

export function hasAtLeastOneTender(tenders: any) {
  return tenders.some(
    (tender) => tender.amount !== 0 && tender.amount !== "0.00"
  );
}

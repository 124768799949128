import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import "./EbayCategoryListItem.scss";

type EbayCategoryListItemType = {
  [key: string]: {
    name: string;
    children: EbayCategoryListItemType;
  };
};

interface EbayCategoryListItemProps {
  id: string;
  name: string;
  children: EbayCategoryListItemType;
}

function EbayCategoryListItem(props: EbayCategoryListItemProps) {
  const { id, name, children = [] } = props;

  const {
    // @ts-ignore
    values: { categoryId },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (categoryId == id) {
      setFieldValue("categoryName", name);
    }
  }, [categoryId, setFieldValue]);

  return (
    <ul className="ebay-category-list-item">
      <li className="ebay-category-list-item__category">
        <label className="ebay-category-list-item__label">
          {!children || children.length === 0 ? (
            <Field
              type="radio"
              name="categoryId"
              value={`${id}`}
              className="ebay-category-list-item__selector"
            />
          ) : null}
          {name}
        </label>
      </li>
      {Object.entries(children).map(([childId, values]) => (
        <EbayCategoryListItem key={childId} id={Number(childId)} {...values} />
      ))}
    </ul>
  );
}

export default EbayCategoryListItem;

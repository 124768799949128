import { _fetch, _fetchWithFormData } from "..";
import { BASE_URL, API_V2_BASE_URL } from "../../constants/api";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";

export const fetchPotentialExistingMainEvents = (
  eventId: number
): Promise<Event[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}/potentialSubEvents`,
  });

export const fetchPotentialNewMainEvents = (): Promise<Event[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/potentialSubEvents`,
  });

export const fetchEventCategories = (): Promise<EventCategory[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/categories`,
  });

type FetchEventParams = {
  limit?: number;
  offset?: number;
};

export const fetchEvents = (
  params: FetchEventParams = {}
): Promise<Event[]> => {
  // Set some defaults for pagination
  if (!params?.limit) {
    params.limit = DEFAULT_EVENTS_PER_PAGE;
  }
  if (!params?.offset) {
    params.offset = 0;
  }
  const queryString = Object.entries(params)
    .map(
      ([param, value]) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events?${queryString}`,
  });
};

export const fetchEventInfo = (eventId: number): Promise<Event> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}`,
  });

export const fetchFailedToCreateEvents = (
  offset: number,
  limit: number
): Promise<Event[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/failedToCreate?offset=${offset}&limit=${limit}`,
  });

export const repushFailedEvent = (id: number): Promise<[]> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/repush`,
  });

export const removeFailedEventFromList = (id: number): Promise<[]> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/failedToCreate/remove`,
  });

export const fetchFailedToDeleteEvents = (
  offset: number,
  limit: number
): Promise<Event[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/failedToDelete?offset=${offset}&limit=${limit}`,
  });

export const removeFailedEventDeleteFromList = (id: number): Promise<[]> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/failedToDelete/remove`,
  });

export const retryFailedToDelete = (id: number): Promise<[]> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/events/${id}/retry/failedToDelete`,
  });

export const addEvent = (event: Event): Promise<Event> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/add`,
    payload: event,
  });

export const doEnableEvent = (eventId: number): Promise<[]> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/${eventId}/enable`,
  });

export const disableEvent = (eventId: number): Promise<[]> =>
  _fetchWithFormData({
    method: "POST",
    endpoint: `${BASE_URL}/events/${eventId}/disable`,
  });

export const updateEvent = (id: number, event: Event): Promise<Event> =>
  _fetchWithFormData({
    method: "PUT",
    endpoint: `${BASE_URL}/events/${id}/update`,
    payload: event,
  });

export const doDeleteEvent = (eventId: number): Promise<[]> =>
  _fetch({
    method: "DELETE",
    endpoint: `${BASE_URL}/events/${eventId}`,
  });

export const fetchEventGamesForClient = (): Promise<string[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/games`,
  });

export const fetchEventTypesForClient = (): Promise<string[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/types`,
  });

export const fetchEventAdditionalInfo = (
  eventId: number
): Promise<EventAdditionalInfo[]> =>
  _fetch({
    method: "GET",
    endpoint: `${BASE_URL}/events/${eventId}/additionalInfo`,
  });

export const fetchEventParticipants = (
  eventId: number | string
): Promise<EventParticipant[]> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant`,
  });

export const fetchEventParticipant = (
  eventId: number | string,
  participantId: number | string
): Promise<EventParticipant> =>
  _fetch({
    method: "GET",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
  });

export const addEventParticipant = (
  eventId: number | string,
  participant: EventParticipant
): Promise<EventParticipant> =>
  _fetch({
    method: "POST",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant`,
    payload: participant,
  });

export const removeEventParticipant = (
  eventId: number | string,
  participantId: number | string
): Promise<[]> =>
  _fetch({
    method: "DELETE",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
  });

export const updateEventParticipant = (
  eventId: number | string,
  participantId: number | string,
  participant: EventParticipant
): Promise<EventParticipant> =>
  _fetch({
    method: "PUT",
    endpoint: `${API_V2_BASE_URL}/event/${eventId}/participant/${participantId}`,
    payload: participant,
  });

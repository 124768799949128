import React from "react";

interface ErrorModalBasicProps {
  error: DetailedError;
  onClose: () => void;
}

function ErrorModalBasic(props: ErrorModalBasicProps) {
  const { error, onClose } = props;
  const hasValidMessage = error?.error !== undefined;
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {hasValidMessage ? error.error : "Unknown error"}
          </p>
          <button
            title="Dismiss error"
            className="delete"
            aria-label="close"
            onClick={() => onClose()}
          />
        </header>
        <section className="modal-card-body">
          {hasValidMessage ? (
            <div className="field">{error.detailedMessage}</div>
          ) : (
            <div className="field">
              An unknown error has occurred, please go back and try again. If
              the problem persists, contact BinderPOS support.
            </div>
          )}
          <br />
        </section>
        <footer className="modal-card-foot">
          <button
            title="Close error"
            className="modalBtn action full"
            onClick={() => onClose()}
          >
            OK
          </button>
        </footer>
      </div>
    </div>
  );
}

export default ErrorModalBasic;

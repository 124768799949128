import React, { Fragment, Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { API_STATE } from "../../constants/api";
import { getBuylistReport } from "../../api/graphql/reports";
import { reformatBuylistReport } from "../../utils/reports";
import { ReportsMenuStructure } from "../../menuStructures";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import {
  Pagination,
  ReportControls,
  TableSpinner,
} from "../../components/reports";
import {
  CurrencyRenderer,
  TextInputFilter,
} from "../../components/reports/CellTypes";
import "react-base-table/styles.css";
import PropTypes from "prop-types";
import TimeUtils from "../../utils/TimeUtils";

function PaymentTypeSelect({ value, setValue }) {
  return (
    <select onChange={(event) => setValue(event.target.value)} value={value}>
      <option value="">All</option>
      <option value="Cash">Cash</option>
      <option value="Credit">Store Credit</option>
    </select>
  );
}

PaymentTypeSelect.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

function StatusSelect({ value, setValue }) {
  return (
    <select onChange={(event) => setValue(event.target.value)} value={value}>
      <option value="">All</option>
      <option value="Pending">Pending</option>
      <option value="Approved">Approved</option>
      <option value="Completed">Completed</option>
      <option value="Declined">Declined</option>
    </select>
  );
}

StatusSelect.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

@inject("store")
@observer
class Buylist extends Component {
  componentDidMount() {
    document.title = "Reports | BinderPOS";
    const { MainStore, MenuStore, ReportsStore } = this.props.store;
    ReportsStore.reset();
    MainStore.getCurrencyCode();
    MenuStore.setSideMenuToDisplay(ReportsMenuStructure);
    ReportsStore.setFilters(this.filters);
  }

  loadData = (fromDate, toDate) => {
    this.props.store.ReportsStore.setApiState(API_STATE.LOADING);
    getBuylistReport(fromDate, toDate)
      .then((data) => {
        const reportData = reformatBuylistReport(data);
        this.props.store.ReportsStore.setSourceData(reportData);
        this.props.store.ReportsStore.setApiState(API_STATE.SUCCESS);
      })
      .catch((error) => {
        this.props.store.ReportsStore.setApiState(API_STATE.ERROR);
        this.props.store.MainStore.setError(
          error,
          "Failed to load buylist report",
          "There was an error retrieving your buylist report. Please refresh and try again"
        );
      });
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } = this.props.store.ReportsStore;
    const {
      buylistId = "",
      customerName = "",
      customerEmail = "",
      paymentType = "",
      status = "",
    } = filterValues;
    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow, flexShrink } = column;
          return (
            <div
              className="filters"
              style={{
                width: width,
                flexGrow: flexGrow,
                flexShrink: flexShrink,
              }}
              key={`header_${key}`}
            >
              {key === "col_buylistId" && (
                <TextInputFilter
                  value={buylistId}
                  setValue={(value) => updateFilterValue("buylistId", value)}
                />
              )}
              {key === "col_customerName" && (
                <TextInputFilter
                  value={customerName}
                  setValue={(value) => updateFilterValue("customerName", value)}
                />
              )}
              {key === "col_customerEmail" && (
                <TextInputFilter
                  value={customerEmail}
                  setValue={(value) =>
                    updateFilterValue("customerEmail", value)
                  }
                />
              )}
              {key === "col_paymentType" && (
                <PaymentTypeSelect
                  value={paymentType}
                  setValue={(value) => updateFilterValue("paymentType", value)}
                />
              )}
              {key === "col_status" && (
                <StatusSelect
                  value={status}
                  setValue={(value) => updateFilterValue("status", value)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "buylist Id",
      condition: (filterValues) =>
        filterValues.buylistId && filterValues.buylistId.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.buylistId &&
        String(buylistDetail.buylistId).includes(filterValues.buylistId),
    },
    {
      name: "customer name",
      condition: (filterValues) =>
        filterValues.customerName && filterValues.customerName.length,
      filterFunction: (buylistDetail, filterValues) =>
        (buylistDetail.firstName &&
          buylistDetail.firstName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())) ||
        (buylistDetail.lastName &&
          buylistDetail.lastName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())),
    },
    {
      name: "customer email",
      condition: (filterValues) =>
        filterValues.customerEmail && filterValues.customerEmail.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.email &&
        buylistDetail.email
          .toLowerCase()
          .includes(filterValues.customerEmail.toLowerCase()),
    },
    {
      name: "payment type",
      condition: (filterValues) =>
        filterValues.paymentType && filterValues.paymentType.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.paymentType === filterValues.paymentType,
    },
    {
      name: "status",
      condition: (filterValues) =>
        filterValues.status && filterValues.status.length,
      filterFunction: (buylistDetail, filterValues) =>
        buylistDetail.status === filterValues.status,
    },
  ];

  render() {
    const rowPerPageOptions = [10, 20, 25, 50, 100];

    const csvHeaders = [
      { name: "Buylist Id", key: "buylistId" },
      { name: "Customer First Name", key: "firstName" },
      { name: "Customer Last Name", key: "lastName" },
      { name: "Customer Email", key: "email" },
      { name: "Payment Type", key: "paymentType" },
      { name: "Total Items", key: "totalItems" },
      {
        name: "Total Buy Price",
        key: "totalBuyPrice",
        formatter: (number) => number && number.toFixed(2),
      },
      { name: "Status", key: "status" },
      {
        name: "Date Submitted",
        key: "dateSubmitted",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
      {
        name: "Date Completed",
        key: "dateCompleted",
        formatter: (date) => TimeUtils.convertDateToHumanReadable(date),
      },
    ];

    const csvFilename = "Online Buylist Reports.csv";

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const { MainStore, ReportsStore } = this.props.store;
    const {
      apiState,
      currentPage,
      rowsPerPage,
      sortColumn,
      tableData,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
      overrideTableHeight,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;

    return (
      <Fragment>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Online Buylist Reports</span>
          </h2>
        </div>
        <div className="report">
          <ReportControls
            setDateRange={this.loadData}
            csvHeaders={csvHeaders}
            csvFilename={csvFilename}
          />
          {apiState !== API_STATE.INITIAL && (
            <div className="data-table">
              <div className="table-container">
                <AutoResizer>
                  {({ width, height }) => (
                    <BaseTable
                      data={data}
                      width={width}
                      height={overrideTableHeight || height}
                      sortBy={sortColumn}
                      onColumnSort={setSortColumn}
                      headerHeight={[40, 40]}
                      headerRenderer={this.headerRenderer}
                      overlayRenderer={() => (
                        <TableSpinner
                          isLoading={apiState === API_STATE.LOADING}
                        />
                      )}
                      emptyRenderer={() => <div>No data to display</div>}
                      rowKey="buylistId"
                    >
                      <Column
                        key="col_buylistId"
                        dataKey="buylistId"
                        className="buylist-report-link"
                        width={100}
                        flexGrow={1}
                        title="Buylist #"
                        sortable
                        resizable
                        cellRenderer={({ cellData }) => (
                          <Link to={`/buylists/pending/moreDetail/${cellData}`}>
                            {cellData}
                          </Link>
                        )}
                      />
                      <Column
                        key="col_customerName"
                        dataKey="customerName"
                        dataGetter={({ rowData }) =>
                          [rowData.firstName, rowData.lastName].join(" ")
                        }
                        align="left"
                        title="Customer Name"
                        width={0}
                        flexGrow={3}
                        sortable
                        resizable
                      />
                      <Column
                        key="col_customerEmail"
                        dataKey="email"
                        align="left"
                        title="Customer Email"
                        width={0}
                        flexGrow={3}
                        sortable
                        resizable
                      />
                      <Column
                        key="col_paymentType"
                        dataKey="paymentType"
                        width={100}
                        title="Payment Type"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_totalItems"
                        dataKey="totalItems"
                        width={100}
                        title="Total Items"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_totalBuyPrice"
                        dataKey="totalBuyPrice"
                        width={120}
                        align="right"
                        currency={MainStore.currencyCode}
                        cellRenderer={CurrencyRenderer}
                        title="Total Buy Price"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_status"
                        dataKey="status"
                        width={100}
                        title="Status"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_dateSubmitted"
                        dataKey="dateSubmitted"
                        dataGetter={({ rowData }) =>
                          rowData.dateSubmitted
                            ? TimeUtils.convertDateToHumanReadable(
                                Number(rowData.dateSubmitted)
                              )
                            : ""
                        }
                        width={150}
                        title="Date Submitted"
                        sortable
                        resizable
                      />
                      <Column
                        key="col_dateCompleted"
                        dataKey="dateCompleted"
                        dataGetter={({ rowData }) =>
                          rowData.dateCompleted
                            ? TimeUtils.convertDateToHumanReadable(
                                Number(rowData.dateCompleted)
                              )
                            : ""
                        }
                        width={150}
                        title="Date Completed"
                        sortable
                        resizable
                      />
                    </BaseTable>
                  )}
                </AutoResizer>
              </div>
              <Pagination
                apiState={apiState}
                currentPage={currentPage}
                totalRowCount={tableData.length}
                rowsPerPage={rowsPerPage}
                rowPerPageOptions={rowPerPageOptions}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

Buylist.propTypes = { store: PropTypes.object };
export default Buylist;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { ItemList } from "./js/pos/ItemStore";
import "prevent-pull-refresh";
import Store from "./js/store";
import App from "./js/app";

const posStore = new ItemList();

ReactDOM.render(
  <Provider store={Store} posStore={posStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);

window.posStore = posStore;

import React, { Component, Fragment } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { BuylistMenuStructure } from "../../menuStructures";
import { Loader } from "../../components";
import {
  fetchBuylistRules,
  doDeleteBuylistRule,
  doDeleteBuylistRules,
} from "../../api/rest/buylist";

@inject("store")
@observer
export default class BuylistRuleList extends Component {
  @observable selectedBuylistRules = [];

  @action setSelectedBuylistRules(selectedBuylistRules) {
    this.selectedBuylistRules = selectedBuylistRules;
  }

  @observable buylistRule;

  @action setBuylistRule(buylistRule) {
    this.buylistRule = buylistRule;
  }

  @observable modalVisible = false;

  @action setModalVisible(bool) {
    this.modalVisible = bool;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteSelectedModalVisible = false;

  @action setDeleteSelectedModalVisible(bool) {
    this.deleteSelectedModalVisible = bool;
  }

  @observable deleteModalBuylistRule;

  @action setDeleteModalBuylistRule(buylistRule) {
    this.deleteModalBuylistRule = buylistRule;
  }

  @observable deleteModalLoading;

  @action setDeleteModalLoading(loading) {
    this.deleteModalLoading = loading;
  }

  @observable deleteSelectedModalLoading;

  @action setDeleteSelectedModalLoading(loading) {
    this.deleteSelectedModalLoading = loading;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Buylist Rules | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    fetchBuylistRules()
      .then((result) => {
        this.props.store.BuylistRulesStore.setBuylistRules(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load buylist rules",
          "There was an error retrieving your store's buylist rules. Please refresh and try again"
        );
      });
  }

  setBuylistRuleInStore(buylistRule) {
    this.props.store.BuylistRulesStore.setBuylistRule(buylistRule);
  }

  @action
  pushToBuylistRulesList(id) {
    const newList = this.selectedBuylistRules.filter((item) => item !== id);
    newList.push(id);
    this.setSelectedBuylistRules(newList);
  }

  @action
  removeFromBuylistRulesList(id) {
    const newList = this.selectedBuylistRules.filter((item) => item !== id);
    this.setSelectedBuylistRules(newList);
  }

  handleOnChangeForCheckbox(index, buylistId) {
    const checkbox = document.querySelector(`#additionalInfoRequired${index}`);
    if (checkbox.checked) {
      this.pushToBuylistRulesList(buylistId);
    } else {
      this.removeFromBuylistRulesList(buylistId);
    }
  }

  showDeleteModalForSelectedBuylistRules() {
    this.setDeleteSelectedModalVisible(true);
  }

  deleteSelectedBuylistRules() {
    this.setDeleteSelectedModalLoading(true);
    const buylistsRulesToDelete = {
      buylistIds: this.selectedBuylistRules,
    };
    doDeleteBuylistRules(buylistsRulesToDelete)
      .then(() => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.setSelectedBuylistRules([]);
        this.props.store.BuylistRulesStore.rehydrate();
      })
      .catch((error) => {
        this.setDeleteSelectedModalVisible(false);
        this.setDeleteSelectedModalLoading(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete buylist rules",
          "There was an error deleting your selected rules. Please try again"
        );
      });
  }

  showViewingModal(buylistRule) {
    this.setBuylistRule(buylistRule);
    this.setModalVisible(true);
  }

  deleteBuylistRule(buylistRuleId) {
    this.setDeleteModalLoading(true);
    doDeleteBuylistRule(buylistRuleId)
      .then(() => {
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.BuylistRulesStore.rehydrate();
      })
      .catch((error) => {
        console.error(error);
        this.setDeleteModalVisible(false);
        this.setDeleteModalBuylistRule(null);
        this.setDeleteModalLoading(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete buylist rule",
          "There was an error deleting your buylist rule. Please try again"
        );
      });
  }

  showDeleteModalFor(buylistRule) {
    this.setDeleteModalVisible(true);
    this.setDeleteModalBuylistRule(buylistRule);
  }

  generateBuylistRuleDescription(buylistRule) {
    let ruleWording = "Equal to";
    if (buylistRule.priceRule == "lessThanOrEqualTo") {
      ruleWording = "Less than or Equal to";
    } else if (buylistRule.priceRule == "lessThan") {
      ruleWording = "Less than";
    } else if (buylistRule.priceRule == "greaterThanOrEqualTo") {
      ruleWording = "Greater than or Equal to";
    } else if (buylistRule.priceRule == "greaterThan") {
      ruleWording = "Greater than";
    }
    if (buylistRule.game && buylistRule.game != "all") {
      if (buylistRule.cardName) {
        if (buylistRule.price && buylistRule.price > 0) {
          return `Applied to card '${buylistRule.cardName}' for '${buylistRule.gameName}' with a price ${ruleWording} $${buylistRule.price}`;
        }
        return `Applied to card '${buylistRule.cardName}' for '${buylistRule.gameName}'`;
      }
      if (buylistRule.setName) {
        if (buylistRule.price && buylistRule.price > 0) {
          return `Applied across cards within '${buylistRule.setName}' for '${buylistRule.gameName}' with a price ${ruleWording} $${buylistRule.price}`;
        }
        return `Applied across cards within '${buylistRule.setName}' for '${buylistRule.gameName}'`;
      }
      if (buylistRule.price && buylistRule.price > 0) {
        return `Applied to all '${buylistRule.gameName}' cards with a price ${ruleWording} $${buylistRule.price}`;
      }
      return `Applied to all '${buylistRule.gameName}' cards`;
    }
    if (buylistRule.price && buylistRule.price > 0) {
      return `Applied across all cards with a price ${ruleWording} $${buylistRule.price}`;
    }
    return "Applied across all cards";
  }

  render() {
    return (
      <>
        {this.props.store.AuthStore.screenSettings.buylist ? (
          <>
            {this.props.store.BuylistRulesStore.buylistRules ? (
              <>
                <div className="app-header">
                  <h2 className="title is-2">
                    <span className="header-text">Buylist Rules</span>
                  </h2>
                </div>
                <div className="buylistRules">
                  <table>
                    <thead>
                      <tr>
                        <td colSpan="2">
                          {this.selectedBuylistRules &&
                          this.selectedBuylistRules.length > 0 ? (
                            <span
                              className="multipleSelect"
                              onClick={() =>
                                this.showDeleteModalForSelectedBuylistRules()
                              }
                              title="Delete selected"
                            >
                              <span className="text">
                                {this.selectedBuylistRules.length} selected{" "}
                                <i className="far fa-trash-alt" />
                              </span>
                            </span>
                          ) : null}
                        </td>
                        <td>Description</td>
                        <td>
                          <Link
                            to="/buylists/rules/add"
                            className="tableLink"
                            onClick={this.setBuylistRuleInStore.bind(
                              this,
                              null
                            )}
                          >
                            Create new rule <i className="fal fa-plus-square" />
                          </Link>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.store.BuylistRulesStore.buylistRules.map(
                        (buylistRule, i) => (
                          <tr key={i}>
                            <td>
                              <label className="checkbox">
                                <input
                                  id={`additionalInfoRequired${i}`}
                                  type="checkbox"
                                  onChange={() =>
                                    this.handleOnChangeForCheckbox(
                                      i,
                                      buylistRule.id
                                    )
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td className="text-bold">{buylistRule.name}</td>
                            <td>
                              {this.generateBuylistRuleDescription(buylistRule)}
                            </td>
                            <td className="text-right">
                              <a
                                className="userActions"
                                title="View rule"
                                onClick={() =>
                                  this.showViewingModal(buylistRule)
                                }
                              >
                                <i className="far fa-eye" />
                              </a>
                              <Link
                                className="userActions"
                                to={`/buylists/rules/update/${buylistRule.id}`}
                                title="Edit rule"
                              >
                                <i className="far fa-edit" />
                              </Link>
                              <a
                                className="userActions"
                                onClick={() =>
                                  this.showDeleteModalFor(buylistRule)
                                }
                                title="Delete rule"
                              >
                                <i className="far fa-trash-alt" />
                              </a>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                {this.modalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card modal-card-large">
                      <header className="modal-card-head">
                        <p className="modal-card-title">{`View rule: ${this.buylistRule.name}`}</p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() => this.setModalVisible(false)}
                        >
                          <i className="fal fa-times" /> Close
                        </button>
                      </header>
                      <section className="modal-card-body">
                        <h5 className="title">Rule description</h5>
                        <p className="desc">
                          {this.generateBuylistRuleDescription(
                            this.buylistRule
                          )}
                        </p>
                        <div className="wrapper">
                          {this.buylistRule.buylistVariants.map(
                            (buylistVariant, i) => (
                              <Fragment key={i}>
                                <h5 className="title1 is-5">
                                  {buylistVariant.variantName}
                                </h5>
                                <div className="variant" key={i}>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            minWidth: "214px",
                                            width: "214px",
                                          }}
                                        />
                                        <td>
                                          <strong>Default</strong>
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td key={`${j}-rarity`}>
                                                  <strong>
                                                    {
                                                      buylistVariantRarityRule.rarity
                                                    }
                                                  </strong>
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Allows purchase</strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.allowPurchase
                                            ? "Yes"
                                            : "No"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-allowPurchase`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.allowPurchase
                                                    ? "Yes"
                                                    : "No"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Cash percent</strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.cashPercent
                                            ? `${buylistVariant.cashPercent}%`
                                            : "N/A"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-cashPercent`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.cashPercent
                                                    ? `${buylistVariantRarityRule.cashPercent}%`
                                                    : "N/A"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Store credit percent</strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.storeCreditPercent
                                            ? `${buylistVariant.storeCreditPercent}%`
                                            : "N/A"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-storeCreditPercent`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.storeCreditPercent
                                                    ? `${buylistVariantRarityRule.storeCreditPercent}%`
                                                    : "N/A"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Overstock quantity</strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.quantity
                                            ? buylistVariant.quantity
                                            : "N/A"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-quantity`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.quantity
                                                    ? buylistVariantRarityRule.quantity
                                                    : "N/A"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>
                                            Allows overstock purchase
                                          </strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.overQuantityAllowPurchase
                                            ? "Yes"
                                            : "No"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-overQuantityAllowPurchase`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.overQuantityAllowPurchase
                                                    ? "Yes"
                                                    : "No"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>
                                            Overstock cash percent
                                          </strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.overQuantityCashPercent
                                            ? `${buylistVariant.overQuantityCashPercent}%`
                                            : "N/A"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-overQuantityCashPercent`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.overQuantityCashPercent
                                                    ? `${buylistVariantRarityRule.overQuantityCashPercent}%`
                                                    : "N/A"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>
                                            Overstock store credit percent
                                          </strong>
                                        </td>
                                        <td className="info">
                                          {buylistVariant &&
                                          buylistVariant.overQuantityStoreCreditPercent
                                            ? `${buylistVariant.overQuantityStoreCreditPercent}%`
                                            : "N/A"}
                                        </td>
                                        {buylistVariant &&
                                        buylistVariant.buylistVariantRarityRules
                                          .length > 0 ? (
                                          <>
                                            {buylistVariant.buylistVariantRarityRules.map(
                                              (buylistVariantRarityRule, j) => (
                                                <td
                                                  className="info"
                                                  key={`${j}-overQuantityStoreCreditPercent`}
                                                >
                                                  {buylistVariantRarityRule &&
                                                  buylistVariantRarityRule.overQuantityStoreCreditPercent
                                                    ? `${buylistVariantRarityRule.overQuantityStoreCreditPercent}%`
                                                    : "N/A"}
                                                </td>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Fragment>
                            )
                          )}
                        </div>
                      </section>
                    </div>
                  </div>
                ) : null}
                {this.deleteModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() => this.setDeleteModalVisible(false)}
                        >
                          <i className="fal fa-times" /> Close
                        </button>
                      </header>
                      <section className="modal-card-body">
                        <p>
                          If you proceed with deleting your buylist rule:{" "}
                          <strong>{this.deleteModalBuylistRule.name}</strong>,
                          you will not be able to restore it.
                        </p>
                        <br />
                        <p>Are you sure you want to delete the buylist rule?</p>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          className="modalBtn cancel"
                          onClick={() => this.setDeleteModalVisible(false)}
                        >
                          <i className="fal fa-times" /> Cancel
                        </button>
                        <button
                          className={`modalBtn action ${
                            this.deleteModalLoading ? "is-loading disabled" : ""
                          }`}
                          onClick={() =>
                            this.deleteBuylistRule(
                              this.deleteModalBuylistRule.id
                            )
                          }
                        >
                          Delete <i className="fas fa-caret-right" />
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
                {this.deleteSelectedModalVisible ? (
                  <div className="modal is-active">
                    <div className="modal-background" />
                    <div className="modal-card">
                      <header className="modal-card-head">
                        <p className="modal-card-title">Are you sure?</p>
                        <button
                          className="delete"
                          aria-label="close"
                          onClick={() =>
                            this.setDeleteSelectedModalVisible(false)
                          }
                        />
                      </header>
                      <section className="modal-card-body">
                        <p>
                          If you proceed with deleting your buylist rules, you
                          will not be able to restore them.
                        </p>
                        <br />
                        <p>
                          Are you sure you want to delete the selected buylist
                          rules?
                        </p>
                      </section>
                      <footer className="modal-card-foot">
                        <button
                          className="modalBtn cancel"
                          onClick={() =>
                            this.setDeleteSelectedModalVisible(false)
                          }
                        >
                          <i className="fal fa-times" /> Cancel
                        </button>
                        <button
                          className={`modalBtn action ${
                            this.deleteSelectedModalLoading
                              ? "is-loading disabled"
                              : ""
                          }`}
                          onClick={() => this.deleteSelectedBuylistRules()}
                        >
                          Delete <i className="fas fa-caret-right" />
                        </button>
                      </footer>
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <div>
            <p>Please contact BinderPOS to enable this screen.</p>
          </div>
        )}
      </>
    );
  }
}

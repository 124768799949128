import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { EbayLocation, EbayParam } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { validateRequiredFields } from "../../utils/forms";
import "./EbayAddressForm.scss";

const initialValues = {
  name: "",
  locationTypes: ["STORE"],
  location: {
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      county: "",
      postalCode: "",
      stateOrProvince: "",
      country: "US",
    },
  },
  locationAdditionalInformation: "",
  locationInstructions: "",
  locationWebUrl: "",
  phone: "",
};

const requiredFields = [
  "name",
  "location.address.addressLine1",
  "location.address.city",
  "location.address.postalCode",
  "location.address.stateOrProvince",
  "location.address.country",
  "locationWebUrl",
];

const validate = (values: EbayLocation) =>
  validateRequiredFields(requiredFields, values);

const generateInput = (fieldName: string, fieldLabel: string) => (
  <>
    <label htmlFor={fieldName}>
      {fieldLabel}
      {requiredFields.includes(fieldName) ? (
        <span className="ebay-address-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} />
    <ErrorMessage
      name={fieldName}
      className="ebay-address-form__field-error"
      component="div"
    />
  </>
);

const getOptions = (options: EbayParam[]) =>
  options.map((option) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

const generateCheckboxes = (
  fieldName: string,
  options: EbayParam[],
  values: string[],
  setValue: Function
) =>
  options.map((option) => (
    <label
      htmlFor={`${fieldName}_${option.code}`}
      className="ebay-address-form__checkbox-label"
      key={option.code}
    >
      <input
        type="checkbox"
        id={`${fieldName}_${option.code}`}
        checked={values.includes(option.code)}
        onChange={(e) => {
          if (e.target.checked) {
            setValue(fieldName, [...values, option.code]);
          } else {
            setValue(
              fieldName,
              values.filter((v) => v !== option.code)
            );
          }
        }}
      />
      {option.description}
    </label>
  ));

interface EbayAddressFormProps {
  location?: EbayLocation;
  countries: EbayParam[];
  locationTypes: EbayParam[];
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayAddressForm(props: EbayAddressFormProps) {
  const { location, countries, locationTypes, setEditSetting, saveSetting } =
    props;

  return (
    <div className="ebay-address-form">
      <Formik
        initialValues={location || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveSetting(values, setSubmitting)
        }
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="ebay-address-form__inputs">
              {generateInput("name", "Location Name")}
              <label htmlFor="locationTypes">Location Type</label>
              <div className="ebay-address-form__location-type">
                {generateCheckboxes(
                  "locationTypes",
                  locationTypes,
                  values.locationTypes || [],
                  setFieldValue
                )}
              </div>
              <ErrorMessage
                name="location.address.country"
                className="ebay-address-form__field-error"
                component="div"
              />
              {generateInput("location.address.addressLine1", "Address")}
              {generateInput("location.address.addressLine2", "Address 2")}
              {generateInput("location.address.city", "City")}
              {generateInput("location.address.county", "County")}
              {generateInput(
                "location.address.postalCode",
                "Zip / Postal Code"
              )}
              {generateInput(
                "location.address.stateOrProvince",
                "State / Province"
              )}
              <label htmlFor="location.address.country">Country</label>
              <Field as="select" name="location.address.country">
                {getOptions(countries)}
              </Field>
              <ErrorMessage
                name="location.address.country"
                className="ebay-address-form__field-error"
                component="div"
              />
              {generateInput(
                "locationAdditionalInformation",
                "Additional Information"
              )}
              {generateInput("locationInstructions", "Location Instructions")}
              {generateInput("locationWebUrl", "Website")}
              {generateInput("phone", "Phone")}
            </div>
            <div className="ebay-address-form__actions">
              <ButtonComponent secondary onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayAddressForm;

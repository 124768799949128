import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Loader from "../generic/Loader";
import CardNoImage from "../../../assets/img/cardNoImage.png";

@inject("store")
@observer
class ListView extends Component {
  componentDidMount() {
    this.props.store.WindowSizeStore.setWindow();
  }

  renderQuantityAndBuyPriceInputs = (
    completed,
    quantity,
    setName,
    type,
    variant,
    currencySymbol,
    buyPrice,
    isPaidInCash,
    cardName,
    cardId
  ) => {
    const { onVariantQuantityChange, onVariantBuyPriceChange } = this.props;
    return (
      <Fragment>
        <span className="buylist-item-input quantity-label">Qty</span>
        <span className="buylist-item-input">
          {completed ? (
            `${quantity} @ `
          ) : (
            <input
              className="quantity"
              type="number"
              min={0}
              step={1}
              value={quantity}
              onChange={(event) =>
                onVariantQuantityChange(
                  cardName,
                  cardId,
                  setName,
                  type,
                  variant.id,
                  event.target.value
                )
              }
              data-testid="quantity"
            />
          )}
        </span>
        &nbsp;
        <span className="buylist-item-input" style={{ whiteSpace: "pre" }}>
          {currencySymbol}
          {completed ? (
            buyPrice
          ) : (
            <input
              className="buy-price"
              type="number"
              min={0}
              step={0.01}
              value={buyPrice}
              onChange={(event) =>
                onVariantBuyPriceChange(
                  cardName,
                  cardId,
                  setName,
                  type,
                  variant.id,
                  isPaidInCash,
                  event.target.value
                )
              }
              data-testid="buy-price"
            />
          )}
        </span>
      </Fragment>
    );
  };

  render() {
    const { removeLine, setSearchModalVisible } = this.props;
    const { BuylistRulesStore, CustomersStore, MainStore, WindowSizeStore } =
      this.props.store;
    const fC = MainStore.currencyBuilder;
    const { completed, paymentType, cards } = BuylistRulesStore.buylistDetails;
    const isPaidInCash = paymentType === "Cash";
    const currencySymbol = MainStore.currency || "$";
    const { windowWidth } = WindowSizeStore;

    if (!CustomersStore.customerVariants) return <Loader />;

    return (
      <div className="col-md-12 buylistList">
        <div className="row">
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Card</td>
                {windowWidth >
                CustomersStore.customerVariants.length * 150 + 1000 ? (
                  CustomersStore.customerVariants.map((variant, _index) => (
                    <td
                      key={_index}
                      style={{ textAlign: `${completed ? "" : "center"}` }}
                    >
                      {variant.name}
                    </td>
                  ))
                ) : (
                  <td>Variant Quantity and Buy Price</td>
                )}
                <td>Total Buy Price</td>
                <td>Total Sell Price</td>
                {!completed && <td></td>}
              </tr>
            </thead>
            <tbody>
              {cards.map((buylistDetail, index) => {
                const { imageUrl, cardName, cardId, type, setName } =
                  buylistDetail;
                let rowClass = "";
                try {
                  const cardCount = Object.values(
                    buylistDetail.variants
                  ).reduce(
                    (count, variant) => count + parseInt(variant.quantity, 10),
                    0
                  );
                  if (cardCount === 0) {
                    rowClass = "error";
                  }
                } catch (err) {
                  console.error(err);
                }
                return (
                  <tr
                    key={index}
                    style={{ lineHeight: "1.5em" }}
                    className={rowClass}
                  >
                    <td>
                      <img src={imageUrl} />
                    </td>
                    <td>
                      {cardName}
                      {type ? "- " + type : ""}
                      <br />
                      <em>{setName}</em>
                    </td>
                    {windowWidth >
                    CustomersStore.customerVariants.length * 150 + 1000 ? (
                      CustomersStore.customerVariants.map((variant, _index) => {
                        let quantity = 0;
                        let buyPrice = 0;
                        if (
                          Object.keys(buylistDetail.variants).includes(
                            String(variant.id)
                          )
                        ) {
                          const _variant = buylistDetail.variants[variant.id];
                          quantity = _variant.quantity;
                          buyPrice = isPaidInCash
                            ? _variant.cashBuyPrice
                            : _variant.storeCreditBuyPrice;
                        }
                        return (
                          <td key={_index} style={{ whiteSpace: "pre" }}>
                            {this.renderQuantityAndBuyPriceInputs(
                              completed,
                              quantity,
                              setName,
                              type,
                              variant,
                              currencySymbol,
                              buyPrice,
                              isPaidInCash,
                              cardName,
                              cardId
                            )}
                          </td>
                        );
                      })
                    ) : (
                      <td>
                        <div className="vertialVariantPricing">
                          {CustomersStore.customerVariants.map(
                            (variant, _index) => {
                              let quantity = 0;
                              let buyPrice = 0;
                              if (
                                Object.keys(buylistDetail.variants).includes(
                                  String(variant.id)
                                )
                              ) {
                                const _variant =
                                  buylistDetail.variants[variant.id];
                                quantity = _variant.quantity;
                                buyPrice = isPaidInCash
                                  ? _variant.cashBuyPrice
                                  : _variant.storeCreditBuyPrice;
                              }
                              return (
                                <Fragment key={_index}>
                                  <span>{variant.name}&nbsp;</span>
                                  {this.renderQuantityAndBuyPriceInputs(
                                    completed,
                                    quantity,
                                    setName,
                                    type,
                                    variant,
                                    currencySymbol,
                                    buyPrice,
                                    isPaidInCash,
                                    cardName,
                                    cardId
                                  )}
                                </Fragment>
                              );
                            }
                          )}
                        </div>
                      </td>
                    )}
                    <td>
                      {fC(
                        Object.values(buylistDetail.variants).reduce(
                          (sum, variant) =>
                            sum +
                            parseFloat(variant.quantity) *
                              parseFloat(
                                isPaidInCash
                                  ? variant.cashBuyPrice
                                  : variant.storeCreditBuyPrice
                              ),
                          0
                        )
                      )}
                    </td>
                    <td>
                      {fC(
                        Object.values(buylistDetail.variants).reduce(
                          (sum, variant) => {
                            return (
                              sum +
                              (variant.quantity > 0
                                ? parseFloat(variant.quantity) *
                                  parseFloat(variant.storeSellPrice)
                                : 0)
                            );
                          },
                          0
                        )
                      ) || "N/A"}
                    </td>
                    {!completed && (
                      <td>
                        <i
                          className="far fa-trash-alt"
                          onClick={() => removeLine(index)}
                        ></i>
                      </td>
                    )}
                  </tr>
                );
              })}
              {!completed && (
                <tr>
                  <td>
                    <img src={CardNoImage} />
                  </td>
                  <td colSpan="6">
                    <p
                      className="listAddCard"
                      onClick={() => setSearchModalVisible(true)}
                    >
                      <i className="fal fa-plus" /> Add a Card
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ListView.propTypes = {
  onVariantQuantityChange: PropTypes.func.isRequired,
  onVariantBuyPriceChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  setSearchModalVisible: PropTypes.func.isRequired,
};

export default ListView;

import React, { Component, Fragment } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { BuylistMenuStructure } from "../../menuStructures";
import { Loader } from "../../components";
import { TimeUtils } from "../../utils";
import {
  removeFailedProduct,
  processFailedProduct,
} from "../../api/rest/buylist";

@inject("store")
@observer
export default class FailedBuylistProducts extends Component {
  @observable offset = 0;

  @action setOffset(offset) {
    this.offset = offset;
  }

  @observable limit = 100;

  @action setLimit(limit) {
    this.limit = limit;
  }

  @observable messageModalText;

  @action setMessageModalText(text) {
    this.messageModalText = text;
  }

  @observable messageModalTitle;

  @action setMessageModalTitle(title) {
    this.messageModalTitle = title;
  }

  @observable messageModalVisible;

  @action setMessageModalVisible(bool) {
    this.messageModalVisible = bool;
  }

  @observable processing;

  @action setProcessing(processing) {
    this.processing = processing;
  }

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible(bool) {
    this.deleteModalVisible = bool;
  }

  @observable deleteModalProduct;

  @action setDeleteModalProduct(product) {
    this.deleteModalProduct = product;
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Failed to Sync to Stock | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(BuylistMenuStructure);

    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  }

  getNextFailedToSync() {
    this.setOffset(this.offset + 100);
    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  }

  getPrevFailedToSync() {
    let offsetUpdate = this.offset - 100;
    if (offsetUpdate < 0) {
      offsetUpdate = 0;
    }
    this.setOffset(offsetUpdate);
    this.props.store.BuylistRulesStore.rehydrateFailedToSync(
      this.offset,
      this.limit
    );
  }

  pushStockThrough(failedToSync) {
    this.setProcessing(true);
    processFailedProduct(failedToSync)
      .then((result) => {
        if (!result.actionPass) {
          this.setMessageModalText(result.message);
          this.setMessageModalTitle("Issue pushing through to stock");
          this.setMessageModalVisible(true);
          this.setProcessing(false);
        } else {
          this.setProcessing(false);
          this.props.store.BuylistRulesStore.rehydrateFailedToSync(
            this.offset,
            this.limit
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to push stock",
          "There was an error pushing stock for your failed buylist products. Please try again"
        );
      });
  }

  showRemoveProductModal(failedToSync) {
    this.setDeleteModalProduct(failedToSync);
    this.setDeleteModalVisible(true);
  }

  removeFromList(failedToSync) {
    this.setProcessing(true);
    this.setDeleteModalVisible(false);
    removeFailedProduct(failedToSync)
      .then((result) => {
        this.setMessageModalText(
          result.actionPass ? "Product removed successfully!" : result.message
        );
        this.setMessageModalTitle(
          result.actionPass
            ? "Product removed"
            : "There was an issue removing this product"
        );
        this.setMessageModalVisible(true);
        this.setProcessing(false);
        this.props.store.BuylistRulesStore.rehydrateFailedToSync(
          this.offset,
          this.limit
        );
      })
      .catch((error) => {
        console.error(error);
        this.setProcessing(false);
        this.props.store.MainStore.setError(
          error,
          "Failed to load remove product",
          "There was an error removing the selected product. Please try again"
        );
      });
  }

  render() {
    if (!this.props.store.AuthStore.screenSettings.buylist)
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    if (!this.props.store.BuylistRulesStore.failedToSyncList) return <Loader />;
    if (this.processing) return <Loader />;
    return (
      <>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">
              Failed to Sync to Stock from Buylist
            </span>
            <div className="top-pagination">
              <button
                className="paging-nav large"
                disabled={this.offset == 0}
                onClick={() => this.getPrevFailedToSync()}
              >
                Prev
              </button>
              <button
                className="paging-nav large"
                disabled={
                  !!(
                    this.props.store.BuylistRulesStore.failedToSyncList &&
                    this.props.store.BuylistRulesStore.failedToSyncList.length <
                      this.limit
                  )
                }
                onClick={() => this.getNextFailedToSync()}
              >
                Next
              </button>
            </div>
          </h2>
        </div>
        <br />
        <div className="tiledPanels">
          {this.props.store.BuylistRulesStore.failedToSyncList.map(
            (failedToSync, i) => (
              <div className="panelWrapper" key={i}>
                <div className="panelWrapper" key={i}>
                  <div className="panel">
                    <div className="row">
                      <div className="col-sm-6">
                        <img className="failedImg" src={failedToSync.img} />
                      </div>
                      <div className="col-sm-6">
                        <div className="titleInfo">
                          <span className="cardName">
                            {`${failedToSync.cardName} ${
                              failedToSync.foil == true ? "Foil" : ""
                            }`}
                          </span>
                          <span className="cartSet">
                            {failedToSync.setName}
                          </span>
                        </div>
                        <div className="cardInfo">
                          <span>
                            Condition: <em>{failedToSync.variantName}</em>
                          </span>
                          <span>
                            Quantity: <em>{failedToSync.quantity}</em>
                          </span>
                          <span style={{ marginTop: "10px" }}>
                            Processed on:{" "}
                            <em>
                              {TimeUtils.convertDateToHumanReadable(
                                failedToSync.readableSubmittedDate
                              )}
                            </em>
                          </span>
                        </div>
                      </div>
                    </div>
                    <span
                      className="failedEdit"
                      onClick={() => this.pushStockThrough(failedToSync)}
                    >
                      <i className="fal fa-paper-plane" /> Add to Stock
                    </span>
                    <span
                      className="failRemove"
                      onClick={() => this.showRemoveProductModal(failedToSync)}
                    >
                      Remove <i className="fas fa-times-circle" />
                    </span>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {this.messageModalVisible ? (
          <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">{this.messageModalTitle}</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => this.setMessageModalVisible(false)}
                >
                  <i className="fal fa-times" /> Close
                </button>
              </header>
              <section className="modal-card-body">
                <p>{this.messageModalText}</p>
              </section>
              <footer className="modal-card-foot">
                <button
                  type="button"
                  className="modalBtn action full"
                  onClick={() => this.setMessageModalVisible(false)}
                >
                  Ok
                </button>
              </footer>
            </div>
          </div>
        ) : null}
        {this.deleteModalVisible ? (
          <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Are you sure?</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => this.setDeleteModalVisible(false)}
                >
                  <i className="fal fa-times" /> Close
                </button>
              </header>
              <section className="modal-card-body">
                <p>
                  Are you sure you want to remove{" "}
                  {this.deleteModalProduct.cardName} [
                  {this.deleteModalProduct.setName}] -{" "}
                  {this.deleteModalProduct.variantName}?
                </p>
              </section>
              <footer className="modal-card-foot">
                <button
                  className="modalBtn cancel half"
                  onClick={() => this.setDeleteModalVisible(false)}
                >
                  Cancel
                </button>
                <button
                  className="modalBtn action half"
                  onClick={() => this.removeFromList(this.deleteModalProduct)}
                >
                  Remove
                </button>
              </footer>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

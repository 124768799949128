import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/generic/Loader";
import { fetchLocations, updateLocations } from "../../api/rest/settings";
import ButtonComponent from "../../components/generic/ButtonComponent";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import SetActiveMenu from "../../menuStructures/SetActiveMenu";
import POSMenuStructure from "../../menuStructures/POSMenuStructure";
import { useShowError } from "../../hooks/errorHooks";
import "./LocationSettings.scss";

let LOCATION_ID: number;

function LocationSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [locationId, setLocationId] = useState(LOCATION_ID);
  const [locations, setLocations] = useState<Locations>();

  const showError = useShowError();

  const getLocations = () =>
    fetchLocations()
      .then((setting: Locations) => {
        setLocations(setting);
        setLocationId(parseInt(setting.binder?.[0]?.shopifyId));
      })
      .catch(showError);

  const handleSave = () => {
    setIsSaving(true);
    const locationsData = {
      id: locationId,
    };
    updateLocations(locationsData)
      .then(() => {
        toast.info("Location updated");
      })
      .catch(showError)
      .finally(() => setIsSaving(false));
  };

  const handleChange = (shopifyId: number) => {
    setLocationId(shopifyId);
  };

  useEffect(() => {
    Promise.resolve(getLocations()).then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SetActiveMenu menuStructure={POSMenuStructure} />
      <SectionHeaderLayout title="Location Settings" />
      <div className="LocationSettings__descriptionText">
        <p>
          Location selected must match the default location in Shopify. The
          default location can be found in your Shopify Admin page under
          Settings -{">"} Locations.
        </p>
      </div>
      <form>
        <div className="LocationSettings__attributeTable">
          <div className="LocationSettings__attributeTableHeader">
            <div className="LocationSettings__attributeLabel">Location</div>
            <div className="LocationSettings__attributeRadio">Selection</div>
          </div>
          {locations.shopify.map((field) => (
            <div
              key={field.name}
              className="LocationSettings__attributeTableRow"
            >
              <div className="LocationSettings__attributeLabel">
                <label htmlFor={field.name}>{field.name}</label>
              </div>
              <div className="LocationSettings__attributeRadio">
                <input
                  type="radio"
                  id={field.name}
                  checked={field.shopifyId === locationId || false}
                  onChange={() => handleChange(field.shopifyId)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="LocationSettings__saveButton">
          <ButtonComponent disabled={isSaving} onClick={() => handleSave()}>
            Save
          </ButtonComponent>
        </div>
      </form>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
      />
    </>
  );
}

export default LocationSettings;

import { _fetch } from "../../api";
import { BASE_URL } from "../../constants/api";

type BulkUpdateRequest = {
  fieldsToUpdate: unknown;
  searchToUpdate: Record<string, string | string[] | null>;
};

type MessageResponse = {
  message: string;
};

export const bulkUpdate = (
  bulkUpdateRequest: BulkUpdateRequest
): Promise<MessageResponse> =>
  _fetch({
    method: "POST",
    endpoint: `${BASE_URL}/products/bulkUpdate`,
    payload: bulkUpdateRequest,
  });

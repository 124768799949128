import React, { useEffect, useState } from "react";
import TimeUtils from "../../utils/TimeUtils";
import Spinner from "../generic/Spinner";
import { getLedgerEntries } from "../../api/graphql/reports";

function LedgerReport(props) {
  const { id, currencyCode, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getLedgerEntries(id)
      .then((result) => {
        setData(result?.POSReports?.LedgerEntries || []);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  const formatCurrency = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) {
      return "-";
    }
    const locale = navigator.languages[0];
    return floatValue.toLocaleString(locale, {
      style: "currency",
      currency: currencyCode || "USD",
    });
  };

  return (
    <div className="LedgerReport modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">View Ledger</p>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>
        <section className="modal-card-body LedgerReport__body">
          <Spinner isLoading={isLoading}>
            <table>
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>Amount</th>
                  <th>Tender</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody className="LedgerReport__entries">
                {data.map((row) => (
                  <tr key={row.entryId}>
                    <td className="LedgerReport__column LedgerReport__column--no-wrap">
                      {TimeUtils.convertDateToHumanReadable(row.createdAt)}
                    </td>
                    <td className="LedgerReport__column LedgerReport__column--no-wrap">
                      {formatCurrency(row.amount)}
                    </td>
                    <td className="LedgerReport__column LedgerReport__column--no-wrap">
                      {row.tender}
                    </td>
                    <td className="LedgerReport__column LedgerReport__notes">
                      {row.notes}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Spinner>
        </section>
        <footer className="modal-card-foot">
          <button
            className="LedgerReport__close-button close-button"
            onClick={onClose}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

export default LedgerReport;

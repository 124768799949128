import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { TimeUtils } from "../../utils";
import NoBanner from "../../../assets/img/noBanner.png";

function EventCard(props) {
  const {
    event,
    showViewEventModalForEvent,
    showDeleteEventModalForEvent,
    showEnableEventModalForEvent,
    setEventInStore,
  } = props;
  const {
    id,
    isDeleting,
    deleting,
    calendarIcon,
    banner,
    title,
    game,
    type,
    date,
    time,
    recurring,
    parentEventId,
  } = event;
  return (
    <div className="panelWrapper">
      <div
        className={"panel" + (!!isDeleting || !!deleting ? " disabled" : "")}
      >
        <div className="eventImage">
          <span className="ticketIcon">
            <img src={calendarIcon} />
          </span>
          <img src={banner ? banner : NoBanner} />
        </div>
        <span className="eventTitle">{title}</span>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="types">
                Game Type:<em>{game}</em>
              </p>
            </div>
            <div className="col-md-6">
              <p className="types">
                Event Type:<em>{type}</em>
              </p>
            </div>
            <div className="col-md-6">
              <p className="info">
                <i className="far fa-calendar-alt" />
                {recurring
                  ? `Recurring`
                  : `${new Date(date.substring(0, 17)).toLocaleDateString(
                      undefined,
                      {
                        timeZone: "Etc/GMT",
                      }
                    )}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="info">
                <i className="far fa-clock" />
                {TimeUtils.convertTime(time)}
              </p>
            </div>
            {!parentEventId && (
              <Fragment>
                {recurring ? (
                  <div className="col-md-6">
                    <p className="info">
                      <Link to={`/events/view/${id}`}>
                        <i className="fas fa-eye" aria-hidden="true" />
                        View All Events
                      </Link>
                    </p>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <p className="info">
                      <a
                        className="userActions"
                        onClick={() => showViewEventModalForEvent(id)}
                      >
                        <i className="far fa-eye" aria-hidden="true" /> View
                        Event
                      </a>
                    </p>
                  </div>
                )}
                <div className="col-md-6">
                  <p className="info">
                    <a onClick={() => showDeleteEventModalForEvent(id)}>
                      <i className="far fa-trash-alt" />
                      Remove Event
                    </a>
                  </p>
                </div>
              </Fragment>
            )}
            {recurring ? (
              <div className="col-md-12">&nbsp;</div>
            ) : (
              <div className="col-md-12">
                <p className="participants">
                  <em>Participants: </em>
                  <Link to={`/events/participants/${id}`}>
                    View the entrants
                  </Link>
                </p>
              </div>
            )}
          </div>
        </div>
        {parentEventId ? (
          <div className="childBtnWrapper">
            <Link
              to={`/events/update/${id}`}
              onClick={() => setEventInStore(event)}
              className="editBtn half"
            >
              <i className="far fa-calendar-edit" /> Edit event
            </Link>
            {!event.isDisabled ? (
              <a
                className="editBtn half"
                onClick={() => showDeleteEventModalForEvent(id)}
              >
                Pause <i className="fa fa-pause pause" aria-hidden="true" />
              </a>
            ) : (
              <a
                className="editBtn half"
                onClick={() => showEnableEventModalForEvent(id)}
              >
                Resume{" "}
                <i className="fa fa-play pause green" aria-hidden="true" />
              </a>
            )}
          </div>
        ) : (
          <Link
            to={`/events/update/${id}`}
            onClick={() => setEventInStore(event)}
            className="editBtn"
          >
            <i className="far fa-calendar-edit" /> Edit event
          </Link>
        )}
      </div>
    </div>
  );
}

EventCard.propTypes = {
  event: PropTypes.object,
  showViewEventModalForEvent: PropTypes.func.isRequired,
  showDeleteEventModalForEvent: PropTypes.func.isRequired,
  showEnableEventModalForEvent: PropTypes.func.isRequired,
  setEventInStore: PropTypes.func.isRequired,
};

export default EventCard;

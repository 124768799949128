import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  EbayFulfillment,
  EbayParam,
  ShippingService,
  ShippingServiceProvider,
} from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import EbayShippingServices from "../../components/integrations/EbayShippingServices";
import { useStoreDetails } from "../../hooks/storeHooks";
import { validateRequiredFields } from "../../utils/forms";
import "./EbayFulfillmentForm.scss";

const initialValues = {
  name: "",
  description: "",
  freightShipping: false,
  globalShipping: false,
  localPickup: false,
  pickupDropOff: false,
  handlingTime: {
    unit: "DAY",
    value: 0,
  },
  shipInternationally: false,
  regionsIncluded: [],
  regionsExcluded: [],
  shippingOptions: [],
} as EbayFulfillment;

const requiredFields = [] as string[];
const validate = (values: EbayFulfillment) =>
  validateRequiredFields(requiredFields, values);

const generateInput = (
  fieldName: string,
  fieldLabel: string,
  prefix?: string
) => (
  <>
    <label htmlFor={fieldName}>
      {fieldLabel} {prefix}
      {requiredFields.includes(fieldName) ? (
        <span className="ebay-fulfillment-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} />
    <ErrorMessage
      name={fieldName}
      className="ebay-fulfillment-form__field-error"
      component="div"
    />
  </>
);

const getOptions = (options: EbayParam[]) =>
  options.map((option) => (
    <option key={option.code} value={option.code}>
      {option.description}
    </option>
  ));

const supportedDurations = ["HOUR", "DAY", "CALENDAR_DAY"];
const filterDurations = (durations: EbayParam[]) =>
  durations.filter((duration: EbayParam) =>
    supportedDurations.includes(duration.code)
  );

const mergeUpdatedShippingSettings = (
  values: EbayFulfillment,
  shippingOption: number,
  shippingServices: ShippingService[],
  setValues: Function
) => {
  const newValues = { ...values };
  newValues.shippingOptions[shippingOption].shippingServices = shippingServices;
  setValues(newValues);
};

const costTypes = [
  { code: "FLAT_RATE", description: "Flat rate" },
  { code: "NOT_SPECIFIED", description: "Not specified" },
];

interface EbayFulfillmentFormProps {
  fulfillmentSetting?: EbayFulfillment;
  shippingServices: ShippingServiceProvider[];
  includeRegions: EbayParam[];
  excludeRegions: EbayParam[];
  timeDurations: EbayParam[];
  shippingOptionTypes: EbayParam[];
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayFulfillmentForm(props: EbayFulfillmentFormProps) {
  const {
    fulfillmentSetting,
    shippingServices,
    includeRegions,
    excludeRegions,
    timeDurations,
    setEditSetting,
    saveSetting,
  } = props;

  const [shipInternationally, setShipInternationally] = useState(
    fulfillmentSetting?.shippingOptions
      ? fulfillmentSetting.shippingOptions.length > 1
      : false
  );
  const { currencySymbol } = useStoreDetails();

  return (
    <div className="ebay-fulfillment-form">
      <Formik
        initialValues={fulfillmentSetting || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          const newValues = { ...values };
          newValues.shippingOptions[0].optionType = "DOMESTIC";
          if (newValues.shippingOptions.length > 1) {
            newValues.shippingOptions[1].optionType = "INTERNATIONAL";
          }
          saveSetting(values, setSubmitting);
        }}
      >
        {({ values, isSubmitting, setFieldValue, setValues }) => (
          <Form>
            <div className="ebay-fulfillment-form__inputs">
              {generateInput("name", "Fulfillment Policy Name")}
              {generateInput("description", "Description")}
              <label htmlFor="freightShipping">
                Freight Shipping Available
              </label>
              <Field type="checkbox" name="freightShipping" />
              <ErrorMessage
                name="freightShipping"
                className="ebay-fulfillment-form__field-error"
                component="div"
              />
              <label htmlFor="localPickup">Local Pickup</label>
              <Field
                type="checkbox"
                name="localPickup"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(`event.target.checked: ${event.target.checked}`);
                  setFieldValue("localPickup", event.target.checked);
                  setFieldValue(
                    "shippingOptions[0].costType",
                    event.target.checked ? "NOT_SPECEFIED" : "FLAT_RATE"
                  );
                }}
              />
              <ErrorMessage
                name="localPickup"
                className="ebay-fulfillment-form__field-error"
                component="div"
              />
              <label>
                Handling Time
                <span className="ebay-fulfillment-form__required">*</span>
              </label>
              <span>
                <Field name="handlingTime.value" type="number" />
                <Field
                  name="handlingTime.unit"
                  className="ebay-fulfillment-form__unit-select"
                  as="select"
                >
                  {getOptions(filterDurations(timeDurations))}
                </Field>
              </span>
              {values.localPickup ? null : (
                <>
                  <h5>Domestic Shipping</h5>
                  <Field
                    type="hidden"
                    name="shippingOptions[0].optionType"
                    value="DOMESTIC"
                  />
                  <span />
                  <label htmlFor="shippingOptions[0].costType">Cost Type</label>
                  <Field as="select" name="shippingOptions[0].costType">
                    <option value="">-- Select Cost Type --</option>
                    {getOptions(costTypes)}
                  </Field>
                  <ErrorMessage
                    name="shippingOptions[0].costType"
                    className="ebay-fulfillment-form__field-error"
                    component="div"
                  />
                  {values?.shippingOptions[0]?.costType === "CALCULATED"
                    ? generateInput(
                        "values.shippingOptions[0].packageHandlingCost",
                        "Handling Cost",
                        currencySymbol
                      )
                    : null}
                  <p>Shipping Services</p>
                  {values?.shippingOptions[0]?.costType &&
                  values.shippingOptions[0].costType.length > 0 ? (
                    <EbayShippingServices
                      settings={
                        values.shippingOptions[0].shippingServices || []
                      }
                      shippingServiceProviders={shippingServices}
                      costType={values.shippingOptions[0].costType}
                      includeRegions={includeRegions}
                      excludeRegions={excludeRegions}
                      updateShippingServices={(services: ShippingService[]) =>
                        mergeUpdatedShippingSettings(
                          values,
                          0,
                          services,
                          setValues
                        )
                      }
                    />
                  ) : (
                    <span>Please Select a Cost Type</span>
                  )}
                  <h5>International Shipping</h5>
                  <span />
                  <label htmlFor="shipInternationally">
                    Ship Internationally
                  </label>
                  <input
                    name="shipInternationally"
                    type="checkbox"
                    onClick={() => {
                      setShipInternationally(!shipInternationally);
                    }}
                  />
                  {shipInternationally ? (
                    <>
                      <Field
                        type="hidden"
                        name="shippingOptions[1].optionType"
                        value="INTERNATIONAL"
                      />
                      <span />
                      <span />
                      <label htmlFor="shippingOptions[1].costType">
                        Cost Type
                      </label>
                      <Field as="select" name="shippingOptions[1].costType">
                        <option value="">-- Select Cost Type --</option>
                        {getOptions(costTypes)}
                      </Field>
                      <ErrorMessage
                        name="shippingOptions[1].costType"
                        className="ebay-fulfillment-form__field-error"
                        component="div"
                      />
                      {values.shippingOptions[1]?.costType === "CALCULATED"
                        ? generateInput(
                            "values.shippingOptions[1].packageHandlingCost",
                            "Handling Cost"
                          )
                        : null}
                      <p>Shipping Services</p>
                      {values?.shippingOptions[1]?.costType &&
                      values.shippingOptions[1].costType.length > 0 ? (
                        <EbayShippingServices
                          international
                          settings={
                            values.shippingOptions[1].shippingServices || []
                          }
                          shippingServiceProviders={shippingServices}
                          costType={values.shippingOptions[1].costType}
                          includeRegions={includeRegions}
                          excludeRegions={excludeRegions}
                          updateShippingServices={(
                            services: ShippingService[]
                          ) =>
                            mergeUpdatedShippingSettings(
                              values,
                              1,
                              services,
                              setValues
                            )
                          }
                        />
                      ) : (
                        <span>Please Select a Cost Type</span>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
            <div className="ebay-payment-methods-form__actions">
              <ButtonComponent secondary onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayFulfillmentForm;

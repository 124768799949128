import React, { useEffect, useState } from "react";
import CartNoteModel from "./cart-note.model";

export default function EditCartNotes({
  onClose,
  posStore,
  cartNotes,
  customFields,
}: {
  cartNotes?: string;
  customFields?: string[];
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posStore: any;
}) {
  const [note, setNote] = useState<CartNoteModel>({
    note: "",
    customFields: {},
  });
  const twoColStyle: React.CSSProperties = {
    marginTop: 10,
    marginLeft: 10,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  };

  useEffect(() => {
    if (!cartNotes) return;
    setNote(JSON.parse(cartNotes));
  }, [cartNotes]);

  return (
    <div className="modal display-block">
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Notes</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => onClose()}
          >
            <i className="fal fa-times"></i> Close
          </button>
        </header>
        <section className="modal-card-body">
          <textarea
            id="note"
            rows={10}
            onChange={(event) => setNote({ ...note, note: event.target.value })}
            value={note.note || ""}
            style={{ width: "545px" }}
          />
          {customFields && (
            <div style={{ maxHeight: 340, overflowY: "scroll" }}>
              {customFields.map((customField) => (
                <div key={customField} style={twoColStyle}>
                  <label htmlFor={customField}>{customField}</label>
                  <input
                    id={customField}
                    onChange={(event) => {
                      const newNote = { ...note };
                      newNote.customFields[customField] = event.target.value;
                      setNote(newNote);
                    }}
                    value={note.customFields[customField] || ""}
                  />
                </div>
              ))}
            </div>
          )}
        </section>
        <footer className="modal-card-foot">
          <button
            className="modalBtn cancel"
            type="button"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="modalBtn action"
            type="button"
            onClick={async () => {
              posStore.setCartNotes(JSON.stringify(note));
              await posStore.validateCart();
              onClose();
            }}
          >
            Attach note
          </button>
        </footer>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { EventMenuStructure } from "../../menuStructures";
import { doDeleteEvent, fetchEvents } from "../../api/rest/events";
import { DEFAULT_EVENTS_PER_PAGE } from "../../constants/events";
import { Loader, Paging, Spinner } from "../../components";
import {
  EventCard,
  EventsFilter,
  ViewEventModal,
  DeleteEventModal,
} from "../../components/events";

@inject("store")
@observer
class EventList extends Component {
  @observable pageOffset = 0;

  @action setPageOffset = (offset) => {
    this.pageOffset = offset;
  };

  @observable modalVisible = false;

  @action setModalVisible = (visible) => {
    this.modalVisible = visible;
  };

  @observable selectedEvent;

  @action setSelectedEvent = (event) => {
    this.selectedEvent = event;
  };

  @observable deleteModalVisible = false;

  @action setDeleteModalVisible = (visible) => {
    this.deleteModalVisible = visible;
  };

  @observable deleteModalLoading;

  @action setDeleteModalLoading = (loading) => {
    this.deleteModalLoading = loading;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable eventFilters = {};

  @action resetEventFilters = () => {
    this.eventFilters = {};
  };

  @action updateEventFilter = (parameter, value) => {
    if (parameter === "offset") {
      this.setPageOffset(value);
    } else {
      this.eventFilters[parameter] = value;
    }
  };

  @computed get eventListParams() {
    // The line at the end removes parameters where a value is not set
    return {
      offset: this.pageOffset,
      limit: DEFAULT_EVENTS_PER_PAGE,
      ...Object.fromEntries(
        Object.entries(this.eventFilters).filter(([, value]) => !!value)
      ),
    };
  }

  showViewEventModalForEvent = (eventId) => {
    const event = this.props.store.EventsStore.events.filter(
      (event) => event.id == eventId
    );
    this.setModalVisible(true);
    this.setSelectedEvent(event[0]);
  };

  showDeleteEventModalForEvent = (eventId) => {
    const event = this.props.store.EventsStore.events.filter(
      (event) => event.id == eventId
    );
    this.setDeleteModalVisible(true);
    this.setSelectedEvent(event[0]);
  };

  deleteEvent = (eventId) => {
    this.setDeleteModalLoading(true);
    doDeleteEvent(eventId)
      .then(() => {
        this.setDeleteModalVisible(false);
        this.setSelectedEvent(null);
        this.setDeleteModalLoading(false);
        this.props.store.EventsStore.rehydrate();
      })
      .catch((error) => {
        console.error(error);
        this.props.store.MainStore.setError(
          error,
          "Failed to delete event",
          "There was an error deleting your event. Please try again"
        );
      });
  };

  setEventInStore = (event) => {
    this.props.store.EventsStore.setEvent(event);
  };

  loadEvents = () => {
    this.setIsLoading(true);
    fetchEvents(this.eventListParams)
      .then((result) => {
        this.props.store.EventsStore.setEvents(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load events",
          "There was an error retrieving your events. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  prevPage = () => {
    let newOffset = this.pageOffset - DEFAULT_EVENTS_PER_PAGE;
    if (newOffset < 0) {
      newOffset = 0;
    }
    this.setPageOffset(newOffset);
    this.loadEvents();
  };

  nextPage = () => {
    const newOffset = this.pageOffset + DEFAULT_EVENTS_PER_PAGE;
    this.setPageOffset(newOffset);
    this.loadEvents();
  };

  componentDidMount() {
    document.title = "Events | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(EventMenuStructure);
    this.loadEvents(this.eventListParams);
    this.props.store.WindowSizeStore.setWindow();
  }

  render() {
    const { AuthStore, EventsStore, WindowSizeStore } = this.props.store;

    if (!AuthStore.screenSettings.events) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    if (!EventsStore.events) {
      return <Loader />;
    }

    return (
      <>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">My Events</span>
            {WindowSizeStore.windowWidth > 650 ? (
              <Link
                to="/events/add"
                className="btn1"
                onClick={() => this.setSelectedEvent(null)}
              >
                Add Event <i className="fas fa-caret-right" />
              </Link>
            ) : (
              <Link
                to="/events/add"
                className="btn1 mobile"
                onClick={() => this.setSelectedEvent(null)}
              >
                <i className="far fa-calendar-plus" />
              </Link>
            )}
          </h2>
        </div>
        <EventsFilter
          eventFilters={this.eventFilters}
          resetEventFilters={this.resetEventFilters}
          updateEventFilter={this.updateEventFilter}
          loadEvents={this.loadEvents}
        />
        <Paging
          pageOffset={this.pageOffset}
          currentPageItemCount={EventsStore.events && EventsStore.events.length}
          maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
          getPrevPage={this.prevPage}
          getNextPage={this.nextPage}
        />
        <Spinner isLoading={this.isLoading}>
          <div className="tiledPanels">
            {EventsStore.events.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                showViewEventModalForEvent={this.showViewEventModalForEvent}
                showDeleteEventModalForEvent={this.showDeleteEventModalForEvent}
                showEnableEventModalForEvent={() => null}
                setEventInStore={this.setSelectedEvent}
              />
            ))}
            {EventsStore.events &&
              EventsStore.events.length === 0 &&
              "No events found"}
          </div>
          {EventsStore.events && EventsStore.events.length > 4 && (
            <Paging
              pageOffset={this.pageOffset}
              currentPageItemCount={
                EventsStore.events && EventsStore.events.length
              }
              maxItemsPerPage={DEFAULT_EVENTS_PER_PAGE}
              getPrevPage={this.prevPage}
              getNextPage={this.nextPage}
            />
          )}
          <Link
            to="/events/add"
            className="button buttonWide"
            onClick={() => this.setSelectedEvent(null)}
          >
            <i className="far fa-calendar-plus" />
            Add Event
          </Link>
        </Spinner>
        {this.modalVisible && (
          <ViewEventModal
            event={this.selectedEvent}
            setModalVisible={this.setModalVisible}
          />
        )}
        {this.deleteModalVisible && (
          <DeleteEventModal
            id={this.selectedEvent.id}
            title={this.selectedEvent.title}
            parentEventId={this.selectedEvent.parentEventId}
            isLoading={this.deleteModalLoading}
            setDeleteModalVisible={this.setDeleteModalVisible}
            deleteEvent={this.deleteEvent}
          />
        )}
      </>
    );
  }
}

EventList.propTypes = { store: PropTypes.object };
export default EventList;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { Link } from "react-router-dom";

class ConfirmationModal extends Component {
  @observable isLoading;
  @action setIsLoading(loading) {
    this.isLoading = loading;
  }

  cancel = () => {
    this.props.cancelAction();
  };

  confirm = () => {
    this.setIsLoading(true);
    this.props.confirmAction();
  };

  render() {
    return (
      <div className={"modal is-active"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {this.props.title && this.props.title.length > 0
                ? this.props.title
                : "Are you sure?"}
            </p>
            {!this.isLoading ? (
              <React.Fragment>
                {this.props.cancelAction ? (
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => this.cancel()}
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </header>
          <section className="modal-card-body">
            <div className="field">
              {this.props.isHtml ? (
                <React.Fragment>
                  <div dangerouslySetInnerHTML={this.props.text}></div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.props.children ?? this.props.text}
                </React.Fragment>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            {this.props.cancelAction ? (
              <button
                className={"modalBtn cancel"}
                onClick={() => this.cancel()}
              >
                {this.props.cancelWord && this.props.cancelWord.length > 0
                  ? this.props.cancelWord
                  : "Cancel"}
              </button>
            ) : null}
            {this.props.confirmAction ? (
              <button
                className={
                  this.props.cancelAction
                    ? "modalBtn action"
                    : "modalBtn action full"
                }
                onClick={() => this.confirm()}
              >
                {this.props.confirmWord && this.props.confirmWord.length > 0
                  ? this.props.confirmWord
                  : "Confirm"}
              </button>
            ) : (
              <React.Fragment>
                {this.props.link ? (
                  <Link to={this.props.link} className={"modalBtn action"}>
                    {this.props.confirmWord && this.props.confirmWord.length > 0
                      ? this.props.confirmWord
                      : "Confirm"}
                  </Link>
                ) : null}
              </React.Fragment>
            )}{" "}
          </footer>
        </div>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  confirmWord: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelWord: PropTypes.string,
  link: PropTypes.string,
  confirmAction: PropTypes.func,
  title: PropTypes.string,
  isHtml: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default ConfirmationModal;

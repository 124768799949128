export const betaCustomers = [
  // Alvar and Hurriks
  "a1f935c8-e4c5-4f44-ad35-fe2093fb2cce",
  // A&H Games - Monroe
  "ea64c29d-d379-46ac-8cd5-5638fd0ccc94",
  // BinderPOS Dev 2
  "6016c704-dead-4e04-9a80-ffcad0da0939",
  // Game Grid - Lehi
  "8d538eac-8aa2-4d60-b8fd-ca11dfac8890",
  // Game Grid - Layton
  "8066ee81-0b81-411f-a1a4-dc49ad50fae8",
  // Game Grid - North Salt Lake
  "cb4ca939-4bc0-4614-bcbc-f1154f996f01",
  // Game Grid - North Ogden
  "bb58e401-98a7-4398-bc1a-db538c7abeb6",
  // Game Grid - Logan
  "c78fef21-8841-4992-aa05-acae55d1f59d",
  // Game Grid - Midvale
  "d5e42b82-6b17-4908-a0e9-a3034b3ea33a",
  // Game Grid - Clinton
  "166fd495-74e6-46de-b6bf-c3fa98f79076",
  // Gamers Haven St. George
  "16e91d52-4c1f-43cd-af86-69adafb3f92e",
];

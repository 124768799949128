import React, { useRef, useState } from "react";
import { postCustomerCsv } from "../../api/rest/customers";

export interface CustomerCsvUploadModalProps {
  handleClose: () => void;
}

function CustomerCsvUploadModal(props: CustomerCsvUploadModalProps) {
  const { handleClose } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [response, setResponse] = useState<string>();

  const uploadCSVFile = () => {
    const file = fileRef?.current?.files?.[0];
    if (file) {
      setIsProcessing(true);
      const data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      postCustomerCsv(data)
        .then((result) => {
          setResponse(result.message);
        })
        .catch(() => {
          setResponse(
            "There was an issue uploading your CSV, please try again."
          );
        })
        .finally(() => setIsProcessing(false));
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title modal-card-title--desktop">
            Upload CSV of Customers
          </p>
          <p className="modal-card-title modal-card-title--mobile">
            Upload a CSV
          </p>

          <button className="delete" aria-label="close" onClick={handleClose}>
            <i className="fal fa-times" /> Close
          </button>
        </header>
        <section className="modal-card-body">
          {response ? (
            <pre>{response}</pre>
          ) : (
            <>
              Upload a CSV of customers with the following headers to process:
              <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email</li>
                <li>Phone</li>
              </ul>
              <p>To overwrite add the header:</p>
              <ul>
                <li>Overwrite Credit</li>
              </ul>
              <p>To adjust add the header:</p>
              <ul>
                <li>Adjust Credit</li>
              </ul>
              <label htmlFor="csvFileUpload" className="file-upload">
                {" "}
                <i className="far fa-paperclip" />
                Choose file
                <i className="fas fa-caret-right" />
              </label>
              <input ref={fileRef} id="csvFileUpload" type="file" />
            </>
          )}
        </section>
        <footer className="modal-card-foot">
          {response ? (
            <button
              type="button"
              className="modalBtn action full"
              onClick={handleClose}
              disabled={isProcessing}
            >
              Ok
            </button>
          ) : (
            <button
              type="button"
              className="modalBtn action full"
              onClick={uploadCSVFile}
              disabled={isProcessing}
            >
              Upload!
            </button>
          )}
        </footer>
      </div>
    </div>
  );
}

export default CustomerCsvUploadModal;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { LineItem } from "../../pos/ItemStore";
import "./OutOfStockWarning.scss";

@inject("posStore")
@observer
class OutOfStockWarning extends Component {
  handleCancel = () => this.props.posStore.setShowOutOfStockWarning(false);

  handleProceed = () => {
    const { outOfStockItem, buyMode, allTax } = this.props.posStore;
    this.props.posStore.addToCart(
      new LineItem(outOfStockItem, buyMode, null, allTax)
    );
    this.props.posStore.setShowOutOfStockWarning(false);
  };

  render() {
    const { outOfStockItem } = this.props.posStore;
    const selectedVariant =
      outOfStockItem?.variants[outOfStockItem.selectedVariant];
    const variantTitle = selectedVariant?.title;

    return (
      <div className="modal display-block">
        <div className="modal-card">
          <section className="modal-card-body">
            {outOfStockItem ? (
              <div className="OutOfStockWarning__product">
                <div className="OutOfStockWarning__productTitle">
                  <h5>{outOfStockItem.title}</h5>
                  <p>{variantTitle}</p>
                  <hr />
                  <p>
                    Warning: this item has 0 inventory and adding it to the cart
                    will oversell this item.
                  </p>
                  <p>Are you sure you want to proceed?</p>
                </div>
                <div className="OutOfStockWarning__productImage">
                  <img src={outOfStockItem.img} title={outOfStockItem.title} />
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Warning: this item has 0 inventory and adding it to the cart
                  will oversell this item.
                </p>
                <p>Are you sure you want to proceed?</p>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <button
              className="modalBtn cancel"
              type="button"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
            <button
              className="modalBtn caution"
              type="button"
              onClick={this.handleProceed}
            >
              Proceed Anyway!
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default OutOfStockWarning;

import React, { useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import {
  fetchAttributesForGame,
  fetchTitleAttributesForGame,
} from "../../api/rest/integrations";
import Loader from "../../components/generic/Loader";
import { attributeSelect } from "../../utils/editor";
import { joinClasses } from "../../utils/styling";
import "./EbayDescriptionEditor.scss";
import {
  toolbarAll,
  toolbarMobile,
  toolbarTitle,
} from "./EbayDescriptionEditorToolBars";

const TITLE_DESCRIPTION_LIMIT = 80;
const MOBILE_DESCRIPTION_LIMIT = 750; // 800
const DESCRIPTION_LIMIT = 499000; // 500000

const customiseSettings = (mobile: boolean, title: boolean) => {
  if (title)
    return {
      suffix: "title",
      toolbar: toolbarTitle,
      height: 100,
      charCounterType: "char",
    };
  if (mobile)
    return {
      suffix: "mobile",
      toolbar: toolbarMobile,
      height: 200,
      charCounterType: "byte-html",
    };
  return {
    suffix: "all",
    toolbar: toolbarAll,
    height: 400,
    charCounterType: "byte-html",
  };
};

type Attribute = {
  id: string;
  name: string;
};

interface EbayDescriptionEditorProps {
  game?: string;
  title?: boolean;
  mobile?: boolean;
  className?: string;
  theme?: string;
  value?: string;
  name: string;
  setFieldValue: Function;
  onChange: (content: string) => void;
}

function EbayDescriptionEditor(props: EbayDescriptionEditorProps) {
  const { game, mobile, title, className, value, onChange } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const sunRef = useRef<SunEditor>();
  const descriptionLimit = mobile
    ? MOBILE_DESCRIPTION_LIMIT
    : title
    ? TITLE_DESCRIPTION_LIMIT
    : DESCRIPTION_LIMIT;

  const fetchAttributes = title
    ? fetchTitleAttributesForGame
    : fetchAttributesForGame;

  useEffect(() => {
    setIsLoading(true);
    fetchAttributes(game || "all")
      .then((data: Attribute[]) => setAttributes(data))
      .finally(() => setIsLoading(false));
  }, [game]);

  const handleChange = (content: string) => {
    // Strip any formatting for title field
    if (title) {
      onChange(sunRef.current.editor.getText());
    } else {
      onChange(content);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const { suffix, toolbar, height, charCounterType } = customiseSettings(
    mobile,
    title
  );

  return (
    <div
      className={joinClasses([
        className,
        "ebay-description-editor",
        `ebay-description-editor--${suffix}`,
      ])}
    >
      <SunEditor
        ref={sunRef}
        setContents={value || ""}
        onChange={handleChange}
        setOptions={{
          // @ts-ignore
          attributes: attributes,
          defaultTag: "div",
          plugins: [attributeSelect],
          buttonList: [toolbar],
          imageFileInput: false,
          height: height,
          charCounter: true,
          charCounterType: charCounterType,
          maxCharCount: descriptionLimit,
        }}
      />
      {suffix === "all" ? (
        <p className="ebay-description-editor__warning-text">
          Please note, when including images via the Insert Attribute dropdown,
          the image content will not appear in the editor. This is because these
          values are not populated until the listings are created.
        </p>
      ) : null}
    </div>
  );
}

EbayDescriptionEditor.defaultProps = {
  game: "all",
  title: false,
  mobile: false,
  theme: "snow",
  className: null,
};

export default EbayDescriptionEditor;

import React, { Component, Fragment } from "react";
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import { fetchCarts } from "../../api/rest/pos";
import { API_STATE } from "../../constants/api";
import OptionModal from "../generic/OptionModal";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import { Pagination, TableSpinner } from "../../components/reports";
import TimeUtils from "../../utils/TimeUtils";

@inject("posStore")
class DeleteButtonCell extends Component {
  render() {
    const { posStore, rowData } = this.props;
    return (
      <i
        className="far fa-trash-alt"
        onClick={() => posStore.setDeleteModal(rowData.id)}
        title="Delete"
      />
    );
  }
}

@inject("posStore")
class LoadButtonCell extends Component {
  render() {
    const { posStore, rowData } = this.props;
    return (
      <i
        className="fas fa-cloud-download-alt"
        onClick={(e) => posStore.getCartById(e, rowData.id)}
      />
    );
  }
}

function CartTypeSelect({ value, setValue }) {
  return (
    <select
      data-testid="cart-select"
      onChange={(event) => setValue(event.target.value)}
      value={value}
    >
      <option>All</option>
      <option value="pos">POS</option>
      <option value="kiosk">Kiosk</option>
      <option value="return">Return</option>
    </select>
  );
}

function TextInputFilter({ value, setValue }) {
  return (
    <input
      type="text"
      value={value === null || value === undefined ? "" : value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
}

@inject("posStore")
@observer
class CartModal extends Component {
  componentDidMount() {
    const { ReportsStore } = this.props.posStore;
    const { reset, setApiState, setSourceData } = ReportsStore;
    reset();
    ReportsStore.setFilters(this.filters);
    setApiState(API_STATE.LOADING);
    fetchCarts()
      .then((data) => {
        setSourceData(data);
        setApiState(API_STATE.SUCCESS);
      })
      .catch(() => {
        setApiState(API_STATE.ERROR);
      });
  }

  @action closeModal = () => {
    this.props.posStore.cartModal = false;
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }
    const { filterValues, updateFilterValue } =
      this.props.posStore.ReportsStore;
    const {
      cartType = "",
      cartId = "",
      cartName = "",
      customerName = "",
    } = filterValues;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width: width, flexGrow: flexGrow }}
              key={`header_${key}`}
            >
              {key === "col_cart_type" && (
                <CartTypeSelect
                  value={cartType}
                  setValue={(value) => updateFilterValue("cartType", value)}
                />
              )}
              {key === "col_cart_id" && (
                <TextInputFilter
                  value={cartId}
                  setValue={(value) => updateFilterValue("cartId", value)}
                />
              )}
              {key === "col_cart_name" && (
                <TextInputFilter
                  value={cartName}
                  setValue={(value) => updateFilterValue("cartName", value)}
                />
              )}
              {key === "col_customer_name" && (
                <TextInputFilter
                  value={customerName}
                  setValue={(value) => updateFilterValue("customerName", value)}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  filters = [
    {
      name: "cart has items",
      condition: () => true, // Always apply
      filterFunction: (cart) => cart?.cartItems && cart.cartItems.length,
    },
    {
      name: "cart type",
      condition: (filterValues) =>
        filterValues.cartType && filterValues.cartType !== "All",
      filterFunction: (cart, filterValues) =>
        cart.cartType === filterValues.cartType,
    },
    {
      name: "cart Id",
      condition: (filterValues) =>
        filterValues.cartId && filterValues.cartId.length,
      filterFunction: (cart, filterValues) =>
        String(cart.id).includes(filterValues.cartId),
    },
    {
      name: "cart name",
      condition: (filterValues) =>
        filterValues.cartName && filterValues.cartName.length,
      filterFunction: (cart, filterValues) =>
        cart.cartName &&
        cart.cartName
          .toLowerCase()
          .includes(filterValues.cartName.toLowerCase()),
    },
    {
      name: "customer name",
      condition: (filterValues) =>
        filterValues.customerName && filterValues.customerName.length,
      filterFunction: (cart, filterValues) =>
        (cart.customer?.firstName &&
          cart.customer.firstName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())) ||
        (cart.customer?.lastName &&
          cart.customer.lastName
            .toLowerCase()
            .includes(filterValues.customerName.toLowerCase())),
    },
  ];

  render() {
    const { posStore } = this.props;
    const { ReportsStore } = posStore;
    const rowPerPageOptions = [10, 20];

    // Need to unpack here or the change in sort column does not trigger a re-render.
    const {
      currentPage,
      rowsPerPage,
      sortColumn,
      totalRowCount,
      apiState,
      setCurrentPage,
      setRowsPerPage,
      setSortColumn,
    } = ReportsStore;
    const data = ReportsStore.computedTableData;
    return (
      <Fragment>
        <div className="modal is-active loadCart">
          <div className="modal-background" onClick={this.closeModal} />
          <div className="modal-card modal-card-large">
            <header className="modal-card-head">
              <p className="modal-card-title">Load a cart</p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.closeModal}
              >
                <i className="fal fa-times"></i> Close
              </button>
            </header>
            <section className="modal-card-body">
              <div className="wrapper" style={{ height: "100%" }}>
                {apiState !== API_STATE.SUCCESS || totalRowCount > 0 ? (
                  <Fragment>
                    <div className="data-table">
                      <div style={{ height: "calc(100% - 2em)" }}>
                        <AutoResizer>
                          {({ width, height }) => (
                            <BaseTable
                              data={data}
                              width={width}
                              height={height}
                              sortBy={sortColumn}
                              onColumnSort={setSortColumn}
                              rowHeight={60}
                              headerHeight={[40, 40]}
                              headerRenderer={this.headerRenderer}
                              overlayRenderer={() => (
                                <TableSpinner
                                  isLoading={apiState === API_STATE.LOADING}
                                />
                              )}
                            >
                              <Column
                                key="col_delete"
                                width={48}
                                align="center"
                                cellRenderer={DeleteButtonCell}
                              />
                              <Column
                                key="col_load"
                                width={48}
                                align="center"
                                cellRenderer={LoadButtonCell}
                              />
                              <Column
                                key="col_cart_type"
                                dataKey="cartType"
                                dataGetter={({ rowData }) =>
                                  rowData?.cartType ? rowData.cartType : "POS"
                                }
                                align="left"
                                title="Cart Type"
                                width={100}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_cart_id"
                                dataKey="id"
                                align="left"
                                title="Cart Id"
                                width={100}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_cart_name"
                                dataKey="cartName"
                                align="left"
                                title="Cart Name"
                                width={0}
                                flexGrow={1}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_customer_name"
                                dataKey="customerName"
                                dataGetter={({ rowData }) =>
                                  [
                                    rowData.customer?.firstName,
                                    rowData.customer?.lastName,
                                  ].join(" ")
                                }
                                align="left"
                                title="Customer Name"
                                width={0}
                                flexGrow={2}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_line_items"
                                dataGetter={({ rowData }) =>
                                  rowData.cartItems.length
                                }
                                align="left"
                                title="Line Items"
                                width={100}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_updated"
                                dataKey="lastUpdated"
                                dataGetter={({ rowData }) =>
                                  TimeUtils.convertDateToHumanReadable(
                                    rowData.lastUpdated
                                  )
                                }
                                align="left"
                                title="Last Updated"
                                width={250}
                                sortable
                                resizable
                              />
                              <Column
                                key="col_saved"
                                dataGetter={({ rowData }) =>
                                  rowData.savedCart ? "Saved" : "Autosaved"
                                }
                                align="left"
                                width={100}
                                resizable
                              />
                            </BaseTable>
                          )}
                        </AutoResizer>
                      </div>
                      <Pagination
                        apiState={apiState}
                        currentPage={currentPage}
                        totalRowCount={totalRowCount}
                        rowsPerPage={rowsPerPage}
                        rowPerPageOptions={rowPerPageOptions}
                        setCurrentPage={setCurrentPage}
                        setRowsPerPage={setRowsPerPage}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <p className="noCart">No saved carts</p>
                )}
              </div>
            </section>
          </div>
        </div>
        {posStore.deleteModal ? (
          <OptionModal
            text={
              "Please choose how you want to delete cart #" +
              posStore.deleteModal
            }
            title="Cart Deletion"
            actions={[
              {
                action: async () => {
                  const { ReportsStore } = this.props.posStore;
                  const { setApiState, setSourceData } = ReportsStore;
                  await posStore.deleteCartById(
                    null,
                    posStore.deleteModal,
                    false
                  );
                  await fetchCarts()
                    .then((data) => {
                      setSourceData(data);
                      setApiState(API_STATE.SUCCESS);
                    })
                    .catch(() => {
                      setApiState(API_STATE.ERROR);
                    });
                  posStore.setDeleteModal(null);
                },
                wording: "Delete",
              },
              {
                action: async () => {
                  const { ReportsStore } = this.props.posStore;
                  const { setApiState, setSourceData } = ReportsStore;
                  await posStore.deleteCartById(
                    null,
                    posStore.deleteModal,
                    true
                  );
                  await fetchCarts()
                    .then((data) => {
                      setSourceData(data);
                      setApiState(API_STATE.SUCCESS);
                    })
                    .catch(() => {
                      setApiState(API_STATE.ERROR);
                    });
                  posStore.setDeleteModal(null);
                },
                wording: "Delete and Return Stock",
                color: "#0a162e",
              },
              {
                action: () => {
                  posStore.setDeleteModal(null);
                },
                wording: "Cancel",
              },
            ]}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default CartModal;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { EbayParam, EbayPayment } from "../../api/rest/ebay";
import ButtonComponent from "../../components/generic/ButtonComponent";
import { validateRequiredFields } from "../../utils/forms";
import "./EbayPaymentMethodsForm.scss";

const initialValues = {
  name: "",
  immediatePay: true,
  paymentPolicyId: "",
  policyUrl: "",
  paymentInstructions: "",
  paymentMethods: [],
  paypalEmail: "",
  paymentInstrumentBrands: [],
} as EbayPayment;

const requiredFields = ["name", "paymentMethods"];
const validate = (values: EbayPayment) =>
  validateRequiredFields(requiredFields, values);

const generateInput = (fieldName: string, fieldLabel: string) => (
  <>
    <label htmlFor={fieldName}>
      {fieldLabel}
      {requiredFields.includes(fieldName) ? (
        <span className="ebay-payment-form__required">*</span>
      ) : (
        ""
      )}
    </label>
    <Field name={fieldName} />
    <ErrorMessage
      name={fieldName}
      className="ebay-payment-form__field-error"
      component="div"
    />
  </>
);

const generateCheckboxes = (
  fieldName: string,
  options: EbayParam[],
  values: string[],
  setValue: Function
) =>
  options.map((option) => (
    <label
      htmlFor={`${fieldName}_${option.code}`}
      className="ebay-address-form__checkbox-label"
      key={option.code}
    >
      <input
        type="checkbox"
        id={`${fieldName}_${option.code}`}
        checked={values.includes(option.code)}
        onChange={(e) => {
          if (e.target.checked) {
            setValue(fieldName, [...values, option.code]);
          } else {
            setValue(
              fieldName,
              values.filter((v) => v !== option.code)
            );
          }
        }}
      />
      {option.description}
    </label>
  ));

const generateRadioButtons = (
  fieldName: string,
  options: EbayParam[],
  values: string[],
  setValue: Function
) =>
  options.map((option) => (
    <label
      htmlFor={`${fieldName}_${option.code}`}
      className="ebay-address-form__checkbox-label"
      key={option.code}
    >
      <input
        type="radio"
        name={fieldName}
        id={`${fieldName}_${option.code}`}
        checked={values.includes(option.code)}
        onChange={() => {
          setValue(fieldName, [option.code]);
        }}
      />
      {option.description}
    </label>
  ));

const filterPaymentMethods = (
  paymentMethods: EbayParam[],
  supportedPaymentMethods: string[]
) =>
  paymentMethods.filter((method: EbayParam) =>
    supportedPaymentMethods.includes(method.code)
  );

const handleManagedPaymentsChange = (
  enabled: boolean,
  values: EbayPayment,
  supportedPaymentMethods: string[],
  setFieldValue: Function
) => {
  if (!enabled && values.paymentMethods?.[0] === "CREDIT_CARD") {
    setFieldValue("paymentMethods", supportedPaymentMethods[0]);
  }
};

interface EbayPaymentMethodFormProps {
  paymentSetting?: EbayPayment;
  paymentMethods: EbayParam[];
  paymentBrands: EbayParam[];
  setEditSetting: Function;
  saveSetting: Function;
}

function EbayPaymentMethodsForm(props: EbayPaymentMethodFormProps) {
  const {
    paymentSetting,
    paymentMethods,
    paymentBrands,
    setEditSetting,
    saveSetting,
  } = props;
  const [managedPayments, setManagedPayments] = useState<boolean>();

  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState<
    string[]
  >(["PAYPAL"]);

  useEffect(() => {
    // We don't store if users have eBay managed payments enabled
    // Therefore we infer this is true if payment by credit card is already enabled
    setManagedPayments(paymentSetting?.paymentMethods?.[0] === "CREDIT_CARD");
  }, [paymentSetting?.paymentMethods?.[0]]);

  useEffect(() => {
    if (managedPayments && !supportedPaymentMethods.includes("CREDIT_CARD")) {
      setSupportedPaymentMethods((methods) => [...methods, "CREDIT_CARD"]);
    }
    if (!managedPayments && supportedPaymentMethods.includes("CREDIT_CARD")) {
      setSupportedPaymentMethods((methods) =>
        methods.filter((method) => method !== "CREDIT_CARD")
      );
    }
  }, [managedPayments]);

  return (
    <div className="ebay-payment-methods-form">
      <Formik
        initialValues={paymentSetting || initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) =>
          saveSetting(values, setSubmitting)
        }
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="ebay-payment-methods-form__inputs">
              {generateInput("name", "Payment Setting Name")}
              <label htmlFor="immediatePay">Immediate Pay</label>
              <Field type="checkbox" name="immediatePay" />
              <ErrorMessage
                name="immediatePay"
                className="ebay-payment-methods-form__field-error"
                component="div"
              />
              {generateInput("policyUrl", "Policy URL")}
              {generateInput("paymentInstructions", "Payment Instructions")}
              <label htmlFor="paymentMethods">Managed Payments</label>
              <div className="ebay-payment-methods-form__payment-methods">
                <label
                  htmlFor="managedPayments"
                  className="ebay-address-form__checkbox-label"
                >
                  <input
                    id="managedPayments"
                    type="checkbox"
                    checked={managedPayments}
                    onChange={(event) => {
                      setManagedPayments(event.target.checked);
                      handleManagedPaymentsChange(
                        event.target.checked,
                        values,
                        supportedPaymentMethods,
                        setFieldValue
                      );
                    }}
                  />
                  Do you have eBay managed payments enabled?
                </label>
                <p className="ebay-payment-methods-form__info-block">
                  Don't know if you have eBay managed payments?{" "}
                  <a
                    href="https://export.ebay.com/en/fees-and-payments/payments/introducing-managed-payments/"
                    target="_blank"
                    rel="noopener"
                  >
                    Click here
                  </a>{" "}
                  to read more
                </p>
              </div>
              <label htmlFor="paymentMethods">Payment Methods</label>
              <div className="ebay-payment-methods-form__payment-methods">
                {generateRadioButtons(
                  "paymentMethods",
                  filterPaymentMethods(paymentMethods, supportedPaymentMethods),
                  values.paymentMethods || [],
                  setFieldValue
                )}
              </div>
              <ErrorMessage
                name="paymentMethods"
                className="ebay-payment-methods-form__field-error"
                component="div"
              />
              {values.paymentMethods.includes("PAYPAL")
                ? generateInput("paypalEmail", "Paypal Email")
                : null}
              {values.paymentMethods.includes("CREDIT_CARD") ? (
                <>
                  <label htmlFor="paymentInstrumentBrands">
                    Accepted Cards
                  </label>
                  <div className="ebay-payment-methods-form__payment-methods">
                    {generateCheckboxes(
                      "paymentInstrumentBrands",
                      paymentBrands,
                      values.paymentInstrumentBrands || [],
                      setFieldValue
                    )}
                  </div>
                  <ErrorMessage
                    name="paymentInstrumentBrands"
                    className="ebay-payment-methods-form__field-error"
                    component="div"
                  />
                </>
              ) : null}
            </div>
            <div className="ebay-payment-methods-form__actions">
              <ButtonComponent secondary onClick={() => setEditSetting()}>
                Cancel
              </ButtonComponent>
              <ButtonComponent type="submit" disabled={isSubmitting}>
                Save
              </ButtonComponent>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EbayPaymentMethodsForm;

import React from "react";
import { capitalizeFirstLetter } from "../../utils/formatting";
import { fieldLookup } from "./bulkUpdateModalFields";
import { useFormatCurrency } from "../../hooks/storeHooks";
import ConfirmationModal from "../generic/ConfirmationModal";

interface BulkUpdateConfirmationModalProps {
  formData: Record<string, string | number>;
  selectedFields: string[];
  removeValues: string[];
  productCount: number;
  cancelAction: () => void;
  pushBulkUpdate: () => void;
}

function BulkUpdateConfirmationModal(props: BulkUpdateConfirmationModalProps) {
  const {
    formData,
    selectedFields,
    removeValues,
    productCount,
    cancelAction,
    pushBulkUpdate,
  } = props;

  const formatValue = (field: string) => {
    if (field.includes("Percentage")) {
      return `${formData[field]}%`;
    }
    if (field.includes("Price") || field.includes("price")) {
      return useFormatCurrency(formData[field]);
    }
    return formData[field];
  };

  const buildUpdateText = () =>
    Object.keys(fieldLookup).map((field) => {
      if (!selectedFields.includes(field)) {
        return null;
      }
      if (field === "stock") {
        return (
          <li key={field}>
            {capitalizeFirstLetter(String(formData.stockUpdateType))} Stock:{" "}
            {formData.stock}
          </li>
        );
      }
      const name = fieldLookup[field];
      if (removeValues.includes(field)) {
        return <li key={field}>{name} will be removed</li>;
      }
      return (
        <li key={field}>
          {name}: {formatValue(field)}
        </li>
      );
    });

  return (
    <ConfirmationModal
      type="confirm"
      title="Are you sure?"
      cancelAction={cancelAction}
      confirmAction={pushBulkUpdate}
    >
      <p>
        Are you sure you want to bulk update the following across {productCount}{" "}
        products?
      </p>
      <ul>{buildUpdateText()}</ul>
    </ConfirmationModal>
  );
}

export default BulkUpdateConfirmationModal;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { Loader, Paging, DateSelect } from "../../components";
import { DEFAULT_SUBMITTED_CARTS_PER_PAGE } from "../../constants/pos";
import BaseTable, { AutoResizer, Column } from "react-base-table";
import "react-base-table/styles.css";
import {
  NumberInputFilter,
  TextInputFilter,
  TimestampRenderer,
} from "../../components/reports/CellTypes";
import { Link } from "react-router-dom";
import { fetchAllCarts } from "../../api/rest/posSettings";
import { TableSpinner } from "../../components/reports";
import { TillSelect } from "../../components/posSettings";

@inject("store")
@observer
class CartList extends Component {
  constructor(props) {
    super(props);
    this.debounceTimeout = null;
  }

  @observable pageOffset = 0;

  @action setPageOffset = (offset) => {
    this.pageOffset = offset;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable cartFilters = {
    cartId: "",
    shopifyOrderId: "",
    tillId: "",
    firstName: "",
    lastName: "",
  };

  @action updateCartFilter = (parameter, value) => {
    if (parameter === "offset") {
      this.setPageOffset(value);
    } else {
      this.setPageOffset(0);
      this.cartFilters[parameter] = value;
    }
  };

  @observable sortColumn = { key: "dateSubmitted", order: "desc" };

  @action setSortColumn = (sort) => {
    console.log({ sort });
    const { key, order } = sort;
    this.sortColumn = { key, order };
    this.setPageOffset(0);
    this.loadSubmittedCarts();
  };

  @computed get cartParams() {
    const { key, order } = this.sortColumn;
    // The line at the end removes parameters where a value is not set
    return {
      offset: this.pageOffset,
      limit: DEFAULT_SUBMITTED_CARTS_PER_PAGE,
      orderBy: key,
      desc: order === "desc",
      ...Object.fromEntries(
        Object.entries(this.cartFilters).filter(([, value]) => !!value)
      ),
    };
  }

  componentDidMount() {
    document.title = "Point of Sale Submitted Carts | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    this.loadSubmittedCarts();
    this.props.store.MainStore.buildReceiptData();
  }

  loadSubmittedCarts = () => {
    this.setIsLoading(true);
    fetchAllCarts(this.cartParams)
      .then((result) => {
        this.props.store.CartStore.setCarts(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load carts",
          "There was an error retrieving your list of submitted carts. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  prevPage = () => {
    let newOffset = this.pageOffset - DEFAULT_SUBMITTED_CARTS_PER_PAGE;
    if (newOffset < 0) {
      newOffset = 0;
    }
    this.setPageOffset(newOffset);
    this.loadSubmittedCarts();
  };

  nextPage = () => {
    const newOffset = this.pageOffset + DEFAULT_SUBMITTED_CARTS_PER_PAGE;
    this.setPageOffset(newOffset);
    this.loadSubmittedCarts();
  };

  onCartFilterChange = (parameter, value) => {
    this.updateCartFilter(parameter, value);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(this.loadSubmittedCarts, 500);
  };

  headerRenderer = ({ cells, columns, headerIndex }) => {
    if (headerIndex === 0) {
      return cells;
    }

    const {
      cartId = "",
      shopifyOrderId = "",
      tillId = "",
      firstName = "",
      lastName = "",
    } = this.cartFilters;

    return (
      <div className="filterable-header">
        {columns.map((column) => {
          const { key, width, flexGrow } = column;
          return (
            <div
              className="filters"
              style={{ width, flexGrow }}
              key={`header_${key}`}
            >
              {key === "id" && (
                <NumberInputFilter
                  value={cartId}
                  setValue={(value) => this.onCartFilterChange("cartId", value)}
                  title="Filter by cart ID"
                />
              )}
              {key === "shopifyOrderId" && (
                <NumberInputFilter
                  value={shopifyOrderId}
                  setValue={(value) =>
                    this.onCartFilterChange("shopifyOrderId", value)
                  }
                  title="Filter by order ID"
                />
              )}
              {key === "tillName" && (
                <TillSelect
                  value={tillId}
                  setValue={(value) => this.onCartFilterChange("tillId", value)}
                  title="Filter by till"
                />
              )}
              {key === "firstName" && (
                <TextInputFilter
                  value={firstName}
                  setValue={(value) =>
                    this.onCartFilterChange("firstName", value)
                  }
                  title="Filter by first name"
                />
              )}
              {key === "lastName" && (
                <TextInputFilter
                  value={lastName}
                  setValue={(value) =>
                    this.onCartFilterChange("lastName", value)
                  }
                  title="Filter by last name"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { AuthStore, CartStore } = this.props.store;

    if (!AuthStore.screenSettings.pos) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }

    if (!CartStore.carts) {
      return <Loader />;
    }

    // Have to destructure here to force re-render
    const { isLoading } = this;

    return (
      <>
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Submitted Carts</span>
          </h2>
        </div>
        <div className="table-controls">
          <DateSelect
            filterValues={this.cartFilters}
            updateFilterValue={this.onCartFilterChange}
          />
          <Paging
            pageOffset={this.pageOffset}
            currentPageItemCount={CartStore.carts && CartStore.carts.length}
            maxItemsPerPage={1}
            getPrevPage={this.prevPage}
            getNextPage={this.nextPage}
          />
        </div>
        <div>
          <div>
            <div className="cart-list">
              <div className="data-table">
                <div
                  style={{
                    height: `${
                      CartStore.carts ? CartStore.carts.length * 50 + 80 : 0
                    }px`,
                  }}
                >
                  <AutoResizer>
                    {({ width }) => (
                      <BaseTable
                        data={CartStore.carts}
                        width={width}
                        height={
                          CartStore.carts ? CartStore.carts.length * 50 + 80 : 0
                        }
                        rowHeight={50}
                        headerHeight={[40, 40]}
                        headerRenderer={this.headerRenderer}
                        overlayRenderer={() => (
                          <TableSpinner isLoading={isLoading} />
                        )}
                        rowClassName={({ rowData }) =>
                          rowData.customer ? "registered-customer" : "guest"
                        }
                        sortBy={this.sortColumn}
                        onColumnSort={this.setSortColumn}
                      >
                        <Column
                          key="id"
                          title="Cart #"
                          dataKey="id"
                          width={110}
                          flexGrow={1}
                          resizable
                          sortable
                        />
                        <Column
                          key="shopifyOrderId"
                          title="Shopify Order #"
                          dataKey="shopifyOrderId"
                          width={140}
                          flexGrow={1}
                          resizable
                          sortable
                        />
                        <Column
                          key="dateSubmitted"
                          title="Date Submitted"
                          dataKey="dateSubmitted"
                          cellRenderer={TimestampRenderer}
                          width={160}
                          resizable
                          sortable
                        />
                        <Column
                          key="tillName"
                          title="Till"
                          dataKey="till.name"
                          width={120}
                          resizable
                          sortable
                        />
                        <Column
                          key="firstName"
                          title="First Name"
                          dataKey="customer"
                          cellRenderer={({ cellData }) =>
                            cellData?.firstName || "Guest"
                          }
                          width={120}
                          resizable
                          sortable
                        />
                        <Column
                          key="lastName"
                          title="Last Name"
                          dataKey="customer.lastName"
                          width={120}
                          flexGrow={1}
                          resizable
                          sortable
                        />
                        <Column
                          key="col_processedBy"
                          title="Processed By"
                          dataKey="submittedBy"
                          width={120}
                          flexGrow={1}
                          resizable
                        />
                        <Column
                          key="col_view"
                          dataKey="id"
                          width={100}
                          cellRenderer={({ cellData }) => (
                            <Link
                              to={`/pointOfSale/carts/${cellData}`}
                              className="viewCart"
                            >
                              <i className="fal fa-eye" /> View
                            </Link>
                          )}
                        />
                      </BaseTable>
                    )}
                  </AutoResizer>
                </div>
              </div>
            </div>
          </div>
          {CartStore.carts && CartStore.carts.length > 10 && (
            <Paging
              pageOffset={this.pageOffset}
              currentPageItemCount={CartStore.carts && CartStore.carts.length}
              maxItemsPerPage={1}
              getPrevPage={this.prevPage}
              getNextPage={this.nextPage}
            />
          )}
        </div>
      </>
    );
  }
}

CartList.propTypes = { store: PropTypes.object };

export default CartList;

/**
 * Returns the ordinal suffix for a given number
 *
 * @param {int} n
 *
 * @returns {string}
 */
export function getOrdinal(n) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Concatinates a list into a more readable format
 *
 * @param {array} theItems
 *
 * @returns {string}
 */
export function commaSplitWithAnd(theItems) {
  const list = theItems.split(",");
  return [list.slice(0, -1).join(", "), list.slice(-1)[0]].join(
    list.length < 2 ? "" : " and "
  );
}

/**
 * Formats a number in the user's local currency format
 * @param {number} value
 * @param {string} currency
 * @returns {string}
 */
export const formatLocalCurrency = (value, currency) => {
  return Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
  }).format(value);
};

/**
 * Formats a number to the requested number of decimal places
 * @param {number} value
 * @param {number} places
 * @returns {string}
 */
export const roundToDecimalPlaces = (value, places) =>
  (+(Math.round(value + `e+${places}`) + `e-${places}`)).toFixed(places);

/**
 * Ensures the first letter of a string is capitalized
 * @param {string} text
 * @returns {string}
 */
export const capitalizeFirstLetter = (text) =>
  text ? text.charAt(0).toUpperCase() + text.slice(1) : text;

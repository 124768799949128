import { useContext } from "react";
import { ErrorContext } from "../providers/ErrorProvider";

export const useShowError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError hook must be used within ErrorProvider");
  }
  const { setError } = context;

  const showError = (
    error: DetailedError,
    fallbackTitle?: string,
    fallbackMessage?: string
  ) => {
    if (error?.error || !fallbackTitle) {
      setError(error);
    } else {
      setError({
        error: fallbackTitle,
        detailedMessage: fallbackMessage,
      });
    }
  };
  return showError;
};

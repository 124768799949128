import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SectionHeaderLayout from "../../components/layout/SectionHeaderLayout";
import IntegrationsSideMenu from "../../menuStructures/IntegrationsSideMenu";
import { useIntegrations } from "../../hooks/integrationHooks";
import TcgPlayerLogo from "../../../assets/img/tcgPlayerLogo.png";
import EbayLogo from "../../../assets/img/ebayLogo.png";
import CfbLogo from "../../../assets/img/channelFireballLogo.png";

function SelectIntegration() {
  const { availableIntegrations } = useIntegrations();

  useEffect(() => {
    document.title = "Integration Sync | BinderPOS";
  }, []);

  const logos = {
    tcgplayer: TcgPlayerLogo,
    ebay: EbayLogo,
    cfbmarket: CfbLogo,
  };

  return (
    <>
      <IntegrationsSideMenu />
      <SectionHeaderLayout title="Select Integration" />
      <div className="container-fluid integrations-list">
        {availableIntegrations.map((integration) => {
          const { syncName, syncFriendlyName } = integration;
          return (
            <Link
              to={`/integrations/${syncName}`}
              className="card"
              key={syncName}
            >
              <img
                src={logos[syncName]}
                className="card-img-top"
                alt={syncFriendlyName}
              />
              <div className="card-body">
                <p className="card-text">
                  Manage how your stock is controlled and pushed through to{" "}
                  {syncFriendlyName}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default SelectIntegration;

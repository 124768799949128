import React from "react";
import { inject, observer } from "mobx-react";
import firebase from "firebase/app";

import "firebase/auth";
import "chart.js";
import { observable, action } from "mobx";
import moment from "moment";
import "moment-timezone";
import { SalesBarChart, SalesBarChartBreakDown } from "../components/dashboard";
import NewsFeed from "./NewsFeed";
import DevBlog from "./DevBlog";
// Temporary Seasonal Import
import Thanksgiving from "../components/dashboard/Thanksgiving";

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const emailProvider = new firebase.auth.GoogleAuthProvider();

// Configure Firebase.

@inject("store")
@observer
export default class Dashboard extends React.Component {
  @observable graphData;

  @action setGraphData(graphData) {
    this.graphData = graphData;
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [googleProvider.PROVIDER_ID, emailProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      dailySales: "0.00",
      selectedRange: {
        value: {
          startDate: moment().startOf("day").toISOString(),
          endDate: moment().endOf("day").toISOString(),
          binSize: 3600,
        },
        label: "Today",
      },
      selectedLabel: "Today",
    };
  }

  async componentDidMount() {
    document.title = "Dashboard | BinderPOS";
    // this.props.store.MainStore.getAppUpdate();
  }

  render() {
    return (
      <section id="dashboard">
        <Thanksgiving />
        <h2>Here&apos;s whats happening with your store...</h2>
        <div className="row">
          <div className="col-lg-6">
            <SalesBarChartBreakDown />
          </div>
          <div className="col-lg-6">
            <SalesBarChart />
          </div>
        </div>
        <DevBlog />
        <NewsFeed />
      </section>
    );
  }
}

import React, { useEffect, useRef, useState } from "react";
import { useShowError } from "../../hooks/errorHooks";
import { TimeUtils } from "../../utils";
import { fetchCustomerCreditHistory } from "../../api/rest/customers";
import Paging from "../../components/generic/Paging";
import TableSpinner from "../../components/reports/TableSpinner";
import { useFormatCurrency } from "../../hooks/storeHooks";

const LIMIT = 10;

export interface StoreCreditHistoryProps {
  customer: Customer;
  handleClose: () => void;
}

function StoreCreditHistory(props: StoreCreditHistoryProps) {
  const { customer, handleClose } = props;
  const listStartRef = useRef<HTMLDivElement>(null);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [creditHistory, setCreditHistory] = useState<CustomerCreditHistory[]>(
    []
  );

  const handlePrevPageClick = () => {
    const newOffset = Math.max(offset - LIMIT, 0);
    setOffset(newOffset);
  };

  const handleNextPageClick = () => {
    const newOffset = offset + LIMIT;
    setOffset(newOffset);
  };

  const getHistory = () => {
    setIsLoading(true);
    fetchCustomerCreditHistory(customer.id, offset, LIMIT)
      .then((results) => {
        if (results?.data) {
          setCreditHistory(results.data);
          listStartRef.current?.scrollIntoView();
        }
      })
      .catch((error: DetailedError) => {
        showError(
          error,
          "Failed to load customer details",
          "There was an error retrieving your customer's credit history. Please refresh and try again"
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getHistory();
  }, [offset]);

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            Store credit history for{" "}
            {`${customer?.firstName} ${customer?.lastName}`}
          </p>
          <button className="delete" aria-label="close" onClick={handleClose}>
            <i className="fal fa-times" /> Close
          </button>
        </header>
        <div className="paging-wrapper">
          <Paging
            pageOffset={offset}
            currentPageItemCount={creditHistory.length}
            maxItemsPerPage={10}
            getPrevPage={handlePrevPageClick}
            getNextPage={handleNextPageClick}
          />
        </div>
        <section className="modal-card-body">
          <div className="wrapper" ref={listStartRef}>
            <TableSpinner isLoading={isLoading} />
            {creditHistory?.length > 0 ? (
              <div className="content">
                {creditHistory.map((storeCreditHistory) => (
                  <div
                    key={storeCreditHistory.id}
                    id={String(storeCreditHistory.id)}
                  >
                    <table className="table is-fullwidth storeCredit">
                      <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Actioned by</strong>
                          </td>
                          <td className="info">
                            {storeCreditHistory.actionedBy}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Date actioned</strong>
                          </td>
                          <td className="info">
                            {TimeUtils.convertDateToHumanReadable(
                              storeCreditHistory.updatedDate
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Amount before</strong>
                          </td>
                          <td className="info">
                            {useFormatCurrency(
                              storeCreditHistory.amountBeforeChange
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Amount after</strong>
                          </td>
                          <td className="info">
                            {useFormatCurrency(
                              storeCreditHistory.amountAfterChange
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Amount changed</strong>
                          </td>
                          <td className="info">
                            {useFormatCurrency(
                              storeCreditHistory.amountChanged
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Notes</strong>
                          </td>
                          <td className="info">
                            <div>
                              {storeCreditHistory.actionNotes
                                ? storeCreditHistory.actionNotes
                                : storeCreditHistory.publicNotes
                                ? ""
                                : "There are no notes attached"}
                            </div>
                            <br />
                            <div>
                              {storeCreditHistory.publicNotes
                                ? storeCreditHistory.publicNotes
                                : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Internal Notes</strong>
                          </td>
                          <td className="info">
                            {storeCreditHistory.privateNotes
                              ? storeCreditHistory.privateNotes
                              : "There are no internal notes attached"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ) : (
              <div className="content">
                <p>
                  There is no store credit history to display for this customer
                </p>
              </div>
            )}
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="modalBtn action full" onClick={handleClose}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

export default StoreCreditHistory;

import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import CheckPermission from "../components/auth/CheckPermission";
import EventList from "../views/events/EventList";
import ChildEventList from "../views/events/ChildEventList";
import Event from "../views/events/Event";
import EventParticipants from "../views/events/EventParticipants";
import EventFailedToCreate from "../views/events/EventFailedToCreate";
import EventFailedToDelete from "../views/events/EventFailedToDelete";
import RemoveParticipantModal from "../components/events/RemoveParticipantModal";
import UpdateParticipantModal from "../components/events/UpdateParticipantModal";

function EventRouter() {
  return (
    <>
      <Switch>
        <Route exact path="/events">
          <Redirect to="/events/list" />
        </Route>
        <Route exact path="/events/list">
          <EventList />
        </Route>
        <Route
          exact
          path="/events/view/:eventId"
          render={(props) => <ChildEventList {...props} />}
        />
        <Route exact path="/events/add">
          <Event />
        </Route>
        <Route
          exact
          path="/events/update/:eventId"
          render={(props) => <Event {...props} />}
        />
        <Route path="/events/participants/:eventId">
          <CheckPermission screenName="events">
            <EventParticipants />
          </CheckPermission>
        </Route>
        <Route
          exact
          path="/events/failedToCreate"
          render={(props) => <EventFailedToCreate {...props} />}
        />
        <Route
          exact
          path="/events/failedToDelete"
          render={(props) => <EventFailedToDelete {...props} />}
        />
      </Switch>
      <Route exact path="/events/participants/:eventId/remove/:participantId">
        <RemoveParticipantModal />
      </Route>
      <Route exact path="/events/participants/:eventId/update/:participantId">
        <UpdateParticipantModal />
      </Route>
      <Route exact path="/events/participants/:eventId/add">
        <UpdateParticipantModal />
      </Route>
    </>
  );
}

export default EventRouter;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactChild,
} from "react";
import { useParams } from "react-router-dom";
import {
  fetchLocationSettings,
  fetchPaymentSettings,
  fetchReturnSettings,
  fetchFulfillmentSettings,
  fetchConditions,
  fetchCustomerEbayProvisioningDetails,
  // fetchWeightMeasurementUnits,
  // fetchLenghtMeasurementUnits,
  // fetchPackageTypes,
  EbayLocation,
  EbayPayment,
  EbayFulfillment,
  EbayReturn,
  EbayParam,
} from "../../../api/rest/ebay";

const defaultContext = {
  locationPolicies: [] as EbayLocation[],
  paymentPolicies: [] as EbayPayment[],
  fulfillmentPolicies: [] as EbayFulfillment[],
  returnsPolicies: [] as EbayReturn[],
  conditions: [] as EbayParam[],
  defaultMarketplace: undefined as string | undefined,
  // weightMeasurementUnits: [] as EbayParam[],
  // lengthMeasurementUnits: [] as EbayParam[],
  // packageTypes: [] as EbayParam[],
};

const EbayPoliciesContext = createContext(defaultContext);

interface EbayPoliciesProviderProps {
  children: ReactChild;
}
const EbayPoliciesProvider = (props: EbayPoliciesProviderProps) => {
  const { children } = props;
  const { integration } = useParams<{ integration: string }>();
  const [locationPolicies, setLocationPolicies] = useState<EbayLocation[]>([]);
  const [paymentPolicies, setPaymentPolicies] = useState<EbayPayment[]>([]);
  const [fulfillmentPolicies, setFulfillmentPolicies] = useState<
    EbayFulfillment[]
  >([]);
  const [returnsPolicies, setReturnsPolicies] = useState<EbayReturn[]>([]);
  const [conditions, setConditions] = useState<EbayParam[]>([]);
  const [defaultMarketplace, setDefaultMarketplace] = useState<string>();
  // const [weightMeasurementUnits, setWeightMeasurementUnits] = useState<
  //   EbayParam[]
  // >([]);
  // const [lengthMeasurementUnits, setLengthMeasurementUnits] = useState<
  //   EbayParam[]
  // >([]);
  // const [packageTypes, setPackageTypes] = useState<EbayParam[]>([]);

  const loadLocationPolicies = () =>
    fetchLocationSettings().then((data: EbayLocation[]) =>
      setLocationPolicies(data)
    );

  const loadPaymentPolicies = () =>
    fetchPaymentSettings().then((data: EbayPayment[]) =>
      setPaymentPolicies(data)
    );

  const loadFulfillmentPolicies = () =>
    fetchFulfillmentSettings().then((data: EbayFulfillment[]) =>
      setFulfillmentPolicies(data)
    );

  const loadReturnsPolicies = () =>
    fetchReturnSettings().then((data: EbayReturn[]) =>
      setReturnsPolicies(data)
    );

  const loadConditions = () =>
    fetchConditions().then((data: EbayParam[]) => setConditions(data));

  const loadProvisioningDetails = () =>
    fetchCustomerEbayProvisioningDetails().then((data) =>
      setDefaultMarketplace(data?.defaultMarketPlace)
    );

  // const loadWeightMeasurementUnits = () =>
  //   fetchWeightMeasurementUnits().then((data: EbayParam[]) =>
  //     setWeightMeasurementUnits(data)
  //   );
  // const loadLenghtMeasurementUnits = () =>
  //   fetchLenghtMeasurementUnits().then((data: EbayParam[]) =>
  //     setLengthMeasurementUnits(data)
  //   );
  // const loadPackageTypes = () =>
  //   fetchPackageTypes().then((data: EbayParam[]) => setPackageTypes(data));

  useEffect(() => {
    if (integration === "ebay") {
      loadLocationPolicies();
      loadPaymentPolicies();
      loadFulfillmentPolicies();
      loadReturnsPolicies();
      loadConditions();
      loadProvisioningDetails();
      // loadWeightMeasurementUnits();
      // loadLenghtMeasurementUnits();
      // loadPackageTypes();
    }
  }, []);

  const contextValue = {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
    defaultMarketplace,
    // weightMeasurementUnits,
    // lengthMeasurementUnits,
    // packageTypes,
  };

  return (
    <EbayPoliciesContext.Provider value={contextValue}>
      {children}
    </EbayPoliciesContext.Provider>
  );
};

export const useEbayPolicies = () => {
  const context = useContext(EbayPoliciesContext);
  if (context === undefined) {
    throw new Error("useEbayPolicies must beused within EbayPoliciesProvider");
  }

  const {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
    defaultMarketplace,
    // weightMeasurementUnits,
    // lengthMeasurementUnits,
    // packageTypes,
  } = context;

  return {
    locationPolicies,
    paymentPolicies,
    fulfillmentPolicies,
    returnsPolicies,
    conditions,
    defaultMarketplace,
    // weightMeasurementUnits,
    // lengthMeasurementUnits,
    // packageTypes,
  };
};

export default EbayPoliciesProvider;

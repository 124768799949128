import React from "react";
import { mainMenuStructure } from "../../menuStructures/mainMenuStructure";
import { showMenuItem } from "../../helpers/navHelpers";
import MobileNavItem from "./MobileNavItem";
import "./MobileMenu.scss";

interface MobileMenuProps {
  mobileMenuOpen: boolean;
  screenSettings: ScreenSettings;
  handleClick: (menu: MenuStructure | null | undefined) => void;
  setMobileMenuOpen: (open: boolean) => void;
}

function MobileMenu(props: MobileMenuProps) {
  const { mobileMenuOpen, screenSettings, handleClick, setMobileMenuOpen } =
    props;

  return (
    <div className="MainMobileMenu">
      <input
        className="MainMobileMenu__checkbox"
        type="checkbox"
        checked={mobileMenuOpen}
        onChange={() => setMobileMenuOpen(!mobileMenuOpen)}
        title="Toggle mobile menu"
        role="mobile-menu"
      />
      <div className="MainMobileMenu__hamburger">
        <div />
      </div>
      <div className="MainMobileMenu__menu">
        <div>
          <div>
            <ul>
              {mainMenuStructure.map((menuItem) => {
                if (!showMenuItem(screenSettings, menuItem.screenSetting)) {
                  return null;
                }
                return (
                  <MobileNavItem
                    key={menuItem.target}
                    handleClick={handleClick}
                    menuLink={menuItem.target}
                    menuIcon={menuItem.iconClass}
                    menuHeaderText={menuItem.title}
                    menuStructure={menuItem.menuStructure}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;

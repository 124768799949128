import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../components/generic/Spinner";

function EnableEventModal(props) {
  const { id, title, isLoading, setEnableModalVisible, enableEvent } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <Spinner isLoading={isLoading}>
          <header className="modal-card-head">
            <p className="modal-card-title">Are you sure?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setEnableModalVisible(false)}
            >
              <i className="fal fa-times" /> Close
            </button>
          </header>
          <section className="modal-card-body">
            <p>
              Are you sure you want to enable the event
              <strong> {title}</strong>?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="modalBtn cancel half"
              onClick={() => setEnableModalVisible(false)}
            >
              Cancel
            </button>
            <button
              className="modalBtn action half"
              disabled={isLoading}
              onClick={() => enableEvent(id)}
            >
              Enable
            </button>
          </footer>
        </Spinner>
      </div>
    </div>
  );
}

EnableEventModal.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  setEnableModalVisible: PropTypes.func.isRequired,
  enableEvent: PropTypes.func.isRequired,
};

export default EnableEventModal;

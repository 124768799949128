import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { POSMenuStructure } from "../../menuStructures";
import { Loader, ConfirmationModal, Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import { AddTill, TillListFilters } from "../../components/posSettings";
import { fetchTills } from "../../api/rest/posSettings";
import { DEFAULT_TILLS_PER_PAGE } from "../../constants/pos";

@inject("store")
@observer
class TillList extends Component {
  @observable addNewTillModalVisible;

  @action setAddNewTillModalVisible = (visible) => {
    this.addNewTillModalVisible = visible;
  };

  @observable addingNewTill;

  @action setAddingNewTill = (addingNewTill) => {
    this.addingNewTill = addingNewTill;
  };

  @observable showConfirmationModal;

  @action setShowConfirmationModal = (showConfirmationModal) => {
    this.showConfirmationModal = showConfirmationModal;
  };

  @observable tillToArchive;

  @action setTillToArchive = (tillToArchive) => {
    this.tillToArchive = tillToArchive;
  };

  @observable isLoading = false;

  @action setIsLoading = (loading) => {
    this.isLoading = loading;
  };

  @observable tillFilters = {};

  @action resetTillFilters = () => {
    // Preseve enabled/disabled selection
    const { showDisabled } = this.tillFilters;
    this.tillFilters = { showDisabled };
  };

  @action updateTillFilter = (parameter, value) => {
    this.tillFilters[parameter] = value;
  };

  @computed get tillListParams() {
    // The line at the end removes parameters where a value is not set
    return {
      offset: 0,
      limit: DEFAULT_TILLS_PER_PAGE,
      ...Object.fromEntries(
        Object.entries(this.tillFilters).filter(([, value]) => !!value)
      ),
    };
  }

  cancelArchive = () => {
    this.setTillToArchive(null);
    this.setShowConfirmationModal(false);
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    document.title = "Point of Sale Tills | BinderPOS";
    this.props.store.MenuStore.setSideMenuToDisplay(POSMenuStructure);
    // this.props.store.TillStore.rehydrateTills();
    this.loadTills();
  }

  loadTills = () => {
    this.setIsLoading(true);
    fetchTills(this.tillListParams)
      .then((result) => {
        this.props.store.TillStore.setTills(result);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to load tills",
          "There was an error retrieving your till list. Please refresh and try again"
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  disableTill = (id) => {
    this.props.store.TillStore.disableTill(id)
      .then(() => {
        this.loadTills();
        this.props.store.MainStore.burntToast("Till has been disabled");
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to disable till",
          "There was an error updating your till setting. Please try again"
        );
      });
  };

  showArchiveTillModal = (till) => {
    this.setShowConfirmationModal(true);
    this.setTillToArchive(till);
  };

  archiveTill = () => {
    this.props.store.TillStore.archiveTill(this.tillToArchive.id)
      .then(() => {
        this.loadTills();
        this.props.store.MainStore.toast(
          `${this.tillToArchive.name} has been archived`
        );
        this.cancelArchive();
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to archive till",
          "There was an error retrieving your till setting. Please try again"
        );
      });
  };

  enableTill = (id) => {
    this.props.store.TillStore.enableTill(id)
      .then(() => {
        this.props.store.TillStore.rehydrateTills();
        this.props.store.MainStore.toast("Till has been enabled");
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to enable till",
          "There was an error updating your till setting. Please try again"
        );
      });
  };

  addNewTill = (settings) => {
    this.setAddingNewTill(true);
    this.props.store.TillStore.addNewTill(settings)
      .then(() => {
        this.loadTills();
        this.setAddNewTillModalVisible(false);
        this.setAddingNewTill(false);
      })
      .catch((error) => {
        this.props.store.MainStore.setError(
          error,
          "Failed to create till",
          "There was an error submitting your new till. Please try again"
        );
      });
  };

  render() {
    const { AuthStore, TillStore } = this.props.store;
    if (!AuthStore.screenSettings.pos) {
      return (
        <div>
          <p>Please contact BinderPOS to enable this screen.</p>
        </div>
      );
    }
    if (!TillStore.tills) {
      return <Loader />;
    }
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="app-header">
          <h2 className="title is-2">
            <span className="header-text">Tills / Registers</span>
            <button
              className="btn1"
              onClick={() => this.setAddNewTillModalVisible(true)}
            >
              Add new Till / Register <i className="fas fa-caret-right" />
            </button>
          </h2>
          <p className="tillInfo">
            Feel free to add as many tills as required for your stores needs.
            Each till becomes usable with our POS system and has its own sales
            ledger.
          </p>
        </div>
        <div className="till-list">
          <TillListFilters
            tillFilters={this.tillFilters}
            resetTillFilters={this.resetTillFilters}
            updateTillFilter={this.updateTillFilter}
            loadTills={this.loadTills}
          />
          <Spinner isLoading={this.isLoading}>
            <div className="tiledPanels">
              {TillStore.tills.map((till, index) => (
                <div className="panelWrapper" key={index}>
                  <div className={till.active ? "panel" : "panel notActive"}>
                    <div className="topBlock">
                      <div className="tillImage">
                        <i className="fas fa-credit-card" />
                      </div>
                      <div className="titleDiv">
                        <span>{till.name}</span>
                        {till.active ? (
                          <p className="tillStatus"> Enabled</p>
                        ) : (
                          <p className="tillStatus"> Disabled</p>
                        )}
                      </div>
                    </div>
                    <div className="panel-body">
                      <div className="tillInfo">
                        <div className="types">
                          <em>Till Description:</em>
                          {till.description}
                        </div>
                      </div>
                      <hr />
                      <div className="tillInfo">
                        <div className="types">
                          <em>Till ID:</em>
                          {till.id}
                        </div>
                      </div>
                    </div>
                    <div className="editInfo">
                      <span className="edit">
                        {till.active ? (
                          <a
                            className="userActions"
                            onClick={() => this.disableTill(till.id)}
                          >
                            <i className="fas fa-eye pause" />
                            Till is enabled
                          </a>
                        ) : (
                          <a
                            className="userActions"
                            onClick={() => this.enableTill(till.id)}
                          >
                            <i className="fas fa-eye-slash green" />
                            Till is disabled
                          </a>
                        )}
                      </span>
                      <span
                        className="deletetill"
                        onClick={() => this.showArchiveTillModal(till)}
                      >
                        <i className="fas fa-archive" />
                        Delete till
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Spinner>
        </div>
        {this.showArchiveTillModal && this.tillToArchive && (
          <ConfirmationModal
            title="Archive till?"
            text={`Are you sure you want to delete till: ${this.tillToArchive.name}`}
            cancelAction={this.cancelArchive}
            confirmAction={this.archiveTill}
          />
        )}
        {this.addNewTillModalVisible && (
          <AddTill
            isLoading={this.isLoading}
            addNewTill={this.addNewTill}
            setAddNewTillModalVisible={this.setAddNewTillModalVisible}
          />
        )}
      </>
    );
  }
}

TillList.propTypes = { store: PropTypes.object };
export default TillList;

import React, { createContext, useEffect, useState } from "react";
import { fetchFeatureSwitches } from "../api/rest/features";
import {
  loadSettingsFromLocalStorage,
  saveSettingsToLocalStorage,
} from "../helpers/settingHelpers";
export interface GlobalSettingsContextProps {
  features: Record<string, string>;
  getLocalSetting: (param: string) => unknown;
  updateLocalSetting: (param: string, value: unknown) => void;
}

export const GlobalSettingsContext =
  createContext<GlobalSettingsContextProps>(null);

interface GlobalSettingsProviderProps {
  children: React.ReactNode;
}

function GlobalSettingsProvider(props: GlobalSettingsProviderProps) {
  const { children } = props;
  const [features, setFeatures] = useState<Record<string, string>>({});
  const [localSettings, setLocalSettings] = useState<LocalSettings>(
    loadSettingsFromLocalStorage()
  );

  useEffect(() => {
    fetchFeatureSwitches([])
      .then((data) => {
        const newFeatures = {};
        data.forEach((feature) => {
          const { settingName, settingValue } = feature;
          if (settingName) {
            newFeatures[settingName] = settingValue;
          }
        });
        setFeatures(newFeatures);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getLocalSetting = (param: string) => localSettings[param];

  const updateLocalSetting = (param: string, value: unknown) => {
    const newSettings = {
      ...localSettings,
      [param]: value,
    };
    setLocalSettings(newSettings);
    saveSettingsToLocalStorage(newSettings);
  };

  return (
    <GlobalSettingsContext.Provider
      value={{ features, getLocalSetting, updateLocalSetting }}
    >
      {children}
    </GlobalSettingsContext.Provider>
  );
}

export default GlobalSettingsProvider;

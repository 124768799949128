import React from "react";
import { NavLink } from "react-router-dom";
import { mainMenuStructure } from "../../menuStructures/mainMenuStructure";
import { showMenuItem } from "../../helpers/navHelpers";
import "./DesktopMenu.scss";

interface DesktopMenuProps {
  screenSettings: ScreenSettings;
  updateMenu: (menu: MenuStructure | undefined | null) => void;
}

function DesktopMenu(props: DesktopMenuProps) {
  const { screenSettings, updateMenu } = props;

  return (
    <div className="DesktopMenu">
      {mainMenuStructure.map((menuItem) => {
        if (!showMenuItem(screenSettings, menuItem.screenSetting)) {
          return null;
        }
        return (
          <NavLink
            key={menuItem.target}
            className={menuItem.classNameOverride ?? "DesktopMenu__link"}
            activeClassName="active"
            to={menuItem.target}
            onClick={() => updateMenu(menuItem.menuStructure)}
          >
            <div title={menuItem.title}>
              <i className={menuItem.iconClass} />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}

export default DesktopMenu;

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";

@inject("store")
@observer
class OptionModal extends Component {
  @observable isLoading = false;
  @action setIsLoading(loading) {
    this.isLoading = loading;
  }

  cancel = () => {
    this.props.cancelAction();
  };

  render() {
    return (
      <div className={"modal is-active"}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {this.props.title && this.props.title.length > 0
                ? this.props.title
                : "Are you sure?"}
            </p>
            {!this.isLoading ? (
              <React.Fragment>
                {this.props.cancelAction ? (
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => this.cancel()}
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </header>
          <section className="modal-card-body">
            <div className="field">
              {this.props.isHtml ? (
                <React.Fragment>
                  <div dangerouslySetInnerHTML={this.props.text}></div>
                </React.Fragment>
              ) : (
                <React.Fragment>{this.props.text}</React.Fragment>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            {this.props.cancelAction ? (
              <button
                className={"modalBtn cancel"}
                onClick={() => this.cancel()}
              >
                {this.props.cancelWord && this.props.cancelWord.length > 0
                  ? this.props.cancelWord
                  : "Cancel"}
              </button>
            ) : null}
            {this.props.actions.map((a, index) => {
              return (
                <button
                  key={index}
                  className={"modalBtn multi"}
                  disabled={this.isLoading}
                  style={{
                    width: 100 / this.props.actions.length + "%",
                    background: a.color ? a.color : "#05a0a0",
                  }}
                  onClick={async () => {
                    this.setIsLoading(true);
                    await a.action();
                    this.setIsLoading(false);
                  }}
                >
                  {this.isLoading ? (
                    <i className="fas fa-sync fa-spin"></i>
                  ) : (
                    a.wording
                  )}
                </button>
              );
            })}
          </footer>
        </div>
      </div>
    );
  }
}

export default OptionModal;
